import React, { Fragment, useState } from 'react'
import first from 'lodash/first'
import get from 'lodash/get'
import { Mutation } from 'react-apollo'
import { string } from 'prop-types'
import { Alert, Card, H5, H6 } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { Row, Col } from 'react-simple-flex-grid'

import Query from '@components/Query/Query'

import { validation } from './validation'
import GET_MARKETPLACE from './queries/getMarketplace.query'
import EDIT_MARKETPLACE from './mutations/editMarketplace.mutation'
import EDIT_MARKETPLACE_TERMINAL from './mutations/editMarketplaceTerminal.mutation'
import ALLOW_REVIEWS from './mutations/allowReviews.mutation'
import ALLOW_ORDER_TO_TABLE from './mutations/allowOrderToTable.mutation'
import ALLOW_MARKETING from './mutations/allowMarketing.mutation'
import MarketplaceForm from '@components/MarketplaceForm/MarketplaceForm'

import { successToast } from '@utils/toast'
import MarketplaceAllowReviewsForm from '@components/MarketplaceForm/MarketplaceAllowReviewsForm'
import MarketplaceAllowOrderToTableForm from '@components/MarketplaceForm/MarketplaceAllowOrderToTableForm'
import MarketplaceAllowMarketingForm from '@components/MarketplaceForm/MarketplaceAllowMarketingForm'
import TerminalSettingsForm from '@components/MarketplaceForm/TerminalSettingsForm'

import {
  canPerformAction,
  isAtLeastMarketplaceAdmin,
  isPlatformLevelUser,
} from '@stores/userStore'
import ALLOW_SINGLE_ITEM_ORDER_NOTES from './mutations/allowSingleItemOrderNotes.mutation'
import MarketplaceAllowSingleItemOrderNotesForm from '@components/MarketplaceForm/MarketplaceAllowSingleItemOrderNotesForm'
import MarketplaceAllowExtendedPreorders from '@components/MarketplaceForm/MarketplaceAllowExtendedPreorders'
import ALLOW_EXTENDED_PREORDERS from './mutations/allowExtendedPreorders.mutation'
import ALLOW_ONBOARDING from './mutations/allowOnboarding.mutation'
import GET_MARKETPLACE_BILLING from '@components/Marketplace/Billing/queries/getMarketplaceBilling.query'
import MarketplaceAllowOnboardingForm from '@components/MarketplaceForm/MarketplaceAllowOnboardingForm'
import AllowedCategories from '@components/Marketplace/AllowedCategories/AllowedCategories'
import { createAwsOptions } from '@utils/createAwsOptions'
import { omit } from 'lodash'
import ALLOW_MULTI_OUTLET_TERMINAL from '@components/Marketplace/Details/mutations/allowMultiOutletTerminal.mutation'
import MarketplaceAllowMultiOutletTerminal from '@components/MarketplaceForm/MarketplaceAllowMultiOutletTerminal'
import ALLOW_SMS_MARKETING from './mutations/allowSMSMarketing.mutation'
import ALLOW_FACEBOOK_MARKETING from './mutations/allowFacebookMarketing.mutation'
import MarketplaceAllowSMSMarketing from '@components/MarketplaceForm/MarketplaceAllowSMSMarketing'
import MarketplaceAllowFacebookMarketing from '@components/MarketplaceForm/MarketplaceAllowFacebookMarketing'
import ArchiveMarketplace from '@components/Marketplace/ArchiveMarketplace/ArchiveMarketplace'
import ENABLE_ANALYTICS from './mutations/enableAnalytics.mutation'
import ENABLE_CUSTOMER_V2_CLIENT from './mutations/enableCustomerV2Client.mutation'
import MarketplaceEnableAnalyticsForm from '@components/MarketplaceForm/MarketplaceEnableAnalyticsForm'
import MarketplaceEnableCustomerV2Client from '@components/MarketplaceForm/MarketplaceEnableCustomerV2Client'
import MarketplaceAllowBilling from '../../MarketplaceForm/MarketplaceAllowBilling'
import ALLOW_BILLING from './mutations/allowBilling.mutation'
import CollectionCard from './CollectionCard'
import GET_TABLE_SERVICE_OUTLETS from './queries/getTableServiceOutlets.query'
import ENABLE_CONFIRM_ADDRESS_AT_PAYMENT from '@components/Marketplace/Details/mutations/enableConfirmAddressAtPayment.mutation'
import MarketplaceEnableConfirmAddressAtPaymentForm from '@components/MarketplaceForm/MarketplaceEnableConfirmAddressAtPaymentForm'
import ENABLE_PAYBOX_ENTERPRISE from './mutations/enablePayboxEnterprise.mutation'
import MarketplaceEnablePayboxEnterprise from '../../MarketplaceForm/MarketplaceEnablePayboxEnterprise'
import MarketplaceAllowPendingOrderAlertSwitch from '@components/MarketplaceForm/MarketplaceAllowPendingOrderAlertSwitch'
import ALLOW_PENDING_ORDER_ALERT from './mutations/allowPendingOrderAlert'
import ALLOW_OTTER_INTEGRATION from './mutations/allowOtterIntegration.mutation'
import MarketplaceAllowOtterIntegrationSwtich from '@components/MarketplaceForm/MarketplaceAllowOtterIntegrationSwtich'

const awsOptions = createAwsOptions()
const Details = ({ match }) => {
  const [showTableOrderingAlert, setShowTableOrderingAlert] = useState(false)
  const { marketplace: marketplaceId } = match.params
  return (
    <Query
      query={GET_MARKETPLACE}
      variables={{ id: marketplaceId }}
      loaderTitle={'Loading Marketplace'}
    >
      {({ getMarketplaces: { regions } }) => {
        const marketplace = first(regions)
        if (!marketplace) {
          return 'Unable to find marketplace'
        }

        return (
          <Row gutter={24}>
            <Col sm={12} lg={8}>
              <Card>
                <Mutation
                  mutation={EDIT_MARKETPLACE}
                  onError={defaultErrorHandler}
                  onCompleted={({ editMarketplace }) =>
                    successToast(editMarketplace.message)
                  }
                >
                  {editMarketplace => {
                    const currentAWSPinpointIds = awsOptions.filter(
                      ({ value }) => value === marketplace.pinpointAwsAccountId
                    )
                    const initialValues = {
                      ...omit(marketplace, ['contactAddress.id']),
                      companyType: marketplace.companyType || 'COMPANY',
                      partnerId: marketplace.partner.id,
                      pinpointAwsAccountId: currentAWSPinpointIds.length
                        ? currentAWSPinpointIds[0].value
                        : '',
                    }

                    return (
                      <MarketplaceForm
                        onSubmit={values =>
                          editMarketplace({
                            variables: { ...values },
                          })
                        }
                        initialValues={initialValues}
                        awsOptions={awsOptions}
                        validationSchema={validation}
                      />
                    )
                  }}
                </Mutation>
              </Card>
            </Col>

            <Col sm={12} lg={4}>
              <Card>
                <H5>Features</H5>
                {isPlatformLevelUser() && (
                  <Fragment>
                    <H6>Platform Controlled</H6>
                    <Mutation
                      mutation={ALLOW_REVIEWS}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceAllowReviewsForm
                          allowReviews={!!get(marketplace, 'allowReviews')}
                          updateStatus={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>
                    <Mutation
                      mutation={ALLOW_MARKETING}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceAllowMarketingForm
                          allowMarketing={!!get(marketplace, 'allowMarketing')}
                          updateStatus={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>

                    <Mutation
                      mutation={ENABLE_ANALYTICS}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceEnableAnalyticsForm
                          enableAnalytics={
                            !!get(marketplace, 'enableAnalytics')
                          }
                          updateStatus={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>
                    <Mutation
                      mutation={ENABLE_CUSTOMER_V2_CLIENT}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceEnableCustomerV2Client
                          enableCustomerV2Client={
                            !!get(marketplace, 'enableCustomerV2Client')
                          }
                          updateStatus={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>
                    <br />
                    <H6>Premium Features</H6>
                    <Mutation
                      mutation={ALLOW_BILLING}
                      onError={defaultErrorHandler}
                      refetchQueries={[
                        {
                          query: GET_MARKETPLACE_BILLING,
                          variables: { id: marketplaceId },
                        },
                      ]}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceAllowBilling
                          allowBilling={!!get(marketplace, 'allowBilling')}
                          updateStatus={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>
                    <Mutation
                      mutation={ALLOW_SMS_MARKETING}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceAllowSMSMarketing
                          allowSMSMarketing={
                            !!get(marketplace, 'allowSMSMarketing')
                          }
                          disabled={!get(marketplace, 'allowBilling')}
                          updateStatus={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>
                    <Mutation
                      mutation={ALLOW_FACEBOOK_MARKETING}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceAllowFacebookMarketing
                          allowFacebookMarketing={
                            !!get(marketplace, 'allowFacebookMarketing')
                          }
                          disabled={!get(marketplace, 'allowBilling')}
                          updateStatus={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>
                    <br />
                    <H6>Marketplace Controlled</H6>
                  </Fragment>
                )}

                {canPerformAction('editMarketplace') ? (
                  <Fragment>
                    <Query
                      query={GET_TABLE_SERVICE_OUTLETS}
                      variables={{
                        marketplaceIds: [marketplaceId],
                      }}
                    >
                      {({ getOutlets }) => (
                        <Fragment>
                          <Mutation
                            mutation={ALLOW_ORDER_TO_TABLE}
                            onError={defaultErrorHandler}
                            onCompleted={({ editMarketplace }) =>
                              successToast(editMarketplace.message)
                            }
                          >
                            {editMarketplace => (
                              <MarketplaceAllowOrderToTableForm
                                allowOrderToTable={
                                  !!get(marketplace, 'allowOrderToTable')
                                }
                                allowedToChange={!getOutlets.outlets.length}
                                updateStatus={values =>
                                  !getOutlets.outlets.length
                                    ? editMarketplace({
                                        variables: {
                                          ...values,
                                          id: marketplaceId,
                                        },
                                      })
                                    : setShowTableOrderingAlert(true)
                                }
                              />
                            )}
                          </Mutation>
                          <Alert
                            isOpen={showTableOrderingAlert}
                            onClose={() => setShowTableOrderingAlert(false)}
                          >
                            <h5 className="bp3-heading">Table ordering</h5>
                            <p>
                              Unable to deactivate table ordering while it is
                              enabled on the following outlets:
                            </p>

                            <ul>
                              {getOutlets.outlets.map(outlet => (
                                <li key={outlet.id}>
                                  <a
                                    href={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/tables`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {outlet.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </Alert>
                        </Fragment>
                      )}
                    </Query>
                    <Mutation
                      mutation={ALLOW_ONBOARDING}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceAllowOnboardingForm
                          allowOnboarding={
                            !!get(marketplace, 'allowOnboarding')
                          }
                          updateStatus={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>
                    <Mutation
                      mutation={ENABLE_PAYBOX_ENTERPRISE}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceEnablePayboxEnterprise
                          disabled={
                            (marketplace.stripeEnterpriseId &&
                              marketplace.enablePayboxEnterprise) ||
                            !marketplace.partner.stripeEnterpriseId
                          }
                          enablePayboxEnterprise={
                            !!get(marketplace, 'enablePayboxEnterprise')
                          }
                          updateStatus={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>
                    <br />
                    <H5>Settings</H5>
                    <Mutation
                      mutation={ENABLE_CONFIRM_ADDRESS_AT_PAYMENT}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceEnableConfirmAddressAtPaymentForm
                          confirmAddressAtPayment={
                            !!get(marketplace, 'confirmAddressAtPayment')
                          }
                          updateStatus={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>
                    <Mutation
                      mutation={ALLOW_SINGLE_ITEM_ORDER_NOTES}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceAllowSingleItemOrderNotesForm
                          allowSingleItemOrderNotes={
                            !!get(marketplace, 'allowSingleItemOrderNotes')
                          }
                          updateStatus={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>
                    <Mutation
                      mutation={ALLOW_EXTENDED_PREORDERS}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceAllowExtendedPreorders
                          allowExtendedPreorders={
                            !!get(marketplace, 'allowExtendedPreorders')
                          }
                          updateStatus={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>

                    <Mutation
                      mutation={ALLOW_MULTI_OUTLET_TERMINAL}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplace => (
                        <MarketplaceAllowMultiOutletTerminal
                          allowMultiOutletTerminal={get(
                            marketplace,
                            'allowMultiOutletTerminal'
                          )}
                          updateField={values =>
                            editMarketplace({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>

                    {isPlatformLevelUser() && (
                      <Mutation
                        mutation={ALLOW_PENDING_ORDER_ALERT}
                        onError={defaultErrorHandler}
                        onCompleted={({ editMarketplace }) =>
                          successToast(editMarketplace.message)
                        }
                      >
                        {editMarketplace => (
                          <MarketplaceAllowPendingOrderAlertSwitch
                            allowPendingOrderAlert={Boolean(
                              marketplace.allowPendingOrderAlert
                            )}
                            updateField={values =>
                              editMarketplace({
                                variables: {
                                  ...values,
                                  id: marketplaceId,
                                },
                              })
                            }
                          />
                        )}
                      </Mutation>
                    )}

                    {isPlatformLevelUser() && (
                      <Mutation
                        mutation={ALLOW_OTTER_INTEGRATION}
                        onError={defaultErrorHandler}
                        onCompleted={({ editMarketplace }) =>
                          successToast(editMarketplace.message)
                        }
                      >
                        {editMarketplace => (
                          <MarketplaceAllowOtterIntegrationSwtich
                            allowOtterIntegration={Boolean(
                              marketplace.allowOtterIntegration
                            )}
                            updateField={values =>
                              editMarketplace({
                                variables: {
                                  ...values,
                                  id: marketplaceId,
                                },
                              })
                            }
                          />
                        )}
                      </Mutation>
                    )}
                  </Fragment>
                ) : null}
              </Card>
              <Card>
                <h5 className="bp3-heading">Categories</h5>
                <AllowedCategories
                  marketplaceId={marketplaceId}
                  initialCategories={get(marketplace, 'allowedCuisines', [])}
                  optInAllowedCategories={get(
                    marketplace,
                    'optInAllowedCuisines',
                    false
                  )}
                  categoryEmojiOrImage={marketplace.categoryEmojiOrImage}
                />
              </Card>
              {isAtLeastMarketplaceAdmin() && (
                <Fragment>
                  <Card>
                    <h5 className="bp3-heading">Receipt Settings</h5>
                    <Mutation
                      mutation={EDIT_MARKETPLACE_TERMINAL}
                      onError={defaultErrorHandler}
                      onCompleted={({ editMarketplace }) =>
                        successToast(editMarketplace.message)
                      }
                    >
                      {editMarketplaceTerminal => (
                        <TerminalSettingsForm
                          initialValues={marketplace}
                          onSubmit={values =>
                            editMarketplaceTerminal({
                              variables: {
                                ...values,
                                id: marketplaceId,
                              },
                            })
                          }
                        />
                      )}
                    </Mutation>
                  </Card>
                  <CollectionCard
                    marketplaceId={marketplaceId}
                    outletVisibilityRadiusKM={
                      marketplace.outletCollectionVisibilityRadiusKM
                    }
                  />
                </Fragment>
              )}
              {isPlatformLevelUser() ? (
                <ArchiveMarketplace
                  id={marketplaceId}
                  name={marketplace.name}
                />
              ) : null}
            </Col>
          </Row>
        )
      }}
    </Query>
  )
}

Details.propTypes = {
  marketplaceId: string.isRequired,
}
export default Details

import { Button, Classes, Intent, Spinner } from '@blueprintjs/core'
import Query from '@components/Query/Query'
import { Formik } from 'formik'
import React, { Fragment } from 'react'
import { OtterConnectionFound, OtterConnectionMissing } from './OtterConnection'
import { OtterFetch } from './OtterFetch'
import { GET_OUTLET_BY_OTTER_STORE_ID } from './queries/getOutletByOtterStoreId.query'
import OutletSelect from '@components/OutletSelect/OutletSelect'
import { Mutation } from 'react-apollo'
import { EDIT_OUTLET_OTTER_STORE_ID } from './mutations/editOutletOtterStoreId.mutation'

export const OtterStore = ({ store, brandId, restaurantId }) => (
  <div className={Classes.DIALOG_BODY}>
    <h3>{store.name}</h3>
    <OtterFetch path={`/organization/brands/${brandId}/stores/${store.id}`}>
      {data => (
        <Fragment>
          <p>Store ID: {data.id}</p>
          <p>Address: {data.address.fullAddress}</p>
        </Fragment>
      )}
    </OtterFetch>
    <OtterFetch
      ErrorComponent={({ error }) => (
        <OtterConnectionMissing error={error} brandId={brandId} store={store} />
      )}
      path={`/organization/brands/${brandId}/stores/${store.id}/connection`}
    >
      {data =>
        data && <OtterConnectionFound brandId={brandId} storeId={store.id} />
      }
    </OtterFetch>
    <br />
    <br />
    <Query
      query={GET_OUTLET_BY_OTTER_STORE_ID}
      variables={{ storeId: store.id }}
      fetchPolicy="network-only"
      showLoader
    >
      {data => {
        if (!data) return null

        const outletId =
          data.getOutletByOtterStoreId && data.getOutletByOtterStoreId.id

        return (
          <Mutation mutation={EDIT_OUTLET_OTTER_STORE_ID}>
            {(editOutletOtterStoreId, { loading, data: saved = null }) => (
              <Formik
                initialValues={{
                  outletId,
                }}
                enableReinitialize
                onSubmit={values => {
                  editOutletOtterStoreId({
                    variables: {
                      id: values.outletId,
                      otterStoreId: store.id,
                    },
                  })
                }}
              >
                {({ values, submitForm, setFieldValue }) => (
                  <form>
                    <OutletSelect
                      onChange={id => {
                        setFieldValue('outletId', id)
                      }}
                      showMarketplaceName={false}
                      outletId={values.outletId}
                      restaurantId={restaurantId}
                      showRestaurantName
                    />

                    <Button
                      onClick={submitForm}
                      intent={Intent.PRIMARY}
                      text={saved ? 'Saved' : 'Save'}
                      disabled={loading || saved}
                      rightIcon={
                        loading && <Spinner size={Spinner.SIZE_SMALL} />
                      }
                    />
                  </form>
                )}
              </Formik>
            )}
          </Mutation>
        )
      }}
    </Query>
  </div>
)

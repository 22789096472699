import React from 'react'
import { Mutation } from 'react-apollo'
import { Drawer, DrawerSize } from '@blueprintjs/core'
import { Formik } from 'formik'
import { first, omit, pick } from 'lodash'
import { modalType } from '@utils/types'
import { string } from 'prop-types'
import { successToast } from '@utils/toast'
import { numberToPence, penceToPounds } from '@utils/helpers'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import EDIT_DELIVERY_NETWORK from './mutations/editDeliveryNetwork.mutation'
import GET_DELIVERY_NETWORKS from '@components/Marketplace/DeliveryNetworks/queries/getDeliveryNetworks.query'
import Query from '@components/Query/Query'
import DeliveryNetworkForm from './DeliveryNetworkForm'
import { DELIVERY_PROVIDER_TO_CHARGE_RECIPIENT } from './shared'

const EditDeliveryNetworkModal = ({
  modalOpen,
  modalClose,
  marketplaceId,
  deliveryNetworkId,
}) => (
  <Drawer
    isOpen={modalOpen}
    onClose={modalClose}
    title="Edit Delivery Network"
    size={DrawerSize.SMALL}
  >
    <Query
      query={GET_DELIVERY_NETWORKS}
      variables={{ marketplaceId, id: deliveryNetworkId }}
    >
      {({ getDeliveryNetworks: { deliveryNetworks } }) => {
        if (deliveryNetworks && deliveryNetworks.length) {
          const deliveryNetwork = first(deliveryNetworks)
          return (
            <Mutation
              mutation={EDIT_DELIVERY_NETWORK}
              refetchQueries={[
                {
                  query: GET_DELIVERY_NETWORKS,
                  variables: {
                    marketplaceId,
                  },
                },
              ]}
              onCompleted={({ editDeliveryNetwork }) => {
                successToast(
                  editDeliveryNetwork.message || 'Delivery network updated.'
                )
                modalClose()
              }}
              onError={defaultErrorHandler}
            >
              {editDeliveryNetwork => {
                const initialValues = {
                  marketplaceId,
                  deliveryProvider: deliveryNetwork.deliveryProvider,
                  name: deliveryNetwork.name,
                  helpPhone: deliveryNetwork.helpPhone || '',
                  namePrefix: deliveryNetwork.namePrefix || '',
                  subsidiseDelivery: deliveryNetwork.fixedSubsidy !== null,
                  fixedSubsidy:
                    deliveryNetwork.fixedSubsidy !== null
                      ? penceToPounds(deliveryNetwork.fixedSubsidy)
                      : 0,
                  ...deliveryNetwork.apiCredentials,
                  deliveryChargeRecipient:
                    deliveryNetwork.deliveryChargeRecipient ||
                    DELIVERY_PROVIDER_TO_CHARGE_RECIPIENT[
                      deliveryNetwork.deliveryProvider
                    ],
                  deliveryNetworkType: deliveryNetwork.deliveryNetworkType,
                  uberDirectTestScenario:
                    deliveryNetwork.uberDirectTestScenario,
                }
                return (
                  <Formik
                    initialValues={initialValues}
                    onSubmit={values => {
                      // get keys of non-apiCredentials fields which have changed
                      const baseAlteredKeys = Object.keys(
                        omit(initialValues, [
                          'apiClientId',
                          'apiSecret',
                          'apiURL',
                          'apiKey',
                          'subsidiseDelivery',
                          'fixedSubsidy',
                          'uberDirectCustomerId',
                          'uberDirectClientId',
                          'uberDirectClientSecret',
                          'uberDirectSigningKey',
                        ])
                      ).filter(key => initialValues[key] !== values[key])
                      // get keys of apiCredentials fields which have changed
                      const alteredAPICredKeys = Object.keys(
                        deliveryNetwork.apiCredentials
                      ).filter(
                        key =>
                          deliveryNetwork.apiCredentials[key] !== values[key]
                      )

                      // calculate fixed subsidy to either:
                      // * undefined = no change
                      // * null = remove subsidy
                      // * number = set new subsidy value
                      let fixedSubsidy
                      if (
                        !values.subsidiseDelivery &&
                        initialValues.subsidiseDelivery
                      ) {
                        fixedSubsidy = null
                      } else if (
                        values.subsidiseDelivery &&
                        values.fixedSubsidy !== initialValues.fixedSubsidy
                      ) {
                        fixedSubsidy = numberToPence(values.fixedSubsidy)
                      }
                      const variables = {
                        id: deliveryNetworkId,
                        ...pick(values, baseAlteredKeys),
                        ...(fixedSubsidy !== undefined && {
                          fixedSubsidy,
                        }),
                        ...(values.deliveryProvider === 'STUART' && {
                          ...(alteredAPICredKeys.includes('apiClientId') && {
                            apiClientId: values.apiClientId,
                          }),
                          ...(alteredAPICredKeys.includes('apiSecret') && {
                            apiSecret: values.apiSecret,
                          }),
                        }),
                        ...(values.deliveryProvider === 'WAYBOX' && {
                          ...(alteredAPICredKeys.includes('apiURL') && {
                            apiURL: values.apiURL,
                          }),
                          ...(alteredAPICredKeys.includes('apiKey') && {
                            apiKey: values.apiKey,
                          }),
                        }),
                        ...(values.deliveryProvider === 'UBER_DIRECT' && {
                          ...(alteredAPICredKeys.includes(
                            'uberDirectCustomerId'
                          ) && {
                            uberDirectCustomerId: values.uberDirectCustomerId,
                          }),
                        }),
                        ...(values.deliveryProvider === 'UBER_DIRECT' && {
                          ...(alteredAPICredKeys.includes(
                            'uberDirectClientId'
                          ) && {
                            uberDirectClientId: values.uberDirectClientId,
                          }),
                        }),
                        ...(values.deliveryProvider === 'UBER_DIRECT' && {
                          ...(alteredAPICredKeys.includes(
                            'uberDirectClientSecret'
                          ) && {
                            uberDirectClientSecret:
                              values.uberDirectClientSecret,
                          }),
                        }),
                        ...(values.deliveryProvider === 'UBER_DIRECT' && {
                          ...(alteredAPICredKeys.includes(
                            'uberDirectSigningKey'
                          ) && {
                            uberDirectSigningKey: values.uberDirectSigningKey,
                          }),
                        }),
                      }

                      return editDeliveryNetwork({
                        variables,
                      })
                    }}
                  >
                    {props => <DeliveryNetworkForm {...props} isEditing />}
                  </Formik>
                )
              }}
            </Mutation>
          )
        }
      }}
    </Query>
  </Drawer>
)

EditDeliveryNetworkModal.propTypes = {
  ...modalType,
  marketplaceId: string,
}

export default EditDeliveryNetworkModal

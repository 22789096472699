import gql from 'graphql-tag'

export default gql`
  query reconcile(
    $afterDate: DateTime!
    $beforeDate: DateTime!
    $partnerIds: [ID!]
    $showManualCash: Boolean
  ) {
    reconcile(
      acceptedAt_gte: $afterDate
      acceptedAt_lte: $beforeDate
      partnerIds: $partnerIds
      showManualCash: $showManualCash
    ) {
      id
      type
      transactionStripeId
      refundedAt
      acceptedAt
      totalToOutlet
      totalToMarketplace
      totalToPartner
      totalToPaybox
      totalToRedbox
      totalPaidToOutlet
      totalPaidToMarketplace
      totalPaidToPartner
      totalPaidToPaybox
      totalPaidToRedbox
      reconciliationResolutionType
      reconciliationNotes
      createdAt
      order {
        id
        orderNumber
      }
      marketplace {
        id
        name
        stripeId
        stripeEnterpriseId
        stripeDirectPayment
      }
      outlet {
        id
        name
        active
        isOnline
      }
      restaurant {
        id
        name
        active
      }
    }
  }
`

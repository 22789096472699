import gql from 'graphql-tag'

const LOGOUT = gql`
  mutation {
    logoutUser {
      blacklistedTokenId
    }
  }
`
export default LOGOUT

import React, { useState, useContext, useEffect } from 'react'
import {
  HTMLTable,
  Card,
  NonIdealState,
  ControlGroup,
  ButtonGroup,
  Button,
} from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import Currency from '@components/Currency/Currency'
import DateTime from '@components/DateTime/DateTime'
import { Pager, Search, MarketplaceFilter } from '@components/Toolbar'
import GET_CUSTOMERS from '@components/Customers/queries/getCustomers.query'
import { get } from 'lodash'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import FilterRow from '@components/FilterRow/FilterRow'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import { canView } from '../../stores/userStore'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { getCustomerSearchIntent } from './CustomerSearchRegex'
import SortBy from '../Toolbar/SortBy'
import { useSearchQueryParam } from '../Toolbar/Search/useSearchQueryParam'
import { useRoleAwareBusinessFilterQueryParams } from '../Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'

const tableHead = [
  {
    key: 'firstName',
    content: 'Name',
    sortable: true,
  },
  {
    key: 'orders',
    content: 'Orders',
  },
  {
    key: 'totalValue',
    content: 'Total',
  },
  {
    key: 'email',
    content: 'Email',
  },
  {
    key: 'location',
    content: 'Location',
  },
  {
    key: 'marketplaceName',
    content: 'Marketplace',
  },
  {
    key: 'createdAt',
    content: 'Registered',
    sortable: true,
  },
  {
    key: 'lastOrderAt',
    content: 'Last Order',
  },
  {
    key: 'reviews',
    content: 'Reviews',
  },
  {
    key: 'logins',
    content: 'Failed Logins',
  },
]

const DEFAULT_RECORDS = 25
const DEFAULT_STATE = {
  variables: {
    skip: 0,
    first: DEFAULT_RECORDS,
    last: null,
  },
  total: 0,
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  navigationDisabled: false,
}

const CustomersList = () => {
  const [orderBy, onChangeOrderBy] = useQueryParam(
    'orderBy',
    withDefault(StringParam, 'createdAt_DESC')
  )
  const { dark } = useContext(PageLayoutContext)
  const [state, setState] = useState(DEFAULT_STATE)
  const { searchValue, resetSearch } = useSearchQueryParam()
  useEffect(() => {
    if (searchValue.length) {
      setState(DEFAULT_STATE)
    }
  }, [searchValue, setState])
  const { marketplaceIds, onChangeMarketplaceIds } =
    useRoleAwareBusinessFilterQueryParams()

  const setTotalCount = (totalCount, returnCount) => {
    if (state.total !== totalCount) {
      setState(prevState => ({ ...prevState, total: totalCount }))
    }
    if (state.outcomeLength !== returnCount) {
      setState(prevState => ({ ...prevState, outcomeLength: returnCount }))
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < state.defaultNmbRecords ? currentCursor : skip
  }

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (state.variables.skip + state.variables.first < state.total) {
      setState(prevState => ({
        ...prevState,
        variables: {
          skip: limitNext(
            prevState.variables.skip,
            prevState.variables.first,
            limit
          ),
          first: prevState.defaultNmbRecords,
          last: null,
        },
      }))
    }
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const goToPrevious = e => {
    e.preventDefault()
    setState(prevState => ({
      ...prevState,
      variables: {
        skip: limitPrevious(
          prevState.variables.skip,
          prevState.variables.first
        ),
        first: prevState.defaultNmbRecords,
        last: null,
      },
    }))
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setState(prevState => ({
      ...prevState,
      variables: {
        skip: numberToSkip,
        first: DEFAULT_RECORDS,
        last: null,
      },
    }))
  }

  const noCustomersMessage = () => {
    const query = searchValue
    const intent = getCustomerSearchIntent(query)
    switch (intent) {
      case 'PHONE':
        return (
          <div>
            <p>
              No customers found with the phone number '<b>{query}</b>'.
            </p>
            <p>
              Please specify a full phone number, starting with <b>0</b> or{' '}
              <b>+44</b>.
            </p>
            <p>
              You can also search by email address, stripe customer Id, or start
              of postcode.
            </p>
          </div>
        )

      case 'EMAIL':
        return (
          <div>
            <div>
              No customers found with the email address <b>{query}</b>.
            </div>
            <div>Please specify a full email address.</div>
            <p>
              You can also search by phone number, stripe customer Id, or start
              of postcode.
            </p>
          </div>
        )

      case 'POSTCODE':
        return (
          <div>
            <div>
              No customers found with any post codes that start with{' '}
              <b>{query}</b>.
            </div>
            <p>
              You can also search by phone number, stripe customer Id, or email
              address.
            </p>
          </div>
        )

      case 'STRIPE':
        return (
          <div>
            <div>
              No customers found with stripe Id matching <b>{query}</b>.
            </div>
            <p>
              You can also search by phone number, post code, or email address.
            </p>
          </div>
        )

      default:
        return (
          <div>
            <div>
              No customers found matching <b>{query}</b>.
            </div>
            <p>
              You must search by phone number, post code, email address, or
              start of post code.
            </p>
          </div>
        )
    }
  }

  const searchIntent = () => {
    const query = searchValue
    const searchIntent = getCustomerSearchIntent(query)
    switch (searchIntent) {
      case 'PHONE':
        return { phoneNumber: query }
      case 'EMAIL':
        return { email: query }
      case 'POSTCODE':
        return { postcodePartial: query }
      case 'STRIPE':
        return { stripeCustomerId: query }
      default:
        return ''
    }
  }

  return (
    <div className="bp3-table-frame">
      <FilterRow>
        <ButtonGroup>
          <ControlGroup>
            <Search autoFocus placeholder="Email, Phone Number, Postcode..." />
            <MarketplaceFilter />
          </ControlGroup>
        </ButtonGroup>
        <Pager
          goToPrevious={e => goToPrevious(e)}
          goToNext={e => goToNext(e)}
          goToPage={goToPage}
          defaultNmbRecords={DEFAULT_RECORDS}
          skip={state.variables.skip}
          total={state.total}
          outcomeLength={state.outcomeLength}
          totalCount={state.total}
          dataName="Customers"
        />
      </FilterRow>
      <DebouncedQuery
        query={GET_CUSTOMERS}
        loaderTitle="Loading Customers"
        variables={{
          marketplaceIds,
          ...state.variables,
          ...searchIntent(),
          orderBy,
        }}
        fetchPolicy="network-only"
      >
        {({ customers: { customers, count, totalCount } }) => {
          if (customers.length) {
            setTotalCount(totalCount, count)
            return (
              <div className="bp3-table-container bp3-scrollable">
                <Card className="bp3-nopad">
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        {tableHead.map(({ key, content, sortable }) => {
                          if (!sortable) return <th key={key}>{content}</th>
                          return (
                            <th key={key}>
                              <SortBy
                                title={content}
                                selected={orderBy}
                                orderBy={key}
                                setSelected={(updatedOrderBy, orderDirection) =>
                                  onChangeOrderBy(
                                    orderDirection === 'NONE'
                                      ? undefined
                                      : `${updatedOrderBy}_${orderDirection}`
                                  )
                                }
                              />
                            </th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {customers.map(customer => (
                        <tr key={customer.id}>
                          <td>
                            <Link to={`/customer/${customer.id}`}>
                              {customer.firstName} {customer.lastName}
                            </Link>
                          </td>
                          <td
                            style={
                              customer.totalCompletedOrders > 0
                                ? { color: 'black' }
                                : { color: '#CDD6DD' }
                            }
                          >
                            <Link to={`/customer/${customer.id}/orders`}>
                              {customer.totalCompletedOrders}
                            </Link>
                          </td>
                          <td
                            style={
                              dark
                                ? customer.totalCompletedOrders > 0
                                  ? { color: '#CDD6DD' }
                                  : { color: '#8A9BA833' }
                                : customer.totalCompletedOrders > 0
                                ? { color: 'black' }
                                : { color: '#CDD6DD' }
                            }
                          >
                            <Currency amount={customer.totalValue} />
                          </td>
                          <td>{customer.email}</td>
                          <td>
                            {get(customer.deliveryAddress, '[0].city', '')}{' '}
                            {get(customer.deliveryAddress, '[0].postcode', '')}
                          </td>
                          <td>
                            {canView('marketplaces') ? (
                              <Link
                                to={`/marketplaces/${customer.marketplace.id}`}
                              >
                                {customer.marketplace.name}
                              </Link>
                            ) : (
                              customer.marketplace.name
                            )}
                          </td>
                          <td>
                            <DateTime
                              dateTime={customer.createdAt}
                              hideDayIfSame={false}
                            />
                          </td>
                          <td>
                            {!customer.lastOrderAt ? (
                              <em
                                style={
                                  dark
                                    ? { color: '#8A9BA833' }
                                    : { color: '#CDD6DD' }
                                }
                              >
                                Never
                              </em>
                            ) : (
                              <DateTime
                                dateTime={customer.lastOrderAt}
                                timeAgo
                              />
                            )}
                          </td>
                          <td
                            style={
                              get(customer.reviews, 'length', 0) > 0
                                ? { color: 'black' }
                                : { color: '#CDD6DD' }
                            }
                          >
                            <Link to={`/customer/${customer.id}/reviews`}>
                              {get(customer.reviews, 'length', 0)}
                            </Link>
                          </td>
                          <td
                            style={
                              customer.failedAttempts > 0
                                ? { color: 'red' }
                                : { color: '#CDD6DD' }
                            }
                          >
                            {customer.failedAttempts || 0}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </HTMLTable>
                </Card>
              </div>
            )
          } else {
            return marketplaceIds.length || searchValue ? (
              <NonIdealState
                icon="mugshot"
                title="No Customers Found"
                description={noCustomersMessage()}
                action={
                  <Button
                    onClick={() => {
                      onChangeMarketplaceIds(undefined)
                      resetSearch()
                    }}
                    minimal
                    intent="primary"
                  >
                    Clear Filters
                  </Button>
                }
              />
            ) : (
              <NonIdealState
                icon="mugshot"
                title="Waiting For Customers"
                description="Your registered customers will be displayed here."
              />
            )
          }
        }}
      </DebouncedQuery>
    </div>
  )
}

export default CustomersList

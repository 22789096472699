import React from 'react'
import Form from '@components/TerminalForm/Form'
import { Formik } from 'formik'
import { func, object, bool, string } from 'prop-types'
import injectSheet from 'react-jss'

const styles = () => ({
  leftFooterButton: {
    marginRight: 'auto',
    marginLeft: '0px !important',
  },
})

const TerminalForm = ({
  onSubmit,
  initialValues,
  validationSchema,
  edit,
  restaurantId,
  outlet,
  classes,
  queryVariables,
}) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{ ...initialValues, edit }}
    validationSchema={validationSchema}
  >
    {props => (
      <Form
        {...props}
        edit={edit}
        classes={classes}
        restaurantId={restaurantId}
        outlet={outlet}
        queryVariables={queryVariables}
      />
    )}
  </Formik>
)

TerminalForm.propTypes = {
  onSubmit: func.isRequired,
  initialValues: object,
  edit: bool,
  classes: object,
  restaurantId: string.isRequired,
  outlet: string,
}

export default injectSheet(styles)(TerminalForm)

import React, { Fragment } from 'react'
import { Formik } from 'formik'
import { Mutation, Query as CacheQuery } from 'react-apollo'
import {
  Card,
  HTMLTable,
  Switch,
  Icon,
  AnchorButton,
  NonIdealState,
  Button,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { string, arrayOf, bool, shape } from 'prop-types'

import { toggleModal } from '@utils/cacheHelpers'
import OPEN_EDIT_TABLE_FRIENDLY_NAME_MODAL from '@components/Outlet/Tables/queries/openEditTableFriendlyNameModal.clientQuery'
import EDIT_TABLE_ACTIVATION_STATUS from '@components/Outlet/Tables/mutations/editTableActivationStatus.mutation'
import EditTableFriendlyNameModal from '@components/TableModal/EditTableFriendlyNameModal'
import config from '@config/config'
import colors from '@styles/colors'

const tableHead = [
  { key: 'friendlyName', content: 'Table Name / Number' },
  { key: 'active', content: 'Active' },
  { key: 'qrCode', content: 'Table Service QR Code' },
]

const TableList = ({ tables, outletId }) => {
  let tableBeingEdited = {}
  if (tables.length > 0) {
    return (
      <CacheQuery query={OPEN_EDIT_TABLE_FRIENDLY_NAME_MODAL}>
        {({ data: { editTableFriendlyNameModal } }) => (
          <Fragment>
            <Card className={'bp3-nopad'}>
              <HTMLTable bordered={false} interactive={true}>
                <thead>
                  <tr>
                    {tableHead.map(({ key, content }) => (
                      <th key={key}>{content}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tables.map(table => (
                    <tr key={table.id}>
                      <td>
                        <Icon
                          icon="symbol-circle"
                          color={
                            table.active
                              ? colors.onlineGreen
                              : colors.offlineRed
                          }
                        />
                        &nbsp;
                        <a
                          onClick={() => {
                            tableBeingEdited = table
                            toggleModal({
                              editTableFriendlyNameModal: true,
                            })
                          }}
                        >
                          {table.friendlyName}
                        </a>
                      </td>
                      <td>
                        <Mutation
                          mutation={EDIT_TABLE_ACTIVATION_STATUS}
                          onError={defaultErrorHandler}
                          onCompleted={({
                            editTableActivation: { active: isActive },
                          }) =>
                            successToast(
                              `Table ${table.friendlyName} ${
                                isActive ? 'Activated' : 'Deactivated'
                              }.`
                            )
                          }
                        >
                          {editTableActivationStatus => (
                            <Formik initialValues={{ active: table.active }}>
                              {({ values, handleChange }) => (
                                <Switch
                                  label={'Active'}
                                  checked={values.active}
                                  onChange={e => {
                                    handleChange(e)
                                    editTableActivationStatus({
                                      variables: {
                                        tableId: table.id,
                                        isActive: e.currentTarget.checked,
                                      },
                                    })
                                  }}
                                  name="active"
                                />
                              )}
                            </Formik>
                          )}
                        </Mutation>
                      </td>
                      <td>
                        <AnchorButton
                          href={`${config.apiUrl}/api/${outletId}/table/generate-qr?table=${table.id}&friendly=${table.friendlyName}`}
                          target="_blank"
                          icon="print"
                          minimal
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </HTMLTable>
            </Card>

            <EditTableFriendlyNameModal
              modalOpen={editTableFriendlyNameModal}
              modalClose={() =>
                toggleModal({
                  editTableFriendlyNameModal: false,
                })
              }
              table={tableBeingEdited}
              outletId={outletId}
            />
          </Fragment>
        )}
      </CacheQuery>
    )
  } else {
    return (
      <Card>
        <div style={{ padding: '100px' }}>
          <NonIdealState
            icon="list"
            title="No Tables"
            description="There are no available tables for this outlet."
            action={
              <Button
                icon="plus"
                onClick={() =>
                  toggleModal({
                    addTableModal: true,
                  })
                }
              >
                Add Table
              </Button>
            }
          />
        </div>
      </Card>
    )
  }
}

TableList.propTypes = {
  outletId: string.isRequired,
  tables: arrayOf(
    shape({
      id: string.isRequired,
      friendlyName: string.isRequired,
      active: bool.isRequired,
    })
  ),
}

export default TableList

import React, { Fragment } from 'react'
import { Mutation } from 'react-apollo'
import { Formik } from 'formik'
import { first, map } from 'lodash'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { Button, Card, Intent, NonIdealState } from '@blueprintjs/core'
import GeoFence from '@components/GeoJSON/GeoFence'
import GeoOutletRadii from '@components/GeoJSON/GeoOutletRadii'

import GET_DELIVERY_ZONE from '@components/DeliveryZone/queries/getDeliveryZone.query'
import EDIT_DELIVERY_ZONE from '@components/DeliveryZone/mutations/editDeliveryZone.mutation'
import EDIT_DELIVERY_ZONE_GEOFENCE from '@components/DeliveryZone/mutations/editDZGeoFence.mutation'
import GET_DELIVERY_ZONES from '@components/Marketplace/DeliveryZones/queries/getDeliveryZones.query'

import Query from '@components/Query/Query'
import Form from '@components/DeliveryZone/Form'
import { editDZvalidation } from './validation'
import DELETE_DELIVERY_ZONE from './mutations/deleteDeliveryZone.mutation'
import { Link } from 'react-router-dom'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'

const DeliveryZone = ({ id, marketplaceId, onChangeViewZone }) => {
  return (
    <Query
      query={GET_DELIVERY_ZONE}
      variables={{ id }}
      loaderTitle="Loading Delivery Zone"
    >
      {({ getDeliveryZones: { deliveryZones } }) => {
        const deliveryZone = first(deliveryZones)
        const { marketplace, outletDeliveryZoneCosts } = deliveryZone
        const outlets = map(outletDeliveryZoneCosts, 'outlet')

        if (!deliveryZone) {
          return (
            <NonIdealState icon="error" title="Unable to Find Delivery Zone" />
          )
        } else {
          return (
            <div>
              <Card className="bp3-nopad">
                <Mutation
                  mutation={EDIT_DELIVERY_ZONE_GEOFENCE}
                  onCompleted={({ editDeliveryZone }) => {
                    successToast(editDeliveryZone.message)
                  }}
                  onError={defaultErrorHandler}
                >
                  {editDeliveryZone => {
                    let { geoFence } = deliveryZone
                    const { deliveryZoneType } = deliveryZone

                    if (geoFence && !geoFence.coordinates) {
                      geoFence = null // safety net
                    }

                    if (deliveryZoneType === 'RADIUS_AROUND_OUTLET') {
                      return (
                        <GeoOutletRadii
                          outlets={outlets}
                          center={marketplace.geoFence.center}
                        />
                      )
                    }

                    return (
                      <GeoFence
                        geoFence={geoFence}
                        features={[marketplace, ...outlets]}
                        handleUpdate={() => null}
                        setFieldValue={(e, geoFence) => {
                          editDeliveryZone({
                            variables: {
                              geoFence,
                              id,
                            },
                          })
                        }}
                        showDelete={false}
                      />
                    )
                  }}
                </Mutation>
              </Card>
              <div className="bp3-drawer-footer-actions">
                <Mutation
                  mutation={DELETE_DELIVERY_ZONE}
                  onCompleted={({ deleteDeliveryZone }) => {
                    onChangeViewZone(null)
                    successToast(deleteDeliveryZone.message)
                  }}
                  refetchQueries={[
                    { query: GET_DELIVERY_ZONES, variables: { marketplaceId } },
                  ]}
                  onError={defaultErrorHandler}
                >
                  {deleteDeliveryZone => (
                    <div>
                      <ConfirmationPopover
                        remove={() => deleteDeliveryZone({ variables: { id } })}
                        disabled={
                          deliveryZone.outletDeliveryZoneCosts.length > 0
                        }
                        confirmationText={
                          deliveryZone.outletDeliveryZoneCosts.length > 0 ? (
                            <Fragment>
                              <p>
                                This delivery zone is still attached to the
                                following outlets:
                              </p>
                              <ul>
                                {deliveryZone.outletDeliveryZoneCosts.map(
                                  ({ outlet }) => (
                                    <li key={outlet.id}>
                                      <Link
                                        to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/fulfilment`}
                                      >
                                        {outlet.name}
                                      </Link>
                                    </li>
                                  )
                                )}
                              </ul>
                              You must detach this zone from these outlets
                              before you can delete it.
                            </Fragment>
                          ) : (
                            `Are you sure you want to delete this delivery zone?`
                          )
                        }
                      >
                        <Button
                          intent={Intent.DANGER}
                          minimal
                          icon={
                            deliveryZone.outletDeliveryZoneCosts.length > 0
                              ? 'warning-sign'
                              : null
                          }
                        >
                          Delete
                        </Button>
                      </ConfirmationPopover>
                    </div>
                  )}
                </Mutation>
                <Mutation
                  mutation={EDIT_DELIVERY_ZONE}
                  onCompleted={({ editDeliveryZone }) => {
                    successToast(editDeliveryZone.message)
                  }}
                  onError={defaultErrorHandler}
                >
                  {editDeliveryZone => {
                    return (
                      <Formik
                        initialValues={{ ...deliveryZone }}
                        validationSchema={editDZvalidation}
                        onSubmit={values => {
                          editDeliveryZone({
                            variables: {
                              ...values,
                            },
                          })
                        }}
                      >
                        {props => <Form {...props} />}
                      </Formik>
                    )
                  }}
                </Mutation>
              </div>
            </div>
          )
        }
      }}
    </Query>
  )
}

export default DeliveryZone

import React from 'react'
import { Mutation } from 'react-apollo'
import Dialog from '@components/Dialog/Dialog'
import { modalType } from '@utils/types'
import {
  Classes,
  Button,
  Spinner,
  Card,
  NonIdealState,
} from '@blueprintjs/core'
import QUEUE_INVOICE_EMAILS from './mutations/queueInvoiceEmails.mutation'
import QUEUE_INVOICE_EMAIL_COUNT from './queries/queueInvoiceEmailCount.query'
import Query from '../../Query/Query'
import defaultErrorHandler from '../../../utils/defaultErrorHandler'
import { successToast } from '../../../utils/toast'

const QueueEmailsInner = ({ dateFrom, dateTo }) => (
  <Query
    query={QUEUE_INVOICE_EMAIL_COUNT}
    variables={{
      dateFrom,
      dateTo,
      dryRun: true,
    }}
    loaderTitle={'Loading Analytics'}
  >
    {({ queueInvoiceEmailCount, loading, error }) => {
      if (loading) return <Spinner size={Spinner.SIZE_STANDARD} />
      if (error) {
        return (
          <Card>
            <NonIdealState
              icon="error"
              description="Unable to load email queue data"
            />
          </Card>
        )
      }
      if (!queueInvoiceEmailCount) {
        return (
          <p>
            All invoices that can be emailed in this billing period have already
            been queued or sent.
          </p>
        )
      }
      return (
        <p>
          Please confirm that you would like to queue {queueInvoiceEmailCount}{' '}
          invoice email{queueInvoiceEmailCount === 1 ? '' : 's'}.
        </p>
      )
    }}
  </Query>
)

const QueueInvoiceEmailsButton = ({ dateFrom, dateTo, onClose }) => (
  <Mutation
    mutation={QUEUE_INVOICE_EMAILS}
    onError={defaultErrorHandler}
    onCompleted={({ queueInvoiceEmails: emailCount }) => {
      successToast(
        `${emailCount} email${emailCount === 1 ? '' : 's'} queued for sending`
      )
      onClose()
    }}
  >
    {queueInvoiceEmails => {
      return (
        <Button
          dateFrom={dateFrom}
          dateTo={dateTo}
          text="Queue Invoice Emails"
          onClick={() => {
            queueInvoiceEmails({
              variables: {
                dateFrom,
                dateTo,
              },
            })
          }}
        />
      )
    }}
  </Mutation>
)

const QueueInvoiceEmailsDialog = ({
  modalOpen,
  modalClose,
  dateFrom,
  dateTo,
}) => (
  <Dialog isOpen={modalOpen} onClose={modalClose} title="Queue Emails">
    <div className={Classes.DIALOG_BODY}>
      <QueueEmailsInner dateFrom={dateFrom} dateTo={dateTo} />
    </div>
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <QueueInvoiceEmailsButton
          onClose={modalClose}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </div>
    </div>
  </Dialog>
)
QueueInvoiceEmailsDialog.propTypes = modalType

export default QueueInvoiceEmailsDialog

export const getDomain = (environmentName, AWSaccountId) => {
  switch (environmentName) {
    case 'PROD': {
      switch (AWSaccountId) {
        case '191086008577':
          return 'prod2.redbox.systems'
        case '734162251103':
          return 'prod3.redbox.systems'
        case '526378738687':
          return 'prod4.redbox.systems'
        default:
          return 'prod.redbox.systems'
      }
    }
    case 'STAGING': {
      switch (AWSaccountId) {
        case '436195212889':
          return 'staging2.redbox.systems'
        default:
          return 'staging.redbox.systems'
      }
    }

    case 'TEST': {
      switch (AWSaccountId) {
        default:
          return 'test.redbox.systems'
      }
    }
    case 'DEV': {
      switch (AWSaccountId) {
        default:
          return 'dev.redbox.systems'
      }
    }
    default:
      throw new Error('ENVIRONMENT_NAME is not found')
  }
}

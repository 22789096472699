import App from './Dnd/App'
import React, { Fragment } from 'react'
import { DndProvider } from 'react-dnd'
import { GridProvider } from './Dnd/GridContext'
import HTML5Backend from 'react-dnd-html5-backend'

const UploadedImages = ({ images, setImages }) => {
  if (images.uploadImages && images.uploadImages.images) {
    images = images.uploadImages.images
  }

  const deleteItem = src => {
    const updatedArray = images.filter(img => {
      return img.src !== src
    })
    setImages(updatedArray)
  }

  const updateCaption = (src, newCaption) => {
    setImages(items =>
      items.map(i => {
        if (i.src === src) {
          return { ...i, caption: newCaption }
        }
        return i
      })
    )
  }

  return (
    <Fragment>
      {images[0] && (
        <DndProvider backend={HTML5Backend}>
          <GridProvider items={images} setImages={setImages}>
            <App deleteItem={deleteItem} updateCaption={updateCaption} />
          </GridProvider>
        </DndProvider>
      )}
    </Fragment>
  )
}

export default UploadedImages

import {
  Drawer,
  Card,
  Classes,
  Button,
  Intent,
  Spinner,
  NonIdealState,
  Checkbox,
} from '@blueprintjs/core'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import DiscountAnalytics from '@components/Analytics/DiscountAnalytics'
import DISCOUNT_ANALYTICS from '@components/Analytics/queries/discountAnalytics.query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import find from 'lodash/find'
import React, { useMemo, useState } from 'react'
import { Mutation } from 'react-apollo'
import DELETE_MEMBER_DISCOUNT from './mutations/deleteMemberDiscount'
import GET_MEMBER_DISCOUNTS from './queries/getMemberDiscounts.query'
import Query from '@components/Query/Query'
import { transformDiscountsRawData } from '@components/Analytics/util/transformDiscountsRawData'
import NonIdealStateSmaller from '@components/Analytics/NonIdealStateSmaller'
import TOGGLE_DISCOUNT_ACTIVATION from '@components/Discount/mutations/toggle-discount-activattion'
import { canPerformAction } from '@stores/userStore'

const EditMemberDiscountDrawer = ({
  discounts,
  discountId,
  businessId,
  onChangeDiscountId,
  refetchVars,
}) => {
  const [rawData, setRawData] = useState([])
  const memoizedTransformedData = useMemo(
    () => transformDiscountsRawData(rawData),
    [rawData]
  )
  const discount = find(discounts, d => d.id === discountId)
  if (!discount) {
    return null
  }
  const { name, discountPercentage, customerEnrolled, customer } = discount

  return (
    <Drawer
      isOpen={discountId}
      title={`Edit Member Discount`}
      autoFocus
      canEscapeKeyClose
      canOutsideClickClose
      isCloseButtonShown
      lazy
      size={Drawer.SIZE_STANDARD}
      onClose={() => {
        onChangeDiscountId(undefined)
      }}
    >
      <Query
        query={DISCOUNT_ANALYTICS}
        variables={{ discountId, businessId }}
        loaderTitle="Loading Analytics"
      >
        {({ businessOrdersForAnalyticsByDiscount: data, loading, error }) => {
          if (loading) {
            return <Spinner />
          }
          if (error) {
            return (
              <NonIdealState
                icon="error"
                title="Unable to load analytics data"
              />
            )
          }

          if (!data.length) return <NonIdealStateSmaller />

          setRawData(data)
          return (
            <DiscountAnalytics
              {...memoizedTransformedData}
              showCustomerCount={false}
              showFilterRow={false}
            />
          )
        }}
      </Query>
      <Card>
        {customer ? (
          <Card>
            <h3>
              {customer.firstName} {customer.lastName}
            </h3>
            <div>Email: {customer.email}</div>
            <div>Phone: {customer.phoneNumber}</div>
          </Card>
        ) : (
          <Card>
            <h3>Customer not yet registered</h3>
          </Card>
        )}

        <Card>
          <Mutation
            mutation={TOGGLE_DISCOUNT_ACTIVATION}
            refetchQueries={[
              {
                query: GET_MEMBER_DISCOUNTS,
                variables: { ...refetchVars },
              },
            ]}
            onCompleted={data => {
              successToast(data.toggleDiscountActivation.message)
            }}
          >
            {toggleDiscountActivation => (
              <Checkbox
                name="active"
                label="Activate Discount"
                id="active"
                checked={discount.active}
                onChange={() =>
                  toggleDiscountActivation({
                    variables: {
                      id: discountId,
                      active: !discount.active,
                    },
                  })
                }
              />
            )}
          </Mutation>

          <h3>Discount: {name}</h3>
          <div>Discount percentage: {discountPercentage}%</div>
          <div>Status: {customerEnrolled ? 'Active' : 'Inactive'}</div>
        </Card>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {canPerformAction('deleteDiscount') && (
            <Mutation
              mutation={DELETE_MEMBER_DISCOUNT}
              onCompleted={() => {
                successToast('Successfully removed the discount')
                onChangeDiscountId(undefined)
              }}
              onError={err => {
                defaultErrorHandler(err)
                onChangeDiscountId(undefined)
              }}
              refetchQueries={[
                {
                  query: GET_MEMBER_DISCOUNTS,
                  variables: { ...refetchVars },
                },
              ]}
            >
              {deleteDiscount => (
                <ConfirmationPopover
                  remove={() => {
                    deleteDiscount({
                      variables: {
                        id: discountId,
                      },
                    })
                  }}
                  confirmationText="Are you sure you want to delete this discount?"
                >
                  <Button
                    text="Delete"
                    type="button"
                    intent={Intent.DANGER}
                    minimal
                  />
                </ConfirmationPopover>
              )}
            </Mutation>
          )}
        </div>
      </Card>
    </Drawer>
  )
}

export default EditMemberDiscountDrawer

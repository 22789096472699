import React from 'react'
import first from 'lodash/first'
import { Mutation } from 'react-apollo'
import Query from '@components/Query/Query'
import GeoFence from '@components/GeoJSON/GeoFence'
import { string } from 'prop-types'
import { successToast, errorToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import GET_MARKETPLACE_GEOFENCE from './getMarketplaceGeofence.query'
import EDIT_MARKETPLACE_GEOFENCE from './editMarketplaceGeoFence.mutation'
import { isAtLeastPartner } from '@stores/userStore'

const MarketplaceMap = ({ match }) => {
  const { marketplace: marketplaceId } = match.params
  return (
    <Query
      query={GET_MARKETPLACE_GEOFENCE}
      variables={{ id: marketplaceId }}
      loaderTitle={'Loading Map'}
    >
      {({ getMarketplaces: { regions } }) => {
        const marketplace = first(regions)
        if (!marketplace) {
          return 'Unable to find marketplace'
        }

        return (
          <div className="bp3-table-frame">
            <Mutation
              mutation={EDIT_MARKETPLACE_GEOFENCE}
              onCompleted={({ editMarketplace }) =>
                successToast(editMarketplace.message)
              }
              onError={defaultErrorHandler}
            >
              {editMarketplaceGeoFence => {
                const { geoFence } = marketplace

                return (
                  <GeoFence
                    geoFence={geoFence}
                    features={marketplace.deliveryZones}
                    handleUpdate={() => null}
                    disableEdit={!isAtLeastPartner()}
                    showDelete={isAtLeastPartner()}
                    setFieldValue={(e, geoFence) => {
                      if (isAtLeastPartner()) {
                        editMarketplaceGeoFence({
                          variables: {
                            geoFence: geoFence,
                            id: marketplaceId,
                          },
                        })
                      } else {
                        errorToast(
                          'You do not have permission to perform this action.'
                        )
                      }
                    }}
                  />
                )
              }}
            </Mutation>
          </div>
        )
      }}
    </Query>
  )
}

MarketplaceMap.propTypes = {
  marketplaceId: string.isRequired,
}

export default MarketplaceMap

import React from 'react'
import Currency from '@components/Currency/Currency'

const FulfillmentMethodToDisplayName = {
  TABLE: 'Table Service',
  DELIVERY: 'Delivery',
  NETWORK: 'Delivery',
  COLLECTION: 'Collection',
}

const Totals = ({ classes, order }) => (
  <section className={classes.section}>
    <p className={classes.text}>
      <span className={classes.total}>
        <span>Subtotal</span>
        <Currency amount={order.subtotalGross} />
      </span>

      {!!order.discountAmount && (
        <span className={classes.total}>
          <span>{order.discount.name}</span>
          -<Currency amount={order.discountAmount} />
        </span>
      )}

      <span className={classes.total}>
        <span>{FulfillmentMethodToDisplayName[order.fulfillmentMethod]}</span>
        <Currency amount={order.fulfillmentCharge} />
      </span>

      {!!order.partnerCharge && (
        <span className={classes.total}>
          <span>{order.outlet.marketplace.partnerChargeDescription}</span>
          <Currency amount={order.partnerCharge} />
        </span>
      )}

      <span className={classes.total}>
        <span>Total</span>
        <span className={classes.bold}>
          <Currency amount={order.grossTotal} />
        </span>
      </span>
    </p>
  </section>
)

export default Totals

import { Button, Card, FormGroup, H5, Intent } from '@blueprintjs/core'
import React, { Fragment } from 'react'
import OnePerCustomerAndActive from './OneUsePerCustomerAndActive'
import { Row, Col } from 'react-simple-flex-grid'
import CopyText from '@components/CopyText/CopyText'
import UsageReportDrawer from './UsageReportDrawer'
import { BooleanParam, useQueryParam } from 'use-query-params'

const SingleVoucher = ({ voucher, discountName, orderIds = [] }) => {
  const [usageReportDrawer, onChangeUsageReportDrawer] = useQueryParam(
    'usageReportDrawer',
    BooleanParam
  )
  const { id, key, onePerCustomer, active, usageLimit, usageCount } = voucher
  const [voucherKey] = key.split('-')
  return (
    <Fragment>
      <Card>
        <Row>
          <Col span={6} md={6}>
            <H5>Usage Report</H5>
          </Col>
          <Col span={6} md={6}>
            <Button
              minimal
              icon="chart"
              text="Usage Data"
              intent={Intent.PRIMARY}
              disabled={!orderIds.length}
              onClick={() => onChangeUsageReportDrawer(true)}
              style={{ float: 'right' }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={3} md={3}>
            <p className="dashboard-data-value-large">{usageCount}</p>
            <p className="dashboard-data-label">Total Uses</p>
          </Col>
          <Col span={5} md={5}>
            <p className="dashboard-data-value-large">
              <span className="bp3-text-disabled">/ </span>
              <span className="bp3-text-muted">
                {usageLimit === 0 ? '∞' : usageLimit}
              </span>
            </p>
            <p className="dashboard-data-label">Limit</p>
          </Col>
        </Row>
      </Card>
      <br />
      <FormGroup label="Discount Configuration">
        <OnePerCustomerAndActive
          active={active}
          onePerCustomer={onePerCustomer}
          voucherIds={[id]}
        />
      </FormGroup>
      <FormGroup
        label="Code"
        helperText="Share with your customer to redeem the discount at checkout."
      >
        <CopyText mono large text={voucherKey} />
      </FormGroup>

      <UsageReportDrawer
        orderIds={orderIds}
        discountName={discountName}
        isOpen={usageReportDrawer}
        setIsOpen={onChangeUsageReportDrawer}
      />
    </Fragment>
  )
}

export default SingleVoucher

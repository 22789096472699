import React, { Fragment, useContext, useEffect } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { Route, Switch, Redirect } from 'react-router-dom'
import { matchType } from '@utils/types'
import { object } from 'prop-types'
import split from 'lodash/split'

import { toggleModal } from '@utils/cacheHelpers'
import Dashboard from './Dashboard/Dashboard'
import Details from './Details/Details'
import Financials from './Financials/Financials'
import Terminals from './Terminals/Terminals'
import Tables from './Tables/Tables'
import Fulfilment from './Fulfilment/Fulfilment'
import OpeningTimes from './OpeningTimes/OpeningTimes'
import OutletReviews from './OutletReviews/OutletReviews'
import Trace from './Trace/Trace'
import Menu from '@components/Restaurant/Menu/Menu'
import {
  canPerformAction,
  isMenuEditor,
  canView,
  isAtLeastMenuEditor,
  isAtLeastOutletUser,
  isAtLeastOutletFinancialUser,
  isAtLeastBusinessUser,
} from '@stores/userStore'

import { Callout } from '@blueprintjs/core'
import Users from '@components/Users/Users'
import UsersPageWrapper from '@components/Users/UsersPageWrapper'
import usersPageContext from '@components/Users/usersPageContext'

import { isPeakTimeIncludingFridayAndSaturday } from '@utils/isPeakTime'

const showCacheWarning = isPeakTimeIncludingFridayAndSaturday()

const tabButton = (pathname, setModalOpen) => {
  const tabName = split(pathname, '/')[5]

  if (tabName === 'tables' && isAtLeastOutletUser()) {
    return {
      text: 'Add Table',
      onClick: () =>
        toggleModal({
          addTableModal: true,
        }),
    }
  }

  if (tabName === 'terminals' && isAtLeastOutletUser()) {
    return {
      text: 'Add Terminal',
      onClick: () =>
        toggleModal({
          terminalModal: true,
        }),
    }
  }

  if (tabName === 'fulfilment' && isAtLeastBusinessUser()) {
    return {
      text: 'Add Delivery Zone',
      onClick: () =>
        toggleModal({
          outletDeliveryZoneModal: true,
        }),
    }
  }

  if (tabName === 'users' && canPerformAction('inviteUser')) {
    return {
      text: 'Invite user',
      onClick: () => setModalOpen(true),
    }
  }

  return null
}

const Outlet = ({ match, location, product }) => {
  const { setModalOpen } = useContext(usersPageContext)
  const { configurePageLayout } = useContext(PageLayoutContext)

  const tabs = [
    {
      to: `${match.url}/overview`,
      name: 'Overview',
      hide: !isAtLeastOutletFinancialUser(),
    },
    {
      to: `${match.url}/details`,
      name: 'Details',
      hide: !isAtLeastOutletFinancialUser(),
    },
    {
      to: `${match.url}/financial`,
      name: 'Payments',
      hide: !isAtLeastOutletFinancialUser(),
    },
    {
      to: `${match.url}/terminals`,
      name: 'Terminals',
      hide: !canView('virtualTerminals'),
    },
    {
      to: `${match.url}/fulfilment`,
      name: 'Delivery',
      hide: !isAtLeastOutletUser(),
    },
    {
      to: `${match.url}/opening-times`,
      name: 'Ordering',
      hide: !isAtLeastMenuEditor(),
    },
    {
      to: `${match.url}/reviews`,
      name: 'Reviews',
      hide: !isAtLeastOutletUser(),
    },
    {
      to: `${match.url}/stock`,
      name: 'Stock',
      hide: !isAtLeastMenuEditor(),
    },
    {
      to: `${match.url}/trace`,
      name: 'Track & Trace',
      hide: !isAtLeastOutletUser(),
    },
    {
      to: `${match.url}/users`,
      name: 'Users',
      hide: !isAtLeastOutletUser(),
    },
    {
      to: `${match.url}/tables`,
      name: 'Tables',
      hide: !isAtLeastBusinessUser(),
    },
  ]

  useEffect(() => {
    configurePageLayout({
      product,
      tabs,
      button: tabButton(location.pathname, setModalOpen),
    })
  }, [configurePageLayout, match.url, location.pathname, setModalOpen, product])

  return (
    <Fragment>
      {showCacheWarning && (
        <Fragment>
          <Callout intent="warning">
            We are currently in a peak order period, during this time it may
            take up to 60 minutes to reflect updates to your menu or outlet
            settings.
          </Callout>
          <br />
        </Fragment>
      )}
      <Switch>
        <Redirect
          from="/business/:restaurantId/outlets/:outletId"
          to={
            isMenuEditor()
              ? '/business/:restaurantId/outlets/:outletId/opening-times'
              : '/business/:restaurantId/outlets/:outletId/overview'
          }
          exact
        />
        <Route
          path={`${match.path}/overview`}
          exact={true}
          component={Dashboard}
          isPermissionAllowed={() => isAtLeastOutletFinancialUser()}
        />
        <Route
          path={`${match.path}/details`}
          exact={true}
          component={Details}
          isPermissionAllowed={() => isAtLeastOutletFinancialUser()}
        />
        <Route
          path={`${match.path}/financial`}
          exact={true}
          component={Financials}
          isPermissionAllowed={() => isAtLeastOutletFinancialUser()}
        />
        <Route
          path={`${match.path}/terminals`}
          exact={true}
          component={Terminals}
          isPermissionAllowed={() => canView('virtualTerminals')}
        />
        <Route
          path={`${match.path}/fulfilment`}
          exact={true}
          component={Fulfilment}
          isPermissionAllowed={() => isAtLeastOutletUser()}
        />
        <Route
          path={`${match.path}/opening-times`}
          exact={true}
          component={OpeningTimes}
          isPermissionAllowed={() => isAtLeastMenuEditor()}
        />
        <Route
          path={`${match.path}/reviews`}
          exact={true}
          component={OutletReviews}
          isPermissionAllowed={() => isAtLeastOutletUser()}
        />
        <Route
          path={`${match.path}/stock`}
          render={props => <Menu outlet {...props} />}
          isPermissionAllowed={() => isAtLeastMenuEditor()}
        />
        <Route
          path={`${match.path}/trace`}
          render={props => <Trace outlet {...props} />}
          isPermissionAllowed={() => isAtLeastOutletUser()}
        />
        <Route
          path={`${match.path}/users`}
          render={() => <Users outletId={match.params.outlet} />}
          isPermissionAllowed={() => isAtLeastBusinessUser()}
        />
        <Route path={`${match.path}/tables`} exact={true} component={Tables} />
      </Switch>
    </Fragment>
  )
}

Outlet.propTypes = {
  location: object,
  match: matchType,
}

const OutletPageWrapper = props => (
  <UsersPageWrapper entityName="outlet">
    <Outlet {...props} />
  </UsersPageWrapper>
)

export default OutletPageWrapper

import { Button } from '@blueprintjs/core/lib/esm/components/button/buttons'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import { successToast } from '@utils/toast'
import React from 'react'
import { Mutation } from 'react-apollo'
import DELETE_CAMPAIGN from '../mutations/deleteCampaign.mutation'

const DeleteCampaignButton = ({
  campaignId,
  marketplaceId,
  title = null,
  intent = null,
  onCompleted,
}) => (
  <Mutation
    mutation={DELETE_CAMPAIGN}
    onCompleted={() => {
      successToast('Campaign deleted successfully')
      onCompleted()
    }}
  >
    {deleteRequest => (
      <ConfirmationPopover
        remove={() =>
          deleteRequest({
            variables: { id: campaignId, marketplaceId },
          })
        }
        confirmationText={`Are you sure you want to delete this campaign?`}
      >
        <Button text={title} icon="trash" intent={intent} minimal />
      </ConfirmationPopover>
    )}
  </Mutation>
)

export default DeleteCampaignButton

import React, { useState } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { Formik } from 'formik'
import { Spinner, NonIdealState, Card, Elevation } from '@blueprintjs/core'
import GENERATE_PREVIEW_HTML_FOR_EMAIL from '@components/Marketplace/EmailTheme/queries/generatePreviewHtmlForEmail.query'
import SanitizedHTML from '@components/EmailLivePreview/HTMLEmailPreview'
import { Query, Mutation } from 'react-apollo'
import GET_EMAIL_THEME from '@components/Marketplace/EmailTheme/queries/getEmailTheme.query'
import UPDATE_AND_SYNCHRONISE_EMAIL_THEME from '@components/Marketplace/EmailTheme/mutations/updateAndSynchroniseEmailTheme.mutaton'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import omit from 'lodash/omit'
import InnerForm from './InnerForm'
import debounce from 'lodash/debounce'

/**
 * @param {{
 *      id: string
 *      emailBackgroundColor: string
 *      emailBodyColor: string
 *      emailButtonColor: string
 *      emailFontColor: string
 *      emailFooter: string
 *      emailFooterColor: string
 *      emailImageUrl: string
 *      emailShowImage: boolean
 *      emailShowCustomLogo: boolean
 *      brandColor: string
 *      logoImage: string
 *      name: string
 *  }} theme
 */
const handleThemeDefaults = ({
  id: _id,
  name: _name,
  emailButtonColor,
  emailImageUrl,
  brandColor,
  logoImage,
  ...rest
}) => ({
  ...rest,
  emailButtonColor: emailButtonColor || brandColor,
  emailImageUrl: emailImageUrl || logoImage,
})

const exampleTemplate = [
  { text: 'This is some example text...', type: 'TEXT' },
  {
    text: 'You can set the text that would show here for each template type in the message templates tab',
    type: 'TEXT',
  },
  { text: 'You can also add a button there', type: 'TEXT' },
  {
    type: 'BUTTON',
    buttonText: 'Click me!',
    buttonLink: 'http://google.com',
  },
  {
    type: 'TEXT',
    text: 'Emails sent out through marketing campaigns will also use this theme',
  },
]

const EmailTheme = ({ match }) => {
  const { marketplace: marketplaceId } = match.params
  const [loading, setLoading] = useState(false)

  return (
    <Mutation
      mutation={UPDATE_AND_SYNCHRONISE_EMAIL_THEME}
      onCompleted={({ updateAndSynchroniseEmailTheme: { message } }) => {
        setLoading(false)
        successToast(message)
      }}
      onError={error => {
        setLoading(false)
        defaultErrorHandler(error)
      }}
    >
      {updateAndSynchroniseEmailTheme =>
        loading ? (
          <NonIdealState
            icon={<Spinner size={60} />}
            title="Updating all templates to match theme"
            description="Please wait..."
          />
        ) : (
          <Query query={GET_EMAIL_THEME} variables={{ marketplaceId }}>
            {({ data, loading }) => {
              if (loading || !data || !data.getMarketplaces) {
                return (
                  <NonIdealState
                    title="Loading Email Themes"
                    description="Please wait..."
                    icon={<Spinner size={60} />}
                  />
                )
              }

              const themeData = data.getMarketplaces.regions[0]

              const defaults = handleThemeDefaults(themeData)

              return (
                <Query
                  query={GENERATE_PREVIEW_HTML_FOR_EMAIL}
                  variables={{
                    ...defaults,
                    content: exampleTemplate,
                    marketplaceId,
                  }}
                >
                  {({
                    previousData = null,
                    data: _data,
                    refetch: _refetch,
                  }) => {
                    const refetch = debounce(_refetch, 250)

                    const data = _data.generatePreviewHtmlForEmail
                      ? _data
                      : previousData

                    return (
                      <Row>
                        <Col md={6}>
                          <Card>
                            <Formik
                              initialValues={{
                                ...defaults,
                                emailUploadImage: false,
                                logoImage: themeData.logoImage,
                              }}
                              onSubmit={values => {
                                setLoading(true)
                                return updateAndSynchroniseEmailTheme({
                                  variables: {
                                    theme: omit(
                                      values,
                                      'emailUploadImage',
                                      'emailSenderUsername',
                                      'cname',
                                      'logoImage'
                                    ),
                                    marketplaceId,
                                  },
                                })
                              }}
                            >
                              {props => {
                                return (
                                  <InnerForm {...props} refetch={refetch} />
                                )
                              }}
                            </Formik>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card
                            className="bp3-nopad"
                            style={{ overflow: 'hidden' }}
                            elevation={Elevation.TWO}
                          >
                            <Card style={{ marginBottom: 0 }}>
                              To:{' '}
                              <strong className="bp3-skeleton">
                                customer@gmail.com
                              </strong>
                              <br />
                              From:{' '}
                              <strong>
                                {themeData.emailSenderUsername}@
                                {themeData.cname.trim().replace(/^www\./, '')}
                              </strong>
                              <br />
                              Subject:{' '}
                              <strong className="bp3-skeleton">
                                Your Email Subject
                              </strong>
                              <br />
                            </Card>
                            {!data ? (
                              <Spinner />
                            ) : (
                              <SanitizedHTML
                                exampleData={{}}
                                html={data.generatePreviewHtmlForEmail.html}
                              />
                            )}
                          </Card>
                        </Col>
                      </Row>
                    )
                  }}
                </Query>
              )
            }}
          </Query>
        )
      }
    </Mutation>
  )
}

export default EmailTheme

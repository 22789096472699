import React from 'react'
import { object, string, func, bool, array, shape } from 'prop-types'
import injectSheet from 'react-jss'
import ReactSVG from 'react-svg'
import { Button, Navbar, NavbarGroup, Icon } from '@blueprintjs/core'
import { history } from '@stores/routerStore'
import topLeftLogo from '@assets/topLeftLogo.svg'
import Breadcrumbs from '@components/Header/Breadcrumbs/Breadcrumbs'
import TabBar from '@components/Header/TabBar/TabBar'
import { productType } from '@utils/types'

const styles = ({ colors }) => ({
  topHeader: {
    width: '100%',
    height: 48,
    zIndex: 2,
    paddingLeft: 64,
    paddingRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    //background: colors.headerBgColor,
  },
  topLeftLogo: {
    top: 0,
    zIndex: 20,
    position: 'fixed',
  },
  button: {
    backgroundImage: 'none !important',
    color: `${colors.primaryButtonText} !important`,
    boxShadow: `${colors.primaryButtonShadow} !important`,
    backgroundColor: `${colors.primaryButtonColor} !important`,
    span: {
      color: 'green !important',
    },
  },
})

const Header = ({
  product,
  classes,
  button,
  permissions = true,
  tabs,
  persistQueryParamsBetweenTabs,
}) => (
  <header>
    <Navbar
      className={
        classes.topHeader +
        ' ' +
        (process.env.ENVIRONMENT_NAME !== 'PROD' ? 'devBanner' : '')
      }
      fixedToTop={true}
    >
      <Breadcrumbs product={product} />
      <NavbarGroup>
        {button && permissions && (
          <Button
            icon={
              <Icon
                icon={(button && button.icon) || 'add'}
                color={
                  button.icon === 'add' || !button.icon ? '#1ac57e' : '#5c7080'
                }
              />
            }
            className={classes.button}
            {...button}
          />
        )}
      </NavbarGroup>
    </Navbar>
    <TabBar
      tabs={tabs}
      persistQueryParamsBetweenTabs={persistQueryParamsBetweenTabs}
    />
    <div>
      <ReactSVG
        src={topLeftLogo}
        alt="logo"
        className={classes.topLeftLogo}
        onClick={() => history.push('/')}
      />
    </div>
  </header>
)

Header.propTypes = {
  product: productType,
  classes: object,
  button: shape({
    text: string,
    action: func,
  }),
  permissions: bool,
  tabs: array,
  persistQueryParamsBetweenTabs: bool,
}

export default injectSheet(styles)(Header)

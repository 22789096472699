import gql from 'graphql-tag'

const GET_IMAGES = gql`
  query getImages(
    $skip: Int
    $first: Int
    $menuItemId: String
    $optionItemId: String
  ) {
    getImages(
      skip: $skip
      first: $first
      menuItemId: $menuItemId
      optionItemId: $optionItemId
    ) {
      totalCount
      images {
        id
        src
        caption
        position
        menuItems {
          id
          name
        }
        optionItems {
          id
          name
        }
        menuItemThumbnails {
          id
          name
        }
      }
    }
  }
`

export default GET_IMAGES

import {
  Button,
  Card,
  ControlGroup,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core'
import FilterRow from '@components/FilterRow/FilterRow'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import {
  canView,
  isBusinessUser,
  isOutletUser,
  isPlatformLevelUser,
  isPartner,
} from '@stores/userStore'
import { toast } from '@utils/toast'
import { startOfWeek, format } from 'date-fns'
import React, { Fragment, useContext, useEffect } from 'react'
import { Query } from 'react-apollo'
import { DATE_FILTER_TYPES, ISO_WEEK_DAY } from '@utils/datetime'
import { penceToPounds } from '@utils/helpers'
import DateRangeFilter from '@components/Toolbar/DateRangeFilter/DateRangeFilter'
import RoleAwareBusinessFilter from '@components/Toolbar/RoleAwareBusinessFilter/RoleAwareBusinessFilter'
import TAX_REPORT from './queries/taxReport.query'
import TaxesTable from './TaxesTable'
import { useRoleAwareBusinessFilterQueryParams } from '@components/Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'
import { useDateRangeQueryParams } from '@components/Toolbar/DateRangeFilter/useDateRangeQueryParams'

const Taxes = ({ product }) => {
  // page layout wrapper
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    const tabs = []

    if (canView('sales')) {
      tabs.push(
        { to: '/reports/products', name: 'Products' },
        { to: '/reports/sales', name: 'Sales' },
        { to: '/reports/taxes', name: 'Taxes' }
      )
    }

    configurePageLayout({
      product,
      tabs,
    })
  }, [configurePageLayout, product])

  const {
    marketplaceIds,
    marketplaceOutletIds,
    onChangeMarketplaceIds,
    onChangeMarketplaceOutletIds,
  } = useRoleAwareBusinessFilterQueryParams()
  const dateRangeFilterArgs = {
    defaultFilterTypeKey: DATE_FILTER_TYPES.WEEK.key,
    defaultStartOfRangeDate: startOfWeek(new Date(), {
      weekStartsOn: ISO_WEEK_DAY.MONDAY,
    }),
    filterTypes: [
      DATE_FILTER_TYPES.DAY,
      DATE_FILTER_TYPES.WEEK,
      DATE_FILTER_TYPES.MONTH,
      DATE_FILTER_TYPES.QUARTER,
      DATE_FILTER_TYPES.CUSTOM,
    ],
    maxCustomRangeInterval: {
      months: 3,
    },
  }
  const {
    dateFilterTypeKey,
    shiftedStartOfRangeDateTime,
    shiftedEndOfRangeDateTime,
    resetRange,
    isRangeSameAsDefault,
  } = useDateRangeQueryParams(dateRangeFilterArgs)

  const [csvIsDownloading, setCsvIsDownloading] = React.useState(false)

  // filters
  return (
    <Query
      query={TAX_REPORT}
      variables={{
        ...(marketplaceOutletIds &&
          marketplaceOutletIds.length && {
            ownerFilter: {
              type: 'OUTLET',
              ids: marketplaceOutletIds.map(
                marketplaceOutletId => marketplaceOutletId.split(':')[1]
              ),
            },
          }),
        range: {
          type: dateFilterTypeKey,
          from: shiftedStartOfRangeDateTime,
          ...(dateFilterTypeKey === DATE_FILTER_TYPES.CUSTOM.key && {
            to: shiftedEndOfRangeDateTime,
          }),
        },
      }}
      skip={
        !marketplaceOutletIds.length && !isBusinessUser() && !isOutletUser()
      }
    >
      {({ loading, error = undefined, data }) => (
        <div className="bp3-table-frame">
          <Fragment>
            <FilterRow>
              <ControlGroup>
                <RoleAwareBusinessFilter />
                <DateRangeFilter {...dateRangeFilterArgs} />
                <Button
                  icon="filter-remove"
                  onClick={() => {
                    resetRange()
                    onChangeMarketplaceIds(undefined)
                    onChangeMarketplaceOutletIds(undefined)
                  }}
                  disabled={
                    isRangeSameAsDefault &&
                    !marketplaceIds.length &&
                    !marketplaceOutletIds.length
                  }
                />
              </ControlGroup>
              <Button
                disabled={
                  loading ||
                  !data ||
                  (data.taxReport && data.taxReport.length === 0)
                }
                loading={csvIsDownloading}
                icon={'cloud-download'}
                onClick={() => {
                  setCsvIsDownloading(true)
                  const csvBlob = generateCSVBlob({
                    taxRecords: data.taxReport,
                    formattedStartOfRangeDate: format(
                      shiftedStartOfRangeDateTime,
                      'dd/MM/yyyy HH:mm'
                    ),
                    formattedEndOfRangeDate: format(
                      shiftedEndOfRangeDateTime,
                      'dd/MM/yyyy HH:mm'
                    ),
                  })
                  const url = window.URL.createObjectURL(csvBlob)

                  //Add temp Link to page and start download.
                  let a = document.createElement('a')
                  document.body.appendChild(a)
                  a.style = 'display: none'
                  a.href = url
                  a.download = `vat-${format(
                    shiftedStartOfRangeDateTime,
                    'yyyyMMdd'
                  )}-to-${format(shiftedEndOfRangeDateTime, 'yyyyMMdd')}.csv`
                  a.click()
                  a.remove()

                  window.URL.revokeObjectURL(url)

                  setCsvIsDownloading(false)

                  toast({
                    message: 'Download complete.',
                    intent: 'success',
                    icon: 'saved',
                  })
                }}
              >
                Download CSV
              </Button>
            </FilterRow>
            {loading && (
              <NonIdealState
                icon={<Spinner size={60} />}
                title="Loading Tax Report"
                description="Please wait..."
              />
            )}
            {error && (
              <NonIdealState
                icon="error"
                title="Failed to load tax report"
                description="Please try again"
              />
            )}
            {!loading && data && data.taxReport.length === 0 && (
              <NonIdealState
                icon="error"
                title="No Tax Records Found"
                description="Please adjust filters"
              />
            )}
            {!marketplaceIds.length &&
            (isPartner() || isPlatformLevelUser()) ? (
              <NonIdealState
                icon="error"
                title="No Marketplaces Selected"
                description="Please select a marketplace"
              />
            ) : (
              !marketplaceOutletIds.length &&
              !isBusinessUser() &&
              !isOutletUser() && (
                <NonIdealState
                  icon="error"
                  title="No Businesses Selected"
                  description="Please select a business"
                />
              )
            )}
            {!loading && data && data.taxReport.length > 0 && (
              <div className="bp3-table-container bp3-scrollable">
                <Card className={'bp3-nopad'}>
                  <TaxesTable taxRecords={data.taxReport} />
                </Card>
              </div>
            )}
          </Fragment>
        </div>
      )}
    </Query>
  )
}

const generateCSVBlob = ({
  taxRecords,
  formattedStartOfRangeDate,
  formattedEndOfRangeDate,
}) => {
  const header = [
    'Restaurant',
    'Outlet',
    'Marketplace',
    'Date From',
    'Date To',
    'Products Net',
    'Products Gross',
    'Products VAT',
    'Fulfilment Net',
    'Fulfilment Gross',
    'Fulfilment VAT',
    'Service Charges Net',
    'Service Charges Gross',
    'Service Charges VAT',
  ].join(',')

  const csvRows = taxRecords.map(taxRecord =>
    [
      taxRecord.outlet.restaurant.name,
      taxRecord.outlet.name,
      taxRecord.outlet.marketplace.name,
      formattedStartOfRangeDate,
      formattedEndOfRangeDate,
      penceToPounds(taxRecord.productsNet),
      penceToPounds(taxRecord.productsGross),
      penceToPounds(taxRecord.productsVAT),
      penceToPounds(taxRecord.fulfilmentNet),
      penceToPounds(taxRecord.fulfilmentGross),
      penceToPounds(taxRecord.fulfilmentVAT),
      penceToPounds(taxRecord.serviceChargeNet),
      penceToPounds(taxRecord.serviceChargeGross),
      penceToPounds(taxRecord.serviceChargeVAT),
    ].join(',')
  )

  const csvData = [header, ...csvRows].join('\n')
  return new Blob([csvData], { type: 'octet/stream' })
}

export default Taxes

import React, { Fragment } from 'react'
import * as yup from 'yup'
import { first, omit } from 'lodash'
import { Mutation } from 'react-apollo'
import { FieldArray, Formik } from 'formik'
import {
  InputGroup,
  Card,
  TextArea,
  Button,
  FormGroup,
  Intent,
  Checkbox,
  Divider,
  HTMLTable,
  RadioGroup,
  Radio,
  Icon,
  Popover,
  Classes,
  H5,
  PopoverInteractionKind,
  Position,
  AnchorButton,
  HTMLSelect,
} from '@blueprintjs/core'
import ImageUpload from '../../ImageUpload/ImageUpload'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import Query from '@components/Query/Query'
import ColorPicker from '@components/ColorPicker/ColorPicker'
import GET_MARKETPLACE_WEBSITE from './queries/getMarketplaceWebsite.query'
import EDIT_MARKETPLACE_WEBSITE from './mutations/editMarketplaceWebsite.mutation'
import { editMarketplaceStructure } from '@validation/marketplace'
import { Row, Col } from 'react-simple-flex-grid'
import { successToast } from '@utils/toast'
import AddLinks from './AddLinks'
import LinkTag from '@components/LinkTag/LinkTag'

const validationSchema = yup.object().shape(editMarketplaceStructure)

const defaultFulfilmentOption = [
  { label: 'All', value: 'ALL' },
  { label: 'Delivery', value: 'DELIVERY' },
  { label: 'Collection', value: 'COLLECTION' },
  { label: 'Table', value: 'TABLE' },
]

const defaultDatepickerOption = [
  { label: 'Anytime', value: 'ANYTIME' },
  { label: 'Now', value: 'ASAP' },
]

const defaultLocationOption = [
  { label: 'Everywhere', value: 'EVERYWHERE' },
  { label: 'Postcode', value: 'POSTCODE' },
  { label: 'Your Location', value: 'NEARME' },
]

const Website = ({ match }) => {
  const { marketplace: marketplaceId } = match.params
  return (
    <Query
      query={GET_MARKETPLACE_WEBSITE}
      variables={{ id: marketplaceId }}
      loaderTitle={'Loading Website'}
    >
      {({ getMarketplaces: { regions } }) => {
        const marketplace = first(regions)

        if (!marketplace) {
          return 'Unable to find marketplace'
        }

        return (
          <Mutation
            mutation={EDIT_MARKETPLACE_WEBSITE}
            onError={defaultErrorHandler}
            onCompleted={({ editMarketplaceWebsite }) =>
              successToast(editMarketplaceWebsite.message)
            }
          >
            {editMarketplaceWebsite => {
              return (
                <Formik
                  onSubmit={({
                    brandColor,
                    brandSecondaryColor,
                    brandTertiaryColor,
                    ...values
                  }) => {
                    const validHeaderLinks = values.headerLinks.map(
                      headerLink => ({
                        ...headerLink,
                        link:
                          headerLink.link.split('.')[0] === 'www'
                            ? 'https://' + headerLink.link
                            : headerLink.link,
                      })
                    )
                    const validFooterLinks = values.footerLinks.map(
                      footerLink => ({
                        ...footerLink,
                        link:
                          footerLink.link.split('.')[0] === 'www'
                            ? 'https://' + footerLink.link
                            : footerLink.link,
                      })
                    )
                    editMarketplaceWebsite({
                      variables: {
                        marketplaceId,
                        brandColor: brandColor || null,
                        brandSecondaryColor: brandSecondaryColor || null,
                        brandTertiaryColor: brandTertiaryColor || null,
                        ...values,
                        headerLinks: validHeaderLinks,
                        footerLinks: validFooterLinks,
                      },
                    })
                  }}
                  initialValues={{
                    ...omit(marketplace, 'stripeId'),
                    headerLinks: marketplace.headerLinks || [],
                    footerLinks: marketplace.footerLinks || [],
                  }}
                  validationSchema={validationSchema}
                  enableReinitialize
                >
                  {props => {
                    const {
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      setStatus,
                      status,
                    } = props

                    return (
                      <form onSubmit={handleSubmit}>
                        <Card>
                          <Row gutter={48}>
                            <Col xs={12} lg={9}>
                              <H5>Search Engine Optimization</H5>
                              <FormGroup
                                label="Page Title"
                                labelInfo="(required)"
                                labelFor="title"
                                helperText={errors.title ? errors.title : ''}
                                intent={Intent.DANGER}
                              >
                                <InputGroup
                                  name="title"
                                  id="title"
                                  type="text"
                                  onChange={handleChange}
                                  intent={
                                    errors.title ? Intent.DANGER : Intent.NONE
                                  }
                                  value={values.title}
                                />
                              </FormGroup>
                              <FormGroup
                                label="Description"
                                labelFor="metaDescription"
                                labelInfo="155 Character Limit"
                                helperText={
                                  errors.metaDescription
                                    ? errors.metaDescription
                                    : 'Typically displayed in search engine results beneath the title of the page.'
                                }
                                intent={
                                  errors.metaDescription
                                    ? Intent.DANGER
                                    : Intent.NONE
                                }
                              >
                                <TextArea
                                  name="metaDescription"
                                  id="metaDescription"
                                  type="text"
                                  fill={true}
                                  onChange={handleChange}
                                  intent={
                                    errors.metaDescription
                                      ? Intent.DANGER
                                      : Intent.NONE
                                  }
                                  value={values.metaDescription}
                                />
                              </FormGroup>
                              <FormGroup
                                label="Keywords"
                                labelInfo=""
                                labelFor="metaKeywords"
                                helperText={
                                  errors.metaKeywords
                                    ? errors.metaKeywords
                                    : 'List of keywords used by search engines that describe content.'
                                }
                                intent={
                                  errors.metaKeywords
                                    ? Intent.DANGER
                                    : Intent.NONE
                                }
                              >
                                <TextArea
                                  name="metaKeywords"
                                  id="metaKeywords"
                                  type="text"
                                  fill={true}
                                  onChange={handleChange}
                                  intent={
                                    errors.metaKeywords
                                      ? Intent.DANGER
                                      : Intent.NONE
                                  }
                                  value={values.metaKeywords}
                                />
                              </FormGroup>

                              <br />
                              <h5 className="bp3-heading">Hero</h5>

                              <FormGroup
                                label="Title"
                                labelFor="heroText"
                                helperText={
                                  errors.heroText ? errors.heroText : ''
                                }
                                intent={Intent.DANGER}
                              >
                                <InputGroup
                                  fill
                                  name="heroText"
                                  onChange={handleChange}
                                  value={values.heroText}
                                />
                              </FormGroup>

                              <FormGroup
                                label="Secondary"
                                labelFor="heroTextSecondary"
                                helperText={
                                  errors.heroTextSecondary
                                    ? errors.heroTextSecondary
                                    : ''
                                }
                                intent={Intent.DANGER}
                              >
                                <InputGroup
                                  fill
                                  name="heroTextSecondary"
                                  onChange={handleChange}
                                  value={values.heroTextSecondary}
                                />
                              </FormGroup>

                              <br />

                              <h5 className="bp3-heading">
                                Service Defaults <LinkTag type="web-v2" />
                              </h5>

                              <FormGroup
                                label="Fulfilment"
                                helperText={
                                  'This will be selected by default on the outlet list page.'
                                }
                              >
                                <HTMLSelect
                                  name={`defaultFulfilmentOption`}
                                  options={defaultFulfilmentOption}
                                  value={values.defaultFulfilmentOption}
                                  onChange={e => {
                                    if (
                                      values.defaultLocationOption !==
                                        'NEARME' &&
                                      (values.defaultDatepickerOption !==
                                        'ANYTIME' ||
                                        values.defaultLocationOption !==
                                          'EVERYWHERE' ||
                                        e.target.value !== 'ALL') &&
                                      values.enableRedirectFromLandingPage
                                    ) {
                                      setFieldValue(
                                        'enableRedirectFromLandingPage',
                                        false,
                                        false
                                      )
                                    }
                                    handleChange(e)
                                  }}
                                  intent={
                                    errors.defaultFulfilmentOption
                                      ? Intent.DANGER
                                      : Intent.NONE
                                  }
                                />
                              </FormGroup>
                              <FormGroup
                                label="Timepicker"
                                helperText={
                                  'This will be selected by default on the outlet list page.'
                                }
                              >
                                <HTMLSelect
                                  name={`defaultDatepickerOption`}
                                  options={defaultDatepickerOption}
                                  value={values.defaultDatepickerOption}
                                  intent={
                                    errors.defaultDatepickerOption
                                      ? Intent.DANGER
                                      : Intent.NONE
                                  }
                                  onChange={e => {
                                    if (
                                      values.defaultLocationOption !==
                                        'NEARME' &&
                                      (e.target.value !== 'ANYTIME' ||
                                        values.defaultLocationOption !==
                                          'EVERYWHERE' ||
                                        values.defaultFulfilmentOption !==
                                          'ALL') &&
                                      values.enableRedirectFromLandingPage
                                    ) {
                                      setFieldValue(
                                        'enableRedirectFromLandingPage',
                                        false,
                                        false
                                      )
                                    }
                                    handleChange(e)
                                  }}
                                />
                              </FormGroup>
                              <FormGroup
                                label="Location"
                                helperText={
                                  'This will be selected by default if the customer navigates directly to the outlet list.'
                                }
                              >
                                <HTMLSelect
                                  name={`defaultLocationOption`}
                                  options={defaultLocationOption}
                                  value={values.defaultLocationOption}
                                  intent={
                                    errors.defaultLocationOption
                                      ? Intent.DANGER
                                      : Intent.NONE
                                  }
                                  onChange={e => {
                                    if (e.target.value === 'NEARME') {
                                      if (
                                        !values.enableRedirectFromLandingPage
                                      ) {
                                        setFieldValue(
                                          'enableRedirectFromLandingPage',
                                          true,
                                          false
                                        )
                                      }
                                    } else if (
                                      (values.defaultDatepickerOption !==
                                        'ANYTIME' ||
                                        e.target.value !== 'EVERYWHERE' ||
                                        values.defaultFulfilmentOption !==
                                          'ALL') &&
                                      values.enableRedirectFromLandingPage
                                    ) {
                                      setFieldValue(
                                        'enableRedirectFromLandingPage',
                                        false,
                                        false
                                      )
                                    }
                                    handleChange(e)
                                  }}
                                />
                              </FormGroup>

                              <Checkbox
                                label={
                                  'Enable redirect homepage to outlet list'
                                }
                                checked={values.enableRedirectFromLandingPage}
                                onChange={() =>
                                  setFieldValue(
                                    'enableRedirectFromLandingPage',
                                    !values.enableRedirectFromLandingPage,
                                    false
                                  )
                                }
                                name="enableRedirectFromLandingPage"
                                disabled={
                                  values.defaultFulfilmentOption !== 'ALL' ||
                                  values.defaultDatepickerOption !==
                                    'ANYTIME' ||
                                  values.defaultLocationOption !==
                                    'EVERYWHERE' ||
                                  values.defaultLocationOption === 'NEARME'
                                }
                              />

                              <span className={'bp3-text-small bp3-text-muted'}>
                                (This option is only available if defaults are
                                'All', 'Anytime' and 'Everywhere'. If default is
                                'Your Location’ this option will be selected.)
                              </span>

                              <br />
                              <br />
                              <br />
                              <h5 className="bp3-heading">
                                Outlet List <LinkTag type="web-v2" />
                              </h5>

                              <FormGroup
                                label="'All Outlets' Title"
                                labelFor="allOutletsText"
                                helperText={
                                  errors.allOutletsText ? (
                                    errors.allOutletsText
                                  ) : (
                                    <p>
                                      Example: 'All Outlets' or 'All
                                      Restaurants' or 'All Services' displayed:
                                      <ul>
                                        <li>
                                          On the picker Service section, when
                                          all services are selected.
                                        </li>
                                        <li>
                                          On the outlet list heading, when all
                                          services are selected in the service
                                          picker.
                                        </li>
                                      </ul>
                                    </p>
                                  )
                                }
                                intent={
                                  errors.allOutletsText
                                    ? Intent.DANGER
                                    : Intent.NONE
                                }
                              >
                                <InputGroup
                                  fill
                                  name="allOutletsText"
                                  onChange={handleChange}
                                  value={values.allOutletsText}
                                />
                              </FormGroup>

                              <br />
                              <h5 className="bp3-heading">App Stores</h5>

                              <FormGroup
                                label="Title"
                                labelFor="appText"
                                helperText={
                                  errors.appText ? errors.appText : ''
                                }
                                intent={Intent.DANGER}
                              >
                                <InputGroup
                                  fill
                                  name="appText"
                                  onChange={handleChange}
                                  value={values.appText}
                                />
                              </FormGroup>

                              <FormGroup
                                label="Secondary"
                                labelFor="appTextSecondary"
                                helperText={
                                  errors.appText ? errors.appText : ''
                                }
                                intent={Intent.DANGER}
                              >
                                <InputGroup
                                  fill
                                  name="appTextSecondary"
                                  onChange={handleChange}
                                  value={values.appTextSecondary}
                                />
                              </FormGroup>

                              <FormGroup
                                label="App Store URL"
                                labelFor="storeURLApple"
                                labelInfo="(URL)"
                                helperText={
                                  errors.storeURLApple
                                    ? errors.storeURLApple
                                    : ''
                                }
                                intent={Intent.DANGER}
                              >
                                <InputGroup
                                  fill
                                  name="storeURLApple"
                                  onChange={handleChange}
                                  value={values.storeURLApple}
                                />
                              </FormGroup>

                              <FormGroup
                                label="Google Play Store URL"
                                labelFor="storeURLGooglePlay"
                                labelInfo="(URL)"
                                helperText={
                                  errors.storeURLGooglePlay
                                    ? errors.storeURLGooglePlay
                                    : ''
                                }
                                intent={Intent.DANGER}
                              >
                                <InputGroup
                                  fill
                                  name="storeURLGooglePlay"
                                  onChange={handleChange}
                                  value={values.storeURLGooglePlay}
                                />
                              </FormGroup>

                              <br />
                              <h5 className="bp3-heading">Social Links</h5>

                              <FormGroup
                                label="Facebook"
                                labelFor="socialURLFacebook"
                                labelInfo="(URL)"
                                helperText={
                                  errors.socialURLFacebook
                                    ? errors.socialURLFacebook
                                    : ''
                                }
                                intent={Intent.DANGER}
                              >
                                <InputGroup
                                  fill
                                  name="socialURLFacebook"
                                  onChange={handleChange}
                                  value={values.socialURLFacebook}
                                />
                              </FormGroup>

                              <FormGroup
                                label="X.com"
                                labelFor="socialURLTwitter"
                                labelInfo="(URL)"
                                helperText={
                                  errors.socialURLTwitter
                                    ? errors.socialURLTwitter
                                    : ''
                                }
                                intent={Intent.DANGER}
                              >
                                <InputGroup
                                  fill
                                  name="socialURLTwitter"
                                  onChange={handleChange}
                                  value={values.socialURLTwitter}
                                />
                              </FormGroup>

                              <FormGroup
                                label="Instagram"
                                labelFor="socialURLInstagram"
                                labelInfo="(URL)"
                                helperText={
                                  errors.socialURLInstagram
                                    ? errors.socialURLInstagram
                                    : ''
                                }
                                intent={Intent.DANGER}
                              >
                                <InputGroup
                                  fill
                                  name="socialURLInstagram"
                                  onChange={handleChange}
                                  value={values.socialURLInstagram}
                                />
                              </FormGroup>
                              <FormGroup
                                label="TikTok"
                                labelFor="socialURLTikTok"
                                labelInfo="(URL)"
                                helperText={
                                  errors.socialURLTikTok
                                    ? errors.socialURLTikTok
                                    : ''
                                }
                                intent={Intent.DANGER}
                              >
                                <InputGroup
                                  fill
                                  name="socialURLTikTok"
                                  onChange={handleChange}
                                  value={values.socialURLTikTok}
                                />
                              </FormGroup>

                              <FormGroup>
                                <br />
                                <h5 className="bp3-heading">Header Links</h5>
                                <Card className="bp3-nopad">
                                  <HTMLTable className="bp3-dialog-table">
                                    <thead>
                                      <tr>
                                        <th>Title</th>
                                        <th>Link (URL)</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <FieldArray
                                        name="headerLinks"
                                        render={({ remove }) => (
                                          <AddLinks
                                            links={values.headerLinks}
                                            handleChange={handleChange}
                                            name={'headerLinks'}
                                            errors={errors}
                                            position="header"
                                            marketplaceId={marketplaceId}
                                            remove={remove}
                                          />
                                        )}
                                      />
                                    </tbody>
                                    <tfoot>
                                      <FieldArray
                                        name="headerLinks"
                                        render={({ push }) => (
                                          <Button
                                            text="Add Header Link"
                                            minimal={true}
                                            icon="plus"
                                            type="button"
                                            intent={'primary'}
                                            onClick={() =>
                                              push({
                                                title: '',
                                                link: '',
                                                position:
                                                  values.headerLinks.length,
                                              })
                                            }
                                            style={{
                                              margin: '6px 0 10px 10px',
                                            }}
                                          />
                                        )}
                                      />
                                    </tfoot>
                                  </HTMLTable>
                                </Card>
                              </FormGroup>
                              <FormGroup>
                                <h5 className="bp3-heading">Footer Links</h5>
                                <Card className="bp3-nopad">
                                  <HTMLTable
                                    className="bp3-dialog-table"
                                    interactive={true}
                                  >
                                    <thead>
                                      <tr>
                                        <th>Title</th>
                                        <th>Link (URL)</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <FieldArray
                                        name="footerLinks"
                                        render={({ remove }) => (
                                          <AddLinks
                                            links={values.footerLinks}
                                            handleChange={handleChange}
                                            name={'footerLinks'}
                                            errors={errors}
                                            position="footer"
                                            marketplaceId={marketplaceId}
                                            remove={remove}
                                          />
                                        )}
                                      />
                                    </tbody>
                                    <tfoot>
                                      <FieldArray
                                        name="footerLinks"
                                        render={({ push }) => (
                                          <Button
                                            text="Add Footer Link"
                                            minimal={true}
                                            icon="plus"
                                            type="button"
                                            intent={'primary'}
                                            onClick={() =>
                                              push({
                                                title: '',
                                                link: '',
                                                position:
                                                  values.footerLinks.length,
                                              })
                                            }
                                            style={{
                                              margin: '6px 0 10px 10px',
                                            }}
                                          />
                                        )}
                                      />
                                    </tfoot>
                                  </HTMLTable>
                                </Card>
                              </FormGroup>
                            </Col>
                            <Col xs={12} lg={3} className="bp3-col-divide">
                              <h5 className="bp3-heading">Branding</h5>
                              {!marketplace.enableCustomerV2Client && (
                                <Fragment>
                                  <FormGroup
                                    label="Primary Colour"
                                    labelInfo="(hex)"
                                    labelFor="brandColor"
                                    helperText={errors.brandColor}
                                    intent={Intent.DANGER}
                                  >
                                    <ColorPicker
                                      name="brandColor"
                                      id="brandColor"
                                      type="text"
                                      fill={false}
                                      onChange={handleChange}
                                      value={values.brandColor || null}
                                      error={errors.brandColor}
                                    />
                                  </FormGroup>

                                  <FormGroup
                                    label="Secondary Colour"
                                    labelInfo="(hex)"
                                    labelFor="brandSecondaryColor"
                                    helperText={errors.brandSecondaryColor}
                                    intent={Intent.DANGER}
                                  >
                                    <ColorPicker
                                      name="brandSecondaryColor"
                                      id="brandSecondaryColor"
                                      type="text"
                                      fill={false}
                                      onChange={handleChange}
                                      value={values.brandSecondaryColor || null}
                                      error={errors.brandSecondaryColor}
                                    />
                                  </FormGroup>

                                  <FormGroup
                                    label="Tertiary Colour"
                                    labelInfo="(hex)"
                                    labelFor="brandTertiaryColor"
                                    helperText={errors.brandTertiaryColor}
                                    intent={Intent.DANGER}
                                  >
                                    <ColorPicker
                                      name="brandTertiaryColor"
                                      id="brandTertiaryColor"
                                      type="text"
                                      fill={false}
                                      onChange={handleChange}
                                      value={values.brandTertiaryColor || null}
                                      error={errors.brandTertiaryColor}
                                    />
                                  </FormGroup>

                                  <Divider />
                                  <br />
                                </Fragment>
                              )}
                              <ImageUpload
                                values={values}
                                setFieldValue={setFieldValue}
                                imageName="faviconImage"
                                imageLabel="Favicon"
                                status={status}
                                setStatus={setStatus}
                                labelInfo="(Square png - Max 250Kb)"
                                accept="image/png"
                              />

                              <ImageUpload
                                values={values}
                                setFieldValue={setFieldValue}
                                imageName="logoImage"
                                imageLabel="Logo"
                                status={status}
                                setStatus={setStatus}
                              />
                              <ImageUpload
                                values={values}
                                setFieldValue={setFieldValue}
                                imageName="heroImage"
                                imageLabel="Hero Background"
                                status={status}
                                setStatus={setStatus}
                              />
                              <ImageUpload
                                values={values}
                                setFieldValue={setFieldValue}
                                imageName="appImage"
                                imageLabel="App Screenshot"
                                status={status}
                                setStatus={setStatus}
                              />
                              <br />
                              <h5 className="bp3-heading">Pages</h5>
                              <Checkbox
                                label={'FAQ & Support'}
                                checked={values.featureFAQ}
                                onChange={() =>
                                  setFieldValue(
                                    'featureFAQ',
                                    !values.featureFAQ,
                                    false
                                  )
                                }
                                name="featureFAQ"
                              />
                              <br />
                              <h5 className="bp3-heading">Homepage</h5>
                              <Checkbox
                                label={'Recruit Business Panel'}
                                checked={values.featureRecruit}
                                onChange={() =>
                                  setFieldValue(
                                    'featureRecruit',
                                    !values.featureRecruit,
                                    false
                                  )
                                }
                                name="featureRecruit"
                              />
                              <Checkbox
                                label={'Promote Businesses Panel'}
                                checked={values.featurePromotions}
                                onChange={() =>
                                  setFieldValue(
                                    'featurePromotions',
                                    !values.featurePromotions,
                                    false
                                  )
                                }
                                name="featurePromotions"
                              />
                              <Checkbox
                                labelElement={
                                  <Fragment>
                                    Advanced Location Search{' '}
                                    <Popover
                                      interactionKind={
                                        PopoverInteractionKind.HOVER
                                      }
                                      position={Position.RIGHT_TOP}
                                      popoverClassName={
                                        Classes.POPOVER_CONTENT_SIZING
                                      }
                                      content={
                                        <Fragment>
                                          <H5>Help</H5>
                                          <p>
                                            <i>Advanced Location Search</i>,
                                            when enabled replaces the default
                                            postcode search field on the
                                            ordering site so the customer will
                                            be presented with a multi-search
                                            panel.
                                          </p>
                                          <AnchorButton
                                            href="https://support.redbox.systems/docs/advanced-location-search"
                                            rightIcon="document-open"
                                            outlined
                                            target="_blank"
                                          >
                                            Read Documentation
                                          </AnchorButton>
                                        </Fragment>
                                      }
                                    >
                                      <Icon icon="help" className="bp3-help" />
                                    </Popover>
                                  </Fragment>
                                }
                                checked={values.allowLocationSearch}
                                onChange={() =>
                                  setFieldValue(
                                    'allowLocationSearch',
                                    !values.allowLocationSearch,
                                    false
                                  )
                                }
                                name="allowLocationSearch"
                              />

                              <br />
                              <h5 className="bp3-heading">Services</h5>
                              {!marketplace.stripeId && (
                                <p className="bp3-text-small bp3-text-muted">
                                  <Icon icon="warning-sign" /> Payments not
                                  configured.
                                </p>
                              )}
                              <Checkbox
                                label={'Allow Registration'}
                                checked={values.featureRegister}
                                onChange={() =>
                                  setFieldValue(
                                    'featureRegister',
                                    !values.featureRegister,
                                    false
                                  )
                                }
                                name="featureRegister"
                                disabled={
                                  !marketplace.stripeId &&
                                  !values.featureRegister
                                }
                              />
                              <Checkbox
                                label={'Allow Login'}
                                checked={values.featureLogin}
                                onChange={() =>
                                  setFieldValue(
                                    'featureLogin',
                                    !values.featureLogin,
                                    false
                                  )
                                }
                                name="featureLogin"
                                disabled={
                                  !marketplace.stripeId && !values.featureLogin
                                }
                              />
                              <Checkbox
                                label={'Allow Ordering'}
                                checked={values.featureOrdering}
                                onChange={() =>
                                  setFieldValue(
                                    'featureOrdering',
                                    !values.featureOrdering,
                                    false
                                  )
                                }
                                name="featureOrdering"
                                disabled={
                                  !marketplace.stripeId &&
                                  !values.featureOrdering
                                }
                              />
                              <br />
                              <h5
                                className="bp3-heading"
                                style={{ marginBottom: '5px' }}
                              >
                                Default Search Type
                              </h5>
                              <span className={'bp3-text-small bp3-text-muted'}>
                                Default method for finding outlets on homepage.
                              </span>
                              <RadioGroup
                                name="defaultSearch"
                                selectedValue={values.defaultSearch}
                                onChange={handleChange}
                              >
                                <Radio
                                  label="Postcode"
                                  value="POSTCODE"
                                  style={{ marginTop: '10px' }}
                                />
                                <Radio label="Location" value="COORDINATES" />
                              </RadioGroup>
                              <br />
                              <h5
                                className="bp3-heading"
                                style={{ marginBottom: '5px' }}
                              >
                                Meta Event Tracking <LinkTag type="web-v2" />
                              </h5>
                              <p className={'bp3-text-small bp3-text-muted'}>
                                Add a meta pixel to your website to track user
                                behavior and setup targeted ads.
                              </p>
                              <FormGroup
                                label="Meta Pixel ID"
                                labelFor="facebookPixelId"
                                helperText={
                                  errors.facebookPixelId
                                    ? errors.facebookPixelId
                                    : ''
                                }
                                intent={Intent.DANGER}
                              >
                                <InputGroup
                                  fill
                                  name="facebookPixelId"
                                  onChange={handleChange}
                                  value={values.facebookPixelId}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <div className="bp-card-footer-actions">
                            <Button
                              text="Save"
                              intent={Intent.DEFAULT}
                              type="submit"
                            />
                          </div>
                        </Card>
                      </form>
                    )
                  }}
                </Formik>
              )
            }}
          </Mutation>
        )
      }}
    </Query>
  )
}

export default Website

import React, { Fragment, useState } from 'react'
import { first } from 'lodash'
import { Mutation } from 'react-apollo'
import { Formik } from 'formik'
import {
  Tabs,
  Tab,
  Card,
  TextArea,
  Button,
  FormGroup,
  H4,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import Query from '@components/Query/Query'
import GET_MARKETPLACE_LEGAL from './queries/getMarketplaceLegal.query'
import EDIT_MARKETPLACE_LEGAL from './mutations/editMarketplaceLegal.mutation'
import { successToast } from '@utils/toast'

const Legal = ({ match }) => {
  const { marketplace: marketplaceId } = match.params
  const [navbarTabId, setNavbarTabId] = useState('terms')

  const handleNavbarTabChange = navbarTabId => setNavbarTabId(navbarTabId)

  return (
    <Query
      query={GET_MARKETPLACE_LEGAL}
      variables={{ id: marketplaceId }}
      loaderTitle={'Loading Legal Policies'}
    >
      {({ getMarketplaces: { regions } }) => {
        const marketplace = first(regions)
        if (!marketplace) {
          return 'Unable to find marketplace'
        }

        return (
          <div className="bp3-layout-row">
            <Card className="bp3-layout-col">
              <Mutation
                mutation={EDIT_MARKETPLACE_LEGAL}
                onError={defaultErrorHandler}
                onCompleted={({ editMarketplace }) =>
                  successToast(editMarketplace.message)
                }
              >
                {editMarketplaceLegal => (
                  <Formik
                    onSubmit={values => {
                      editMarketplaceLegal({
                        variables: {
                          marketplaceId,
                          ...values,
                        },
                      })
                    }}
                    initialValues={marketplace}
                  >
                    {props => {
                      const {
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                      } = props

                      return (
                        <form onSubmit={handleSubmit}>
                          <FormGroup
                            helperText={
                              <Fragment>
                                Formatted with{' '}
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://support.redbox.systems/docs/markdown-guide"
                                >
                                  Markdown (Guide)
                                </a>
                                .
                              </Fragment>
                            }
                            fill={true}
                          >
                            <H4>
                              Legal{' '}
                              <Button
                                type="submit"
                                loading={isSubmitting && !errors}
                                style={{ float: 'right' }}
                              >
                                Save & Publish
                              </Button>
                            </H4>

                            <Tabs
                              id="legal"
                              onChange={handleNavbarTabChange}
                              selectedTabId={navbarTabId}
                            >
                              <Tab
                                id="terms"
                                title="Terms & Conditions"
                                panel={
                                  <TextArea
                                    id="legalTerms"
                                    name="legalTerms"
                                    growVertically={false}
                                    fill={true}
                                    onChange={handleChange}
                                    value={values.legalTerms || ''}
                                    style={{ height: '75vh' }}
                                  />
                                }
                              />
                              <Tab
                                id="privacy"
                                title="Privacy Policy"
                                panel={
                                  <TextArea
                                    id="legalPrivacy"
                                    name="legalPrivacy"
                                    growVertically={false}
                                    fill={true}
                                    onChange={handleChange}
                                    value={values.legalPrivacy || ''}
                                    style={{ height: '60vh' }}
                                  />
                                }
                              />
                              <Tab
                                id="cookies"
                                title="Cookie Policy"
                                panel={
                                  <TextArea
                                    id="legalCookies"
                                    name="legalCookies"
                                    growVertically={false}
                                    fill={true}
                                    onChange={handleChange}
                                    value={values.legalCookies || ''}
                                    style={{ height: '60vh' }}
                                  />
                                }
                              />
                              <Tab
                                id="misc"
                                title="Misc Policy"
                                panel={
                                  <TextArea
                                    id="legalPolicy"
                                    name="legalPolicy"
                                    growVertically={false}
                                    fill={true}
                                    onChange={handleChange}
                                    value={values.legalPolicy || ''}
                                    style={{ height: '60vh' }}
                                  />
                                }
                              />
                              <Tab
                                id="allergy"
                                title="Allergy Policy"
                                panel={
                                  <TextArea
                                    id="legalAllergy"
                                    name="legalAllergy"
                                    growVertically={false}
                                    fill={true}
                                    onChange={handleChange}
                                    value={values.legalAllergy || ''}
                                    style={{ height: '60vh' }}
                                  />
                                }
                              />
                              <Tabs.Expander />
                            </Tabs>
                          </FormGroup>
                        </form>
                      )
                    }}
                  </Formik>
                )}
              </Mutation>
            </Card>
          </div>
        )
      }}
    </Query>
  )
}

export default Legal

import React from 'react'
import injectSheet from 'react-jss'
import { object, array, bool } from 'prop-types'
import map from 'lodash/map'
import filter from 'lodash/filter'
import { Tabs, Tab, Tag } from '@blueprintjs/core'
import { history } from '@stores/routerStore'
import cx from 'classnames'
import find from 'lodash/find'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'

const styles = ({ colors }) => ({
  container: {
    top: 48,
    height: 48,
    width: '100%',
    background: colors.tabBarBG,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    zIndex: 20,
  },
  scrollBar: {
    display: 'flex',
    width: '100%',
  },
  tabs: {
    display: 'flex',
    alignItems: 'center',
    background: colors.tabBarBG,

    minWidth: 'max-content',
    flexGrow: 1,
    padding: '0 60px',
    '& .bp3-tab-indicator': {
      bottom: '-9px',
    },
  },
  tab: {
    color: `${colors.tabBarTabText} !important`,
    padding: '0 5px',
    '&:hover': {
      color: colors.tabBarTabHoverText,
    },
  },
  activeTab: {
    color: `${colors.tabBarTabActiveText} !important`,
    '&:not(.activeNavLink):hover': {
      color: `${colors.tabBarTabActiveText} !important`,
    },
  },
})

const activePath = name => history.location.pathname.endsWith(name)

const getId = tabs => {
  const selectedTab = find(tabs, tab =>
    window.location.href.split(/[?#]/)[0].endsWith(tab.to)
  )
  return selectedTab && selectedTab.to
}

const TabBar = ({ classes, tabs, persistQueryParamsBetweenTabs }) => (
  <div className={classes.container}>
    <PerfectScrollBar
      options={{
        className: classes.scrollBar,
        options: { suppressScrollY: true },
      }}
    >
      <Tabs
        className={classes.tabs}
        onChange={route => {
          const routeMaybeWithParams = persistQueryParamsBetweenTabs
            ? route + location.search
            : route
          history.push(routeMaybeWithParams.toString())
        }}
        selectedTabId={getId(tabs)}
      >
        {map(
          filter(tabs, tab => !tab.hide),
          ({ to, name, count, intent }) => (
            <Tab
              key={to}
              id={to}
              className={cx(classes.tab, {
                [classes.activeTab]: activePath(to),
              })}
            >
              {name}
              {count > 0 && (
                <Tag style={{ marginLeft: 8 }} intent={intent || null}>
                  {count}
                </Tag>
              )}
            </Tab>
          )
        )}
      </Tabs>
    </PerfectScrollBar>
  </div>
)

TabBar.propTypes = {
  classes: object,
  tabs: array,
  persistQueryParamsBetweenTabs: bool,
}

export default injectSheet(styles)(TabBar)

import React, { Fragment, Component } from 'react'
import injectSheet from 'react-jss'
import { ButtonGroup, Button } from '@blueprintjs/core'

import { string, bool, object, number, shape, func, array } from 'prop-types'
import UpdateOrderStatusModal from '../UpdateOrderStatus.modal'
import AcceptNetworkOrderModal from '../AcceptNetworkOrder.modal'
import DelayNetworkOrderModal from '../DelayNetworkOrder.modal'
import CancelNetworkOrderModal from '../CancelNetworkOrder.modal'
import ReadyNetworkOrderModal from '../ReadyNetworkOrder.modal'

import { canPerformAction } from '@stores/userStore'

import { styles } from './OrderStatusInfo.styles'

import RefundOrderModal from './RefundOrderModal'

class OrderStatusInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showRefundModal: false,
      openModalName: null,
      newStatus: null,
    }
  }

  updateOrderStatus = (status, statusOptions) => {
    this.setState({
      openModalName: 'UPDATE_ORDER_STATUS',
      newStatus: status,
      updateOrderOptions: statusOptions,
    })
  }

  static defaultProps = {
    minimal: false,
    offerToPrintOnSuccess: false,
  }

  render() {
    const { id, orderStatus, fulfillmentMethod } = this.props.order

    return (
      <Fragment>
        {['COMPLETE'].includes(orderStatus) ? (
          <ButtonGroup>
            {canPerformAction('refund') && (
              <Button
                icon="undo"
                intent="danger"
                onClick={() =>
                  this.setState({
                    showRefundModal: true,
                  })
                }
              >
                Refund
              </Button>
            )}
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            {['PENDING'].includes(orderStatus) ? (
              <Button
                icon="tick"
                intent="success"
                minimal={this.props.minimal}
                onClick={() =>
                  fulfillmentMethod === 'NETWORK'
                    ? this.setState({
                        openModalName: 'ACCEPT_NETWORK_ORDER',
                      })
                    : this.updateOrderStatus('PREPARING', {
                        offerToPrintOnSuccess: this.props.offerToPrintOnSuccess,
                        updateFulfilmentTime: true,
                      })
                }
                data-test-id="order-details-accept-button"
              >
                Accept
              </Button>
            ) : (
              !this.props.minimal && <Button disabled={true}>Accept</Button>
            )}
            {['PENDING'].includes(orderStatus) ? (
              <Button
                icon="cross"
                intent={'danger'}
                minimal={this.props.minimal}
                onClick={() => this.updateOrderStatus('REJECTED')}
                data-test-id="order-details-reject-button"
              >
                Reject
              </Button>
            ) : (
              !this.props.minimal && <Button disabled={true}>Reject</Button>
            )}
            {fulfillmentMethod !== 'TABLE' &&
              ['PREPARING'].includes(orderStatus) && (
                <Button
                  icon="arrow-right"
                  minimal={this.props.minimal}
                  disabled={fulfillmentMethod === 'NETWORK'}
                  onClick={() =>
                    this.updateOrderStatus('PREPARING', {
                      updateFulfilmentTime: true,
                    })
                  }
                >
                  Add Time
                </Button>
              )}
            {['PREPARING', 'READY'].includes(orderStatus) ? (
              <Button
                icon="undo"
                intent={'warning'}
                minimal={this.props.minimal}
                onClick={() =>
                  fulfillmentMethod === 'NETWORK'
                    ? this.setState({
                        openModalName: 'CANCEL_NETWORK_ORDER',
                      })
                    : this.updateOrderStatus('ORDER_FAILURE_REFUND')
                }
                data-test-id="order-details-cancel-button"
              >
                Cancel
              </Button>
            ) : (
              !this.props.minimal && <Button disabled={true}>Cancel</Button>
            )}
            {['PREPARING'].includes(orderStatus) ? (
              <Button
                icon="tick"
                intent={'primary'}
                minimal={this.props.minimal}
                onClick={() =>
                  fulfillmentMethod === 'NETWORK'
                    ? this.setState({
                        openModalName: 'READY_NETWORK_ORDER',
                      })
                    : this.updateOrderStatus('READY')
                }
                data-test-id="order-details-ready-button"
              >
                Ready
              </Button>
            ) : (
              !this.props.minimal && <Button disabled={true}>Ready</Button>
            )}
            {['READY'].includes(orderStatus) ? (
              <Button
                icon="tick-circle"
                intent="success"
                minimal={this.props.minimal}
                onClick={() => this.updateOrderStatus('COMPLETE')}
                data-test-id="order-details-complete-button"
              >
                Complete
              </Button>
            ) : (
              !this.props.minimal && <Button disabled={true}>Complete</Button>
            )}
            {this.props.onReview && (
              <Button
                minimal
                icon="document-open"
                intent="none"
                onClick={this.props.onReview}
                data-test-id="order-details-view-button"
              >
                View
              </Button>
            )}
          </ButtonGroup>
        )}

        <AcceptNetworkOrderModal
          modalOpen={this.state.openModalName === 'ACCEPT_NETWORK_ORDER'}
          modalClose={() => {
            this.setState({
              openModalName: null,
            })
          }}
          orderId={id}
          refetchQueries={this.props.refetchQueries}
        />

        <DelayNetworkOrderModal
          modalOpen={this.state.openModalName === 'DELAY_NETWORK_ORDER'}
          modalClose={() => {
            this.setState({
              openModalName: null,
            })
          }}
          orderId={id}
          refetchQueries={this.props.refetchQueries}
        />

        <CancelNetworkOrderModal
          modalOpen={this.state.openModalName === 'CANCEL_NETWORK_ORDER'}
          modalClose={() => {
            this.setState({
              openModalName: null,
            })
          }}
          orderId={id}
          refetchQueries={this.props.refetchQueries}
        />

        <ReadyNetworkOrderModal
          modalOpen={this.state.openModalName === 'READY_NETWORK_ORDER'}
          modalClose={() => {
            this.setState({
              openModalName: null,
            })
          }}
          orderId={id}
          refetchQueries={this.props.refetchQueries}
        />

        <UpdateOrderStatusModal
          modalOpen={this.state.openModalName === 'UPDATE_ORDER_STATUS'}
          modalClose={() => {
            this.setState({
              openModalName: null,
              newStatus: null,
            })
          }}
          newOrderStatus={this.state.newStatus}
          orderId={id}
          refetchQueries={this.props.refetchQueries}
          updateOrderOptions={this.state.updateOrderOptions}
        />
        <RefundOrderModal
          orderId={this.props.order.id}
          grossTotal={this.props.order.grossTotal}
          modalOpen={this.state.showRefundModal}
          modalClose={() => {
            this.setState({ showRefundModal: false })
          }}
        />
      </Fragment>
    )
  }
}

OrderStatusInfo.propTypes = {
  classes: object,
  minimal: bool,
  onReview: func,
  refetchQueries: array,
  order: shape({
    id: string,
    orderStatus: string,
    orderNumber: string,
    asap: bool,
    paid: bool,
    grossTotal: number,
    estimatedCompletionTime: string,
    estimatedDeliveryDate: string,
    fulfillmentMethod: string,
    paymentMethod: string,
    createdAt: string,
    acceptedAt: string,
    updatedAt: string,
    outlet: shape({
      name: string,
      restaurant: object,
    }),
  }).isRequired,
}
export default injectSheet(styles)(OrderStatusInfo)

import {
  Button,
  Classes,
  Intent,
  Popover,
  PopoverInteractionKind,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import GET_RESTAURANTS from '../List/queries/getRestaurants.query'
import ONBOARDING_DENY from './mutations/deny.mutation'

const DenyOnboardingButton = ({ restaurant }) => {
  const [denyReason, setDenyReason] = useState(null)

  return restaurant ? (
    <Mutation
      mutation={ONBOARDING_DENY}
      onError={defaultErrorHandler}
      onCompleted={() => successToast('Application Denied')}
      refetchQueries={[
        {
          query: GET_RESTAURANTS,
          variables: { statusOnboarding: ['SIGNUP', 'ONBOARDING'] },
        },
      ]}
    >
      {denyApplication => (
        <Popover
          interactionKind={PopoverInteractionKind.CLICK}
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        >
          <Button intent={Intent.DANGER} minimal icon="cross">
            Deny
          </Button>
          <div>
            <h5 className={Classes.HEADING}>Confirmation</h5>
            <p>
              Are you sure you want to deny <strong>"{restaurant.name}"</strong>
              ?
            </p>
            <p>Reason:</p>
            <div className="bp3-input-group">
              <input
                type="text"
                id="reason"
                name="reason"
                onChange={e => setDenyReason(e.target.value)}
                placeholder="Deny Reason"
                className="bp3-input"
              />
            </div>
            <br />
            <br />
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                className={Classes.POPOVER_DISMISS}
                text="Cancel"
                minimal
              />

              <Button
                intent={Intent.DANGER}
                onClick={_ => {
                  denyApplication({
                    variables: {
                      id: restaurant.id,
                      denyReason,
                    },
                  })
                }}
                text="Deny"
              />
            </div>
          </div>
        </Popover>
      )}
    </Mutation>
  ) : null
}

export default DenyOnboardingButton

import React, { Fragment } from 'react'
import { func, bool, array } from 'prop-types'
import Filter from '@components/Toolbar/Filter'

import GET_PARTNER_IDS from './queries/getPartnerIds.query'
import { isPartner, isPlatformLevelUser } from '../../stores/userStore'
import { Divider } from '@blueprintjs/core'

export const PartnerFilter = ({
  disabled,
  icon,
  partnerFilter,
  onChange,
  divider = false,
}) =>
  (isPartner() || isPlatformLevelUser()) && (
    <Fragment>
      <Filter
        name="Partner"
        icon={icon}
        disabled={disabled}
        filter={partnerFilter}
        onChange={onChange}
        query={GET_PARTNER_IDS}
        dataPath="getPartners.partners"
      />
      {divider && <Divider />}
    </Fragment>
  )

PartnerFilter.propTypes = {
  onChange: func,
  marketplaceFilter: array,
  disabled: bool,
  icon: bool,
  divider: bool,
}

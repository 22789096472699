import { Drawer } from '@blueprintjs/core'
import React from 'react'
import CreateDiscountVoucher from './CreateDiscountVoucher'

const CreateVoucherDrawer = ({ isOpen, marketplaceId, drawerClose }) => {
  return (
    <Drawer isOpen={isOpen} onClose={drawerClose} title="Discount Code">
      <CreateDiscountVoucher
        marketplaceId={marketplaceId}
        drawerClose={drawerClose}
      />
    </Drawer>
  )
}

export default CreateVoucherDrawer

import { Icon } from '@blueprintjs/core'
import React, { Fragment } from 'react'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import MUTE_TERMINAL from '@components/Terminal/mutations/muteTerminal.mutation'
import moment from 'moment'

const MutedStatus = ({ terminalId, isMuted, friendlyName }) => {
  return (
    <Mutation
      mutation={MUTE_TERMINAL}
      onError={defaultErrorHandler}
      onCompleted={() => {
        isMuted
          ? successToast(`${friendlyName} has been muted for 24 hours`)
          : successToast(`${friendlyName} has been unmuted`)
      }}
    >
      {muteTerminal => (
        <Fragment>
          {isMuted ? (
            <Icon
              icon="volume-off"
              onClick={() =>
                muteTerminal({
                  variables: { id: terminalId, mutedUntil: null },
                })
              }
              className={'bp3-mute-toggle'}
            />
          ) : (
            <Icon
              icon="volume-up"
              onClick={() =>
                muteTerminal({
                  variables: {
                    id: terminalId,
                    mutedUntil: moment()
                      .add(1, 'day')
                      .format(),
                  },
                })
              }
              className={'bp3-mute-toggle'}
            />
          )}
        </Fragment>
      )}
    </Mutation>
  )
}

export default MutedStatus

import React, { useState } from 'react'
import injectSheet from 'react-jss'
import { Tag, Icon } from '@blueprintjs/core'
import cx from 'classnames'
import QuickEdit from './QuickEdit'

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '34px',
    alignItems: 'center',
    padding: '2px 8px',
    position: 'relative',

    '&:hover': {
      background: 'rgba(191, 204, 214, 0.4)',
    },
  },
  shallow: {
    width: '500px',
  },
  deep: {
    width: '600px',
  },
  active: {
    '& $leftIcon svg, & $secondaryLabel svg': {
      fill: '#fff',
    },
  },
  hidden: {
    opacity: '0.4',
  },
  leftIcon: {
    marginRight: '7px',
  },
  label: {
    width: '100%',
  },
  quickEditContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  secondaryLabel: {
    marginLeft: 'auto',
  },
})

const MenuRow = ({
  classes,
  onClick,
  icon,
  iconColor = '#5c7080',
  label,
  soldCount,
  secondaryLabel,
  cursor = 'move',
  isOpen = null,
  active,
  hidden,
  item,
  depth,
}) => {
  const [isHovered] = useState('')
  return (
    <div
      style={{ cursor }}
      className={cx(
        classes.container,
        { [classes.hidden]: hidden },
        { [classes.active]: active || isHovered },
        depth
      )}
    >
      <div onClick={onClick} className={classes.quickEditContainer}>
        {isOpen ? (
          <Icon
            className={classes.leftIcon}
            icon={isOpen ? 'chevron-down' : 'chevron-right'}
            color={iconColor}
          />
        ) : null}
        {icon && (
          <Icon className={classes.leftIcon} icon={icon} color={iconColor} />
        )}
        {label && <span className={classes.label}>{label}</span>}
        {!!item && <QuickEdit item={item} />}
        {!!soldCount && <Tag intent={'danger'}>{soldCount} Sold</Tag>}
        {secondaryLabel && (
          <span className={classes.secondaryLabel}>{secondaryLabel}</span>
        )}
      </div>
    </div>
  )
}

export default injectSheet(styles)(MenuRow)

import React, { useEffect, useContext, useState } from 'react'
import { matchType } from '@utils/types'
import { object } from 'prop-types'
import { Intent } from '@blueprintjs/core'
import { Switch, Redirect, Route } from 'react-router-dom'
import Details from './Details'
import Orders from './Orders'
import CustomerReviews from './CustomerReviews'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import ArchiveCustomerModal from './ArchiveCustomerModal/ArchiveCustomerModal'

const Customer = ({ match, product, history }) => {
  const [isArchiveCustomerModalOpen, setIsArchiveCustomerModalOpen] =
    useState(false)
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    configurePageLayout({
      product,
      button: {
        text: 'Archive Customer',
        intent: Intent.DANGER,
        icon: 'delete',
        onClick: () => {
          return setIsArchiveCustomerModalOpen(true)
        },
      },
      tabs: [
        { to: `${match.url}/details`, name: 'Details' },
        { to: `${match.url}/orders`, name: 'Orders' },
        { to: `${match.url}/reviews`, name: 'Reviews' },
      ],
    })
  }, [configurePageLayout, product, match.url, setIsArchiveCustomerModalOpen])

  return (
    <section>
      <Switch>
        <Redirect
          from="/customer/:customer"
          to="/customer/:customer/details"
          exact
        />
        <Route path={`${match.path}/orders`} exact={true} component={Orders} />
        <Route
          exact
          path={`${match.path}/reviews`}
          component={CustomerReviews}
        />
        <Route path={`${match.path}/details`} component={Details} />
      </Switch>
      <ArchiveCustomerModal
        isModalOpen={isArchiveCustomerModalOpen}
        customerId={match.params.customer}
        closeModal={() => setIsArchiveCustomerModalOpen(false)}
        history={history}
      ></ArchiveCustomerModal>
    </section>
  )
}

Customer.propTypes = {
  match: matchType.isRequired,
  location: object,
  classes: object,
  history: object,
}
export default Customer

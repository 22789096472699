import React, { Fragment, useState } from 'react'
import {
  Card,
  HTMLTable,
  NonIdealState,
  Callout,
  Button,
  Drawer,
  DrawerSize,
} from '@blueprintjs/core'
import { get } from 'lodash'
import { matchType } from '@utils/types'
import Query from '@components/Query/Query'
import { Query as CacheQuery } from 'react-apollo'
import { toggleModal } from '@utils/cacheHelpers'
import moment from 'moment'
import GET_STANDARD_RESTAURANT_DISCOUNTS from './queries/getStandardRestaurantDiscounts.query'
import AddDiscountModal from './modals/AddDiscountModal'
import OPEN_MODAL from './queries/openModal.clientQuery'
import { canPerformAction } from '@stores/userStore'
import DiscountDetails from './DiscountDetails'
import { StringParam, useQueryParam } from 'use-query-params'
import Currency from '@components/Currency/Currency'

const tableHead = [
  {
    key: 'name',
    content: 'Name',
  },
  {
    key: 'discountPercentage',
    content: 'Discount %',
  },
  {
    key: 'discountAmount',
    content: 'Fixed Discount',
  },
  {
    key: 'startDate',
    content: 'Availability',
  },

  {
    key: 'daysOfWeek',
    content: 'Days Allowed',
  },
  {
    key: 'minimumSubtotalGross',
    content: 'Minimum Subtotal',
  },
]

const Discounts = ({ match }) => {
  const [discountId, onChangeDiscountId] = useQueryParam(
    'discountId',
    StringParam
  )
  const businessId = match.params.restaurants
  const [outlets, setOutlets] = useState([])

  return (
    <Fragment>
      <CacheQuery query={OPEN_MODAL}>
        {({ data: { addDiscountModal } }) => (
          <Fragment>
            {!canPerformAction('editDiscount') && (
              <Callout intent="warning" style={{ marginBottom: 24 }}>
                Please contact your marketplace administrator to modify
                restaurant discounts.
              </Callout>
            )}
            <Card className="bp3-nopad bp3-scrollable">
              <Fragment>
                <Query
                  query={GET_STANDARD_RESTAURANT_DISCOUNTS}
                  variables={{ id: businessId }}
                  loaderTitle={'Loading Discounts'}
                >
                  {({ getRestaurants: { restaurants } }) => {
                    const discounts = get(restaurants, '[0].discounts', [])
                    setOutlets(get(restaurants, '[0].outlets', []))

                    const allowOutletSelection = restaurants
                      .flatMap(restaurant =>
                        restaurant.outlets.map(
                          outlet => outlet.marketplace.enableCustomerV2Client
                        )
                      )
                      .some(Boolean)
                    const isVatRegistered = get(
                      restaurants,
                      '[0].isVATregistered',
                      false
                    )

                    return (
                      <Fragment>
                        {isVatRegistered ? (
                          <Callout intent="warning">
                            Discounts are not available for this business
                            configuration, please contact support for
                            assistance.
                          </Callout>
                        ) : (
                          <Fragment>
                            {discounts.length > 0 ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <HTMLTable interactive={true} bordered={false}>
                                  <thead>
                                    <tr>
                                      {tableHead.map(({ key, content }) => (
                                        <th key={key}>{content}</th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {discounts.map(discount => {
                                      const {
                                        id,
                                        name,
                                        discountPercentage,
                                        discountAmount,
                                        startDate,
                                        endDate,
                                        daysOfWeek,
                                        minimumSubtotalGross,
                                      } = discount
                                      return (
                                        <tr key={id}>
                                          <td>
                                            {canPerformAction(
                                              'editDiscount'
                                            ) ? (
                                              <a
                                                onClick={() =>
                                                  onChangeDiscountId(id)
                                                }
                                              >
                                                {name}
                                              </a>
                                            ) : (
                                              name
                                            )}
                                          </td>
                                          <td>
                                            {discountPercentage ? (
                                              `${discountPercentage}%`
                                            ) : (
                                              <span className="bp3-text-disabled">
                                                -
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            {discountAmount ? (
                                              <Currency
                                                amount={discountAmount}
                                              />
                                            ) : (
                                              <span className="bp3-text-disabled">
                                                -
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            {startDate ? (
                                              `${moment(startDate).format(
                                                'DD.MM.YYYY'
                                              )} - ${moment(endDate).format(
                                                'DD.MM.YYYY'
                                              )}`
                                            ) : (
                                              <span className="bp3-text-disabled">
                                                -
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            {daysOfWeek.length ? (
                                              `${daysOfWeek.reduce(
                                                (acc, day) =>
                                                  acc.concat(
                                                    moment()
                                                      .isoWeekday(day)
                                                      .format('ddd')
                                                  ),
                                                []
                                              )}`
                                            ) : (
                                              <span className="bp3-text-disabled">
                                                -
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            <Currency
                                              amount={minimumSubtotalGross}
                                            />
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </HTMLTable>
                              </div>
                            ) : (
                              <NonIdealState
                                icon="add"
                                title="No Discounts"
                                description="You can setup time based fixed or percentage discounts for your customers basket. Great for promotions."
                                action={
                                  <Button
                                    text="Add Discount"
                                    icon="plus"
                                    onClick={() =>
                                      toggleModal({
                                        addDiscountModal: true,
                                      })
                                    }
                                  />
                                }
                              />
                            )}
                            <AddDiscountModal
                              modalOpen={addDiscountModal}
                              modalClose={() => {
                                toggleModal({
                                  addDiscountModal: false,
                                })
                              }}
                              restaurantId={match.params.restaurants}
                              allowOutletSelection={allowOutletSelection}
                            />
                          </Fragment>
                        )}
                      </Fragment>
                    )
                  }}
                </Query>
              </Fragment>
            </Card>
          </Fragment>
        )}
      </CacheQuery>
      <Drawer
        title="Discount Details"
        isOpen={!!discountId}
        onClose={() => onChangeDiscountId(undefined)}
        size={DrawerSize.LARGE}
      >
        <DiscountDetails
          discountId={discountId}
          businessId={businessId}
          outlets={outlets}
          closeDrawer={() => onChangeDiscountId(undefined)}
        />
      </Drawer>
    </Fragment>
  )
}

Discounts.propTypes = {
  match: matchType,
}

export default Discounts

import React, { useEffect, useState } from 'react'
import { unsubscribe } from '@services/client'
import { object, func } from 'prop-types'
import injectSheet from 'react-jss'
import ReactSVG from 'react-svg'
import { withFormik } from 'formik'
import { Mutation } from 'react-apollo'
import { Card, Spinner } from '@blueprintjs/core'
import ls from '@utils/localStorage'
import get from 'lodash/get'
import { errorToast } from '@utils/toast'
import { Link } from 'react-router-dom'

import RegisterForm from './RegisterForm'
import styles from '@components/Login/Login.styles'

import Query from '@components/Query/Query'
import REGISTER from './mutations/register.mutation'
import logo from '@assets/logo.svg'
import loginIcon from '@assets/icons/login.svg'
import queryString from 'query-string'
import CHECK_USER_CAN_REGISTER from './queries/checkUserCanRegister.query'
import { newUserRegisterStructure } from '../../validation/user'
import * as yup from 'yup'
import * as Sentry from '@sentry/react'

const handleRegister = (data, history) => {
  ls.set('canUse2FA', data.canUse2FA)
  ls.set('phoneNumber', data.phoneNumber)
  if (data.canUse2FA) {
    history.push('/2fa')
  } else {
    history.push('/validate-phone')
  }
}

const RegisterWrapper = props => {
  const { classes, history } = props
  const { container, loginContainer, logoContainer, loginForm, icon } = classes
  const { uid, token } = queryString.parse(location.search)
  const [isInitialised, setIsInitialised] = useState(false)

  useEffect(() => {
    if (!uid || !token) {
      history.push('/')
    }
    ls.set('partialJwt', token)

    // if user is already logged in, force them to logout
    if (ls.get('jwt')) {
      localStorage.clear()
      unsubscribe() // clear cache and reset link state defaults
      Sentry.setUser(null)

      location.reload()
    }

    setIsInitialised(true)
  }, [history, token, uid])

  return isInitialised ? (
    <Query query={CHECK_USER_CAN_REGISTER}>
      {({ checkUserCanRegister }) => {
        return (
          <div className={container}>
            <div className={loginContainer}>
              <ReactSVG src={logo} alt={'logo'} className={logoContainer} />
              <Card elevation="2" className={loginForm}>
                <ReactSVG src={loginIcon} className={icon} />
                {checkUserCanRegister.canRegister ? (
                  <Mutation
                    mutation={REGISTER}
                    onError={error => {
                      const serverErrors = get(error, 'graphQLErrors', [])

                      // fallback for errors that weren't returned by the server
                      if (!serverErrors.length) {
                        return errorToast(error.message)
                      }

                      // loop through custom server errors displaying error toast
                      for (const serverError of serverErrors) {
                        errorToast(serverError.message)
                      }

                      props.setSubmitting(false)
                    }}
                    onCompleted={({ register }) => {
                      handleRegister(register.user, history)
                    }}
                  >
                    {register => (
                      <RegisterForm
                        classes={classes}
                        mutation={register}
                        data={checkUserCanRegister}
                        {...props}
                      />
                    )}
                  </Mutation>
                ) : (
                  <p>
                    This account has already been registered please{' '}
                    <Link to="/login">login</Link>
                  </p>
                )}
              </Card>
            </div>
          </div>
        )
      }}
    </Query>
  ) : (
    <Spinner />
  )
}

const Register = withFormik({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    password: '',
    passwordConfirm: '',
  }),
  validationSchema: yup.object(newUserRegisterStructure),
})(RegisterWrapper)

RegisterWrapper.propTypes = {
  classes: object,
  history: object,
  setSubmitting: func,
}

export default injectSheet(styles)(Register)
export { Register }

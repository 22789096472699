import React from 'react'
import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import { modalType } from '@utils/types'
import { string } from 'prop-types'
import { successToast } from '@utils/toast'
// import { addAppValidation } from './validation'
import AppImportForm from './AppImportForm'
import { Mutation } from 'react-apollo'
import ADD_APP from './mutations/appApp.mutation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import GET_MARKETPLACE_APPS_BY_PARTNER_ID from './queries/getMarketplaceAppsByPartnerId.query'
import { appValidation } from './validation'

const AppExistingImport = ({ modalOpen, modalClose, location }) => (
  <Dialog isOpen={modalOpen} onClose={modalClose} title="Import App">
    <Mutation
      mutation={ADD_APP}
      onError={defaultErrorHandler}
      onCompleted={() => {
        successToast('Existing app was imported')
        modalClose()
      }}
      refetchQueries={[
        {
          query: GET_MARKETPLACE_APPS_BY_PARTNER_ID,
          variables: {
            partnerId: location.split('/')[2],
          },
        },
      ]}
    >
      {addApp => {
        return (
          <Formik
            validationSchema={appValidation}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{}}
            onSubmit={async (values, actions) => {
              await addApp({
                variables: {
                  ...values,
                },
              })
              actions.setSubmitting(false)
            }}
          >
            {props => <AppImportForm location={location} {...props} />}
          </Formik>
        )
      }}
    </Mutation>
  </Dialog>
)

AppExistingImport.propTypes = {
  ...modalType,
  appId: string,
  device: string,
  version: string,
}

export default AppExistingImport

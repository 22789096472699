import React from 'react'
import { matchType } from '@utils/types'
import ReviewsTable from '@components/Reviews/ReviewsTable'
import Query from '@components/Query/Query'
import GET_ALLOW_REVIEWS from './queries/getAllowReview.query'
import get from 'lodash/get'
import { NonIdealState } from '@blueprintjs/core'
import { isAtLeastOutletUser } from '@stores/userStore'
import { NoPermissions } from '@components/UI/Permission/Permission'

const OutletReviews = ({ match }) => {
  const queryVars = {
    outletId: match.params.outlet,
    hideOutlet: true,
  }

  if (!isAtLeastOutletUser()) return <NoPermissions />

  return (
    <Query
      query={GET_ALLOW_REVIEWS}
      variables={{ id: match.params.outlet }}
      loaderTitle={'Loading Reviews'}
    >
      {({ getOutlets: { outlets } }) => {
        const allowReviews = get(outlets, '[0]marketplace.allowReviews')

        return allowReviews ? (
          <ReviewsTable queryVars={queryVars} />
        ) : (
          <NonIdealState
            icon="search"
            title="Reviews are disabled."
            description="Reviews are disabled at a marketplace level."
          />
        )
      }}
    </Query>
  )
}

OutletReviews.propTypes = {
  match: matchType,
}

export default OutletReviews

import {
  Callout,
  Card,
  Checkbox,
  FormGroup,
  Icon,
  Intent,
  Spinner,
  TextArea,
} from '@blueprintjs/core'
import { Col, Row } from 'react-flexbox-grid'

import React, { Fragment } from 'react'
import GET_LIVE_ESTIMATE_FOR_CAMPAIGN from '@components/Marketing/queries/getLiveEstimateForCampaign.query'
import { count } from 'sms-length'
import FetchQuery from '@components/FetchQuery/FetchQuery'
import { errorToast } from '@utils/toast'
import moment from 'moment'
import { Query } from 'react-apollo'
import iphoneBg from '@assets/ios-iphone-background.png'
import messagesIcon from '@assets/icons/messages.png'
import config from '@config/config'

const smsNotificationCharacterLimit = { GSM_7BIT: 1600, NOT_GSM_7BIT: 630 }
const maxApproxLengthOfStopText = 40 // "STOP: https://staging.msgs.top/XXXXXXXXX".length = 40
const approxMarketingSMSCharge = 0.04 // This is a bit random but based that currently SMS price on AWS to the UK is USD 0.038 + VAT

export const InnerFormSMSSection = ({
  disabled,
  marketplaceId,
  marketplaceCNAME,
  allowSMSMarketing,
  handleChange,
  values,
  errors,
  classes,
}) => {
  const smsCharacterLimit = allowSMSMarketing
    ? smsNotificationCharacterLimit[
        count(values.sms.body).encoding == 'GSM_7BIT'
          ? 'GSM_7BIT'
          : 'NOT_GSM_7BIT'
      ] - maxApproxLengthOfStopText
    : 0
  const smsCharactersLeft = smsCharacterLimit - values.sms.body.length

  return (
    <Fragment>
      <Checkbox
        disabled={disabled}
        checked={values.smsChannel}
        name="smsChannel"
        label="SMS"
        onChange={handleChange}
      />
      {values.smsChannel ? (
        <Card>
          <Row>
            <Col md={6}>
              <div>
                {values.segment && values.type ? (
                  <Query
                    query={GET_LIVE_ESTIMATE_FOR_CAMPAIGN}
                    variables={{
                      marketplaceId,
                      segmentId: values.segment,
                      channel: 'SMS',
                      type: values.type,
                    }}
                  >
                    {({ data, loading }) => (
                      <Callout icon={loading ? 'reset' : 'updated'}>
                        {loading ? (
                          'Calculating, please wait...'
                        ) : (
                          <div>
                            {!data.getLiveEstimateForCampaign
                              ? 0
                              : data.getLiveEstimateForCampaign.estimate || 0}
                            {` targeted customers in 
                                    ${
                                      count(values.sms.body).messages || 1
                                    } part${
                              count(values.sms.body).messages > 1 ? 's' : ''
                            }, estimated cost £${
                              !data.getLiveEstimateForCampaign
                                ? 0
                                : (
                                    data.getLiveEstimateForCampaign.estimate *
                                      count(values.sms.body).messages *
                                      approxMarketingSMSCharge || 0
                                  ).toFixed(2)
                            }.`}
                          </div>
                        )}
                      </Callout>
                    )}
                  </Query>
                ) : (
                  <Callout intent={Intent.PRIMARY}>
                    Please select target segment and campaign type to calculate
                    estimated costs.
                  </Callout>
                )}
              </div>
              <br />
              <FormGroup
                label="Body"
                labelFor="sms.body"
                labelInfo={
                  <span>
                    ({smsCharactersLeft}{' '}
                    {smsCharactersLeft < 10 && (
                      <Icon icon="warning-sign" intent={Intent.WARNING} />
                    )}{' '}
                    Characters Remaining)
                  </span>
                }
                helperText={
                  (errors.sms && errors.sms.body) || (
                    <Fragment>
                      <b>Note</b>: All prices are estimates. Charges apply for
                      SMS that are delivered successfully.
                    </Fragment>
                  )
                }
                intent={errors.sms ? Intent.DANGER : Intent.NONE}
              >
                <TextArea
                  disabled={disabled}
                  name="sms.body"
                  value={values.sms.body}
                  growVertically={true}
                  style={{ width: 350, height: 400 }}
                  onChange={handleChange}
                  maxLength={smsCharacterLimit}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <Card
                style={{
                  background: `url(${iphoneBg}) no-repeat`,
                  minHeight: 240,
                  width: 400,
                  margin: '0 auto',
                  color: 'black',
                }}
              >
                <ul className={classes.iosNotifications}>
                  <li className={classes.iosNotificationItem}>
                    <div className={classes.iosNotificationItemHeader}>
                      <div className={classes.iosNotificationItemTitle}>
                        <img src={messagesIcon} width="16" height="16" />
                        <span style={{ marginLeft: 8 }}>
                          {values.smsSenderId}
                        </span>
                      </div>
                      <div>{moment(values.startDateTime).format(`h:mma`)}</div>
                    </div>
                    <pre
                      style={{
                        'font-family':
                          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
                        'white-space': 'pre-wrap',
                        'word-break': 'break-word',
                      }}
                    >
                      {values.sms.body || 'SMS Body'}
                      {'\n'}
                      {'STOP: '}
                      <FetchQuery
                        path={`https://${marketplaceCNAME}`}
                        domain={config.environmentDomainUrlshortener}
                        fetchOptions={{ method: 'POST' }}
                      >
                        {({ loading, error, data: shorterUrl }) => {
                          if (loading) {
                            return (
                              <div
                                style={{
                                  display: 'inline-block',
                                  marginLeft: '10px',
                                }}
                              >
                                <Spinner size={10} />
                              </div>
                            )
                          }
                          // toast the error
                          if (error) {
                            if (typeof error !== 'string') {
                              error = 'Error!'
                            }
                            return errorToast(error)
                          }

                          return (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={shorterUrl.url}
                            >
                              {shorterUrl.url}
                            </a>
                          )
                        }}
                      </FetchQuery>
                    </pre>
                  </li>
                </ul>
              </Card>
            </Col>
          </Row>
        </Card>
      ) : (
        <Fragment>
          <Callout icon="issue">SMS messages will not be sent.</Callout>
          <br />
        </Fragment>
      )}
    </Fragment>
  )
}

import { gql } from '@services/client'

const GET_ALL_USERS = gql`
  query getAllUsersManagement(
    $where: UserWhereInput
    $skip: Int
    $first: Int
    $last: Int
  ) {
    getUsers(where: $where, skip: $skip, first: $first, last: $last) {
      users {
        id
        email
        firstName
        lastName
        phoneNumber
        hasRegistered
        role {
          id
          key
          title
        }
        ownsPlatforms {
          id
          name
        }
        ownsPartners {
          id
          name
        }
        ownsRestaurants {
          id
          name
        }
        ownsOutlets {
          id
          name
        }
        ownsMarketplaces {
          id
          name
        }
      }
      totalCount
    }
  }
`

export default GET_ALL_USERS

import React, { Fragment, useContext, useMemo } from 'react'
import Query from '@components/Query/Query'
import CUSTOMER_ORDERS_FOR_ANALYTICS from './queries/customerOrdersAnalytics.query'
import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Divider,
  MenuItem,
  NonIdealState,
  Popover,
  Position,
  Spinner,
} from '@blueprintjs/core'
import { Row, Col } from 'react-simple-flex-grid'
import { transformCustomerDiscountsRawData } from '@components/Analytics/util/transformCustomerDiscountsRawData'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'
import { ResolutionMap } from '@components/Analytics/util/maps'
import { capitalize } from 'lodash'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { renderTooltip } from './utils/renderCustomerAnalyticsTooltip'
import { userAgentColorGenerator } from '@components/Analytics/util/userAgentColorGenerator'
import FilterRow from '@components/FilterRow/FilterRow'
import { CustomerAnalyticsContext } from './CustomerAnalyticsContext'
import { Select } from '@blueprintjs/select'
import {
  PaymentMethodsMap,
  FulfillmentMethodsMap,
  OrderStatusesMap,
  ANALYTICS_COLORS,
} from '../Analytics/styles'
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params'
import Currency from '@components/Currency/Currency'

const itemRenderer = (option, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null
  }

  return (
    <MenuItem
      key={option}
      onClick={handleClick}
      multiline={true}
      text={capitalize(ResolutionMap[option])}
    />
  )
}

const CustomerAnalytics = ({ customer }) => {
  // should be safe if under 512 chars with cloudfront // atm at 230 chars
  // https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/cloudfront-limits.html#limits-whitelisted-query-strings
  const [resolution = ResolutionMap.week, onChangeResolution] = useQueryParam(
    'resolution',
    StringParam
  )
  const [showBlankEntries = true, onChangeShowBlankEntries] = useQueryParam(
    'showBlankEntries',
    BooleanParam
  )
  const [showOutlets = false, onChangeShowOutlets] = useQueryParam(
    'showOutlets',
    BooleanParam
  )
  const [showNumberOfOrders = true, onChangeShowNumberOfOrders] = useQueryParam(
    'showNumberOfOrders',
    BooleanParam
  )
  const [avgOrderValue = true, onChangeAvgOrderValue] = useQueryParam(
    'avgOrderValue',
    BooleanParam
  )
  const [showTotals = false, onChangeShowTotals] = useQueryParam(
    'showTotals',
    BooleanParam
  )
  const [showTotalDiscounted = false, onChangeShowTotalDiscounted] =
    useQueryParam('showTotalDiscounted', BooleanParam)
  const [showPaymentMethods = false, onChangeShowPaymentMethods] =
    useQueryParam('showPaymentMethods', BooleanParam)
  const [showFulfillmentMethods = false, onChangeShowFulfillmentMethods] =
    useQueryParam('showFulfillmentMethods', BooleanParam)
  const [showOrdersStatus = false, onChangeShowOrdersStatus] = useQueryParam(
    'showOrdersStatus',
    BooleanParam
  )
  const [showUserAgents = false, onChangeShowUserAgents] = useQueryParam(
    'showUserAgents',
    BooleanParam
  )

  const { rawCustomerData, setRawCustomerData } = useContext(
    CustomerAnalyticsContext
  )

  const { createdAt } = customer
  const { transformedData, userAgentEntries } = useMemo(
    () =>
      transformCustomerDiscountsRawData(
        rawCustomerData,
        resolution,
        createdAt,
        showBlankEntries
      ),
    [rawCustomerData, resolution, showBlankEntries, createdAt]
  )

  const userAgentColorMap = useMemo(
    () => userAgentColorGenerator(userAgentEntries),
    [userAgentEntries]
  )

  return (
    <Fragment>
      <FilterRow>
        <div>
          <Button icon="chart" disabled minimal />
          <Select
            filterable={false}
            activeItem={resolution}
            items={Object.values(ResolutionMap)}
            itemRenderer={itemRenderer}
            onItemSelect={item => onChangeResolution(item)}
          >
            <Button
              text={capitalize(resolution)}
              icon="small-tick"
              minimal={true}
            />
          </Select>

          <Divider />
          <ButtonGroup>
            <Popover
              position={Position.BOTTOM_LEFT}
              boundary="window"
              defaultIsOpen={false}
            >
              <Button
                text="Series"
                rightIcon="double-caret-vertical"
                minimal
                icon="series-add"
              />
              <PerfectScrollBar>
                <div
                  style={{
                    padding: '12px',
                  }}
                >
                  <Checkbox
                    checked={showNumberOfOrders}
                    onChange={() =>
                      onChangeShowNumberOfOrders(!showNumberOfOrders)
                    }
                    label="Number of Orders"
                  />
                  <Checkbox
                    checked={avgOrderValue}
                    onChange={() => onChangeAvgOrderValue(!avgOrderValue)}
                    label="Average Order Value"
                  />
                  <Checkbox
                    checked={showTotals}
                    onChange={() => onChangeShowTotals(!showTotals)}
                    label="Totals"
                  />
                  <Checkbox
                    checked={showTotalDiscounted}
                    onChange={() =>
                      onChangeShowTotalDiscounted(!showTotalDiscounted)
                    }
                    label="Total Discounted"
                  />
                  <Checkbox
                    checked={showOutlets}
                    onChange={() => onChangeShowOutlets(!showOutlets)}
                    label="Outlets"
                  />
                  <Checkbox
                    checked={showPaymentMethods}
                    onChange={() =>
                      onChangeShowPaymentMethods(!showPaymentMethods)
                    }
                    label="Payment"
                  />
                  <Checkbox
                    checked={showFulfillmentMethods}
                    onChange={() =>
                      onChangeShowFulfillmentMethods(!showFulfillmentMethods)
                    }
                    label="Fulfillment"
                  />
                  <Checkbox
                    checked={showUserAgents}
                    onChange={() => onChangeShowUserAgents(!showUserAgents)}
                    label="User Agents"
                  />
                  <Checkbox
                    checked={showOrdersStatus}
                    onChange={() => onChangeShowOrdersStatus(!showOrdersStatus)}
                    label="Orders Status"
                  />
                </div>
              </PerfectScrollBar>
            </Popover>
          </ButtonGroup>

          <Divider />

          <ButtonGroup>
            <Popover
              position={Position.BOTTOM_LEFT}
              boundary="window"
              defaultIsOpen={false}
            >
              <Button rightIcon="caret-down" minimal icon="more" />
              <PerfectScrollBar>
                <div
                  style={{
                    padding: '12px',
                  }}
                >
                  <Checkbox
                    checked={showBlankEntries}
                    onChange={() => onChangeShowBlankEntries(!showBlankEntries)}
                    label={`Display ${capitalize(resolution)}s with no Orders`}
                  />
                </div>
              </PerfectScrollBar>
            </Popover>
          </ButtonGroup>
        </div>
      </FilterRow>
      <Query
        query={CUSTOMER_ORDERS_FOR_ANALYTICS}
        variables={{
          customerId: customer.id,
        }}
        loaderTitle={'Loading Analytics'}
      >
        {({ loading, error, customerOrdersForAnalytics: rawOrdersData }) => {
          if (loading) return <Spinner size={Spinner.SIZE_STANDARD} />
          if (error) {
            return (
              <Card>
                <NonIdealState
                  icon="error"
                  description="Unable to load analytics data"
                />
              </Card>
            )
          }

          if (!rawOrdersData || !rawOrdersData.length)
            return (
              <Card>
                <NonIdealState
                  icon="list"
                  description="No orders from this customer or for the current selection"
                />
              </Card>
            )

          setRawCustomerData(rawOrdersData)

          return transformedData ? (
            <Row gutter={24}>
              <Col span={12}>
                <ResponsiveContainer width="100%" height={500}>
                  <ComposedChart
                    data={transformedData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="5 5" />
                    <XAxis dataKey="name" />
                    <YAxis
                      yAxisId="left"
                      tickFormatter={val => <Currency amount={val} />}
                    />
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      allowDecimals={false}
                      label={
                        showOrdersStatus ||
                        showFulfillmentMethods ||
                        showNumberOfOrders ||
                        showPaymentMethods ||
                        showUserAgents
                          ? {
                              value: 'Number of Orders',
                              angle: -90,
                              position: 'right',
                            }
                          : null
                      }
                    />

                    {/* total orders value */}
                    {showTotals && (
                      <Fragment>
                        <Bar
                          dataKey="subTotalWithNoDiscounts"
                          stackId="totalsBar"
                          fill={ANALYTICS_COLORS.noDiscountFill}
                          yAxisId="left"
                        />
                        <Bar
                          dataKey="subTotalWithRegularDiscounts"
                          stackId="totalsBar"
                          fill={ANALYTICS_COLORS.regularDiscountFill}
                          yAxisId="left"
                        />
                        <Bar
                          dataKey="subTotalWithMemberDiscounts"
                          stackId="totalsBar"
                          fill={ANALYTICS_COLORS.memberDiscountFill}
                          yAxisId="left"
                        />
                        <Bar
                          dataKey="subTotalWithVoucherDiscounts"
                          stackId="totalsBar"
                          fill={ANALYTICS_COLORS.vouchersDiscountFill}
                          yAxisId="left"
                        />
                      </Fragment>
                    )}

                    {/* number of orders */}
                    {showNumberOfOrders && (
                      <Fragment>
                        <Bar
                          dataKey="numberOfOrdersWithNoDiscount"
                          stackId="numberOfOrdersBar"
                          fill={ANALYTICS_COLORS.noDiscountFill}
                          yAxisId="right"
                        />
                        <Bar
                          dataKey="numberOfOrdersWithRegularDiscount"
                          stackId="numberOfOrdersBar"
                          fill={ANALYTICS_COLORS.regularDiscountFill}
                          yAxisId="right"
                        />
                        <Bar
                          dataKey="numberOfOrdersWithMemberDiscount"
                          stackId="numberOfOrdersBar"
                          fill={ANALYTICS_COLORS.memberDiscountFill}
                          yAxisId="right"
                        />
                        <Bar
                          dataKey="numberOfOrdersWithVoucherDiscount"
                          stackId="numberOfOrdersBar"
                          fill={ANALYTICS_COLORS.vouchersDiscountFill}
                          yAxisId="right"
                        />
                      </Fragment>
                    )}

                    {/* total discounted */}
                    {showTotalDiscounted && (
                      <Fragment>
                        <Bar
                          dataKey="subTotalDiscountedRegularDiscounts"
                          stackId="totalsDiscountedBar"
                          fill={ANALYTICS_COLORS.regularDiscountFill}
                          yAxisId="left"
                        />
                        <Bar
                          dataKey="subTotalDiscountedMemberDiscounts"
                          stackId="totalsDiscountedBar"
                          fill={ANALYTICS_COLORS.memberDiscountFill}
                          yAxisId="left"
                        />
                        <Bar
                          dataKey="subTotalDiscountedVoucherDiscounts"
                          stackId="totalsDiscountedBar"
                          fill={ANALYTICS_COLORS.vouchersDiscountFill}
                          yAxisId="left"
                        />
                      </Fragment>
                    )}

                    {/* orders status */}
                    {showOrdersStatus && (
                      <Fragment>
                        {Object.keys(OrderStatusesMap).map(status => (
                          <Bar
                            key={status}
                            dataKey={`statuses.${status}`}
                            stackId="statusBar"
                            fill={OrderStatusesMap[status]}
                            yAxisId="right"
                          />
                        ))}
                      </Fragment>
                    )}

                    {/* payment methods */}
                    {showPaymentMethods && (
                      <Fragment>
                        {Object.keys(PaymentMethodsMap).map(payment => (
                          <Bar
                            key={payment}
                            dataKey={`paymentMethods.${payment}`}
                            stackId="paymentBar"
                            fill={PaymentMethodsMap[payment]}
                            yAxisId="right"
                          />
                        ))}
                      </Fragment>
                    )}

                    {/* fulfillment methods */}
                    {showFulfillmentMethods && (
                      <Fragment>
                        {Object.keys(FulfillmentMethodsMap).map(fulfillment => (
                          <Bar
                            key={fulfillment}
                            dataKey={`fulfillmentMethods.${fulfillment}`}
                            stackId="fulfillmentBar"
                            fill={FulfillmentMethodsMap[fulfillment]}
                            yAxisId="right"
                          />
                        ))}
                      </Fragment>
                    )}

                    {/* user agents */}
                    {showUserAgents && (
                      <Fragment>
                        {userAgentEntries.map(userAgent => (
                          <Bar
                            key={userAgent}
                            dataKey={`userAgents["${userAgent}"]`}
                            stackId="userAgentsBar"
                            fill={userAgentColorMap[userAgent]}
                            yAxisId="right"
                          />
                        ))}
                      </Fragment>
                    )}

                    {/* outlets */}
                    {showOutlets && (
                      <Line
                        type="monotone"
                        connectNulls
                        dataKey="numberOfOutlets"
                        stroke="red"
                        strokeWidth={3}
                        activeDot={{ r: 10 }}
                        yAxisId="right"
                      />
                    )}

                    {/* avg order value */}
                    {avgOrderValue && (
                      <Line
                        type="monotone"
                        connectNulls
                        dataKey="intervalOrderValueAverage"
                        stroke="blue"
                        strokeWidth={3}
                        activeDot={{ r: 10 }}
                        yAxisId="left"
                      />
                    )}

                    <Tooltip
                      content={tooltipData =>
                        renderTooltip({
                          resolution,
                          tooltipData,
                        })
                      }
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </Col>
            </Row>
          ) : null
        }}
      </Query>
    </Fragment>
  )
}

export default CustomerAnalytics

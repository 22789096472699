import React, { Fragment } from 'react'
import {
  Collapse,
  Card,
  FormGroup,
  Button,
  NonIdealState,
  H5,
  H6,
  Callout,
} from '@blueprintjs/core'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import first from 'lodash/first'
import { matchType } from '@utils/types'
import { penceToPounds, numberToPence } from '@utils/helpers'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import Query from '@components/Query/Query'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import GET_COLLECTION_DETAILS from './queries/getCollectionDetails.query'
import EDIT_COLLECTION_DETAILS from './mutations/editCollectionDetails.mutation'
import GET_PAYMENT_METHODS from './queries/getPaymentMethods.query'
import { successToast } from '@utils/toast'
import PaymentMethodField from '@components/PaymentMethodField/PaymentMethodField'

const Collection = ({ match }) => (
  <Card>
    <Query
      query={GET_COLLECTION_DETAILS}
      variables={{ id: match.params.outlet }}
    >
      {({ getOutlets: { outlets } }) => {
        const outlet = first(outlets)

        return outlet ? (
          <Mutation
            mutation={EDIT_COLLECTION_DETAILS}
            onError={defaultErrorHandler}
            onCompleted={() =>
              successToast('Successfully edited collection details')
            }
          >
            {(editCollectionDetails, { error = null }) => (
              <Formik
                enableReinitialize={true}
                initialValues={{
                  ...outlet,
                  error,
                  collectionMinimumOrderValue: penceToPounds(
                    outlet.collectionMinimumOrderValue
                  ),
                  collectionCharge: penceToPounds(outlet.collectionCharge),
                  deliveryMinimumOrderValue: penceToPounds(
                    outlet.deliveryMinimumOrderValue
                  ),
                }}
                onSubmit={values =>
                  editCollectionDetails({
                    variables: {
                      ...values,
                      collectionMinimumOrderValue: numberToPence(
                        values.collectionMinimumOrderValue
                      ),
                      collectionCharge: numberToPence(values.collectionCharge),
                      deliveryMinimumOrderValue: numberToPence(
                        values.deliveryMinimumOrderValue
                      ),
                    },
                  })
                }
              >
                {({ values, handleSubmit, setFieldValue }) => {
                  const { paymentMethods } = values
                  const canCollect = paymentMethods.some(method =>
                    method.toLowerCase().includes('collection')
                  )
                  const canDeliver = paymentMethods.some(method =>
                    method.toLowerCase().includes('delivery')
                  )
                  return (
                    <form onSubmit={handleSubmit}>
                      <Query
                        query={GET_PAYMENT_METHODS}
                        variables={{ id: match.params.outlet }}
                        showLoader={false}
                      >
                        {({
                          allowedPaymentMethodsOnOutlet: { paymentMethods },
                        }) => (
                          <Fragment>
                            <H5>Fulfillment Options / Financials</H5>
                            {values.paymentMethods &&
                              values.paymentMethods.length === 0 && (
                                <Callout
                                  intent="warning"
                                  style={{ marginBottom: 12 }}
                                >
                                  At least One Fulfillment Option is Required.
                                </Callout>
                              )}
                            <PaymentMethodField
                              paymentMethods={paymentMethods || []}
                              values={values}
                              outlet
                            />
                          </Fragment>
                        )}
                      </Query>

                      <Collapse isOpen={canCollect}>
                        <H6>Collection</H6>
                        <FormGroup
                          label="Minimum Order Value"
                          helperText="Orders must be over this value to be accepted."
                        >
                          <CurrencyInput
                            name="collectionMinimumOrderValue"
                            defaultValue={values.collectionMinimumOrderValue}
                            onUpdate={amount => {
                              setFieldValue(
                                'collectionMinimumOrderValue',
                                amount
                              )
                            }}
                          />
                        </FormGroup>

                        <FormGroup
                          label="Customer Charge"
                          helperText="Fee charged to the customer for collection."
                        >
                          <CurrencyInput
                            name="collectionCharge"
                            defaultValue={values.collectionCharge}
                            onUpdate={amount => {
                              setFieldValue('collectionCharge', amount)
                            }}
                          />
                        </FormGroup>
                      </Collapse>

                      <Collapse isOpen={canDeliver}>
                        <H6>Delivery</H6>
                        <FormGroup
                          label="Minimum Order Value"
                          helperText="Orders must be over this value to be accepted."
                        >
                          <CurrencyInput
                            name="deliveryMinimumOrderValue"
                            defaultValue={values.deliveryMinimumOrderValue}
                            onUpdate={amount => {
                              setFieldValue('deliveryMinimumOrderValue', amount)
                            }}
                          />
                        </FormGroup>
                      </Collapse>

                      <div className="bp-card-footer-actions">
                        <Button text="Save" type="submit" />
                      </div>
                    </form>
                  )
                }}
              </Formik>
            )}
          </Mutation>
        ) : (
          <NonIdealState
            title="No collection and payment details found"
            icon="credit-card"
            className="bp3-card-non-ideal-state"
          />
        )
      }}
    </Query>
  </Card>
)

Collection.propTypes = {
  match: matchType,
}
export default Collection

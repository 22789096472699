import React, { Fragment } from 'react'
import {
  isPlatformLevelUser,
  isPartner,
  isOutletUser,
  isBusinessUser,
} from '../../../stores/userStore'
import { BusinessOutletFilter } from '../BusinessOutletFilter'
import { MarketplaceFilter } from '../MarketplaceFilter'
import { useRoleAwareBusinessFilterQueryParams } from './useRoleAwareBusinessFilterQueryParams'

const RoleAwareBusinessFilter = ({ divider = true, disabled = false }) => {
  const {
    marketplaceIds,
    marketplaceOutletIds,
    onChangeMarketplaceIds,
    onChangeMarketplaceOutletIds,
  } = useRoleAwareBusinessFilterQueryParams()
  // superadmins and partners must filter by marketplace first
  const isMarketplaceFilterRequired = isPlatformLevelUser() || isPartner()

  // business and outlet users don't have enough businesses to require a filter
  const isOutletFilterRequired =
    (!isMarketplaceFilterRequired ||
      (marketplaceIds && marketplaceIds.length > 0)) &&
    !isBusinessUser() &&
    !isOutletUser()

  return (
    <Fragment>
      {isMarketplaceFilterRequired && (
        <div>
          <MarketplaceFilter
            marketplaceFilter={marketplaceIds}
            disabled={disabled}
            onChange={e => {
              const { checked, id: marketplaceId } = e.currentTarget
              if (checked) {
                onChangeMarketplaceIds([...marketplaceIds, marketplaceId])
              } else {
                onChangeMarketplaceIds(
                  marketplaceIds.filter(id => id !== marketplaceId)
                )
                onChangeMarketplaceOutletIds(
                  marketplaceOutletIds.filter(
                    id => !id.startsWith(`${marketplaceId}:`)
                  )
                )
              }
            }}
          />
        </div>
      )}
      {isOutletFilterRequired && (
        <BusinessOutletFilter
          selectedBusinessOutlets={marketplaceOutletIds}
          divider={divider}
          disabled={disabled}
          mapItems={outlets =>
            outlets.map(outlet => ({
              id: `${outlet.marketplace.id}:${outlet.id}`,
              name: `${outlet.restaurant.name} - ${outlet.name}`,
            }))
          }
          onChange={e => {
            const { checked, id: marketplaceAndOutletId } = e.currentTarget
            onChangeMarketplaceOutletIds(
              checked
                ? [...marketplaceOutletIds, marketplaceAndOutletId]
                : marketplaceOutletIds.filter(
                    id => id !== marketplaceAndOutletId
                  )
            )
          }}
          marketplaceIds={marketplaceIds}
        />
      )}
    </Fragment>
  )
}

export default RoleAwareBusinessFilter

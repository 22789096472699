import React, { Fragment } from 'react'
import { bool, func, object } from 'prop-types'
import {
  Classes,
  Button,
  Intent,
  FormGroup,
  InputGroup,
  H5,
  AnchorButton,
  Callout,
} from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { history } from '@stores/routerStore'
import get from 'lodash/get'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import { toggleModal } from '@utils/cacheHelpers'
import { selectionUpdate } from '@utils/helpers'
import { ownsPartners } from '@stores/userStore'
import GET_RESTAURANTS from '@components/Restaurants/List/queries/getRestaurants.query'
import ShortDetailsForm from './ShortDetailsForm'
import ADD_RESTAURANT from '@components/Restaurants/List/mutations/addRestaurant.mutation'
import PartnerSelect from '@components/PartnerSelect/PartnerSelect'
import MarketplaceSelect from '@components/MarketplaceSelect/MarketplaceSelect'
import { validation } from './validation'
import GET_PARTNERS from '../../Partners/queries/getPartners.query'

const updateCache = (cache, data) => {
  let partners
  try {
    partners = cache.readQuery({ query: GET_PARTNERS })
  } catch (e) {
    // if partners page hasn't been visited, this will error.
  }
  if (partners) {
    const partnerId = get(data.addRestaurant, 'restaurant.partner.id')

    get(partners, 'getPartners.partners', []).forEach(partner => {
      if (partner.id === partnerId) {
        partner.restaurants.push(data.addRestaurant.restaurant)
      }
    })

    cache.writeQuery({
      query: GET_PARTNERS,
      data: partners,
    })
  }
}

const handleMutation = data => {
  const id = data.restaurant.id
  history.push(`/business/${id}/details`)
  toggleModal({
    restaurantModal: false,
  })
}

const RestaurantModal = ({ modalOpen, modalClose }) => (
  <Dialog isOpen={modalOpen} onClose={modalClose} title="Add Business">
    <Mutation
      mutation={ADD_RESTAURANT}
      onError={defaultErrorHandler}
      onCompleted={({ addRestaurant }) => handleMutation(addRestaurant)}
      refetchQueries={[{ query: GET_RESTAURANTS }]}
      update={(cache, { data }) => updateCache(cache, data)}
    >
      {addRestaurant => {
        return (
          <Formik
            onSubmit={values => {
              addRestaurant({
                variables: {
                  ...values,
                  outletName: values.contactAddress.firstLine,
                },
              })
            }}
            initialValues={{
              name: '',
              contactEmail: '',
              contactAddress: {
                firstLine: '',
                secondLine: '',
                thirdLine: '',
                city: '',
                country: { code: 'GB', name: 'United Kingdom' },
                postcode: '',
              },
              contactPhone: '',
              contactName: '',
              partnerId: ownsPartners() ? ownsPartners() : '',
              outletMarketplaceId: '',
            }}
            validationSchema={validation}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {props => {
              const {
                handleChange,
                handleSubmit,
                errors,
                values,
                setFieldValue,
                isSubmitting,
              } = props

              return (
                <form onSubmit={handleSubmit}>
                  <div className={Classes.DIALOG_BODY}>
                    <FormGroup
                      label="Business Name"
                      labelInfo="(required)"
                      labelFor="name"
                      subLabel="Identifying name of the business as shown to the customer."
                      helperText={errors.name ? errors.name : ''}
                      intent={errors.name ? Intent.DANGER : Intent.NONE}
                    >
                      <InputGroup
                        name="name"
                        id="name"
                        type="text"
                        large={true}
                        onChange={handleChange}
                        intent={errors.name ? Intent.DANGER : Intent.NONE}
                        value={values.name}
                      />
                    </FormGroup>
                    <ShortDetailsForm
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      handleSubmit={handleSubmit}
                      setFieldValue={setFieldValue}
                    />
                    {!ownsPartners() && (
                      <FormGroup
                        label="Partner"
                        labelInfo="(required)"
                        labelFor="partnerId"
                        helperText={errors.partnerId || ''}
                        intent={Intent.DANGER}
                      >
                        <PartnerSelect
                          partnerId={values.partnerId}
                          onChange={event => {
                            const selection = selectionUpdate(event)
                            setFieldValue('partnerId', selection.value, true)
                          }}
                        />
                      </FormGroup>
                    )}

                    <H5>Outlet Details</H5>

                    {values.contactAddress.firstLine && (
                      <Fragment>
                        <Callout icon="info-sign" intent={Intent.PRIMARY}>
                          An Outlet named{' '}
                          <strong>"{values.contactAddress.firstLine}"</strong>
                          will automatically be created for this Business.
                        </Callout>
                        <br />
                      </Fragment>
                    )}

                    <FormGroup
                      label="Marketplace for Outlet"
                      labelInfo="(required)"
                      labelFor="outletMarketplaceId"
                      helperText={errors.outletMarketplaceId || ''}
                      intent={Intent.DANGER}
                    >
                      <MarketplaceSelect
                        disabled={!values.contactAddress.firstLine}
                        marketplaceId={values.outletMarketplaceId}
                        onChange={value => {
                          setFieldValue('outletMarketplaceId', value, false)
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                      <AnchorButton
                        text="What is a Business?"
                        href="https://support.redbox.systems/docs/businesses"
                        target="_blank"
                        rel="noopener noreferrer"
                        rightIcon="help"
                        intent={Intent.PRIMARY}
                        minimal
                      />
                      <Button
                        text="Save"
                        type="submit"
                        loading={isSubmitting}
                      />
                    </div>
                  </div>
                </form>
              )
            }}
          </Formik>
        )
      }}
    </Mutation>
  </Dialog>
)

RestaurantModal.propTypes = {
  modalOpen: bool,
  modalClose: func,
  handleChange: func,
  handleSubmit: func,
  setFieldValue: func,
  errors: object,
  values: object,
}

export default RestaurantModal

import React, { Fragment } from 'react'
import { Mutation, Query as CacheQuery } from 'react-apollo'
import first from 'lodash/first'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import Query from '@components/Query/Query'
import TerminalForm from '@components/TerminalForm/TerminalForm'
import EDIT_TERMINAL from '@components/Terminal/mutations/editTerminal.mutation'
import GET_TERMINAL from '@components/Terminal/queries/getTerminal.query'
import OPEN_MODAL from '@components/Terminal/queries/openModal.clientQuery'
import { successToast } from '@utils/toast'
import { validation } from './validation'
import ResetTerminalPinModal from '@components/ResetTerminalPinModal/ResetTerminalPin.modal'
import { toggleModal } from '@utils/cacheHelpers'
import GET_OUTLET_TERMINALS from '@components/Outlet/Terminals/queries/getTerminals.query'
import TERMINAL_LIST from '@components/Restaurants/Terminals/queries/terminalList.query'

const TerminalDetails = ({ id, queryVariables }) => (
  <Query
    query={GET_TERMINAL}
    variables={{ ids: id }}
    loaderTitle={'Loading Terminal'}
  >
    {({ getTerminals: { terminals } }) => {
      if (!terminals.length) {
        return 'Unable to find terminal'
      }
      const terminal = first(terminals)
      return (
        <Fragment>
          <Mutation
            mutation={EDIT_TERMINAL}
            onError={defaultErrorHandler}
            onCompleted={() =>
              successToast(
                `${terminal.friendlyName} terminal has been modified`
              )
            }
            refetchQueries={[
              {
                query: queryVariables.outletId
                  ? GET_OUTLET_TERMINALS
                  : TERMINAL_LIST,
                variables: queryVariables,
              },
            ]}
          >
            {editTerminal => (
              <Fragment>
                <TerminalForm
                  onSubmit={values =>
                    editTerminal({
                      variables: {
                        ...values,
                        slave:
                          values.slave || typeof values.slave === 'boolean'
                            ? values.slave
                            : values.slave[0] === 'on',
                        editableMessages:
                          values.editableMessages ||
                          typeof values.editableMessages === 'boolean'
                            ? values.editableMessages
                            : values.editableMessages[0] === 'on',
                        receiptGroupItems:
                          values.receiptGroupItems ||
                          typeof values.receiptGroupItems === 'boolean'
                            ? values.receiptGroupItems
                            : values.receiptGroupItems[0] === 'on',
                      },
                    })
                  }
                  initialValues={{
                    ...terminal,
                    outletIds: terminal.outlets.map(({ id }) => id),
                    slave: Boolean(terminal.slave),
                    editableMessages: Boolean(terminal.editableMessages),
                  }}
                  validationSchema={validation}
                  edit
                  queryVariables={queryVariables}
                />
              </Fragment>
            )}
          </Mutation>
          <CacheQuery query={OPEN_MODAL}>
            {({ data: { resetTerminalPinModal } }) => (
              <ResetTerminalPinModal
                modalOpen={resetTerminalPinModal}
                modalClose={() => toggleModal({ resetTerminalPinModal: false })}
                terminalId={terminal.id}
              />
            )}
          </CacheQuery>
        </Fragment>
      )
    }}
  </Query>
)

export default TerminalDetails

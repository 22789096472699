import React, { Fragment } from 'react'
import {
  Card,
  NonIdealState,
  HTMLTable,
  Tag,
  Button,
  Tooltip,
  H2,
  H3,
  Intent,
  Callout,
} from '@blueprintjs/core'
import first from 'lodash/first'
import { capitalize, get, startCase } from 'lodash'
import injectSheet from 'react-jss'
import { object } from 'prop-types'
import { matchType } from '@utils/types'
import { Row, Col } from 'react-simple-flex-grid'
import { Mutation } from 'react-apollo'
import Currency from '@components/Currency/Currency'
import GeoPlot from '@components/GeoJSON/GeoFence'
import Query from '@components/Query/Query'
import GET_CUSTOMER_DETAILS from './queries/getCustomerDetails.query'
import { FORCE_CUSTOMER_LOGOUT } from './mutations/forceCustomerLogout.query'
import { MUTATION_RELOCATE } from './mutations/relocateAddress.query'
import { REGENERATE_STRIPE_CUSTOMER } from './mutations/regenerateStripeCustomer.query'
import { styles } from './Customer.styles'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { errorToast, successToast } from '@utils/toast'
import CustomerAnalytics from './CustomerAnalytics'
import Favourites from './Favourites'
import CustomerAnalyticsContextProvider from './CustomerAnalyticsContext'
import CopyText from '@components/CopyText/CopyText'
import { Link } from 'react-router-dom'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import {
  isAtLeastMarketplaceOwner,
  isPlatformLevelUser,
} from '@stores/userStore'
import { PaymentIcon } from 'react-svg-credit-card-payment-icons'
import Enum from '@components/Enum/Enum'
import { AnchorButton } from '@blueprintjs/core/lib/esm'
import DateTime from '@components/DateTime/DateTime'

const Details = ({ match }) => (
  <CustomerAnalyticsContextProvider>
    <Query
      query={GET_CUSTOMER_DETAILS}
      variables={{ id: match.params.customer }}
      loaderTitle={'Loading Customer Details'}
    >
      {({ customers }) => {
        const customer = customers.customers
        if (customer.length) {
          const customerDetails = first(customer)

          const analyticsSwitch = get(
            customerDetails,
            'marketplace.enableAnalytics',
            false
          )

          return (
            <Fragment>
              <Row gutter={24}>
                <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                  <Card className="bp3-nopad">
                    <div>
                      <div style={{ padding: '24px 24px 12px 24px' }}>
                        <H3>
                          {`${capitalize(
                            customerDetails.firstName
                          )} ${capitalize(customerDetails.lastName)}`}
                        </H3>
                        <Row>
                          <Col span={6}>
                            <H2 style={{ color: '#1968F6' }}>
                              <Currency amount={customerDetails.totalValue} />
                            </H2>
                            <p>Customer Value</p>
                          </Col>
                          <Col span={6}>
                            <Link to={`/customer/${customerDetails.id}/orders`}>
                              <H2 style={{ color: '#1968F6' }}>
                                {customerDetails.totalCompletedOrders}
                              </H2>
                            </Link>
                            Total Orders
                          </Col>
                        </Row>
                      </div>
                      <HTMLTable bordered>
                        <tbody>
                          <tr>
                            <td>
                              <strong>Phone Number</strong>
                            </td>
                            <td>
                              <CopyText
                                minimal
                                text={customerDetails.phoneNumber}
                                mono={false}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Email</strong>
                            </td>
                            <td>
                              <CopyText
                                minimal
                                text={customerDetails.email}
                                mono={false}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <strong>Redbox Customer</strong>
                            </td>
                            <td>
                              <CopyText
                                mono
                                minimal
                                text={customerDetails.id}
                              />
                            </td>
                          </tr>

                          {customerDetails.stripeCustomerId && (
                            <tr>
                              <td>
                                <strong>Stripe Customer</strong>
                              </td>
                              <td className="bp3-space-between">
                                <CopyText
                                  mono
                                  minimal
                                  text={customerDetails.stripeCustomerId}
                                  leftElement={
                                    isPlatformLevelUser() && (
                                      <Mutation
                                        mutation={REGENERATE_STRIPE_CUSTOMER}
                                        onError={defaultErrorHandler}
                                        onCompleted={response => {
                                          response &&
                                          response.forceCustomerLogout
                                            ? successToast(
                                                'Customer logged out.'
                                              )
                                            : errorToast(
                                                'Failed to log out customer.'
                                              )
                                        }}
                                        refetchQueries={[
                                          {
                                            query: GET_CUSTOMER_DETAILS,
                                            variables: {
                                              id: customerDetails.id,
                                            },
                                          },
                                        ]}
                                      >
                                        {regenerateStripeCustomer => (
                                          <ConfirmationPopover
                                            remove={async () => {
                                              await regenerateStripeCustomer({
                                                variables: {
                                                  customerId:
                                                    customerDetails.id,
                                                },
                                              })
                                            }}
                                            buttonTitle="Regenerate"
                                            confirmationText={
                                              <Fragment>
                                                <p>
                                                  Regenerate the stripe
                                                  customer, useful if the
                                                  customer has been created on
                                                  the wrong Stripe account or if
                                                  the marketplace is moving to
                                                  Paybox.
                                                </p>
                                                <ul>
                                                  <li>
                                                    Deleted the current stripe
                                                    customer if Redbox still has
                                                    access to it.
                                                  </li>
                                                  <li>
                                                    Customer will loose access
                                                    to saved cards.
                                                  </li>
                                                  <li>
                                                    Logs out the user, new
                                                    Stripe customer created on
                                                    login.{' '}
                                                  </li>
                                                </ul>
                                              </Fragment>
                                            }
                                          >
                                            <Tooltip content="Regenerate Stripe Customer">
                                              <Button icon="reset" minimal />
                                            </Tooltip>
                                          </ConfirmationPopover>
                                        )}
                                      </Mutation>
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <strong>Marketplace</strong>
                            </td>
                            <td>
                              {isAtLeastMarketplaceOwner() ? (
                                <Link
                                  to={`/marketplaces/${customerDetails.marketplace.id}`}
                                >
                                  {customerDetails.marketplace.name}
                                </Link>
                              ) : (
                                customerDetails.marketplace.name
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Customer Since</strong>
                            </td>
                            <td>
                              <DateTime
                                dateTime={customerDetails.createdAt}
                                timeAgo
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Last Order</strong>
                            </td>
                            <td>
                              {!customerDetails.lastOrderAt ? (
                                'Never Ordered'
                              ) : (
                                <DateTime
                                  dateTime={customerDetails.lastOrderAt}
                                  timeAgo
                                />
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Last Login</strong>
                            </td>
                            <td className="bp3-space-between">
                              <div>
                                <DateTime
                                  dateTime={customerDetails.updatedAt}
                                  timeAgo
                                />{' '}
                              </div>
                              <div>
                                {customerDetails.invalidateToken ? (
                                  <Tag minimal intent={Intent.WARNING}>
                                    FORCE LOGOUT
                                  </Tag>
                                ) : (
                                  <Mutation
                                    mutation={FORCE_CUSTOMER_LOGOUT}
                                    onError={defaultErrorHandler}
                                    onCompleted={response => {
                                      response && response.forceCustomerLogout
                                        ? successToast('Customer logged out.')
                                        : errorToast(
                                            'Failed to log out customer.'
                                          )
                                    }}
                                    refetchQueries={[
                                      {
                                        query: GET_CUSTOMER_DETAILS,
                                        variables: {
                                          id: customerDetails.id,
                                        },
                                      },
                                    ]}
                                  >
                                    {forceCustomerLogout => (
                                      <ConfirmationPopover
                                        remove={async () => {
                                          await forceCustomerLogout({
                                            variables: {
                                              customerId: customerDetails.id,
                                            },
                                          })
                                        }}
                                        buttonTitle="Force Logout"
                                        confirmationText="Are you sure you want to log this customer out of their account?"
                                      >
                                        <Tooltip content="Force Logout">
                                          <Button icon="log-out" minimal />
                                        </Tooltip>
                                      </ConfirmationPopover>
                                    )}
                                  </Mutation>
                                )}
                              </div>
                            </td>
                          </tr>
                          {customerDetails.discounts.length > 0 && (
                            <tr>
                              <td>
                                <strong>Member Discounts</strong>
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                  }}
                                >
                                  {customerDetails.discounts.map(discount => {
                                    const {
                                      id: discountId,
                                      name,
                                      restaurant: { id: restaurantId },
                                    } = discount
                                    return discountId && restaurantId ? (
                                      <div key={discountId}>
                                        <a
                                          href={`/business/${restaurantId}/members?discountId=${discountId}`}
                                        >
                                          {name}
                                        </a>
                                      </div>
                                    ) : null
                                  })}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </HTMLTable>
                    </div>
                  </Card>
                  {customerDetails.paymentMethods &&
                  customerDetails.paymentMethods.length > 0 ? (
                    <Card className="bp3-nopad">
                      <HTMLTable>
                        <thead>
                          <tr>
                            <th colSpan={2}>Payment Method</th>
                            <th>Expiry</th>
                            <th>Checks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerDetails.paymentMethods.map(
                            (paymentMethod, index) => (
                              <tr key={index}>
                                <td>
                                  <Tooltip
                                    content={`${startCase(
                                      `${paymentMethod.brand} ${paymentMethod.funding}`
                                    )} (${paymentMethod.country.toUpperCase()})`}
                                  >
                                    <PaymentIcon
                                      type={paymentMethod.brand}
                                      format="logo"
                                      width={32}
                                    />
                                  </Tooltip>
                                </td>
                                <td className="bp3-monospace-text">
                                  •••• {paymentMethod.last4}
                                </td>
                                <td className="bp3-monospace-text">
                                  {paymentMethod.exp_month}/
                                  {paymentMethod.exp_year}
                                </td>
                                <td>
                                  <Enum tagName={paymentMethod.cvcCheck} />
                                  &nbsp;
                                  <Enum
                                    minimal
                                    tagName={paymentMethod.postCodeCheck}
                                  />
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </HTMLTable>
                    </Card>
                  ) : (
                    <Callout icon="credit-card">No Payment Methods</Callout>
                  )}

                  {analyticsSwitch && (
                    <Card className="bp3-nopad">
                      <Favourites customerId={customerDetails.id} />
                    </Card>
                  )}
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={8}>
                  <Card className="bp3-nopad">
                    {customerDetails.deliveryAddress &&
                    customerDetails.deliveryAddress.length > 0 ? (
                      <Fragment>
                        <GeoPlot
                          width="100%"
                          height="40vh"
                          features={[
                            ...customerDetails.marketplace.deliveryZones,
                            ...customerDetails.deliveryAddress,
                          ]}
                          showDelete={false}
                          disableEdit={true}
                        />
                        <div className="bp3-scrollable">
                          <HTMLTable
                            interactive={false}
                            style={{ borderTop: '1px solid #D2D5D8' }}
                          >
                            <thead>
                              <tr>
                                <th>Address</th>
                                <th>Postcode</th>
                                <th>City</th>
                                <th>Latitude</th>
                                <th>Longitude</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody className="bp3-no-break">
                              {customerDetails.deliveryAddress.map(address => {
                                const [latNum, lngNum] = get(
                                  address,
                                  'geo.coordinates',
                                  []
                                )
                                const lat = latNum
                                  ? latNum.toFixed(6)
                                  : 'Unknown'
                                const lng = lngNum
                                  ? lngNum.toFixed(6)
                                  : 'Unknown'
                                return (
                                  <tr key={get(address, 'id')}>
                                    <td>
                                      {[
                                        get(address, 'firstLine'),
                                        get(address, 'secondLine'),
                                        get(address, 'thirdLine'),
                                      ]
                                        .filter(
                                          item => item !== null && item !== ''
                                        )
                                        .join(', ')}
                                      {address.default && (
                                        <Fragment>
                                          &nbsp;<Tag minimal>DEFAULT</Tag>
                                        </Fragment>
                                      )}
                                    </td>
                                    <td>
                                      {get(address, 'postcode').toUpperCase()}
                                    </td>
                                    <td>{get(address, 'city')}</td>
                                    <td className="bp3-monospace-text">
                                      {lat}
                                    </td>
                                    <td className="bp3-monospace-text">
                                      {lng}
                                    </td>
                                    <td className="bp3-action-cell">
                                      <Tooltip content="Relocate Address">
                                        <Mutation
                                          mutation={MUTATION_RELOCATE}
                                          onError={defaultErrorHandler}
                                          onCompleted={({ relocateAddress }) =>
                                            successToast(
                                              relocateAddress.message
                                            )
                                          }
                                        >
                                          {relocateAddress => (
                                            <Button
                                              minimal
                                              icon={'locate'}
                                              onClick={() =>
                                                relocateAddress({
                                                  variables: {
                                                    id: address.id,
                                                  },
                                                })
                                              }
                                            />
                                          )}
                                        </Mutation>
                                      </Tooltip>
                                      <Tooltip content="View on Google Maps">
                                        <AnchorButton
                                          rel="noopener noreferrer"
                                          href={`https://www.google.com/maps?q=${lng},${lat}`}
                                          target="_blank"
                                          minimal
                                          icon="map"
                                        />
                                      </Tooltip>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </HTMLTable>
                        </div>
                      </Fragment>
                    ) : (
                      <div style={{ padding: 100 }}>
                        <NonIdealState
                          icon="map"
                          title="No Addresses"
                          description="This customer has not added any addresses."
                        />
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
              {analyticsSwitch && (
                <Row gutter={24}>
                  <Col span={12}>
                    <Card className="bp3-nopad">
                      <CustomerAnalytics customer={customerDetails} />
                    </Card>
                  </Col>
                </Row>
              )}
            </Fragment>
          )
        } else {
          return (
            <NonIdealState
              icon="mugshot"
              title="Customer Not Found"
              description="We were unable to find details for this customer."
            />
          )
        }
      }}
    </Query>
  </CustomerAnalyticsContextProvider>
)

Details.propTypes = {
  match: matchType,
  classes: object,
}

export default injectSheet(styles)(Details)

import gql from 'graphql-tag'

const GET_PARTNER_MARKETPLACES = gql`
  query getPartnerIds($partnerIds: [ID!]) {
    getMarketplaces(partnerIds: $partnerIds) {
      regions {
        id
        name
        geoFence
        brandColor
        customerCount
      }
    }
  }
`

export default GET_PARTNER_MARKETPLACES

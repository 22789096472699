import React, { Fragment, useContext } from 'react'
import injectSheet from 'react-jss'
import { object } from 'prop-types'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import {
  Popover,
  Menu,
  MenuItem,
  MenuDivider,
  Position,
  Navbar,
  Tag,
  Icon,
} from '@blueprintjs/core'
import { get, upperCase } from 'lodash'
import { Mutation, Query as CacheQuery } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import Fetch from 'react-fetch-component'
import { Query } from 'react-apollo'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import * as Sentry from '@sentry/react'

import LOGOUT from './mutations/logoutUser.mutation'
import OPEN_MODAL from './mutations/openModal.clientMutation'
import GET_USER_INFO from '@components/User/Details/queries/getUserInfo.query'

import {
  logout,
  canView,
  isOutletUser,
  isMenuEditor,
  isOutletFinancialUser,
  isMarketingUser,
} from '@stores/userStore'
import { toggleModal } from '@utils/cacheHelpers'

import NavIcon from '@components/NavIcon/NavIcon'
import userIcon from '@assets/icons/user.svg'
import dashboardIcon from '@assets/icons/dashboard.svg'
import settingsIcon from '@assets/icons/settings.svg'
import businessesIcon from '@assets/icons/businesses.svg'
import marketplacesIcon from '@assets/icons/marketplaces.svg'
import partnersIcon from '@assets/icons/partners.svg'
import platformIcon from '@assets/icons/platform.svg'
import ordersIcon from '@assets/icons/bill.svg'
import customersIcon from '@assets/icons/customers.svg'
import reportsIcon from '@assets/icons/reports.svg'
import supportIcon from '@assets/icons/support.svg'
import terminalIcon from '@assets/icons/cash-register.svg'
import marketingIcon from '@assets/icons/marketing.svg'
import messagingIcon from '@assets/icons/messaging.svg'

import UserModal from '@components/User/User.modal'

const styles = theme => ({
  navContainer: {
    display: 'flex',
    position: 'fixed',
    left: 0,
    top: 96,
    height: 'calc(100% - 96px)',
    zIndex: 1,
  },
  slimContainer: {
    width: 48,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: theme.colors.secondaryBg,
    paddingTop: 12,
    paddingBottom: 12,
  },
  slimContainerTop: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  slimContainerBottom: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  navLink: {
    color: theme.colors.primaryNavColor,
    '&:hover': {
      color: theme.colors.primaryNavHoverColor,
    },
  },
  darkModeNavLink: {
    color: theme.darkColors.primaryNavColor,
    '&:hover': {
      color: theme.darkColors.primaryNavHoverColor,
    },
  },
  activeNavLink: {
    color: theme.colors.primaryNavActiveColor,
    '&:not(.activeNavLink):hover': {
      color: theme.colors.primaryNavActiveColor,
    },
  },
  darkModeActiveNavLink: {
    color: theme.darkColors.primaryNavActiveColor,
  },
  supportIcon: {
    color: 'red',
  },
})

export const Nav = ({ classes, dark }) => {
  const {
    navContainer,
    slimContainer,
    slimContainerTop,
    slimContainerBottom,
    navLink,
    activeNavLink,
    darkModeNavLink,
    darkModeActiveNavLink,
  } = classes

  const { switchDarkMode } = useContext(PageLayoutContext)

  const userIsOutletUser = isOutletUser()
  const userIsMenuEditor = isMenuEditor()
  const userIsOutletFinancialUser = isOutletFinancialUser()
  const userIsMarketingUser = isMarketingUser()
  const userIsNotMenuEditorOrFinancialUser =
    !userIsMenuEditor && !userIsOutletFinancialUser

  return (
    <nav className={navContainer}>
      <Query query={GET_USER_INFO}>
        {({ data }) => {
          const user = get(data, 'getMyDetails.user', null)

          let fullName =
            get(user, 'firstName', '') + ' ' + get(user, 'lastName', '')
          fullName = fullName.length > 0 ? fullName : 'Unknown'
          const roleName =
            get(user, 'role.title', '').replace(/Restaurant/i, 'Business') ||
            'Unknown Role'

          Sentry.setUser({
            email: get(user, 'email', null),
            id: get(user, 'id', null),
          })
          Sentry.setContext('user', {
            role: get(user, 'role.title', null),
            name: fullName,
            phone: get(user, 'phoneNumber', null),
          })

          return (
            <Navbar className={slimContainer}>
              <div>
                <div className={slimContainerTop}>
                  {canView('restaurants') && !userIsOutletFinancialUser && (
                    <NavLink
                      to={'/dashboard'}
                      className={dark ? darkModeNavLink : navLink}
                      activeClassName={
                        dark ? darkModeActiveNavLink : activeNavLink
                      }
                    >
                      <NavIcon
                        linkUrl={'/'}
                        imagePath={dashboardIcon}
                        tooltipContent="Dashboard"
                        dataTestId="slim-container-dashboard-icon"
                      />
                    </NavLink>
                  )}
                  {canView('platform') && (
                    <NavLink
                      to="/platform"
                      className={dark ? darkModeNavLink : navLink}
                      activeClassName={
                        dark ? darkModeActiveNavLink : activeNavLink
                      }
                    >
                      <NavIcon
                        imagePath={platformIcon}
                        tooltipContent="Platform"
                        linkUrl="/platform"
                        dataTestId="slim-container-platform-icon"
                      />
                    </NavLink>
                  )}
                  {canView('partners') && (
                    <NavLink
                      to="/partners"
                      className={dark ? darkModeNavLink : navLink}
                      activeClassName={
                        dark ? darkModeActiveNavLink : activeNavLink
                      }
                    >
                      <NavIcon
                        imagePath={partnersIcon}
                        tooltipContent="Partners"
                        linkUrl="/partners"
                        dataTestId="slim-container-partners-icon"
                      />
                    </NavLink>
                  )}
                  {canView('marketplaces') && (
                    <NavLink
                      to="/marketplaces"
                      className={dark ? darkModeNavLink : navLink}
                      activeClassName={
                        dark ? darkModeActiveNavLink : activeNavLink
                      }
                    >
                      <NavIcon
                        imagePath={marketplacesIcon}
                        tooltipContent="Marketplaces"
                        linkUrl="/marketplaces"
                        dataTestId="slim-container-marketplaces-icon"
                      />
                    </NavLink>
                  )}

                  {(canView('restaurants') ||
                    userIsOutletUser ||
                    userIsMenuEditor) && (
                    <NavLink
                      to={
                        userIsOutletUser || userIsOutletFinancialUser
                          ? '/outlets'
                          : '/business'
                      }
                      className={dark ? darkModeNavLink : navLink}
                      activeClassName={
                        dark ? darkModeActiveNavLink : activeNavLink
                      }
                      isActive={(match, location) => {
                        return [
                          'business',
                          'outlets',
                          'terminals',
                          'onboardingList',
                        ].includes(location.pathname.split('/')[1])
                      }}
                    >
                      <NavIcon
                        imagePath={businessesIcon}
                        tooltipContent="Businesses"
                        linkUrl={
                          userIsOutletUser || userIsOutletFinancialUser
                            ? '/outlets'
                            : '/business'
                        }
                        dataTestId="slim-container-business-icon"
                      />
                    </NavLink>
                  )}
                  {canView('orders') &&
                    userIsNotMenuEditorOrFinancialUser &&
                    !userIsMarketingUser && (
                      <NavLink
                        to="/orders"
                        className={dark ? darkModeNavLink : navLink}
                        activeClassName={
                          dark ? darkModeActiveNavLink : activeNavLink
                        }
                      >
                        <NavIcon
                          imagePath={ordersIcon}
                          tooltipContent="Orders"
                          linkUrl="/orders"
                          dataTestId="slim-container-orders-icon"
                        />
                      </NavLink>
                    )}
                  {canView('virtualTerminals') && (
                    <NavLink
                      to="/virtual-terminal"
                      className={dark ? darkModeNavLink : navLink}
                      activeClassName={
                        dark ? darkModeActiveNavLink : activeNavLink
                      }
                    >
                      <NavIcon
                        imagePath={terminalIcon}
                        tooltipContent="Virtual Terminal"
                        linkUrl="/virtual-terminal"
                        dataTestId="slim-container-virtual-terminal-icon"
                      />
                    </NavLink>
                  )}
                  {canView('messaging') && (
                    <NavLink
                      to="/messaging"
                      className={dark ? darkModeNavLink : navLink}
                      activeClassName={
                        dark ? darkModeActiveNavLink : activeNavLink
                      }
                      isActive={(match, location) => {
                        return location.pathname.split('/')[1] === 'messaging'
                      }}
                    >
                      <NavIcon
                        imagePath={messagingIcon}
                        tooltipContent="Messaging"
                        linkUrl="/messaging"
                        dataTestId="slim-container-messaging-icon"
                      />
                    </NavLink>
                  )}
                  {canView('marketing') && (
                    <NavLink
                      to="/marketing"
                      className={dark ? darkModeNavLink : navLink}
                      activeClassName={
                        dark ? darkModeActiveNavLink : activeNavLink
                      }
                    >
                      <NavIcon
                        imagePath={marketingIcon}
                        tooltipContent="Marketing"
                        linkUrl="/marketing"
                        dataTestId="slim-container-marketing-icon"
                      />
                    </NavLink>
                  )}
                  {canView('customers') && (
                    <NavLink
                      to="/customers"
                      className={dark ? darkModeNavLink : navLink}
                      activeClassName={
                        dark ? darkModeActiveNavLink : activeNavLink
                      }
                      isActive={(match, location) =>
                        location.pathname.startsWith('/customer')
                      }
                    >
                      <NavIcon
                        imagePath={customersIcon}
                        tooltipContent="Customers"
                        linkUrl="/customer"
                        dataTestId="slim-container-customer-icon"
                      />
                    </NavLink>
                  )}
                  {(canView('reports') || canView('sales')) && (
                    <NavLink
                      to="/reports"
                      className={dark ? darkModeNavLink : navLink}
                      activeClassName={
                        dark ? darkModeActiveNavLink : activeNavLink
                      }
                    >
                      <NavIcon
                        imagePath={reportsIcon}
                        tooltipContent="Reports"
                        linkUrl="/reports"
                        dataTestId="slim-container-reports-icon"
                      />
                    </NavLink>
                  )}
                  {canView('admin') && (
                    <NavLink
                      to="/admin"
                      className={dark ? darkModeNavLink : navLink}
                      activeClassName={
                        dark ? darkModeActiveNavLink : activeNavLink
                      }
                    >
                      <NavIcon
                        imagePath={settingsIcon}
                        tooltipContent="Administrator Settings"
                        linkUrl="/admin"
                        dataTestId="slim-container-admin-icon"
                      />
                    </NavLink>
                  )}
                </div>
              </div>
              <div>
                <div className={slimContainerBottom}>
                  <Popover
                    content={
                      <Menu>
                        <MenuItem
                          text="Release Notes"
                          target="_blank"
                          icon="clean"
                          rel="noopener noreferrer"
                          href="https://support.redbox.systems/docs/service-maintenance-release-notes"
                        />
                        <MenuDivider />
                        {user && (
                          <MenuItem text={'Support'} icon="help">
                            <MenuItem
                              icon="book"
                              text="Documentation"
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://support.redbox.systems"
                            />

                            {user.supportUrl && (
                              <MenuItem
                                icon="lifesaver"
                                text={user.supportTitle}
                                href={user.supportUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                              />
                            )}
                            {user.supportEmail && (
                              <MenuItem
                                icon="envelope"
                                text="Email Support"
                                href={`mailto:${user.supportEmail}`}
                              />
                            )}
                          </MenuItem>
                        )}
                        <MenuItem
                          text="Redbox News"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://redbox.systems/blog"
                        />
                        <MenuDivider />
                        {process.env.ENVIRONMENT_NAME !== 'PROD' && (
                          <MenuItem
                            text="Environment"
                            labelElement={
                              <Tag intent="warning">
                                {upperCase(
                                  process.env.ENVIRONMENT_NAME || 'DEV'
                                )}
                              </Tag>
                            }
                            onClick={() =>
                              window.open(
                                'https://status.redbox.systems',
                                '_blank'
                              )
                            }
                          />
                        )}
                        <MenuItem
                          text="Status"
                          labelElement={
                            <Fetch
                              url={
                                'https://w87zt0h66nqt.statuspage.io/api/v2/status.json'
                              }
                            >
                              {({ loading, data }) =>
                                !loading && (
                                  <Fragment>
                                    {data.status.indicator == 'none' && (
                                      <Tag intent="success">OK</Tag>
                                    )}
                                    {data.status.indicator == 'minor' && (
                                      <Tag intent="warning">MINOR</Tag>
                                    )}
                                    {data.status.indicator == 'major' && (
                                      <Tag intent="danger">INCIDENT</Tag>
                                    )}
                                    {data.status.indicator == 'critical' && (
                                      <Tag intent="danger">CRITICAL</Tag>
                                    )}
                                  </Fragment>
                                )
                              }
                            </Fetch>
                          }
                          onClick={() =>
                            window.open(
                              'https://status.redbox.systems',
                              '_blank'
                            )
                          }
                        />
                      </Menu>
                    }
                    position={Position.RIGHT}
                  >
                    <NavIcon
                      className={'supportIcon'}
                      imagePath={supportIcon}
                      tooltipContent="Support"
                    />
                  </Popover>

                  <Popover
                    content={
                      <Menu>
                        {user && (
                          <Fragment>
                            <MenuItem
                              icon={'person'}
                              text={fullName}
                              labelElement={
                                <Tag minimal>{upperCase(roleName)}</Tag>
                              }
                              onClick={() =>
                                toggleModal({
                                  profileModal: true,
                                })
                              }
                            />
                            <MenuDivider />
                          </Fragment>
                        )}
                        <MenuItem
                          text={dark ? 'Light Mode' : 'Dark Mode'}
                          label={<Icon icon={dark ? 'flash' : 'moon'} />}
                          onClick={() => switchDarkMode(!dark)}
                        />
                        <MenuItem
                          text="Edit Profile"
                          onClick={() =>
                            toggleModal({
                              profileModal: true,
                            })
                          }
                        />
                        <Mutation
                          mutation={LOGOUT}
                          onError={defaultErrorHandler}
                          onCompleted={() => logout()}
                        >
                          {logoutUser => (
                            <MenuItem
                              text="Logout"
                              onClick={() => logoutUser()}
                            />
                          )}
                        </Mutation>
                      </Menu>
                    }
                    position={Position.RIGHT}
                  >
                    <NavIcon
                      pathNameToMatch="profile"
                      imagePath={userIcon}
                      tooltipContent="Profile"
                    />
                  </Popover>
                </div>
              </div>
            </Navbar>
          )
        }}
      </Query>
      <CacheQuery query={OPEN_MODAL}>
        {({ data: { profileModal } }) => (
          <UserModal
            modalOpen={profileModal}
            modalClose={() =>
              toggleModal({
                profileModal: false,
              })
            }
          />
        )}
      </CacheQuery>
    </nav>
  )
}

Nav.propTypes = {
  children: object,
  classes: object,
  location: object,
  history: object,
}

export default injectSheet(styles)(withRouter(Nav))

import React, { useEffect, useContext, useState } from 'react'
import { NonIdealState, Card, Button, Drawer, Spinner } from '@blueprintjs/core'
import { Query } from 'react-apollo'

import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import CampaignsTable from './CampaignsTable'
import CreateCampaign from './Form/CreateCampaign'
import EditCampaign from './Form/EditCampaign'
import GET_CAMPAIGNS from '../queries/getCampaigns.query'
import FilterRow from '@components/FilterRow/FilterRow'
import { Pager } from '@components/Toolbar'
import { StringParam, useQueryParam } from 'use-query-params'

const DEFAULT_RECORDS = 20

const CampaignsContainer = ({ match, product, history }) => {
  const [viewCampaign, onChangeViewCampaign] = useQueryParam(
    'viewCampaign',
    StringParam
  )
  const { id } = match.params
  const { configurePageLayout, dark } = useContext(PageLayoutContext)
  const [createDrawIsOpen, setCreateDrawIsOpen] = useState(false)
  const [campaignsPagination, setCampaignsPagination] = useState({
    total: null,
    skip: 0,
    first: DEFAULT_RECORDS,
    last: null,
    defaultNmbRecords: DEFAULT_RECORDS,
    outcomeLength: null,
    navigationDisabled: false,
  })

  useEffect(() => {
    configurePageLayout({
      product,
      tabs: [
        { to: `dashboard`, name: 'Dashboard' },
        { to: `segments`, name: 'Segments' },
        { to: `campaigns`, name: 'Campaigns' },
        {
          to: `meta-business`,
          name: 'Meta Business',
        },
      ],
      button: {
        text: 'New Campaign',
        onClick: () => setCreateDrawIsOpen(true),
      },
    })
  }, [match.url, product, configurePageLayout, history])

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (
      campaignsPagination.skip + campaignsPagination.first <
      campaignsPagination.total
    ) {
      setCampaignsPagination({
        ...campaignsPagination,
        skip: limitNext(
          campaignsPagination.skip,
          campaignsPagination.first,
          limit
        ),
        first: DEFAULT_RECORDS,
        last: null,
      })
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    const skip = parseInt(currentCursor) + parseInt(amount)
    return limit < campaignsPagination.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setCampaignsPagination({
      ...campaignsPagination,
      skip: limitPrevious(campaignsPagination.skip, campaignsPagination.first),
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  const limitPrevious = (currentCursor, amount) => {
    const skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const setFilterState = (count, totalCount) => {
    if (campaignsPagination.total !== totalCount) {
      setCampaignsPagination({
        ...campaignsPagination,
        total: totalCount,
      })
    }
    if (campaignsPagination.outcomeLength !== count) {
      setCampaignsPagination({
        ...campaignsPagination,
        outcomeLength: count,
      })
    }
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setCampaignsPagination({
      ...campaignsPagination,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  return (
    <div className="bp3-table-frame">
      <FilterRow>
        <Pager
          goToPrevious={goToPrevious}
          goToNext={goToNext}
          gotToPage={goToPage}
          defaultNmbRecords={DEFAULT_RECORDS}
          skip={campaignsPagination.skip}
          total={campaignsPagination.total}
          outcomeLength={campaignsPagination.outcomeLength}
          totalCount={campaignsPagination.total}
          dataName="Campaigns"
        />
      </FilterRow>
      <Query
        query={GET_CAMPAIGNS}
        variables={{
          marketplaceId: id,
          skip: campaignsPagination.skip,
          first: campaignsPagination.first,
        }}
      >
        {({ data, loading, error = null, refetch }) => {
          if (loading) {
            return (
              <NonIdealState
                title="Loading Campaigns"
                description="Please wait..."
                icon={<Spinner size={60} />}
              />
            )
          }

          if (error) {
            return (
              <NonIdealState
                title="Failed to connect to notification service"
                icon="error"
              />
            )
          }

          if (
            !data ||
            !data.getCampaigns ||
            !data.getCampaigns.campaigns.length
          ) {
            return (
              <NonIdealState
                title="No Campaigns Found"
                icon="send-message"
                description="Send marketing campaigns to your customer segments."
                action={
                  <Button onClick={() => setCreateDrawIsOpen(true)}>
                    New Campaign
                  </Button>
                }
              />
            )
          }
          const {
            getCampaigns: { count, totalCount },
          } = data

          setFilterState(count, totalCount)

          return (
            <div className="bp3-table-container bp3-scrollable">
              <Card className="bp3-noPad">
                <CampaignsTable
                  data={data.getCampaigns.campaigns}
                  setEditId={onChangeViewCampaign}
                  marketplaceId={id}
                  refetch={refetch}
                />
              </Card>
            </div>
          )
        }}
      </Query>
      <Drawer
        title="New Campaign"
        isOpen={createDrawIsOpen}
        onClose={() => setCreateDrawIsOpen(false)}
        className={dark ? 'bp3-dark' : ''}
        size={Drawer.SIZE_LARGE}
      >
        <CreateCampaign
          closeDraw={() => {
            setCreateDrawIsOpen(false)
          }}
          marketplaceId={id}
        />
      </Drawer>
      <Drawer
        title="Campaign"
        isOpen={viewCampaign}
        onClose={() => onChangeViewCampaign(null)}
        className={dark ? 'bp3-dark' : ''}
        size={Drawer.SIZE_LARGE}
      >
        <EditCampaign
          closeDraw={() => {
            onChangeViewCampaign(null)
          }}
          marketplaceId={id}
          id={viewCampaign}
        />
      </Drawer>
    </div>
  )
}

export default CampaignsContainer

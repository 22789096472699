import React, { Fragment } from 'react'
import { Mutation } from 'react-apollo'
import Query from '@components/Query/Query'
import { FormGroup, TextArea, Card, Button, Tag, Icon } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import { string, func, bool } from 'prop-types'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { get } from 'lodash'
import { removeUnderscores } from '@utils/helpers'

import CANCEL_NETWORK_ORDER from './mutations/cancelNetworkOrder.mutation'
import GET_ORDER from './Order/queries/getOrder.query'

import { getIntent } from './helpers/helpers'

const CancelNetworkOrderModal = ({
  modalOpen = false,
  modalClose,
  orderId,
  refetchQueries = [],
}) => (
  <Dialog title="Cancel Order" isCloseButtonShown={false} isOpen={modalOpen}>
    <Query query={GET_ORDER} variables={{ id: orderId }}>
      {({ order }) => {
        return (
          <Mutation
            mutation={CANCEL_NETWORK_ORDER}
            refetchQueries={[
              ...refetchQueries,
              {
                query: GET_ORDER,
                variables: {
                  id: orderId,
                },
              },
            ]}
            onError={error => {
              defaultErrorHandler(error)
              modalClose()
            }}
            onCompleted={({ cancelNetworkOrder: { message } }) => {
              successToast(message)
              modalClose()
            }}
          >
            {cancelNetworkOrder => {
              let initialValues = {
                id: orderId,
                reason: get(order, 'outlet.marketplace.defaultCancelText', ''),
              }

              return (
                <Formik
                  initialValues={initialValues}
                  onSubmit={values => {
                    cancelNetworkOrder({
                      variables: values,
                    })
                  }}
                >
                  {props => {
                    const { values, handleSubmit, handleChange, isSubmitting } =
                      props

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="bp3-dialog-body">
                          <Fragment>
                            <p>
                              Are you sure you want to update the order status?
                            </p>
                            <Card>
                              <center>
                                <Tag
                                  large={true}
                                  intent={getIntent(order.orderStatus)}
                                >
                                  {removeUnderscores(order.orderStatus)}
                                </Tag>
                                &nbsp;&nbsp;
                                <Icon icon={'arrow-right'} />
                                &nbsp;&nbsp;
                                <Tag
                                  large={true}
                                  intent={getIntent('ORDER_FAILURE_REFUND')}
                                  data-test-id="order-failure-refund-tag"
                                >
                                  CANCELLED
                                </Tag>
                              </center>
                            </Card>

                            <FormGroup
                              label="Customer Message"
                              labelFor="reason"
                              labelInfo="(required)"
                            >
                              <TextArea
                                id="reason"
                                growVertically={true}
                                fill={true}
                                onChange={handleChange}
                                style={{ height: 100 }}
                                value={values.reason}
                              />
                            </FormGroup>
                          </Fragment>
                        </div>
                        <div className="bp3-dialog-footer">
                          <div className="bp3-dialog-footer-actions">
                            <Button
                              minimal
                              onClick={() => modalClose(false)}
                              disabled={isSubmitting || !values.reason.length}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              loading={isSubmitting}
                              data-test-id="cancel-network-order-button"
                            >
                              Confirm
                            </Button>
                          </div>
                        </div>
                      </form>
                    )
                  }}
                </Formik>
              )
            }}
          </Mutation>
        )
      }}
    </Query>
  </Dialog>
)

CancelNetworkOrderModal.propTypes = {
  modalClose: func,
  modalOpen: bool,
  orderId: string,
}

export default CancelNetworkOrderModal

export const styles = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  menuGroupHeader: {
    fontSize: '20px',
    fontWeight: 'bold',
    paddingLeft: '7px',
    marginBottom: '0px',
  },
  groupContainer: {
    padding: '15px 0px 15px 0px',
    borderTop: 'solid 1px #E6E6E6',
    pageBreakInside: 'avoid',
  },
  firstGroupContainer: {
    padding: '15px 0px 15px 0px',
    pageBreakInside: 'avoid',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    lineHeight: '18px',
    margin: '0px',
    justifyContent: 'space-between',
  },
  itemContainer: {
    width: '45vw',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 50px 10px 5px',
    justifyContent: 'flex-start',
  },
  itemHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  itemHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    margin: '0px',
    width: '100%',
    justifyContent: 'space-between',
    lineHeight: '0px',
  },
  itemName: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '18px',
    fontWeight: 'bold',
    padding: '2px',
    marginBottom: '0px',
    lineHeight: '18px',
    justifyContent: 'flex-start',
    minHeight: '20px',
  },
  itemDescription: {
    width: '40vw',
    marginLeft: '1px',
  },
  smlIcon: {
    width: '20px',
    height: '20px',
    marginLeft: '5px',
    marginBottom: '2px',
    border: `1px solid grey`,
    borderRadius: '200px',
    backgroundColor: 'white',
  },
  optionsSvg: {
    width: '20px',
    height: '20px',
    marginLeft: '5px',
    marginBottom: '2px',
    color: 'grey',
  },
  restaurantName: {
    fontSize: '35px',
    margin: '0px',
    padding: '5px',
  },
  restaurantLogo: { width: '175px', margin: '0px' },
  bottomBorder: {
    borderBottom: 'dotted 0.5px #E6E6E6',
    width: '40vw',
    padding: '10px 50px 10px 5px',
    position: 'fixed',
    zIndex: '-1',
  },
  itemPrice: {
    padding: '5px',
    lineHeight: '18px',
  },
  footer: {
    position: 'fixed !important',
    bottom: -15,
    pageBreakInside: 'avoid',
    margin: '0px',
    borderTop: 'solid 0.5px #E6E6E6',
    width: '100vw',
    padding: '0px',
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    margin: '0px',
    padding: '0px',
  },
  keyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    width: '22vw',
    alignItems: 'center',
    margin: '10px 0px 0px 0px',
  },
  keyP: {
    fontSize: '11px',
    padding: '5px',
    marginTop: '6px',
    textAlign: 'center',
  },
  svgContainer: {
    width: '20px',
    height: '20px',
    margin: '10px',
  },
}

import React, { Fragment } from 'react'
import { HTMLTable, Icon, Spinner, Text } from '@blueprintjs/core'
import CopyText from '@components/CopyText/CopyText'
import DateTime from '@components/DateTime/DateTime'
import moment from 'moment'
import { isInAlarm } from '@utils/order/isInAlarm'
import { get, toLower, upperFirst } from 'lodash'
import PropTypes from 'prop-types'

const OrderStatusTimeline = ({ order }) => {
  const isAlarm = isInAlarm(order.orderStatus, order.autoRejectAt)
  const fulfillmentName = upperFirst(toLower(order.fulfillmentMethod))

  return (
    <HTMLTable interactive={false}>
      <thead>
        <tr>
          <td colSpan={3}>
            <CopyText
              minimal
              mono
              fill
              text={order.orderNumber}
              leftElement={
                <Text style={{ padding: 7, fontWeight: 'bold' }}>#</Text>
              }
            />
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ width: 0 }}>
            {['AWAITING_PAYMENT', 'AUTH_ACTION_PENDING'].includes(
              order.orderStatus
            ) ? (
              <Spinner size={16} />
            ) : (
              <Icon icon="tick-circle" intent="success" />
            )}
          </td>
          <td>Created</td>
          <td>
            <DateTime
              dateTime={order.createdAt}
              className="bp3-text-small bp3-text-muted"
            />
          </td>
        </tr>
        {['REJECTED'].includes(order.orderStatus) ? (
          <tr>
            <td>
              <Icon icon="ban-circle" intent="danger" />
            </td>
            <td>Rejected</td>
            <td>
              <DateTime
                dateTime={order.updatedAt}
                className="bp3-text-small bp3-text-muted"
              />
            </td>
          </tr>
        ) : (
          <Fragment>
            <tr>
              <td>
                {['AWAITING_PAYMENT', 'AUTH_ACTION_PENDING'].includes(
                  order.orderStatus
                ) ? (
                  <Icon icon="circle" color="#dfe3e7" />
                ) : order.acceptedAt ? (
                  <Icon icon="tick-circle" intent="success" />
                ) : (
                  <Spinner size={16} intent={isAlarm ? 'danger' : 'none'} />
                )}
              </td>
              {order.acceptedAt ? (
                <Fragment>
                  <td>Accepted</td>
                  <td>
                    <DateTime
                      dateTime={order.acceptedAt}
                      className="bp3-text-small bp3-text-muted"
                    />
                  </td>
                </Fragment>
              ) : (
                <Fragment>
                  <td>{isAlarm ? 'Auto-Reject' : 'Pending'}</td>
                  <td>
                    <DateTime
                      dateTime={order.autoRejectAt}
                      timeAgo={order.acceptedAt}
                      className="bp3-text-small bp3-text-muted"
                    />
                  </td>
                </Fragment>
              )}
            </tr>
            <tr>
              <td>
                {[
                  'REJECTED',
                  'PENDING',
                  'AWAITING_PAYMENT',
                  'AUTH_ACTION_PENDING',
                ].includes(order.orderStatus) && (
                  <Icon icon="circle" color="#dfe3e7" />
                )}
                {['PREPARING'].includes(order.orderStatus) && (
                  <Spinner size={16} />
                )}
                {['READY', 'COMPLETE'].includes(order.orderStatus) && (
                  <Icon icon="tick-circle" intent="success" />
                )}
                {['ORDER_FAILURE_REFUND'].includes(order.orderStatus) && (
                  <Spinner size={16} intent="danger" />
                )}
                {['REFUND_RELEASED'].includes(order.orderStatus) && (
                  <Icon icon="ban-circle" intent="danger" />
                )}
              </td>
              <td>
                {['ORDER_FAILURE_REFUND', 'REFUND_RELEASED'].includes(
                  order.orderStatus
                )
                  ? 'Refund'
                  : 'Ready'}
              </td>
              <td>
                <DateTime
                  dateTime={order.estimatedCompletionTime}
                  timeAgo={order.orderStatus === 'PREPARING'}
                  className="bp3-text-small bp3-text-muted"
                />
              </td>
            </tr>
            {!['ORDER_FAILURE_REFUND', 'REFUND_RELEASED'].includes(
              order.orderStatus
            ) && (
              <Fragment>
                <tr>
                  <td>
                    {[
                      'REJECTED',
                      'PENDING',
                      'PREPARING',
                      'AWAITING_PAYMENT',
                      'AUTH_ACTION_PENDING',
                    ].includes(order.orderStatus) && (
                      <Icon icon="circle" color="#dfe3e7" />
                    )}
                    {['READY', 'AWAITING_DELIVERY_NETWORK'].includes(
                      order.orderStatus
                    ) && <Spinner size={16} />}
                    {['DELIVERY_NETWORK_FAILURE'].includes(
                      order.orderStatus
                    ) && <Icon icon="ban-circle" intent="danger" />}
                    {['COMPLETE'].includes(order.orderStatus) && (
                      <Icon icon="tick-circle" intent="success" />
                    )}
                  </td>
                  <td>
                    {fulfillmentName}
                    {order.fulfillmentMethod === 'TABLE' &&
                      ` (${get(
                        order.tableSnapshot,
                        'friendlyName',
                        'Unknown'
                      )})`}
                  </td>
                  <td>
                    {(order.fulfillmentMethod === 'DELIVERY' ||
                      order.fulfillmentMethod === 'NETWORK') &&
                    order.selectedDeliveryWindow ? (
                      <Text className="bp3-text-small bp3-text-muted">
                        <DateTime
                          dateTime={order.selectedDeliveryWindow.start}
                        />
                        {` to ${moment(order.selectedDeliveryWindow.end).format(
                          'HH:mm'
                        )}`}
                      </Text>
                    ) : (
                      <DateTime
                        className="bp3-text-small bp3-text-muted"
                        dateTime={
                          order.estimatedCompletionTime ||
                          order.estimatedDeliveryDate
                        }
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {[
                      'REJECTED',
                      'PENDING',
                      'PREPARING',
                      'READY',
                      'AWAITING_PAYMENT',
                      'AUTH_ACTION_PENDING',
                    ].includes(order.orderStatus) && (
                      <Icon icon="circle" color="#dfe3e7" />
                    )}
                    {['COMPLETE'].includes(order.orderStatus) && (
                      <Icon icon="tick-circle" intent="success" />
                    )}
                  </td>
                  <td>Complete</td>
                  <td>
                    {['COMPLETE'].includes(order.orderStatus) && (
                      <span className="bp3-text-small bp3-text-muted">
                        Update&nbsp;
                        <DateTime dateTime={order.updatedAt} />
                      </span>
                    )}
                  </td>
                </tr>
              </Fragment>
            )}
          </Fragment>
        )}
      </tbody>
    </HTMLTable>
  )
}

OrderStatusTimeline.propTypes = {
  order: PropTypes.object.isRequired,
}

export default OrderStatusTimeline

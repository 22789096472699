import React from 'react'
import { Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Mutation } from 'react-apollo'
import { successToast } from '@utils/toast'
import first from 'lodash/first'
import { toggleModal } from '@utils/cacheHelpers'
import { modalType } from '@utils/types'
import { string } from 'prop-types'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import Query from '@components/Query/Query'
import MenuForm from './MenuForm'
import EDIT_MENU_ITEMS_GROUP from '@components/Restaurant/Menu/mutations/editMenuItemsGroup.mutation'
import GET_MENU_DETAILS from '@components/Restaurant/Menu/queries/getMenuDetails.query'

const EditMenuModal = ({ modalOpen, modalClose, menuId, restaurantId }) => (
  <Dialog isOpen={modalOpen} onClose={modalClose} title="Edit Menu">
    <div className={Classes.DIALOG_BODY}>
      <Query query={GET_MENU_DETAILS} variables={{ id: menuId }}>
        {({ getMenuItemsGroups: { menuItemsGroups } }) => (
          <Mutation
            mutation={EDIT_MENU_ITEMS_GROUP}
            onError={defaultErrorHandler}
            onCompleted={() => {
              successToast('Menu successfully edited')
              toggleModal({
                editMenuModal: false,
              })
            }}
          >
            {editMenu => (
              <MenuForm
                mutation={editMenu}
                restaurantId={restaurantId}
                menu={first(menuItemsGroups)}
              />
            )}
          </Mutation>
        )}
      </Query>
    </div>
  </Dialog>
)

EditMenuModal.propTypes = {
  menuId: string,
  restaurantId: string,
  ...modalType,
}

export default EditMenuModal

import { Toaster, Position, Intent } from '@blueprintjs/core'

const errorToaster = Toaster.create({
  position: Position.TOP,
})

const successToaster = Toaster.create({
  position: Position.TOP,
  maxToasts: 1,
})

export const toast = toast =>
  toast.intent === 'danger'
    ? errorToaster.show({ ...toast, timeout: 10000 })
    : successToaster.show({ ...toast, timeout: 5000 })

export const loadingToast = message => {
  toast({
    message,
    icon: 'refresh',
    intent: Intent.NONE,
  })
}

export const infoToast = message => {
  toast({
    message,
    icon: 'info-sign',
    intent: Intent.PRIMARY,
  })
}

export const successToast = message => {
  toast({
    message,
    icon: 'tick',
    intent: Intent.SUCCESS,
  })
}

export const errorToast = error => {
  toast({
    icon: 'warning-sign',
    message: error,
    intent: Intent.DANGER,
  })
}

export const warningToast = warning => {
  toast({
    icon: 'warning-sign',
    message: warning,
    intent: Intent.WARNING,
  })
}

export default toast

import React from 'react'
import { string, bool, func, object } from 'prop-types'
import { Route } from 'react-router-dom'
import { NoPermissions } from '@components/UI/Permission/Permission'
import { productType } from '@utils/types'
import ReportsRestricted from '@components/UI/Permission/ReportsRestricted'

const CustomRoute = ({
  path,
  exact = false,
  component: Component,
  isPermissionAllowed = () => true,
  restrictReports = false,
  product,
}) => (
  <Route
    render={props => {
      if (restrictReports) {
        return <ReportsRestricted />
      }

      if (!isPermissionAllowed()) {
        return <NoPermissions {...props} />
      }

      return <Component {...props} product={product} />
    }}
    path={path}
    exact={exact}
  />
)

CustomRoute.propTypes = {
  path: string,
  exact: bool,
  component: func,
  render: func,
  isPermissionAllowed: func,
  location: object,
  product: productType,
}

export default CustomRoute

export const styles = _theme => ({
  linkStyledButton: {
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',

    '&:hover': {
      cursor: 'pointer',
    },
  },
})

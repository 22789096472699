import gql from 'graphql-tag'

const GET_ORDER = gql`
  query getOrder($id: ID!) {
    order(id: $id) {
      id
      paymentMethod
      orderNumber
      orderStatus
      estimatedCompletionTime
      estimatedDeliveryDate
      selectedDeliverySlot
      selectedDeliveryWindow
      fulfillmentMethod
      asap
      grossTotal
      hasSentPendingOrderAlert
      netTotal
      subtotalNet
      subtotalGross
      addOnItemsNet
      addOnItemsGross
      paid
      customerDeliveryAddress
      customerOrderNotes
      customerDeliveryNotes
      deliveryNotes
      platformFee
      platformCharge
      partnerFee
      partnerCharge
      partnerCommissionFee
      partnerCommissionCharge
      merchantCommissionCharge
      deliveryNetworkBookingId
      deliveryNetworkDriverName
      deliveryNetworkProvider
      deliveryNetworkBookingStatus
      deliveryNetworkJobTrackerURL
      deliveryNetworkDashboardURL
      deliveryNetworkPickupEta
      userAgent
      autoRejectAt
      autoAcceptedAt
      customerTaxes
      orderItems {
        id
        menuItem
        optionItems
        netTotal
        grossTotal
        singleItemNotes
        isAddOnItem
      }
      cancellationNotes
      customer {
        id
        firstName
        lastName
        phoneNumber
      }
      fulfillmentCharge
      partnerCharge
      discount
      discountAmount
      voucherKey
      tableId
      tableSnapshot
      createdAt
      updatedAt
      acceptedAt
      outlet {
        id
        name
        restaurant {
          id
          name
          image
        }
        outletPhone
        outletAddress {
          id
          firstLine
          city
          postcode
        }
        marketplace {
          id
          image
          defaultCancelText
          defaultRejectText
          partnerChargeDescription
          partnerTableChargeDescription
          receiptHideCustomerPhoneNumber
        }
      }
      stripeCharge
      review {
        id
        createdAt
        updatedAt
        foodQuality
        restaurantService
        deliveryTime
        reviewText
        approved
      }
    }
  }
`

export default GET_ORDER

import React, { useContext, useEffect } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import Dashboard from '@components/Reports/Dashboard/Dashboard'
import Transactions from '@components/Reports/Transaction/Transactions'
import TransactionReconciliation from '@components/Reports/Reconciliation/Reconciliation'
import { isPlatformLevelUser } from '@stores/userStore'

import { Route, Switch } from 'react-router-dom'
import { matchType } from '@utils/types'
import { object } from 'prop-types'
import ReportsRestricted from '@components/UI/Permission/ReportsRestricted'
import { isPeakTime } from '@utils/isPeakTime'

const restrictReports =
  isPeakTime() &&
  process.env.ENVIRONMENT_NAME === 'PROD' &&
  !isPlatformLevelUser()

const Reports = ({ match, product }) => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  const tabs = [{ to: '/reports', name: 'Reports' }]
  if (isPlatformLevelUser()) {
    tabs.push({ to: '/reports/transactions', name: 'Transactions' })
    tabs.push({
      to: '/reports/reconciliation',
      name: 'Reconciliation',
    })
  }
  useEffect(() => {
    configurePageLayout({
      product,
      tabs,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurePageLayout, product])

  return restrictReports ? (
    <ReportsRestricted />
  ) : (
    <Switch>
      <Route path={`${match.path}`} exact render={() => <Dashboard />} />
      <Route
        path={`${match.path}/transactions`}
        exact
        render={() => <Transactions />}
        isPermissionAllowed={() => isPlatformLevelUser()}
      />
      <Route
        path={`${match.path}/reconciliation`}
        exact
        render={() => <TransactionReconciliation product={undefined} />}
        isPermissionAllowed={() => isPlatformLevelUser()}
      />
    </Switch>
  )
}

Reports.propTypes = {
  match: matchType.isRequired,
  classes: object,
  location: object,
}

export default Reports

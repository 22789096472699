import React from 'react'
import first from 'lodash/first'
import { Mutation } from 'react-apollo'
import { string } from 'prop-types'
import { Tag, Card, Intent, H5 } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import Query from '@components/Query/Query'
import { penceToPounds, numberToPence } from '@utils/helpers'

import GET_MARKETPLACE from './queries/getMarketplaceFinancials.query'
import EDIT_MARKETPLACE_FINANCIALS from './mutations/editMarketplaceFinancials.mutation'
import EDIT_PAYMENT_METHODS from './mutations/editMarketplacePaymentMethods.mutation'
import EDIT_APPLEPAY_VERIFICATION from './mutations/editApplePayVerification.mutation'

import MarketplacePaymentMethodsForm from './MarketplacePaymentMethodsForm/MarketplacePaymentMethodsForm'
import FinancialsForm from './MarketplaceFinancialsForm/MarketplaceFinancialsForm'
import MarketplaceApplePayVerificationUpload from './MarketplacePaymentMethodsForm/MarketplaceApplePayVerificationUpload'

import { successToast } from '@utils/toast'
import { isPlatformLevelUser } from '@stores/userStore'

const Details = ({ match }) => {
  const { marketplace: marketplaceId } = match.params
  return (
    <Query
      query={GET_MARKETPLACE}
      variables={{ id: marketplaceId }}
      loaderTitle={'Loading Financials'}
    >
      {({ getMarketplaces: { regions } }) => {
        const marketplace = first(regions)
        if (!marketplace) {
          return 'Unable to find marketplace'
        }

        return (
          <div className="bp3-layout-row">
            <Card className="bp3-layout-col">
              <Mutation
                mutation={EDIT_MARKETPLACE_FINANCIALS}
                onError={defaultErrorHandler}
                onCompleted={({ editMarketplace }) =>
                  successToast(editMarketplace.message)
                }
              >
                {editMarketplaceFinancials => {
                  const initialValues = {
                    ...marketplace,
                    platformCharge: penceToPounds(marketplace.platformCharge),
                    platformTableCharge: penceToPounds(
                      marketplace.platformTableCharge
                    ),
                    partnerCharge: penceToPounds(marketplace.partnerCharge),
                    partnerTableCharge: penceToPounds(
                      marketplace.partnerTableCharge
                    ),
                    merchantCommissionCharge: penceToPounds(
                      marketplace.merchantCommissionCharge
                    ),
                    merchantTableCommissionCharge: penceToPounds(
                      marketplace.merchantTableCommissionCharge
                    ),
                  }
                  initialValues.partnerId = marketplace.partner.id
                  return (
                    <FinancialsForm
                      onSubmit={values => {
                        editMarketplaceFinancials({
                          variables: {
                            ...values,
                            platformCharge: numberToPence(
                              values.platformCharge
                            ),
                            platformTableCharge: numberToPence(
                              values.platformTableCharge
                            ),
                            partnerCharge: numberToPence(values.partnerCharge),
                            partnerTableCharge: numberToPence(
                              values.partnerTableCharge
                            ),
                            merchantCommissionCharge: numberToPence(
                              values.merchantCommissionCharge
                            ),
                            merchantTableCommissionCharge: numberToPence(
                              values.merchantTableCommissionCharge
                            ),
                          },
                        })
                      }}
                      initialValues={initialValues}
                    />
                  )
                }}
              </Mutation>
            </Card>

            <div className="bp3-layout-col-side">
              <Card>
                <H5>Payment Methods</H5>
                <Mutation
                  mutation={EDIT_PAYMENT_METHODS}
                  onError={defaultErrorHandler}
                  onCompleted={({ editMarketplace }) =>
                    successToast(editMarketplace.message)
                  }
                >
                  {editPaymentMethods => (
                    <MarketplacePaymentMethodsForm
                      stripeDirectPayment={marketplace.stripeDirectPayment}
                      paymentMethods={marketplace.paymentMethods}
                      cashText={marketplace.cashText}
                      cashCollectionText={marketplace.cashCollectionText}
                      onSubmit={values =>
                        editPaymentMethods({
                          variables: {
                            ...values,
                            id: marketplaceId,
                          },
                        })
                      }
                    />
                  )}
                </Mutation>
              </Card>
              {isPlatformLevelUser() && (
                <Card>
                  <h5 className="bp3-heading">
                    Apple Pay{' '}
                    {marketplace.applePayVerification && (
                      <Tag intent={Intent.SUCCESS} minimal>
                        VERIFIED
                      </Tag>
                    )}
                  </h5>
                  <Mutation
                    mutation={EDIT_APPLEPAY_VERIFICATION}
                    onError={defaultErrorHandler}
                    onCompleted={({ editMarketplaceFinancials }) =>
                      successToast(editMarketplaceFinancials.message)
                    }
                  >
                    {editMarketplaceFinancials => (
                      <MarketplaceApplePayVerificationUpload
                        onSubmit={values =>
                          editMarketplaceFinancials({
                            variables: {
                              applePayVerification: values.applePayVerification,
                              id: marketplaceId,
                            },
                          })
                        }
                      />
                    )}
                  </Mutation>
                </Card>
              )}
            </div>
          </div>
        )
      }}
    </Query>
  )
}

Details.propTypes = {
  marketplaceId: string.isRequired,
}

export default Details

import gql from 'graphql-tag'

const GET_MARKETPLACE = gql`
  query getMarketplace($id: ID!) {
    getMarketplaces(id: $id) {
      totalCount
      count
      regions {
        id
        image
        name
        companyType
        companyLegalName
        companyNumber
        taxReference
        contactName
        contactAddress {
          id
          firstLine
          secondLine
          thirdLine
          city
          postcode
          country
        }
        contactPhone
        contactEmail
        contactDoB
        supportPhone
        supportEmail
        partner {
          id
          stripeEnterpriseId
        }
        orderMode
        merchantType
        defaultRejectText
        defaultCancelText
        paymentMethods
        allowReviews
        enableAnalytics
        enablePayboxEnterprise
        enableCustomerV2Client
        receiptMessage
        receiptHideMarketplace
        receiptHideOutlet
        receiptHideCustomerPhoneNumber
        receiptLargeOrderNotes
        receiptLargeDeliveryNotes
        emailReceiptOnOrder
        storeURLApple
        storeURLGooglePlay
        trackingId
        cname
        brandColor
        ageRestrictionText
        checkoutText
        orderNoteText
        deliveryNoteText
        collectionNoteText
        specialInstructionsText
        tableNoteText
        vatNumber
        isVATregistered
        pollInterval
        allowOrderToTable
        allowMarketing
        allowSMSMarketing
        allowBilling
        allowFacebookMarketing
        allowSingleItemOrderNotes
        allowExtendedPreorders
        allowLocationSearch
        allowMultiOutletTerminal
        allowPendingOrderAlert
        allowOtterIntegration
        launchedAt
        promptForUpdates
        androidVersion
        iosVersion
        onboardingDescription
        allowOnboarding
        allowedCuisines
        optInAllowedCuisines
        pinpointAwsAccountId
        categoryEmojiOrImage
        outletCollectionVisibilityRadiusKM
        urlPath
        ga4Id
        confirmAddressAtPayment
        stripeEnterpriseId
        outletOpenStatus
      }
    }
  }
`

export default GET_MARKETPLACE

// import FormValidator from '@utils/formValidator'
import * as yup from 'yup'
export const validation = yup.object().shape({
  id: yup.string().nullable().required('ID is required'),
  marketplaceId: yup.string().nullable().required('Marketplace ID is required'),
  name: yup.string().nullable().required('Name is required'),
  companyType: yup.string().nullable().required('Company Type is required'),
  tin: yup
    .string()
    .nullable()
    .required(
      'TIN is required, make sure you have a marketplace Company Number or Tax Reference'
    ),
  tradingName: yup.string().nullable().required('Trading Name is required'),
  countryCode: yup.string().nullable().required('Country Code is required'),
  contactAddress: yup
    .string()
    .nullable()
    .required('Contact Address is required'),
  sendingEntityIN: yup
    .string()
    .nullable()
    .required(
      'Your Registration IN (Sending Entity) is required, it can be found after registering on the government portal'
    ),
  reportingYear: yup
    .string()
    .nullable()
    .required('Reporting Year is required')
    .matches(/^\d{4}$/, 'Invalid year format'),
})

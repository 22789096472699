import React, { Fragment } from 'react'
import { NonIdealState } from '@blueprintjs/core'

export const NoPermissions = () => (
  <div
    style={{
      display: 'flex',
      height: '100%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <NonIdealState
      icon="blocked-person"
      title="Unauthorised"
      description={
        <Fragment>
          You do not have permission to access this page, please contact your
          marketplace administrator or{' '}
          <a href="mailto:support@redbox.systems">support@redbox.systems</a>
        </Fragment>
      }
    />
  </div>
)

import React from 'react'

const OrderInfo = ({ classes, order, formatDateString }) => (
  <section className={classes.section}>
    <p className={classes.text}>
      Placed at: {formatDateString(order.createdAt)}
    </p>
    <p className={classes.text}>
      Accepted at:{' '}
      {order.acceptedAt ? formatDateString(order.acceptedAt) : 'N/A'}
    </p>
  </section>
)

export default OrderInfo

import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import { FormGroup, Slider, Intent, Button, Card } from '@blueprintjs/core'
import { KM_TO_MILES_FACTOR } from '../../../utils/constants'
import { successToast } from '../../../utils/toast'
import defaultErrorHandler from '../../../utils/defaultErrorHandler'
import EDIT_OUTLET_COLLECTION_VISIBILITY_RADIUS_KM from './mutations/editOutletCollectionVisibilityRadiusKM'

const CollectionCard = ({ marketplaceId, outletVisibilityRadiusKM }) => {
  const [radiusInputValue, setRadiusInputValue] = useState(
    outletVisibilityRadiusKM
  )

  return (
    <Mutation
      mutation={EDIT_OUTLET_COLLECTION_VISIBILITY_RADIUS_KM}
      refetchQueries={['getMarketplace']}
      onError={defaultErrorHandler}
      onCompleted={({ editMarketplace }) =>
        successToast(editMarketplace.message)
      }
    >
      {editMarketplace => (
        <Card>
          <form
            onSubmit={e => {
              e.preventDefault()
              editMarketplace({
                variables: {
                  id: marketplaceId,
                  outletCollectionVisibilityRadiusKM: radiusInputValue,
                },
              })
            }}
          >
            <h5 className="bp3-heading">Collection</h5>
            <FormGroup
              label="Outlet Visibility Radius"
              helperText="Only outlets within this radius of the customer will be listed for collection. This setting can take up to an hour to apply."
            >
              <Slider
                name="outletVisibilityRadiusKM"
                min={0}
                max={50}
                stepSize={5}
                labelStepSize={10}
                value={radiusInputValue}
                labelRenderer={(valInKM, { isHandleTooltip }) => {
                  if (!isHandleTooltip) return valInKM
                  const valInMiles = Math.round(valInKM * KM_TO_MILES_FACTOR)
                  return (
                    <span>
                      {valInKM}km&nbsp;/&nbsp;{valInMiles}mi
                    </span>
                  )
                }}
                onChange={setRadiusInputValue}
              />
            </FormGroup>
            <div className="bp-card-footer-actions">
              <Button text="Save" intent={Intent.DEFAULT} type="submit" />
            </div>
          </form>
        </Card>
      )}
    </Mutation>
  )
}

export default CollectionCard

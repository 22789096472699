import {
  H5,
  Button,
  Card,
  Checkbox,
  ControlGroup,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  Tag,
  TextArea,
  Divider,
} from '@blueprintjs/core'
import MarketplaceSelect from '@components/MarketplaceSelect/MarketplaceSelect'
import {
  isPartner,
  isPlatformLevelUser,
  isOutletFinancialUser,
  isAtLeastMarketplaceOwner,
  isAtLeastOutletUser,
} from '@stores/userStore'
import { countryList, selectionUpdate } from '@utils/helpers'
import get from 'lodash/get'
import { func, object } from 'prop-types'
import React, { Fragment } from 'react'
import ImageUpload from '../../ImageUpload/ImageUpload'
import PickupAddressFormPartial from '../shared/PickupAddressFormPartial'
import EntitySelectGroup from '@components/EnitySelect/EntitySelect'
import { format, subYears } from 'date-fns'
import { DateInput } from '@blueprintjs/datetime'

const isOnlineOverrideOptions = [
  { label: 'Terminal Based', value: 0 },
  {
    label: 'Online',
    value: 1,
  },
  { label: 'Offline', value: 2 },
]

const OutletDetailsForm = ({
  errors,
  values,
  handleChange,
  setFieldValue,
  handleSubmit,
  status,
  setStatus,
  showEscalationContact,
}) => {
  const userIsOutletFinancialUser = isOutletFinancialUser()
  return (
    <Card className="bp3-layout-col">
      <form onSubmit={handleSubmit}>
        <FormGroup
          label={
            <span style={{ fontSize: 16, fontWeight: 'bold' }}>
              Outlet Name
            </span>
          }
          labelFor="name"
          labelInfo="(Required)"
          helperText={
            errors.name
              ? errors.name
              : !userIsOutletFinancialUser && (
                  <ul>
                    <li>
                      Usually the initial address line or street name, ensuring
                      it's distinct from the business name.
                    </li>
                    <li>
                      Shown to the Customer if Business is set to "Display All
                      Outlets".
                    </li>
                  </ul>
                )
          }
          intent={errors.name ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            name="name"
            id="name"
            type="text"
            onChange={handleChange}
            value={values.name}
            large={true}
            disabled={userIsOutletFinancialUser}
            intent={errors.name ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>
        {(isPartner() || isPlatformLevelUser()) && (
          <FormGroup label="Marketplace" labelInfo="(Required)">
            <MarketplaceSelect
              marketplaceId={get(values.marketplace, 'id')}
              onChange={value => {
                setFieldValue('marketplace.id', value, false)
              }}
            />
          </FormGroup>
        )}

        <Divider />
        <br />

        <H5>Business Details</H5>

        <EntitySelectGroup
          name="companyType"
          selectedValue={values.companyType}
          onChange={value => setFieldValue('companyType', value)}
        />

        {['COMPANY', 'PARTNERSHIP', 'NON_PROFIT'].includes(
          values.companyType
        ) && (
          <FormGroup
            label="Company Information"
            labelInfo="(Required)"
            intent={Intent.DANGER}
            helperText={
              [errors.companyLegalName, errors.companyNumber].filter(Boolean)
                .length ? (
                <ul className="bp3-list-unstyled">
                  {[errors.companyLegalName, errors.companyNumber]
                    .filter(Boolean)
                    .map((errorString, index) => (
                      <li key={index}>{errorString}</li>
                    ))}
                </ul>
              ) : null
            }
          >
            <ControlGroup vertical={true}>
              <InputGroup
                name="companyLegalName"
                id="companyLegalName"
                type="text"
                leftIcon="office"
                onChange={handleChange}
                placeholder="Legal Company Name (e.g. Ltd, Inc, etc.)"
                intent={errors.companyLegalName ? Intent.DANGER : Intent.NONE}
                value={values.companyLegalName}
                rightElement={
                  values.companyType === 'COMPANY' &&
                  values.companyLegalName ? null : (
                    <Tag icon="warning-sign" intent="warning" minimal />
                  )
                }
              />

              <InputGroup
                name="companyNumber"
                id="companyNumber"
                type="text"
                leftIcon="document"
                placeholder="Company Number"
                onChange={handleChange}
                intent={errors.companyNumber ? Intent.DANGER : Intent.NONE}
                value={values.companyNumber}
                rightElement={
                  values.companyType === 'COMPANY' &&
                  values.companyNumber ? null : (
                    <Tag icon="warning-sign" intent="warning" minimal />
                  )
                }
              />
            </ControlGroup>
          </FormGroup>
        )}

        {values.companyType === 'PARTNERSHIP' && (
          <FormGroup
            label=" Unique Taxpayer Reference (UTR)"
            labelInfo="(Required for DPR)"
            labelFor="taxReference"
            helperText={
              errors.taxReference ? 'Highlighted fields are required' : ''
            }
            intent={errors.taxReference ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup
              name="taxReference"
              type="text"
              placeholder=""
              onChange={handleChange}
              intent={errors.taxReference ? Intent.DANGER : Intent.NONE}
              value={values.taxReference}
              rightElement={
                values.taxReference ? null : (
                  <Tag icon="warning-sign" intent="warning" minimal />
                )
              }
            />
          </FormGroup>
        )}

        <FormGroup
          label="Bank Account"
          labelInfo="(Used for manual payouts, shown on DPR.)"
        >
          <ControlGroup>
            <FormGroup
              labelFor="bankAccount"
              helperText={
                errors.bankAccount ? errors.bankAccount : 'Account Number'
              }
              intent={errors.bankAccount ? Intent.DANGER : Intent.NONE}
            >
              <InputGroup
                name="bankAccount"
                id="bankAccount"
                type="text"
                placeholder="12345678"
                onChange={handleChange}
                intent={errors.bankAccount ? Intent.DANGER : Intent.NONE}
                value={values.bankAccount}
              />
            </FormGroup>
            <FormGroup
              labelFor="bankSort"
              helperText={errors.bankSort ? errors.bankSort : 'Sort Code'}
              intent={errors.bankSort ? Intent.DANGER : Intent.NONE}
            >
              <InputGroup
                name="bankSort"
                id="bankSort"
                type="text"
                placeholder="00-11-22"
                onChange={handleChange}
                intent={errors.bankSort ? Intent.DANGER : Intent.NONE}
                value={values.bankSort}
              />
            </FormGroup>
          </ControlGroup>
        </FormGroup>

        <FormGroup
          label="Owner"
          labelInfo="(Required)"
          helperText={
            [
              errors.contactName,
              errors.contactEmail,
              errors.contactPhone,
            ].filter(Boolean).length ? (
              <ul className="bp3-list-unstyled">
                {[errors.contactName, errors.contactEmail, errors.contactPhone]
                  .filter(Boolean)
                  .map((errorString, index) => (
                    <li key={index}>{errorString}</li>
                  ))}
              </ul>
            ) : null
          }
          intent={Intent.DANGER}
        >
          <ControlGroup vertical={true}>
            <InputGroup
              name="contactName"
              id="contactName"
              type="text"
              leftIcon="person"
              placeholder="Full Name"
              onChange={handleChange}
              intent={errors.contactName ? Intent.DANGER : Intent.NONE}
              value={values.contactName}
              rightElement={
                values.contactName === '' ? (
                  <Tag icon="warning-sign" intent="warning" minimal />
                ) : null
              }
            />
            <InputGroup
              name="contactPhone"
              id="contactPhone"
              type="tel"
              leftIcon="phone"
              placeholder="Phone Number"
              onChange={handleChange}
              intent={errors.contactPhone ? Intent.DANGER : Intent.NONE}
              value={values.contactPhone}
              rightElement={
                values.contactPhone === '' ? (
                  <Tag icon="warning-sign" intent="warning" minimal />
                ) : null
              }
            />
            <InputGroup
              name="contactEmail"
              id="contactEmail"
              type="email"
              onChange={handleChange}
              placeholder="Email Address"
              intent={errors.contactEmail ? Intent.DANGER : Intent.NONE}
              value={values.contactEmail}
              leftIcon="envelope"
              rightElement={
                values.contactEmail === '' ? (
                  <Tag icon="warning-sign" intent="warning" minimal />
                ) : null
              }
            />
          </ControlGroup>
        </FormGroup>
        {values.companyType === 'INDIVIDUAL' && (
          <Fragment>
            <FormGroup
              label="National Insurance Number"
              labelInfo="(Required for DPR)"
              labelFor="taxReference"
              helperText={
                errors.taxReference ? 'Highlighted fields are required' : ''
              }
              intent={errors.taxReference ? Intent.DANGER : Intent.NONE}
            >
              <InputGroup
                name="taxReference"
                type="text"
                placeholder=""
                onChange={handleChange}
                intent={errors.taxReference ? Intent.DANGER : Intent.NONE}
                value={values.taxReference}
                rightElement={
                  values.taxReference ? null : (
                    <Tag icon="warning-sign" intent="warning" minimal />
                  )
                }
              />
            </FormGroup>
            <FormGroup
              label="Date of Birth"
              labelInfo="(Required for DPR)"
              labelFor="taxReference"
              helperText={
                errors.taxReference ? 'Highlighted fields are required' : ''
              }
              intent={errors.taxReference ? Intent.DANGER : Intent.NONE}
            >
              <DateInput
                parseDate={str => new Date(str)}
                placeholder="Date of Birth"
                maxDate={new Date(subYears(new Date(), 16))} // 16 years old min
                minDate={new Date(subYears(new Date(), 100))} // over 100, retire!
                formatDate={date => format(date, 'dd/MM/yyyy')}
                value={values.contactDoB ? new Date(values.contactDoB) : null}
                onChange={date => setFieldValue('contactDoB', date)}
                rightElement={
                  values.contactDoB ? null : (
                    <Tag icon="warning-sign" intent="warning" minimal />
                  )
                }
              />
            </FormGroup>
          </Fragment>
        )}

        <FormGroup
          label={`${
            values.companyType === 'INDIVIDUAL' ? 'Home' : 'Registered'
          } Address`}
          labelInfo="(Required)"
          labelFor="firstLine"
          helperText={
            errors.contactAddress ? 'Highlighted fields are required' : ''
          }
          intent={Intent.DANGER}
        >
          <ControlGroup vertical={true}>
            <InputGroup
              name="contactAddress.firstLine"
              id="firstLine"
              type="text"
              placeholder="First Line"
              onChange={handleChange}
              intent={
                get(errors, 'contactAddress.firstLine')
                  ? Intent.DANGER
                  : Intent.NONE
              }
              value={get(values.contactAddress, 'firstLine')}
              rightElement={
                get(values.contactAddress, 'firstLine') === '' ? (
                  <Tag icon="warning-sign" intent="warning" minimal />
                ) : null
              }
            />
            <InputGroup
              name="contactAddress.secondLine"
              type="text"
              placeholder="Second Line"
              onChange={handleChange}
              value={get(values.contactAddress, 'secondLine')}
            />
            <InputGroup
              name="contactAddress.thirdLine"
              type="text"
              placeholder="Third Line"
              onChange={handleChange}
              value={get(values.contactAddress, 'thirdLine')}
            />
            <InputGroup
              name="contactAddress.city"
              type="text"
              placeholder="City"
              onChange={handleChange}
              intent={
                get(errors, 'contactAddress.city') ? Intent.DANGER : Intent.NONE
              }
              value={get(values.contactAddress, 'city')}
            />

            <InputGroup
              name="contactAddress.postcode"
              type="text"
              placeholder="Postcode"
              onChange={e =>
                setFieldValue(
                  'contactAddress.postcode',
                  e.target.value.toUpperCase()
                )
              }
              intent={
                get(errors, 'contactAddress.postcode')
                  ? Intent.DANGER
                  : Intent.NONE
              }
              value={get(values.contactAddress, 'postcode')}
              rightElement={
                get(values.contactAddress, 'postcode') === '' ? (
                  <Tag icon="warning-sign" intent="warning" minimal />
                ) : null
              }
            />

            <HTMLSelect
              name="country"
              options={countryList()}
              value={get(values.contactAddress, 'country.code') || 'GB'}
              onChange={event => {
                const selection = selectionUpdate(event)
                setFieldValue(
                  'contactAddress.country',
                  {
                    name: selection.label,
                    code: selection.value,
                  },
                  false
                )
              }}
            />
          </ControlGroup>
        </FormGroup>

        <Divider />
        <br />

        <Fragment>
          <H5>Outlet Settings</H5>
          <PickupAddressFormPartial
            errors={errors}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={values}
          />
          <FormGroup
            label="Outlet Email Address"
            labelInfo="(Required)"
            labelFor="outletEmail"
            subLabel="Redbox will send email notifications to this address."
            helperText={errors.outletEmail ? errors.outletEmail : ''}
            intent={errors.outletEmail ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup
              name="outletEmail"
              id="outletEmail"
              type="email"
              leftIcon="envelope"
              onChange={handleChange}
              intent={errors.outletEmail ? Intent.DANGER : Intent.NONE}
              value={values.outletEmail}
            />
            <Checkbox
              name="emailOrderReceipt"
              label="Send Order Receipts"
              type="check"
              onChange={handleChange}
              checked={values.emailOrderReceipt}
            />
            {get(values.marketplace, 'invoiceEnabled', null) && (
              <Checkbox
                name="emailInvoices"
                label="Send Invoice on Mondays"
                type="check"
                onChange={handleChange}
                checked={values.emailInvoices}
              />
            )}
          </FormGroup>
          {showEscalationContact && (
            <FormGroup
              label="Escalation Contact"
              labelFor="escalationMobile"
              helperText={
                errors.escalationMobile ? errors.escalationMobile : ''
              }
              intent={errors.escalationMobile ? Intent.DANGER : Intent.NONE}
              subLabel="Redbox will send a Pending Order alert SMS to this number to notify you of orders that will auto-reject after 10 minutes."
            >
              <InputGroup
                name="escalationMobile"
                id="escalationMobile"
                type="text"
                leftIcon="mobile-phone"
                onChange={handleChange}
                intent={errors.escalationMobile ? Intent.DANGER : Intent.NONE}
                value={values.escalationMobile}
              />
            </FormGroup>
          )}

          <FormGroup
            label="Customer Contact Phone Number"
            labelInfo="(Required)"
            labelFor="outletPhone"
            helperText={errors.outletPhone ? errors.outletPhone : ''}
            intent={errors.outletPhone ? Intent.DANGER : Intent.NONE}
            subLabel="Shown to the customer for allergen queries or after checkout for order queries."
          >
            <InputGroup
              name="outletPhone"
              id="outletPhone"
              type="tel"
              leftIcon="phone"
              onChange={handleChange}
              intent={errors.outletPhone ? Intent.DANGER : Intent.NONE}
              value={values.outletPhone}
            />
          </FormGroup>

          {isAtLeastOutletUser() && (
            <Fragment>
              <FormGroup
                label="Online Override"
                labelFor="isOnlineOverride"
                subLabel={
                  <Fragment>
                    How should Redbox determine the{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://support.redbox.systems/docs/business-logic#BusinessLogic-Online/Offline/TerminalBased"
                    >
                      Online/Offline Status
                    </a>
                  </Fragment>
                }
              >
                <HTMLSelect
                  name="isOnlineOverride"
                  options={isOnlineOverrideOptions}
                  value={values.isOnlineOverride}
                  onChange={event => {
                    const selection = selectionUpdate(event)
                    setFieldValue('isOnlineOverride', selection.value, false)
                  }}
                />
              </FormGroup>
              {values.isOnlineOverride == 1 && isAtLeastMarketplaceOwner() && (
                <FormGroup label="Auto Accept Orders" labelFor="isAutoAccept">
                  {/* double equals is intentional - this can be 1 or '1' depending on
            whether the dropdown has been changed */}
                  <Checkbox
                    name="isAutoAccept"
                    checked={values.isAutoAccept}
                    onChange={handleChange}
                  >
                    Enable
                    <br />
                    <span className={'bp3-text-small bp3-text-muted'}>
                      Orders will be accepted automatically and will not be sent
                      to Terminal.
                    </span>
                  </Checkbox>
                </FormGroup>
              )}

              {values.otterStoreId && isAtLeastMarketplaceOwner() && (
                <FormGroup
                  label="Send to otter on Accept"
                  labelFor="sendToOtterOnAccept"
                >
                  <Checkbox
                    name="sendToOtterOnAccept"
                    checked={values.sendToOtterOnAccept}
                    onChange={handleChange}
                  >
                    Enable
                    <br />
                    <span className={'bp3-text-small bp3-text-muted'}>
                      Order will not be added to Otter until it has been
                      accepted in Redbox.
                    </span>
                  </Checkbox>
                </FormGroup>
              )}
            </Fragment>
          )}
        </Fragment>

        {!userIsOutletFinancialUser && (
          <Fragment>
            <Divider />
            <br />
            <H5>Menu</H5>
            <ImageUpload
              values={values}
              setFieldValue={setFieldValue}
              imageLabel="Outlet Logo Override"
              status={status}
              setStatus={setStatus}
              imageName="outletLogoOverride"
              subLabel="Replaces the Business logo on results."
              disabled={userIsOutletFinancialUser}
            />
            <ImageUpload
              values={values}
              setFieldValue={setFieldValue}
              imageLabel="Cover Image"
              status={status}
              setStatus={setStatus}
              imageName="coverImage"
              subLabel="Upload an image to be displayed as the Outlet header."
              disabled={userIsOutletFinancialUser}
            />
            <FormGroup
              label="Summary"
              labelFor="description"
              helperText={
                errors.description
                  ? errors.description
                  : 'Summary appears at the top of the menu index.'
              }
              intent={errors.description ? Intent.DANGER : Intent.NONE}
            >
              <TextArea
                name="description"
                id="description"
                type="text"
                onChange={handleChange}
                fill={true}
                value={values.description}
                style={{ height: 100 }}
                disabled={userIsOutletFinancialUser}
              />
            </FormGroup>
          </Fragment>
        )}
        {isPlatformLevelUser() && (
          <Fragment>
            <br />
            <H5>Integrations</H5>
            <FormGroup
              label="Otter Store ID"
              labelFor="otterStoreId"
              subLabel={'Links this Outlet to a Store in Otter.'}
              intent={errors.description ? Intent.DANGER : Intent.NONE}
            >
              <InputGroup
                name="otterStoreId"
                id="otterStoreId"
                type="text"
                onChange={handleChange}
                fill={true}
                value={values.otterStoreId}
              />
            </FormGroup>
            <br />
          </Fragment>
        )}
        <div className="bp-card-footer-actions">
          <Button text="Save" intent={Intent.NONE} type="submit" />
        </div>
      </form>
    </Card>
  )
}

OutletDetailsForm.propTypes = {
  errors: object,
  values: object,
  handleChange: func,
  setFieldValue: func,
  handleSubmit: func,
}

export default OutletDetailsForm

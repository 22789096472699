import React, { useState, Fragment } from 'react'
import { Mutation } from 'react-apollo'
import { func, string } from 'prop-types'
import split from 'lodash/split'
import momentTz from 'moment-timezone'
import { array } from 'prop-types'
import { Button, Classes, FormGroup, Card } from '@blueprintjs/core'
import { DatePicker } from '@blueprintjs/datetime'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import OUTLET_CLOSE_EARLY from './mutations/closeEarly.mutation'

const getDefaultClosedUntilTime = openingTimes => {
  if (!openingTimes || !openingTimes.length) {
    return null
  }

  // find next time the outlet is open
  const now = momentTz()
    .utc()
    .seconds(0)

  const openingTimeMoment = openingTimes.reduce(
    (nextOpeningTimeAcc, { start }) => {
      const [hours, minutes] = split(start.time, ':')
      const parsedHours = parseInt(hours, 10)
      const parsedMinutes = parseInt(minutes, 10)
      // convert openingTime to moment type and convert from GB time (GMT/BST) to UTC
      const openingTimeMoment = momentTz()
        .isoWeekday(start.day)
        .hour(parsedHours)
        .minutes(parsedMinutes)
        .seconds(0)
        .utc()

      // if the opening time is in the past then move it forward 1 week
      if (openingTimeMoment.isBefore(now)) {
        openingTimeMoment.add(1, 'weeks')
      }

      // if this opening time is before the previous acc value, override nextOpeningTimeAcc with it
      if (
        !nextOpeningTimeAcc ||
        openingTimeMoment.isBefore(nextOpeningTimeAcc)
      ) {
        nextOpeningTimeAcc = openingTimeMoment
      }

      return nextOpeningTimeAcc
    },
    null
  )

  return openingTimeMoment.toDate()
}

const ModalContent = ({ openingTimes, closeModal, outletId }) => {
  const [reopenAt, setReopenAt] = useState(
    getDefaultClosedUntilTime(openingTimes)
  )

  return (
    <Mutation
      mutation={OUTLET_CLOSE_EARLY}
      onError={defaultErrorHandler}
      onCompleted={({ closeEarly }) => {
        successToast(
          `Outlet is now closed until ${momentTz(
            closeEarly.outlet.closedUntil
          ).format('dddd, MMMM Do YYYY, h:mm a')}`
        )
        closeModal()
      }}
    >
      {closeEarly => (
        <Fragment>
            <FormGroup label="Select a time to reopen:" helperText="Defaults to next opening time">
              <DatePicker
              className="bp3-elevation-1"
                timePrecision="minutes"
                onChange={value => setReopenAt(value)}
                value={reopenAt}
              />
            </FormGroup>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button minimal onClick={() => closeModal()}>Cancel</Button>
            <Button
            intent="danger"
              onClick={() =>
                closeEarly({
                  variables: {
                    outletId,
                    reopenAt: reopenAt.toISOString(),
                  },
                })
              }
            >
              Close Outlet
            </Button>
          </div>
        </Fragment>
      )}
    </Mutation>
  )
}

ModalContent.propTypes = {
  openingTimes: array,
  closeModal: func,
  outletId: string,
}

export default ModalContent

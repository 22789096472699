import React, { Fragment, useEffect, useState, useContext } from 'react'
import {
  HTMLTable,
  Card,
  Tag,
  NonIdealState,
  Button,
  ButtonGroup,
  Icon,
  ControlGroup,
  Spinner,
} from '@blueprintjs/core'

import { Link } from 'react-router-dom'
import { endOfDay, format, isSameDay, parse, subDays } from 'date-fns'
import { upperFirst, get } from 'lodash'
import { errorToast } from '@utils/toast'
import { removeUnderscores } from '@utils/helpers'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import Currency from '@components/Currency/Currency'
import { canView } from '@stores/userStore'
import OutletSelect from '@components/OutletSelect/OutletSelect'
import OrderStatusInfo from '@components/Orders/Order/OrderStatusInfo'

import { Pager } from '@components/Toolbar'
import Sound from 'react-sound'
// @ts-ignore
import alarmSound from '@assets/sounds/alarm.mp3'
import { Query as ApolloQuery } from 'react-apollo'
import Query from '@components/Query/Query'
import GET_TERMINAL_ORDERS from './queries/getTerminalOrders.query'
import GET_MODALS from './queries/getModals.clientQuery'
import {
  getIntent,
  getHumanReadableStatus,
  getOrderStatusMapping,
} from '@components/Orders/helpers/helpers'

import { object } from 'prop-types'
import FilterRow from '@components/FilterRow/FilterRow'
import PrintOrderModal from '@components/Orders/Order/PrintOrderModal'
import FulfilmentStatus from '@components/Orders/Order/FulfilmentStatus'
import CloseOrReopen from '../CloseOrReopen/CloseOrReopen'
import ls from '@utils/localStorage'
import SettingsToggle from '@components/Toolbar/SettingsToggle'
import { isInAlarm } from '@utils/order/isInAlarm'
import { StringParam, useQueryParams } from 'use-query-params'
import DateTime from '@components/DateTime/DateTime'

const DELIVERY_PROVIDER_TO_TAG_ICON = {
  ICABBI: 'taxi',
  STUART: 'cycle',
  UBER_DIRECT: 'taxi',
}
const DEFAULT_RECORDS = 25
const DEFAULT_PAGINATION_STATE = {
  total: 0,
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: 0,
  navigationDisabled: false,
  orderBy: 'createdAt_DESC',
}

const PageLayoutWrapper = ({
  product,
  location,
  totalPending,
  totalPreparing,
  totalReady,
  totalCompleted,
  totalOrders,
  children,
}) => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    configurePageLayout({
      product,
      tabs: [
        {
          to: '/virtual-terminal/pending',
          name: 'Pending',
          count: totalPending,
          intent: 'danger',
        },
        {
          to: '/virtual-terminal/preparing',
          name: 'In Kitchen',
          count: totalPreparing,
        },
        {
          to: '/virtual-terminal/ready',
          name: 'Ready',
          count: totalReady,
        },
        {
          to: '/virtual-terminal/complete',
          name: 'Complete',
          count: totalCompleted,
        },
        {
          to: '/virtual-terminal/all',
          name: 'All',
          count: totalOrders,
        },
      ],
    })
  }, [
    configurePageLayout,
    product,
    location,
    totalPending,
    totalPreparing,
    totalReady,
    totalCompleted,
    totalOrders,
  ])

  return children
}

const Orders = props => {
  const [, setQueryParams] = useQueryParams({
    viewOrder: StringParam,
  })
  const [state, setState] = useState({
    ...DEFAULT_PAGINATION_STATE,
    outletId: ls.get('virtualTerminalOutletId'),
    after: format(
      subDays(endOfDay(subDays(new Date(), 1)), 7),
      "yyyyMMdd'T'HHmm"
    ),
    muteAlarms: ls.get('virtualTerminalMuteAlarms'),
    showDeliveryWindow: ls.get('virtualTerminalShowDeliveryWindow'),
  })

  const setTotalCount = (totalCount, returnCount) => {
    if (state.total !== totalCount) {
      setState(prevState => ({
        ...prevState,
        total: totalCount,
      }))
    }
    if (state.outcomeLength !== returnCount) {
      setState(prevState => ({
        ...prevState,
        outcomeLength: returnCount,
      }))
    }
  }

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (state.skip + state.first < state.total) {
      setState(prevState => ({
        ...prevState,
        skip: limitNext(prevState.skip, prevState.first, limit),
        first: prevState.defaultNmbRecords,
        last: null,
      }))
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < state.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setState(prevState => ({
      ...prevState,
      skip: limitPrevious(prevState.skip, prevState.first),
      first: prevState.defaultNmbRecords,
      last: null,
    }))
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setState(prevState => ({
      ...prevState,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    }))
  }

  useEffect(() => {
    if (state.muteAlarms !== ls.get('virtualTerminalMuteAlarms')) {
      ls.set('virtualTerminalMuteAlarms', state.muteAlarms)
    }
    if (
      state.showDeliveryWindow !== ls.get('virtualTerminalShowDeliveryWindow')
    ) {
      ls.set('virtualTerminalShowDeliveryWindow', state.muteAlarms)
    }
  }, [state.muteAlarms, state.showDeliveryWindow])

  const renderFilterBar = () => (
    <FilterRow>
      <ButtonGroup>
        <ControlGroup>
          <Button
            icon={
              <Icon
                icon={'symbol-circle'}
                color={state.outletId ? '#5bb70d' : '#CDD6DD'}
              />
            }
            disabled
          >
            {state.outletId ? 'ONLINE' : 'OFFLINE'}
          </Button>
          <OutletSelect
            showRestaurantName
            isOnlineOverride
            placeholder="Outlet  [ Select to Connect ]"
            outletId={state.outletId}
            onChange={outletId => {
              setState(prevState => ({
                ...prevState,
                outletId,
              }))
              ls.set('virtualTerminalOutletId', outletId)
            }}
          />
          {state.outletId && <CloseOrReopen outletId={state.outletId} />}
        </ControlGroup>
      </ButtonGroup>

      <ButtonGroup>
        <SettingsToggle
          simpleView={true}
          isMuted={state.muteAlarms}
          showDeliveryWindow={state.showDeliveryWindow}
          updateState={setting => {
            switch (setting) {
              case 'muteAlarms':
                setState(prevState => ({
                  ...prevState,
                  muteAlarms: !prevState.muteAlarms,
                }))
                break
              case 'showDeliveryWindow':
                setState(prevState => ({
                  ...prevState,
                  showDeliveryWindow: !prevState.showDeliveryWindow,
                }))
                break
            }
          }}
        />
        <Pager
          goToPrevious={goToPrevious}
          goToNext={goToNext}
          goToPage={goToPage}
          defaultNmbRecords={DEFAULT_RECORDS}
          skip={state.skip}
          total={state.total}
          outcomeLength={state.outcomeLength}
          dataName="Orders"
        />
      </ButtonGroup>
    </FilterRow>
  )

  const afterDate = parse(state.after, "yyyyMMdd'T'HHmm", new Date())

  const orderQueryVariables = {
    outletId: state.outletId,
    afterDate: afterDate,
    ...getOrderStatusMapping(props.match.params.statusFilter),
    ...state,
  }

  return (
    <Query query={GET_MODALS}>
      {({ printOrderModal }) => (
        <Fragment>
          <PrintOrderModal open={printOrderModal} />
          <ApolloQuery
            pollInterval={60000}
            query={GET_TERMINAL_ORDERS}
            variables={orderQueryVariables}
            fetchPolicy="network-only"
            skip={!state.outletId}
            onCompleted={data => {
              if (data.orders) {
                setTotalCount(data.orders.totalCount, data.orders.count)
              }
            }}
          >
            {({ loading, error = null, data, networkStatus }) => {
              const refetchQueries = [
                {
                  query: GET_TERMINAL_ORDERS,
                  variables: orderQueryVariables,
                },
              ]

              if (loading) {
                return (
                  <NonIdealState
                    icon={<Spinner size={60} value={null} />}
                    title="Loading Orders"
                    description="Please wait..."
                  />
                )
              }

              const {
                totalOrders = 0,
                totalPending = 0,
                totalPreparing = 0,
                totalReady = 0,
                totalCompleted = 0,
              } = (data && data.orders) || {}

              let orders = []
              if (!error && data && data.orders) {
                orders = data.orders.orders.filter(order => order.orderStatus)
              }

              return (
                <PageLayoutWrapper
                  location={props.location}
                  product={props.product}
                  totalPending={totalPending}
                  totalPreparing={totalPreparing}
                  totalReady={totalReady}
                  totalCompleted={totalCompleted}
                  totalOrders={totalOrders}
                >
                  {error &&
                    errorToast(
                      networkStatus === 8
                        ? 'Cannot connect to server, please retry.'
                        : error.message
                    )}
                  {!state.muteAlarms && !!totalPending && (
                    // @ts-ignore
                    <Sound
                      url={alarmSound}
                      playStatus={
                        // @ts-ignore
                        Sound.status.PLAYING
                      }
                    />
                  )}

                  <div className="bp3-table-frame">
                    {renderFilterBar()}
                    {!error && !state.outletId ? (
                      <NonIdealState
                        icon="offline"
                        title="Virtual Terminal - Offline"
                        description="Select an outlet and receive orders to your browser."
                      />
                    ) : !error && orders.length === 0 ? (
                      <Fragment>
                        <NonIdealState
                          icon="stopwatch"
                          title="Waiting for Orders"
                          description="When you receive an order it will appear here."
                        />
                      </Fragment>
                    ) : (
                      <div className="bp3-table-container bp3-scrollable">
                        <Card className="bp3-noPad">
                          <HTMLTable bordered={false} interactive={true}>
                            <thead>
                              <tr>
                                <th>Order</th>
                                <th>Customer</th>
                                <th>Total</th>
                                <th>Creation</th>
                                <th colSpan={2}>Status</th>
                                <th>Last Update</th>
                                <th>Fulfilment</th>
                                {state.showDeliveryWindow && (
                                  <td>Delivery Window</td>
                                )}
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!error &&
                                orders.map(order => {
                                  const isAlarm = isInAlarm(
                                    order.orderStatus,
                                    order.autoRejectAt
                                  )

                                  const customerName = `${upperFirst(
                                    order.customer.firstName
                                  )} ${upperFirst(
                                    order.customer.lastName.charAt(0)
                                  )}`

                                  return (
                                    <tr key={order.id}>
                                      <td>
                                        <a
                                          onClick={() => {
                                            setQueryParams({
                                              viewOrder: order.id,
                                            })
                                          }}
                                        >
                                          #{order.orderNumber}
                                        </a>
                                      </td>
                                      <td>
                                        {canView('customers') ? (
                                          <Link
                                            to={`/customer/${order.customer.id}`}
                                          >
                                            {customerName}
                                          </Link>
                                        ) : (
                                          customerName
                                        )}
                                      </td>
                                      <td>
                                        <Icon
                                          icon="credit-card"
                                          color={
                                            order.paymentMethod.split(
                                              '_'
                                            )[0] === 'CARD'
                                              ? '#30404d'
                                              : '#CDD6DD'
                                          }
                                        />{' '}
                                        <Currency amount={order.grossTotal} />
                                      </td>
                                      <td>
                                        <span
                                          style={
                                            isAlarm ? { color: 'red' } : {}
                                          }
                                        >
                                          {format(
                                            new Date(order.createdAt),
                                            'HH:mm'
                                          )}
                                        </span>
                                        &nbsp;
                                        {!isSameDay(
                                          new Date(),
                                          new Date(order.createdAt)
                                        ) &&
                                          format(
                                            new Date(order.createdAt),
                                            'd/MM'
                                          )}
                                      </td>
                                      <td>
                                        {isAlarm ? (
                                          <Tag
                                            minimal={false}
                                            intent={'danger'}
                                          >
                                            {getHumanReadableStatus(
                                              order.orderStatus,
                                              order.createdAt,
                                              order.asap
                                            )}
                                          </Tag>
                                        ) : (
                                          <Tag
                                            minimal={true}
                                            intent={getIntent(
                                              getHumanReadableStatus(
                                                order.orderStatus,
                                                order.createdAt,
                                                order.asap
                                              )
                                            )}
                                          >
                                            {getHumanReadableStatus(
                                              order.orderStatus,
                                              order.createdAt,
                                              order.asap
                                            )}
                                          </Tag>
                                        )}
                                      </td>
                                      <td>
                                        {order.fulfillmentMethod ===
                                          'NETWORK' && (
                                          <Tag
                                            icon={
                                              DELIVERY_PROVIDER_TO_TAG_ICON[
                                                order.deliveryNetworkProvider
                                              ] || 'taxi'
                                            }
                                            intent={'primary'}
                                            minimal
                                          >
                                            {removeUnderscores(
                                              order.deliveryNetworkBookingStatus
                                            )}
                                          </Tag>
                                        )}
                                        {order.fulfillmentMethod ===
                                          'TABLE' && (
                                          <Tag
                                            icon={'locate'}
                                            intent={'primary'}
                                            minimal
                                          >
                                            {`${get(
                                              order.tableSnapshot,
                                              'friendlyName',
                                              'Table (Unknown)'
                                            ).replace(/^table\s/i, '')}`}
                                          </Tag>
                                        )}
                                      </td>
                                      <td>
                                        <DateTime dateTime={order.updatedAt} />
                                      </td>
                                      <td>
                                        <FulfilmentStatus
                                          orderStatus={order.orderStatus}
                                          fulfillmentMethod={
                                            order.fulfillmentMethod
                                          }
                                          createdAt={order.createdAt}
                                          updatedAt={order.updatedAt}
                                          selectedDeliveryWindow={
                                            order.selectedDeliveryWindow
                                          }
                                          estimatedDeliveryDate={
                                            order.selectedDeliveryWindow
                                              ? order.selectedDeliveryWindow.end
                                              : order.estimatedDeliveryDate
                                          }
                                          estimatedCompletionTime={
                                            order.selectedDeliveryWindow
                                              ? order.selectedDeliveryWindow
                                                  .start
                                              : order.estimatedCompletionTime
                                          }
                                          asap={order.asap}
                                          tableFriendlyName={get(
                                            order.tableSnapshot,
                                            'friendlyName',
                                            'Table (Unknown)'
                                          )}
                                        />
                                      </td>
                                      {state.showDeliveryWindow && (
                                        <td>
                                          {order.selectedDeliveryWindow ? (
                                            <Fragment>
                                              {order.selectedDeliveryWindow
                                                .start
                                                ? format(
                                                    new Date(
                                                      order.selectedDeliveryWindow.start
                                                    ),
                                                    'HH:mm'
                                                  )
                                                : order.estimatedCompletionTime
                                                ? format(
                                                    new Date(
                                                      order.estimatedCompletionTime
                                                    ),
                                                    'HH:mm'
                                                  )
                                                : null}
                                              {' - '}
                                              {format(
                                                new Date(
                                                  order.selectedDeliveryWindow.end
                                                ),
                                                'HH:mm'
                                              )}
                                            </Fragment>
                                          ) : order.estimatedDeliveryDate ? (
                                            format(
                                              new Date(
                                                order.estimatedDeliveryDate
                                              ),
                                              'HH:mm'
                                            )
                                          ) : null}
                                        </td>
                                      )}
                                      <td>
                                        <OrderStatusInfo
                                          order={order}
                                          minimal={true}
                                          offerToPrintOnSuccess={true}
                                          refetchQueries={refetchQueries}
                                        />
                                      </td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </HTMLTable>
                        </Card>
                      </div>
                    )}
                  </div>
                </PageLayoutWrapper>
              )
            }}
          </ApolloQuery>
        </Fragment>
      )}
    </Query>
  )
}

Orders.propTypes = {
  location: object,
  match: object,
}

export default Orders

import React, { Fragment, useContext, useEffect } from 'react'
import { split } from 'lodash'
import { object } from 'prop-types'
import { Switch, Redirect } from 'react-router'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { matchType } from '@utils/types'
import Route from '@ui/Route/Route'
import {
  canPerformAction,
  canPerformMutation,
  getRole,
  isPlatformLevelUser,
  isAtLeastPartner,
  isAtLeastMarketplaceOwner,
  isAtLeastMarketplaceAdmin,
} from '@stores/userStore'
import Dashboard from '@components/Marketplace/Dashboard/Dashboard'
import Details from '@components/Marketplace/Details/Details'
import Financials from '@components/Marketplace/Financials/Financials'
import Billing from '@components/Marketplace/Billing/Billing'
import DeliveryZones from '@components/Marketplace/DeliveryZones/DeliveryZones'
import DeliveryNetworks from '@components/Marketplace/DeliveryNetworks/DeliveryNetworks'
import MarketplaceMap from '@components/Marketplace/MarketplaceMap/MapMarketplace'
import MessageTemplates from '@components/Marketplace/MessageTemplates/MessageTemplates'
import EmailTheme from '@components/Marketplace/EmailTheme/EmailTheme'
import Website from '@components/Marketplace/Website/Website'
import Segments from '@components/Marketplace/Segments/Segments'
import Legal from '@components/Marketplace/Legal/Legal'
import Support from '@components/Marketplace/Support/Support'
import Users from '@components/Users/Users'
import UsersPageWrapper from '@components/Users/UsersPageWrapper'
import usersPageContext from '@components/Users/usersPageContext'
import blacklistedAddressesPageContext from '@components/BlacklistedAddresses/BlacklistedAddressesPageContext'
import BlacklistedAddresses from '../BlacklistedAddresses/BlacklistedAddresses'
import BlacklistedAddressesPageWrapper from '../BlacklistedAddresses/BlacklistedAddressesPageWrapper'
import DisplayApp from '../Apps/DisplayApp'
import AppPageWrapper from '@components/Apps/AppPageWrapper'
import AppPageContext from '../Apps/appPageContext'
import MarketplaceVouchers from './MarketplaceVouchers/MarketplaceVouchers'
import DNS from './DNS/DNS'
import { environmentName } from '../../../config/config'
import POS from './POS/POS'
import { WebsiteTheme } from './WebsiteTheme/WebsiteTheme'
import Payments from './Payments/Payments'
import {
  OVERLAYS,
  OuterLayoutOverlayContext,
  OuterLayoutOverlayContextProvider,
} from './OuterLayoutOverlayContext/OuterLayoutOverlayContext'

const IS_PROD_OR_STAGING = ['PROD', 'STAGING'].includes(environmentName)

const getButton = ({
  pathname,
  openOverlay,
  setAppModalOpen,
  setInviteUserModalOpen,
  setBlacklistedAddressModalOpen,
  appId,
}) => {
  switch (split(pathname, '/')[3]) {
    case 'delivery-zones':
      return {
        text: 'Add Delivery Zone',
        onClick: () => {
          openOverlay(OVERLAYS.ADD_DELIVERY_ZONE)
        },
      }
    case 'delivery-networks':
      return {
        text: 'Add Delivery Network',
        onClick: () => {
          openOverlay(OVERLAYS.ADD_DELIVERY_NETWORK)
        },
      }
    case 'segments':
      return {
        text: 'Create Segment',
        onClick: () => {
          openOverlay(OVERLAYS.ADD_SEGMENT)
        },
      }
    case 'support':
      return {
        text: 'Add FAQ',
        onClick: () => {
          openOverlay(OVERLAYS.ADD_FAQ)
        },
      }
    case 'apps': {
      return appId && canPerformAction('appBuilds')
        ? {
            text: 'Start Build',
            onClick: () => {
              setAppModalOpen(true)
            },
          }
        : null
    }
    case 'users':
      return canPerformAction('inviteUser')
        ? {
            text: 'Invite user',
            onClick: () => setInviteUserModalOpen(true),
          }
        : null
    case 'blacklisted-addresses':
      return canPerformAction('blacklistAddress')
        ? {
            text: 'Blacklist Address',
            onClick: () => setBlacklistedAddressModalOpen(true),
          }
        : null
    case 'discount-codes':
      return canPerformAction('editDiscount')
        ? {
            text: 'Create Discount Code',
            onClick: () => {
              openOverlay(OVERLAYS.CREATE_VOUCHER)
            },
          }
        : null
    case 'pos':
      return canPerformMutation('addPOSDevice')
        ? {
            text: 'Add POS Device',
            onClick: () => {
              openOverlay(OVERLAYS.ADD_POS_DEVICE)
            },
          }
        : null
    default:
      return null
  }
}

const Marketplace = props => {
  const { setModalOpen: setInviteUserModalOpen } = useContext(usersPageContext)
  const { openOverlay } = useContext(OuterLayoutOverlayContext)
  const { setBlacklistedAddressModalOpen } = useContext(
    blacklistedAddressesPageContext
  )

  const { appId, setAppModalOpen } = useContext(AppPageContext)

  const userRole = getRole()

  const { configurePageLayout } = useContext(PageLayoutContext)
  const match = props.match
  const product = props.product
  const location = props.location

  useEffect(() => {
    const button = getButton({
      pathname: location.pathname,
      openOverlay,
      setAppModalOpen,
      setInviteUserModalOpen,
      setBlacklistedAddressModalOpen,
      appId,
    })

    configurePageLayout({
      product,
      tabs: [
        {
          to: `${match.url}/overview`,
          name: 'Overview',
          hide: ['MARKETPLACE_USER'].includes(userRole),
        },
        {
          to: `${match.url}/details`,
          name: 'Details',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `${match.url}/payments`,
          name: 'Payments',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `${match.url}/financials`,
          name: 'Financials',
          hide: !isAtLeastPartner(),
        },

        {
          to: `${match.url}/billing`,
          name: 'Billing',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `${match.url}/delivery-networks`,
          name: 'Delivery Networks',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `${match.url}/delivery-zones`,
          name: 'Delivery Zones',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `${match.url}/map`,
          name: 'Map',
          hide: !isAtLeastPartner(),
        },
        {
          to: `${match.url}/discount-codes`,
          name: 'Discount Codes',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `${match.url}/message-templates`,
          name: 'Messaging',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `${match.url}/email-theme`,
          name: 'Email Theme',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `${match.url}/website`,
          name: 'Website',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `${match.url}/branding`,
          name: 'Branding',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `${match.url}/segments`,
          name: 'Segments',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `${match.url}/support`,
          name: 'Support',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `${match.url}/legal`,
          name: 'Legal',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `${match.url}/apps`,
          name: 'Apps',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `${match.url}/users`,
          name: 'Users',
          hide: !isAtLeastMarketplaceAdmin(),
        },
        {
          to: `${match.url}/blacklisted-addresses`,
          name: 'Blacklist',
          hide: !isAtLeastMarketplaceOwner(),
        },
        {
          to: `${match.url}/pos`,
          name: 'POS',
          // TODO: Remove the platform level user assertion once ready for prod. RED-4726
          hide: IS_PROD_OR_STAGING && !isPlatformLevelUser(),
        },
        { to: `${match.url}/DNS`, name: 'DNS', hide: !isAtLeastPartner() },
      ],
      button,
    })
  }, [
    configurePageLayout,
    match.url,
    location.pathname,
    product,
    setInviteUserModalOpen,
    setAppModalOpen,
    openOverlay,
    setBlacklistedAddressModalOpen,
    appId,
    userRole,
  ])

  return (
    <Fragment>
      <section>
        <Switch>
          <Redirect
            from="/marketplaces/:marketplace"
            to={'/marketplaces/:marketplace/overview'}
            exact={true}
          />
          <Route
            path={`${match.path}/overview`}
            exact={true}
            component={Dashboard}
          />
          <Route
            path={`${match.path}/details`}
            exact
            isPermissionAllowed={() => isAtLeastMarketplaceOwner()}
            component={Details}
          />
          <Route
            path={`${match.path}/payments`}
            exact
            isPermissionAllowed={() => isAtLeastMarketplaceOwner()}
            component={Payments}
          />
          <Route
            path={`${match.path}/financials`}
            exact
            isPermissionAllowed={() => isAtLeastPartner()}
            component={Financials}
          />

          <Route
            path={`${match.path}/billing`}
            exact
            isPermissionAllowed={() => isAtLeastMarketplaceOwner()}
            component={Billing}
          />
          <Route
            path={`${match.path}/delivery-networks`}
            exact
            isPermissionAllowed={() => isAtLeastMarketplaceAdmin()}
            component={DeliveryNetworks}
          />
          <Route
            path={`${match.path}/delivery-zones`}
            exact
            isPermissionAllowed={() => isAtLeastMarketplaceOwner()}
            component={DeliveryZones}
          />
          <Route
            path={`${match.path}/map`}
            exact
            isPermissionAllowed={() => isAtLeastPartner()}
            component={MarketplaceMap}
          />
          <Route
            path={`${match.path}/message-templates`}
            exact
            component={MessageTemplates}
            isPermissionAllowed={() => isAtLeastMarketplaceAdmin()}
          />
          <Route
            path={`${match.path}/email-theme`}
            exact
            component={EmailTheme}
            isPermissionAllowed={() => isAtLeastMarketplaceAdmin()}
          />
          <Route
            path={`${match.path}/website`}
            exact
            component={Website}
            isPermissionAllowed={() => isAtLeastMarketplaceAdmin()}
          />
          <Route
            path={`${match.path}/branding`}
            exact
            component={WebsiteTheme}
            isPermissionAllowed={() => isAtLeastMarketplaceAdmin()}
          />
          <Route
            path={`${match.path}/segments`}
            exact
            component={Segments}
            isPermissionAllowed={() => isAtLeastMarketplaceOwner()}
          />
          <Route
            path={`${match.path}/support`}
            exact
            component={Support}
            isPermissionAllowed={() => isAtLeastMarketplaceOwner()}
          />
          <Route
            path={`${match.path}/legal`}
            exact
            component={Legal}
            isPermissionAllowed={() => isAtLeastMarketplaceOwner()}
          />
          <Route
            path={`${match.path}/users`}
            exact
            isPermissionAllowed={() => isAtLeastMarketplaceAdmin()}
            component={Users}
          />
          <Route
            path={`${match.path}/blacklisted-addresses`}
            exact
            isPermissionAllowed={() => isAtLeastMarketplaceOwner()}
            component={BlacklistedAddresses}
          />
          <Route
            path={`${match.path}/apps`}
            exact={true}
            component={DisplayApp}
            isPermissionAllowed={() => isAtLeastMarketplaceAdmin()}
          />
          <Route
            path={`${match.path}/discount-codes`}
            exact={true}
            component={MarketplaceVouchers}
            isPermissionAllowed={() => isAtLeastMarketplaceOwner()}
          />
          <Route
            path={`${match.path}/DNS`}
            exact={true}
            component={DNS}
            isPermissionAllowed={() => isAtLeastPartner()}
          />
          <Route
            path={`${match.path}/pos`}
            exact={true}
            component={POS}
            isPermissionAllowed={() => isPlatformLevelUser()}
          />
        </Switch>
      </section>
    </Fragment>
  )
}

const MarketplacePageWrapper = props => (
  <BlacklistedAddressesPageWrapper entityName="marketplace">
    <UsersPageWrapper entityName="marketplace">
      <AppPageWrapper entityName="marketplace" location={location.pathname}>
        <OuterLayoutOverlayContextProvider>
          <Marketplace {...props} />
        </OuterLayoutOverlayContextProvider>
      </AppPageWrapper>
    </UsersPageWrapper>
  </BlacklistedAddressesPageWrapper>
)

Marketplace.propTypes = {
  match: matchType.isRequired,
  location: object,
}

export default MarketplacePageWrapper

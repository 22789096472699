import Query from '@components/Query/Query'
import TypeaheadSingle from '@components/Typeahead/TypeaheadSingle'
import React from 'react'
import { GET_MENU_ITEMS } from './queries/getMenuItems.query'

export const MenuItemSelectByOutlet = ({
  outletId,
  menuItemId,
  setSelected,
  disabled,
}) =>
  outletId ? (
    <Query
      query={GET_MENU_ITEMS}
      showLoader={false}
      variables={{
        outletId,
      }}
    >
      {({ getOutlets }) => {
        const [outlet] = getOutlets.outlets
        const menuItems = outlet.menuItemsGroups
          .filter(({ addOnItemsMenu }) => !addOnItemsMenu)
          .reduce(
            (acc, menuItemGroup) => [
              ...acc,
              ...menuItemGroup.menuItems.map(menuItem => ({
                id: menuItem.id,
                name: menuItem.name,
                label: menuItemGroup.name,
              })),
            ],
            []
          )

        return (
          <TypeaheadSingle
            items={menuItems}
            selected={menuItemId}
            setSelected={setSelected}
            disabled={disabled}
            placeholder="Select a menu item"
          />
        )
      }}
    </Query>
  ) : (
    <TypeaheadSingle
      items={[]}
      selected={menuItemId}
      setSelected={setSelected}
      disabled={true}
      placeholder="Please select an outlet"
    />
  )

export const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    padding: '0px 10px',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%',
  },
  timesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: 20,
  },
  statusImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  statusImage: { width: 30, paddingBottom: 5 },
  orderNumberContainer: {
    marginLeft: 12,
    display: 'flex',
    flexDirection: 'column',
  },
  orderStatusContainer: {
    marginLeft: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

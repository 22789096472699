import React from 'react'
import injectSheet from 'react-jss'
import cx from 'classnames'
import { styles } from './Totals.styles'
import capitalize from 'lodash/capitalize'
import { number, string, object } from 'prop-types'
import { Divider, Tag } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import Currency from '@components/Currency/Currency'
import { Fragment } from 'react'
import get from 'lodash/get'

const Totals = ({
  fulfillmentCharge,
  fulfillmentMethod,
  partnerCharge,
  outlet,
  grossTotal,
  subtotalGross,
  addOnItemsGross,
  classes,
  discountAmount,
  discount,
  voucherKey,
  customerTaxes,
}) => {
  let discountLink = '#'
  if (discount) {
    if (voucherKey) {
      // discount comes from a voucher
      discountLink = `/marketplaces/${
        voucherKey.split('-')[1]
      }/discount-codes?discountId=${discount.id}`
    } else if (discount.customerEnrolled) {
      // discount comes from a member discount
      discountLink = `/business/${get(
        outlet,
        'restaurant.id',
        '#'
      )}/members?discountId=${discount.id}`
    } else {
      // a regular discount
      discountLink = `/business/${get(
        outlet,
        'restaurant.id',
        '#'
      )}/discounts?discountId=${discount.id}`
    }
  }

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.row}>
          <p>Subtotal</p>
          <p>
            <Currency amount={subtotalGross} />
          </p>
        </div>
        {addOnItemsGross > 0 && (
          <div className={cx(classes.row, classes.charge)}>
            <p>Add-On</p>
            <p>
              (<Currency amount={addOnItemsGross} />)
            </p>
          </div>
        )}
        {discount && (
          <div className={cx(classes.row, classes.charge)}>
            <p>
              Discount{' '}
              {voucherKey && <Tag minimal>{voucherKey.split('-')[0]}</Tag>}{' '}
              <Link to={discountLink}>{discount.name}</Link>
            </p>

            <p>
              - <Currency amount={discountAmount} />
            </p>
          </div>
        )}
        {fulfillmentMethod !== 'TABLE' && (
          <div className={cx(classes.row, classes.charge)}>
            <p>{`${capitalize(fulfillmentMethod)}`}</p>
            <p>
              <Currency amount={fulfillmentCharge} />
            </p>
          </div>
        )}
        <div className={cx(classes.row, classes.charge)}>
          <p>
            {fulfillmentMethod === 'TABLE'
              ? outlet.marketplace.partnerTableChargeDescription
              : outlet.marketplace.partnerChargeDescription || 'Partner Charge'}
          </p>
          <p>
            <Currency amount={partnerCharge} />
          </p>
        </div>
        <div className={cx(classes.row, classes.charge)}>
          <p>VAT</p>
          <p>
            (<Currency amount={customerTaxes} />)
          </p>
        </div>
      </div>
      <Divider />
      <div className={classes.container}>
        <div className={cx(classes.row, classes.total)}>
          <p>Total</p>
          <p>
            <Currency amount={grossTotal} />
          </p>
        </div>
      </div>
    </Fragment>
  )
}

Totals.propTypes = {
  fulfillmentCharge: number,
  fulfillmentMethod: string,
  outlet: object,
  partnerCharge: number,
  subtotalGross: number,
  grossTotal: number,
  classes: object,
  discountAmount: number,
  discount: object,
  customerTaxes: number,
}

export default injectSheet(styles)(Totals)

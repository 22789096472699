import React from 'react'
import { Mutation } from 'react-apollo'
import { MenuOptionItemDefinition } from '../definitions/MenuOptionItemDefinition'
import Query from '@components/Query/Query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { ImportTable } from '../../ImportTable/ImportTable'

import { GET_OPTION_ITEMS_FOR_DOWNLOAD } from '../queries/getOptionItemsForDownload.query'
import { EDIT_OPTION_ITEM } from './mutations/editOptionItem.mutation'
import { ADD_OPTION_ITEM } from './mutations/addOptionItem.mutation'
import { importItemSchema } from './schema/optionItemFormSchema'

const MutationProvider = ({ children }) => {
  return (
    <Mutation mutation={EDIT_OPTION_ITEM} onError={defaultErrorHandler}>
      {editOptionItem => (
        <Mutation mutation={ADD_OPTION_ITEM} onError={defaultErrorHandler}>
          {addOptionItem => children({ editOptionItem, addOptionItem })}
        </Mutation>
      )}
    </Mutation>
  )
}

export const OptionItemImport = ({ importData, restaurantId, handleClose }) => (
  <MutationProvider>
    {({ editOptionItem, addOptionItem }) => (
      <Query
        query={GET_OPTION_ITEMS_FOR_DOWNLOAD}
        variables={{ restaurantId }}
        showLoader={true}
      >
        {data => {
          const menuOptions = data.getOptions.options

          const menuOptionItems = menuOptions.flatMap(menuOption =>
            menuOption.optionItems.map(optionItem => ({
              ...optionItem,
              option: { id: menuOption.id, name: menuOption.name },
            }))
          )

          return (
            <ImportTable
              importItemSchema={importItemSchema}
              handleClose={handleClose}
              fields={MenuOptionItemDefinition.fields}
              importData={importData}
              redboxData={menuOptionItems}
              options={{
                optionId: [
                  { label: 'Choose a menu option', value: null },
                  ...menuOptions.map(menuOption => {
                    return {
                      label: menuOption.name,
                      value: menuOption.id,
                    }
                  }),
                ],
              }}
              handleRowSubmission={async row => {
                const importItem = row.importItem

                const cleanData = {
                  ...importItem,
                  // Price should be in pence not pounds etc
                  price: Number((importItem.price * 100).toFixed()),
                  parentId: importItem.optionId,
                  isVegan: importItem.isVegan || false,
                  isVegetarian: importItem.isVegetarian || false,
                  isDairyFree: importItem.isDairyFree || false,
                  isGlutenFree: importItem.isGlutenFree || false,
                  ageRestricted: importItem.isAgeRestricted || false,
                }

                if (row.importItem.id) {
                  const response = await editOptionItem({
                    variables: cleanData,
                  })
                  return response.data.editOptionItem.optionItem
                } else {
                  const response = await addOptionItem({
                    variables: {
                      ...cleanData,
                      restaurantId,
                    },
                  })
                  return response.data.addOptionItem.optionItem
                }
              }}
            />
          )
        }}
      </Query>
    )}
  </MutationProvider>
)

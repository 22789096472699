import { Switch } from '@blueprintjs/core'
import { Formik } from 'formik'
import React from 'react'
import * as PropTypes from 'prop-types'
import { isAtLeastMarketplaceAdmin } from '@stores/userStore'

const MarketplaceAllowPendingOrderAlertSwitch = ({
  updateField,
  allowPendingOrderAlert,
}) => {
  return (
    <Formik initialValues={{ allowPendingOrderAlert }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={'Allow Pending Order Alerts'}
            checked={values.allowPendingOrderAlert}
            onChange={e => {
              handleChange(e)
              updateField({
                allowPendingOrderAlert: e.currentTarget.checked,
              })
            }}
            name="allowPendingOrderAlert"
            disabled={!isAtLeastMarketplaceAdmin()}
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceAllowPendingOrderAlertSwitch.propTypes = {
  allowPendingOrderAlert: PropTypes.bool,
  updateField: PropTypes.func,
}

export default MarketplaceAllowPendingOrderAlertSwitch

import React, { Fragment } from 'react'
import { string } from 'prop-types'
import FetchQuery from '@components/FetchQuery/FetchQuery'
import { Spinner, Icon } from '@blueprintjs/core'
import CopyText from '@components/CopyText/CopyText'

function removeDotsAndQuotes(inputString) {
  // Replace all dots and quotes with an empty string
  return inputString.replace(/[."']/g, '')
}

const DNSTRVerify = ({
  nameUserFriendly,
  name,
  priority = undefined,
  value,
  type,
}) => (
  <tr>
    <td>{type}</td>
    <td>{nameUserFriendly}</td>
    <td>
      <div style={{ width: '70px' }}>
        {priority && <CopyText text={priority} mono />}
      </div>
    </td>
    <td>
      <CopyText text={value} mono />
    </td>
    <td>
      <FetchQuery
        domain="dns.google"
        path={`resolve?type=${type}&name=${name}`}
      >
        {({ loading, error, data }) => {
          if (error) {
            return <Icon icon="error" />
          }
          if (loading) {
            return (
              <div
                style={{
                  display: 'inline-block',
                  marginLeft: 0,
                }}
              >
                <Spinner size={16} />
              </div>
            )
          }
          return (
            <Fragment>
              {data.Answer &&
              data.Answer[0] &&
              (data.Answer[0].data === value ||
                removeDotsAndQuotes(data.Answer[0].data) ===
                  removeDotsAndQuotes(value) ||
                data.Answer[0].data === `${priority} ${value}`) ? (
                <Icon icon="tick-circle" color="#5bb70d" />
              ) : (
                <div
                  style={{
                    display: 'inline-block',
                    marginLeft: 0,
                  }}
                >
                  <Spinner size={16} />
                </div>
              )}
            </Fragment>
          )
        }}
      </FetchQuery>
    </td>
  </tr>
)

DNSTRVerify.propTypes = {
  type: string,
  nameUserFriendly: string,
  name: string,
  value: string,
}

export default DNSTRVerify

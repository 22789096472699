import React from 'react'
import FinancialForm from './FinancialsForm'
import { Formik } from 'formik'
import { Button, Card, Intent, Switch, Tag } from '@blueprintjs/core'
import { func, object } from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'

const PartnerForm = ({ partner, onSubmit, initialValues }) => (
  <Formik onSubmit={onSubmit} initialValues={initialValues}>
    {props => {
      const { handleSubmit, values, setFieldValue } = props
      return (
        <form onSubmit={handleSubmit}>
          <Row gutter={24}>
            <Col sm={12} lg={8}>
              <Card className="bp3-layout-col">
                <FinancialForm {...props} partner={partner} />
                <br />
                <div className="bp-card-footer-actions">
                  <Button text="Save" type="submit" />
                </div>
              </Card>
            </Col>
            <Col sm={12} lg={4}>
              <Card>
                <h5 className="bp3-heading">
                  Paybox Enterprise{' '}
                  {partner.stripeId && (
                    <Tag intent={Intent.SUCCESS}>CONNECTED</Tag>
                  )}
                </h5>
                Allow the partner to process payments through their own stripe
                account.
                <Switch
                  style={{
                    marginTop: '0.5rem',
                  }}
                  checked={values.stripeEnterpriseAllowed}
                  label="Enable"
                  large
                  onChange={e => {
                    setFieldValue(
                      'stripeEnterpriseAllowed',
                      e.currentTarget.checked
                    )
                  }}
                  disabled={
                    partner.stripeEnterpriseAllowed &&
                    partner.stripeId &&
                    partner.stripeConnect.accountType === 'express'
                  }
                />
                <br />
                <div className="bp-card-footer-actions">
                  <Button text="Save" type="submit" />
                </div>
              </Card>
            </Col>
          </Row>
        </form>
      )
    }}
  </Formik>
)

PartnerForm.propTypes = {
  partner: object,
  onSubmit: func.isRequired,
  handleSubmit: func.isRequired,
  initialValues: object,
}

export default PartnerForm

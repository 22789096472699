import React from 'react'
import { FormGroup, InputGroup, Button } from '@blueprintjs/core'
import { func, object, bool } from 'prop-types'
import ls from '@utils/localStorage'

const TwoFactorAuthForm = ({
  handleChange,
  values,
  isSubmitting,
  classes,
  mutation,
}) => {
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        mutation({
          variables: { ...values },
        })
      }}
    >
      <FormGroup>
        <p>
          Please enter the 6 digit code sent to your phone:{' '}
          {ls.get('phoneNumber')}
        </p>
        <InputGroup
          placeholder={'Code'}
          value={values.code}
          onChange={handleChange}
          type="text"
          name="code"
          large="true"
        />
      </FormGroup>
      <div className={classes.buttonContainer}>
        <FormGroup>
          <Button type="submit" disabled={isSubmitting} text="Login" />
        </FormGroup>
      </div>
    </form>
  )
}

TwoFactorAuthForm.propTypes = {
  handleChange: func,
  values: object,
  data: object,
  isSubmitting: bool,
  classes: object,
  mutation: func,
}

export default TwoFactorAuthForm

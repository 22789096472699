export const styles = {
  colorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  color: {
    boxSizing: 'border-box',
    width: '100%',
  },
}

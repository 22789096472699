import {
  Button,
  Card,
  HTMLTable,
  Icon,
  NonIdealState,
  Spinner,
  Intent,
} from '@blueprintjs/core'
import { formatEnum } from '@utils/helpers'
import { find, get } from 'lodash'
import React, { Fragment } from 'react'
import { Query } from 'react-apollo'
import { TemplateTypesEnum } from '../../../../../../server-services/src/shared/types/enums'
import MessageDrawer from './MessageDrawer'

import { MessageTemplateSettings } from './MessageTemplateSettings'
import GET_MARKETPLACE_MESSAGE_TEMPLATES from './queries/getMarketplaceMessageTemplates.query'
import { StringParam, useQueryParam } from 'use-query-params'
import { isPlatformLevelUser } from '@stores/userStore'

const MessageTemplates = ({ match }) => {
  const [viewTemplate, onChangeViewTemplate] = useQueryParam(
    'viewTemplate',
    StringParam
  )
  const { marketplace: marketplaceId } = match.params
  const templatesEnums = Object.keys(TemplateTypesEnum).filter(key =>
    isNaN(Number(key))
  )

  return (
    <div className="bp3-layout-row">
      <Card className="bp3-nopad bp3-layout-col">
        <Query
          query={GET_MARKETPLACE_MESSAGE_TEMPLATES}
          variables={{ marketplaceId: marketplaceId }}
        >
          {({ data, loading, error = null }) => {
            if (loading) {
              return (
                <div style={{ margin: '50px' }}>
                  <NonIdealState
                    icon={<Spinner size={60} />}
                    title="Loading Message Templates"
                    description="Please wait..."
                  />
                </div>
              )
            }

            if (error) {
              return (
                <div style={{ margin: '100px' }}>
                  <NonIdealState
                    icon={<Spinner />}
                    title="Building marketplace..."
                    description="This may take up to 20 minutes"
                  />
                </div>
              )
            }
            const { getMarketplaceMessageTemplates, getMarketplaces } = data
            return (
              <Fragment>
                <HTMLTable bordered={false} interactive={true}>
                  <thead>
                    <tr>
                      <th style={{ width: '20%' }}>Template</th>
                      <th
                        colSpan={2}
                        style={{ width: '80%', textAlign: 'right' }}
                      >
                        {getMarketplaces.regions[0].emailSenderUsername ||
                          'Not configured'}{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {templatesEnums.map((templateKey, key) => {
                      const template = find(
                        getMarketplaceMessageTemplates.messageTemplates,
                        {
                          templateType: templateKey,
                        }
                      )
                      const templateName = formatEnum(templateKey)

                      return (
                        <tr key={key}>
                          <td>
                            {template ? (
                              <a
                                onClick={() =>
                                  onChangeViewTemplate(templateKey)
                                }
                              >
                                {templateName}
                              </a>
                            ) : (
                              templateName
                            )}
                          </td>
                          <td>{get(template, 'emailSubject')}</td>
                          <td style={{ textAlign: 'right' }}>
                            {template ? (
                              <Icon icon={'tick'} intent={Intent.SUCCESS} />
                            ) : (
                              <Button
                                minimal={true}
                                icon={'add'}
                                onClick={() =>
                                  onChangeViewTemplate(templateKey)
                                }
                              />
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </HTMLTable>
                <MessageDrawer
                  isOpen={Boolean(viewTemplate)}
                  marketplaceId={marketplaceId}
                  templateType={viewTemplate}
                  drawerClose={() => {
                    onChangeViewTemplate(undefined)
                  }}
                />
              </Fragment>
            )
          }}
        </Query>
      </Card>
      {isPlatformLevelUser() && (
        <div className="bp3-layout-col-side">
          <MessageTemplateSettings marketplaceId={marketplaceId} />
        </div>
      )}
    </div>
  )
}

export default MessageTemplates

import React, { useEffect } from 'react'
import GET_CUISINES from '@components/Platform/queries/getCuisines.query'
import { get } from 'lodash'
import AddCategoryModal from './AddCategoryModal'
import { Search, Pager } from '@components/Toolbar'
import FilterRow from '@components/FilterRow/FilterRow'
import { MarketplaceFilter } from '@components/Toolbar'
import {
  HTMLTable,
  Card,
  ButtonGroup,
  ControlGroup,
  NonIdealState,
  Button,
  Drawer,
} from '@blueprintjs/core'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import { useState } from 'react'
import { Fragment } from 'react'
import Query from '@components/Query/Query'
import GET_CUISINE from '@components/Platform/queries/getCuisine.query'
import { Mutation } from 'react-apollo'
import EDIT_CUISINE from '../mutations/editCuisine.mutation'
import { successToast } from '@utils/toast'
import { editCuisineValidation } from './validation'
import CategoryForm from './CategoryForm'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { ImagesPopover } from './ImagesPopover'
import { MarketplacesPopover } from './MarketplacesPopover'
import { StringParam, useQueryParam } from 'use-query-params'
import { useSearchQueryParam } from '../../Toolbar/Search/useSearchQueryParam'
import { useRoleAwareBusinessFilterQueryParams } from '../../Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'

const DEFAULT_RECORDS = 20
const DEFAULT_PAGINATION_STATE = {
  total: null,
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  navigationDisabled: false,
}

const Categories = ({ marketplacesMap }) => {
  const [categoryId, onChangeCategoryId] = useQueryParam(
    'categoryId',
    StringParam
  )
  const [categoriesPagination, setCategoriesPagination] = useState(
    DEFAULT_PAGINATION_STATE
  )
  const { searchValue: nameContains, resetSearch } = useSearchQueryParam()
  useEffect(() => {
    if (nameContains) {
      setCategoriesPagination(DEFAULT_PAGINATION_STATE)
    }
  }, [nameContains, setCategoriesPagination])

  const {
    marketplaceIds: marketplaceFilter,
    onChangeMarketplaceIds: onChangeMarketplaceFilter,
  } = useRoleAwareBusinessFilterQueryParams()

  const setFilterState = (count, totalCount) => {
    if (categoriesPagination.total !== totalCount) {
      setCategoriesPagination({
        ...categoriesPagination,
        total: totalCount,
      })
    }
    if (categoriesPagination.outcomeLength !== count) {
      setCategoriesPagination({
        ...categoriesPagination,
        outcomeLength: count,
      })
    }
  }

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (
      categoriesPagination.skip + categoriesPagination.first <
      categoriesPagination.total
    ) {
      setCategoriesPagination({
        ...categoriesPagination,
        skip: limitNext(
          categoriesPagination.skip,
          categoriesPagination.first,
          limit
        ),
        first: DEFAULT_RECORDS,
        last: null,
      })
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < categoriesPagination.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setCategoriesPagination({
      ...categoriesPagination,
      skip: limitPrevious(
        categoriesPagination.skip,
        categoriesPagination.first
      ),
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  const resetFilters = () => {
    resetSearch()
    onChangeMarketplaceFilter([])
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const renderFilterBar = () => (
    <FilterRow>
      <ButtonGroup>
        <ControlGroup>
          <Search autofocus placeholder="Name contains ..." />
          <MarketplaceFilter icon="filter" />
          <Button
            icon="filter-remove"
            onClick={() => resetFilters()}
            disabled={marketplaceFilter.length === 0 && !categoryId}
          />
        </ControlGroup>
      </ButtonGroup>
      <Pager
        goToPrevious={e => goToPrevious(e)}
        goToNext={e => goToNext(e)}
        defaultNmbRecords={DEFAULT_RECORDS}
        skip={categoriesPagination.skip}
        total={categoriesPagination.total}
        outcomeLength={categoriesPagination.outcomeLength}
        totalCount={categoriesPagination.total}
        dataName="Categories"
      />
    </FilterRow>
  )

  return (
    <div className="bp3-table-frame">
      {renderFilterBar()}
      <DebouncedQuery
        query={GET_CUISINES}
        loaderTitle={'Loading Categories'}
        variables={{
          nameContains,
          marketplaceIds: marketplaceFilter,
          ...categoriesPagination,
        }}
      >
        {({ getCuisines: { cuisines, count, totalCount } }, refetch) => {
          setFilterState(totalCount, count)
          if (!cuisines || !count || !totalCount) {
            return (
              <NonIdealState
                icon="th-list"
                title="No Categories Found"
                description="There are no categories that meet your search criteria."
                action={
                  <Button
                    onClick={() => resetFilters()}
                    minimal
                    intent="primary"
                  >
                    Clear Filters
                  </Button>
                }
              />
            )
          } else {
            return (
              <Fragment>
                <Drawer
                  isOpen={categoryId}
                  title={`Edit Category`}
                  size={Drawer.SIZE_SMALL}
                  autoFocus
                  canEscapeKeyClose
                  canOutsideClickClose
                  isCloseButtonShown
                  onClose={() => {
                    onChangeCategoryId(undefined)
                  }}
                >
                  <Query
                    query={GET_CUISINE}
                    variables={{
                      id: categoryId,
                    }}
                  >
                    {data => {
                      const category = get(data, 'getCuisines.cuisines[0]')
                      return (
                        <Mutation
                          mutation={EDIT_CUISINE}
                          onError={defaultErrorHandler}
                          onCompleted={({ editCuisine: { cuisine } }) => {
                            successToast(`Successfully Edited ${cuisine.name}`)
                            onChangeCategoryId(undefined)
                            refetch()
                          }}
                        >
                          {editCuisine => (
                            <CategoryForm
                              categoryId={categoryId}
                              closeDrawer={() => onChangeCategoryId(undefined)}
                              refetch={refetch}
                              initialName={category.name}
                              initialEmoji={category.emoji}
                              // the initial image is the string stored from the S3 bucket
                              // not the graphql object
                              initialImage={category.imageSrc}
                              onSubmit={values => {
                                editCuisine({
                                  variables: {
                                    id: categoryId,
                                    name: values.name,
                                    emoji: values.emoji,
                                    image: values.image,
                                  },
                                })
                              }}
                              validationSchema={editCuisineValidation}
                            />
                          )}
                        </Mutation>
                      )
                    }}
                  </Query>
                </Drawer>
                <div className="bp3-table-container bp3-scrollable">
                  <Card className={'bp3-nopad'}>
                    <HTMLTable bordered={false} interactive={true}>
                      <thead>
                        <tr>
                          <th />
                          <th>Name</th>
                          <th>Marketplaces</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cuisines.map(category => (
                          <tr key={category.id}>
                            <td
                              style={{
                                width: 20,
                                textAlign: 'center',
                              }}
                            >
                              {category.emoji}
                            </td>
                            <td
                              onClick={() => {
                                onChangeCategoryId(category.id)
                              }}
                            >
                              <a>
                                <ImagesPopover category={category} />
                              </a>
                            </td>
                            <td>
                              <MarketplacesPopover
                                marketplaces={get(
                                  marketplacesMap,
                                  `${category.id}`,
                                  []
                                )}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </HTMLTable>
                  </Card>
                </div>
              </Fragment>
            )
          }
        }}
      </DebouncedQuery>
      <AddCategoryModal />
    </div>
  )
}

export default Categories

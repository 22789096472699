import { Button, Popover, Position } from '@blueprintjs/core'
import { DatePicker } from '@blueprintjs/datetime'
import {
  add,
  differenceInDays,
  endOfDay,
  format,
  min,
  addDays,
  subDays,
} from 'date-fns'
import { isFuture } from 'date-fns'
import React, { Fragment } from 'react'
import DatePickerButton from './DatePickerButton'
import { YEARLESS_FORMAT } from '../../../utils/datetime'

const CustomRangeSelector = ({
  startOfRangeDate,
  startOfRangeDateToDisplay,
  endOfRangeDate,
  endOfRangeDateToDisplay,
  onChangeRange,
  maxCustomRangeInterval,
  minimal = false,
  disabled = false,
}) => (
  <Fragment>
    {
      // startOfRange selector
    }
    <Button
      rightIcon="caret-left"
      disabled={disabled}
      minimal={minimal}
      onClick={() => {
        onChangeRange({
          start: subDays(startOfRangeDate, 1),
          end: subDays(endOfRangeDate, 1),
        })
      }}
    />
    <Popover position={Position.BOTTOM_LEFT} boundary="window">
      <DatePickerButton minimal={minimal} disabled={disabled}>
        {format(startOfRangeDateToDisplay, YEARLESS_FORMAT)}
      </DatePickerButton>
      <DatePicker
        value={startOfRangeDate}
        maxDate={endOfDay(new Date())}
        minimal={minimal}
        highlightCurrentDay
        onChange={(selectedDate, isUserChange) => {
          if (selectedDate && isUserChange) {
            // move startOfRangeDate to start of selectedDate and endOfRangeDate by the same amount of days
            onChangeRange({
              start: selectedDate,
              end: addDays(
                endOfRangeDate,
                differenceInDays(selectedDate, startOfRangeDate)
              ),
            })
          }
        }}
      />
    </Popover>

    <Button disabled={true} minimal={minimal}>
      to
    </Button>

    {
      // endOfRange selector
    }
    <Popover position={Position.BOTTOM_LEFT} boundary="window">
      <DatePickerButton minimal={minimal} disabled={disabled}>
        {format(endOfRangeDateToDisplay, YEARLESS_FORMAT)}
      </DatePickerButton>
      <DatePicker
        value={endOfRangeDate}
        minDate={endOfDay(startOfRangeDate)}
        highlightCurrentDay
        maxDate={min([
          add(endOfDay(startOfRangeDate), maxCustomRangeInterval),
          endOfDay(new Date()),
        ])}
        minimal={minimal}
        onChange={(selectedDate, isUserChange) => {
          if (selectedDate && isUserChange) {
            onChangeRange({
              start: startOfRangeDate,
              end: endOfDay(selectedDate),
            })
          }
        }}
      />
    </Popover>

    <Button
      rightIcon="caret-right"
      minimal={minimal}
      onClick={() => {
        onChangeRange({
          start: addDays(startOfRangeDate, 1),
          end: addDays(endOfRangeDate, 1),
        })
      }}
      disabled={disabled || isFuture(add(startOfRangeDate, { days: 1 }))}
    />
  </Fragment>
)

export default CustomRangeSelector

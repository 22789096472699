import React, { Fragment, useState } from 'react'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { SEND_TEST_NOTIFICATION } from '@components/Platform/TestNotification/mutations/sendTestNotification.mutation'
import MarketplaceSelect from '@components/MarketplaceSelect/MarketplaceSelect'
import {
  FormGroup,
  Button,
  HTMLSelect,
  Spinner,
  TagInput,
  RadioGroup,
  Radio,
} from '@blueprintjs/core'
import EmailEditor from '@components/EmailEditor/EmailEditor'
import { TemplateTypesEnum } from '../../../../../../server-services/src/shared/types/enums'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import startCase from 'lodash/startCase'
import { groupBy } from 'lodash'
import { successToast, errorToast } from '@utils/toast'

const TestNotification = () => {
  const [loading, setLoading] = useState(false)
  if (loading) return <Spinner />

  return (
    <Mutation
      mutation={SEND_TEST_NOTIFICATION}
      onCompleted={({ sendTestNotification: { messageDeliveryStatuses } }) => {
        setLoading(false)

        Object.entries(groupBy(messageDeliveryStatuses, 'status')).map(
          ([status, groupedStatuses]) => {
            const success = status === 'SUCCESSFUL'
            const customerIds = groupedStatuses
              .map(({ customerId }) => customerId)
              .join()

            if (!customerIds.length) {
              return errorToast(`Failed to send email`)
            }

            success
              ? successToast(`Email for ${customerIds} sent`)
              : errorToast(`Email for ${customerIds} failed to send`)
          }
        )
      }}
      onError={error => {
        setLoading(false)
        defaultErrorHandler(error)
      }}
    >
      {sendTestNotification => (
        <Formik
          initialValues={{
            marketplaceId: '',
            emailAddresses: [],
            email: {
              type: 'TEMPLATE',
              templateType: 'REGISTRATION',
            },
            emailSubject: '',
            emailContent: [],
            emailPreviewText: '',
          }}
          onSubmit={variables => {
            sendTestNotification({
              variables: {
                ...variables,
                email: {
                  ...variables.email,
                  subject: variables.emailSubject,
                  emailContent: variables.emailContent,
                  previewText: variables.emailPreviewText,
                },
                emailAddresses: variables.emailAddresses,
              },
            })
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue, ...rest }) => (
            <form onSubmit={e => e.preventDefault()}>
              <FormGroup label="Marketplace">
                <MarketplaceSelect
                  marketplaceId={values.marketplaceId}
                  onChange={value => setFieldValue('marketplaceId', value)}
                />
              </FormGroup>
              {!values.marketplaceId ? null : (
                <Fragment>
                  <FormGroup label="Email Addresses">
                    <TagInput
                      values={values.emailAddresses}
                      separator=" "
                      onChange={emailAddresses =>
                        setFieldValue('emailAddresses', emailAddresses)
                      }
                      addOnBlur
                      leftIcon="envelope"
                    />
                  </FormGroup>

                  <FormGroup>
                    <RadioGroup
                      onChange={handleChange}
                      selectedValue={values.email.type}
                      name="email.type"
                    >
                      <Radio label="Use existing template" value="TEMPLATE" />
                      <Radio label="Create a new Email" value="RAW" />
                    </RadioGroup>
                  </FormGroup>
                  {values.email.type === 'RAW' ? (
                    <EmailEditor
                      {...rest}
                      marketplaceId={values.marketplaceId}
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  ) : (
                    <Fragment>
                      <FormGroup
                        label="Template type"
                        helperText="Template will be populated with dummy data"
                      >
                        <HTMLSelect
                          options={Object.keys(TemplateTypesEnum)
                            .filter(key => isNaN(Number(key)))
                            .map(value => ({
                              value,
                              label: startCase(value.toLowerCase()),
                            }))}
                          value={values.email.templateType}
                          name={'email.templateType'}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Fragment>
                  )}
                </Fragment>
              )}
              <Button
                onClick={handleSubmit}
                disabled={
                  !values.marketplaceId || !values.emailAddresses.length
                }
              >
                Send
              </Button>
            </form>
          )}
        </Formik>
      )}
    </Mutation>
  )
}

export default TestNotification

import React from 'react'
import cx from 'classnames'

const AWS_CDN_URL =
  process.env.AWS_CDN_URL ||
  'https://s3.eu-west-1.amazonaws.com/redbox-gbp-img-staging/'

const RestaurantInfo = ({ classes, order }) => (
  <section className={cx(classes.section, classes.center)}>
    <img
      src={AWS_CDN_URL + order.outlet.marketplace.image}
      className={classes.image}
    />

    <h2 className={classes.title}>{order.outlet.name}</h2>
    <h3 className={classes.title}>{order.outlet.restaurant.name}</h3>
    <p className={classes.text}>Tel: {order.outlet.outletPhone}</p>
    <p className={classes.text}>#{order.orderNumber}</p>
  </section>
)

export default RestaurantInfo

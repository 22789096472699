import React from 'react'
import cx from 'classnames'
import Currency from '@components/Currency/Currency'

const buildItem = (item, amount) => ({ ...item, amount })

const OrderItems = ({ classes, order }) => {
  const orderItems = order.orderItems.reduce((acc, item) => {
    const hasOptions = !!item.optionItems.length
    if (hasOptions) {
      acc = [...acc, buildItem(item, 1)]
      return acc
    }

    const existsWithNoOptions = acc.find(findItem => {
      const hasNoOptions = findItem.optionItems.length === 0
      const exists = findItem.menuItem.id === item.menuItem.id
      return hasNoOptions && exists
    })

    if (existsWithNoOptions) {
      existsWithNoOptions.amount = existsWithNoOptions.amount + 1
    } else {
      acc = [...acc, buildItem(item, 1)]
    }

    return acc
  }, [])

  return (
    <section className={classes.section}>
      {orderItems.map(
        ({
          id,
          menuItem,
          optionItems,
          amount,
          isAddOnItem,
          singleItemNotes,
        }) => (
          <article key={id}>
            <h4 className={cx(classes.title, classes.menuItem)}>
              <span>
                {amount}x {menuItem.name}
              </span>{' '}
              <Currency amount={menuItem.price * amount} />
            </h4>

            {isAddOnItem ? (
              <li className={classes.optionItem}>
                <span>- OFFER</span>
              </li>
            ) : null}

            {!!optionItems.length && (
              <ul className={classes.optionItems}>
                {optionItems.map(optionItem => (
                  <li className={classes.optionItem} key={optionItem.id}>
                    <span>- {optionItem.name}</span>
                    {!!optionItem.price && (
                      <Currency amount={optionItem.price} />
                    )}
                  </li>
                ))}
              </ul>
            )}
            {singleItemNotes ? (
              <li className={classes.optionItem}>
                <span>{singleItemNotes}</span>
              </li>
            ) : null}
          </article>
        )
      )}
    </section>
  )
}

export default OrderItems

import React, { useState } from 'react'
import {
  Intent,
  FormGroup,
  InputGroup,
  Button,
  Classes,
  Popover,
} from '@blueprintjs/core'
import { Formik } from 'formik'
import { Picker } from 'emoji-mart'
import ls from '@utils/localStorage'
import { ImageUploadDirectSingle } from '@components/ImageUpload/ImageUploadDirect'
import { Mutation } from 'react-apollo'
import DELETE_CUISINE from '../mutations/deleteCuisine.mutation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import { successToast } from '@utils/toast'

const CategoryForm = ({
  initialName = '',
  initialEmoji = '',
  initialImage = '',
  onSubmit,
  validationSchema,
  categoryId,
  closeDrawer,
  refetch,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <Formik
      initialValues={{
        name: initialName,
        emoji: initialEmoji,
        image: initialImage,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        setFieldValue,
        setStatus,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={Classes.DIALOG_BODY}>
              <FormGroup
                label="Name"
                labelFor="name"
                helperText={errors.name ? errors.name : ''}
                intent={Intent.DANGER}
              >
                <InputGroup
                  name="name"
                  large
                  value={values.name}
                  onChange={handleChange}
                  autoFocus
                />
              </FormGroup>

              <FormGroup
                label="Emoji"
                labelFor="emoji"
                helperText={errors.emoji ? errors.emoji : ''}
                intent={Intent.DANGER}
              >
                <InputGroup
                  name="emoji"
                  id="emoji"
                  value={values.emoji}
                  onChange={handleChange}
                  rightElement={
                    <Popover
                      minimal={true}
                      isOpen={isPopoverOpen}
                      target={
                        <Button
                          text="Select an emoji"
                          rightIcon="emoji"
                          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        />
                      }
                      content={
                        <Picker
                          theme={ls.get('darkMode') ? 'dark' : 'light'}
                          autoFocus
                          title="Pick an Emoji"
                          emoji=""
                          onClick={emoji => {
                            setFieldValue('emoji', emoji.native, false)
                            setIsPopoverOpen(false)
                          }}
                        />
                      }
                    />
                  }
                />
              </FormGroup>

              <ImageUploadDirectSingle
                imageLabel="Image"
                setFieldValue={setFieldValue}
                values={values}
                helperText="Please use a .png format at 512 x 512 pixels."
                setStatus={setStatus}
                sizeLimit={1000000}
                imageName="image"
                replace={true}
                showImagePickerDialog={false}
                imageType="image/png"
              />
            </div>
            <div className="bp3-drawer-footer-actions">
              <Mutation
                mutation={DELETE_CUISINE}
                variables={{
                  id: categoryId,
                }}
                onError={defaultErrorHandler}
                onCompleted={({ deleteCuisine }) => {
                  successToast(deleteCuisine.message)
                  closeDrawer(null)
                  refetch()
                }}
              >
                {deleteCuisine => (
                  <ConfirmationPopover
                    remove={() => {
                      deleteCuisine({
                        variables: {
                          id: categoryId,
                        },
                      })
                    }}
                    confirmationText="Are you sure you want to delete this category?"
                  >
                    <Button
                      text="Delete"
                      type="button"
                      intent={Intent.DANGER}
                      minimal
                    />
                  </ConfirmationPopover>
                )}
              </Mutation>
              <Button intent={Intent.DEFAULT} type="submit" text="Save" />
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export default CategoryForm

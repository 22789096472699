import gql from 'graphql-tag'

const GET_DPROUTLETS = gql`
  query getDPROutletsByMarketplace(
    $search: String
    $skip: Int
    $first: Int
    $last: Int
    $marketplaceIds: [ID]
    $orderBy: OutletOrderByInput
    $isActive: Boolean
    $isSubsidised: Boolean
    $isDirectPayment: Boolean
  ) {
    getOutlets(
      skip: $skip
      first: $first
      last: $last
      orderBy: $orderBy
      marketplaceIds: $marketplaceIds
      nameContains: $search
      contactNameContains: $search
      contactEmailContains: $search
      restaurantNameContains: $search
      marketplaceNameContains: $search
      isActive: $isActive
      isSubsidised: $isSubsidised
      isDirectPayment: $isDirectPayment
    ) {
      count
      totalCount
      outlets {
        id
        name
        active
        companyLegalName
        companyNumber
        companyType
        bankAccount
        bankSort
        taxReference
        isVATregistered
        vatNumber
        contactName
        contactPhone
        contactEmail
        contactDoB
        contactAddress {
          id
          firstLine
          secondLine
          city
          postcode
          country
        }
        outletAddress {
          id
          firstLine
          secondLine
          city
          postcode
        }
        restaurant {
          id
          name
          active
          isVATregistered
          vatNumber
        }
        marketplace {
          id
          name
          cname
          stripeDirectPayment
          stripeOnboarding
        }
      }
    }
  }
`

export default GET_DPROUTLETS

import React from 'react'
import { Mutation } from 'react-apollo'
import { MenuGroupDefinition } from '@components/Restaurant/Menu/schema/menu-group'
import Query from '@components/Query/Query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { ImportTable } from '../../ImportTable/ImportTable'

import GET_MENU_FOR_DOWNLOAD from '../queries/getMenuForDownload.query'
import { ADD_MENU_ITEM_GROUP } from './mutations/addMenuItemGroup.mutation'
import { EDIT_MENU_ITEM_GROUP } from './mutations/editMenuItemGroup.mutation'
import { importItemSchema } from './schema/menuItemGroupFormSchema'

const MutationProvider = ({ children }) => {
  return (
    <Mutation mutation={EDIT_MENU_ITEM_GROUP} onError={defaultErrorHandler}>
      {editMenuItemGroup => (
        <Mutation mutation={ADD_MENU_ITEM_GROUP} onError={defaultErrorHandler}>
          {addMenuItemGroup =>
            children({ editMenuItemGroup, addMenuItemGroup })
          }
        </Mutation>
      )}
    </Mutation>
  )
}

export const MenuGroupImport = ({ importData, restaurantId, handleClose }) => (
  <MutationProvider>
    {({ editMenuItemGroup, addMenuItemGroup }) => (
      <Query
        query={GET_MENU_FOR_DOWNLOAD}
        variables={{ id: restaurantId }}
        showLoader={true}
      >
        {data => {
          const menuItemGroups =
            data.getRestaurants.restaurants[0].menuItemsGroups
          // TODO we can slim the query to not get the menu items
          return (
            <ImportTable
              importItemSchema={importItemSchema}
              handleClose={handleClose}
              fields={MenuGroupDefinition.fields}
              importData={importData}
              redboxData={menuItemGroups}
              handleRowSubmission={async row => {
                const importItem = row.importItem

                const cleanData = {
                  ...importItem,
                  description: importItem.description || '',
                  fulfillmentMethods: importItem.fulfillmentMethods
                    ? importItem.fulfillmentMethods.split('|')
                    : [],
                }

                if (row.importItem.id) {
                  const response = await editMenuItemGroup({
                    variables: cleanData,
                  })
                  return response.data.editMenuItemsGroup.menuItemsGroup
                } else {
                  const response = await addMenuItemGroup({
                    variables: {
                      ...cleanData,
                      restaurantId,
                    },
                  })
                  return response.data.addMenuItemsGroup.menuItemsGroup
                }
              }}
              options={{
                // TODO this might need to come from marketplace / restaurant????
                fulfillmentMethods: [
                  { id: 'DELIVERY', label: 'Delivery' },
                  { id: 'COLLECTION', label: 'Collection' },
                  { id: 'TABLE', label: 'Table' },
                ],
              }}
            />
          )
        }}
      </Query>
    )}
  </MutationProvider>
)

import React, { Fragment } from 'react'
import { MenuItem, MenuDivider } from '@blueprintjs/core'
import { Mutation } from 'react-apollo'
import { object, array, number } from 'prop-types'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { orderList } from '@utils/orderList'
import { successToast } from '@utils/toast'

const moveItemUp = 'up'
const moveItemDown = 'down'

const MoveUpDown = ({
  mutationToExecute,
  refetchQueries,
  index,
  initialList,
  marketplaceId,
}) => (
  <Fragment>
    <MenuDivider title="Sort" />
    <Mutation
      mutation={mutationToExecute}
      refetchQueries={refetchQueries}
      onError={defaultErrorHandler}
      onCompleted={() => {
        successToast('Position updated')
      }}
    >
      {editMenusOrder => (
        <MenuItem
          disabled={index === 0}
          text="Move Up"
          icon="chevron-up"
          onClick={() => {
            const payload = orderList(initialList, index, moveItemUp)
            editMenusOrder({
              variables: {
                input: payload,
                marketplaceId,
              },
            })
          }}
        />
      )}
    </Mutation>
    <Mutation
      mutation={mutationToExecute}
      refetchQueries={refetchQueries}
      onError={defaultErrorHandler}
      onCompleted={() => {
        successToast('Position updated')
      }}
    >
      {editMenusOrder => (
        <MenuItem
          disabled={index === initialList.length - 1}
          text="Move Down"
          icon="chevron-down"
          onClick={() => {
            const payload = orderList(initialList, index, moveItemDown)
            editMenusOrder({
              variables: {
                input: payload,
                marketplaceId,
              },
            })
          }}
        />
      )}
    </Mutation>
  </Fragment>
)

MoveUpDown.propTypes = {
  mutationToExecute: object,
  refetchQueries: array,
  index: number,
  initialList: array,
}

export default MoveUpDown

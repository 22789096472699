import React from 'react'
import { Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { string, object } from 'prop-types'
import injectSheet from 'react-jss'

import { styles } from './AddOption.modal.styles'

import { modalType } from '@utils/types'
import AddOptionForm from './AddOptionForm'

const AddOptionModal = ({ modalOpen, modalClose, classes, restaurantId }) => (
  <Dialog
    isOpen={modalOpen}
    onClose={modalClose}
    title={'Create New Option'}
    style={{ width: '80%' }}
  >
    <div className={Classes.DIALOG_BODY}>
      <AddOptionForm
        classes={classes}
        restaurantId={restaurantId}
        onClose={modalClose}
      />
    </div>
  </Dialog>
)

AddOptionModal.propTypes = {
  ...modalType,
  optionId: string,
  classes: object,
}

export default injectSheet(styles)(AddOptionModal)

import React, { Fragment } from 'react'
import { Card, Button, Switch } from '@blueprintjs/core'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { isAtLeastMarketplaceUser } from '@stores/userStore'
import EDIT_OUTLET_FEATURE_OR_PROMOTE_STATUS from './mutations/editOutletFeatureOrPromotionStatus.mutation'

const Promote = ({ outlet }) => {
  if (Boolean(outlet) && !isAtLeastMarketplaceUser()) {
    return null
  }

  return (
    <Mutation
      mutation={EDIT_OUTLET_FEATURE_OR_PROMOTE_STATUS}
      onError={defaultErrorHandler}
      onCompleted={() => successToast('Successfully edited outlet promotion')}
    >
      {editPromotionStatus => (
        <Card>
          <h5 className="bp3-heading">Promote</h5>

          <Formik
            initialValues={{
              featured: outlet.featured,
              promote: outlet.promote || false,
            }}
            onSubmit={values =>
              editPromotionStatus({
                variables: {
                  outletId: outlet.id,
                  feature: values.featured,
                  promote: values.promote,
                },
              })
            }
          >
            {({ values, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Switch
                  checked={values.featured}
                  onChange={handleChange}
                  name="featured"
                >
                  Feature on Homepage
                  <br />
                  <span className={'bp3-text-small bp3-text-muted'}>
                    3 outlets chosen at random from featured
                  </span>
                </Switch>

                {outlet.restaurant.outletPromoteOverride && (
                  <Fragment>
                    <br />
                    <Switch
                      checked={values.promote}
                      onChange={handleChange}
                      name="promote"
                    >
                      Promotion Override
                      <br />
                      <span className={'bp3-text-small bp3-text-muted'}>
                        Move the outlet to the top of the outlet list within
                        their opening status section
                      </span>
                    </Switch>
                  </Fragment>
                )}
                <br />
                <div className="bp-card-footer-actions">
                  <Button text="Save" type="submit" />
                </div>
              </form>
            )}
          </Formik>
        </Card>
      )}
    </Mutation>
  )
}

export default Promote

import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import React from 'react'
import { Mutation } from 'react-apollo'
import EDIT_MENU_ITEM from '../mutations/editMenuItem.mutation'
import { poundsToPence } from '@utils/helpers'

const QuickEdit = ({ item }) => {
  return (
    <Mutation
      mutation={EDIT_MENU_ITEM}
      onError={defaultErrorHandler}
      onCompleted={() => {
        successToast('Successfully updated menu item')
      }}
    >
      {editMenuItem => (
        <Formik
          initialValues={{
            id: item.id,
            price: item.price,
          }}
          onSubmit={() => {}}
        >
          {({ values }) => {
            return (
              <CurrencyInput
                minimal
                name="price"
                id="price"
                defaultValue={values.price / 100}
                onUpdate={amount => {
                  if (values.price !== parseInt(poundsToPence(amount)))
                    editMenuItem({
                      variables: {
                        id: values.id,
                        price: parseInt(poundsToPence(amount)),
                      },
                    })
                }}
              />
            )
          }}
        </Formik>
      )}
    </Mutation>
  )
}

export default QuickEdit

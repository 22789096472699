import React from 'react'
import {
  HTMLTable,
  Card
} from '@blueprintjs/core'
import { object, array } from 'prop-types'
import ReviewsTableHead from './ReviewsTableHead'
import ReviewsTableBody from './ReviewsTableBody'

const ReviewsTableData = ({ reviews, queryVars}) => (
  <div className="bp3-table-container bp3-scrollable">
    <Card className="bp3-noPad">
      <HTMLTable bordered={false} interactive={true}>
        <ReviewsTableHead queryVars={queryVars} />
        <ReviewsTableBody reviews={reviews} queryVars={queryVars} />
      </HTMLTable>
    </Card>
  </div>
)

ReviewsTableData.propTypes = {
  classes: object,
  reviews: array,
  queryVars: object
}

export default ReviewsTableData

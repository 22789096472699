import React from 'react'
import { Query as ApolloQuery } from 'react-apollo'

import { Spinner, NonIdealState } from '@blueprintjs/core'
import { bool, string, element } from 'prop-types'

const Query = ({
  children,
  showLoader = true,
  loaderTitle = '',
  loaderSubTitle = 'Please wait...',
  loaderIcon = <Spinner size={60} />,
  ErrorComponent = null,
  ...props
}) => {
  return (
    <ApolloQuery {...props}>
      {({ loading, error, data, refetch }) => {
        if (loading) {
          return showLoader ? (
            loaderTitle ? (
              <NonIdealState
                icon={loaderIcon}
                title={loaderTitle}
                description={loaderSubTitle}
              />
            ) : loaderIcon ? (
              loaderIcon
            ) : (
              <Spinner size={30} value={null} />
            )
          ) : null
        }
        if (error) {
          return ErrorComponent ? <ErrorComponent /> : null
        }
        return children(data, refetch)
      }}
    </ApolloQuery>
  )
}

Query.propTypes = {
  showLoader: bool,
  loaderTitle: string,
  loaderSubTitle: string,
  loaderIcon: element,
  ErrorComponent: element,
}

Query.displayName = 'RedboxQuery'

export default Query

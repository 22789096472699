const selectConfig = () => {
  return {
    apiUrl: process.env.API_URL || 'http://localhost:3002',
    pinpointAuthUrl:
      process.env.PINPOINT_AUTH_URL ||
      'https://pinpoint.staging.redbox.systems/auth',
    marketingAccounts: process.env.MARKETING_ACCOUNTS,
    environmentName: process.env.ENVIRONMENT_NAME,
    environmentDomain: process.env.ENVIRONMENT_DOMAIN || 'test.redbox.systems',
    awsPinpointAccountId: process.env.AWS_PINPOINT_ACCOUNT_ID,
    awsAccountId: process.env.AWS_ACCOUNT_ID || '855720269092',
    environmentDomainUrlshortener:
      process.env.ENVIRONMENT_DOMAIN_URLSHORTENER || 'test.msgs.top',
    midnightReportingMigrationDate:
      process.env.MIDNIGHT_REPORTING_MIGRATION_DATE &&
      new Date(process.env.MIDNIGHT_REPORTING_MIGRATION_DATE),
  }
}

const config = selectConfig()

module.exports = { ...config }

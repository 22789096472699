import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Classes,
  Colors,
  Divider,
  FormGroup,
  InputGroup,
  Popover,
  Position,
} from '@blueprintjs/core'
import { Mutation } from 'react-apollo'
import { BusinessAnalyticsContext } from './BusinessAnalyticsContext'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'
import { successToast, errorToast } from '@utils/toast'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import EDIT_SIGNIFICANT_DATES from './mutations/editSignificantDates'
import GET_RESTAURANT from '../Dashboard/queries/getRestaurant.query'
import { DatePicker } from '@blueprintjs/datetime'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

const URL_DATE_FORMAT = 'YYYYMMDD'

const SignificantDates = ({ businessId }) => {
  const [urlSignificantDates, onChangeUrlSignificantDates] = useQueryParam(
    'significantDates',
    StringParam
  )
  const { significantDates, setSignificantDates, activationDates } = useContext(
    BusinessAnalyticsContext
  )
  const [newDateData, setNewDateData] = useState({
    name: null,
    formattedDate: null,
  })

  const isChecked = formattedDate =>
    urlSignificantDates && urlSignificantDates.includes(formattedDate)

  const handleChange = formattedDate => {
    if (isChecked(formattedDate)) {
      const regex = new RegExp(`-?${formattedDate}`)
      onChangeUrlSignificantDates(urlSignificantDates.replace(regex, ''))
    } else {
      onChangeUrlSignificantDates(
        urlSignificantDates
          ? urlSignificantDates + `-${formattedDate}`
          : formattedDate
      )
    }
  }

  return (
    <ButtonGroup>
      <Popover
        position={Position.BOTTOM_LEFT}
        boundary="window"
        defaultIsOpen={false}
      >
        <Button
          text="Significant Dates"
          rightIcon={'double-caret-vertical'}
          minimal
          icon="timeline-events"
        />
        <PerfectScrollBar>
          <div
            style={{
              padding: '12px',
            }}
          >
            {activationDates.length > 0 &&
              activationDates.map(({ formattedDate, name }) => {
                return (
                  <Checkbox
                    key={formattedDate}
                    checked={isChecked(formattedDate)}
                    onChange={() => handleChange(formattedDate)}
                    label={name}
                  />
                )
              })}
            {activationDates.length > 0 && <Divider />}
            {significantDates.map(({ formattedDate, name }) => (
              <Checkbox
                key={formattedDate}
                checked={isChecked(formattedDate)}
                onChange={() => handleChange(formattedDate)}
                style={{ marginBottom: 0 }}
              >
                <span>
                  {`${name} `}
                  <Mutation
                    mutation={EDIT_SIGNIFICANT_DATES}
                    variables={{
                      businessId,
                      significantDates,
                    }}
                    onCompleted={({
                      editBusinessSignificantDates: { significantDates },
                    }) => {
                      successToast('Significant Dates updated')
                      setSignificantDates(significantDates)
                    }}
                    onError={() => errorToast('Problem deleting date.')}
                    refetchQueries={[
                      {
                        query: GET_RESTAURANT,
                        variables: { id: businessId },
                      },
                    ]}
                  >
                    {deleteRequest => (
                      <ConfirmationPopover
                        remove={() =>
                          deleteRequest({
                            variables: {
                              significantDates: significantDates.filter(
                                ({ name: entryName }) => name !== entryName
                              ),
                            },
                          })
                        }
                        confirmationText={`Are you sure you want to delete this date?`}
                      >
                        <Button
                          text=""
                          color={Colors.GRAY2}
                          type="button"
                          minimal={true}
                          icon="small-cross"
                        />
                      </ConfirmationPopover>
                    )}
                  </Mutation>
                </span>
              </Checkbox>
            ))}
            <Mutation
              mutation={EDIT_SIGNIFICANT_DATES}
              variables={{
                businessId,
                significantDates,
              }}
              onCompleted={({
                editBusinessSignificantDates: { significantDates },
              }) => {
                successToast('Significant Dates updated')
                setSignificantDates(significantDates)
              }}
              onError={() => errorToast('Problem adding date.')}
              refetchQueries={[
                {
                  query: GET_RESTAURANT,
                  variables: { id: businessId },
                },
              ]}
            >
              {editRequest => (
                <Popover
                  content={
                    <Card interactive style={{ marginBottom: 0 }}>
                      <h3>Add new significant date</h3>
                      <FormGroup label="Name" labelFor="text-input">
                        <InputGroup
                          id="text-input"
                          placeholder="New Year's Eve"
                          onChange={e =>
                            setNewDateData({
                              ...newDateData,
                              name: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <DatePicker
                        highlightCurrentDay
                        onChange={selectedDate => {
                          setNewDateData({
                            ...newDateData,
                            formattedDate:
                              moment(selectedDate).format(URL_DATE_FORMAT),
                          })
                        }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                        }}
                      >
                        <Button
                          icon="add"
                          text="Add"
                          minimal
                          className={Classes.POPOVER_DISMISS}
                          intent="primary"
                          disabled={
                            !newDateData.name || !newDateData.formattedDate
                          }
                          onClick={e => {
                            e.preventDefault()
                            setSignificantDates(significantDates)
                            editRequest({
                              variables: {
                                significantDates: [
                                  ...significantDates,
                                  newDateData,
                                ],
                              },
                            })
                            setNewDateData({
                              name: null,
                              formattedDate: null,
                            })
                          }}
                        />
                      </div>
                    </Card>
                  }
                  target={
                    <Button
                      text="Add New"
                      type="button"
                      minimal={true}
                      icon="plus"
                    />
                  }
                />
              )}
            </Mutation>
          </div>
        </PerfectScrollBar>
      </Popover>
    </ButtonGroup>
  )
}

export default SignificantDates

export const styles = {
  iosNotifications: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    width: 360,
    position: 'relative',
    transformStyle: 'preserve-3d',
    perspective: '800px',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    listStyleType: 'none',
  },
  iosNotificationItem: {
    position: 'relative',
    width: '100%',
    padding: '0.75rem',
    background: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '15px',
    boxShadow:
      '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    backdropFilter: 'saturate(130%) blur(20px)',
    transition: '0.6s var(--ease-out-cubic)',
  },
  iosNotificationItemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iosNotificationItemTitle: {
    display: 'flex',
    '& span': {
      fontWeight: 'bold',
      marginright: 3,
    },
    '& .bp3-popover-wrapper': {
      marginRight: 5,
    },
  },
}

import React from 'react'
import { Mutation } from 'react-apollo'
import { MenuDefinition } from '@components/Restaurant/Menu/schema/menu'
import Query from '@components/Query/Query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { ImportTable } from '../../ImportTable/ImportTable'

import GET_MENU_FOR_DOWNLOAD from '../queries/getMenuForDownload.query'
import { ADD_MENU_ITEM } from './mutations/addMenuItem.mutation'
import { EDIT_MENU_ITEM } from './mutations/editMenuItem.mutation'
import { importItemSchema } from './schema/menuImportFormSchema'

const MutationProvider = ({ children }) => {
  return (
    <Mutation mutation={EDIT_MENU_ITEM} onError={defaultErrorHandler}>
      {editMenuItem => (
        <Mutation mutation={ADD_MENU_ITEM} onError={defaultErrorHandler}>
          {addMenuItem => children({ editMenuItem, addMenuItem })}
        </Mutation>
      )}
    </Mutation>
  )
}

const normalizePrice = price => {
  if (!price) {
    return 0
  }
  return Number((price * 100).toFixed())
}

export const MenuItemImport = ({ importData, restaurantId, handleClose }) => (
  <MutationProvider>
    {({ editMenuItem, addMenuItem }) => (
      <Query
        query={GET_MENU_FOR_DOWNLOAD}
        variables={{ id: restaurantId }}
        showLoader={true}
      >
        {data => {
          // hack: sometimes theres just no data when closing the drawer and the app crashes
          if (
            !data ||
            !data.getRestaurants ||
            !data.getRestaurants.restaurants ||
            !data.getRestaurants.restaurants[0] ||
            !data.getRestaurants.restaurants[0].menuItemsGroups
          ) {
            return null
          }

          const menuItemsGroups =
            data.getRestaurants.restaurants[0].menuItemsGroups

          const menuOptions = data.getOptions.options

          const menuItems = menuItemsGroups.flatMap(menuItemsGroup =>
            menuItemsGroup.menuItems.map(menuItem => ({
              ...menuItem,
              group: { id: menuItemsGroup.id, name: menuItemsGroup.name },
            }))
          )

          return (
            <ImportTable
              importItemSchema={importItemSchema}
              handleClose={handleClose}
              fields={MenuDefinition.fields}
              importData={importData}
              redboxData={menuItems}
              options={{
                menuId: [
                  { label: 'Choose a menu group', value: null },
                  ...menuItemsGroups.map(menu => {
                    return {
                      label: menu.name,
                      value: menu.id,
                    }
                  }),
                ],
                optionIds: menuOptions.map(option => {
                  return {
                    label: option.name,
                    id: option.id,
                  }
                }),
              }}
              handleRowSubmission={async row => {
                const importItem = row.importItem

                const cleanData = {
                  ...importItem,
                  // Price should be in pence not pounds etc
                  price: normalizePrice(importItem.price),
                  costPrice: normalizePrice(importItem.costPrice),
                  minimumPurchasePrice: normalizePrice(importItem.minPrice),
                  menuItemGroupIds: [importItem.menuId],
                  optionIds: importItem.optionIds
                    ? importItem.optionIds.split('|')
                    : [],
                  SKU: importItem.SKU ? String(importItem.SKU) : null,
                  UPC: importItem.UPC ? String(importItem.UPC) : null,
                  ageRestricted: importItem.isAgeRestricted,
                  spiceLevel: importItem.spiceLevel
                    ? Number(importItem.spiceLevel)
                    : 0,
                }

                if (row.importItem.id) {
                  const response = await editMenuItem({
                    variables: cleanData,
                  })
                  return response.data.editMenuItem.menuItem
                } else {
                  const response = await addMenuItem({
                    variables: {
                      ...cleanData,
                      restaurantId,
                    },
                  })
                  return response.data.addMenuItem.menuItem
                }
              }}
            />
          )
        }}
      </Query>
    )}
  </MutationProvider>
)

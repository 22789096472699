export const styles = theme => ({
  container: {
    borderLeft: 0,
    flexGrow: 1,
    overflowY: 'auto',
  },
  topDetails: {
    padding: '24px',
  },
  heading: {
    marginTop: 0,
  },
  bottomDetails: {
    marginBottom: 24,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '24px ',
  },
  saveButton: {
    background: `${theme.colors.primaryButtonColor} !important`,
    color: `${theme.colors.primaryButtonText} !important`,
    padding: '0px 10px',
    marginRight: 5,
  },
  price: {
    margin: '20px 0px',
  },
  addButton: {
    padding: '30px 0px',
    color: `${theme.colors.highlight} !important`,

    '& .bp3-icon': {
      color: theme.colors.highlight,
    },

    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  currencyContainer: {
    display: 'flex',
    position: 'relative',
  },
  currency: {
    position: 'absolute',
    zIndex: 3,
    display: 'flex',
    bottom: 6,
    left: 10,
  },
  icon: {
    paddingRight: 10,
    '&:hover': {
      color: theme.colors.highlight,
    },
  },
  iconContainer: {
    display: 'flex',
  },
})

import { Button, Checkbox, Popover, Position, Tag } from '@blueprintjs/core'
import { array, bool, func } from 'prop-types'
import React from 'react'
import injectSheet from 'react-jss'

const FulfilmentFilter = ({
  disabled,
  fulfilmentFilter,
  onChange,
  classes,
}) => {
  const fulfilmentMethods = [
    { name: 'Network Delivery', key: 'NETWORK' },
    { name: 'Delivery', key: 'DELIVERY' },
    { name: 'Collection', key: 'COLLECTION' },
    { name: 'Table Service', key: 'TABLE' },
  ]
  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      boundary="window"
      disabled={disabled}
      content={
        <div className={classes.popup}>
          {fulfilmentMethods.map(({ name, key }) => (
            <Checkbox
              key={key}
              id={key}
              label={name}
              alignIndicator="left"
              onChange={onChange}
              className={classes.checkBox}
              checked={fulfilmentFilter.includes(key)}
            />
          ))}
        </div>
      }
    >
      <Button
        rightIcon={
          fulfilmentFilter.length > 0 ? (
            <Tag intent="primary">{fulfilmentFilter.length}</Tag>
          ) : (
            'double-caret-vertical'
          )
        }
        disabled={disabled}
      >
        Fulfilment
      </Button>
    </Popover>
  )
}

FulfilmentFilter.propTypes = {
  onChange: func,
  fulfilmentFilter: array,
  disabled: bool,
}

const styles = () => ({
  popup: {
    maxHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '15px',
  },
  checkBox: {
    margin: '0px 16px 10px',
  },
})

export default injectSheet(styles)(FulfilmentFilter)

import gql from 'graphql-tag'

const ARCHIVE_MARKETPLACE = gql`
  mutation archiveMarketplace($id: String!) {
    archiveMarketplace(id: $id) {
      message
      marketplace {
        id
        name
      }
    }
  }
`

export default ARCHIVE_MARKETPLACE

import React from 'react'
import { MenuItem, Position } from '@blueprintjs/core'
import { MultiSelect } from '@blueprintjs/select'
import EmojiImageLabel from '@components/EmojiImageLabel'

const TypeaheadMulti = ({
  items,
  selected,
  setSelected,
  placeholder = 'Search...',
  noResults = 'No matches',
  selectedIcon = 'tick',
  unSelectedIcon = 'blank',
  showEmojis = false,
  disabled = false,
  fill = false,
  preferEmoji,
  className,
}) => {
  const isSelected = item => selected.includes(item.id)

  const tagRenderer = item => {
    const foundItem = items.find(({ id }) => id === item)
    if (!foundItem) {
      return null
    }
    const { name, emoji, imageSrc } = foundItem

    return showEmojis ? (
      <EmojiImageLabel
        label={name}
        emoji={emoji}
        imageSrc={imageSrc}
        preferEmoji={preferEmoji}
      />
    ) : (
      name
    )
  }

  /**
   *
   * @param {*} item it's a React node returned by the 'tagRenderer' if using emojis otherwise a simple string
   */
  const tagRemover = item =>
    showEmojis
      ? setSelected(
          selected.filter(
            id => id !== items.find(({ name }) => name === item.props.label).id
          )
        )
      : setSelected(
          selected.filter(
            id => id !== items.find(({ name }) => name === item).id
          )
        )

  const textRenderer = item => {
    const { name, emoji, imageSrc } = item
    return showEmojis ? (
      <EmojiImageLabel
        label={name}
        emoji={emoji}
        imageSrc={imageSrc}
        preferEmoji={preferEmoji}
      />
    ) : (
      name
    )
  }

  const ItemRenderer = (item, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }

    return (
      <MenuItem
        disabled={disabled}
        active={modifiers.active}
        icon={isSelected(item) ? selectedIcon : unSelectedIcon}
        key={item.id}
        label={item.label || ''}
        onClick={handleClick}
        text={textRenderer(item)}
        shouldDismissPopover={false}
      />
    )
  }

  return (
    <MultiSelect
      fill={fill}
      items={items}
      itemRenderer={ItemRenderer}
      itemPredicate={(query, item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      }
      itemsEqual={(a, b) => a.id === b.id}
      onItemSelect={item =>
        isSelected(item)
          ? setSelected(selected.filter(selItem => selItem !== item.id))
          : setSelected([...selected, item.id])
      }
      tagRenderer={tagRenderer}
      tagInputProps={{
        onRemove: tagRemover,
        tagProps: {
          minimal: true,
        },
      }}
      selectedItems={selected}
      popoverProps={{ position: Position.BOTTOM_LEFT, minimal: true }}
      resetOnSelect={true}
      placeholder={placeholder}
      noResults={
        <MenuItem icon="warning-sign" label={noResults} disabled={true} />
      }
      className={className}
    />
  )
}

export default TypeaheadMulti

import React, { useState, Fragment } from 'react'
import { bool, func, string, object } from 'prop-types'
import { Classes, Intent, Button } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Mutation } from 'react-apollo'
import { ARCHIVE_CUSTOMER } from './mutations/archiveCustomer.query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'

const ArchiveCustomerModal = ({
  isModalOpen,
  customerId,
  closeModal,
  history,
}) => {
  const [isMutating, setIsMutating] = useState(false)
  return (
    <Dialog title="Archive Customer" isOpen={isModalOpen} onClose={closeModal}>
      <Mutation
        mutation={ARCHIVE_CUSTOMER}
        onError={(...args) => {
          setIsMutating(false)
          defaultErrorHandler(...args)
        }}
        onCompleted={() => {
          successToast('Customer successfully archived')
          history.push('/customers')
        }}
      >
        {archiveCustomer => (
          <Fragment>
            <div className={Classes.DIALOG_BODY}>
              <p>
                The customer will no longer be able to log in, and their data
                will no longer be visible within management.
              </p>
              <p>This action cannot be reverted.</p>
              <p>Are you sure you want to archive the customer?</p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button minimal onClick={closeModal} disabled={isMutating}>
                  Cancel
                </Button>
                <Button
                  loading={isMutating}
                  intent={Intent.DANGER}
                  type="submit"
                  onClick={() => {
                    setIsMutating(true)
                    archiveCustomer({
                      variables: {
                        customerId,
                      },
                    })
                  }}
                >
                  Archive
                </Button>
              </div>
            </div>
          </Fragment>
        )}
      </Mutation>
    </Dialog>
  )
}

ArchiveCustomerModal.propTypes = {
  isModalOpen: bool.isRequired,
  customerId: string.isRequired,
  closeModal: func.isRequired,
  history: object,
}
export default ArchiveCustomerModal

import * as yup from 'yup'

export const outletDeliveryValidation = yup.object().shape({
  id: yup.string(),
  pickupNotes: yup.string().nullable(),
  uberDirectUndeliverableAction: yup
    .string()
    .nullable()
    .when('deliveryZone.deliveryZoneType', {
      is: 'UBER_DIRECT',
      then: yup
        .string()
        .min(1, 'Uber Direct Undeliverable Action is required')
        .required('Uber Direct Undeliverable Action is required'),
    }),
  radiusMiles: yup
    .number()
    .nullable()
    .when('deliveryZone.deliveryZoneType', {
      is: 'UBER_DIRECT',
      then: yup
        .number()
        .min(0.1, 'Radius Miles must be greater than 0.09')
        .max(100, 'Radius Miles is too large')
        .required('Radius Miles is required'),
    }),
  deliveryZone: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
      deliveryZoneType: yup.string().required(),
      geoFence: yup
        .object()
        .shape({
          coordinates: yup.array(),
          type: yup.string(),
          center: yup.mixed(),
          zoom: yup.number(),
        })
        .nullable(),
    })
    .nullable(),
  cost: yup
    .string()
    .nullable()
    .when('deliveryZone.deliveryZoneType', {
      is: 'UBER_DIRECT',
      then: yup.string().required('Cost is required'),
      otherwise: yup.string().nullable(),
    }),
})

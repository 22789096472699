import React from 'react'
import ShortPartnerForm from './PartnerShortForm'
import { Formik } from 'formik'
import { Button, Card, H5, Intent, Spinner, Tag } from '@blueprintjs/core'
import { bool, func, object } from 'prop-types'
import { isPlatformLevelUser } from '@stores/userStore'
import PartnerAppGoogleKeyJsonUpload from './PartnerAppGoogleKeyJsonUpload'
import { Mutation } from 'react-apollo'
import { successToast } from '@utils/toast'
import { Col, Row } from 'react-flexbox-grid'
import FetchQuery from '@components/FetchQuery/FetchQuery'
import EDIT_GOOGLE_JSON_S3_KEY_PATH from '../mutations/editGoogleJsonS3KeyPath.mutation'
import EDIT_APPLE_KEY_DETAILS from '../mutations/editAppleKeyDetails.mutation'
import config from '@config/config'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import ls from '../../../../utils/localStorage'
import PartnerAppAppleKeyUpload from './PartnerAppAppleKeyUpload'

const environmentDomain = config.environmentDomain

const PartnerForm = ({ partner, onSubmit, initialValues = {} }) => (
  <Row gutter={48}>
    <Col xs={12} lg={8}>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {props => {
          const { handleSubmit, isSubmitting } = props
          return (
            <form onSubmit={handleSubmit}>
              <Card>
                <ShortPartnerForm
                  {...props}
                  editable={isPlatformLevelUser()}
                  disabled={!isPlatformLevelUser()}
                />
                {isPlatformLevelUser() && (
                  <div className="bp-card-footer-actions">
                    <Button text="Save" type="submit" loading={isSubmitting} />
                  </div>
                )}
              </Card>
            </form>
          )
        }}
      </Formik>
    </Col>
    {isPlatformLevelUser() && (
      <FetchQuery
        domain={`app-build.${environmentDomain}`}
        path={`app/auth/management`}
        fetchOptions={{
          method: 'POST',
          headers: { Authorization: `Bearer ${ls.get('jwt')}` },
        }}
      >
        {({ loading, error, data: authManagementAWS }) => {
          if (error) {
            return <div>Error connecting to backend service</div>
          }
          if (loading) {
            return (
              <Col xs={12} lg={4}>
                <Card>
                  <Spinner size={64} />
                </Card>
              </Col>
            )
          }
          return (
            <Col xs={12} lg={4}>
              <Card>
                <H5>
                  Play Store{' '}
                  {process.env.ENVIRONMENT_NAME !== 'PROD' && (
                    <Tag intent={Intent.WARNING} minimal>
                      PRODUCTION ONLY
                    </Tag>
                  )}
                  {partner.googleJsonS3KeyPathDefault && (
                    <Tag intent={Intent.SUCCESS}>UPLOADED</Tag>
                  )}
                </H5>
                <Mutation
                  mutation={EDIT_GOOGLE_JSON_S3_KEY_PATH}
                  onError={defaultErrorHandler}
                  onCompleted={() => successToast('Google Json key submitted')}
                >
                  {editPartner => (
                    <PartnerAppGoogleKeyJsonUpload
                      onSubmit={values =>
                        editPartner({
                          variables: {
                            id: partner.id,
                            googleJsonS3KeyPathDefault:
                              values.googleJsonS3KeyPathDefault,
                          },
                        })
                      }
                      partnerId={partner.id}
                      credentials={authManagementAWS}
                    />
                  )}
                </Mutation>
              </Card>
              <Card>
                <H5>
                  App Store{' '}
                  {process.env.ENVIRONMENT_NAME !== 'PROD' && (
                    <Tag intent={Intent.WARNING} minimal>
                      PRODUCTION ONLY
                    </Tag>
                  )}
                  {partner.appStoreConnectApiKeyPath && (
                    <Tag intent={Intent.SUCCESS}>UPLOADED</Tag>
                  )}
                </H5>
                <Mutation
                  mutation={EDIT_APPLE_KEY_DETAILS}
                  onError={defaultErrorHandler}
                  onCompleted={() => successToast('Apple key submitted')}
                >
                  {editPartner => (
                    <PartnerAppAppleKeyUpload
                      onSubmit={values => {
                        return editPartner({
                          variables: {
                            id: partner.id,
                            appStoreConnectApiKeyId:
                              values.appStoreConnectApiKeyId,
                            appStoreConnectApiIssuerId:
                              values.appStoreConnectApiIssuerId,
                            appStoreConnectApiKeyPath:
                              values.appStoreConnectApiKeyPath,
                            appleTeamIdDefault: values.appleTeamIdDefault,
                          },
                        })
                      }}
                      partnerId={partner.id}
                      credentials={authManagementAWS}
                      initialValues={initialValues}
                    />
                  )}
                </Mutation>
              </Card>
            </Col>
          )
        }}
      </FetchQuery>
    )}
  </Row>
)

PartnerForm.propTypes = {
  partner: object,
  editable: bool,
  onSubmit: func.isRequired,
  handleSubmit: func.isRequired,
  initialValues: object,
}

export default PartnerForm

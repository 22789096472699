import React from 'react'
import { Mutation } from 'react-apollo'
import { MenuOptionDefinition } from '../definitions/MenuOptionDefinition'
import Query from '@components/Query/Query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { ImportTable } from '../../ImportTable/ImportTable'

import { GET_OPTION_ITEMS_FOR_DOWNLOAD } from '../queries/getOptionItemsForDownload.query'
import { EDIT_OPTION } from './mutations/editOption.mutation'
import { ADD_OPTION } from './mutations/addOption.mutation'
import { importItemSchema } from './schema/optionFormSchema'

const MutationProvider = ({ children }) => {
  return (
    <Mutation mutation={EDIT_OPTION} onError={defaultErrorHandler}>
      {editOption => (
        <Mutation mutation={ADD_OPTION} onError={defaultErrorHandler}>
          {addOption => children({ editOption, addOption })}
        </Mutation>
      )}
    </Mutation>
  )
}

export const OptionImport = ({ importData, restaurantId, handleClose }) => (
  <MutationProvider>
    {({ editOption, addOption }) => (
      <Query
        query={GET_OPTION_ITEMS_FOR_DOWNLOAD}
        variables={{ restaurantId }}
        showLoader={true}
      >
        {data => {
          const menuOptions = data.getOptions.options

          return (
            <ImportTable
              importItemSchema={importItemSchema}
              handleClose={handleClose}
              fields={MenuOptionDefinition.fields}
              importData={importData}
              redboxData={menuOptions}
              handleRowSubmission={async row => {
                const importItem = row.importItem
                const cleanData = {
                  ...importItem,
                }

                if (row.importItem.id) {
                  const response = await editOption({
                    variables: cleanData,
                  })
                  return response.data.editOption.option
                } else {
                  const response = await addOption({
                    variables: {
                      ...cleanData,
                      restaurantId,
                    },
                  })
                  return response.data.addOption.option
                }
              }}
            />
          )
        }}
      </Query>
    )}
  </MutationProvider>
)

import React from 'react'
import injectSheet from 'react-jss'
import { bool, node } from 'prop-types'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'

const styles = {
  filterWrapper: {
    flexShrink: 0,
    flexGrow: 0,
  },

  scrollBar: {
    width: '100%',
    height: 'auto',
  },

  filters: {
    '.bp3-dark &': {
      background: '#394B59',
      borderBottom: '1px solid #26323E',
    },
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    background: '#eef0f2',
    borderBottom: '1px solid #CDD3D6',

    '& > *': {
      display: 'inline-flex',
      marginRight: '12px',
      flexShrink: 0,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  filtersMinimal: {
    '.bp3-dark &': {
      background: '#394B59',
      borderBottom: '1px solid #26323E',
    },
    padding: '0',
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    background: 'transparent',

    '& > *': {
      display: 'inline-flex',
      marginRight: '12px',
      flexShrink: 0,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}

const FilterRow = ({ classes, children, minimal = false }) => (
  <div className={classes.filterWrapper}>
    <PerfectScrollBar
      options={{
        className: classes.scrollBar,
        options: { suppressScrollY: true },
      }}
    >
      <div className={minimal ? classes.filtersMinimal : classes.filters}>
        {children}
      </div>
    </PerfectScrollBar>
  </div>
)

FilterRow.propTypes = {
  children: node,
  minimal: bool,
}

const StyledFilterRow = injectSheet(styles)(FilterRow)

export default StyledFilterRow

import gql from 'graphql-tag'

const GET_DPRMARKETPLACE = gql`
  query getDPRMarketplace($marketplaceId: ID!) {
    getMarketplaces(id: $marketplaceId) {
      regions {
        id
        name
        companyType
        companyLegalName
        companyNumber
        taxReference
        contactName
        contactAddress {
          id
          firstLine
          secondLine
          city
          postcode
          country
        }
      }
    }
  }
`

export default GET_DPRMARKETPLACE

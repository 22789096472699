import React, { useContext, useEffect, useState, Fragment } from 'react'
import {
  Card,
  HTMLTable,
  Drawer,
  Button,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import {
  canPerformAction,
  canView,
  isAtLeastOutletFinancialUser,
} from '@stores/userStore'
import GET_ITEM_SALES from './queries/getItemSales.query'
import Currency from '@components/Currency/Currency'
import { startCase, get } from 'lodash'
import { MarketplaceFilter } from '@components/Toolbar'
import { toast } from '@utils/toast'
import { client } from '@services/client'
import { penceToPounds } from '@utils/helpers'
import FilterRow from '@components/FilterRow/FilterRow'
import { errorToast } from '@utils/toast'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import ItemDetails from '@components/Restaurant/Menu/MenuTree/ItemDetails'
import GET_ITEM_PARENT from './queries/getItemParent'
import { format } from 'date-fns'
import { DATE_FILTER_TYPES } from '@utils/datetime'
import { useDateRangeQueryParams } from '@components/Toolbar/DateRangeFilter/useDateRangeQueryParams'
import DateRangeFilter from '@components/Toolbar/DateRangeFilter/DateRangeFilter'
import { Query } from 'react-apollo'
import {
  DelimitedArrayParam,
  StringParam,
  useQueryParams,
} from 'use-query-params'
import { useRoleAwareBusinessFilterQueryParams } from '../../Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'

const Sales = () => {
  const [
    { outletFilter = [], viewMenuItem = null, restaurantId = null },
    setQueryParams,
  ] = useQueryParams({
    outletFilter: DelimitedArrayParam,
    viewMenuItem: StringParam,
    restaurantId: StringParam,
  })
  const { marketplaceIds: marketplaceFilter } =
    useRoleAwareBusinessFilterQueryParams()
  const { configurePageLayout, dark } = useContext(PageLayoutContext)
  const [downloading, setDownloading] = useState(false)

  const { shiftedStartOfRangeDateTime, shiftedEndOfRangeDateTime } =
    useDateRangeQueryParams()

  useEffect(() => {
    const tabs = []
    if (canView('sales')) {
      tabs.push(
        { to: '/reports/products', name: 'Products' },
        { to: '/reports/sales', name: 'Sales' },
        { to: '/reports/taxes', name: 'Taxes' }
      )
    }

    configurePageLayout({
      tabs,
    })
  }, [configurePageLayout])

  const downloadCSV = async e => {
    e.preventDefault()
    setDownloading(true)

    const csvHeader = [
      'name',
      'restaurant',
      'outlet',
      'marketplace',
      'price',
      'vat',
      'sales',
      'grossTotal',
      'netTotal',
    ]

    let csvData = [
      csvHeader
        .map(column => {
          return startCase(column)
        })
        .join(','),
    ]

    await client
      .query({
        query: GET_ITEM_SALES,
        variables: {
          afterDate: shiftedStartOfRangeDateTime,
          beforeDate: shiftedEndOfRangeDateTime,
          marketplaceIds: marketplaceFilter,
          outletIds: outletFilter,
        },
      })
      .then(data => {
        const salesItems = get(data, 'data.salesItems', {})
        if (salesItems.items && salesItems.items.length) {
          for (const item of salesItems.items) {
            const columnValues = {
              name: `"${get(item, 'menuItem.name', 'unknown')}"`,
              outlet: `"${get(item, 'outlet.name', 'unknown')}"`,
              restaurant: `"${get(item, 'outlet.restaurant.name', 'unknown')}"`,
              marketplace: `"${get(
                item,
                'outlet.marketplace.name',
                'unknown'
              )}"`,
              price: penceToPounds(item.menuItem.price),
              vat: item.menuItem.vatRate,
              sales: item.sales,
              grossTotal: penceToPounds(item.grossTotal),
              netTotal: penceToPounds(item.netTotal),
            }
            csvData.push(
              csvHeader
                .map(column =>
                  columnValues[column] !== undefined ? columnValues[column] : ''
                )
                .join(',')
            )
          }
          const { totalSales = 0, totalGross = 0, totalNet = 0 } = salesItems
          csvData.push(
            [
              'Totals',
              ...new Array(5).fill(''),
              totalSales,
              ...[totalGross, totalNet].map(penceToPounds),
            ].join(',')
          )
        }
      })

    const blob = new Blob([csvData.join('\n')], { type: 'octet/stream' })
    const url = window.URL.createObjectURL(blob)

    // Add temp Link to page and start download.
    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = `sales-${format(
      shiftedStartOfRangeDateTime,
      'yyyyMMdd-HHmm'
    )}-to-${format(shiftedEndOfRangeDateTime, 'yyyyMMdd-HHmm')}.csv`
    a.click()

    window.URL.revokeObjectURL(url)

    setDownloading(false)

    toast({
      message: 'Download complete.',
      intent: 'success',
      icon: 'saved',
    })
  }

  return (
    <Query
      query={GET_ITEM_SALES}
      variables={{
        afterDate: shiftedStartOfRangeDateTime,
        beforeDate: shiftedEndOfRangeDateTime,
        marketplaceIds: marketplaceFilter,
        outletIds: outletFilter,
      }}
    >
      {({ loading, error = null, data, networkStatus }) => (
        <Fragment>
          {error &&
            errorToast(
              networkStatus === 8
                ? 'Cannot connect to server, please retry.'
                : error.message
            )}
          <div className="bp3-table-frame">
            <FilterRow>
              <div>
                <MarketplaceFilter disabled={loading} divider />
                <DateRangeFilter
                  filterTypes={
                    isAtLeastOutletFinancialUser()
                      ? [
                          DATE_FILTER_TYPES.DAY,
                          DATE_FILTER_TYPES.WEEK,
                          DATE_FILTER_TYPES.CUSTOM,
                        ]
                      : [DATE_FILTER_TYPES.DAY]
                  }
                  disabled={
                    loading ||
                    (canPerformAction('filterByMarketplace') &&
                      marketplaceFilter.length === 0)
                  }
                />
              </div>
              <Button
                disabled={
                  loading ||
                  (data.salesItems &&
                    data.salesItems.items &&
                    data.salesItems.items.length === 0)
                }
                loading={downloading}
                icon={'cloud-download'}
                onClick={downloadCSV}
              >
                Download CSV
              </Button>
            </FilterRow>
            {!error && loading ? (
              <NonIdealState
                icon={<Spinner size={60} />}
                title="Loading Sales Report"
                description="Please wait..."
              />
            ) : !error &&
              data.salesItems &&
              data.salesItems.items &&
              data.salesItems.items.length === 0 ? (
              canPerformAction('filterByMarketplace') &&
              marketplaceFilter.length === 0 ? (
                <NonIdealState
                  icon="property"
                  title="Select Marketplace"
                  description="Please select a marketplace to load this report."
                />
              ) : (
                <NonIdealState
                  icon="th-list"
                  title="No Sales Data"
                  description="There is no data for the selected filter."
                />
              )
            ) : (
              <div className="bp3-table-container bp3-scrollable">
                <Card className={'bp3-nopad'}>
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        <th colSpan={6} />
                        <th>Total</th>
                        <th>Gross</th>
                        <th>Net</th>
                      </tr>
                      <tr>
                        <th style={{ width: '200px' }}>Name</th>
                        <th>Restaurant</th>
                        <th>Outlet</th>
                        <th>Marketplace</th>
                        <th style={{ width: '100px' }}>Price</th>
                        <th style={{ width: '100px' }}>VAT</th>
                        <th style={{ width: '100px' }}>
                          {data.salesItems.totalSales}
                        </th>
                        <th style={{ width: '120px' }}>
                          <Currency amount={data.salesItems.totalGross} />
                        </th>
                        <th style={{ width: '120px' }}>
                          <Currency amount={data.salesItems.totalNet} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.salesItems.items.map(item => (
                        <tr key={item.outletMenuItemId}>
                          <td>
                            {canView('restaurants') ? (
                              <a
                                onClick={() => {
                                  setQueryParams({
                                    restaurantId: item.outlet.restaurant.id,
                                    viewMenuItem: item.menuItemId,
                                  })
                                }}
                              >
                                {item.menuItem.name}
                              </a>
                            ) : (
                              item.menuItem.name
                            )}
                          </td>
                          <td>
                            {canView('restaurants') ? (
                              <Link
                                className="bp3-text-overflow-ellipsis"
                                to={`/business/${item.outlet.restaurant.id}`}
                              >
                                {item.outlet.restaurant.name}
                              </Link>
                            ) : (
                              item.outlet.restaurant.name
                            )}
                          </td>
                          <td>
                            <Link
                              className="bp3-text-overflow-ellipsis"
                              to={`/business/${item.outlet.restaurant.id}/outlets/${item.outlet.id}`}
                            >
                              {item.outlet.name}
                            </Link>
                          </td>
                          <td>
                            {canView('marketplaces') ? (
                              <Link
                                className="bp3-text-overflow-ellipsis"
                                to={`/marketplaces/${item.outlet.marketplace.id}`}
                              >
                                {item.outlet.marketplace.name}
                              </Link>
                            ) : (
                              item.outlet.marketplace.name
                            )}
                          </td>
                          <td>
                            <Currency amount={item.menuItem.price} />
                          </td>
                          <td>{item.menuItem.vatRate}%</td>
                          <td>{item.sales}</td>
                          <td>
                            <Currency amount={item.grossTotal} />
                          </td>

                          <td>
                            <Currency amount={item.netTotal} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </HTMLTable>
                </Card>
              </div>
            )}
          </div>
          <Drawer
            isOpen={viewMenuItem}
            onClose={() => {
              setQueryParams({
                viewMenuItem: undefined,
                restaurantId: undefined,
              })
            }}
            title={`Edit Menu Item`}
            autoFocus={true}
            size={window.innerWidth > 1100 ? '1000px' : '90%'}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            className={dark ? 'bp3-dark' : ''}
          >
            <Query
              query={GET_ITEM_PARENT}
              variables={{
                id: viewMenuItem,
              }}
            >
              {({ loading, error = null, data }) => {
                if (error) {
                  return (
                    <NonIdealState
                      icon="list"
                      title="Error"
                      description="There was an error loading item details"
                    />
                  )
                }
                if (loading) {
                  return <Spinner size={64} />
                }

                const menuId = get(
                  data,
                  'getMenuItems.menuItems[0].parentMenus[0].id'
                )

                return viewMenuItem ? (
                  <ItemDetails
                    menuItemId={viewMenuItem}
                    restaurantId={restaurantId}
                    menuId={menuId}
                  />
                ) : null
              }}
            </Query>
          </Drawer>
        </Fragment>
      )}
    </Query>
  )
}

export default Sales

import React, { Fragment, useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Button } from '@blueprintjs/core'
import { string, func, node, bool } from 'prop-types'
import injectSheet from 'react-jss'

export const PRINT_CONTENT_CLASSNAME = 'PrintContent'

const styles = () => ({
  container: {
    display: 'none',

    '@media print': {
      display: 'block',
      visibility: 'visible',
      overflowY: 'visible',
    },
  },
})

const Content = injectSheet(styles)(({ children, classes, printing }) => {
  useEffect(() => {
    if (printing) {
      window.print()
    }
  }, [printing])
  return (
    <div
      // inline styles as we can have multiple print components on the page.
      style={
        printing
          ? {}
          : { display: 'none', visibility: 'hidden', overflowY: 'hidden' }
      }
      className={classes.container}
    >
      {children}
    </div>
  )
})

Content.propTypes = {
  buttonName: string,
  children: node,
  printing: bool,
}

const PrintContent = ({
  children,
  afterPrint,
  printIntent = 'none',
  buttonName,
  minimal = false,
}) => {
  const portalRef = useRef(null)
  const styleRef = useRef(null)
  const [printing, setPrinting] = useState(false)

  const print = () => {
    setPrinting(true)
  }

  const handleAfterPrint = () => {
    afterPrint && afterPrint()
    setPrinting(false)
  }

  const appendPortal = () => {
    portalRef.current = document.createElement('div')
    portalRef.current.classList.add(PRINT_CONTENT_CLASSNAME)
    document.body.appendChild(portalRef.current)
  }

  const appendBodyStyles = () => {
    const css = `@media print {
                  body {
                    visibility: hidden;
                  }
                  body > :not(.${PRINT_CONTENT_CLASSNAME}) {
                    height: 0;
                  }
                }`

    styleRef.current = document.createElement('style')

    document.head.appendChild(styleRef.current)

    styleRef.current.type = 'text/css'
    styleRef.current.appendChild(document.createTextNode(css))
  }

  useEffect(() => {
    window.addEventListener('afterprint', handleAfterPrint)
    appendPortal()
    appendBodyStyles()

    return () => {
      portalRef.current.remove()
      styleRef.current.remove()
      window.removeEventListener('afterprint', handleAfterPrint)
    }
  }, [])

  return (
    <Fragment>
      <Button
        icon="print"
        onClick={print}
        intent={printIntent}
        text={buttonName}
        minimal={minimal}
      />
      {portalRef.current &&
        createPortal(
          <Content printing={printing}>{children}</Content>,
          portalRef.current
        )}
    </Fragment>
  )
}

PrintContent.propTypes = {
  minimal: bool,
  buttonName: string,
  children: node,
  afterPrint: func,
  printIntent: string,
}

export default PrintContent

import React from 'react'
import { Card, H3, Icon, Classes, Intent, Dialog, Tag } from '@blueprintjs/core'
import { Row, Col } from 'react-simple-flex-grid'
import { func } from 'prop-types'
import AddPaymentMethodButton from '@components/Billing/AddBillingPaymentMethodButton'
import GET_MARKETPLACE_BILLING_FEATURES from '../queries/getMarketplaceBillingFeatures.query'
import { first } from 'lodash'
import AddBillingSubscriptionButton from '../AddBillingSubscriptionButton'
import Query from '@components/Query/Query'

const WayboxDialog = ({
  marketplaceId,
  isOpen = false,
  usePortal = true,
  paywall = false,
  onClose = () => {},
}) => (
  <Dialog
    isOpen={isOpen}
    usePortal={usePortal}
    style={{ top: 100, position: 'absolute' }}
    title={paywall ? null : 'Add-On Features'}
    icon={paywall ? null : 'cube'}
    onClose={onClose}
  >
    <div className={Classes.DIALOG_BODY}>
      <Row>
        <Col span={6}>
          <H3>Waybox</H3>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Tag intent="primary">ADD-ON</Tag>
        </Col>
      </Row>

      <Row>
        <Col span="12" className="bp3-running-text">
          <p>
            Tightly integrated, last-mile delivery fleet management solution for
            unparalleled efficiency and customer satisfaction.
          </p>
          <Card style={{ marginBottom: 10, marginTop: 20 }}>
            <strong>Features</strong>
            <p>
              Simplify and automate the management of funds with these advanced
              features:
            </p>
            <ul
              className="bp3-list-unstyled bp3-list"
              style={{ marginTop: 20 }}
            >
              <li>
                <Icon icon="tick-circle" intent="success" />
                {'  '}
                Fleet Management
              </li>
              <li>
                <Icon icon="tick-circle" intent="success" />
                {'  '}
                Tracking
              </li>
              <li>
                <Icon icon="tick-circle" intent="success" />
                {'  '}
                Route Planning / Optimization
              </li>
              <li>
                <Icon icon="tick-circle" intent="success" />
                {'  '}
                Multi-Drop / Stacking
              </li>
            </ul>
          </Card>
          <p className="bp3-text-small">
            Billed monthly based on usage:
            <ul className="bp3-text-muted ">
              <li>Monthly fee applies after trial.</li>
              <li>
                Price per drop depending on plan, plans may have inclusive
                drops.
              </li>
              <li>Pricing excludes VAT.</li>
            </ul>
          </p>
        </Col>
      </Row>
    </div>
    <div className={Classes.DIALOG_FOOTER}>
      <Query
        query={GET_MARKETPLACE_BILLING_FEATURES}
        variables={{ id: marketplaceId }}
      >
        {({ getMarketplaces: { regions } }) => {
          const marketplace = first(regions)
          if (!marketplace) {
            return 'Unable to find marketplace'
          }
          const paymentMethods = marketplace.billing.paymentMethods
          const isPaymentSetup = paymentMethods.length > 0
          const hasSubscription = marketplace.billing.products.some(product => {
            return product.service === 'WAYBOX' && product.subscribed
          })

          return (
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              {/* <AnchorButton
                target="_blank"
                href="https://support.redbox.systems/docs/waybox"
                minimal
                intent={Intent.PRIMARY}
                text="Learn More"
              /> TODO: Make the marketing site.*/}
              {isPaymentSetup ? (
                <AddBillingSubscriptionButton
                  marketplaceId={marketplaceId}
                  icon="cube-add"
                  disabled={hasSubscription}
                  text="Add Waybox"
                  intent={Intent.SUCCESS}
                  product="WAYBOX"
                  onCompleted={() => {
                    onClose()
                  }}
                />
              ) : (
                <AddPaymentMethodButton
                  id={marketplaceId}
                  level="MARKETPLACE"
                  product="WAYBOX"
                />
              )}
            </div>
          )
        }}
      </Query>
    </div>
  </Dialog>
)

WayboxDialog.propTypes = {
  marketplaceId: String,
  isOpen: Boolean,
  usePortal: Boolean,
  paywall: Boolean,
  onClose: func,
}

export default WayboxDialog

import React from 'react'
import { func, bool, array } from 'prop-types'
import Filter from '@components/Toolbar/Filter'

import GET_RESTAURANT_IDS from './queries/getRestaurantIds.query'

export const RestaurantFilter = ({
  disabled,
  restaurantFilter,
  marketplaceFilter,
  onChange,
}) => (
  <Filter
    name="Business"
    disabled={disabled}
    filter={restaurantFilter}
    onChange={onChange}
    query={GET_RESTAURANT_IDS}
    variables={{ marketplaceIds: marketplaceFilter }}
    dataPath="getOutlets.outlets"
    itemPath="restaurant"
  />
)

RestaurantFilter.propTypes = {
  onChange: func,
  restaurantFilter: array,
  disabled: bool,
}

import React from 'react'
import {
  FormGroup,
  InputGroup,
  Classes,
  Button,
  Intent,
  AnchorButton,
} from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Mutation } from 'react-apollo'
import { modalType } from '@utils/types'
import { Formik } from 'formik'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import { toggleModal } from '@utils/cacheHelpers'
import ADD_OUTLET from './mutations/addOutlet.mutation'
import GET_OUTLETS from '@components/Restaurant/Outlets/queries/getOutlets.query'

import { validation } from './validation'
import MarketplaceSelect from '@components/MarketplaceSelect/MarketplaceSelect'

const handleMutation = (data, history) => {
  const { id } = data.outlet
  history.push(`outlets/${id}/details`)
  toggleModal({
    outletModal: false,
  })
}

const OutletModal = ({ modalOpen, modalClose, restaurantId, history }) => (
  <Dialog isOpen={modalOpen} onClose={modalClose} title="Add Outlet">
    <Mutation
      mutation={ADD_OUTLET}
      refetchQueries={[{ query: GET_OUTLETS, variables: { restaurantId } }]}
      onError={defaultErrorHandler}
      onCompleted={({ addOutlet }) => handleMutation(addOutlet, history)}
    >
      {addOutlet => (
        <Formik
          validationSchema={validation}
          validateOnChange={true}
          onSubmit={values => {
            addOutlet({
              variables: {
                ...values,
              },
            })
          }}
          initialValues={{
            name: '',
            restaurantId,
            marketplaceId: null,
          }}
        >
          {({
            errors,
            handleChange,
            values,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={Classes.DIALOG_BODY}>
                <FormGroup
                  label="Outlet Name"
                  labelInfo="(required)"
                  labelFor="name"
                  subLabel="Identifier, usually the first line of address, area or town if you have many outlets."
                  helperText={errors.name ? errors.name : ''}
                  intent={errors.name ? Intent.DANGER : Intent.NONE}
                >
                  <InputGroup
                    name="name"
                    id="name"
                    type="text"
                    placeholder="Main Street"
                    onChange={handleChange}
                    intent={errors.name ? Intent.DANGER : Intent.NONE}
                    value={values.name}
                    large={true}
                  />
                </FormGroup>
                <FormGroup
                  label="Marketplace"
                  labelInfo="(required)"
                  subLabel="You can have outlets in different marketplaces if you own more then one."
                >
                  <MarketplaceSelect
                    marketplaceId={values.marketplaceId}
                    onChange={value => {
                      setFieldValue('marketplaceId', value, false)
                    }}
                  />
                </FormGroup>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <AnchorButton
                    text="What is an Outlet?"
                    href="https://support.redbox.systems/docs/outlets"
                    target="_blank"
                    rel="noopener noreferrer"
                    rightIcon="help"
                    intent={Intent.PRIMARY}
                    minimal
                  />
                  <Button
                    text="Save"
                    intent={Intent.NONE}
                    loading={isSubmitting}
                    onClick={() => handleSubmit()}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </Mutation>
  </Dialog>
)

OutletModal.propTypes = {
  ...modalType,
}

export default OutletModal

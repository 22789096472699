import React, { memo } from 'react'
import Query from '@components/Query/Query'
import { bool, number, object, string } from 'prop-types'
import GET_CURRENCY from '@components/Currency/queries/getCurrency.query'
import { penceToPounds } from '@utils/helpers'
import currencySymbols from '@utils/currencyMap'

const Currency = ({
  amount,
  currency,
  amountInPence = true,
  className,
  style,
}) => {
  // Compute the raw number and whether it's negative
  const numberValue = amountInPence ? penceToPounds(amount || 0) : amount || 0
  const isNegative = numberValue < 0

  const formatCurrency = (
    currencyObj = {
      thousandsSeparator: ',',
      decimalSeparator: '.',
      prefix: '',
      suffix: '',
    }
  ) => {
    const absValue = Math.abs(numberValue)
    const [integerPart, decimalPart] = absValue.toFixed(2).split('.')

    const formattedInteger = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      currencyObj.thousandsSeparator || ','
    )

    return `${formattedInteger}${
      currencyObj.decimalSeparator || '.'
    }${decimalPart}`
  }

  return !currency ? (
    <span className={className} style={style}>
      <Query query={GET_CURRENCY} showLoader={false}>
        {({ getCurrency: { currency: queryCurrency } }) =>
          amount !== undefined
            ? `${isNegative ? '-' : ''}${queryCurrency.prefix}${formatCurrency(
                queryCurrency
              )}${queryCurrency.suffix}`
            : queryCurrency.symbol
        }
      </Query>
    </span>
  ) : (
    <span className={className} style={style}>
      {isNegative ? '-' : ''}
      {currencySymbols[currency.toString().toUpperCase()] || ''}
      {formatCurrency()}
    </span>
  )
}

Currency.propTypes = {
  amount: number,
  currency: string,
  amountInPence: bool,
  className: string,
  style: object,
}

export default memo(Currency)

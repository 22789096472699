import React from 'react'
import first from 'lodash/first'
import { Mutation } from 'react-apollo'
import { string } from 'prop-types'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import Query from '@components/Query/Query'
import { matchType } from '@utils/types'
import { penceToPounds, numberToPence } from '@utils/helpers'

import { successToast } from '@utils/toast'
import GET_PARTNER from './queries/getPartner.query'
import EDIT_PARTNER_FINANCIALS from './mutations/editPartner.mutation'
import PartnerForm from './PartnerForm/PartnerForm'

import { validation } from './validation'
import { isPlatformLevelUser } from '@stores/userStore'
import { NoPermissions } from '@components/UI/Permission/Permission'

const Details = ({ match }) => {
  const partnerId = match.params.partner

  if (!isPlatformLevelUser()) {
    return <NoPermissions />
  }

  return (
    <Query
      query={GET_PARTNER}
      variables={{ id: partnerId }}
      loaderTitle={'Loading Financials'}
    >
      {({ getPartners: { partners } }) => {
        const partner = first(partners)
        if (!partner) {
          return 'Unable to find partner'
        }

        return (
          <Mutation
            mutation={EDIT_PARTNER_FINANCIALS}
            onError={defaultErrorHandler}
            onCompleted={() => successToast('Partner successfully edited')}
          >
            {editPartner => {
              const initialValues = {
                ...partner,
                platformCharge: penceToPounds(partner.platformCharge),
                partnerCommissionCharge: penceToPounds(
                  partner.partnerCommissionCharge || 0
                ),
                partnerCommissionFee: partner.partnerCommissionFee || 0,
                platformTableCharge: penceToPounds(partner.platformTableCharge),
                partnerTableCommissionCharge: penceToPounds(
                  partner.partnerTableCommissionCharge || 0
                ),
                partnerTableCommissionFee:
                  partner.partnerTableCommissionFee || 0,
                partnerVat: partner.partnerVat || 0,
                stripeChargebackCharge: penceToPounds(
                  partner.stripeChargebackCharge || 0
                ),
                stripePayoutCharge: penceToPounds(
                  partner.stripePayoutCharge || 0
                ),
                stripeConnectCharge: penceToPounds(
                  partner.stripeConnectCharge || 0
                ),
                stripeCharge: penceToPounds(partner.stripeCharge || 0),
                stripePresentCharge: penceToPounds(
                  partner.stripePresentCharge || 0
                ),
              }
              initialValues.platform = partner.platform.id
              return (
                <PartnerForm
                  partner={partner}
                  onSubmit={values =>
                    editPartner({
                      variables: {
                        ...values,
                        platformCharge: numberToPence(values.platformCharge),
                        partnerCommissionCharge: numberToPence(
                          values.partnerCommissionCharge
                        ),
                        platformTableCharge: numberToPence(
                          values.platformTableCharge
                        ),
                        partnerTableCommissionCharge: numberToPence(
                          values.partnerTableCommissionCharge
                        ),
                        stripeChargebackCharge: numberToPence(
                          values.stripeChargebackCharge || 0
                        ),
                        stripePayoutCharge: numberToPence(
                          values.stripePayoutCharge || 0
                        ),
                        stripeConnectCharge: numberToPence(
                          values.stripeConnectCharge || 0
                        ),
                        stripeCharge: numberToPence(values.stripeCharge || 0),
                        stripePresentCharge: numberToPence(
                          values.stripePresentCharge || 0
                        ),
                      },
                    })
                  }
                  initialValues={initialValues}
                  validationSchema={validation}
                />
              )
            }}
          </Mutation>
        )
      }}
    </Query>
  )
}

Details.propTypes = {
  partnerId: string,
  match: matchType,
}

export default Details

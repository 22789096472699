import React, { Fragment, useState } from 'react'
import WithStyles from 'react-jss'
import { func, object } from 'prop-types'
import styles from './dateTimePopoverStyles'
import Day from './Day'
import DatePicker from './DatePicker'
import moment from 'moment'
import Dialog from '@components/Dialog/Dialog'

// returns the first fulfilment time from the third fulfilment day (the first 2 are displayed as lists before the picker), falling back to the first fulfilment day
const getInitialPickerDate = fulfilmentTimeBracketsByDay => {
  if (
    !fulfilmentTimeBracketsByDay ||
    Object.keys(fulfilmentTimeBracketsByDay).length === 0
  ) {
    return null
  }
  // eslint-disable-next-line no-unused-vars
  const [today, _tomorrow, dayAfterTomorrow] = Object.values(
    fulfilmentTimeBracketsByDay
  )

  return (
    (dayAfterTomorrow && dayAfterTomorrow.day.toDate()) || today.day.toDate()
  )
}

// takes an array of fulfilment time brackets and groups the "end" times by the start of the day
const groupFulfilmentTimeBracketsByDay = fulfilmentTimeBrackets =>
  fulfilmentTimeBrackets.reduce((acc, fulfilmentTimeBracket) => {
    // map dates to moments
    const fulfilmentTimeBracketMoments = {
      start: fulfilmentTimeBracket.start
        ? moment(fulfilmentTimeBracket.start)
        : null,
      end: moment(fulfilmentTimeBracket.end),
    }
    // find start of day
    const startOfDay = (
      fulfilmentTimeBracketMoments.start || fulfilmentTimeBracketMoments.end
    )
      .clone()
      .startOf('day')
    // group by start of day
    const existingTimesForDay = acc[startOfDay.format()]
      ? acc[startOfDay.format()].times
      : []
    acc[startOfDay.format()] = {
      day: startOfDay,
      times: [...existingTimesForDay, fulfilmentTimeBracketMoments],
    }
    return acc
  }, {})

const DateTimeASAPRow = ({
  classes,
  fulfilmentMethod,
  selectASAP,
  asapTime,
}) => (
  <li className={classes.asapRow} onClick={selectASAP}>
    <span>ASAP</span>
    <span className={classes.subLbl}>
      Estimated {fulfilmentMethod} time{' '}
      {fulfilmentMethod.toUpperCase() === 'DELIVERY' ? `by` : `at`}{' '}
      {moment(asapTime).format('HH:mm')}
    </span>
  </li>
)

const DateTimePopover = ({
  classes,
  fulfilmentTimeBrackets,
  setPreorderTime,
  setASAPTime,
  selectedPreorderTime,
  fulfilmentChosen,
  outlet: { isOnline, isOpen },
  asapTime = null,
  showPopover = false,
  setShowPopover,
}) => {
  const canASAP = isOnline && isOpen

  const fulfilmentTimeBracketsByDay = groupFulfilmentTimeBracketsByDay(
    fulfilmentTimeBrackets
  )
  const [pickerDate, setPickerDate] = useState(
    getInitialPickerDate(fulfilmentTimeBracketsByDay)
  )

  const allFulfilmentTimeBracketsByDay = Object.values(
    fulfilmentTimeBracketsByDay
  )
  const [
    firstFulfilmentDayWithTimeBrackets,
    secondFulfilmentDayWithTimeBrackets,
  ] = allFulfilmentTimeBracketsByDay
  const selectedFulfilmentDayWithTimeBracket =
    allFulfilmentTimeBracketsByDay.find(
      fulfilmentDayWithTimeBrackets =>
        fulfilmentDayWithTimeBrackets.day.valueOf() === pickerDate.valueOf()
    )

  const firstFulfilmentTimeBracket =
    firstFulfilmentDayWithTimeBrackets &&
    firstFulfilmentDayWithTimeBrackets.times[0]

  return firstFulfilmentTimeBracket ? (
    <Dialog
      isOpen={showPopover}
      onClose={() => setShowPopover(false)}
      title="Delivery Windows Preview"
    >
      <div className={classes.scrollableArea}>
        <ul style={{ padding: '0' }}>
          {canASAP && asapTime && (
            <DateTimeASAPRow
              classes={classes}
              fulfilmentMethod={fulfilmentChosen.label}
              selectASAP={() => setASAPTime()}
              asapTime={asapTime}
            />
          )}
          {allFulfilmentTimeBracketsByDay.length > 5 ? (
            // if there are over 5 days of fulfilment times display the first 2 days, and a date picker
            <Fragment>
              <Day
                key={firstFulfilmentDayWithTimeBrackets.day.format(
                  'DD/MM/YYYY'
                )}
                fulfilmentDayWithTimeBrackets={
                  firstFulfilmentDayWithTimeBrackets
                }
                classes={classes}
                setFulfilmentTime={setPreorderTime}
                selectedFulfilmentTime={selectedPreorderTime}
                openByDefault={true}
              />
              {secondFulfilmentDayWithTimeBrackets && (
                <Day
                  key={secondFulfilmentDayWithTimeBrackets.day.format(
                    'DD/MM/YYYY'
                  )}
                  fulfilmentDayWithTimeBrackets={
                    secondFulfilmentDayWithTimeBrackets
                  }
                  classes={classes}
                  setFulfilmentTime={setPreorderTime}
                  selectedFulfilmentTime={selectedPreorderTime}
                />
              )}
              {allFulfilmentTimeBracketsByDay.length > 2 && (
                <DatePicker
                  classes={classes}
                  selectedDate={pickerDate}
                  setSelectedDate={setPickerDate}
                  minDate={allFulfilmentTimeBracketsByDay[0].day}
                  maxDate={
                    allFulfilmentTimeBracketsByDay[
                      allFulfilmentTimeBracketsByDay.length - 1
                    ].day
                  }
                />
              )}
              {selectedFulfilmentDayWithTimeBracket &&
              selectedFulfilmentDayWithTimeBracket.times.length ? (
                <Day
                  key={selectedFulfilmentDayWithTimeBracket.day.format(
                    'DD/MM/YYYY'
                  )}
                  fulfilmentDayWithTimeBrackets={
                    selectedFulfilmentDayWithTimeBracket
                  }
                  classes={classes}
                  setFulfilmentTime={setPreorderTime}
                  selectedFulfilmentTime={selectedPreorderTime}
                  openByDefault={true}
                  displayHeader={false}
                />
              ) : (
                <p className={classes.timeLink}>
                  No time slots available for this date.
                </p>
              )}
            </Fragment>
          ) : (
            allFulfilmentTimeBracketsByDay.map(
              (fulfilmentDayWithTimeBrackets, i) => {
                return (
                  <Day
                    key={fulfilmentDayWithTimeBrackets.day.format('DD/MM/YYYY')}
                    fulfilmentDayWithTimeBrackets={
                      fulfilmentDayWithTimeBrackets
                    }
                    classes={classes}
                    setFulfilmentTime={setPreorderTime}
                    selectedFulfilmentTime={selectedPreorderTime}
                    openByDefault={i === 0}
                  />
                )
              }
            )
          )}
        </ul>
      </div>
    </Dialog>
  ) : null
}

DateTimePopover.propTypes = {
  setFulfilmentTime: func,
  classes: object,
}

export default WithStyles(styles)(DateTimePopover)

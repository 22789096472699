import React, { useEffect, useState } from 'react'
import { Button, FileInput, FormGroup } from '@blueprintjs/core'
import { get } from 'lodash'
import { Mutation } from 'react-apollo'
import { func, string, bool, number } from 'prop-types'

import { errorToast } from '@utils/toast'
import UPLOAD_IMAGES from './Mutations/UploadImages.mutation'
import { ImagePickerDialog } from '@components/Image/ImagePicker'
import { CheckerBackgroundImgPreview } from './CheckerBackgroundImgPreview'
import { isOutletFinancialUser } from '@stores/userStore'

const handleChange = async (
  e,
  setImages = null,
  sizeLimit = 1048576,
  uploadImages = () => {},
  setFieldValue = () => {},
  imageId = null
) => {
  const files = e.target.files
  const base64Strings = await Promise.all(
    Array.from(files).map(async file => {
      const base64str = new Promise(resolve => {
        if (file.size > sizeLimit) {
          errorToast(
            `File is bigger than ${
              sizeLimit / 1000
            }kB. Please choose a smaller image.`
          )
        } else {
          const reader = new FileReader()
          let base64String = ''
          reader.readAsBinaryString(file)
          reader.onload = async () => {
            // attach extension to string
            base64String = await btoa(reader.result).concat(
              file.name.substr(file.name.lastIndexOf('.'))
            )
            resolve(base64String)
          }
          reader.onerror = () => {
            errorToast('An error occurred while uploading image')
          }
        }
      })
      return await base64str
    })
  )

  const uploadedImages = await Promise.all(
    base64Strings.map(async base64Image =>
      uploadImages({
        variables: { images: base64Image },
      })
    )
  )

  setImages(state => [
    ...state,
    ...uploadedImages.map(mutationResponse => ({
      caption: '',
      src: `${mutationResponse.data.uploadImages.images[0]}`,
    })),
  ])
  setFieldValue(imageId, uploadedImages[0].data.uploadImages.images[0])
}

const ImageUploadDirect = ({
  setImages,
  disabled = false,
  labelInfo = '(Max 1MB)',
  sizeLimit,
  helperText = 'Choose images to upload',
}) => {
  return (
    <Mutation mutation={UPLOAD_IMAGES}>
      {uploadImages => (
        <FormGroup
          className="bp3-form-group"
          labelFor="images"
          labelInfo={labelInfo}
          helperText={helperText}
          style={{ width: '100%' }}
        >
          <label
            htmlFor="images"
            className="custom-file-upload-container bp3-button"
          >
            Browse Files...
            <input
              type="file"
              className="upload-input"
              name="images"
              accept="image/*"
              multiple
              onChange={e => {
                handleChange(e, setImages, sizeLimit, uploadImages)
              }}
              disabled={disabled}
            />
          </label>
          <ImagePickerDialog
            setImages={setImages}
            style={{ marginLeft: '15px' }}
          />
        </FormGroup>
      )}
    </Mutation>
  )
}

ImageUploadDirect.propTypes = {
  setImages: func,
  disabled: bool,
  labelInfo: string,
  sizeLimit: number,
}

export const ImageUploadDirectSingle = ({
  imageLabel,
  setFieldValue,
  values,
  helperText,
  labelInfo = '(Max 1MB)',
  setStatus,
  sizeLimit,
  imageName,
  imageType = 'image/*',
  disabled = false,
  replace = false,
  showImagePickerDialog = true,
  optionItemId,
}) => {
  const userIsOutletFinancialUser = isOutletFinancialUser()

  const imageId = imageName || 'image'
  const [image, setImage] = useState(
    get(values, imageId)
      ? [
          typeof get(values, imageId) === 'object'
            ? get(values, imageId).image.src
            : get(values, imageId),
        ]
      : []
  )

  useEffect(() => {
    setImage(
      get(values, imageId)
        ? [
            typeof get(values, imageId) === 'object'
              ? get(values, imageId).image.src
              : get(values, imageId),
          ]
        : []
    )
  }, [values, imageId])

  const handleRemove = (e, setFieldValue, setStatus, imageId) => {
    setFieldValue(imageId, undefined)
    setStatus(imageId, null)
  }

  return (
    <FormGroup
      label={imageLabel}
      labelFor="image"
      labelInfo={labelInfo}
      helperText={helperText}
      style={{ maxWidth: '100%' }}
    >
      {image.length ? (
        <div>
          <CheckerBackgroundImgPreview
            imageSrc={`https://${process.env.ENVIRONMENT_DOMAIN}/${image[0]}`}
          />
          {!userIsOutletFinancialUser && (
            <Button
              style={{ marginBottom: '-6px' }}
              onClick={e => handleRemove(e, setFieldValue, setStatus, imageId)}
              disabled={disabled}
              intent={'danger'}
              minimal={true}
              icon={'trash'}
            >
              Remove Image
            </Button>
          )}
        </div>
      ) : (
        <Mutation mutation={UPLOAD_IMAGES}>
          {uploadImages => (
            <FileInput
              type="file"
              fill
              name={imageId}
              inputProps={{
                accept: imageType,
              }}
              onChange={e =>
                handleChange(
                  e,
                  setImage,
                  sizeLimit,
                  uploadImages,
                  setFieldValue,
                  imageId
                )
              }
              style={{ maxWidth: '250px', display: 'inline-block' }}
              disabled={disabled}
            />
          )}
        </Mutation>
      )}
      {!image.length && showImagePickerDialog ? (
        <ImagePickerDialog
          setImages={val => setFieldValue(imageId, val)}
          replace={replace}
          style={{ marginTop: '4px', marginLeft: '15px' }}
          optionItemId={optionItemId}
        />
      ) : null}
    </FormGroup>
  )
}

export default ImageUploadDirect

import React from 'react'
import { object, func } from 'prop-types'
import injectSheet from 'react-jss'
import ReactSVG from 'react-svg'
import { withFormik } from 'formik'
import { Mutation } from 'react-apollo'
import { Card } from '@blueprintjs/core'
import ls from '@utils/localStorage'
import get from 'lodash/get'
import { errorToast } from '@utils/toast'

import TwoFactorAuthForm from './TwoFactorAuthForm'
import styles from '@components/Login/Login.styles'
import logo from '@assets/logo.svg'
import TWO_FACTOR_CHECK from './mutations/twoFactorCheck.mutation'

const handleLogin = (data, history) => {
  ls.set('jwt', data.token)
  ls.set('permissionCategories', get(data.role, 'permissionCategories'))
  ls.set('role', get(data.role, 'title'))
  ls.set('ownsPartners', data.ownsPartners)
  history.push('/')
}

const TwoFactorAuthWrapper = props => {
  const { classes, history } = props
  const { container, loginContainer, logoContainer, loginForm } = classes

  return (
    <div className={container}>
      <div className={loginContainer}>
        <ReactSVG src={logo} alt={'logo'} className={logoContainer} />
        <Card elevation="2" className={loginForm}>
          <Mutation
            mutation={TWO_FACTOR_CHECK}
            onError={error => {
              const serverErrors = get(error, 'graphQLErrors', [])

              // fallback for errors that weren't returned by the server
              if (!serverErrors.length) {
                return errorToast(error.message)
              }

              // loop through custom server errors displaying error toast
              for (const serverError of serverErrors) {
                errorToast(serverError.message)
              }

              props.setSubmitting(false)
            }}
            onCompleted={({ twoFactorCheck }) => {
              handleLogin(twoFactorCheck, history)
            }}
          >
            {twoFactorCheck => (
              <TwoFactorAuthForm
                classes={classes}
                mutation={twoFactorCheck}
                {...props}
              />
            )}
          </Mutation>
        </Card>
      </div>
    </div>
  )
}

const TwoFactorAuth = withFormik({
  mapPropsToValues: () => ({
    code: '',
  }),
})(TwoFactorAuthWrapper)

TwoFactorAuth.propTypes = {
  classes: object,

  history: object,
  setSubmitting: func,
}

export default injectSheet(styles)(TwoFactorAuth)
export { TwoFactorAuth }

import { Card, Drawer, HTMLTable, NonIdealState } from '@blueprintjs/core'
import moment from 'moment'
import React, { Fragment, useContext, useState } from 'react'
import GET_MESSAGES from './queries/getMessages.query'
import EmailDialog from './EmailDialog'
import { split } from 'lodash'
import { useEffect } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import SendNewMessageToOutlets from './SendNewMessageToOutlets'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'

const Inbox = ({ product, url }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState(null)
  const { configurePageLayout } = useContext(PageLayoutContext)
  const [showNewMessageDrawer, setShowNewMessageDrawer] = useState(false)
  const [selectedMarketplace, setSelectedMarketplace] = useState(null)

  const showMessage = message => {
    setSelectedMessage(message)
    setModalOpen(true)
  }

  useEffect(() => {
    const marketplaceId = split(location.pathname, '/')[3]
    setSelectedMarketplace(marketplaceId)

    const tabs = [
      { to: `/messaging/inbox/${selectedMarketplace}`, name: 'Inbox' },
      { to: `/messaging/sent/${selectedMarketplace}`, name: 'Sent' },
    ]

    configurePageLayout({
      product,
      button: {
        text: 'New Message',
        onClick: () => setShowNewMessageDrawer(true),
      },
      tabs,
    })
  }, [configurePageLayout, product, selectedMarketplace, url])

  return (
    <Fragment>
      <div className="bp3-table-frame">
        {!selectedMarketplace ? (
          <NonIdealState
            icon="list"
            title="No marketplace selected"
            description="Select marketplace to fetch messages."
          />
        ) : (
          <Fragment>
            <DebouncedQuery
              query={GET_MESSAGES}
              variables={{
                marketplaceId: selectedMarketplace,
                messageType: 'INCOMING',
              }}
              loaderTitle={'Loading Messages'}
            >
              {data => {
                if (
                  !data ||
                  !data.getMessages ||
                  !data.getMessages.messages.length
                ) {
                  return (
                    <NonIdealState
                      icon="th-list"
                      title="No Messages Found"
                      description="There are no new messages."
                    />
                  )
                }

                const {
                  getMessages: { messages },
                } = data

                return (
                  <Fragment>
                    {!messages.length ? null : (
                      <div className="bp3-table-container bp3-scrollable">
                        <Card className={'bp3-nopad'}>
                          <HTMLTable bordered={false} interactive={true}>
                            <thead>
                              <tr>
                                <th style={{ minWidth: '150px' }}>Date</th>
                                <th>From</th>
                                <th style={{ width: '70%' }}>Subject</th>
                              </tr>
                            </thead>
                            <tbody>
                              {messages.map(message => (
                                <tr
                                  key={`${message.id}`}
                                  style={
                                    !message.readAt ? { fontWeight: 700 } : null
                                  }
                                >
                                  <td>
                                    <a
                                      onClick={() => {
                                        showMessage(message)
                                      }}
                                    >
                                      {moment(message.date).format(
                                        'HH:mm DD/MM/YY '
                                      )}
                                    </a>
                                  </td>
                                  <td>{message.from}</td>
                                  <td>{message.subject}</td>
                                </tr>
                              ))}
                            </tbody>
                          </HTMLTable>
                        </Card>
                        {selectedMessage && modalOpen && (
                          <EmailDialog
                            selectedMessage={selectedMessage}
                            modalOpen={modalOpen}
                            setModalOpen={setModalOpen}
                            isInbox={true}
                          />
                        )}
                      </div>
                    )}
                  </Fragment>
                )
              }}
            </DebouncedQuery>
            <Drawer
              isOpen={showNewMessageDrawer}
              onClose={() => setShowNewMessageDrawer(false)}
              title="Send New Message"
            >
              <SendNewMessageToOutlets
                setShowNewMessageDrawer={setShowNewMessageDrawer}
              />
            </Drawer>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

export default Inbox

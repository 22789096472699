import React, { useContext } from 'react'
import ADD_CUISINE from '../mutations/addCuisine.mutation'
import Dialog from '@components/Dialog/Dialog'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { PlatformContext } from '@components/Platform/platform-context'
import { addCuisineValidation } from './validation'
import { Formik } from 'formik'
import {
  Button,
  FormGroup,
  InputGroup,
  Intent,
  Classes,
} from '@blueprintjs/core'
import { history } from '@stores/routerStore'

const AddCategoryModal = () => {
  const { addCategoryModalOpen, setAddCategoryModalOpen } =
    useContext(PlatformContext)

  return (
    <Dialog
      title="Add Category"
      isOpen={addCategoryModalOpen}
      onClose={() => setAddCategoryModalOpen(false)}
    >
      <Mutation
        mutation={ADD_CUISINE}
        onError={defaultErrorHandler}
        onCompleted={({
          addCuisine: {
            cuisine: { id, name },
          },
        }) => {
          successToast(`Successfully Added ${name}`)
          setAddCategoryModalOpen(false)
          history.push(`/platform/categories?categoryId=${id}`)
        }}
      >
        {addCuisine => (
          <Formik
            initialValues={{ name: '' }}
            onSubmit={values => {
              addCuisine({ variables: values })
            }}
            validateOnBlur
            validationSchema={addCuisineValidation}
          >
            {({ values, handleChange, handleSubmit, errors }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  style={{
                    padding: '20px',
                    paddingBottom: '10px',
                  }}
                >
                  <FormGroup
                    label="Name"
                    labelFor="name"
                    helperText={errors.name ? errors.name : ''}
                    intent={Intent.DANGER}
                  >
                    <InputGroup
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      autoFocus
                    />
                  </FormGroup>

                  <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button intent={Intent.PRIMARY} type="submit">
                      Add Category
                    </Button>
                  </div>
                </form>
              )
            }}
          </Formik>
        )}
      </Mutation>
    </Dialog>
  )
}

export default AddCategoryModal

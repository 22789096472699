import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { array, string, object } from 'prop-types'
import {
  Divider,
  HTMLTable,
  Popover,
  PopoverInteractionKind,
  Position,
  Tag,
} from '@blueprintjs/core'
import { styles } from './OrderItems.styles'

import Currency from '@components/Currency/Currency'

const OrderItems = ({ orderItems, classes }) => (
  <Fragment>
    <div className={classes.container}>
      <HTMLTable condensed={true}>
        <thead>
          <tr>
            <th>Items</th>
            <th style={{ width: '70%' }}></th>
            <th>VAT</th>
            <th style={{ textAlign: 'right' }}>Gross</th>
          </tr>
        </thead>
        <tbody>
          {orderItems.map(item => (
            <Fragment key={item.menuItem.id}>
              <tr>
                <td>1x</td>
                <td>
                  {item.menuItem.name}{' '}
                  {item.isAddOnItem ? (
                    <Popover
                      interactionKind={PopoverInteractionKind.HOVER}
                      position={Position.TOP_RIGHT}
                      content={
                        <p
                          style={{
                            width: '200px',
                            backgroundColor: 'none',
                            margin: '5px',
                          }}
                        >
                          The customer added this item to their basket at
                          checkout for a discounted price.
                        </p>
                      }
                    >
                      <Tag minimal>ADD-ON</Tag>
                    </Popover>
                  ) : null}
                </td>
                <td style={{ color: '#5c7080' }}>{item.menuItem.vatRate}%</td>
                <td style={{ textAlign: 'right' }}>
                  <Currency amount={item.grossTotal} />
                </td>
              </tr>
              {item.optionItems &&
                item.optionItems.map(option => (
                  <tr key={option.id} className="bp3-text-small">
                    <td></td>
                    <td>{option.name}</td>
                    <td style={{ color: '#5c7080', textAlign: 'left' }}>
                      {option.price > 0 && (
                        <Fragment>{option.vatRate || 0}%</Fragment>
                      )}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {option.price ? <Currency amount={option.price} /> : ''}
                    </td>
                  </tr>
                ))}
              {item.singleItemNotes ? (
                <tr className="bp3-text-small">
                  <td></td>
                  <td style={{ opacity: 0.6, paddingLeft: '100px' }}>
                    <p style={{ padding: '7px 20px' }}>
                      {item.singleItemNotes}
                    </p>
                  </td>
                </tr>
              ) : null}
            </Fragment>
          ))}
        </tbody>
      </HTMLTable>
    </div>
    <Divider />
  </Fragment>
)

OrderItems.propTypes = {
  orderItems: array,
  orderNumber: string,
  classes: object,
}

export default injectSheet(styles)(OrderItems)

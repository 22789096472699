import {
  Button,
  Switch,
  Alert,
  FormGroup,
  HTMLSelect,
  Classes,
  Intent,
} from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import Query from '@components/Query/Query'
import CuisineSelect from '@components/Restaurant/Details/CuisineSelect'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { Fragment } from 'react'
import { Mutation } from 'react-apollo'
import EDIT_ALLOWED_CATEGORIES from './mutations/editAllowedCategories.mutation'
import GET_ALL_CATEGORIES from './queries/getAllCategories.query'

const EMOJI_IMAGE_MAP = {
  Emoji: 'EMOJI',
  Image: 'IMAGE',
}

const AllowedCategories = ({
  marketplaceId,
  initialCategories,
  optInAllowedCategories,
  categoryEmojiOrImage = 'EMOJI',
}) => {
  const [allowedCategories, setAllowedCategories] = useState(initialCategories)
  const [optInSwitch, setOptInSwitch] = useState(optInAllowedCategories)
  const [limitationAlertIsOpen, setLimitationAlertIsOpen] = useState(false)
  const [emojiOrImageAlertIsOpen, setEmojiOrImageAlertIsOpen] = useState(false)
  const [preferEmoji, setPreferEmoji] = useState(
    categoryEmojiOrImage === EMOJI_IMAGE_MAP.Emoji
  )

  return (
    <Mutation
      mutation={EDIT_ALLOWED_CATEGORIES}
      onCompleted={({ editAllowedCategories }) =>
        successToast(editAllowedCategories.message)
      }
      onError={error => {
        defaultErrorHandler(error)
      }}
    >
      {editMarketplace => (
        <Query query={GET_ALL_CATEGORIES}>
          {({ getCuisines: { cuisines } }) => (
            <Fragment>
              <Formik
                initialValues={{
                  allowedTags: initialCategories,
                  optInSwitch: optInAllowedCategories,
                  categoryEmojiOrImage,
                }}
                onSubmit={() => {
                  editMarketplace({
                    variables: {
                      marketplaceId,
                      allowedCategories: allowedCategories,
                      optInAllowedCategories: !!optInSwitch,
                      categoryEmojiOrImage: preferEmoji
                        ? EMOJI_IMAGE_MAP.Emoji
                        : EMOJI_IMAGE_MAP.Image,
                    },
                  })
                }}
              >
                {({ handleSubmit, values, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <FormGroup
                      label="Display Type"
                      labelFor="categoryEmojiOrImage"
                      style={{ marginBottom: '10px' }}
                    >
                      <Alert
                        isOpen={emojiOrImageAlertIsOpen}
                        confirmButtonText="OK"
                        onConfirm={() => {
                          setEmojiOrImageAlertIsOpen(false)
                        }}
                      >
                        <p>
                          NOTE: If any categories don't contain the selected
                          display type a default one will be used
                        </p>
                      </Alert>
                      <HTMLSelect
                        options={Object.keys(EMOJI_IMAGE_MAP).map(key => ({
                          value: EMOJI_IMAGE_MAP[key],
                          label: key,
                        }))}
                        onChange={e => {
                          setFieldValue(
                            'categoryEmojiOrImage',
                            e.currentTarget.value
                          )
                          setPreferEmoji(
                            e.currentTarget.value === EMOJI_IMAGE_MAP.Emoji
                          )
                          setEmojiOrImageAlertIsOpen(!emojiOrImageAlertIsOpen)
                        }}
                        fill
                        value={values.categoryEmojiOrImage}
                      />
                    </FormGroup>

                    <section>
                      <Dialog
                        isOpen={limitationAlertIsOpen}
                        canEscapeKeyCancel
                        canOutsideClickCancel
                        onClose={() => {
                          setLimitationAlertIsOpen(false)
                          setOptInSwitch(!optInSwitch)
                        }}
                      >
                        <div className={Classes.DIALOG_BODY}>
                          {optInSwitch ? (
                            <p>
                              This will limit the available categories for
                              businesses to those you select from the list below
                            </p>
                          ) : (
                            <p>
                              This will allow businesses to pick any category
                            </p>
                          )}
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button
                              minimal
                              intent={Intent.DEFAULT}
                              text="Cancel"
                              onClick={() => {
                                setLimitationAlertIsOpen(false)
                                setOptInSwitch(!optInSwitch)
                              }}
                            />
                            <Button
                              text="OK"
                              onClick={() => {
                                setLimitationAlertIsOpen(false)
                                if (!optInSwitch) {
                                  setFieldValue('allowedTags', [])
                                  setAllowedCategories([])
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Dialog>
                      <Switch
                        label={'Limit Categories'}
                        checked={optInSwitch}
                        onChange={e => {
                          setOptInSwitch(e.currentTarget.checked)
                          setLimitationAlertIsOpen(true)
                        }}
                        name="optInSwitch"
                      />
                    </section>

                    {optInSwitch && (
                      <FormGroup labelFor="allowedTags">
                        <CuisineSelect
                          cuisineList={cuisines}
                          disabled={!optInSwitch}
                          placeholder={
                            optInSwitch
                              ? 'Please select categories'
                              : 'All categories allowed'
                          }
                          selectedCuisines={values.allowedTags}
                          setSelected={cuisinesNew => {
                            setFieldValue('allowedTags', cuisinesNew)
                            setAllowedCategories(cuisinesNew)
                          }}
                          preferEmoji={preferEmoji}
                        />
                      </FormGroup>
                    )}
                    <div className="bp-card-footer-actions">
                      <Button text="Save" type="submit" />
                    </div>
                  </form>
                )}
              </Formik>
            </Fragment>
          )}
        </Query>
      )}
    </Mutation>
  )
}

export default AllowedCategories

import React, { useContext } from 'react'
import Query from '@components/Query/Query'
import GET_APP_BY_ID from './queries/getAppById.query'
import {
  Button,
  HTMLTable,
  Icon,
  Intent,
  NonIdealState,
  Tag,
} from '@blueprintjs/core'
import appPageContext from './appPageContext'
import { Fragment } from 'react'
import { canPerformAction } from '@stores/userStore'
import appleIcon from '@assets/icons/apple.svg'
import androidIcon from '@assets/icons/android.svg'
import ReactSVG from 'react-svg'
import DateTime from '@components/DateTime/DateTime'

export const getIntent = status => {
  switch (status) {
    case 'INITIALISING':
      return Intent.PRIMARY
    case 'BUILDING':
      return Intent.PRIMARY
    case 'FAILED':
      return Intent.DANGER
    case 'COMPLETED':
      return Intent.SUCCESS
    default:
      return Intent.NONE
  }
}

const Apps = ({ id }) => {
  const { setAppModalOpen } = useContext(appPageContext)

  return (
    <Query
      query={GET_APP_BY_ID}
      pollInterval={5 * 1000}
      variables={{ id }}
      loaderTitle={'Loading App'}
    >
      {({ getApp: { app }, error = null }) => {
        const appBuildsReversed = [...app.appBuilds].reverse()
        return (
          <Fragment>
            {error ? (
              <NonIdealState
                icon="list"
                title="Error"
                description="There was an error loading the app"
                action={
                  id && canPerformAction('appBuild') ? (
                    <Button
                      icon={<Icon icon="build" />}
                      onClick={() => setAppModalOpen(true)}
                    >
                      Start Build
                    </Button>
                  ) : null
                }
              />
            ) : (
              <HTMLTable bordered={false} interactive={true}>
                <thead>
                  <tr>
                    <th>Device</th>
                    <th>Destination</th>
                    <th>Version</th>
                    <th>Started</th>
                    <th>Updated</th>
                    <th colSpan={2}>Build Status</th>
                  </tr>
                </thead>
                <tbody>
                  {appBuildsReversed.map((appBuild, appBuildIndex) => {
                    const lastAppBuild =
                      appBuild.appBuildStatuses &&
                      appBuild.appBuildStatuses.length &&
                      appBuild.appBuildStatuses.slice(-1)[0]
                    const appBuildLastStatus =
                      lastAppBuild && lastAppBuild.status
                        ? lastAppBuild.status.toUpperCase()
                        : 'NOT AVAILABLE'
                    return (
                      <tr key={appBuildIndex}>
                        <td>
                          <ReactSVG
                            style={{
                              color:
                                appBuild.device === 'android'
                                  ? '#9fc137'
                                  : '#222222',
                            }}
                            src={
                              appBuild.device === 'android'
                                ? androidIcon
                                : appleIcon
                            }
                          />
                        </td>
                        <td>
                          {appBuild.releaseToAppcenter ? 'Appcenter ' : null}
                          {appBuild.releaseToStore &&
                          appBuild.device === 'android'
                            ? 'Google Play Store '
                            : null}
                          {appBuild.releaseToStore && appBuild.device === 'ios'
                            ? 'Apple Store '
                            : null}
                        </td>
                        <td>
                          <Tag minimal round>
                            {appBuild.version}
                          </Tag>
                        </td>
                        <td>
                          <DateTime dateTime={appBuild.createdAt} />
                        </td>
                        <td>
                          {lastAppBuild &&
                          appBuild.appBuildStatuses.length > 0 ? (
                            <DateTime
                              dateTime={lastAppBuild.createdAt}
                              timeAgo
                            />
                          ) : (
                            ''
                          )}
                        </td>
                        <td>
                          <Tag minimal intent={getIntent(appBuildLastStatus)}>
                            {appBuildLastStatus}
                          </Tag>
                          {appBuildLastStatus === 'FAILED' &&
                          appBuild.releaseToStore &&
                          appBuild.device === 'android' ? (
                            <Fragment>
                              <div>
                                Initial uploads to Play Store fail, manual
                                uploading of the build is required.
                              </div>
                              <div>
                                Click{' '}
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`https://manage.redbox.systems/app-build-artifacts/${appBuild.id}/build-artifacts.zip`}
                                >
                                  here
                                </a>{' '}
                                to get the build
                              </div>
                            </Fragment>
                          ) : null}
                        </td>
                        <td>
                          {lastAppBuild ? lastAppBuild.circleCiBuildNum : null}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </HTMLTable>
            )}
          </Fragment>
        )
      }}
    </Query>
  )
}

export default Apps

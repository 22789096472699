import { Icon } from '@blueprintjs/core'
import React, { useContext } from 'react'
import DragItem from './DragItem'
import GridContext from './GridContext'

function App({ deleteItem, updateCaption }) {
  const { items, moveItem } = useContext(GridContext)
  return (
    <div className="App">
      <div className="image-grid">
        {items.map(item => (
          <DragItem key={item.src} src={item.src} onMoveItem={moveItem}>
            <GridItem forwardedRef key={`grid${item.src}`}>
              <div
                className="image-grid-image"
                style={{
                  backgroundImage: `url(https://${process.env.ENVIRONMENT_DOMAIN}/${item.src})`,
                  height: 150,
                  marginBottom: 10,
                  marginRight: 10,
                }}
              >
                <Icon
                  icon="cross"
                  style={{
                    right: '2px',
                    top: '2px',
                    position: 'absolute',
                    color: '#FF3B4C',
                  }}
                  onClick={_ => deleteItem(item.src)}
                />
                <input
                  type="text"
                  placeholder="Add a caption"
                  style={{
                    position: 'absolute',
                    width: '100%',
                    bottom: '0',
                    opacity: '0.8',
                  }}
                  value={item.caption}
                  onKeyPress={e => {
                    if (e.key === 'Enter') e.preventDefault()
                  }}
                  onChange={e => {
                    updateCaption(item.src, e.target.value)
                  }}
                />
              </div>
            </GridItem>
          </DragItem>
        ))}
      </div>
    </div>
  )
}

export const GridItem = ({ forwardedRef, ...props }) => (
  <div className="image-grid-item-wrapper" ref={forwardedRef} {...props} />
)

export default App

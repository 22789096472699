import React from 'react'
import injectSheet from 'react-jss'
import { Tag, Icon } from '@blueprintjs/core'
import cx from 'classnames'

const styles = () => ({
  container: {
    display: 'flex',
    minHeight: '30px',
    alignItems: 'center',
    padding: '0 8px',
    position: 'relative',

    '&:hover': {
      background: 'rgba(191, 204, 214, 0.4)',

      '& $dragHandle': {
        opacity: 1,
      },
    },
  },
  active: {
    background: '#137CBD',
    color: '#fff',

    '& $leftIcon svg, & $secondaryLabel svg': {
      fill: '#fff',
    },
  },
  hidden: {
    opacity: '0.4',
  },
  leftIcon: {
    marginRight: '7px',
  },
  label: {
    marginRight: '7px',
  },
  dragHandle: {
    opacity: 0,
    cursor: 'move',
    display: 'flex',
    alignItems: 'center',

    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '30px',
  },
  secondaryLabel: {
    marginLeft: 'auto',
  },
})

const Row = ({
  classes,
  className,
  onClick,
  icon,
  iconColor = '#5c7080',
  label,
  soldCount,
  secondaryLabel,
  cursor = 'pointer',
  isOpen = null,
  canDrag,
  active,
  hidden,
}) => (
  <div
    style={{ cursor }}
    className={cx(classes.container, { [classes.active]: active }, className, {
      [classes.hidden]: hidden,
    })}
    onClick={onClick}
  >
    {isOpen !== null && (
      <Icon
        className={classes.leftIcon}
        icon={isOpen ? 'chevron-down' : 'chevron-right'}
        color={iconColor}
      />
    )}

    {icon && (
      <Icon className={classes.leftIcon} icon={icon} color={iconColor} />
    )}

    {label && <span className={classes.label}>{label}</span>}

    {!!soldCount && <Tag intent={'danger'}>{soldCount}</Tag>}

    {canDrag && (
      <Icon
        icon="drag-handle-vertical"
        color="#5c7080"
        className={classes.dragHandle}
      />
    )}

    {secondaryLabel && (
      <span className={classes.secondaryLabel}>{secondaryLabel}</span>
    )}
  </div>
)

export default injectSheet(styles)(Row)

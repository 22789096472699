import React, { useContext } from 'react'
import { Mutation } from 'react-apollo'
import { find } from 'lodash'
import { string, bool, func } from 'prop-types'
import { Drawer } from '@blueprintjs/core'
import { Formik } from 'formik'
import { formatEnum } from '@utils/helpers'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import Query from '@components/Query/Query'
import GET_MARKETPLACE_MESSAGE_TEMPLATES from './queries/getMarketplaceMessageTemplates.query'
import EDIT_MARKETPLACE_MESSAGE_TEMPLATE from './mutations/editMarketplaceMessageTemplate.mutation'
import ADD_MARKETPLACE_MESSAGE_TEMPLATE from './mutations/addMarketplaceMessageTemplate.mutation'
import Form from './Form'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'

const MessageDrawer = ({
  templateType,
  marketplaceId,
  isOpen,
  drawerClose,
}) => {
  const { dark } = useContext(PageLayoutContext)

  return templateType ? (
    <Query
      query={GET_MARKETPLACE_MESSAGE_TEMPLATES}
      variables={{ marketplaceId }}
    >
      {({ getMarketplaceMessageTemplates, getMarketplaces }) => {
        // TODO refactor to a query for just one
        const template = find(getMarketplaceMessageTemplates.messageTemplates, {
          templateType,
        })
        const isEditing = template && template.id
        const templateName = formatEnum(templateType)
        const [marketplace] = getMarketplaces.regions

        return (
          <Drawer
            isOpen={isOpen}
            onClose={drawerClose}
            title={`${templateName} Template` || 'Message Template'}
            autoFocus={true}
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            className={dark ? 'bp3-dark' : ''}
            size={Drawer.SIZE_LARGE}
          >
            {isEditing ? (
              <Mutation
                mutation={EDIT_MARKETPLACE_MESSAGE_TEMPLATE}
                onCompleted={() => {
                  drawerClose()
                  successToast(`Saved ${templateName} Template`)
                }}
                onError={defaultErrorHandler}
              >
                {editMarketplaceMessageTemplate => {
                  return (
                    <Formik
                      onSubmit={values => {
                        editMarketplaceMessageTemplate({
                          variables: {
                            id: values.id,
                            marketplaceId,
                            ...values,
                          },
                        })
                      }}
                      initialValues={{
                        ...template,
                        marketplaceName: marketplace.name,
                        marketplaceIcon: marketplace.faviconImage,
                        marketplaceEmail: `${
                          marketplace.emailSenderUsername
                        }@${marketplace.cname.trim().replace(/^www\./, '')}`,
                      }}
                      validateOnChange={false}
                    >
                      {props => (
                        <Form
                          {...props}
                          templateType={templateType}
                          isEditing={isEditing}
                          marketplaceId={marketplaceId}
                        />
                      )}
                    </Formik>
                  )
                }}
              </Mutation>
            ) : (
              <Mutation
                mutation={ADD_MARKETPLACE_MESSAGE_TEMPLATE}
                onCompleted={() => {
                  drawerClose()
                  successToast(`Created ${templateName} Template`)
                }}
                onError={defaultErrorHandler}
                refetchQueries={[
                  {
                    query: GET_MARKETPLACE_MESSAGE_TEMPLATES,
                    variables: {
                      marketplaceId: marketplaceId,
                    },
                  },
                ]}
              >
                {addMarketplaceMessageTemplate => {
                  return (
                    <Formik
                      onSubmit={values => {
                        addMarketplaceMessageTemplate({
                          variables: {
                            marketplaceId: marketplaceId,
                            templateType: templateType,
                            ...values,
                          },
                        })
                      }}
                      initialValues={{
                        emailSubject: '',
                        emailPreviewText: '',
                        emailContent: [],
                        pushTitle: '',
                        pushBody: '',
                        marketplaceName: marketplace.name,
                        marketplaceIcon: marketplace.faviconImage,
                        marketplaceEmail: `${
                          marketplace.emailSenderUsername
                        }@${marketplace.cname.trim().replace(/^www\./, '')}`,
                        ...template,
                      }}
                      refetchQueries={[
                        {
                          query: GET_MARKETPLACE_MESSAGE_TEMPLATES,
                          variables: {
                            marketplaceId: marketplaceId,
                          },
                        },
                      ]}
                      validateOnChange={false}
                    >
                      {props => (
                        <Form
                          {...props}
                          isEditing={isEditing}
                          templateType={templateType}
                          marketplaceId={marketplaceId}
                        />
                      )}
                    </Formik>
                  )
                }}
              </Mutation>
            )}
          </Drawer>
        )
      }}
    </Query>
  ) : null
}

MessageDrawer.propTypes = {
  isOpen: bool,
  drawerClose: func,
  templateType: string,
  marketplaceId: string,
}

export default MessageDrawer

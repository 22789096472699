import React from 'react'
import {
  ButtonGroup,
  AnchorButton,
  Popover,
  Button,
  Tooltip,
  Position,
  Tag,
} from '@blueprintjs/core'
import moment from 'moment'
import FilterRow from '@components/FilterRow/FilterRow'
import { DatePicker } from '@blueprintjs/datetime'
import Query from '@components/Query/Query'
import GET_OUTLET from './queries/getOutlet.query'
import config from '@config/config'

const ListVisitorsFilterBar = ({
  date,
  setDate,
  buttonHref,
  outletId,
  totalCount,
}) => {
  const maxDate = moment()
    .endOf('day')
    .toDate()

  const minDate = moment()
    .startOf('day')
    .subtract(21, 'days')
    .toDate()

  return (
    <Query query={GET_OUTLET} variables={{ outletId }} showLoader={false}>
      {({ getOutlets: { outlets } }) => {
        const outlet = outlets[0]
        const dateFormatted = date.format('dddd, MMM Do YYYY')
        const mailtoLink = encodeURI(
          [
            'mailto:suport@redbox.systems',
            '?subject=',
            `Track and Trace request for ${dateFormatted}`,
            '&body=',
            `I would like to request track and trace data from ${dateFormatted} for `,
            `${outlet.restaurant.name}, ${outlet.name} (${outletId})`,
            '\n',
            'I understand that this data can only be used for track and trace purposes and will be passed to NHS Test and Trace directly.',
            '\n',
            'Thanks',
          ].join('')
        )
        return (
          <FilterRow>
            <ButtonGroup>
              <Button
                rightIcon="caret-left"
                disabled={date
                  .clone()
                  .subtract(1, 'day')
                  .isBefore(minDate)}
                onClick={() => {
                  const clone = date.clone()
                  clone.subtract(1, 'day')
                  setDate(clone)
                }}
              />
              <Popover position={Position.BOTTOM_LEFT} boundary="window">
                <Button rightIcon="double-caret-vertical">
                  {date.format('dddd, MMM Do YYYY')}
                </Button>
                <DatePicker
                  value={date.toDate()}
                  maxDate={maxDate}
                  minDate={minDate}
                  minimal={true}
                  onChange={(selectedDate, isUserChange) => {
                    if (selectedDate && isUserChange) {
                      setDate(moment(selectedDate))
                    }
                  }}
                />
              </Popover>
              <Button
                rightIcon="caret-right"
                disabled={date
                  .clone()
                  .add(1, 'day')
                  .isAfter(maxDate)}
                onClick={() => {
                  const clone = date.clone()
                  clone.add(1, 'day')
                  setDate(clone)
                }}
              />
            </ButtonGroup>
            <ButtonGroup style={{ marginLeft: 'auto' }}>
              <AnchorButton
                href={`${config.apiUrl}/api/outlet/${outletId}/generate-pdf`}
                target="_blank"
                rightIcon="print"
                text={'Print QR Code'}
              />
              <AnchorButton
                href={buttonHref}
                rightIcon="share"
                target="_blank"
                text={'Open'}
              />
              <AnchorButton
                href={mailtoLink}
                rightIcon="help"
                target="_blank"
                text={'Request export'}
              />
              <Tooltip
                content="For data security, we only show the last 20 records."
                position={Position.LEFT}
              >
                <Tag minimal={true} large={true}>
                  {`${totalCount} Visitors`}
                </Tag>
              </Tooltip>
            </ButtonGroup>
          </FilterRow>
        )
      }}
    </Query>
  )
}

export default ListVisitorsFilterBar

import React, { useContext, useEffect, useState } from 'react'
import { matchType } from '@utils/types'
import { object } from 'prop-types'
import Marketplaces from './Marketplaces'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'

const PageLayoutWrapper = ({ product, children, url }) => {
  const { configurePageLayout } = useContext(PageLayoutContext)

  useEffect(() => {
    const tabs = [{ to: '/messaging', name: 'List' }]
    configurePageLayout({
      product,
      tabs,
    })
  }, [configurePageLayout, product, url])

  return children
}

const Messaging = ({ product, match }) => {
  useEffect(() => {
    setUrl(match.url)
  }, [match.url])

  const [url, setUrl] = useState(match.url)

  return (
    <PageLayoutWrapper product={product} url={url}>
      <Marketplaces />
    </PageLayoutWrapper>
  )
}

Messaging.propTypes = {
  match: matchType.isRequired,
  classes: object,
  location: object,
}

export default Messaging

import React from 'react'
import Crumb from '@components/Header/Breadcrumbs/Crumb'
import Query from '@components/Query/Query'
import GET_RESTAURANT_NAME from './queries/getRestaurantName.query'

const isSecondPart = i => i === 1
const isThirdPart = i => i === 2

const RestaurantCrumbs = ({ crumbs }) =>
  crumbs.map((crumb, i) => {
    if (isSecondPart(i)) {
      return (
        <Query
          key={crumb}
          query={GET_RESTAURANT_NAME}
          variables={{ id: crumb }}
          showLoader={false}
        >
          {({ getRestaurants }) => {
            if (!getRestaurants) {
              return null
            }
            const { restaurants } = getRestaurants
            return (
              <Crumb
                key={crumb}
                text={restaurants.length ? restaurants[0].name : crumb}
                link={`/business/${crumb}`}
              />
            )
          }}
        </Query>
      )
    }

    return (
      <Crumb
        key={crumb}
        text={crumb}
        link={isThirdPart(i) ? `/business/${crumbs[1]}/${crumb}` : `/${crumb}`}
      />
    )
  })

RestaurantCrumbs.propTypes = {
  crumbs: (props, propName, componentName) => {
    const crumbs = props[propName]
    if (!Array.isArray(crumbs)) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName} Validation failed. expected array recived ${typeof crumbs}`
      )
    }
    if (crumbs.length !== 3) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName} Validation failed. expected array of length 3`
      )
    }
  },
}

export default RestaurantCrumbs

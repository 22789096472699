import React, { useState } from 'react'
import { Button, Tabs, Tab } from '@blueprintjs/core'
import EmailEditor from '@components/EmailEditor/EmailEditor'
import { styles } from '../../Marketing/Campaigns/Form/InnerForm.styles'
import injectSheet from 'react-jss'
import PushNotificationPreview from '@components/PushNotificationPreview/PushNotificationPreview'

const Form = ({
  handleSubmit,
  handleChange,
  setFieldValue,
  values,
  isEditing,
  templateType,
  marketplaceId,
  classes,
  errors,
  isSubmitting,
  ...rest
}) => {
  const [navBarTab, setNavBarTab] = useState('email')

  return (
    <div>
      <form onSubmit={handleSubmit} className="bp3-drawer-form">
        <div className="bp3-drawer-content">
          <Tabs onChange={setNavBarTab} selectedTabId={navBarTab}>
            <Tab
              id="email"
              title="Email"
              panel={
                <EmailEditor
                  {...rest}
                  marketplaceId={marketplaceId}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  errors={errors}
                />
              }
            />
            {!['REGISTRATION', 'RESET', 'INVITE_TO_DISCOUNT'].includes(
              templateType
            ) && (
              <Tab
                id="push"
                title="Push"
                panel={
                  <PushNotificationPreview
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    classes={classes}
                  />
                }
              />
            )}
          </Tabs>
        </div>
        <div className="bp3-drawer-footer-actions">
          <Button
            disabled={
              (errors.emailContent &&
                errors.emailContent.filter(err => Boolean(err)).length) ||
              isSubmitting
            }
            loading={isSubmitting}
            type="submit"
          >
            {isEditing ? 'Save' : 'Create'}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default injectSheet(styles)(Form)

import {
  ControlGroup,
  Tag,
  Intent,
  HTMLSelect,
  Button,
  FormGroup,
} from '@blueprintjs/core'
import { get } from 'lodash'
import { object, func, number, array, string } from 'prop-types'
import React, { Fragment } from 'react'
import { Query } from 'react-apollo'
import { comparatorMapping } from '../../Marketing/shared/HumanReadableFilters'
import TypeaheadMulti from '../../Typeahead/TypeaheadMulti'
import GET_CUISINES from './queries/getCuisines.query'
import GET_OUTLETS from './queries/getOutlets.query'

const AttributeValueSelector = ({
  selected,
  disabled,
  marketplaceId,
  setFieldValue,
  valuesName,
  values,
}) => {
  switch (selected.value) {
    case 'outlet': {
      //outlet query
      return (
        <Query
          query={GET_OUTLETS}
          variables={{ marketplaceIds: [marketplaceId] }}
          fetchPolicy="no-cache"
        >
          {({ data }) => {
            if (!data || !data.getOutlets) {
              return <Fragment></Fragment>
            }
            const { outlets } = data.getOutlets
            return (
              <div>
                {
                  <TypeaheadMulti
                    items={outlets.map(outlet => ({
                      ...outlet,
                      name: `${outlet.name}, ${outlet.restaurant.name}`,
                    }))}
                    selected={get(values, valuesName)}
                    setSelected={newSelected => {
                      setFieldValue(valuesName, newSelected)
                    }}
                    placeholder={`select ${selected.label}...`}
                    showEmojis
                    disabled={disabled}
                    preferEmoji={false}
                    className={'segmentAttributeFilterSelect'}
                  />
                }
              </div>
            )
          }}
        </Query>
      )
    }
    case 'category': {
      //outlet query
      return (
        <Query
          query={GET_CUISINES}
          variables={{ marketplaceIds: [marketplaceId] }}
        >
          {({ data }) => {
            if (!data || !data.getCuisines) {
              return <Fragment></Fragment>
            }
            const { cuisines } = data.getCuisines

            return (
              <Fragment>
                {
                  <TypeaheadMulti
                    items={cuisines}
                    selected={get(values, valuesName)}
                    setSelected={newSelected => {
                      setFieldValue(valuesName, newSelected)
                    }}
                    placeholder={`select ${selected.label}...`}
                    showEmojis
                    disabled={disabled}
                    preferEmoji={false}
                    className={'segmentAttributeCuisineFilterSelect'}
                  />
                }
              </Fragment>
            )
          }}
        </Query>
      )
    }
    default: {
      return <Fragment></Fragment>
    }
  }
}

export const SegmentAttributeFilter = ({
  filter,
  onChange,
  index,
  arrayHelpers,
  attributes,
  marketplaceId,
  setFieldValue,
  formNamePrefix,
  currentValues,
  errors,
}) => {
  const selected = attributes.find(({ value }) => value === filter.attribute)
  const deprecated = filter.attribute && !selected

  const valueName = `${formNamePrefix}.value`
  const valuesName = `${formNamePrefix}.values`
  const selectedFilter = selected
    ? filter
    : { attribute: '', comparator: '', value: '', values: [] }

  return (
    <Fragment>
      {errors && errors.comparator && (
        <FormGroup
          helperText={errors && errors.comparator}
          intent={Intent.DANGER}
        />
      )}
      <ControlGroup
        vertical={false}
        style={{ borderLeft: '3px solid #1968f6', padding: 4 }}
      >
        {deprecated ? (
          <Tag intent={Intent.DANGER}>
            {filter.attribute} {comparatorMapping[filter.comparator]}{' '}
            {filter.value}
          </Tag>
        ) : (
          <Fragment>
            <HTMLSelect
              name={`${formNamePrefix}.attribute`}
              options={[
                { values: [], label: 'Choose attribute...', key: '0' },
                ...attributes.map(attribute => ({
                  ...attribute,
                  key: attribute.value,
                })),
              ]}
              value={selectedFilter.attribute}
              onChange={e => {
                setFieldValue(valueName, '')
                setFieldValue(valuesName, [])
                onChange(e)
              }}
            />
            {selectedFilter.attribute ? (
              <Fragment>
                <HTMLSelect
                  name={`${formNamePrefix}.comparator`}
                  options={[
                    { value: '', label: 'Select comparison...' },
                    ...selected.comparators,
                  ]}
                  value={selectedFilter.comparator}
                  onChange={onChange}
                />
                <AttributeValueSelector
                  disabled={selectedFilter.comparator === ''}
                  setFieldValue={setFieldValue}
                  marketplaceId={marketplaceId}
                  selected={selected}
                  valuesName={valuesName}
                  values={currentValues}
                />
              </Fragment>
            ) : null}
          </Fragment>
        )}
        <Button
          type="button"
          onClick={() => arrayHelpers.remove(index)}
          icon="cross"
        />
        {deprecated && (
          <p style={{ padding: '4 0 0 12' }}>
            This field is now deprecated, please delete.
          </p>
        )}
      </ControlGroup>
    </Fragment>
  )
}

SegmentAttributeFilter.propTypes = {
  filter: object,
  onChange: func.isRequired,
  index: number.isRequired,
  arrayHelpers: object.isRequired,
  attributes: array.isRequired,
  marketplaceId: string.isRequired,
  setFieldValue: func.isRequired,
  formNamePrefix: string.isRequired,
  currentValues: object,
}

export default SegmentAttributeFilter

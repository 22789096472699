import React from 'react'
import DeliveryZones from './DeliveryZones'
import { matchType } from '@utils/types'
import { isAtLeastOutletUser } from '@stores/userStore'
import { NoPermissions } from '@components/UI/Permission/Permission'

const Fulfilment = ({ match }) =>
  !isAtLeastOutletUser() ? <NoPermissions /> : <DeliveryZones match={match} />

Fulfilment.propTypes = {
  match: matchType,
}
export default Fulfilment

import React from 'react'
import { Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import { modalType } from '@utils/types'
import { successToast } from '@utils/toast'
import { validation } from '@components/TableForm/validation'
import { toggleModal } from '@utils/cacheHelpers'
import TableForm from '@components/TableForm/TableForm'
import ADD_TABLE from '@components/TableModal/mutations/addTable.mutation'
import GET_OUTLET_TABLES from '../Outlet/Tables/queries/getOutletTables.query'

const AddTableModal = ({ modalOpen, modalClose, outletId }) => (
  <Dialog isOpen={modalOpen} onClose={modalClose} title="Add a table">
    <div className={Classes.DIALOG_BODY}>
      <Mutation
        mutation={ADD_TABLE}
        onError={defaultErrorHandler}
        onCompleted={() => {
          successToast('Table successfully added')
          toggleModal({
            addTableModal: false,
          })
        }}
        refetchQueries={[{ query: GET_OUTLET_TABLES, variables: { outletId } }]}
      >
        {addTable => (
          <TableForm
            onSubmit={values =>
              addTable({
                variables: {
                  ...values,
                },
              })
            }
            initialValues={{
              outletId,
              friendlyName: '',
            }}
            validationSchema={validation}
          />
        )}
      </Mutation>
    </div>
  </Dialog>
)

AddTableModal.propTypes = {
  ...modalType,
}

export default AddTableModal

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Card, Elevation, HTMLTable } from '@blueprintjs/core'
import {
  canView,
  isAtLeastMarketplaceOwner,
  isOutletFinancialUser,
} from '@stores/userStore'
import { Row, Col } from 'react-simple-flex-grid'
import 'react-simple-flex-grid/lib/main.css'

import { matchType } from '@utils/types'
import { object } from 'prop-types'

const Dashboard = () => {
  const userIsOutletFinancialUser = isOutletFinancialUser()
  return (
    <Row gutter={24}>
      <Col xs={12} md={6} lg={4}>
        <Card
          elevation={Elevation.ZERO}
          style={{
            height: 200,
            width: 'max-content',
            minWidth: '100%',
          }}
        >
          <Fragment>
            <Row>
              <Col span={6}>
                <h5 className={'bp3-heading'}>Sales</h5>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Row>
              <Col span={12}>
                <HTMLTable className="bp3-html-table-bordered bp3-cell-nopad">
                  <tbody>
                    {canView('sales') && (
                      <Fragment>
                        {' '}
                        <tr>
                          <td>
                            <Link to="/reports/products">Sales by Product</Link>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="/reports/sales">Sales by Outlet</Link>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link to="/reports/taxes">Taxes by Outlet</Link>
                          </td>
                        </tr>
                      </Fragment>
                    )}
                  </tbody>
                </HTMLTable>
              </Col>
            </Row>
          </Fragment>
        </Card>
      </Col>
      {(canView('billing') || canView('payouts')) &&
        !userIsOutletFinancialUser && (
          <Col xs={12} md={6} lg={4}>
            <Card
              elevation={Elevation.ZERO}
              style={{
                height: 200,
                width: 'max-content',
                minWidth: '100%',
              }}
            >
              <Row>
                <Col span={6}>
                  <h5 className={'bp3-heading'}>Payments</h5>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Row>
                <Col span={12}>
                  <HTMLTable className="bp3-html-table-bordered bp3-cell-nopad">
                    <tbody>
                      <Fragment>
                        {canView('billing') && (
                          <tr>
                            <td>
                              <Link to="/reports/invoicing">
                                Invoices by Outlet
                              </Link>
                            </td>
                          </tr>
                        )}
                        {canView('payouts') && (
                          <tr>
                            <td>
                              <Link to="/reports/payments">
                                Payments by Outlet
                              </Link>
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    </tbody>
                  </HTMLTable>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
      {canView('reports') && !userIsOutletFinancialUser && (
        <Col xs={12} md={6} lg={4}>
          <Card
            elevation={Elevation.ZERO}
            style={{
              height: 200,
              width: 'max-content',
              minWidth: '100%',
            }}
          >
            <Row>
              <Col span={6}>
                <h5 className={'bp3-heading'}>Revenue</h5>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Row>
              <Col span={12}>
                <HTMLTable className="bp3-html-table-bordered bp3-cell-nopad">
                  <tbody>
                    <tr>
                      <td>
                        <Link to="/reports/revenue">
                          Revenue by Marketplace
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </HTMLTable>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
      {isAtLeastMarketplaceOwner() && (
        <Col xs={12} md={6} lg={4}>
          <Card
            elevation={Elevation.ZERO}
            style={{
              height: 200,
              width: 'max-content',
              minWidth: '100%',
            }}
          >
            <Row>
              <Col span={6}>
                <h5 className={'bp3-heading'}>Compliance</h5>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Row>
              <Col span={12}>
                <HTMLTable className="bp3-html-table-bordered bp3-cell-nopad">
                  <tbody>
                    <tr>
                      <td>
                        <Link to="/reports/compliance/dac7">
                          Digital Platform Reporting (DAC7)
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </HTMLTable>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
    </Row>
  )
}

Dashboard.propTypes = {
  match: matchType.isRequired,
  classes: object,
  location: object,
}

export default Dashboard

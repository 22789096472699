import React from 'react'
import { Mutation } from 'react-apollo'
import { Formik } from 'formik'
import RESET_PASSWORD from './mutations/resetPassword.mutation'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import {
  FormGroup,
  InputGroup,
  Button,
  Classes,
  Intent,
  Card,
} from '@blueprintjs/core'
import { validation } from './validation'

const ResetPassword = ({ email, token, history }) => {
  return (
    <Card
      elevation={2}
      style={{
        width: '25rem',
        marginTop: '8rem',
      }}
    >
      <Mutation
        mutation={RESET_PASSWORD}
        onCompleted={({ resetPassword }) => {
          successToast(resetPassword.message)
        }}
        onError={defaultErrorHandler}
      >
        {resetPassword => (
          <Formik
            validationSchema={validation}
            initialValues={{
              email,
              token,
              newPassword: '',
            }}
            onSubmit={values => {
              resetPassword({
                variables: {
                  ...values,
                },
              })

              history.push('/login')
            }}
          >
            {({ errors, values, handleChange, handleSubmit }) => (
              <form className={Classes.DIALOG_BODY} onSubmit={handleSubmit}>
                <FormGroup
                  labelFor="email"
                  helperText={errors.email || ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    disabled
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup
                  label="Password"
                  labelFor="newPassword"
                  helperText={errors.newPassword || ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    name="newPassword"
                    type="password"
                    value={values.newPassword}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup
                  label="Retype Password"
                  labelFor="passwordConfirm"
                  helperText={errors.passwordConfirm || ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    name="passwordConfirm"
                    type="password"
                    onChange={handleChange}
                  />
                </FormGroup>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button text="Reset Password" type="submit" />
                </div>
              </form>
            )}
          </Formik>
        )}
      </Mutation>
    </Card>
  )
}

export default ResetPassword

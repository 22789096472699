import React, { Component, Fragment } from 'react'
import { Tree, Card, Icon, Callout, Button } from '@blueprintjs/core'
import { array, string } from 'prop-types'
import { successToast } from '@utils/toast'
import EDIT_MENU_ITEM_OPTION_ORDER from '../mutations/editMenuItemPosition.mutation'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { GET_ITEM_OPTIONS } from '../queries/getItemDetails.query'
import { swapAdjacentArrayItems } from '@utils/swapAdjacentArrayItems'

class OptionList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nodes: this.props.options,
    }
  }

  handleNodeExpand = node => {
    node.isExpanded = true
    this.setState(this.state)
  }

  handleNodeCollapse = node => {
    node.isExpanded = false
    this.setState(this.state)
  }

  handleChangeIndex = (index, direction, editMenuItemOptionOrder) => {
    const reorderedOptions = swapAdjacentArrayItems(
      this.props.options,
      index,
      direction
    )
    const reorderedOptionIds = reorderedOptions.map(({ id }) => id)
    editMenuItemOptionOrder({
      variables: {
        id: this.props.menuItemId,
        optionPositions: reorderedOptionIds,
      },
    })
  }

  render() {
    return (
      (this.props.options.length > 0 && (
        <Mutation
          mutation={EDIT_MENU_ITEM_OPTION_ORDER}
          onError={defaultErrorHandler}
          onCompleted={({ editMenuItemOptionOrder }) => {
            successToast(editMenuItemOptionOrder.message)
          }}
          refetchQueries={[
            {
              query: GET_ITEM_OPTIONS,
              variables: { id: this.props.menuItemId },
            },
          ]}
        >
          {editMenuItemOptionOrder => (
            <Card className="bp3-noPad">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '92%' }}>
                  <Tree
                    contents={this.props.options}
                    onNodeExpand={this.handleNodeExpand}
                    onNodeCollapse={this.handleNodeCollapse}
                  />
                </div>
                <div>
                  {this.props.options.map((option, index) => (
                    <div key={option.id}>
                      <Button
                        disabled={index === 0}
                        icon="chevron-up"
                        onClick={() => {
                          this.handleChangeIndex(
                            index,
                            'up',
                            editMenuItemOptionOrder
                          )
                        }}
                        minimal
                      />
                      <Button
                        disabled={index === this.props.options.length - 1}
                        icon="chevron-down"
                        onClick={() => {
                          this.handleChangeIndex(
                            index,
                            'down',
                            editMenuItemOptionOrder
                          )
                        }}
                        minimal
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Card>
          )}
        </Mutation>
      )) || (
        <Fragment>
          <Callout>
            <Icon icon={'properties'} /> Set up options for this item so the
            order can be customised.
          </Callout>
          <br />
        </Fragment>
      )
    )
  }
}

OptionList.propTypes = {
  options: array,
  restaurantId: string,
  menuItemId: string,
}

export default OptionList

import React, { useContext, useEffect } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { object } from 'prop-types'

import Route from '@ui/Route/Route'

import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { matchType } from '@utils/types'
import Details from './Details/Details'
import Billing from './Billing/Billing'
import Payments from './Payments/Payments'
import Financials from './Financials/Financials'
import Users from '@components/Users/Users'
import split from 'lodash/split'
import {
  canPerformAction,
  canView,
  isPlatformLevelUser,
} from '@stores/userStore'
import UsersPageWrapper from '@components/Users/UsersPageWrapper'
import usersPageContext from '@components/Users/usersPageContext'
import appPageContext from '@components/Apps/appPageContext'
import Apps from '../Apps/Apps'
import ListApps from '../Apps/ListApps'
import AppPageWrapper from '@components/Apps/AppPageWrapper'
import MarketplaceMap from '@components/MarketplaceMap/MarketplaceMap'

const tabButton = (
  pathname,
  setUserModalOpen,
  setAppModalOpen,
  setAppImportModalOpen
) => {
  const path = split(pathname, '/')[3]
  const pathId = split(pathname, '/')[4]

  if (path === 'users' && canPerformAction('inviteUser')) {
    return {
      text: 'Invite user',
      onClick: () => setUserModalOpen(true),
    }
  } else if (
    path === 'apps' &&
    typeof pathId !== 'undefined' &&
    canPerformAction('appBuild')
  ) {
    return {
      text: 'Start Build',
      onClick: () => setAppModalOpen(true),
    }
  } else if (path === 'apps' && isPlatformLevelUser()) {
    return {
      text: 'Import App',
      onClick: () => setAppImportModalOpen(true),
    }
  } else {
    return null
  }
}

const Partner = ({ location, match, product }) => {
  const { setModalOpen } = useContext(usersPageContext)
  const { setAppModalOpen } = useContext(appPageContext)
  const { setAppImportModalOpen } = useContext(appPageContext)
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    configurePageLayout({
      product,
      tabs: [
        { to: `${match.url}/details`, name: 'Details' },
        {
          to: `${match.url}/financials`,
          name: 'Financials',
          hide: !isPlatformLevelUser(),
        },
        { to: `${match.url}/billing`, name: 'Billing' },
        { to: `${match.url}/payments`, name: 'Payments' },
        { to: `${match.url}/apps`, name: 'Apps' },
        { to: `${match.url}/map`, name: 'Map' },
        { to: `${match.url}/users`, name: 'Users' },
      ],
      button: tabButton(
        location.pathname,
        setModalOpen,
        setAppModalOpen,
        setAppImportModalOpen
      ),
    })
  }, [
    configurePageLayout,
    match.url,
    product,
    location.pathname,
    setModalOpen,
    setAppModalOpen,
    setAppImportModalOpen,
  ])

  return (
    <section>
      <Switch>
        <Redirect
          from="/partners/:partner"
          to="/partners/:partner/details"
          exact
        />
        <Route
          path={`${match.path}/details`}
          exact={true}
          component={Details}
          isPermissionAllowed={() => canView('partners')}
        />
        <Route
          path={`${match.path}/billing`}
          exact={true}
          component={Billing}
          isPermissionAllowed={() => canView('partners')}
        />
        <Route
          path={`${match.path}/payments`}
          exact={true}
          component={Payments}
          isPermissionAllowed={() => canView('partners')}
        />
        <Route
          path={`${match.path}/financials`}
          exact={true}
          component={Financials}
          isPermissionAllowed={() => canView('partners')}
        />
        <Route
          path={`${match.path}/apps`}
          exact={true}
          component={ListApps}
          isPermissionAllowed={() => canView('partners')}
        />
        <Route
          path={`${match.path}/apps/:app`}
          component={Apps}
          exact={true}
          isPermissionAllowed={() => canView('partners')}
        />
        <Route
          path={`${match.path}/map`}
          exact={true}
          component={MarketplaceMap}
        />

        <Route
          path={`${match.path}/users`}
          exact={true}
          component={() => <Users partnerId={match.params.partner} />}
        />
      </Switch>
    </section>
  )
}

const PartnerPageWrapper = props => (
  <UsersPageWrapper entityName="partner">
    <AppPageWrapper entityName="partner" location={location.pathname}>
      <Partner {...props} />
    </AppPageWrapper>
  </UsersPageWrapper>
)

PartnerPageWrapper.propTypes = {
  match: matchType.isRequired,
  classes: object,
  location: object,
}

export default PartnerPageWrapper

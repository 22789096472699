import React, { useState } from 'react'
import { string } from 'prop-types'
import { HTMLTable, Card, NonIdealState } from '@blueprintjs/core'
import Query from '@components/Query/Query'
import GET_VISITORS from './queries/getVisitors.query'
import moment from 'moment'
import ListVisitorsFilterBar from './ListVisitorsFilterBar'

const TRACE_URL = process.env.TRACE_URL

const ListVisitors = ({ outletId }) => {
  const [date, setDate] = useState(moment().startOf('day'))
  const buttonHref = TRACE_URL + `/outlet/${outletId}`

  return (
    <Query
      query={GET_VISITORS}
      variables={{ outletId, date }}
      loaderTitle={'Loading Visitors'}
    >
      {({ getVisitors: { visitors, count } }) => (
        <div className="bp3-table-frame">
          <ListVisitorsFilterBar
            buttonHref={buttonHref}
            date={date}
            setDate={setDate}
            outletId={outletId}
            totalCount={count}
            count={visitors.length}
          />
          {visitors && visitors.length ? (
            <Card className="bp3-nopad bp3-scrollable">
              <HTMLTable bordered={false} interactive={true}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Number</th>
                  </tr>
                </thead>
                <tbody>
                  {visitors.map(visitor => (
                    <tr key={visitor.id}>
                      <td>{visitor.name}</td>
                      <td>{visitor.phoneNumber}</td>
                    </tr>
                  ))}
                </tbody>
              </HTMLTable>
            </Card>
          ) : (
            <NonIdealState
              icon="th-list"
              title="No visitors found"
              description="No visitors were found on this day"
            />
          )}
        </div>
      )}
    </Query>
  )
}

ListVisitors.propTypes = {
  outletId: string,
}

export default ListVisitors

import React, { Fragment } from 'react'
import { func, object } from 'prop-types'
import {
  Button,
  Intent,
  FormGroup,
  TextArea,
  ControlGroup,
  Label,
  Switch,
  Divider,
  H5,
  H6,
  Collapse,
  InputGroup,
  Tag,
} from '@blueprintjs/core'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import PercentageInput from '@components/PercentageInput/PercentageInput'
import { Row, Col } from 'react-simple-flex-grid'
import { VATFormCore } from '@components/Vat/VATFormCore'
import { canPerformAction, isPlatformLevelUser } from '@stores/userStore'

const editableField = () => {
  return !canPerformAction('editMarketplace')
}

const Form = ({
  handleChange,
  handleSubmit,
  errors,
  values,
  setFieldValue,
}) => (
  <form onSubmit={handleSubmit}>
    {isPlatformLevelUser() && (
      <Fragment>
        <H5>Platform Commission</H5>
        <Row gutter={24}>
          <Col span={6}>
            <H6>Delivery / Collection</H6>
            <FormGroup
              label="Basket Fee"
              labelFor="platformFee"
              helperText={
                errors.platformFee
                  ? errors.platformFee
                  : 'Percentage of total basket charged to marketplace as commission.'
              }
            >
              <PercentageInput
                value={values.platformFee}
                handleChange={values =>
                  setFieldValue('platformFee', values.floatValue)
                }
                fill
                disabled={editableField()}
              />
            </FormGroup>
            <FormGroup
              label="Platform Charge"
              labelFor="platformCharge"
              helperText={
                errors.platformCharge
                  ? errors.platformCharge
                  : 'Additional fixed charge made to the marketplace per order.'
              }
            >
              <CurrencyInput
                name="platformCharge"
                id="platformCharge"
                defaultValue={values.platformCharge}
                full={true}
                intent={errors.platformCharge ? Intent.DANGER : Intent.NONE}
                onUpdate={amount => {
                  setFieldValue('platformCharge', amount)
                }}
                disabled={editableField()}
              />
            </FormGroup>
          </Col>
          <Col span={6}>
            <H6>Table Service</H6>
            <FormGroup
              label="Basket Fee"
              labelFor="platformTableFee"
              helperText={
                errors.platformTableFee
                  ? errors.platformTableFee
                  : `Usually a lower fee.`
              }
            >
              <PercentageInput
                value={values.platformTableFee}
                handleChange={values =>
                  setFieldValue('platformTableFee', values.floatValue)
                }
                fill
                disabled={editableField()}
              />
            </FormGroup>
            <FormGroup
              label="Platform Charge"
              labelFor="platformTableCharge"
              helperText={
                errors.platformTableCharge ? errors.platformTableCharge : ``
              }
            >
              <CurrencyInput
                name="platformTableCharge"
                id="platformTableCharge"
                defaultValue={values.platformTableCharge}
                full={true}
                intent={
                  errors.platformTableCharge ? Intent.DANGER : Intent.NONE
                }
                onUpdate={amount => {
                  setFieldValue('platformTableCharge', amount)
                }}
                disabled={editableField()}
              />
            </FormGroup>
          </Col>
        </Row>
        <Divider />
        <br />
        <Row>
          <H5>Partner Commission</H5>
          <Col span={12}>
            <ControlGroup fill={true}>
              <Label style={{ marginRight: 5 }}>
                <Switch
                  checked={
                    values.partnerCommissionFee !== null ||
                    values.partnerTableCommissionFee !== null
                  }
                  label="Override Partner Commission Fee"
                  disabled={editableField()}
                  onChange={event => {
                    setFieldValue(
                      'partnerCommissionFee',
                      event.target.checked ? 0 : null
                    )
                    setFieldValue(
                      'partnerTableCommissionFee',
                      event.target.checked ? 0 : null
                    )
                  }}
                />
              </Label>
            </ControlGroup>
          </Col>
        </Row>
        {(values.partnerCommissionFee !== null ||
          values.partnerTableCommissionFee !== null) && (
          <Row gutter={24}>
            <Col span={6}>
              <FormGroup
                labelFor="partnerCommissionFee"
                helperText={
                  errors.partnerCommissionFee
                    ? errors.partnerCommissionFee
                    : 'Override the default partner commission shared with this marketplace.'
                }
                intent={errors.partnerCommissionFee ? Intent.DANGER : null}
              >
                <PercentageInput
                  id="partnerCommissionFee"
                  style={{ marginLeft: '26px' }}
                  value={values.partnerCommissionFee}
                  disabled={
                    values.partnerCommissionFee === null || editableField()
                  }
                  handleChange={values =>
                    setFieldValue('partnerCommissionFee', values.floatValue)
                  }
                />
              </FormGroup>
            </Col>
            <Col span={6}>
              <FormGroup
                labelFor="partnerTableCommissionFee"
                helperText={
                  errors.partnerTableCommissionFee
                    ? errors.partnerTableCommissionFee
                    : ''
                }
                intent={errors.partnerTableCommissionFee ? Intent.DANGER : null}
              >
                <PercentageInput
                  id="partnerTableCommissionFee"
                  style={{ marginLeft: '26px' }}
                  value={values.partnerTableCommissionFee}
                  disabled={
                    values.partnerTableCommissionFee === null || editableField()
                  }
                  handleChange={values =>
                    setFieldValue(
                      'partnerTableCommissionFee',
                      values.floatValue
                    )
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <Divider />
        <br />
      </Fragment>
    )}
    <H5>Marketplace Commission</H5>
    <Row gutter={24}>
      <Col span={6}>
        <H6>Delivery / Collection</H6>
        <FormGroup
          label="Basket Fee"
          labelFor="partnerFee"
          helperText={
            errors.partnerFee
              ? errors.partnerFee
              : 'Percentage of total basket charged to business as commission.'
          }
        >
          <PercentageInput
            value={values.partnerFee}
            handleChange={values =>
              setFieldValue('partnerFee', values.floatValue)
            }
            fill
            disabled={editableField()}
          />
        </FormGroup>
        <FormGroup
          label="Business Charge"
          labelFor="merchantCommissionCharge"
          helperText={
            errors.merchantCommissionCharge
              ? errors.merchantCommissionCharge
              : 'Additional fixed charge made to the business, taken from payout.'
          }
        >
          <CurrencyInput
            name="merchantCommissionCharge"
            id="merchantCommissionCharge"
            defaultValue={values.merchantCommissionCharge}
            full={true}
            intent={
              errors.merchantCommissionCharge ? Intent.DANGER : Intent.NONE
            }
            onUpdate={amount => {
              setFieldValue('merchantCommissionCharge', amount)
            }}
            disabled={editableField()}
          />
        </FormGroup>
        <FormGroup
          label="Customer Service Charge"
          labelFor="partnerCharge"
          helperText={
            errors.partnerCharge
              ? errors.partnerCharge
              : 'Additional fixed charge made to the customer, added to order total.'
          }
        >
          <CurrencyInput
            name="partnerCharge"
            id="partnerCharge"
            defaultValue={values.partnerCharge}
            full={true}
            intent={errors.partnerCharge ? Intent.DANGER : Intent.NONE}
            onUpdate={amount => {
              setFieldValue('partnerCharge', amount)
            }}
            disabled={editableField()}
          />
        </FormGroup>

        <FormGroup
          label="Service Charge Description"
          labelFor="partnerChargeDescription"
          helperText={
            errors.partnerChargeDescription
              ? errors.partnerChargeDescription
              : 'Description of service charge that will be shown to the customer.'
          }
        >
          <TextArea
            name="partnerChargeDescription"
            id="partnerChargeDescription"
            value={values.partnerChargeDescription}
            fill={true}
            small={true}
            onChange={handleChange}
            intent={
              errors.partnerChargeDescription ? Intent.DANGER : Intent.NONE
            }
            disabled={editableField()}
          />
        </FormGroup>
      </Col>
      <Col span={6}>
        <H6>Table Service</H6>
        <FormGroup
          label="Basket Fee"
          labelFor="partnerTableFee"
          helperText={errors.partnerTableFee ? errors.partnerTableFee : '-'}
        >
          <PercentageInput
            value={values.partnerTableFee}
            handleChange={values =>
              setFieldValue('partnerTableFee', values.floatValue)
            }
            fill
            disabled={editableField()}
          />
        </FormGroup>
        <FormGroup
          label="Business Charge"
          labelFor="merchantTableCommissionCharge"
          helperText={
            errors.merchantTableCommissionCharge
              ? errors.merchantTableCommissionCharge
              : '-'
          }
        >
          <CurrencyInput
            name="merchantTableCommissionCharge"
            id="merchantTableCommissionCharge"
            defaultValue={values.merchantTableCommissionCharge}
            full={true}
            intent={
              errors.merchantTableCommissionCharge ? Intent.DANGER : Intent.NONE
            }
            onUpdate={amount => {
              setFieldValue('merchantTableCommissionCharge', amount)
            }}
            disabled={editableField()}
          />
        </FormGroup>
        <FormGroup
          label="Customer Service Charge"
          labelFor="partnerTableCharge"
          helperText={
            errors.partnerTableCharge ? errors.partnerTableCharge : '-'
          }
        >
          <CurrencyInput
            name="partnerTableCharge"
            id="partnerTableCharge"
            defaultValue={values.partnerTableCharge}
            full={true}
            intent={errors.partnerTableCharge ? Intent.DANGER : Intent.NONE}
            onUpdate={amount => {
              setFieldValue('partnerTableCharge', amount)
            }}
            disabled={editableField()}
          />
        </FormGroup>

        <FormGroup
          label="Service Charge Description"
          labelFor="partnerTableChargeDescription"
          helperText={
            errors.partnerTableChargeDescription
              ? errors.partnerTableChargeDescription
              : ''
          }
        >
          <TextArea
            name="partnerTableChargeDescription"
            id="partnerTableChargeDescription"
            value={values.partnerTableChargeDescription}
            fill={true}
            small={true}
            onChange={handleChange}
            intent={
              errors.partnerTableChargeDescription ? Intent.DANGER : Intent.NONE
            }
            disabled={editableField()}
          />
        </FormGroup>
      </Col>
    </Row>
    <br />
    <Divider />
    <br />
    <Row>
      <h5 className="bp3-heading">Business Invoicing</h5>
      <Col span={12}>
        <Switch
          id="invoiceEnabled"
          checked={values.invoiceEnabled}
          labelElement={
            <Fragment>
              Enabled <br />
              <span className="bp3-text-small bp3-text-muted">
                Generate and send invoice to businesses weekly.
              </span>
            </Fragment>
          }
          onChange={event => {
            setFieldValue('invoiceEnabled', event.target.checked)
            setFieldValue(
              'merchantComparisonPercentage',
              event.target.checked ? 0 : null
            )
          }}
        />
      </Col>
    </Row>
    <Collapse isOpen={values.invoiceEnabled}>
      <Fragment>
        <Row gutter={24}>
          <Col span={6}>
            <FormGroup
              label="Invoice From"
              labelFor="invoiceEmail"
              style={{ marginLeft: '38px' }}
              helperText={
                errors.invoiceEmail
                  ? errors.invoiceEmail
                  : 'Email address the invoice will be sent from.'
              }
            >
              <InputGroup
                name="invoiceEmail"
                required
                type="text"
                placeholder="invoices"
                onChange={handleChange}
                value={values.invoiceEmail || ''}
                disabled={editableField()}
                rightElement={
                  <Tag minimal style={{ maxWidth: '310px' }}>
                    @{values.cname && values.cname.trim().replace(/^www\./, '')}
                  </Tag>
                }
              />
            </FormGroup>
          </Col>
          <Col span={6}>
            <FormGroup
              label="Invoice BCC"
              labelFor="invoiceBcc"
              helperText={
                errors.invoiceBcc
                  ? errors.invoiceBcc
                  : 'Email address to be used as BCC for all invoices.'
              }
            >
              <InputGroup
                name="invoiceBcc"
                type="text"
                onChange={handleChange}
                value={values.invoiceBcc || ''}
                disabled={editableField()}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <FormGroup
              label="Custom Email Text"
              labelFor="invoiceEmailCustomText"
              style={{ marginLeft: '38px' }}
              helperText={
                errors.invoiceEmailCustomText
                  ? errors.invoiceEmailCustomText
                  : 'Shown in the invoice email.'
              }
              intent={errors.invoiceEmailCustomText ? Intent.DANGER : null}
            >
              <TextArea
                name="invoiceEmailCustomText"
                id="invoiceEmailCustomText"
                value={values.invoiceEmailCustomText}
                fill={true}
                small={true}
                onChange={handleChange}
                intent={
                  errors.invoiceEmailCustomText ? Intent.DANGER : Intent.NONE
                }
                disabled={editableField()}
              />
            </FormGroup>
            <FormGroup
              label="Additional Information"
              labelFor="invoiceTextFooter"
              style={{ marginLeft: '38px' }}
              helperText={
                errors.invoiceTextFooter
                  ? errors.invoiceTextFooter
                  : 'Shown in the invoice footer, include custom payment terms or bank details.'
              }
              intent={errors.invoiceTextFooter ? Intent.DANGER : null}
            >
              <TextArea
                name="invoiceTextFooter"
                id="invoiceTextFooter"
                value={values.invoiceTextFooter}
                fill={true}
                small={true}
                onChange={handleChange}
                intent={errors.invoiceTextFooter ? Intent.DANGER : Intent.NONE}
                disabled={editableField()}
              />
            </FormGroup>
            <Switch
              checked={values.merchantComparisonPercentage !== null}
              label="Show competitor comparison on merchant invoices."
              disabled={editableField()}
              onChange={event => {
                setFieldValue(
                  'merchantComparisonPercentage',
                  event.target.checked ? 0 : null
                )
              }}
            />
          </Col>
        </Row>
        {values.merchantComparisonPercentage !== null && (
          <Row gutter={24}>
            <Col span={3}>
              <FormGroup
                label="Percentage"
                labelFor="merchantComparisonPercentage"
                style={{ marginLeft: '38px' }}
                helperText={
                  errors.merchantComparisonPercentage
                    ? errors.merchantComparisonPercentage
                    : ''
                }
                intent={
                  errors.merchantComparisonPercentage ? Intent.DANGER : null
                }
              >
                <PercentageInput
                  id="merchantComparisonPercentage"
                  value={values.merchantComparisonPercentage}
                  handleChange={values =>
                    setFieldValue(
                      'merchantComparisonPercentage',
                      values.floatValue
                    )
                  }
                />
              </FormGroup>
            </Col>
            <Col span={9}>
              <FormGroup
                label="Description"
                labelFor="merchantComparisonDescription"
                style={{ marginLeft: '38px' }}
                helperText={
                  errors.merchantComparisonDescription
                    ? errors.merchantComparisonDescription
                    : ''
                }
                intent={
                  errors.merchantComparisonDescription ? Intent.DANGER : null
                }
              >
                <InputGroup
                  name="merchantComparisonDescription"
                  id="merchantComparisonDescription"
                  value={values.merchantComparisonDescription}
                  fill={true}
                  onChange={handleChange}
                  intent={
                    errors.merchantComparisonDescription
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                  disabled={editableField()}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </Fragment>
    </Collapse>
    <br />
    <Divider />
    <br />
    <h5 className="bp3-heading">Taxes</h5>
    <Row gutter={24}>
      <Col span={6}>
        <VATFormCore
          values={values}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          errors={errors}
        />
      </Col>
    </Row>
    <Collapse isOpen={values.isVATregistered}>
      <Row>
        <Col span={12}>
          <Switch
            id="partnerChargeMerchantVat"
            checked={values.partnerChargeMerchantVat}
            labelElement={
              <Fragment>
                Charge Business VAT on Customer Charge <br />
                <span className="bp3-text-small bp3-text-muted">
                  If service charge is on behalf of the business, charge the
                  business VAT on this service charge.
                </span>
              </Fragment>
            }
            onChange={event => {
              setFieldValue(
                'partnerChargeMerchantVat',
                event.target.checked ? true : false
              )
            }}
          />
        </Col>
      </Row>
    </Collapse>
    <br />
    <div className="bp-card-footer-actions">
      {!editableField() && (
        <Button text="Save" type="submit" disabled={editableField()} />
      )}
    </div>
  </form>
)

Form.propTypes = {
  handleChange: func,
  handleSubmit: func,
  setFieldValue: func,
  errors: object,
  values: object,
}

export default Form

import React from 'react'
import { Card } from '@blueprintjs/core'
import injectSheet from 'react-jss'
import { object } from 'prop-types'
import moment from 'moment'
import get from 'lodash/get'

const style = () => ({
  reviewText: {
    marginTop: '1rem'
  }
})

const ReviewBody = ({ classes, review }) => {
  const {
    reviewText,
    reply,
    replyDate
  } = review
  const name = get(review, 'outlet.name', '')

  return(
    <Card className={classes.reviewText}>
      {reviewText}
      {reply && <blockquote>
        <p>{`On ${moment(replyDate).format('MMM Do')}`}<strong>{` ${name} `}</strong>replied:</p>
        <cite>{reply}</cite>
      </blockquote>}
    </Card>
  )
}

ReviewBody.propTypes = {
  classes: object,
  review: object
}

export default injectSheet(style)(ReviewBody)

import React from 'react'
import map from 'lodash/map'
import { string, func } from 'prop-types'

import TypeaheadSingle from '@components/Typeahead/TypeaheadSingle'
import Query from '@components/Query/Query'
import GET_OUTLET_IDS from './queries/getOutletIds.query'

const OutletSelectByMarketplace = ({
  outletId,
  marketplaceId,
  setSelected,
  disabled,
}) => (
  <Query
    query={GET_OUTLET_IDS}
    showLoader={false}
    variables={{
      marketplaceId,
    }}
  >
    {({ getOutlets: { outlets } }) => (
      <TypeaheadSingle
        items={map(outlets, outlet => ({
          id: outlet.id,
          name: outlet.restaurant.name,
          label: outlet.name,
          isOpen: outlet.isOpen,
        }))}
        isOpenFilter={true}
        selected={outletId}
        setSelected={setSelected}
        disabled={disabled}
        placeholder="Select an outlet"
      />
    )}
  </Query>
)

OutletSelectByMarketplace.propTypes = {
  outletId: string,
  pinpointApplicationId: string,
  onChange: func,
}

export default OutletSelectByMarketplace

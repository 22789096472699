import React, { Fragment } from 'react'
import { Button, Card, H5, NonIdealState } from '@blueprintjs/core'
import { string, object, arrayOf, number, bool } from 'prop-types'
import Currency from '@components/Currency/Currency'
import { Row, Col } from 'react-simple-flex-grid'
import { PaymentMethodsMap } from './util/maps'
import { capitalize } from 'lodash'
import FilterRow from '@components/FilterRow/FilterRow'
import OutletSelector from './sharedComponents/OutletSelector'
import { useQueryParam, StringParam } from 'use-query-params'

const DiscountAnalytics = ({
  numberOfOrders,
  numberOfCustomers,
  numberOfOutlets,
  subtotalGrossSum,
  subtotalGrossAvg,
  discountAmountSum,
  discountAmountAvg,
  paymentMethods,
  fulfillmentMethods,
  outlets,
  totalsGroupedByOutlets,
  showCustomerCount = true,
  showOutletCount = true,
  showFilterRow = true,
}) => {
  const [outletId, onChangeOutletId] = useQueryParam('outletId', StringParam)
  // due to the async nature of the data, we need to wait for it to be loaded
  if (
    outletId &&
    outletId !== 'All' &&
    totalsGroupedByOutlets &&
    totalsGroupedByOutlets[outletId]
  ) {
    // use specific outlet totals
    numberOfOrders = totalsGroupedByOutlets[outletId].numberOfOrders || null
    numberOfCustomers = totalsGroupedByOutlets[outletId].customers.length
    subtotalGrossSum = totalsGroupedByOutlets[outletId].subtotalGrossSum
    discountAmountSum = totalsGroupedByOutlets[outletId].discountAmountSum
    subtotalGrossAvg = totalsGroupedByOutlets[outletId].subtotalGrossAvg
    discountAmountAvg = totalsGroupedByOutlets[outletId].discountAmountAvg
    paymentMethods = totalsGroupedByOutlets[outletId].paymentMethods
    fulfillmentMethods = totalsGroupedByOutlets[outletId].fulfillmentMethods
  }

  return (
    <Fragment>
      {showFilterRow && (
        <FilterRow minimal>
          <OutletSelector outlets={outlets} />
        </FilterRow>
      )}
      {numberOfOrders ? (
        <Fragment>
          <Row gutter={24}>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <Row>
                  <Col span={10}>
                    <H5>Volume</H5>
                  </Col>
                </Row>
                <Row>
                  <Col span={4}>
                    <p className={'dashboard-data-value-small'}>
                      {numberOfOrders}
                    </p>
                    <p className="dashboard-data-label">Orders</p>
                  </Col>
                  {showCustomerCount && (
                    <Col span={4}>
                      <p className={'dashboard-data-value-small'}>
                        {numberOfCustomers}
                      </p>
                      <p className="dashboard-data-label">Customers</p>
                    </Col>
                  )}
                  {showOutletCount && (
                    <Col span={4}>
                      <p className={'dashboard-data-value-small'}>
                        {numberOfOutlets}
                      </p>
                      <p className="dashboard-data-label">Outlets</p>
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <Row>
                  <Col span={10}>
                    <H5>Financials</H5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} sm={3}>
                    <p className={'dashboard-data-value-small'}>
                      <Currency amount={subtotalGrossSum} />
                    </p>
                    <p className="dashboard-data-label">Total Value</p>
                  </Col>
                  <Col xs={6} sm={3}>
                    <p className={'dashboard-data-value-small'}>
                      <Currency amount={subtotalGrossAvg} />
                    </p>
                    <p className="dashboard-data-label">Avg Value</p>
                  </Col>
                  <Col xs={6} sm={3}>
                    <p className={'dashboard-data-value-small'}>
                      <Currency amount={discountAmountSum} />
                    </p>
                    <p className="dashboard-data-label">Total Discounted</p>
                  </Col>
                  <Col xs={6} sm={3}>
                    <p className={'dashboard-data-value-small'}>
                      <Currency amount={discountAmountAvg} />
                    </p>
                    <p className="dashboard-data-label">Avg Discount</p>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <Row>
                  <Col span={10}>
                    <H5>Fulfilment</H5>
                  </Col>
                </Row>
                <Row>
                  {' '}
                  {Object.keys(fulfillmentMethods).map(method => (
                    <Col key={method} xs={6} sm={3}>
                      <p className={'dashboard-data-value-small'}>
                        {fulfillmentMethods[method]}
                      </p>
                      <p className="dashboard-data-label">
                        {capitalize(method)}
                      </p>
                    </Col>
                  ))}
                </Row>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card>
                <Row>
                  <Col span={10}>
                    <H5>Payment</H5>
                  </Col>
                </Row>
                <Row>
                  {Object.keys(paymentMethods).map(method => (
                    <Col key={method} xs={6} sm={3}>
                      <p className={'dashboard-data-value-small'}>
                        {paymentMethods[method]}
                      </p>
                      <p className="dashboard-data-label">
                        {PaymentMethodsMap[method]}
                      </p>
                    </Col>
                  ))}
                </Row>
              </Card>
            </Col>
          </Row>
        </Fragment>
      ) : (
        <NonIdealState
          icon="th-list"
          title="No Data Available"
          description="There are no orders placed at this outlet with this discount."
          action={
            <Button onClick={() => onChangeOutletId('All')}>All Outlets</Button>
          }
        />
      )}
    </Fragment>
  )
}

DiscountAnalytics.propTypes = {
  numberOfOrders: number,
  numberOfCustomers: number,
  numberOfOutlets: number,
  subtotalGrossSum: number,
  subtotalGrossAvg: number,
  discountAmountSum: number,
  discountAmountAvg: number,
  customers: arrayOf(string),
  outlets: arrayOf(string),
  paymentMethods: object,
  fulfillmentMethods: object,
  classes: object,
  totalsGroupedByOutlets: object,
  showCustomerCount: bool,
  showOutletCount: bool,
  showFilterRow: bool,
}

export default DiscountAnalytics

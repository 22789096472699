/* eslint-disable no-useless-escape */
// ^^ because we do need to escape in regexes

const TryingForEmail = new RegExp('^[a-zA-Z0-9._\\+\\-]+@')
const TryingForPostCode = new RegExp('^[a-zA-Z]{1,2}[0-9]{1,2}')
const TryingForUkPhone = new RegExp('^(\\+44|0)[0-9]+')
const TryingForStripeId = new RegExp('^cus_(0-9a-zA-Z)*')

export const getCustomerSearchIntent = searchQuery => {
  if (!searchQuery) return null
  if (searchQuery.match(TryingForEmail)) return 'EMAIL'
  if (searchQuery.match(TryingForPostCode)) return 'POSTCODE'
  if (searchQuery.match(TryingForUkPhone)) return 'PHONE'
  if (searchQuery.match(TryingForStripeId)) return 'STRIPE'
  return null
}

import React, { Fragment } from 'react'
// eslint-disable-next-line no-restricted-imports
import { DatePicker, DateRangePicker } from '@blueprintjs/datetime'
import {
  ButtonGroup,
  Button,
  Popover,
  Position,
  MenuItem,
  AnchorButton,
} from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import moment from 'moment'
import { toast } from '@utils/toast'
import { object, bool, func, number } from 'prop-types'
import {
  addDays,
  endOfMonth,
  getMonth,
  getYear,
  isBefore,
  min,
  set,
  startOfISOWeek,
  startOfMonth,
} from 'date-fns'
import { shiftToRedboxPreMigration } from '../../utils/dateTimeRangeShifters'

export const DateFiltersDictionary = {
  DAY: { key: 'DAY', shift: 1, start: 'day', name: 'Day' },
  WEEK: { key: 'WEEK', shift: 7, start: 'isoWeek', name: 'Week' },
  MONTH: { key: 'MONTH', start: 'month', name: 'Month' },
  CUSTOM: { key: 'CUSTOM', shift: 1, start: 'day', name: 'Custom' },
}

const MAX_DAYS = 31
const MAX_YEARS_OFFERED = moment().diff(moment('2018', 'YYYY'), 'years') // Years since redbox launch

/**
 * @deprecated Use DateRangeFilter with useDateRangeQueryParams instead
 */
export class DateSelect extends React.Component {
  constructor(props) {
    super(props)

    const getDefaultFilter = () => {
      if (props.defaultDateFilter) {
        return props.defaultDateFilter
      }
      if (props.beforeDate.isSame(props.afterDate, 'day')) {
        return DateFiltersDictionary.DAY
      }
      if (props.beforeDate.diff(props.afterDate, 'days') === 6) {
        return DateFiltersDictionary.WEEK
      }
      return DateFiltersDictionary.CUSTOM
    }

    this.state = {
      selectedDateFilter: getDefaultFilter(),
      visibleDateFilters:
        props.visibleDateFilters ||
        Object.values(DateFiltersDictionary).filter(
          ({ key }) => key !== 'MONTH'
        ),
      afterDate: moment(props.afterDate),
      beforeDate: moment(props.beforeDate),
      month: getMonth(new Date()),
      year: getYear(new Date()),
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedDateFilter !== this.state.selectedDateFilter &&
      (this.state.selectedDateFilter === DateFiltersDictionary.DAY ||
        this.state.selectedDateFilter === DateFiltersDictionary.WEEK)
    ) {
      // Reset on range filter change.
      const { start: updatedAfterDate, end: updatedBeforeDate } =
        shiftToRedboxPreMigration({
          range: {
            start: moment()
              .startOf(this.state.selectedDateFilter.start)
              .toDate(),
            end: moment()
              .startOf(this.state.selectedDateFilter.start)
              .add(this.state.selectedDateFilter.shift, 'day')
              .subtract(1, 'millisecond')
              .toDate(),
          },
        })
      this.setState({
        afterDate: moment(updatedAfterDate),
        beforeDate: moment(updatedBeforeDate),
      })
    }

    if (
      prevState.month !== this.state.month ||
      prevState.year !== this.state.year ||
      (prevState.selectedDateFilter !== this.state.selectedDateFilter &&
        this.state.selectedDateFilter === DateFiltersDictionary.MONTH)
    ) {
      const monthInt = this.state.month
      const yearInt = this.state.year
      const selectedMonthDate = set(new Date(), {
        month: monthInt,
        year: yearInt,
      })
      const { start: updatedAfterDate, end: updatedBeforeDate } =
        shiftToRedboxPreMigration({
          range: {
            start: startOfMonth(selectedMonthDate),
            end: endOfMonth(selectedMonthDate),
          },
        })

      this.setState({
        month: monthInt,
        year: yearInt,
        afterDate: moment(updatedAfterDate),
        beforeDate: moment(updatedBeforeDate),
      })
    }

    if (prevState !== this.state) {
      // Update URL
      this.props.onDateChange({
        afterDate: this.state.afterDate,
        beforeDate: this.state.beforeDate,
      })
    }
  }

  getBeforeDateBounds = () => {
    // calculate the earliest and latest allowed values for the beforeDate,
    // based on the afterDate
    const { start: earliestAllowedBeforeDate, end: latestAllowedBeforeDate } =
      shiftToRedboxPreMigration({
        range: {
          start: this.state.afterDate.toDate(),
          end: addDays(this.state.afterDate.toDate(), MAX_DAYS),
        },
      })
    return {
      earliestAllowedBeforeDate,
      latestAllowedBeforeDate: min([new Date(), latestAllowedBeforeDate]), // don't allow future dates
    }
  }

  render() {
    const isDisabled =
      this.state.afterDate === null ||
      this.state.beforeDate === null ||
      this.props.disabled

    const afterDateValue = moment(this.state.afterDate).format(
      'ddd DD MMM, HH:mm'
    )
    const beforeDateValue = moment(this.state.beforeDate).format(
      'ddd DD MMM, HH:mm'
    )

    const disableShift =
      isDisabled ||
      this.state.selectedDateFilter === DateFiltersDictionary.CUSTOM

    const renderItem = (item, { handleClick }) => {
      return (
        <MenuItem
          key={item.key}
          onClick={handleClick}
          text={item.name}
          icon={item === this.state.selectedDateFilter ? 'tick' : 'blank'}
          shouldDismissPopover={false}
        />
      )
    }

    const months = [
      { label: 'January', afterSet: 'Jan', beforeSet: 'Feb', value: 0 },
      { label: 'February', afterSet: 'Feb', beforeSet: 'Mar', value: 1 },
      { label: 'March', afterSet: 'Mar', beforeSet: 'Apr', value: 2 },
      { label: 'April', afterSet: 'Apr', beforeSet: 'May', value: 3 },
      { label: 'May', afterSet: 'May', beforeSet: 'Jun', value: 4 },
      { label: 'June', afterSet: 'Jun', beforeSet: 'Jul', value: 5 },
      { label: 'July', afterSet: 'Jul', beforeSet: 'Aug', value: 6 },
      { label: 'August', afterSet: 'Aug', beforeSet: 'Sept', value: 7 },
      { label: 'September', afterSet: 'Sept', beforeSet: 'Oct', value: 8 },
      { label: 'October', afterSet: 'Oct', beforeSet: 'Nov', value: 9 },
      { label: 'November', afterSet: 'Nov', beforeSet: 'Dec', value: 10 },
      { label: 'December', afterSet: 'Dec', beforeSet: 'Jan', value: 11 },
    ]

    const thisYear = moment().format('YYYY')
    const endOfDay = moment().endOf('day')

    const years = new Array(MAX_YEARS_OFFERED)
      .fill(null)
      .map((_, i) => parseInt(thisYear) - i)

    const { earliestAllowedBeforeDate, latestAllowedBeforeDate } =
      this.getBeforeDateBounds()

    return (
      <ButtonGroup>
        <Select
          filterable={false}
          items={this.state.visibleDateFilters}
          itemRenderer={renderItem}
          onItemSelect={selectedDateFilter => {
            this.setState({ selectedDateFilter })
          }}
          popoverProps={{
            minimal: false,
            position: Position.BOTTOM_LEFT,
            boundary: 'window',
            disabled: isDisabled,
          }}
        >
          <Button
            disabled={isDisabled}
            minimal={this.props.minimal}
            icon={'calendar'}
          >
            {this.state.selectedDateFilter.name}
          </Button>
        </Select>

        {this.state.selectedDateFilter.key === 'MONTH' && (
          <Fragment>
            <Select
              disabled={isDisabled}
              filterable={false}
              items={months}
              itemRenderer={(month, { handleClick }) => {
                return (
                  <MenuItem
                    key={month.afterSet}
                    label={month.value.toString()}
                    text={month.label}
                    onClick={handleClick}
                    icon={this.state.month === month.value ? 'tick' : 'blank'}
                    shouldDismissPopover={false}
                  />
                )
              }}
              popoverProps={{
                minimal: false,
                position: Position.BOTTOM_LEFT,
                boundary: 'window',
              }}
              onItemSelect={month => {
                this.setState({
                  month: month.value,
                })
              }}
            >
              <AnchorButton
                rightIcon="double-caret-vertical"
                disabled={isDisabled}
                minimal={this.props.minimal}
              >
                {this.state.afterDate.format('MMMM')}
              </AnchorButton>
            </Select>
            <Select
              filterable={false}
              items={years}
              itemRenderer={(year, { handleClick }) => {
                return (
                  <MenuItem
                    key={year}
                    text={year}
                    onClick={handleClick}
                    icon={this.state.year === year ? 'tick' : 'blank'}
                    shouldDismissPopover={false}
                  />
                )
              }}
              onItemSelect={year => {
                this.setState({
                  year,
                })
              }}
              popoverProps={{
                minimal: false,
                position: Position.BOTTOM_LEFT,
                boundary: 'window',
                disabled: isDisabled,
              }}
            >
              <Button
                rightIcon="double-caret-vertical"
                disabled={isDisabled}
                minimal={this.props.minimal}
              >
                {this.props.afterDate.format('YYYY')}
              </Button>
            </Select>
          </Fragment>
        )}

        {(this.state.selectedDateFilter === DateFiltersDictionary.DAY ||
          this.state.selectedDateFilter === DateFiltersDictionary.WEEK) && (
          <Fragment>
            <Button
              rightIcon="caret-left"
              disabled={disableShift}
              minimal={this.props.minimal}
              onClick={() => {
                const { start: updatedAfterDate, end: updatedBeforeDate } =
                  shiftToRedboxPreMigration({
                    range: {
                      start: this.state.afterDate
                        .subtract(this.state.selectedDateFilter.shift, 'day')
                        .toDate(),
                      end: this.state.beforeDate
                        .subtract(this.state.selectedDateFilter.shift, 'day')
                        .toDate(),
                    },
                  })
                this.setState({
                  afterDate: moment(updatedAfterDate),
                  beforeDate: moment(updatedBeforeDate),
                })
              }}
            />
            {this.state.selectedDateFilter === DateFiltersDictionary.DAY ? (
              <Popover
                position={Position.BOTTOM_LEFT}
                boundary="window"
                disabled={isDisabled}
              >
                <Button
                  disabled={isDisabled}
                  minimal={this.props.minimal}
                  rightIcon="double-caret-vertical"
                >
                  {afterDateValue}
                  {'  '}
                  <span className="bp3-text-disabled">to</span>
                  {'  '}
                  {beforeDateValue}
                </Button>

                <DatePicker
                  value={this.state.afterDate.toDate()}
                  maxDate={moment().endOf('day').toDate()}
                  highlightCurrentDay
                  onChange={(selectedDate, isUserChange) => {
                    if (selectedDate && isUserChange) {
                      const {
                        start: updatedAfterDate,
                        end: updatedBeforeDate,
                      } = shiftToRedboxPreMigration({
                        range: {
                          start: selectedDate,
                          end: selectedDate,
                        },
                      })
                      this.setState({
                        afterDate: moment(updatedAfterDate),
                        beforeDate: moment(updatedBeforeDate),
                      })
                    }
                  }}
                />
              </Popover>
            ) : (
              <Popover
                position={Position.BOTTOM_LEFT}
                boundary="window"
                disabled={isDisabled}
              >
                <Button
                  disabled={isDisabled}
                  minimal={this.props.minimal}
                  rightIcon="double-caret-vertical"
                >
                  {moment(this.state.afterDate).format('DD MMM HH:mm')}
                  {'  '}
                  <span className="bp3-text-disabled">to</span>
                  {'  '}
                  {moment(this.state.beforeDate).format('DD MMM HH:mm')}
                  {' - '}
                  <span className="bp3-text-disabled">
                    Week {this.state.afterDate.isoWeek()}
                  </span>
                </Button>
                <DateRangePicker
                  dayPickerProps={{
                    firstDayOfWeek: 1,
                  }}
                  value={[
                    this.state.afterDate.toDate(),
                    this.state.beforeDate.toDate(),
                  ]}
                  maxDate={
                    endOfDay.isAfter(this.state.beforeDate)
                      ? endOfDay.toDate()
                      : this.state.beforeDate.toDate()
                  }
                  shortcuts={false}
                  onChange={([startOfRange]) => {
                    if (startOfRange) {
                      const startOfSelectedWeek = startOfISOWeek(startOfRange)
                      const {
                        start: updatedAfterDate,
                        end: updatedBeforeDate,
                      } = shiftToRedboxPreMigration({
                        range: {
                          start: startOfSelectedWeek,
                          end: addDays(startOfSelectedWeek, 6),
                        },
                      })
                      this.setState({
                        afterDate: moment(updatedAfterDate),
                        beforeDate: moment(updatedBeforeDate),
                      })
                    }
                  }}
                />
              </Popover>
            )}
            <Button
              rightIcon="caret-right"
              minimal={this.props.minimal}
              onClick={() => {
                const { start: updatedAfterDate, end: updatedBeforeDate } =
                  shiftToRedboxPreMigration({
                    range: {
                      start: this.state.afterDate
                        .add(this.state.selectedDateFilter.shift, 'day')
                        .toDate(),
                      end: this.state.beforeDate
                        .add(this.state.selectedDateFilter.shift, 'day')
                        .toDate(),
                    },
                  })
                this.setState({
                  afterDate: moment(updatedAfterDate),
                  beforeDate: moment(updatedBeforeDate),
                })
              }}
              disabled={
                disableShift ||
                moment().isSameOrBefore(this.state.beforeDate.clone())
              }
            />
          </Fragment>
        )}
        {this.state.selectedDateFilter === DateFiltersDictionary.CUSTOM && (
          <Fragment>
            <Popover
              position={Position.BOTTOM_LEFT}
              boundary="window"
              disabled={isDisabled}
            >
              <Button
                disabled={isDisabled}
                minimal={this.props.minimal}
                rightIcon="double-caret-vertical"
              >{`${afterDateValue}`}</Button>

              <DatePicker
                value={this.state.afterDate.toDate()}
                maxDate={moment().endOf('day').toDate()}
                highlightCurrentDay
                onChange={(selectedDate, isUserChange) => {
                  if (selectedDate && isUserChange) {
                    const { start: updatedAfterDate, end: updatedBeforeDate } =
                      shiftToRedboxPreMigration({
                        range: {
                          start: selectedDate,
                          end: addDays(
                            selectedDate,
                            this.state.selectedDateFilter.shift
                          ),
                        },
                      })
                    this.setState({
                      afterDate: moment(updatedAfterDate),
                      beforeDate: moment(updatedBeforeDate),
                    })
                  }
                }}
              />
            </Popover>
            <Fragment>
              <Button disabled={true} minimal={this.props.minimal}>
                to
              </Button>
              <Popover
                position={Position.BOTTOM_LEFT}
                boundary="window"
                disabled={isDisabled}
              >
                <Button
                  disabled={
                    isDisabled ||
                    // don't allow selecting new end date if custom filtering is disabled
                    !this.state.visibleDateFilters.find(
                      ({ key }) => key === 'CUSTOM'
                    )
                  }
                  minimal={this.props.minimal}
                  rightIcon="double-caret-vertical"
                >{`${beforeDateValue}`}</Button>
                <DatePicker
                  minDate={earliestAllowedBeforeDate}
                  highlightCurrentDay
                  maxDate={latestAllowedBeforeDate}
                  onChange={(selectedDate, isUserChange) => {
                    if (selectedDate && isUserChange) {
                      const { end: updatedBeforeDate } =
                        shiftToRedboxPreMigration({
                          range: {
                            start: selectedDate,
                            end: selectedDate,
                          },
                        })
                      if (
                        isBefore(
                          updatedBeforeDate,
                          this.state.afterDate.toDate()
                        )
                      ) {
                        toast({
                          message:
                            'The ending date is before the starting date',
                          intent: 'warning',
                        })
                      } else {
                        this.setState({
                          selectedDateFilter: DateFiltersDictionary.CUSTOM,
                          beforeDate: moment(updatedBeforeDate),
                        })
                      }
                    }
                  }}
                />
              </Popover>
            </Fragment>
          </Fragment>
        )}
      </ButtonGroup>
    )
  }
}

DateSelect.propTypes = {
  year: number,
  month: number,
  afterDate: object,
  beforeDate: object,
  onDateChange: func,
  disabled: bool,
  minimal: bool,
  useHistoricalFourAMDayStart: bool,
}

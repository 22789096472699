const products = {
  DASHBOARD: { name: 'dashboard' },
  PLATFORM: { name: 'platform' },
  RESTAURANTS: { name: 'restaurants' },
  RESTAURANT: { name: 'restaurant' },
  PARTNER: { name: 'partner' },
  MARKETPLACE: { name: 'marketplace' },
  OUTLET: { name: 'outlet', crumbNo: 4 },
  TERMINAL: { name: 'terminal', crumbNo: 6 },
  ORDER: { name: 'order' },
  CUSTOMERS: { name: 'customers' },
  CUSTOMER: { name: 'customer' },
  REPORTS: { name: 'report' },
  TRANSACTIONS: { name: 'transactions' },
  INVOICES: { name: 'invoices' },
  DELIVERY_ZONE: { name: 'delivery-zones', crumbNo: 4 },
  REVIEWS: { name: 'reviews' },
  REVIEW: { name: 'review' },
  MARKETING: { name: 'marketing', crumbNo: 4 },
  VIRTUAL_TERMINAL: { name: 'virtual-terminal' },
  MESSAGING: { name: 'messaging' },
}

export default products

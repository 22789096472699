import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const DragItem = ({ src, onMoveItem, children }) => {
  const ref = useRef(null)

  const [{ isDragging }, connectDrag] = useDrag({
    item: { src, type: 'IMG' },
    collect: monitor => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  })

  const [, connectDrop] = useDrop({
    accept: 'IMG',
    drop(hoveredOverItem) {
      if (hoveredOverItem.src !== src) {
        onMoveItem(hoveredOverItem.src, src)
      }
    },
  })

  connectDrag(ref)
  connectDrop(ref)

  const opacity = isDragging ? 0.5 : 1
  const containerStyle = { opacity }

  return React.Children.map(children, child =>
    React.cloneElement(child, {
      forwardedRef: ref,
      style: containerStyle,
    })
  )
}

export default DragItem

import Query from '@components/Query/Query'
import React, { Fragment } from 'react'
import GET_MENU_FOR_PRINTING from './queries/getMenuForPrinting.query'
import veganImage from './images/vegan.png'
import glutenFreeImage from './images/glutenfree.png'
import vegetarianImage from './images/vegetarian.png'
import options from './images/add.svg'
import ReactSVG from 'react-svg'
import injectSheet from 'react-jss'
import { styles } from './MenuPrintout.styles'
import { canView } from '@stores/userStore'
import Currency from '@components/Currency/Currency'
const AWS_CDN_URL =
  process.env.AWS_CDN_URL ||
  'https://s3.eu-west-1.amazonaws.com/redbox-gbp-img-staging/'

const MenuPrintout = ({ restaurantId, classes }) => {
  const {
    groupContainer,
    menuGroupHeader,
    itemsContainer,
    itemContainer,
    headerContainer,
    itemHeader,
    itemName,
    smlIcon,
    restaurantName,
    restaurantLogo,
    optionsSvg,
    itemPrice,
    itemDescription,
    footerContent,
    keyContainer,
    svgContainer,
    keyP,
    firstGroupContainer,
  } = classes

  if (!canView('restaurants')) return false

  return (
    <Query
      query={GET_MENU_FOR_PRINTING}
      variables={{
        id: restaurantId,
      }}
    >
      {data => {
        if (
          data &&
          data.getRestaurants &&
          data.getRestaurants.restaurants.length > 0
        ) {
          const { image, menuItemsGroups, name, outlets } =
            data.getRestaurants.restaurants[0]
          return (
            <Fragment>
              {outlets.length > 0 && (
                <Fragment>
                  <footer className="page-footer">
                    <div className={footerContent}>
                      <div className={keyContainer}>
                        <div className={svgContainer}>
                          <ReactSVG src={options} className={optionsSvg} />
                        </div>
                        <p className={keyP} style={{ textAlign: 'left' }}>
                          Options available, see online menu for details
                        </p>
                      </div>
                      <div className={keyContainer}>
                        <img
                          src={glutenFreeImage}
                          alt="Gluten free"
                          className={smlIcon}
                        />
                        <p className={keyP}>Gluten Free</p>
                      </div>
                      <div className={keyContainer}>
                        <img
                          src={vegetarianImage}
                          alt="Vegetarian"
                          className={smlIcon}
                        />
                        <p className={keyP}>Vegetarian</p>
                      </div>
                      <div className={keyContainer}>
                        <img src={veganImage} alt="Vegan" className={smlIcon} />
                        <p className={keyP}>Vegan</p>
                      </div>
                    </div>
                    <div className={footerContent}>
                      <p className={keyP}>
                        Please inform {name} of any dietary requirements before
                        ordering. We cannot guarantee dishes are 100% free of
                        allergens or contaminants.
                      </p>
                    </div>
                  </footer>
                  <table>
                    <tr>
                      <td>
                        <div className="page">
                          <div className={headerContainer}>
                            <img
                              src={`${AWS_CDN_URL}${image}`}
                              alt={`${name} logo`}
                              className={restaurantLogo}
                            />
                            <h2 className={restaurantName}>{name}</h2>
                            <p>
                              Available through {outlets[0].marketplace.name}
                            </p>
                          </div>
                          {menuItemsGroups.map((group, groupIndex) => {
                            if (group.menuItems.length) {
                              return (
                                <section
                                  className={
                                    groupIndex === 0
                                      ? firstGroupContainer
                                      : groupContainer
                                  }
                                  key={group.id}
                                >
                                  <h4 className={menuGroupHeader}>
                                    {group.name.toUpperCase()}
                                  </h4>
                                  <p style={{ padding: '7px' }}>
                                    {group.description}
                                  </p>
                                  <div className={itemsContainer}>
                                    {group.menuItems.map(item => {
                                      return (
                                        <Fragment key={item.id}>
                                          <div className={itemContainer}>
                                            <div className={itemHeader}>
                                              <div className={itemName}>
                                                {item.name}

                                                {item.isGlutenFree ? (
                                                  <img
                                                    src={glutenFreeImage}
                                                    alt="Gluten free"
                                                    title={`${item.name} is gluten free`}
                                                    className={smlIcon}
                                                  />
                                                ) : null}
                                                {item.isVegan ? (
                                                  <img
                                                    src={veganImage}
                                                    alt="Vegan"
                                                    title={`${item.name} is vegan`}
                                                    className={smlIcon}
                                                  />
                                                ) : null}
                                                {item.isVegetarian ? (
                                                  <img
                                                    src={vegetarianImage}
                                                    alt="Vegetarian"
                                                    title={`${item.name} is vegetarian`}
                                                    className={smlIcon}
                                                  />
                                                ) : null}
                                                {item.options.length > 0 ? (
                                                  <ReactSVG
                                                    src={options}
                                                    className={optionsSvg}
                                                  />
                                                ) : null}
                                              </div>
                                              <p className={itemPrice}>
                                                <Currency amount={item.price} />
                                              </p>
                                            </div>
                                            <div className={itemDescription}>
                                              {item.description}
                                            </div>
                                          </div>
                                          {/* QR code is in RED-2878 still waiting to be merged. Just need to put a couple of lines of code in here when available. */}
                                          {/* {groupIndex === menuItemsGroups.length - 1 &&
                              itemIndex === group.menuItems.length - 1 ? (
                                <div>QR code here</div>
                              ) : null} */}
                                        </Fragment>
                                      )
                                    })}
                                  </div>
                                </section>
                              )
                            }
                          })}
                        </div>
                      </td>
                    </tr>
                    <tfoot>
                      <tr>
                        <td>
                          <div className="page-footer-space"></div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </Fragment>
              )}
            </Fragment>
          )
        } else {
          return null
        }
      }}
    </Query>
  )
}

export default injectSheet(styles)(MenuPrintout)

export const ReconciliationType = {
  on_transaction_create: {
    title: 'Transaction Creation Error',
    tag: 'TRANSACTION CREATE',
    minimal: true,
    details:
      'An error occurred during the initial transaction processing. Commission transfers or application fee processing failed.',
  },
  on_transaction_update: {
    title: 'Transaction Update Error',
    tag: 'TRANSACTION UPDATE',
    minimal: true,
    details:
      'Discrepancies detected during transaction update. This may occur during retry attempts or transaction state changes, such as order rejection scenarios.',
  },
  on_transaction_refund: {
    intent: 'warning',
    title: 'Refund Processing Error',
    tag: 'REFUND PROCESSING',
    details:
      'Issues encountered while processing transfer reversals during refund operation.',
  },
  backfill: {
    title: 'Historical Error',
    tag: 'HISTORICAL',
    minimal: true,
    details:
      'A historical transaction discrepancy was detected. The exact timing of the issue is unknown.',
  },
}

export const ReconciliationResolutionType = {
  manual_stripe_action: {
    title: 'Manual Stripe Resolution',
    tag: 'MANUAL STRIPE',
    minimal: true,
    details:
      'A manual resolution was applied to this transaction in Stripe. This may include transfers being manually reversed in Stripe.',
  },
  non_stripe_action: {
    title: 'Non-Stripe Resolution',
    tag: 'NON-STRIPE',
    minimal: true,
    details:
      'A non-Stripe resolution was applied to this transaction. This may include invoices paid outside of stripe.',
  },
  refund_resolved: {
    title: 'Refund Resolved',
    tag: 'REFUND RESOLVED',
    details:
      'Discrepancies detected during initial transaction processing no longer require resolution, due to the order being refunded.',
  },
}

const colors = {
  mainBg: 'rgba(232, 237, 242, 0.42)',
  secondaryBg: '#fff',
  borderColor: 'rgba(115, 134, 148, 0.28)',
  globalLinkColor: '#106ba3',
  headerBgColor: '#394b59',
  headerTextColor: '#bfccd6',
  primaryButtonColor: '#2f3f4c',
  primaryButtonText: '#fff',
  primaryButtonShadow: '0 0 0 1px rgba(16, 22, 26, 0.4)',
  primaryNavColor: '#738694',
  primaryNavHoverColor: '#181F26',
  primaryNavActiveColor: '#FF3B4C',
  disabledText: 'rgba(92, 112, 128, 0.5)',
  activeGreen: '#006400',
  infoGrey: 'rgba(138, 155, 168)',

  // ********************************
  // tabBar
  // ********************************
  tabBarBG: '#2f3f4c',
  tabBarTabText: '#738694',
  tabBarTabHoverText: '#181F26',
  tabBarTabActiveText: '#fff',

  // ********************************
  // Menu
  // ********************************

  menuSelect: '#137CBD',
  menuSelectText: 'white',

  dropdownBg: '#fff',
  highlight: '#FF3B4C',

  // ********************************
  // Order
  // ********************************
  orderHighlight: '#5c7080',
  orderTimeColor: '#43bf4d',

  // ********************************
  // Terminal
  // ********************************
  onlineGreen: '#5BB70D',
  offlineRed: '#FF3B4C',
  inactiveGray: '#CDD6DD',
}

export default colors

import React, { Fragment, useState } from 'react'
import {
  Card,
  HTMLTable,
  Button,
  Intent,
  Alert,
  NonIdealState,
  Spinner,
  Callout,
} from '@blueprintjs/core'
import get from 'lodash/get'
import { Formik, FieldArray } from 'formik'
import { Mutation } from 'react-apollo'

import {
  checkForBlankInterval,
  setKeys,
  BLANK_OPENING_TIMES,
} from '@utils/helpers'
import { validation } from './validation'
import { successToast } from '@utils/toast'
import { matchType } from '@utils/types'
import Query from '@components/Query/Query'
import GET_OPENING_TIMES from './queries/openingTimes.query'
import EDIT_OPENING_TIMES from './mutations/editOpeningTimes.mutation'
import OpeningTimeInput from './OpeningTimeInput'
import shortid from 'shortid'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import DateTimePopover from './DateTimePopover'
import GET_OUTLET_DELIVERY_WINDOWS from './queries/deliveryWindows.query'
import DeliveryWindows from './DeliveryWindows'
import FulfilmentTimes from './FulfilmentTimes'
import { Row, Col } from 'react-simple-flex-grid'

const moment = extendMoment(Moment)
const BLANK_INTERVAL = {
  start: {
    day: '',
    time: '',
  },
  end: { day: '', time: '' },
  key: shortid.generate(),
}

const OpeningTimes = ({ match }) => {
  const [overlappingRanges, setOverlappingRanges] = useState([])
  const [showTimesPopover, setShowTimesPopover] = useState(false)

  return (
    <Fragment>
      <Row gutter={24}>
        <Col xs={12} lg={9}>
          <Query
            query={GET_OPENING_TIMES}
            variables={{ id: match.params.outlet }}
            showLoader={false}
          >
            {({ getOutlets }) => {
              const outlet = get(getOutlets, 'outlets[0]')
              const times =
                get(getOutlets, 'outlets[0].openingTimes') ||
                BLANK_OPENING_TIMES

              const openingTimes = setKeys(times).map(openingTime => {
                if (!openingTime.timeSlot) {
                  openingTime.timeSlot = getOutlets.defaultDeliveryTime
                }
                if (!openingTime.timeSlot) {
                  openingTime.timeSlot = outlet.defaultDeliveryTime
                    ? outlet.defaultDeliveryTime
                    : 30
                }
                let timeSlotHours = Math.floor(
                  openingTime.timeSlot / 60
                ).toString()
                let timeSlotMinutes = (openingTime.timeSlot % 60).toString()
                openingTime.timeSlotTime = `${timeSlotHours.padStart(
                  2,
                  '0'
                )}:${timeSlotMinutes.padStart(2, '0')}`
                return openingTime
              })

              //order opening times by day
              openingTimes.sort((a, b) => {
                return (
                  a.start.day - b.start.day ||
                  a.start.time.split(':')[0] - b.start.time.split(':')[0]
                )
              })

              return (
                <Fragment>
                  {!outlet.active && (
                    <Callout
                      style={{ marginBottom: 24 }}
                      intent="danger"
                      icon="ban-circle"
                    >
                      This Outlet is Deactivated and will not allow orders.
                    </Callout>
                  )}
                  <Card>
                    <Mutation
                      mutation={EDIT_OPENING_TIMES}
                      onError={error => {
                        if (error && error.graphQLErrors.length) {
                          if ('data' in error.graphQLErrors[0]) {
                            if (!overlappingRanges.length) {
                              setOverlappingRanges(error.graphQLErrors[0].data)
                            }
                          }
                        }
                      }}
                      onCompleted={() => {
                        successToast('Ordering times successfully edited')
                      }}
                      refetchQueries={[
                        {
                          query: GET_OUTLET_DELIVERY_WINDOWS,
                          variables: { outletId: match.params.outlet },
                        },
                      ]}
                    >
                      {(editOpeningTimes, { loading }) => {
                        return (
                          <Formik
                            initialValues={{
                              openingTimes,
                            }}
                            onSubmit={values => {
                              editOpeningTimes({
                                variables: {
                                  id: match.params.outlet,
                                  ...values,
                                },
                              })
                            }}
                            validationSchema={validation}
                          >
                            {({
                              values,
                              handleChange,
                              setFieldError,
                              errors,
                              handleSubmit,
                              setFieldValue,
                            }) => {
                              if (loading) {
                                return (
                                  <NonIdealState
                                    icon={
                                      <Spinner size={Spinner.SIZE_STANDARD} />
                                    }
                                    title="Loading"
                                    description="Please wait while we regenerate your delivery windows"
                                  />
                                )
                              }
                              return (
                                <form onSubmit={handleSubmit}>
                                  <FieldArray
                                    name="openingTimes"
                                    render={({ push, remove }) => (
                                      <Fragment>
                                        <Button
                                          icon="eye-open"
                                          minimal
                                          style={{ float: 'right' }}
                                          onClick={() =>
                                            setShowTimesPopover(
                                              !showTimesPopover
                                            )
                                          }
                                          intent="primary"
                                        >
                                          Preview Delivery Windows
                                        </Button>
                                        <h5 className="bp3-heading">
                                          Ordering Times
                                        </h5>
                                        <p>
                                          Set your delivery windows and times
                                          your outlet will accept orders.
                                        </p>

                                        <Alert
                                          isOpen={overlappingRanges.length > 0}
                                          onClose={() => {
                                            setOverlappingRanges([])
                                          }}
                                        >
                                          <Fragment>
                                            <div
                                              style={{ marginBottom: '20px' }}
                                            >
                                              Some of your opening times are
                                              clashing, please fix the following
                                            </div>
                                            {overlappingRanges.map(
                                              overlappingRange => (
                                                <div
                                                  key={overlappingRange.weekday}
                                                >
                                                  {`${moment(
                                                    overlappingRange.start
                                                  ).format('dddd HH:mm')}
                                     - ${moment(overlappingRange.end).format(
                                       'dddd HH:mm'
                                     )}`}
                                                </div>
                                              )
                                            )}
                                          </Fragment>
                                        </Alert>
                                        <br />
                                        <Card className="bp3-nopad bp3-scrollable">
                                          <HTMLTable
                                            bordered={false}
                                            interactive={true}
                                            striped={true}
                                          >
                                            <thead>
                                              <tr>
                                                <th>Delivery Window</th>
                                                <th>Start</th>
                                                <th>First Order</th>
                                                <th>First Delivery Window</th>
                                                <th>End</th>
                                                <th>Last Order</th>
                                                <th
                                                  style={{ width: '5%' }}
                                                ></th>
                                              </tr>
                                              <tr>
                                                <th>
                                                  <span className="bp3-text-small bp3-text-muted">
                                                    (hour: min) minutes
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="bp3-text-small bp3-text-muted">
                                                    (day)
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="bp3-text-small bp3-text-muted">
                                                    (time)
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="bp3-text-small bp3-text-muted">
                                                    (start) - (end)
                                                  </span>
                                                </th>
                                                <th>
                                                  <span className="bp3-text-small bp3-text-muted">
                                                    (day)
                                                  </span>
                                                </th>
                                                <th colSpan={2}>
                                                  <span className="bp3-text-small bp3-text-muted">
                                                    (time)
                                                  </span>
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {values.openingTimes.map(
                                                (time, index) => (
                                                  <OpeningTimeInput
                                                    key={time.key}
                                                    onChange={handleChange}
                                                    index={index}
                                                    availabilityTimes={
                                                      values.openingTimes
                                                    }
                                                    errors={errors}
                                                    remove={remove}
                                                    fieldName="openingTimes"
                                                    setFieldValue={
                                                      setFieldValue
                                                    }
                                                    defaultDeliveryTime={
                                                      outlet.defaultDeliveryTime
                                                    }
                                                    defaultCollectionTime={
                                                      outlet.defaultCollectionTime
                                                    }
                                                  />
                                                )
                                              )}
                                            </tbody>
                                          </HTMLTable>
                                        </Card>
                                        <div className="bp-card-footer-actions">
                                          <Button text="Save" type="submit" />
                                          <Button
                                            text="Add New Ordering Time"
                                            minimal={true}
                                            icon="plus"
                                            intent={Intent.SUCCESS}
                                            onClick={() =>
                                              checkForBlankInterval(
                                                values.openingTimes,
                                                setFieldError,
                                                push,
                                                {
                                                  ...BLANK_INTERVAL,
                                                  timeSlot:
                                                    outlet.defaultDeliveryTime,
                                                },
                                                'openingTimes'
                                              )
                                            }
                                          />
                                        </div>
                                      </Fragment>
                                    )}
                                  />
                                </form>
                              )
                            }}
                          </Formik>
                        )
                      }}
                    </Mutation>
                  </Card>
                </Fragment>
              )
            }}
          </Query>

          <Card>
            <Query
              query={GET_OUTLET_DELIVERY_WINDOWS}
              variables={{ outletId: match.params.outlet }}
              loaderTitle={'Delivery Windows'}
            >
              {({ getOutletDeliveryWindows: { outletDeliveryWindows } }) => {
                const fulfilmentTimes = outletDeliveryWindows
                  .map(deliveryWindow => ({
                    id: deliveryWindow.id,
                    limit: deliveryWindow.limit,
                    start: moment()
                      .isoWeekday(
                        deliveryWindow.start_day < moment().day()
                          ? deliveryWindow.start_day + 7
                          : deliveryWindow.start_day
                      )
                      .hour(deliveryWindow.start_hour)
                      .minute(deliveryWindow.start_minute)
                      .format(),
                    end: moment()
                      .isoWeekday(
                        deliveryWindow.end_day < moment().day()
                          ? deliveryWindow.end_day + 7
                          : deliveryWindow.end_day
                      )
                      .hour(deliveryWindow.end_hour)
                      .minute(deliveryWindow.end_minute)
                      .format(),
                  }))
                  .sort(
                    (a, b) =>
                      moment(a.start).valueOf() - moment(b.start).valueOf()
                  )

                return (
                  <Fragment>
                    <DateTimePopover
                      classes={{}}
                      fulfilmentTimeBrackets={fulfilmentTimes}
                      setPreorderTime={() => {}}
                      setASAPTime={() => {}}
                      selectedPreorderTime={() => {}}
                      fulfilmentChosen={() => {}}
                      outlet={{ isOnline: true, isOpen: true }}
                      showPopover={showTimesPopover}
                      setShowPopover={setShowTimesPopover}
                    />
                    <DeliveryWindows
                      deliveryWindows={fulfilmentTimes}
                      match={match}
                    />
                  </Fragment>
                )
              }}
            </Query>
          </Card>
        </Col>
        <Col xs={12} lg={3}>
          <FulfilmentTimes match={match} />
        </Col>
      </Row>
    </Fragment>
  )
}

OpeningTimes.propTypes = {
  match: matchType,
}

export default OpeningTimes

import { Intent } from '@blueprintjs/core'
import { removeUnderscores } from '@utils/helpers'
import moment from 'moment'

export const tableHead = [
  {
    key: 'orderNo',
    content: 'Order',
  },
  {
    key: 'customerName',
    content: 'Customer',
  },
  {
    key: 'customerTotal',
    content: 'Value',
  },
  {
    key: 'outlet',
    content: 'Outlet',
  },
  {
    key: 'grossTotal',
    content: 'Total',
  },
  {
    key: 'createdAt',
    content: 'Creation',
  },
  {
    key: 'orderStatus',
    content: 'Status',
  },
  {
    key: 'updatedAt',
    content: 'Last Update',
  },
  {
    key: 'fulfilmentAt',
    content: 'Fulfilment',
  },
]

export const DELIVERY_PROVIDER_TO_TAG_ICON = {
  ICABBI: 'taxi',
  STUART: 'cycle',
  UBER_DIRECT: 'taxi',
}

export const getIntent = status => {
  switch (status) {
    case 'COMPLETE':
      return Intent.SUCCESS
    case 'PENDING':
      return Intent.WARNING
    case 'REJECTED':
    case 'AUTH_ACTION_FAILURE':
    case 'EXPIRING SOON':
    case 'ORDER_FAILURE_REFUND':
    case 'DELIVERY_FAILURE_REFUND':
    case 'REFUND_REQUESTED':
    case 'REFUND_RELEASED':
      return Intent.DANGER
    default:
      return Intent.NONE
  }
}

export const getHumanReadableStatus = (
  status,
  createdAt,
  asap,
  isAutoRejected
) => {
  if (status == 'AUTH_ACTION_FAILURE') return 'UNAUTHORISED'
  if (status == 'AUTH_ACTION_PENDING') return 'AUTHORISE'
  if (status === 'REJECTED' && isAutoRejected) return 'AUTO REJECTED'

  if (status === 'PENDING' && !asap) {
    if (moment(createdAt).add(4, 'days').isSame(moment(), 'day')) {
      return 'EXPIRING SOON'
    } else {
      return 'PREORDER'
    }
  } else {
    return removeUnderscores(status)
  }
}

// maps the orderStatus query param, to the accepted statuses and the order by field
export const getOrderStatusMapping = (
  statusGroup = 'default',
  showAwaitingPayments = false
) => {
  const awaitingPaymentStatuses = showAwaitingPayments
    ? [
        'AWAITING_PAYMENT',
        'AUTH_ACTION_PENDING',
        'AUTH_ACTION_FAILURE',
        'AWAITING_FRIENDLY_ORDER_NUMBER_ASSIGNMENT',
      ]
    : []

  const mapping = {
    pending: {
      orderStatus: ['PENDING', ...awaitingPaymentStatuses],
      orderBy: 'estimatedDeliveryDate_ASC',
    },
    ready: {
      orderStatus: ['READY'],
      orderBy: 'updatedAt_ASC',
    },
    preparing: {
      orderStatus: ['PREPARING', 'AWAITING_DELIVERY_NETWORK'],
      orderBy: 'createdAt_DESC',
    },
    complete: {
      orderStatus: ['COMPLETE'],
      orderBy: 'createdAt_DESC',
    },
    rejected: {
      orderStatus: [
        'REJECTED',
        'DELIVERY_NETWORK_FAILURE',
        'AUTH_ACTION_FAILURE',
      ],
      orderBy: 'createdAt_DESC',
    },
    refund: {
      orderStatus: [
        'DELIVERY_FAILURE_REFUND',
        'ORDER_FAILURE_REFUND',
        'REFUND_REQUESTED',
        'REFUND_CANCELLED',
        'REFUND_RELEASED',
        'REFUND_FAILED',
      ],
      orderBy: 'createdAt_DESC',
    },
    default: {
      orderStatus: [
        'PENDING',
        'PREPARING',
        'READY',
        'AWAITING_DELIVERY_NETWORK',
        'DELIVERY_NETWORK_FAILURE',
        'COMPLETE',
        'REJECTED',
        'DELIVERY_FAILURE_REFUND',
        'ORDER_FAILURE_REFUND',
        'REFUND_REQUESTED',
        'REFUND_CANCELLED',
        'REFUND_RELEASED',
        'REFUND_FAILED',
        ...awaitingPaymentStatuses,
      ],
      orderBy: 'createdAt_DESC',
    },
  }

  return mapping[statusGroup]
}

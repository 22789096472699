import React, { Component } from 'react'
import { number, func } from 'prop-types'
import { omit, debounce, isEqual } from 'lodash'

const debouncedPropsHoc = ChildComponent =>
  class extends Component {
    static propTypes = {
      children: func,
      wait: number,
    }

    static displayName = `DebouncedPropsHoc(${
      ChildComponent.displayName || ChildComponent.name
    })`

    static defaultProps = {
      wait: 250,
    }
    constructor(props) {
      super(props)
      this.debounceState = debounce(newState => {
        this.setState(() => newState)
      }, props.wait)

      this.state = omit(props, ['children', 'wait'])
    }

    componentDidUpdate() {
      const propsToDebounce = omit(this.props, ['children', 'wait'])

      if (!isEqual(propsToDebounce, this.state)) {
        this.debounceState(propsToDebounce)
      }
    }

    componentWillUnmount() {
      this.debounceState.cancel()
    }

    render() {
      return (
        <ChildComponent {...this.state}>{this.props.children}</ChildComponent>
      )
    }
  }

export default debouncedPropsHoc

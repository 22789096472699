export enum OrderStatus {
  AWAITING_FRIENDLY_ORDER_NUMBER_ASSIGNMENT = 'AWAITING_FRIENDLY_ORDER_NUMBER_ASSIGNMENT',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  AUTH_ACTION_PENDING = 'AUTH_ACTION_PENDING',
  AUTH_ACTION_FAILURE = 'AUTH_ACTION_FAILURE',
  AWAITING_DELIVERY_NETWORK = 'AWAITING_DELIVERY_NETWORK',
  DELIVERY_NETWORK_FAILURE = 'DELIVERY_NETWORK_FAILURE',
  PREPARING = 'PREPARING',
  READY = 'READY',
  COMPLETE = 'COMPLETE',
  DELIVERY_FAILURE_REFUND = 'DELIVERY_FAILURE_REFUND',
  ORDER_FAILURE_REFUND = 'ORDER_FAILURE_REFUND',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  REFUND_CANCELLED = 'REFUND_CANCELLED',
  REFUND_RELEASED = 'REFUND_RELEASED',
  REFUND_FAILED = 'REFUND_FAILED"',
}

export enum TemplateTypesEnum {
  REGISTRATION = 'REGISTRATION',
  RESET = 'RESET',
  FAIL = 'FAIL',
  ACCEPT_COLLECTION = 'ACCEPT_COLLECTION',
  ACCEPT_DELIVERY = 'ACCEPT_DELIVERY',
  ACCEPT_TABLE = 'ACCEPT_TABLE',
  REJECT = 'REJECT',
  CANCEL = 'CANCEL',
  READY_COLLECTION = 'READY_COLLECTION',
  READY_DELIVERY = 'READY_DELIVERY',
  COMPLETE = 'COMPLETE',
  UPDATE_TIME = 'UPDATE_TIME',
  INVITE_TO_DISCOUNT = 'INVITE_TO_DISCOUNT',
}

export enum OrderFulfillmentMethods {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  NETWORK = 'NETWORK',
  TABLE = 'TABLE',
  COURIER = 'COURIER',
}

export enum FulfilmentMethodInputTypeShared {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  FIXED_ZONE_DELIVERY = 'FIXED_ZONE_DELIVERY',
  TABLE = 'TABLE',
}

export enum NarrowFulfilmentMethodInputTypeShared {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  TABLE = 'TABLE',
}

export enum WayboxJobStatuses {
  PENDING = 'PENDING',
  ASSIGNED = 'ASSIGNED',
  TRAVELLING_TO_PICKUP = 'TRAVELLING_TO_PICKUP',
  ALMOST_AT_PICKUP = 'ALMOST_AT_PICKUP',
  WAITING_AT_PICKUP = 'WAITING_AT_PICKUP',
  TRAVELLING_TO_DROP_OFF = 'TRAVELLING_TO_DROP_OFF',
  WAITING_AT_DROP_OFF = 'WAITING_AT_DROP_OFF',
  ALMOST_AT_DROP_OFF = 'ALMOST_AT_DROP_OFF',
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
}

export enum Comparator {
  IS = 'IS',
  IS_NOT = 'IS_NOT',
}

export enum SegmentOrderBy {
  DISTANCE = 'DISTANCE',
  TIME = 'TIME',
  MIN_ORDER = 'MIN_ORDER',
  ALPHABETICAL = 'ALPHABETICAL',
  RANDOM = 'RANDOM',
  POSITION = 'POSITION',
}

export enum MerchantType {
  RESTAURANT = 'RESTAURANT',
  RETAIL = 'RETAIL',
}

export enum BillingProductType {
  WAYBOX = 'WAYBOX',
  PAYBOX = 'PAYBOX',
  API = 'API',
  MARKETING = 'MARKETING',
}

// Describes the type of stripe action being performed ange gets the account permissions level.
export enum StripeAction {
  Payments = 'PAYMENTS', // All payments Uses the platform (redbox) stripe account
  Connect = 'CONNECT', // Higher auth level - Uses the paybox account or enterprise partner account to create customers or transfers outside of the platform account.
}

// Describes the current payment flow
// (eg, a marketplace might be on StripeAccountType.ENTERPRISE, but while their outlets are onboarding they might be on StripePaymentFlow.STANDARD)
export enum StripePaymentFlow {
  Standard = 'STANDARD', // Processes payments through the account connected to the marketplace
  Paybox = 'PAYBOX', // Processes payments through the paybox account
}

// Describes which setup the outlets are onboarding to
export enum StripeAccountType {
  Standard = 'STANDARD', // STRIPE STANDARD - Default Mode - Legacy manual payments and not automatic transfers.
  Paybox = 'PAYBOX', // STRIPE EXPRESS - Uses Paybox account, automated transfers
  Enterprise = 'ENTERPRISE', // STRIPE EXPRESS - Uses 3rd party stripe account, automated transfers, application fee taken by platform.
}

export enum OpenStatusFilterValues {
  ALL = 'ALL',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum BusinessSegmentType {
  OUTLETS = 'OUTLETS',
  CATEGORIES = 'CATEGORIES',
}

export enum SimplePaymentMethod {
  CARD = 'CARD',
  CASH = 'CASH',
}

export enum BaseTransactionType {
  PAYMENT_STRIPE = 'PAYMENT_STRIPE',
  PAYMENT_STRIPE_ACTION_REQUIRED = 'PAYMENT_STRIPE_ACTION_REQUIRED',
  PAYMENT_CASH = 'PAYMENT_CASH',
  PAYMENT_CASH_ACTION_REQUIRED = 'PAYMENT_CASH_ACTION_REQUIRED',
  REFUND = 'REFUND',
}
export enum TransactionType {
  PAYMENT_STRIPE = 'PAYMENT_STRIPE',
  PAYMENT_STRIPE_FAILED = 'PAYMENT_STRIPE_FAILED',
  PAYMENT_STRIPE_ACTION_REQUIRED = 'PAYMENT_STRIPE_ACTION_REQUIRED',
  PAYMENT_CASH = 'PAYMENT_CASH',
  PAYMENT_CASH_ACTION_REQUIRED = 'PAYMENT_CASH_ACTION_REQUIRED',
  REFUND = 'REFUND',
  FEE = 'FEE',
  PAYBOX_STANDARD_PAYMENT_STRIPE = 'PAYBOX_STANDARD_PAYMENT_STRIPE',
  PAYBOX_STANDARD_PAYMENT_STRIPE_ACTION_REQUIRED = 'PAYBOX_STANDARD_PAYMENT_STRIPE_ACTION_REQUIRED',
  PAYBOX_STANDARD_REFUND = 'PAYBOX_STANDARD_REFUND',
  PAYBOX_ENTERPRISE_PAYMENT_STRIPE = 'PAYBOX_ENTERPRISE_PAYMENT_STRIPE',
  PAYBOX_ENTERPRISE_PAYMENT_STRIPE_ACTION_REQUIRED = 'PAYBOX_ENTERPRISE_PAYMENT_STRIPE_ACTION_REQUIRED',
  PAYBOX_ENTERPRISE_PAYMENT_CASH = 'PAYBOX_ENTERPRISE_PAYMENT_CASH',
  PAYBOX_ENTERPRISE_PAYMENT_CASH_ACTION_REQUIRED = 'PAYBOX_ENTERPRISE_PAYMENT_CASH_ACTION_REQUIRED',
  PAYBOX_ENTERPRISE_REFUND = 'PAYBOX_ENTERPRISE_REFUND',
}

export enum ReconciliationTransactionType {
  ON_TRANSACTION_CREATE = 'ON_TRANSACTION_CREATE',
  ON_TRANSACTION_REFUND = 'ON_TRANSACTION_REFUND',
}

import { Card } from '@blueprintjs/core'
import { sanitizedHtmlWithoutSubstitute as sanitizedHtmlWithoutSubstitute } from '@components/EmailLivePreview/HTMLEmailPreview'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import { last } from 'lodash'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { Mutation } from 'react-apollo'
import SEND_MESSAGE_REPLY from './mutations/sendMessageReply.mutation'
import { ReplyMessageForm } from './ReplyMessageForm'
import { replyMessageValidation } from './validation'

export const SendMessageReply = ({ messages, customersList, isInbox }) => {
  const otherMessages = messages.slice(0, messages.length - 1)
  const lastMessage = last(messages)
  const [openMessages, setOpenMessages] = useState([3, 4])
  const [emailSent, setEmailSent] = useState(false)

  return (
    <Mutation
      mutation={SEND_MESSAGE_REPLY}
      onError={defaultErrorHandler}
      onCompleted={() => {
        successToast('Message was successfully sent.')
        setEmailSent(true)
      }}
    >
      {sendReply => {
        return (
          <div style={{ overflowY: 'scroll', backgroundColor: '#c8c8c8' }}>
            {otherMessages.length ? (
              <Fragment>
                {otherMessages.map(message => (
                  <Fragment key={`fragment-${message.id}`}>
                    <div
                      onClick={_ => {
                        const updatedOpenMessages =
                          openMessages.find(msg => msg === message.id) ===
                          undefined
                            ? [...openMessages, message.id]
                            : openMessages.filter(msgId => msgId !== message.id)

                        setOpenMessages(updatedOpenMessages)
                      }}
                    >
                      <Card
                        style={{
                          paddingBottom: '10px',
                        }}
                      >
                        <Fragment>
                          <MessageInfo message={message} />
                          {sanitizedHtmlWithoutSubstitute(
                            message.html && message.html !== '0'
                              ? message.html
                              : message.textAsHtml.replace(/\n/g, '<br />')
                          )}
                        </Fragment>
                      </Card>
                    </div>
                  </Fragment>
                ))}
              </Fragment>
            ) : null}

            <Formik
              initialValues={{
                content: '',
                subject: `RE:${lastMessage.subject}`,
                addMarketplaceTheme: false,
              }}
              onSubmit={values => {
                sendReply({
                  variables: {
                    content: values.content,
                    subject: values.subject,
                    messageId: lastMessage.id,
                    marketplaceId: lastMessage.marketplace.id,
                    addMarketplaceTheme: values.addMarketplaceTheme,
                  },
                })
              }}
              validationSchema={replyMessageValidation}
            >
              {props => (
                <Fragment>
                  <Card>
                    <MessageInfo message={lastMessage} />
                    {sanitizedHtmlWithoutSubstitute(
                      lastMessage.html && lastMessage.html !== '0'
                        ? lastMessage.html
                        : lastMessage.textAsHtml.replace(/\n/g, '<br />')
                    )}
                  </Card>
                  <ReplyMessageForm
                    {...props}
                    values={lastMessage}
                    customersList={customersList}
                    emailSent={emailSent}
                    isInbox={isInbox}
                  />
                </Fragment>
              )}
            </Formik>
          </div>
        )
      }}
    </Mutation>
  )
}

const MessageInfo = ({ message }) => {
  return (
    <Fragment>
      <div style={{ width: '100%' }}>
        <span> Date: </span>
        <strong>{moment(message.date).format('HH:mm DD/MM/YY')}</strong>
      </div>
      <div>
        <span>
          <span> To: </span>
          <strong>{message.to.map(email => `${email} `)}</strong>
        </span>
      </div>
      <div>
        <span>From: </span>
        <strong>{message.from}</strong>
      </div>
      <div style={{ marginBottom: '20px' }}>
        Subject: <strong>{message.subject}</strong>
      </div>
    </Fragment>
  )
}

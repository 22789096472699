import React, { useContext, useState } from 'react'
import {
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core'
import { Mutation } from 'react-apollo'
import { string, bool, array, number } from 'prop-types'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import EDIT_MENUS_ORDER from '@components/Restaurant/Menu/mutations/editMenusOrder.mutation'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import ARCHIVE_MENU_ITEMS_GROUP from '@components/Restaurant/Menu/mutations/archiveMenuItemsGroup.mutation'
import GET_PARENT_MENUS from '@components/Restaurant/Menu/queries/getParentMenus.query'
import GET_OUTLET_PARENT_MENUS from '@components/Restaurant/Menu/queries/getOutletParentMenus.query'
import GET_SUB_MENUS from '@components/Restaurant/Menu/queries/getSubMenus.query'
import GET_OUTLET_SUB_MENUS from '@components/Restaurant/Menu/queries/getOutletSubMenus.query'
import EDIT_MENU_ITEMS_GROUP_FULFILLMENT_METHODS from '@components/Restaurant/Menu/mutations/editMenuItemsGroupFulfillmentMethods.mutation'
import { setValue, toggleModal, togglePane } from '@utils/cacheHelpers'
import { successToast } from '@utils/toast'
import MoveUpDown from '@components/MoveUpDown/MoveUpDown'
import { MenuListContext } from '@components/Restaurant/Menu/MenuList/menu-list-context'

const SubMenu = ({
  id,
  menuId,
  queryId,
  outlet,
  parentMenu,
  firstSub,
  menus,
  index,
  showMenuItem,
  noSort,
}) => {
  const { refetchMenuList } = useContext(MenuListContext)
  const [menu, setMenu] = useState(menus[index])
  // update the list of FulfillmentMethods to include / remove a specific method
  const updateFulfillmentMethods = methodToToggle => {
    const newMenu = menu.fulfillmentMethods.includes(methodToToggle)
      ? {
          ...menu,
          fulfillmentMethods: menu.fulfillmentMethods.filter(
            method => method !== methodToToggle
          ),
        }
      : {
          ...menu,
          fulfillmentMethods: [...menu.fulfillmentMethods, methodToToggle],
        }
    setMenu(newMenu)
    return newMenu.fulfillmentMethods
  }

  return (
    <Menu>
      {parentMenu && (
        <MenuItem
          text={
            <Popover
              interactionKind={PopoverInteractionKind.HOVER}
              popoverClassName="bp3-popover-content-sizing"
              position={Position.LEFT}
              disabled={!menu.menuItems.length}
              content={
                <p>
                  You can't create a submenu because this menu already has items
                  on the same level.{' '}
                  <a
                    href="https://support.redbox.systems/docs/menus"
                    style={{ color: '#147DBD' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn about menu structure
                  </a>
                  .
                </p>
              }
            >
              Add Sub Menu
            </Popover>
          }
          icon="plus"
          disabled={menu.menuItems.length > 0}
          onClick={() => {
            setValue({
              parentMenuId: id,
            })
            toggleModal({ subMenuModal: true })
          }}
        />
      )}
      {showMenuItem && (
        <MenuItem
          text="Add Menu Item"
          icon="plus"
          disabled={menu.childrenMenus.length > 0}
          onClick={() => {
            setValue({
              parentMenuId: id,
              menuId: menuId,
            })
            toggleModal({ itemModal: true })
          }}
        />
      )}
      <MenuItem
        text="Edit / Schedule"
        icon="edit"
        onClick={() => {
          setValue({
            editedMenu: id,
          })
          toggleModal({ editMenuModal: true })
        }}
      />
      <Mutation
        mutation={EDIT_MENU_ITEMS_GROUP_FULFILLMENT_METHODS}
        onCompleted={({
          editMenuItemsGroupFulfillmentMethods: menuItemGroup,
        }) => {
          successToast(`${menuItemGroup.name} Menu Fulfillment Updated`)
          refetchMenuList()
        }}
      >
        {editMenuItemsGroupFulfillmentMethods => (
          <MenuItem text="Fulfillment" icon="unknown-vehicle">
            <MenuItem
              text="Collection"
              shouldDismissPopover={false}
              icon={
                menu.fulfillmentMethods.includes('COLLECTION')
                  ? 'tick'
                  : 'blank'
              }
              onClick={() => {
                editMenuItemsGroupFulfillmentMethods({
                  variables: {
                    id,
                    fulfillmentMethods: updateFulfillmentMethods('COLLECTION'),
                  },
                })
              }}
            />
            <MenuItem
              text="Delivery"
              shouldDismissPopover={false}
              icon={
                menu.fulfillmentMethods.includes('DELIVERY') ? 'tick' : 'blank'
              }
              onClick={() => {
                editMenuItemsGroupFulfillmentMethods({
                  variables: {
                    id,
                    fulfillmentMethods: updateFulfillmentMethods('DELIVERY'),
                  },
                })
              }}
            />
            <MenuItem
              text="Table"
              shouldDismissPopover={false}
              icon={
                menu.fulfillmentMethods.includes('TABLE') ? 'tick' : 'blank'
              }
              onClick={() => {
                editMenuItemsGroupFulfillmentMethods({
                  variables: {
                    id,
                    fulfillmentMethods: updateFulfillmentMethods('TABLE'),
                  },
                })
              }}
            />
          </MenuItem>
        )}
      </Mutation>

      <Mutation
        mutation={ARCHIVE_MENU_ITEMS_GROUP}
        variables={{ id }}
        refetchQueries={
          parentMenu
            ? [
                {
                  query: outlet ? GET_OUTLET_PARENT_MENUS : GET_PARENT_MENUS,
                  variables: { id: queryId },
                },
              ]
            : [
                {
                  query: GET_SUB_MENUS,
                  variables: { id: queryId, menuId },
                },
              ]
        }
        onError={defaultErrorHandler}
        onCompleted={({ archiveMenuItemsGroup }) => {
          refetchMenuList({ id: queryId })

          successToast(archiveMenuItemsGroup.message)
          togglePane({
            itemMenuOpen: false,
            itemDetailsOpen: false,
          })
          let paneNumber = 2
          if (parentMenu) {
            paneNumber = 0
          }
          if (firstSub) {
            paneNumber = 1
          }
          setValue({
            paneNumber,
          })
        }}
      >
        {archiveMenuItemsGroup => (
          <ConfirmationPopover
            confirmationText="Are you sure you want to delete this menu?"
            remove={() =>
              archiveMenuItemsGroup({
                variables: {
                  id,
                },
              })
            }
          >
            <MenuItem text="Delete" icon="trash" shouldDismissPopover={false} />
          </ConfirmationPopover>
        )}
      </Mutation>

      {!noSort && (
        <MoveUpDown
          mutationToExecute={EDIT_MENUS_ORDER}
          refetchQueries={
            parentMenu
              ? [
                  {
                    query: outlet ? GET_OUTLET_PARENT_MENUS : GET_PARENT_MENUS,
                    variables: { id: queryId },
                  },
                ]
              : [
                  {
                    query: outlet ? GET_OUTLET_SUB_MENUS : GET_SUB_MENUS,
                    variables: {
                      id: queryId,
                      menuId,
                    },
                  },
                ]
          }
          index={index}
          initialList={menus}
        />
      )}
    </Menu>
  )
}

SubMenu.propTypes = {
  id: string,
  menuId: string,
  queryId: string.isRequired,
  parentMenu: bool,
  firstSub: bool,
  menus: array,
  index: number,
  outlet: bool,
}

export default SubMenu

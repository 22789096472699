import React, { Fragment } from 'react'
import times from 'lodash/times'
import ReactSVG from 'react-svg'
import star from '@assets/stars/star.svg'
import halfStar from '@assets/stars/star-half.svg'
import emptyStar from '@assets/stars/star-empty.svg'
import injectSheet from 'react-jss'
import { object, string, number } from 'prop-types'


const style = () => ({
  svgStar: {
    width: '1rem',
    marginRight: '2px'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  starsInRow: {
    display: 'flex',
    flexDirection: 'row'
  }
})

const StarsRating = ({classes, title, starsNumber, maxStarsNumber = 6}) => {

  // full stars
  const nmbFullStars = Math.floor(starsNumber / 1)

  // half stars
  let nmbHalfStars = 0
  const remainder = starsNumber % 1
  if(remainder > 0.2 && remainder <= 0.7) { // convert .4 into one half a star
    nmbHalfStars = 1
  } else if(remainder > 0.7) { // convert .9 into a full star
    nmbFullStars += 1
  }

  // empty stars
  const nmbEmptyStars = maxStarsNumber - nmbFullStars - nmbHalfStars

  return (
    <div className={classes.container}>
      {title}
      <div className={classes.starsInRow}>
      {times(nmbFullStars, (index) => (
        <ReactSVG key={index} className={classes.svgStar} src={star} />
      ))}
      {times(nmbHalfStars, (index) => (
        <ReactSVG key={index} className={classes.svgStar} src={halfStar} />
      ))}
      {times(nmbEmptyStars, (index) => (
        <ReactSVG key={index} className={classes.svgStar} src={emptyStar} />
      ))}
      </div>
    </div>
  )
}

StarsRating.propTypes = {
  classes: object,
  title: string,
  starsNumber: number,
  maxStarsNumber: number
}

export default injectSheet(style)(StarsRating)

import React, { Fragment } from 'react'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import {
  Card,
  Intent,
  Switch,
  Callout,
  FormGroup,
  Button,
  TextArea,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import EDIT_OUTLET_ENABLE_ORDER_TO_TABLE from './mutations/editOutletEnableOrderToTable.mutation'
import GET_OUTLET_TABLES from './queries/getOutletTables.query'
import * as yup from 'yup'
import { bool, shape, string } from 'prop-types'
import { isPlatformLevelUser } from '@stores/userStore'

const validationSchema = {
  isOrderToTableEnabled: yup.boolean().required(),
  isPendingTableNumbersEnabled: yup.boolean().required(),
  pendingTableNumbersCustomText: yup
    .string()
    .nullable()
    .test({
      name: 'pendingTableNumbersCustomText',
      message: 'Custom text is required when pending table numbers are enabled',
      test: function (value) {
        return !this.parent.isPendingTableNumbersEnabled || !!value
      },
    }),
}

const EnableOrderToTableForm = ({ outlet }) => (
  <Mutation
    mutation={EDIT_OUTLET_ENABLE_ORDER_TO_TABLE}
    onError={defaultErrorHandler}
    onCompleted={() => successToast('Order to table settings updated')}
    refetchQueries={[
      {
        query: GET_OUTLET_TABLES,
        variables: {
          outletId: outlet.id,
        },
      },
    ]}
  >
    {enableOrderToTable => (
      <Formik
        initialValues={{
          outletId: outlet.id,
          isOrderToTableEnabled: outlet.isOrderToTableEnabled,
          isPendingTableNumbersEnabled: outlet.isPendingTableNumbersEnabled,
          pendingTableNumbersCustomText: outlet.pendingTableNumbersCustomText,
        }}
        onSubmit={async values => {
          await enableOrderToTable({
            variables: {
              outletId: values.outletId,
              isOrderToTableEnabled: values.isOrderToTableEnabled,
              isPendingTableNumbersEnabled: values.isPendingTableNumbersEnabled,
              pendingTableNumbersCustomText:
                values.pendingTableNumbersCustomText,
            },
          })
        }}
        validationSchema={yup.object().shape(validationSchema)}
      >
        {({ values, handleChange, handleSubmit, errors }) => (
          <Card className="bp3-layout-col">
            <h4 className="bp3-heading">Table / Room Ordering</h4>

            {!values.isOrderToTableEnabled && (
              <Fragment>
                <Callout intent={Intent.WARNING}>
                  This feature has not been enabled, this outlet will not allow
                  ordering to table / room.
                </Callout>
                <br />
              </Fragment>
            )}

            <Switch
              label="Enable"
              checked={values.isOrderToTableEnabled}
              onChange={handleChange}
              name="isOrderToTableEnabled"
            />

            {values.isOrderToTableEnabled && isPlatformLevelUser() && (
              <Fragment>
                <h5 className="bp3-heading">Pending Table Numbers</h5>
                <p>
                  Allow customers to place orders without selecting a table
                  number. A number will be required on order acceptance and can
                  be used with a table buzzer/pager system.
                </p>

                <Switch
                  label="Enable pending table numbers"
                  checked={values.isPendingTableNumbersEnabled}
                  onChange={handleChange}
                  name="isPendingTableNumbersEnabled"
                />

                {values.isPendingTableNumbersEnabled && (
                  <FormGroup
                    label="Custom text"
                    labelFor="pendingTableNumbersCustomText"
                    labelInfo="(required)"
                    required
                    intent={
                      errors.pendingTableNumbersCustomText
                        ? Intent.DANGER
                        : Intent.DEFAULT
                    }
                    helperText={
                      errors.pendingTableNumbersCustomText
                        ? 'Custom text is required'
                        : 'Shown on checkout in place of table picker'
                    }
                  >
                    <TextArea
                      id="pendingTableNumbersCustomText"
                      name="pendingTableNumbersCustomText"
                      placeholder="Enter custom text"
                      value={values.pendingTableNumbersCustomText}
                      onChange={handleChange}
                      style={{ width: '100%' }}
                    />
                  </FormGroup>
                )}
              </Fragment>
            )}

            <div className="bp-card-footer-actions">
              <Button text="Save" onClick={handleSubmit} />
            </div>
          </Card>
        )}
      </Formik>
    )}
  </Mutation>
)

EnableOrderToTableForm.propTypes = {
  outlet: shape({
    id: string.isRequired,
    isOrderToTableEnabled: bool.isRequired,
  }),
}

export default EnableOrderToTableForm

import 'setimmediate' // polyfill for generators
import React from 'react'
import { Router, Redirect, Route as ReactRouterRoute } from 'react-router'

import { Switch } from 'react-router-dom'
import { ThemeProvider } from 'react-jss'
import injectSheet from 'react-jss'
import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/table/lib/css/table.css'

import { ApolloProvider } from 'react-apollo'
import { client } from '@services/client'
import { FocusStyleManager } from '@blueprintjs/core'

import Login from '@components/Login/Login'
import TwoFactorAuth from '@components/TwoFactorAuth/TwoFactorAuth'
import ValidatePhone from '@components/ValidatePhone/ValidatePhone'
import Register from '@components/Register/Register'
import BlankPage from '@components/BlankPage/BlankPage'
import Dashboard from '@components/Dashboard/Dashboard'
import UsersPage from '@components/Users/UsersPage'

import Platform from '@components/Platform/Platform'
import Partners from '@components/Partners/Partners'
import Partner from '@components/Partner/Partner'
import Marketplaces from '@components/Marketplaces/Marketplaces'
import Marketplace from '@components/Marketplace/Marketplace'
import Restaurants from '@components/Restaurants/Restaurants'
import Restaurant from '@components/Restaurant/Restaurant'
import Orders from '@components/Orders/Orders'
import VirtualTerminal from '@components/VirtualTerminal/Orders'
import Customers from '@components/Customers/Customers'
import Customer from '@components/Customer/Customer'
import Reports from '@components/Reports/Reports'
import Outlet from '@components/Outlet/Outlet'
import Terminal from '@components/Terminal/Terminal'
import DeliveryZone from '@components/DeliveryZone/DeliveryZone'
import Sales from '@components/Reports/Sale/Sales'
import Taxes from '@components/Reports/Taxes/Taxes'
import DAC7 from '@components/Reports/Compliance/DAC7'
import Revenue from '@components/Reports/Revenue/Revenue'
import Payout from '@components/Reports/Payout/Payout'
import Item from '@components/Reports/Item/Items'
import Marketing from '@components/Marketing/Marketing'
import Onboarding from '@components/Onboarding/Onboarding'
import Permissions from '@components/Permissions/Permissions'

import theme from '@styles/theme'
import global from '@styles/global'
import { history } from '@stores/routerStore'
import {
  jwtLogin,
  canView,
  isOutletUser,
  isPlatformLevelUser,
  isOutletFinancialUser,
  isMarketingUser,
} from '@stores/userStore'
import Route from '@ui/Route/Route'
import productDictionary from '@utils/productDictionary'

import {
  PageLayout,
  PageLayoutContextProvider,
} from '@components/PageLayout/PageLayout'
import Billing from '@components/Reports/Billing/Billing'
import Messaging from '@components/Messaging/Messaging'
import MarketplaceMap from '@components/MarketplaceMap/MarketplaceMap'
import Inbox from '@components/Messaging/Inbox'
import Sent from '@components/Messaging/Sent'
import { isPeakTime } from '@utils/isPeakTime'
import { QueryParamProvider } from 'use-query-params'

// gets JWT from local storage
jwtLogin()

const restrictReports =
  isPeakTime() &&
  process.env.ENVIRONMENT_NAME === 'PROD' &&
  !isPlatformLevelUser()

// focus styles are hidden for users interacting with a mouse, but will appear if they use tab
FocusStyleManager.onlyShowFocusOnTabs()

const App = () => {
  const getHomeRouteByRole = () => {
    if (canView('reports') && !isOutletFinancialUser()) return '/dashboard/day'
    if (isOutletUser() || isOutletFinancialUser()) return '/outlets'
    if (isMarketingUser()) return '/marketing'

    return '/business'
  }

  return (
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={ReactRouterRoute}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/2fa" component={TwoFactorAuth} />
              <Route path="/validate-phone" component={ValidatePhone} />
              <Route path="/register" component={Register} />
              <Route path="/onboarding" component={Onboarding} />

              <PageLayoutContextProvider>
                <PageLayout>
                  <Switch>
                    <Redirect from="/admin" to="/admin/users" exact={true} />
                    <Route path="/admin/users" component={UsersPage} />
                    <Route
                      path="/admin/permissions"
                      component={Permissions}
                      isPermissionAllowed={isPlatformLevelUser}
                    />
                    <Route
                      path="/dashboard/:period"
                      component={Dashboard}
                      product={productDictionary.DASHBOARD}
                    />
                    <Route
                      path="/dashboard"
                      exact={true}
                      component={() => <Redirect to="/dashboard/day" />}
                      isPermissionAllowed={() => canView('restaurants')}
                    />
                    <Route
                      path="/platform"
                      component={Platform}
                      product={productDictionary.PLATFORM}
                      isPermissionAllowed={() => canView('platform')}
                    />
                    <Route
                      path="/partners"
                      component={Partners}
                      exact={true}
                      product={productDictionary.PARTNER}
                      isPermissionAllowed={() => canView('partners')}
                    />
                    <Route
                      path="/partners/:partner"
                      component={Partner}
                      product={productDictionary.PARTNER}
                      isPermissionAllowed={() => canView('partners')}
                    />
                    <Route
                      path="/marketplaces"
                      component={Marketplaces}
                      exact={true}
                      product={productDictionary.MARKETPLACE}
                      isPermissionAllowed={() => canView('marketplaces')}
                    />
                    <Route
                      path="/marketplaces/map"
                      component={MarketplaceMap}
                      exact={true}
                      product={productDictionary.MARKETPLACE}
                      isPermissionAllowed={() => canView('marketplaces')}
                    />
                    <Route
                      path="/messaging/inbox/:marketplaceId"
                      component={Inbox}
                      product={productDictionary.MESSAGING}
                      isPermissionAllowed={() => canView('messaging')}
                    />
                    <Route
                      path="/messaging/sent/:marketplaceId"
                      component={Sent}
                      product={productDictionary.MESSAGING}
                      isPermissionAllowed={() => canView('messaging')}
                    />
                    <Route
                      path="/messaging"
                      component={Messaging}
                      product={productDictionary.MESSAGING}
                      isPermissionAllowed={() => canView('messaging')}
                    />
                    <Route
                      path="/marketplaces/:marketplace/delivery-zones/:deliveryZoneId"
                      component={DeliveryZone}
                      product={productDictionary.DELIVERYZONE}
                      isPermissionAllowed={() => canView('marketplaces')}
                    />
                    <Route
                      path="/marketplaces/:marketplace"
                      component={Marketplace}
                      product={productDictionary.MARKETPLACE}
                      isPermissionAllowed={() => canView('marketplaces')}
                    />
                    <Route
                      path="/orders/:statusFilter"
                      component={Orders}
                      product={productDictionary.ORDER}
                    />
                    <Route
                      path="/orders"
                      product={productDictionary.ORDER}
                      component={Orders}
                      exact={true}
                    />
                    <Route
                      path="/virtual-terminal/:statusFilter"
                      component={VirtualTerminal}
                      product={productDictionary.VIRTUALTERMINAL}
                      isPermissionAllowed={() => canView('virtualTerminals')}
                    />
                    <Redirect
                      from="/virtual-terminal"
                      to="/virtual-terminal/pending"
                      exact={true}
                    />

                    <Route
                      path="/customers"
                      component={Customers}
                      product={productDictionary.CUSTOMERS}
                      isPermissionAllowed={() => canView('customers')}
                    />
                    <Redirect from="/customer" to="/customers" exact={true} />
                    <Route
                      path="/customer/:customer"
                      component={Customer}
                      product={productDictionary.CUSTOMER}
                      isPermissionAllowed={() => canView('customers')}
                    />
                    <Route
                      path="/reports/products"
                      exact
                      component={Item}
                      product={productDictionary.REPORTS}
                      isPermissionAllowed={() => canView('sales')}
                      restrictReports={restrictReports}
                    />
                    <Route
                      path="/reports/sales"
                      exact
                      component={Sales}
                      product={productDictionary.REPORTS}
                      isPermissionAllowed={() => canView('sales')}
                      restrictReports={restrictReports}
                    />
                    <Route
                      path="/reports/taxes"
                      exact
                      component={Taxes}
                      product={productDictionary.REPORTS}
                      isPermissionAllowed={() => canView('sales')}
                      restrictReports={restrictReports}
                    />
                    <Route
                      path="/reports/revenue"
                      exact
                      component={Revenue}
                      product={productDictionary.REPORTS}
                      isPermissionAllowed={() => canView('reports')}
                      restrictReports={restrictReports}
                    />
                    <Route
                      path="/reports/payments"
                      exact
                      component={Payout}
                      product={productDictionary.REPORTS}
                      isPermissionAllowed={() => canView('payouts')}
                      restrictReports={restrictReports}
                    />
                    <Route
                      path="/reports/invoicing"
                      exact
                      component={Billing}
                      product={productDictionary.REPORTS}
                      isPermissionAllowed={() => canView('billing')}
                      restrictReports={restrictReports}
                    />
                    <Route
                      path="/reports/compliance/dac7"
                      exact
                      component={DAC7}
                      product={productDictionary.REPORTS}
                      restrictReports={restrictReports}
                    />
                    <Route
                      path="/reports"
                      component={Reports}
                      product={productDictionary.REPORTS}
                      isPermissionAllowed={() =>
                        canView('reports') || canView('sales')
                      }
                      restrictReports={restrictReports}
                    />
                    <Route
                      path="/outlets"
                      component={Restaurants}
                      product={productDictionary.RESTAURANTS}
                      exact={true}
                    />
                    <Route
                      path="/terminals"
                      component={Restaurants}
                      product={productDictionary.RESTAURANTS}
                      exact={true}
                    />
                    <Route
                      path="/onboardingList"
                      component={Restaurants}
                      product={productDictionary.RESTAURANTS}
                      exact={true}
                    />
                    <Route
                      path="/business"
                      component={Restaurants}
                      product={productDictionary.RESTAURANTS}
                      exact={true}
                    />
                    <Route
                      path="/business/:restaurants/outlets/:outlet/terminals/:terminal"
                      component={Terminal}
                      product={productDictionary.TERMINAL}
                    />
                    <Route
                      path="/business/:restaurants/outlets/:outlet"
                      component={Outlet}
                      product={productDictionary.OUTLET}
                    />
                    <Route
                      path="/business/:restaurants"
                      component={Restaurant}
                      product={productDictionary.RESTAURANT}
                    />
                    <Route
                      path="/"
                      exact={true}
                      component={() => <Redirect to={getHomeRouteByRole()} />}
                    />
                    <Route
                      path="/marketing"
                      component={Marketing}
                      isPermissionAllowed={() => canView('marketing')}
                      product={productDictionary.MARKETING}
                    />
                    <Route component={BlankPage} />
                  </Switch>
                </PageLayout>
              </PageLayoutContextProvider>
            </Switch>
          </ThemeProvider>
        </ApolloProvider>
      </QueryParamProvider>
    </Router>
  )
}

export default injectSheet(global)(App)

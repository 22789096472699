import React, { Fragment, useState } from 'react'
import { get } from 'lodash'
import { Mutation } from 'react-apollo'
import {
  Card,
  Button,
  Popover,
  Position,
  HTMLTable,
  Switch,
  PopoverInteractionKind,
  NonIdealState,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import Query from '@components/Query/Query'
import GET_MARKETPLACE_FAQS from './queries/getMarketplaceFAQs.query'
import DELETE_FAQ from './mutations/deleteFAQ.mutation'
import EDIT_FAQ from './mutations/editFAQ.mutation'
import FAQDrawer from './FAQDrawer'
import { successToast } from '@utils/toast'

const Support = ({ match }) => {
  const { marketplace: marketplaceId } = match.params
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  return (
    <Fragment>
      <Query
        query={GET_MARKETPLACE_FAQS}
        variables={{ marketplaceId }}
        loaderTitle={'Loading FAQs'}
      >
        {({ getFAQs }) => {
          return getFAQs.length ? (
            <Card className={'bp3-nopad'}>
              <HTMLTable bordered={false} interactive={true}>
                <thead>
                  <tr>
                    <th style={{ width: '90%' }}>FAQs</th>
                    <th>Publish</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {getFAQs.map(faq => (
                    <tr key={faq.id}>
                      <td>
                        <a
                          onClick={() => {
                            setDrawerOpen(true)
                            setSelectedId(faq.id)
                          }}
                        >
                          {faq.question}
                        </a>
                      </td>
                      <td>
                        <Mutation
                          mutation={EDIT_FAQ}
                          onCompleted={({ editFAQ }) => {
                            successToast(
                              `${
                                get(editFAQ, 'published', false)
                                  ? 'Published'
                                  : 'Unpublished'
                              } "${get(editFAQ, 'question', '')}"`
                            )
                          }}
                          onError={defaultErrorHandler}
                        >
                          {editFAQ => (
                            <Switch
                              checked={faq.published}
                              onChange={e => {
                                editFAQ({
                                  variables: {
                                    id: faq.id,
                                    published: e.currentTarget.checked,
                                  },
                                })
                              }}
                            />
                          )}
                        </Mutation>
                      </td>
                      <td className="bp3-action-cell">
                        <Mutation
                          mutation={DELETE_FAQ}
                          onError={defaultErrorHandler}
                          onCompleted={() => successToast('FAQ Deleted')}
                          refetchQueries={[
                            {
                              query: GET_MARKETPLACE_FAQS,
                              variables: { marketplaceId },
                            },
                          ]}
                        >
                          {deleteFAQ => (
                            <Popover
                              interactionKind={PopoverInteractionKind.CLICK}
                              popoverClassName="bp3-popover-content-sizing"
                              position={Position.LEFT}
                            >
                              <Button icon={'trash'} minimal={true} />
                              <div>
                                <h5 className={'bp3-heading'}>Delete FAQ</h5>
                                <p>
                                  Are you sure you want to permanently delete "
                                  {faq.question}"?
                                </p>
                                <div className="bp-popover-footer-actions">
                                  <Button
                                    className={'bp3-button bp3-popover-dismiss'}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className={
                                      'bp3-button bp3-intent-danger bp3-popover-dismiss'
                                    }
                                    onClick={() =>
                                      deleteFAQ({ variables: { id: faq.id } })
                                    }
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </div>
                            </Popover>
                          )}
                        </Mutation>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </HTMLTable>
            </Card>
          ) : (
            <NonIdealState
              icon="th-list"
              title="No Frequently Asked Questions"
              description="FAQ's are visible to your customers for self service support."
              action={
                <Button icon="plus" onClick={() => setDrawerOpen(true)}>
                  Add FAQ
                </Button>
              }
            />
          )
        }}
      </Query>
      <FAQDrawer
        isOpen={drawerOpen}
        selectedId={selectedId}
        marketplaceId={marketplaceId}
        drawerClose={() => {
          setDrawerOpen(false)
          setSelectedId(null)
        }}
      />
    </Fragment>
  )
}

export default Support

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import injectSheet from 'react-jss'
import { object, shape, string } from 'prop-types'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import { canView } from '@stores/userStore'
import { Row, Col } from 'react-simple-flex-grid'
import { H5 } from '@blueprintjs/core'
import { styles } from './DeliveryAddress.styles'

const DeliveryAddress = ({
  classes,
  customerDeliveryAddress,
  customer,
  outlet,
}) => {
  const customerName = `${capitalize(customer.firstName)} ${capitalize(
    customer.lastName
  )}`
  return (
    <Row className={classes.container}>
      <Col xs={12} md={6}>
        <H5>Customer</H5>
        {canView('customers') ? (
          <Link to={`/customer/${customer.id}/details`}>{customerName}</Link>
        ) : (
          customerName
        )}
        <div className={classes.address}>
          {get(customerDeliveryAddress, 'firstLine') ? (
            <Fragment>
              {get(customerDeliveryAddress, 'firstLine')}
              <br />
            </Fragment>
          ) : null}
          {get(customerDeliveryAddress, 'secondLine') ? (
            <Fragment>
              {get(customerDeliveryAddress, 'secondLine')}
              <br />
            </Fragment>
          ) : null}
          {get(customerDeliveryAddress, 'thirdLine') ? (
            <Fragment>
              {get(customerDeliveryAddress, 'thirdLine')}
              <br />
            </Fragment>
          ) : null}
          {get(customerDeliveryAddress, 'city') ? (
            <Fragment>
              {get(customerDeliveryAddress, 'city')}
              <br />
            </Fragment>
          ) : null}
          {get(customerDeliveryAddress, 'postcode') ? (
            <Fragment>
              {get(customerDeliveryAddress, 'postcode')}
              <br />
            </Fragment>
          ) : null}
          Tel: {customer.phoneNumber}
        </div>
      </Col>
      <Col xs={12} md={6}>
        <H5>Business</H5>
        <Link to={`/business/${outlet.restaurant.id}`}>
          {outlet.restaurant.name} - {outlet.name}
        </Link>
        <div className={classes.address}>
          {get(outlet, 'outletAddress.firstLine') ? (
            <Fragment>
              {get(outlet, 'outletAddress.firstLine')}
              <br />
            </Fragment>
          ) : null}
          {get(outlet, 'outletAddress.city') ? (
            <Fragment>
              {get(outlet, 'outletAddress.city')}
              <br />
            </Fragment>
          ) : null}
          {get(outlet, 'outletAddress.postcode') ? (
            <Fragment>
              {get(outlet, 'outletAddress.postcode')}
              <br />
            </Fragment>
          ) : null}
        </div>
      </Col>
    </Row>
  )
}

DeliveryAddress.propTypes = {
  classes: object,
  customerDeliveryAddress: shape({
    city: string,
    firstLine: string,
    secondLine: string,
    thirdLine: string,
    postcode: string,
  }),
  customer: shape({
    firstName: string,
    lastName: string,
    phoneNumber: string,
  }),
  outlet: shape({
    name: string,
    restaurant: shape({
      name,
    }),
    phoneNumber: string,
  }),
}

export default injectSheet(styles)(DeliveryAddress)

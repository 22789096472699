import React, { useRef, useEffect, useContext, Fragment } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import dndHelpers from './dndHelpers'
import ItemTypes from './ItemTypes'
import { MenuListContext } from '@components/Restaurant/Menu/MenuList/menu-list-context'
import { removeExpiredSoldOut } from '@utils/removeExpiredSoldOut'
import { Icon, Tooltip, Position } from '@blueprintjs/core'
import MenuRow from './MenuRow'
import ReactSVG from 'react-svg'

import adultContentDangerImg from '@assets/icons/adult-content-danger.svg'
import dairyFreeImg from '@assets/icons/allergies/dairyfree.png'
import glutenFreeImg from '@assets/icons/allergies/glutenfree.png'
import veganImg from '@assets/icons/allergies/vegan.png'
import vegetarianImg from '@assets/icons/allergies/vegetarian.png'
import { OpenMenuContext } from './OpenMenuTreeProvider'

const MenuItem = ({
  menuItem,
  depth,
  outlet,
  parentMenuId,
  path,
  outletsSoldOut,
  restaurantId,
  menuItemsGroupId,
  outletHidden,
}) => {
  const {
    setDragging,
    setViewMenuItem,
    selectedMenuItemId,
    editMenuOrder,
    saveMenuOrder,
  } = useContext(MenuListContext)
  const ref = useRef(null)

  const { setCurrentMenuGroups } = useContext(OpenMenuContext)

  const [{ canDrop, isSelf, isAbove }, drop] = useDrop({
    accept: ItemTypes.MENU_ITEM,
    hover(item, monitor) {
      dndHelpers.dragHalfway({
        item,
        index: dndHelpers.buildIndexFromPath(path),
        ref,
        monitor,
        callback: (drag, hoverIndex, item) => {
          editMenuOrder({ item, hoverPath: path })
        },
      })
    },
    collect: monitor => {
      const item = monitor.getItem() || {}
      return {
        canDrop: monitor.canDrop(),
        isAbove: dndHelpers.buildIndexFromPath(path) < item.index,
        isSelf: item.id === menuItem.id,
      }
    },
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }
      saveMenuOrder(item, path, parentMenuId)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.MENU_ITEM,
      id: menuItem.id,
      index: dndHelpers.buildIndexFromPath(path),
      menu: menuItem,
      path,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    setDragging(isDragging)
  }, [isDragging, setDragging])

  const opacity = isDragging ? 0.2 : 1

  if (!outlet) {
    drag(drop(ref))
  }

  const soldOutOutlets = removeExpiredSoldOut(outletsSoldOut)

  const soldOut = outlet
    ? menuItem.outletSoldOut
    : soldOutOutlets.includes(menuItem.id)

  const openMenuItemRoute = () => {
    setViewMenuItem({ menuId: parentMenuId, menuItemId: menuItem.id })
    setCurrentMenuGroups([
      menuItemsGroupId,
      ...menuItem.parentMenus.map(({ id }) => id),
    ])
  }

  return (
    <div ref={ref} style={{ opacity }}>
      {outlet ? (
        <MenuRow
          icon={dndHelpers.getDragIcon({
            isDragging,
            isSelf,
            isAbove,
            canDrop,
            defaultIcon: (
              <Tooltip
                content={
                  <div style={{ padding: '10px' }}>
                    {soldOut ? 'Sold out' : 'In Stock'}
                  </div>
                }
                position={Position.TOP_LEFT}
              >
                <Icon
                  icon="dot"
                  color={soldOut ? 'red' : 'grey'}
                  style={{ padding: '2px' }}
                />
              </Tooltip>
            ),
          })}
          label={menuItem.name}
          depth={depth}
          iconColor={soldOut ? 'red' : undefined}
          canDrag={false}
          active={selectedMenuItemId === menuItem.id}
          onClick={openMenuItemRoute}
          hidden={outletHidden}
        />
      ) : (
        <MenuRow
          item={menuItem}
          restaurantId={restaurantId}
          icon={dndHelpers.getDragIcon({
            isDragging,
            isSelf,
            isAbove,
            canDrop,
            defaultIcon: (
              <Fragment>
                <Tooltip
                  openOnTargetFocus={false}
                  content={
                    soldOut
                      ? `Sold out in ${outletsSoldOut.length} outlet${
                          outletsSoldOut.length > 1 ? 's' : ''
                        }`
                      : 'In Stock'
                  }
                  position={Position.TOP_LEFT}
                >
                  <Icon
                    icon="dot"
                    color={soldOut ? 'red' : '#5c7080'}
                    style={{ padding: '2px' }}
                  />
                </Tooltip>
              </Fragment>
            ),
          })}
          label={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', alignContent: 'center' }}>
                <a style={{ paddingRight: '5px' }} onClick={openMenuItemRoute}>
                  {menuItem.name}
                </a>
                {menuItem.ageRestricted && (
                  <Tooltip
                    openOnTargetFocus={false}
                    content="Age Restricted"
                    position={Position.TOP_LEFT}
                  >
                    <ReactSVG src={adultContentDangerImg} />
                  </Tooltip>
                )}
                {menuItem.isVegetarian && (
                  <Tooltip
                    openOnTargetFocus={false}
                    content="Vegetarian"
                    position={Position.TOP_LEFT}
                  >
                    <img src={vegetarianImg} height="20" width="20" />
                  </Tooltip>
                )}
                {menuItem.isVegan && (
                  <Tooltip
                    openOnTargetFocus={false}
                    content={<div>Vegan</div>}
                    position={Position.TOP_LEFT}
                  >
                    <img src={veganImg} height="20" width="20" />
                  </Tooltip>
                )}
                {menuItem.isGlutenFree && (
                  <Tooltip
                    openOnTargetFocus={false}
                    content="Gluten Free"
                    position={Position.TOP_LEFT}
                  >
                    <img src={glutenFreeImg} height="20" width="20" />
                  </Tooltip>
                )}
                {menuItem.isDairyFree && (
                  <Tooltip
                    openOnTargetFocus={false}
                    content="Dairy Free"
                    position={Position.TOP_LEFT}
                  >
                    <img src={dairyFreeImg} height="20" width="20" />
                  </Tooltip>
                )}
              </div>
            </div>
          }
          depth={depth == 2 ? 'deep' : 'shallow'}
          canDrag
          active={selectedMenuItemId === menuItem.id}
        />
      )}
    </div>
  )
}

export default MenuItem

import React, { useEffect, useState } from 'react'
import { Intent, InputGroup } from '@blueprintjs/core'
import uuid from 'uuid'
import { countDecimalPlaces } from '@utils/maths'

const NumericInput = props => {
  const {
    name,
    defaultValue,
    disabled = false,
    intent = Intent.NONE,
    rightElement,
    leftIcon,
    fill = false,
    stepSize = 0.01,
    min = 0,
    max = Infinity,
    children,
    onUpdate = () => {}, // called with the value as a string when the user selects the up / down buttons or types in a number and blurs the input
    onValueChange = () => {},
    onKeyPress = () => {},
    clampOnUpdate = true,
    roundFunction = val => val,
    minimal = false,
    style = {},
  } = props
  // calculate how many decimal places to round to
  const precision =
    props.precision !== undefined
      ? props.precision
      : countDecimalPlaces(stepSize)

  // asserts if element is focussed
  const hasFocus = () => document.activeElement.id === id

  const [value, setValue] = useState(Number(defaultValue).toFixed(precision))
  useEffect(() => {
    onValueChange(value, Number(value))
  }, [value])

  useEffect(() => {
    setValue(Number(defaultValue).toFixed(precision))
  }, [defaultValue])

  // set id so that we can detect if the input is focussed when the onInput event fires
  const id = props.id || name || uuid()

  // clamps number so it is not over/under the min/max values
  const clampValue = value => {
    if (!clampOnUpdate) {
      return value
    }
    let clampedValue = value
    if (clampedValue < min) {
      clampedValue = min
    } else if (clampedValue > max) {
      clampedValue = max
    }
    return clampedValue
  }

  // round, clamp, and trim extra decimal places
  const formatValue = value => {
    const roundedValue = roundFunction(Number(value))
    const clampedRoundedValue = clampValue(roundedValue)
    return clampedRoundedValue.toFixed(precision)
  }

  const handleKeyPress = e => {
    onKeyPress(e)
  }

  const handleBlur = () => {
    const formattedValue = formatValue(value)
    setValue(formattedValue)
    onUpdate(formattedValue)
  }

  const handleInput = e => {
    // format and round if spinner buttons (up / down arrows) selected when input does not have focus
    const { value } = e.target
    const formattedValue = formatValue(value)
    if (hasFocus()) {
      setValue(value)
    } else {
      setValue(formattedValue)
      onUpdate(formattedValue)
    }
  }

  return (
    <div style={{ ...style, display: 'flex' }}>
      <InputGroup
        className={minimal && 'bp3-minimal'}
        fill={fill}
        value={value}
        disabled={disabled}
        name={name}
        id={id}
        intent={intent}
        rightElement={rightElement}
        leftIcon={leftIcon}
        type="number"
        step={stepSize}
        min={min}
        max={max}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        onInput={handleInput}
        onChange={() => {
          // supress blueprint error due to missing onChange, which is unnecessary due to onBlue, onKeyPress, and onInput
        }}
      />
      <div style={{ flex: '1 0 auto' }}>{children}</div>
    </div>
  )
}

export default NumericInput

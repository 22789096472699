import React, { Fragment, useContext } from 'react'
import { Row } from 'react-simple-flex-grid'
import { Card } from '@blueprintjs/core'
import BusinessAnalytics from '../Analytics/BusinessAnalytics'
import Filters from '../Analytics/Filters'
import { BusinessAnalyticsContext } from '../Analytics/BusinessAnalyticsContext'
import moment from 'moment'
import { isEqual } from 'lodash'

const OrdersForInterval = ({ business }) => {
  const {
    setOutlets,
    setSignificantDates,
    setActivationDates,
    activationDates,
  } = useContext(BusinessAnalyticsContext)

  if (business.outlets.length > 0) {
    setOutlets(business.outlets)
  }

  if (business.significantDates && business.significantDates.length > 0) {
    setSignificantDates(business.significantDates)
  }

  // get activation date for outlets
  // !might be null
  if (business.outlets.length > 0) {
    let incomingActivationDates = []
    business.outlets.forEach(outlet => {
      if (outlet.activationDate) {
        incomingActivationDates.push({
          name: `${outlet.name} Activation`,
          formattedDate: moment(outlet.activationDate).format('YYYYMMDD'),
        })
      }
    })

    if (
      incomingActivationDates.length > 0 &&
      !isEqual(activationDates, incomingActivationDates)
    ) {
      setActivationDates(incomingActivationDates)
    }
  }

  const { id } = business

  return (
    <Card className="bp3-nopad">
      {business.outlets.length > 0 && (
        <Fragment>
          <Filters businessId={id} />
          <Row style={{ marginTop: '24px', minHeight: '200' }}>
            <BusinessAnalytics businessId={id} />
          </Row>
        </Fragment>
      )}
    </Card>
  )
}

export default OrdersForInterval

const AWS_CDN_URL =
  process.env.AWS_CDN_URL ||
  'https://s3.eu-west-1.amazonaws.com/redbox-gbp-img-staging/'

import React from 'react'
import { string } from 'prop-types'
import { Tooltip } from '@blueprintjs/core'

const Favicon = ({ src, label }) => {
  return (
    <Tooltip content={label}>
      <span className="bp3-icon">
        <img src={`${AWS_CDN_URL}${src}`} height="16" width="16" />
      </span>
    </Tooltip>
  )
}

Favicon.propTypes = {
  src: string,
  label: string,
}

export default Favicon

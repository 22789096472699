import React, { Fragment } from 'react'
import {
  FormGroup,
  InputGroup,
  Classes,
  Intent,
  HTMLSelect,
  Button,
  ControlGroup,
  Card,
} from '@blueprintjs/core'
import { FieldArray } from 'formik'
import injectSheet from 'react-jss'
import Query from '@components/Query/Query'

import SegmentAttributeFilter from '../../shared/SegmentAttributeFilter'
import { styles } from './InnerForm.styles'
import SegmentEstimate from '../../shared/SegmentEstimate'
import GET_SEGMENT_ATTRIBUTE_FILTERS from '../../queries/getSegmentAttributeFilters.query'

const defaultFilter = {
  attribute: '',
  comparator: '',
  value: '',
}

const defaultFilterGroup = {
  filterGroupType: 'ALL',
  filters: [defaultFilter],
}

const InnerForm = ({
  values,
  errors,
  isValid,
  dirty,
  handleChange,
  handleSubmit,
  classes,
  setFieldValue,
  marketplaceId,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <FormGroup
        label="Name"
        labelInfo="(required)"
        helperText={errors.name}
        intent={Intent.DANGER}
      >
        <InputGroup
          name="name"
          type="text"
          value={values.name}
          onChange={handleChange}
        />
      </FormGroup>
      <Fragment>
        <div>Segment Filters:</div>
        <br />
        <Card>
          <Query
            query={GET_SEGMENT_ATTRIBUTE_FILTERS}
            variables={{ marketplaceId }}
          >
            {({ getSegmentAttributeFilters }) => (
              <FieldArray
                name={'filterGroups'}
                render={filterGroupsArrayHelpers => (
                  <div>
                    {values.filterGroups.map(
                      (filterGroup, filterGroupIndex) => {
                        const isLastFilterGroup =
                          values.filterGroups.length - 1 === filterGroupIndex

                        return (
                          <div
                            key={filterGroupIndex}
                            style={{
                              borderLeft: 'solid 3px #E6ECEF',
                              padding: 6,
                              borderRadius: 0,
                              margin: 0,
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <strong className={classes.segmentGroup}>
                                  Group {filterGroupIndex + 1}
                                </strong>
                                <ControlGroup>
                                  {values.filterGroups.length > 1 && (
                                    <Button
                                      icon="trash"
                                      minimal
                                      onClick={() =>
                                        filterGroupsArrayHelpers.remove(
                                          filterGroupIndex
                                        )
                                      }
                                    />
                                  )}
                                </ControlGroup>
                              </div>
                            </div>

                            <FormGroup>
                              <FieldArray
                                name={`filterGroups[${filterGroupIndex}].filters`}
                                render={arrayHelpers => (
                                  <div>
                                    {filterGroup.filters.map(
                                      (filter, filterIndex) => {
                                        const isLastFiter =
                                          filterGroup.filters.length - 1 ===
                                          filterIndex

                                        return (
                                          <Fragment key={filterIndex}>
                                            <SegmentAttributeFilter
                                              attributes={
                                                getSegmentAttributeFilters.attributes
                                              }
                                              key={filterIndex}
                                              filter={filter}
                                              index={filterIndex}
                                              onChange={handleChange}
                                              arrayHelpers={arrayHelpers}
                                              setFieldValue={setFieldValue}
                                              marketplaceId={marketplaceId}
                                              formNamePrefix={`filterGroups[${filterGroupIndex}].filters[${filterIndex}]`}
                                            />
                                            {isLastFiter ? null : (
                                              <HTMLSelect
                                                minimal
                                                className={
                                                  classes.segmentConditionSelect
                                                }
                                                name={`filterGroups[${filterGroupIndex}].filterGroupType`}
                                                options={[
                                                  {
                                                    value: 'ALL',
                                                    label: 'AND',
                                                  },
                                                  { value: 'ANY', label: 'OR' },
                                                ]}
                                                value={
                                                  filterGroup.filterGroupType
                                                }
                                                onChange={handleChange}
                                              />
                                            )}
                                          </Fragment>
                                        )
                                      }
                                    )}
                                    <Button
                                      style={{
                                        borderLeft: 'solid 3px #1968f6',
                                        padding: 6,
                                        borderRadius: 0,
                                      }}
                                      intent="primary"
                                      icon="plus"
                                      type="button"
                                      minimal
                                      onClick={() =>
                                        arrayHelpers.push(defaultFilter)
                                      }
                                    >
                                      Add Filter Attribute
                                    </Button>
                                  </div>
                                )}
                              />
                            </FormGroup>
                            {isLastFilterGroup ? null : (
                              <p
                                className={classes.segmentConditionIndicator}
                                style={{
                                  marginLeft: '-9px',
                                  borderLeft: 'solid 3px #E6ECEF',
                                }}
                              >
                                {values.filterGroupsType === 'ALL'
                                  ? 'AND'
                                  : 'OR'}
                              </p>
                            )}
                          </div>
                        )
                      }
                    )}
                    <Button
                      style={{
                        borderLeft: 'solid 3px #E6ECEF',
                        padding: 6,
                        borderRadius: 0,
                      }}
                      intent="primary"
                      icon="plus"
                      type="button"
                      minimal
                      onClick={() =>
                        filterGroupsArrayHelpers.push(defaultFilterGroup)
                      }
                    >
                      Add Filter Group
                    </Button>
                  </div>
                )}
              />
            )}
          </Query>
        </Card>
      </Fragment>

      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <SegmentEstimate marketplaceId={marketplaceId} values={values} />{' '}
        <Button
          intent={Intent.DEFAULT}
          text="Save"
          disabled={!isValid && dirty}
          type="button"
          onClick={handleSubmit}
        />
      </div>
    </form>
  )
}

export default injectSheet(styles)(InnerForm)

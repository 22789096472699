const styles = () => ({
  container: {
    display: 'block',
    width: '100%',
    maxWidth: '300px',
    background: '#fff',
    color: '#000',
    padding: '10px',

    fontFamily: 'Roboto Mono, monospace',

    '& h1': {
      fontSize: '16px',
    },
    '& h2': {
      fontSize: '16px',
    },
    '& h3': {
      fontSize: '14px',
    },
    '& h4': {
      fontSize: '12px',
    },
  },
  image: {
    width: '150px',
    maxWidth: '30%',
    marginBottom: '10px',
    filter: 'grayscale(100%)',
  },
  title: {
    lineHeight: 1,
    padding: 0,
    margin: 0,
  },
  section: {
    borderBottom: '1px dashed #000',
    padding: '6px 0',
    pageBreakBefore: 'auto',
    breakInside: 'avoid',

    '&:last-child': {
      borderBottom: 0,
    },
  },
  text: {
    padding: 0,
    margin: 0,
    lineHeight: 1,
    fontSize: '10px',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 300,
  },
  optionItems: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  optionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,
    lineHeight: 1,
    fontSize: '10px',
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  center: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: '500',
  },
})

export default styles

import React, { Fragment } from 'react'
import { Card, Drawer, HTMLTable, NonIdealState, Tag } from '@blueprintjs/core'
import Query from '@components/Query/Query'
import GET_DELIVERY_ZONES from '@components/Marketplace/DeliveryZones/queries/getDeliveryZones.query'
import DeliveryZone from '@components/DeliveryZone/DeliveryZone'
import ls from '@utils/localStorage'
import { useQueryParam, StringParam } from 'use-query-params'

const tableHead = [
  { key: 'name', content: 'Name' },
  { key: 'outlets', content: 'Outlets' },
  { key: 'type', content: 'Type' },
]

const isStuartOrWaybox = deliveryZoneType =>
  ['STUART', 'WAYBOX', 'UBER_DIRECT'].includes(deliveryZoneType)

const DeliveryZones = ({ match }) => {
  const [viewZone, onChangeViewZone] = useQueryParam('viewZone', StringParam)
  const { marketplace: marketplaceId } = match.params
  return (
    <Fragment>
      <Query
        query={GET_DELIVERY_ZONES}
        variables={{ marketplaceId }}
        loaderTitle={'Loading Delivery Zones'}
      >
        {({ getDeliveryZones: { deliveryZones } }) => {
          if (deliveryZones && deliveryZones.length) {
            return (
              <Card className="bp3-nopad bp3-scrollable">
                <HTMLTable bordered={false} interactive={true}>
                  <thead>
                    <tr>
                      {tableHead.map(({ key, content }) => (
                        <th key={key}>{content}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {deliveryZones.map(
                      ({
                        id,
                        name,
                        deliveryZoneType,
                        outletDeliveryZoneCosts,
                      }) => (
                        <tr key={id}>
                          <td
                            className={`bp3-text-overflow-ellipsis ${
                              !isStuartOrWaybox(deliveryZoneType) &&
                              `bp3-${
                                ls.get('darkMode') ? 'dark-' : ''
                              }link-text`
                            }`}
                            onClick={() => {
                              if (!isStuartOrWaybox(deliveryZoneType))
                                onChangeViewZone(id)
                            }}
                          >
                            {name}
                          </td>
                          <td>{outletDeliveryZoneCosts.length}</td>
                          <td>
                            <Tag minimal>
                              {deliveryZoneType
                                .toUpperCase()
                                .replace(/_/g, ' ')}
                            </Tag>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </HTMLTable>
              </Card>
            )
          } else {
            return (
              <NonIdealState
                icon="drive-time"
                title="No Delivery Zones"
                description="Configure delivery areas and charges."
              />
            )
          }
        }}
      </Query>
      <Drawer
        size={Drawer.SIZE_LARGE}
        isOpen={viewZone}
        title="Delivery Zone"
        onClose={() => {
          onChangeViewZone(undefined)
        }}
      >
        <DeliveryZone
          id={viewZone}
          marketplaceId={marketplaceId}
          onChangeViewZone={onChangeViewZone}
        />
      </Drawer>
    </Fragment>
  )
}

export default DeliveryZones

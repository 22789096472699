import React from 'react'
import { Classes, Button, Intent } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { bool, func } from 'prop-types'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { toggleModal } from '@utils/cacheHelpers'
import ADD_PARTNER from './mutations/addPartner'
import GET_PARTNERS from '@components/Partners/queries/getPartners.query'
import PartnerShortForm from '@components/Partner/Details/PartnerForm/PartnerShortForm'
import { validation } from './validation'
import defaultErrorHandler from '@utils/defaultErrorHandler'

const handleMutation = (data, history) => {
  const { id } = data.partner
  history.push(`/partners/${id}`)
  toggleModal({
    partnerModal: false,
  })
}

const PartnerModal = ({ modalOpen, modalClose, history }) => {
  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Add Partner">
      <Mutation
        mutation={ADD_PARTNER}
        onError={defaultErrorHandler}
        onCompleted={({ addPartner }) => handleMutation(addPartner, history)}
        refetchQueries={[{ query: GET_PARTNERS }]}
      >
        {addPartner => (
          <Formik
            onSubmit={values => {
              addPartner({
                variables: {
                  ...values,
                },
              })
            }}
            initialValues={{
              name: '',
              companyLegalName: '',
              companyNumber: '',
              contactAddress: {
                firstLine: '',
                secondLine: '',
                thirdLine: '',
                city: '',
                country: { code: 'GB', name: 'United Kingdom' },
                postcode: '',
              },
              contactPhone: '',
              contactEmail: '',
              contactName: '',
            }}
            validationSchema={validation}
          >
            {props => {
              const { handleSubmit } = props

              return (
                <form onSubmit={handleSubmit}>
                  <div className={Classes.DIALOG_BODY}>
                    <PartnerShortForm
                      disabled={false}
                      editable={false}
                      modal={true}
                      {...props}
                    />
                  </div>
                  <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                      <Button
                        text="Save"
                        intent={Intent.Default}
                        type="submit"
                      />
                    </div>
                  </div>
                </form>
              )
            }}
          </Formik>
        )}
      </Mutation>
    </Dialog>
  )
}

PartnerModal.propTypes = {
  modalOpen: bool,
  modalClose: func,
}

export default PartnerModal

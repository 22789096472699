import React from 'react'
import { Query } from 'react-apollo'
import NumericInput from '@components/NumericInput/NumericInput'
import { Classes } from '@blueprintjs/core'
import GET_CURRENCY from '@components/Currency/queries/getCurrency.query'

const CurrencyTag = () => (
  <Query query={GET_CURRENCY} fetchPolicy="cache-first">
    {({ data, loading, error }) => {
      if (
        loading ||
        error ||
        !data ||
        !data.getCurrency ||
        !data.getCurrency.currency
      )
        return null

      return (
        <span
          className={Classes.ICON}
          style={{ width: 16, height: 16, lineHeight: 1.2 }}
        >
          {data.getCurrency.currency.symbol}
        </span>
      )
    }}
  </Query>
)

const CurrencyInput = props => {
  const { displayCurrency = true } = props

  return (
    <NumericInput {...props} leftIcon={displayCurrency && <CurrencyTag />} />
  )
}

export default CurrencyInput

import {
  Button,
  Card,
  Divider,
  HTMLTable,
  Menu,
  MenuItem,
  NonIdealState,
  Popover,
  PopoverInteractionKind,
  Spinner,
  Tag,
} from '@blueprintjs/core'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import MoveUpDown from '@components/MoveUpDown/MoveUpDown'
import { successToast } from '@utils/toast'
import React, { Fragment, useContext } from 'react'
import { Mutation, Query } from 'react-apollo'
import GET_MARKETPLACE from '../Details/queries/getMarketplace.query'
import EditSegmentDrawer from './EditSegmentDrawer'
import DELETE_SEGMENT from './mutations/deleteSegment.mutation'
import EDIT_BUSINESS_SEGMENT_ORDER from './mutations/editBusinessSegmentOrder.mutation'
import GET_SEGMENTS from './queries/getSegments.query'
import SingleFilterGroup from './SingleFilterGroup'
import { BusinessSegmentType } from '../../../../../../server-services/src/shared/types/enums'
import { StringParam, useQueryParam } from 'use-query-params'
import {
  OuterLayoutOverlayContext,
  OVERLAYS,
} from '../OuterLayoutOverlayContext/OuterLayoutOverlayContext'
import DateTime from '@components/DateTime/DateTime'

const Segments = ({ match }) => {
  const [viewSegment, onChangeViewSegment] = useQueryParam(
    'viewSegment',
    StringParam
  )
  const { openOverlay } = useContext(OuterLayoutOverlayContext)
  const { marketplace: marketplaceId } = match.params
  return (
    <Fragment>
      <Query query={GET_MARKETPLACE} variables={{ id: marketplaceId }}>
        {marketplacesData => {
          if (
            !marketplacesData.data ||
            !marketplacesData.data.getMarketplaces
          ) {
            return null
          }
          return (
            <Fragment>
              {viewSegment && (
                <EditSegmentDrawer
                  id={viewSegment}
                  isOpen={!!viewSegment}
                  marketplaceId={marketplaceId}
                  onClose={() => {
                    onChangeViewSegment(undefined)
                  }}
                />
              )}
              <Query query={GET_SEGMENTS} variables={{ marketplaceId }}>
                {({
                  data: { getBusinessSegmentsByMarketplaceId },
                  loading,
                }) => {
                  if (loading) {
                    return (
                      <NonIdealState
                        icon={<Spinner size={60} />}
                        title={'Loading Segments'}
                        description={'Please wait...'}
                      />
                    )
                  }

                  if (
                    !getBusinessSegmentsByMarketplaceId ||
                    !getBusinessSegmentsByMarketplaceId.length
                  ) {
                    return (
                      <NonIdealState
                        icon="th-list"
                        title="No Segments Found"
                        description="There are no segments to display."
                        action={
                          <Button
                            icon="plus"
                            onClick={() => {
                              openOverlay(OVERLAYS.ADD_SEGMENT)
                            }}
                            text="Create Segment"
                          />
                        }
                      />
                    )
                  }

                  return (
                    <div className="bp3-table-frame">
                      <div className="bp3-table-container bp3-scrollable">
                        {/* Get segments query */}
                        <Card className={'bp3-nopad'}>
                          <HTMLTable bordered={false} interactive={true}>
                            <thead>
                              <tr>
                                <th style={{ width: '25%' }}>Name</th>
                                <th style={{ width: '40%' }}>Schedule</th>
                                <th
                                  style={{ width: '10%', whiteSpace: 'nowrap' }}
                                >
                                  Type
                                </th>
                                <th style={{ width: '10%' }}>
                                  Total&nbsp;Items
                                </th>
                                <th style={{ width: '15%' }}>Created</th>
                                <th style={{ width: '0%' }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {getBusinessSegmentsByMarketplaceId &&
                                getBusinessSegmentsByMarketplaceId.map(
                                  segment => {
                                    const {
                                      name,
                                      id,
                                      createdAt,
                                      filterGroupType,
                                      filters,
                                      position,
                                      outletCount,
                                      categoryCount,
                                      type,
                                      availabilityStartDate,
                                      availabilityEndDate,
                                    } = segment

                                    return (
                                      <tr key={id}>
                                        <td>
                                          <a
                                            onClick={() => {
                                              onChangeViewSegment(id)
                                            }}
                                          >
                                            {name}
                                          </a>
                                        </td>
                                        <td style={{ whiteSpace: 'nowrap' }}>
                                          {!availabilityStartDate &&
                                            !availabilityEndDate &&
                                            'Always Available'}
                                          {availabilityStartDate ? (
                                            <DateTime
                                              hideDayIfSame={false}
                                              dateTime={availabilityStartDate}
                                            />
                                          ) : (
                                            ''
                                          )}
                                          {availabilityEndDate && (
                                            <span className="bp3-text-muted">
                                              {' to '}
                                            </span>
                                          )}
                                          {availabilityEndDate ? (
                                            <DateTime
                                              hideDayIfSame={false}
                                              dateTime={availabilityEndDate}
                                            />
                                          ) : (
                                            ''
                                          )}
                                        </td>
                                        <td>
                                          <Tag minimal>{type}</Tag>
                                        </td>
                                        <td>
                                          {type ===
                                            BusinessSegmentType.OUTLETS &&
                                            outletCount}
                                          {type ===
                                            BusinessSegmentType.CATEGORIES &&
                                            categoryCount}
                                        </td>
                                        <td>
                                          <DateTime
                                            hideDayIfSame={false}
                                            dateTime={createdAt}
                                          />
                                        </td>
                                        <td>
                                          <div
                                            style={{
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            <SingleFilterGroup
                                              filters={filters}
                                              filterGroupType={filterGroupType}
                                            />
                                          </div>
                                          {/* Delete segment mutation */}
                                          <span
                                            onClick={e => {
                                              e.stopPropagation()
                                            }}
                                          >
                                            <Popover
                                              position={'left'}
                                              interactionKind={
                                                PopoverInteractionKind.CLICK
                                              }
                                              content={
                                                <Menu>
                                                  <MoveUpDown
                                                    mutationToExecute={
                                                      EDIT_BUSINESS_SEGMENT_ORDER
                                                    }
                                                    index={position}
                                                    initialList={getBusinessSegmentsByMarketplaceId.map(
                                                      ({ id, position }) => {
                                                        return {
                                                          id,
                                                          position,
                                                        }
                                                      }
                                                    )}
                                                    marketplaceId={
                                                      marketplaceId
                                                    }
                                                    refetchQueries={[
                                                      {
                                                        query: GET_SEGMENTS,
                                                        variables: {
                                                          marketplaceId,
                                                        },
                                                      },
                                                    ]}
                                                  />
                                                  <Divider />
                                                  <Mutation
                                                    mutation={DELETE_SEGMENT}
                                                    refetchQueries={[
                                                      {
                                                        query: GET_SEGMENTS,
                                                        variables: {
                                                          marketplaceId,
                                                        },
                                                      },
                                                    ]}
                                                    onCompleted={() => {
                                                      successToast(
                                                        'Segment deleted successfully'
                                                      )
                                                    }}
                                                  >
                                                    {deleteRequest => (
                                                      <ConfirmationPopover
                                                        remove={() =>
                                                          deleteRequest({
                                                            variables: {
                                                              id,
                                                            },
                                                          })
                                                        }
                                                        confirmationText={`Are you sure you want to delete this segment?`}
                                                      >
                                                        <MenuItem
                                                          text="Delete"
                                                          icon="trash"
                                                          shouldDismissPopover={
                                                            false
                                                          }
                                                        />
                                                      </ConfirmationPopover>
                                                    )}
                                                  </Mutation>
                                                </Menu>
                                              }
                                              renderTarget={({
                                                viewSegment,
                                                ref,
                                                ...targetProps
                                              }) => (
                                                <Button
                                                  {...targetProps}
                                                  elementRef={ref}
                                                  intent="primary"
                                                  text="Popover target"
                                                />
                                              )}
                                            >
                                              <Button icon="more" minimal />
                                            </Popover>
                                          </span>
                                        </td>
                                      </tr>
                                    )
                                  }
                                )}
                            </tbody>
                          </HTMLTable>
                        </Card>
                      </div>
                    </div>
                  )
                }}
              </Query>
            </Fragment>
          )
        }}
      </Query>
    </Fragment>
  )
}

export default Segments

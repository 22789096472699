import React from 'react'

const ReviewStatus = ({ approved }) => (
  <p style={{
    marginBottom: 'auto',
    marginTop: 'auto'
  }}
    className={'bp3-tag bp3-minimal '.concat(approved ? 'bp3-intent-success': 'bp3-intent-danger')}
  >
    {approved ? 'Approved': 'Awaiting approval'}
  </p>
)

export default ReviewStatus

import React, { Fragment } from 'react'
import { func, object } from 'prop-types'
import { Intent, FormGroup, Divider, H5, H6, Icon } from '@blueprintjs/core'
import { Row, Col } from 'react-simple-flex-grid'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import PercentageInput from '@components/PercentageInput/PercentageInput'
import { VATFormCore } from '@components/Vat/VATFormCore'
import { isPlatformLevelUser } from '@stores/userStore'

const FinancialsForm = ({
  handleChange,
  errors,
  values,
  setFieldValue,
  partner,
}) => (
  <Fragment>
    <H5>Partner Commission</H5>
    <Row gutter={24}>
      <Col span={6}>
        <H6>Delivery / Collection</H6>
        <FormGroup
          label="Partner Commission Fee"
          labelInfo="(required)"
          labelFor="partnerCommissionFee"
          helperText={
            errors.partnerCommissionFee
              ? errors.partnerCommissionFee
              : 'Percentage of the transaction paid to the partner.'
          }
          intent={errors.partnerCommissionFee ? Intent.DANGER : null}
        >
          <PercentageInput
            value={values.partnerCommissionFee}
            disabled={!partner.stripeEnterpriseId}
            fill
            handleChange={values =>
              setFieldValue('partnerCommissionFee', values.floatValue)
            }
          />
        </FormGroup>
        <FormGroup
          label="Partner Commission Charge"
          labelInfo="(required)"
          labelFor="partnerCommissionCharge"
          helperText={
            errors.partnerCommissionCharge
              ? errors.partnerCommissionCharge
              : 'Fixed charge on transaction paid to the partner.'
          }
          intent={errors.partnerCommissionCharge ? Intent.DANGER : null}
        >
          <CurrencyInput
            defaultValue={values.partnerCommissionCharge}
            disabled={!partner.stripeEnterpriseId}
            fill
            onUpdate={amount =>
              setFieldValue('partnerCommissionCharge', amount)
            }
          />
        </FormGroup>
      </Col>
      <Col span={6}>
        <H6>Table Service</H6>
        <FormGroup
          label="Partner Commission Fee"
          labelInfo="(required)"
          labelFor="partnerTableCommissionFee"
          helperText={
            errors.partnerTableCommissionFee ? (
              errors.partnerTableCommissionFee
            ) : (
              <Icon icon="blank" />
            )
          }
          intent={errors.partnerTableCommissionFee ? Intent.DANGER : null}
        >
          <PercentageInput
            value={values.partnerTableCommissionFee}
            disabled={!partner.stripeEnterpriseId}
            fill
            handleChange={values =>
              setFieldValue('partnerTableCommissionFee', values.floatValue)
            }
          />
        </FormGroup>
        <FormGroup
          label="Partner Commission Charge"
          labelInfo="(required)"
          labelFor="partnerTableCommissionCharge"
          helperText={
            errors.partnerTableCommissionCharge ? (
              errors.partnerTableCommissionCharge
            ) : (
              <Icon icon="blank" />
            )
          }
          intent={errors.partnerTableCommissionCharge ? Intent.DANGER : null}
        >
          <CurrencyInput
            defaultValue={values.partnerTableCommissionCharge}
            disabled={!partner.stripeEnterpriseId}
            fill
            onUpdate={amount =>
              setFieldValue('partnerTableCommissionCharge', amount)
            }
          />
        </FormGroup>
      </Col>
    </Row>
    {isPlatformLevelUser() && (
      <Fragment>
        <br />
        <H5>Platform Commission</H5>
        <Row gutter={24}>
          <Col span={6}>
            <H6>Delivery / Collection</H6>
            <FormGroup
              label="Default Platform Fee"
              labelInfo="(required)"
              labelFor="platformFee"
              helperText={errors.platformFee ? errors.platformFee : ''}
              intent={Intent.DANGER}
            >
              <PercentageInput
                value={values.platformFee}
                handleChange={values =>
                  setFieldValue('platformFee', values.floatValue)
                }
                fill
              />
            </FormGroup>

            <FormGroup
              label="Default Platform Charge"
              labelInfo="(required)"
              labelFor="platformCharge"
              helperText={errors.platformCharge ? errors.platformCharge : ''}
              intent={Intent.DANGER}
            >
              <CurrencyInput
                name="platformCharge"
                id="platformCharge"
                defaultValue={values.platformCharge}
                onUpdate={amount => {
                  setFieldValue('platformCharge', amount)
                }}
                intent={errors.platformCharge ? Intent.DANGER : Intent.NONE}
                fill
              />
            </FormGroup>
          </Col>
          <Col span={6}>
            <H6>Table Service</H6>
            <FormGroup
              label="Default Platform Fee"
              labelInfo="(required)"
              labelFor="platformTableFee"
              helperText={
                errors.platformTableFee ? errors.platformTableFee : ''
              }
              intent={Intent.DANGER}
            >
              <PercentageInput
                value={values.platformTableFee}
                handleChange={values =>
                  setFieldValue('platformTableFee', values.floatValue)
                }
                fill
              />
            </FormGroup>
            <FormGroup
              label="Default Platform Charge"
              labelInfo="(required)"
              labelFor="platformTableCharge"
              helperText={
                errors.platformTableCharge ? errors.platformTableCharge : ''
              }
              intent={Intent.DANGER}
            >
              <CurrencyInput
                name="platformTableCharge"
                id="platformTableCharge"
                defaultValue={values.platformTableCharge}
                onUpdate={amount => {
                  setFieldValue('platformTableCharge', amount)
                }}
                intent={
                  errors.platformTableCharge ? Intent.DANGER : Intent.NONE
                }
                fill
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
      </Fragment>
    )}

    <Divider />
    <br />
    <H5>Processor Charges</H5>
    <Row gutter={24}>
      <Col xs={12} lg={6}>
        <FormGroup
          label="Connect Charge"
          labelFor="stripeConnectCharge"
          helperText={
            errors.stripeConnectCharge
              ? errors.stripeConnectCharge
              : 'Cost per active account per month.'
          }
          intent={errors.stripeConnectCharge ? Intent.DANGER : Intent.NONE}
        >
          <CurrencyInput
            name="stripeConnectCharge"
            id="stripeConnectCharge"
            defaultValue={values.stripeConnectCharge}
            onUpdate={amount => {
              setFieldValue('stripeConnectCharge', amount)
            }}
            fill
          />
        </FormGroup>
      </Col>
      <Col xs={12} lg={6}>
        <FormGroup
          label="Chargeback Charge"
          labelFor="stripeChargebackCharge"
          helperText={
            errors.stripeChargebackCharge
              ? errors.stripeChargebackCharge
              : 'Cost per charge back lost.'
          }
          intent={errors.stripeChargebackCharge ? Intent.DANGER : Intent.NONE}
        >
          <CurrencyInput
            name="stripeChargebackCharge"
            id="stripeChargebackCharge"
            defaultValue={values.stripeChargebackCharge}
            onUpdate={amount => {
              setFieldValue('stripeChargebackCharge', amount)
            }}
            fill
          />
        </FormGroup>
      </Col>
    </Row>

    <Row gutter={24}>
      <Col xs={12} lg={6}>
        <FormGroup
          label="Payout Fee"
          labelFor="stripePayoutFee"
          helperText={
            errors.stripePayoutFee
              ? errors.stripePayoutFee
              : 'Cost for instant stripe payout.'
          }
          intent={errors.stripePayoutFee ? Intent.DANGER : Intent.NONE}
        >
          <PercentageInput
            value={values.stripePayoutFee}
            handleChange={values =>
              setFieldValue('stripePayoutFee', values.floatValue)
            }
            fill
          />
        </FormGroup>
      </Col>
      <Col xs={12} lg={6}>
        <FormGroup
          label="Payout Charge"
          labelFor="stripePayoutCharge"
          intent={Intent.DANGER}
        >
          <CurrencyInput
            name="stripePayoutCharge"
            id="stripePayoutCharge"
            defaultValue={values.stripePayoutCharge}
            onUpdate={amount => {
              setFieldValue('stripePayoutCharge', amount)
            }}
            fill
          />
        </FormGroup>
      </Col>
    </Row>

    <Row gutter={24}>
      <Col xs={12} lg={6}>
        <FormGroup
          label="Instant Payout Fee"
          labelFor="stripeInstantPayoutFee"
          helperText={
            errors.stripeInstantPayoutFee
              ? errors.stripeInstantPayoutFee
              : 'Cost for instant stripe payout.'
          }
          intent={errors.stripeInstantPayoutFee ? Intent.DANGER : Intent.NONE}
        >
          <PercentageInput
            value={values.stripeInstantPayoutFee}
            handleChange={values =>
              setFieldValue('stripeInstantPayoutFee', values.floatValue)
            }
            fill
          />
        </FormGroup>
      </Col>
      <Col xs={12} lg={6}>
        <FormGroup
          label="Account Debit Fee"
          labelFor="stripeDebitFee"
          helperText={
            errors.stripeDebitFee
              ? errors.stripeDebitFee
              : 'Cost for directly debiting stripe account balance.'
          }
          intent={errors.stripeDebitFee ? Intent.DANGER : Intent.NONE}
        >
          <PercentageInput
            value={values.stripeDebitFee}
            handleChange={values =>
              setFieldValue('stripeDebitFee', values.floatValue)
            }
            fill
          />
        </FormGroup>
      </Col>
    </Row>
    <Row gutter={24}>
      <Col xs={12} lg={6}>
        <FormGroup
          label="Online Transaction Fee"
          labelFor="stripeFee"
          helperText={
            errors.stripeFee
              ? errors.stripeFee
              : 'Cost to platform of each online transaction.'
          }
          intent={errors.stripeFee ? Intent.DANGER : Intent.NONE}
        >
          <PercentageInput
            value={values.stripeFee}
            handleChange={values =>
              setFieldValue('stripeFee', values.floatValue)
            }
          />
        </FormGroup>
      </Col>
      <Col xs={12} lg={6}>
        <FormGroup
          label="Online Transaction Charge"
          labelFor="stripeCharge"
          intent={Intent.DANGER}
        >
          <CurrencyInput
            name="stripeCharge"
            id="stripeCharge"
            defaultValue={values.stripeCharge}
            onUpdate={amount => {
              setFieldValue('stripeCharge', amount)
            }}
            fill
          />
        </FormGroup>
      </Col>
    </Row>
    <Row gutter={24}>
      <Col xs={12} lg={6}>
        <FormGroup
          label="Present Transaction Fee"
          labelFor="stripePresentFee"
          helperText={
            errors.stripePresentFee
              ? errors.stripePresentFee
              : 'Cost to platform of each terminal transaction.'
          }
          intent={errors.stripePresentFee ? Intent.DANGER : Intent.NONE}
        >
          <PercentageInput
            value={values.stripePresentFee}
            handleChange={values =>
              setFieldValue('stripePresentFee', values.floatValue)
            }
            fill
          />
        </FormGroup>
      </Col>
      <Col xs={12} lg={6}>
        <FormGroup
          label="Present Transaction Charge"
          labelFor="stripePresentCharge"
          intent={Intent.DANGER}
        >
          <CurrencyInput
            name="stripePresentCharge"
            id="stripePresentCharge"
            defaultValue={values.stripePresentCharge}
            onUpdate={amount => {
              setFieldValue('stripePresentCharge', amount)
            }}
            fill
          />
        </FormGroup>
      </Col>
    </Row>
    {/*
    // Nolonger needed, however want to keep to be able to reference if needed  
  }
    <Divider />
    <br />
    <Row gutter={24}>
      <Col xs={12} lg={12}>
        <H5>Marketplace Billing</H5>
        <Row gutter={24}>
          <Col xs={6} lg={6}>
            <H6>Features</H6>
            <FormGroup
              label="Marketing"
              labelFor="billingProductMarketingId"
              intent={Intent.DANGER}
            >
              <ProductSelect
                productId={values.billingProductMarketingId}
                onChange={event => {
                  const selection = selectionUpdate(event)
                  setFieldValue(
                    'billingProductMarketingId',
                    selection.value,
                    false
                  )
                }}
              />
            </FormGroup>
            <FormGroup
              label="SMS"
              labelFor="billingProductSMSId"
              intent={Intent.DANGER}
            >
              <ProductSelect
                productId={values.billingProductSMSId}
                onChange={event => {
                  const selection = selectionUpdate(event)
                  setFieldValue('billingProductSMSId', selection.value, false)
                }}
              />
            </FormGroup>
            <FormGroup
              label="Waybox"
              labelFor="billingProductWayboxId"
              intent={Intent.DANGER}
            >
              <ProductSelect
                productId={values.billingProductWayboxId}
                onChange={event => {
                  const selection = selectionUpdate(event)
                  setFieldValue(
                    'billingProductWayboxId',
                    selection.value,
                    false
                  )
                }}
              />
            </FormGroup>
            <FormGroup
              label="Integration API"
              labelFor="billingProductAPIId"
              intent={Intent.DANGER}
            >
              <ProductSelect
                productId={values.billingProductAPIId}
                onChange={event => {
                  const selection = selectionUpdate(event)
                  setFieldValue('billingProductAPIId', selection.value, false)
                }}
              />
            </FormGroup>
          </Col>
          <Col xs={6} lg={6}>
            <H6>Paybox</H6>
            <FormGroup
              label="Outlets"
              labelFor="billingProductOutletId"
              intent={Intent.DANGER}
            >
              <ProductSelect
                productId={values.billingProductOutletId}
                onChange={event => {
                  const selection = selectionUpdate(event)
                  setFieldValue(
                    'billingProductOutletId',
                    selection.value,
                    false
                  )
                }}
              />
            </FormGroup>
            <FormGroup
              label="Chargebacks"
              labelFor="billingProductChargebackId"
              intent={Intent.DANGER}
            >
              <ProductSelect
                productId={values.billingProductChargebackId}
                onChange={event => {
                  const selection = selectionUpdate(event)
                  setFieldValue(
                    'billingProductChargebackId',
                    selection.value,
                    false
                  )
                }}
              />
            </FormGroup>
          </Col>
              </Row>
      </Col>
    </Row>
{*/}
    <Divider />
    <br />
    <Row>
      <Col span={12}>
        <H5>Taxes</H5>
        <VATFormCore
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      </Col>
    </Row>
  </Fragment>
)

FinancialsForm.propTypes = {
  handleChange: func,
  errors: object,
  values: object,
  setFieldValue: func,
  partner: object,
}

export default FinancialsForm

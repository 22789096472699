import React, { Fragment } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import {
  Card,
  FormGroup,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
  Tag,
} from '@blueprintjs/core'
import Favicon from '@components/Favicon/Favicon'
import OutletSelectByMarketplace from '@components/Marketing/shared/OutletSelectByMarketplace'
import EmojiPicker from '@components/Marketplace/MessageTemplates/EmojiPicker'
import moment from 'moment'
import iphoneBg from '@assets/ios-iphone-background.png'
import { MenuItemSelectByOutlet } from '@components/Marketing/shared/MenuItemSelectByOutlet'

const pushNotificationCharacterLimit = { title: 40, body: 160 }

export const InnerFormPushSection = ({
  disabled,
  marketplaceId,
  values,
  errors,
  setFieldValue,
  handleChange,
  classes,
}) => (
  <Card>
    <Row>
      <Col md={6}>
        <FormGroup
          label="Title"
          labelFor="push.title"
          labelInfo={`(${
            pushNotificationCharacterLimit.title - values.push.title.length
          } Characters Remaining)`}
          helperText={(errors.push && errors.push.title) || ''}
          intent={errors.push ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            disabled={disabled}
            name="push.title"
            value={values.push.title}
            onChange={handleChange}
            maxLength={pushNotificationCharacterLimit.title}
            rightElement={
              <EmojiPicker
                setFieldValue={(name, value) => {
                  if (value.length <= pushNotificationCharacterLimit.title) {
                    setFieldValue(name, value)
                  }
                }}
                values={values}
                name={'push.title'}
              />
            }
          />
        </FormGroup>
        <FormGroup
          label="Body"
          labelFor="push.body"
          labelInfo={`(${
            pushNotificationCharacterLimit.body - values.push.body.length
          } Characters Remaining)`}
          helperText={(errors.push && errors.push.body) || ''}
          intent={Intent.NONE}
        >
          <InputGroup
            disabled={disabled}
            name="push.body"
            value={values.push.body}
            onChange={handleChange}
            maxLength={pushNotificationCharacterLimit.body}
            rightElement={
              <EmojiPicker
                setFieldValue={(name, value) => {
                  if (value.length <= pushNotificationCharacterLimit.body) {
                    setFieldValue(name, value)
                  }
                }}
                values={values}
                name={'push.body'}
              />
            }
          />
        </FormGroup>
        <FormGroup
          helperText={(errors.push && errors.push.action) || ''}
          intent={errors.push ? Intent.DANGER : Intent.NONE}
          label="Action"
          labelInfo="(required)"
        >
          <RadioGroup
            disabled={disabled}
            onChange={handleChange}
            selectedValue={values.push.action}
            name="push.action"
          >
            <Radio label="Open Outlet" value={'OUTLET'} />
            <Radio
              labelElement={
                <Fragment>
                  Open Menu Item <Tag minimal>App v5+</Tag>
                </Fragment>
              }
              value={'MENU_ITEM'}
            />
            <Radio label="Open app" value={'APP'} />
          </RadioGroup>
        </FormGroup>
        {['OUTLET', 'MENU_ITEM'].includes(values.push.action) ? (
          <FormGroup
            label="Outlet"
            labelFor="push.outletId"
            labelInfo="(required)"
            helperText={(errors.push && errors.push.outletId) || ''}
            intent={errors.push ? Intent.DANGER : Intent.NONE}
          >
            <OutletSelectByMarketplace
              disabled={disabled}
              outletId={values.push.outletId}
              marketplaceId={marketplaceId}
              setSelected={value => setFieldValue('push.outletId', value)}
            />
          </FormGroup>
        ) : null}{' '}
        {['MENU_ITEM'].includes(values.push.action) ? (
          <FormGroup
            label="Menu Item"
            labelFor="push.menuItemId"
            labelInfo="(required)"
            helperText={(errors.push && errors.push.outletId) || ''}
            intent={errors.push ? Intent.DANGER : Intent.NONE}
          >
            <MenuItemSelectByOutlet
              disabled={disabled}
              outletId={values.push.outletId}
              menuItemId={values.push.menuItemId}
              setSelected={value => setFieldValue('push.menuItemId', value)}
            />
          </FormGroup>
        ) : null}{' '}
      </Col>
      <Col md={6}>
        <Card
          style={{
            background: `url(${iphoneBg}) no-repeat`,
            minHeight: 240,
            width: 400,
            margin: '0 auto',
            color: 'black',
          }}
        >
          <ul className={classes.iosNotifications}>
            <li className={classes.iosNotificationItem}>
              <div className={classes.iosNotificationItemHeader}>
                <div className={classes.iosNotificationItemTitle}>
                  <Favicon src={values.marketplaceIcon} />
                  <span style={{ marginLeft: 8 }}>
                    {values.marketplaceName}
                  </span>
                </div>
                <div>{moment(values.startDateTime).format(`h:mma`)}</div>
              </div>
              <div>
                <strong>{values.push.title || 'Push Title'}</strong>
              </div>
              <div>{values.push.body || 'Push Body'}</div>
            </li>
          </ul>
        </Card>
      </Col>
    </Row>
  </Card>
)

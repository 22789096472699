import {
  Button,
  Card,
  ControlGroup,
  H1,
  H3,
  H5,
  Intent,
} from '@blueprintjs/core'
import first from 'lodash/first'
import React, { Fragment, useMemo, useState } from 'react'
import { downloadCSV } from './utils/downloadCSV'
import moment from 'moment'
import OnePerCustomerAndActive from './OneUsePerCustomerAndActive'
import { Row, Col } from 'react-simple-flex-grid'
import UsageReportDrawer from './UsageReportDrawer'

const MultipleVouchers = ({ vouchers, discountName, orderIds = [] }) => {
  const [isReportDrawerOpen, setIsReportDrawerOpen] = useState(false)
  const { onePerCustomer, active } = first(vouchers)
  const { unusedVouchers, voucherIds } = useMemo(
    () =>
      vouchers.reduce(
        (acc, { id, customerIds, key }) => {
          const [voucherKey] = key.split('-')
          if (customerIds.length === 0) {
            acc.unusedVouchers.push(voucherKey)
          }
          acc.voucherIds.push(id)

          return acc
        },
        {
          unusedVouchers: [],
          voucherIds: [],
        }
      ),
    [vouchers]
  )

  return (
    <Fragment>
      <ControlGroup fill vertical={false}>
        <Button
          icon="cloud-download"
          onClick={() => {
            const csvContent = [
              `Discount Name: ${discountName}`,
              `Active Vouchers: ${unusedVouchers.length}`,
              `Generated: ${moment().format('Do MMM HH mm')}`,
              '---',
              ...unusedVouchers,
            ].join('\n')
            downloadCSV(csvContent, 'activeCodes.csv')
          }}
        >
          Download Active Codes
        </Button>
        <Button
          icon="chart"
          text="Usage Report"
          intent={Intent.NONE}
          disabled={!orderIds.length}
          onClick={() => setIsReportDrawerOpen(true)}
        />
      </ControlGroup>
      <br />
      <Card>
        <Row>
          <Col span={3} md={3}>
            <H5>Usage Report</H5>
          </Col>
          <Col span={9} md={9}></Col>
        </Row>
        <Row>
          <Col span={3} md={4}>
            <H1 style={{ color: '#1968F6' }}>
              {vouchers.length - unusedVouchers.length}
            </H1>
            <p>Total Uses</p>
          </Col>
          <Col span={3} md={4}>
            <H3 style={{ marginTop: 15 }}>
              <span className="bp3-text-disabled">/ </span>
              <span className="bp3-text-muted">{vouchers.length}</span>
            </H3>
            Limit
          </Col>
        </Row>
      </Card>
      <br />
      <OnePerCustomerAndActive
        onePerCustomer={onePerCustomer}
        active={active}
        voucherIds={voucherIds}
      />
      <UsageReportDrawer
        orderIds={orderIds}
        discountName={discountName}
        isOpen={isReportDrawerOpen}
        setIsOpen={setIsReportDrawerOpen}
      />
    </Fragment>
  )
}

export default MultipleVouchers

import React, { Fragment } from 'react'
import {
  FormGroup,
  InputGroup,
  HTMLSelect,
  Intent,
  Button,
  Callout,
  Switch,
} from '@blueprintjs/core'
import { without } from 'lodash'
import { selectionUpdate } from '@utils/helpers'
import PhoneInput from '@components/PhoneInput/PhoneInput'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import { isPlatformLevelUser } from '@stores/userStore'
import { DELIVERY_PROVIDER_TO_CHARGE_RECIPIENT } from './shared'
import config from '@config/config'
import CopyText from '@components/CopyText/CopyText'

const DELIVERY_PROVIDERS = ['STUART', 'WAYBOX', 'UBER_DIRECT']
const DELIVERY_NETWORK_TYPES = ['BOOKING', 'MAPPING']

const DeliveryNetworkForm = ({
  values,
  handleSubmit,
  handleChange,
  setFieldValue,
  errors,
  isEditing,
}) => {
  const deliveryProviderOptions = isPlatformLevelUser()
    ? DELIVERY_PROVIDERS
    : without(DELIVERY_PROVIDERS, 'WAYBOX')
  const uberDirectWebhookUrl = `${config.apiUrl}/api/uber-direct/webhooks/${values.marketplaceId}`
  return (
    <form onSubmit={handleSubmit} className="bp3-drawer-form">
      <div className="bp3-drawer-content">
        <FormGroup
          label="Network Name"
          labelFor="name"
          helperText={
            errors.name ||
            'A friendly name for you to easily identify the network.'
          }
          intent={errors.name ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            type="text"
            name="name"
            id="name"
            value={values.name}
            onChange={handleChange}
            intent={errors.name ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>
        <FormGroup
          label="Help Phone Number"
          labelFor="helpPhone"
          helperText={
            errors.helpPhone
              ? errors.helpPhone
              : 'Number provided to the customer to contact driver / network.'
          }
          intent={errors.helpPhone ? Intent.DANGER : Intent.NONE}
        >
          <PhoneInput
            value={values.helpPhone}
            inputProps={{ name: 'helpPhone' }}
            onChange={phone => {
              setFieldValue('helpPhone', phone)
            }}
          />
        </FormGroup>
        <FormGroup
          label="Name Prefix"
          labelFor="namePrefix"
          helperText={errors.namePrefix || 'Text to prefix customers name.'}
          intent={errors.namePrefix ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            type="text"
            name="namePrefix"
            id="namePrefix"
            value={values.namePrefix}
            onChange={handleChange}
            intent={errors.namePrefix ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>
        <FormGroup
          label="Delivery Provider"
          labelFor="deliveryProvider"
          helperText={errors.deliveryProvider || ''}
          intent={Intent.DANGER}
        >
          <HTMLSelect
            name="deliveryProvider"
            disabled={isEditing || deliveryProviderOptions.length === 1}
            options={deliveryProviderOptions}
            value={values.deliveryProvider}
            fill={true}
            onChange={event => {
              const selection = selectionUpdate(event)
              setFieldValue('deliveryProvider', selection.value)
              if (selection.value !== 'STUART')
                setFieldValue(
                  'deliveryChargeRecipient',
                  DELIVERY_PROVIDER_TO_CHARGE_RECIPIENT[selection.value]
                )
            }}
          />
        </FormGroup>
        <FormGroup
          label="Pay Delivery Charge To"
          labelFor="deliveryChargeRecipient"
          helperText={errors.deliveryChargeRecipient || ''}
          intent={Intent.DANGER}
        >
          <HTMLSelect
            name="deliveryChargeRecipient"
            options={['MERCHANT', 'MARKETPLACE']}
            value={values.deliveryChargeRecipient}
            disabled={values.deliveryProvider !== 'STUART'}
            onChange={event => {
              setFieldValue('deliveryChargeRecipient', event.target.value)
            }}
            fill={true}
          />
        </FormGroup>
        {['STUART'].includes(values.deliveryProvider) && (
          <Fragment>
            <FormGroup
              label="Delivery Network Type"
              labelFor="deliveryNetworkType"
              helperText={errors.deliveryNetworkType || ''}
              intent={Intent.DANGER}
            >
              <HTMLSelect
                name="deliveryNetworkType"
                options={DELIVERY_NETWORK_TYPES}
                value={values.deliveryNetworkType}
                onChange={handleChange}
                fill={true}
              />
            </FormGroup>
          </Fragment>
        )}
        <FormGroup>
          <Switch
            checked={values.subsidiseDelivery}
            label="Subsidise Delivery"
            onChange={() =>
              setFieldValue(
                'subsidiseDelivery',
                !values.subsidiseDelivery,
                false
              )
            }
          />
        </FormGroup>
        {values.subsidiseDelivery && (
          <FormGroup
            label="Subsidise Deliveries By"
            labelFor="fixedSubsidy"
            helperText={errors.fixedSubsidy || ''}
            intent={Intent.DANGER}
          >
            <CurrencyInput
              name="fixedSubsidy"
              id="fixedSubsidy"
              intent={errors.fixedSubsidy ? Intent.DANGER : Intent.NONE}
              defaultValue={values.fixedSubsidy || 0}
              onUpdate={amount => {
                setFieldValue('fixedSubsidy', amount)
              }}
              fill={true}
            />
          </FormGroup>
        )}
        {values.deliveryProvider === 'STUART' && (
          <Callout>
            <FormGroup label="API Client ID" labelFor="apiClientId">
              <InputGroup
                type="text"
                name="apiClientId"
                id="apiClientId"
                value={values.apiClientId}
                onChange={event =>
                  setFieldValue('apiClientId', event.currentTarget.value)
                }
              />
            </FormGroup>
            <FormGroup label="API Secret" labelFor="apiSecret">
              <InputGroup
                type="text"
                name="apiSecret"
                id="apiSecret"
                value={values.apiSecret}
                onChange={event =>
                  setFieldValue('apiSecret', event.currentTarget.value)
                }
              />
            </FormGroup>
          </Callout>
        )}

        {values.deliveryProvider === 'WAYBOX' && (
          <Callout>
            <FormGroup label="API URL" labelFor="apiURL">
              <InputGroup
                type="text"
                name="apiURL"
                id="apiURL"
                placeholder="eg http://localhost:8889"
                value={values.apiURL}
                onChange={event =>
                  setFieldValue('apiURL', event.currentTarget.value)
                }
              />
            </FormGroup>
            <FormGroup label="Key" labelFor="apiKey">
              <InputGroup
                type="password"
                name="apiKey"
                id="apiKey"
                value={values.apiKey}
                onChange={event =>
                  setFieldValue('apiKey', event.currentTarget.value)
                }
              />
            </FormGroup>
          </Callout>
        )}
        {isPlatformLevelUser() && values.deliveryProvider === 'UBER_DIRECT' && (
          <Callout>
            <FormGroup label="Customer ID" labelFor="uberDirectCustomerId">
              <InputGroup
                name="uberDirectCustomerId"
                id="uberDirectCustomerId"
                value={values.uberDirectCustomerId}
                onChange={event =>
                  setFieldValue(
                    'uberDirectCustomerId',
                    event.currentTarget.value
                  )
                }
              />
            </FormGroup>
            <FormGroup label="Client ID" labelFor="uberDirectClientId">
              <InputGroup
                name="uberDirectClientId"
                id="uberDirectClientId"
                value={values.uberDirectClientId}
                onChange={event =>
                  setFieldValue('uberDirectClientId', event.currentTarget.value)
                }
              />
            </FormGroup>
            <FormGroup label="Client Secret" labelFor="uberDirectClientSecret">
              <InputGroup
                label="Client Secret"
                name="uberDirectClientSecret"
                id="uberDirectClientSecret"
                value={values.uberDirectClientSecret}
                onChange={event =>
                  setFieldValue(
                    'uberDirectClientSecret',
                    event.currentTarget.value
                  )
                }
              />
            </FormGroup>

            <FormGroup label="Webhook URL">
              <CopyText minimal text={uberDirectWebhookUrl}></CopyText>
            </FormGroup>
            <FormGroup
              label="Webhook Signing Key"
              labelFor="uberDirectSigningKey"
            >
              <InputGroup
                name="uberDirectSigningKey"
                id="uberDirectSigningKey"
                value={values.uberDirectSigningKey}
                onChange={event =>
                  setFieldValue(
                    'uberDirectSigningKey',
                    event.currentTarget.value
                  )
                }
              />
            </FormGroup>
            {isPlatformLevelUser() && (
              /* this is already checked above but this bit should never be available to users so have
              done it again for when this feature gets released */
              <FormGroup
                label="Test Scenario"
                subLabel="Use an outlet with 15min prep, 20min delivery."
                labelFor="uberDirectTestScenario"
                helperText={errors.uberDirectTestScenario || ''}
                intent={
                  errors.uberDirectTestScenario ? Intent.DANGER : Intent.NONE
                }
              >
                <HTMLSelect
                  name="uberDirectTestScenario"
                  options={[
                    { label: 'None', value: null },
                    {
                      label: 'Successful, 30s for each stage',
                      value: 'AUTO',
                    },
                    { label: 'Will cancel the delivery', value: 'CANCEL' },
                    {
                      label: '10 mins late for pickup',
                      value: 'LATE_TO_PICKUP',
                    },
                    {
                      label: '10 mins late for dropoff',
                      value: 'LATE_TO_DROPOFF',
                    },
                  ]}
                  value={values.uberDirectTestScenario}
                  fill={true}
                  onChange={event => {
                    const selection = selectionUpdate(event)
                    setFieldValue('uberDirectTestScenario', selection.value)
                  }}
                />
              </FormGroup>
            )}
          </Callout>
        )}
      </div>
      <div className="bp3-drawer-footer-actions">
        <Button
          type="submit"
          text={isEditing ? 'Save' : 'Create'}
          onClick={handleSubmit}
        />
      </div>
    </form>
  )
}

export default DeliveryNetworkForm

import React, { Fragment } from 'react'
import { Card, Button, Intent, Spinner } from '@blueprintjs/core'
import { Mutation } from 'react-apollo'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import ARCHIVE_MARKETPLACE from '@components/Marketplace/ArchiveMarketplace/archiveMarketplace.mutation'
import { withRouter } from 'react-router'
import { successToast } from '@utils/toast'
import GET_MARKETPLACES from '@components/Marketplaces/queries/getMarketplaces.query'

const ArchiveMarketplace = ({ id, name, history }) => (
  <Mutation
    mutation={ARCHIVE_MARKETPLACE}
    onCompleted={({ archiveMarketplace }) => {
      successToast(archiveMarketplace.message)
      history.push('/marketplaces')
    }}
    refetchQueries={[
      {
        query: GET_MARKETPLACES,
      },
    ]}
  >
    {(archiveMarketplace, { loading }) => (
      <Card>
        <h5 className="bp3-heading">Delete Marketplace</h5>
        <p>
          Deleting this marketplace will also delete all of its outlets and
          terminals
        </p>
        <div className="bp-card-footer-actions">
          <ConfirmationPopover
            confirmationText={
              <Fragment>
                Are you sure you want to delete the{' '}
                <span style={{ fontWeight: 'bold' }}>{name}</span> marketplace
                and all of its outlets and terminals?
              </Fragment>
            }
            buttonTitle="Delete"
            requiresWrittenConfirmation
            remove={() => archiveMarketplace({ variables: { id } })}
          >
            <Button
              icon={loading && <Spinner size={20} />}
              intent={Intent.DANGER}
            >
              Delete
            </Button>
          </ConfirmationPopover>
        </div>
      </Card>
    )}
  </Mutation>
)

export default withRouter(ArchiveMarketplace)

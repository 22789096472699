/* eslint-disable prettier/prettier */
import {
  Button,
  Classes,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { errorToast, successToast } from '@utils/toast'
import { Formik } from 'formik'
import React, { Fragment } from 'react'
import { Mutation } from 'react-apollo'
import ADD_SEGMENT from './mutations/addSegment.mutation'
import GET_SEGMENTS from './queries/getSegments.query'
import * as yup from 'yup'
import { addBusinessSegmentStructure } from '../../../validation/business-segment'
import { BusinessSegmentType } from '../../../../../../server-services/src/shared/types/enums'

const AddSegmentModal = ({ modalOpen, modalClose, marketplaceId }) => {
  const segmentTypes = [
    { label: 'Outlets', value: BusinessSegmentType.OUTLETS },
    { label: 'Categories', value: BusinessSegmentType.CATEGORIES },
  ]

  return (
    <Dialog
      isOpen={modalOpen}
      onClose={modalClose}
      title="Create Segment"
      style={{
        width: 740,
      }}
    >
      <Fragment>
        <Mutation
          mutation={ADD_SEGMENT}
          refetchQueries={[
            {
              query: GET_SEGMENTS,
              variables: {
                marketplaceId,
              },
            },
          ]}
          onCompleted={data => {
            const businessSegment = data.addBusinessSegment
            if (businessSegment.id) {
              successToast(`Created ${businessSegment.name} segment`)
              modalClose(businessSegment.id)
            } else {
              errorToast('Error creating segment')
            }
          }}
        >
          {addRequest => (
            <Formik
              initialValues={{
                name: '',
                type: BusinessSegmentType.OUTLETS,
              }}
              onSubmit={async values => {
                await addRequest({
                  variables: {
                    ...values,
                    marketplaceId,
                  },
                })
              }}
              validationSchema={yup.object().shape(addBusinessSegmentStructure)}
            >
              {({ values, handleChange, handleSubmit, errors }) => (
                <form onSubmit={handleSubmit}>
                  <div className={Classes.DIALOG_BODY}>
                    <p className="bp3-text-muted">
                      A segment is a group of outlets that match certain
                      filters. You can create customised collections of outlets
                      to display on the home page and outlet list.
                    </p>
                    <br />
                    <FormGroup
                      label="Name"
                      labelInfo="(required)"
                      intent={errors.name ? Intent.DANGER : Intent.NONE}
                      helperText={errors.name || ''}
                    >
                      <InputGroup
                        name="name"
                        type="text"
                        value={values.name}
                        intent={errors.name ? Intent.DANGER : Intent.NONE}
                        placeholder="e.g. Saturday Night Favourites"
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup
                      label="Segment Type"
                      labelInfo="(required)"
                      intent={errors.type ? Intent.DANGER : Intent.NONE}
                      helperText={errors.type || ''}
                    >
                      <HTMLSelect
                        name={`type`}
                        options={[
                          ...segmentTypes.map(attribute => ({
                            ...attribute,
                            key: attribute.value,
                          })),
                        ]}
                        value={values.type}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>
                  <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                      <Button text="Create" type="submit" />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </Mutation>
      </Fragment>
    </Dialog>
  )
}

export default AddSegmentModal

import { AnchorButton, HTMLTable, Icon, Tag, Tooltip } from '@blueprintjs/core'
import Currency from '@components/Currency/Currency'
import { Button } from '@blueprintjs/core'
import ls from '@utils/localStorage'
import React from 'react'
import { Link } from 'react-router-dom'
import config from '@config/config'
import { toast, errorToast } from '@utils/toast'
import EmailCell from './EmailCell'
import { isSuperUser } from '../../../stores/userStore'
import VOID_INVOICE from './mutations/voidInvoice.mutation'
import ConfirmationPopover from '../../ConfirmationPopover/ConfirmationPopover'
import { Mutation } from 'react-apollo'

const renderFeeAndChargeText =
  () =>
  ({ feePercentage, commissionCharge }) => {
    const feeAndChargeText = [
      feePercentage && `${feePercentage}%`,
      commissionCharge && `${commissionCharge}p`,
    ]
      .filter(Boolean)
      .join(' + ')
    return <li key={feeAndChargeText}>{feeAndChargeText}</li>
  }

const errorCodeToHandler = {
  400: () => {
    errorToast('An unexpected error occurred')
  },
  403: () => {
    errorToast('You do not have permission to view this report')
  },
  404: () => {
    errorToast(
      'This report is currently being generated. Please try again later.'
    )
  },
  500: () => {
    errorToast('An unexpected error occurred')
  },
}
const defaultErrorHandler = () => errorToast('An unexpected error occurred')

const BillingTable = ({ data, fromDate, toDate, refetchQueries }) => {
  const border = ls.get('darkMode')
    ? { borderLeft: '1px solid #5C7080' }
    : { borderLeft: '1px solid #DCDCDD' }

  const columns = [
    {
      headingComponent: <th>Invoice No.</th>,
      tdRenderer: ({ invoice }) => {
        return (
          <td>
            {invoice ? `#${invoice.key.split(':')[1]}` : 'Not Available'}
            {invoice && invoice.isDuplicate && (
              <Tag style={{ marginLeft: '10px' }} intent="warning">
                CREDITED
              </Tag>
            )}
            {invoice && invoice.isVoid && (
              <Tag style={{ marginLeft: '10px' }} intent="warning">
                VOID
              </Tag>
            )}
          </td>
        )
      },
      isVisible: () => true,
    },
    {
      headingComponent: <th>Restaurant</th>,
      tdRenderer: ({ outlet }) => (
        <td>
          <Link
            className="bp3-text-overflow-ellipsis"
            to={`/business/${outlet.restaurant.id}`}
          >
            {outlet.restaurant.name}
          </Link>
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Outlet</th>,
      tdRenderer: ({ outlet }) => (
        <td>
          <span className="bp3-text-overflow-ellipsis">
            <Icon
              icon="symbol-circle"
              color={outlet.isOnline ? '#5bb70d' : '#CDD6DD'}
            />
            <Link to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}`}>
              {outlet.name}
            </Link>
          </span>
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>PDF</th>,
      tdRenderer: ({ outlet, invoice }) => {
        const handleClick = async () => {
          const managementJwt = ls.get('jwt')
          const url = invoice
            ? `${config.apiUrl}/api/reports/billing/invoice/${invoice.key}`
            : `${
                config.apiUrl
              }/api/reports/billing/draft?startDate=${fromDate.toISOString()}&endDate=${toDate.toISOString()}&outletId=${
                outlet.id
              }`
          const options = {
            headers: {
              Authorization: `Bearer ${managementJwt}`,
            },
            credentials: 'same-origin',
          }
          const res = await fetch(url, options)
          if (res.status !== 200) {
            return (
              errorCodeToHandler[res.status.toString()] || defaultErrorHandler
            )(res)
          }
          const matches = res.headers
            .get('content-disposition')
            .match(/attachment;.+?filename=(.+\.(pdf|zip))/)
          const filename = matches[1]
          const blob = await res.blob()
          const file = new File([blob], filename)
          const objectURL = window.URL.createObjectURL(file)

          let a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display: none'
          a.href = objectURL
          a.download = filename
          a.setAttribute('target', '_blank')
          a.click()
          a.remove()

          window.URL.revokeObjectURL(objectURL)

          toast({
            message: 'Download Complete',
            intent: 'success',
            icon: 'saved',
          })
        }

        return (
          <td className="bp3-action-cell">
            <AnchorButton
              minimal
              disabled={!invoice && toDate.valueOf() < new Date().valueOf()}
              onClick={handleClick}
              icon="cloud-download"
            />
            {isSuperUser() && invoice && !invoice.isVoid && (
              <Mutation
                mutation={VOID_INVOICE}
                variables={{ invoiceId: invoice.id }}
                refetchQueries={refetchQueries}
              >
                {voidInvoice => (
                  <ConfirmationPopover
                    buttonTitle="Void"
                    remove={voidInvoice}
                    confirmationText={`Are you sure you want to void this invoice?`}
                  >
                    <Tooltip content="Void Invoice">
                      <Button minimal intent="danger" icon="disable" />
                    </Tooltip>
                  </ConfirmationPopover>
                )}
              </Mutation>
            )}
          </td>
        )
      },
      isVisible: () => true,
    },
    {
      headingComponent: <th>Email</th>,
      tdRenderer: ({ outlet, invoice }) => {
        return (
          <EmailCell
            invoice={invoice}
            outlet={outlet}
            refetchQueries={refetchQueries}
          />
        )
      },
      isVisible: () => true,
    },
    /* card */
    {
      headingComponent: <th style={border}>Total</th>,
      tdRenderer: ({ totalCardOrders }) => (
        <td style={border}>{totalCardOrders}</td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Gross</th>,
      tdRenderer: ({ totalCardValueGrossExcludingServiceCharge }) => (
        <td>
          <Currency amount={totalCardValueGrossExcludingServiceCharge} />
        </td>
      ),
      isVisible: () => true,
    },
    /* cash */
    {
      headingComponent: <th style={border}>Total</th>,
      tdRenderer: ({ totalCashOrders }) => (
        <td style={border}>{totalCashOrders} </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Gross</th>,
      tdRenderer: ({ totalCashValueGrossExcludingServiceCharge }) => (
        <td>
          <Currency amount={totalCashValueGrossExcludingServiceCharge} />
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th style={border}>Discounts</th>,
      tdRenderer: ({ totalDiscounts }) => (
        <td style={border}>
          <Currency amount={totalDiscounts} />
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Net</th>,
      tdRenderer: ({ totalOrdersNetAfterDiscounts }) => (
        <td>
          <Currency amount={totalOrdersNetAfterDiscounts} />
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Gross</th>,
      tdRenderer: ({ totalOrdersGrossAfterDiscount }) => (
        <td>
          <Currency amount={totalOrdersGrossAfterDiscount} />
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Total to Restaurant</th>,
      tdRenderer: ({ totalToRestaurant }) => (
        <td>
          <Currency amount={totalToRestaurant} />
        </td>
      ),
      isVisible: () => true,
    },
    /* delivery */
    {
      headingComponent: <th style={border}>Restaurant</th>,
      tdRenderer: ({ totalOwnDeliveryCharges }) => (
        <td style={border}>
          <Currency amount={totalOwnDeliveryCharges} />
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Network</th>,
      tdRenderer: ({ totalNetworkDeliveryCharges }) => (
        <td>
          <Currency amount={totalNetworkDeliveryCharges} />
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Fee & Charge</th>,
      tdRenderer: ({ marketplaceFeeAndCommissionChargeAggregates }) => (
        <td>
          <ul>
            {marketplaceFeeAndCommissionChargeAggregates
              .filter(({ fulfilmentMethod }) => fulfilmentMethod === 'DELIVERY')
              .map(renderFeeAndChargeText())}
          </ul>
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Charge</th>,
      tdRenderer: ({ marketplaceFeeAndCommissionChargeAggregates }) => (
        <td>
          <ul>
            {marketplaceFeeAndCommissionChargeAggregates
              .filter(({ fulfilmentMethod }) => fulfilmentMethod === 'DELIVERY')
              .map(
                ({
                  feePercentage,
                  commissionCharge,
                  merchantCommissionChargeTotal,
                }) => (
                  <li key={`${feePercentage}-${commissionCharge}`}>
                    <Currency amount={merchantCommissionChargeTotal} />
                  </li>
                )
              )}
          </ul>
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Fee</th>,
      tdRenderer: ({ marketplaceFeeAndCommissionChargeAggregates }) => (
        <td>
          <ul>
            {marketplaceFeeAndCommissionChargeAggregates
              .filter(({ fulfilmentMethod }) => fulfilmentMethod === 'DELIVERY')
              .map(
                ({
                  feePercentage,
                  commissionCharge,
                  marketplaceFeeNetTotal,
                }) => (
                  <li key={`${feePercentage}-${commissionCharge}`}>
                    <Currency amount={marketplaceFeeNetTotal} />
                  </li>
                )
              )}
          </ul>
        </td>
      ),
      isVisible: () => true,
    },
    /* collection */
    {
      headingComponent: <th style={border}>Fee & Charge</th>,
      tdRenderer: ({ marketplaceFeeAndCommissionChargeAggregates }) => (
        <td style={border}>
          <ul>
            {marketplaceFeeAndCommissionChargeAggregates
              .filter(
                ({ fulfilmentMethod }) => fulfilmentMethod === 'COLLECTION'
              )
              .map(renderFeeAndChargeText())}
          </ul>
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Charge</th>,
      tdRenderer: ({ marketplaceFeeAndCommissionChargeAggregates }) => (
        <td>
          <ul>
            {marketplaceFeeAndCommissionChargeAggregates
              .filter(
                ({ fulfilmentMethod }) => fulfilmentMethod === 'COLLECTION'
              )
              .map(
                ({
                  feePercentage,
                  commissionCharge,
                  merchantCommissionChargeTotal,
                }) => (
                  <li key={`${feePercentage}-${commissionCharge}`}>
                    <Currency amount={merchantCommissionChargeTotal} />
                  </li>
                )
              )}
          </ul>
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Fee</th>,
      tdRenderer: ({ marketplaceFeeAndCommissionChargeAggregates }) => (
        <td>
          <ul>
            {marketplaceFeeAndCommissionChargeAggregates
              .filter(
                ({ fulfilmentMethod }) => fulfilmentMethod === 'COLLECTION'
              )
              .map(
                ({
                  feePercentage,
                  commissionCharge,
                  marketplaceFeeNetTotal,
                }) => (
                  <li key={`${feePercentage}-${commissionCharge}`}>
                    <Currency amount={marketplaceFeeNetTotal} />
                  </li>
                )
              )}
          </ul>
        </td>
      ),
      isVisible: () => true,
    },
    /* table */
    {
      headingComponent: <th style={border}>Fee & Charge</th>,
      tdRenderer: ({ marketplaceFeeAndCommissionChargeAggregates }) => (
        <td style={border}>
          <ul>
            {marketplaceFeeAndCommissionChargeAggregates
              .filter(({ fulfilmentMethod }) => fulfilmentMethod === 'TABLE')
              .map(renderFeeAndChargeText())}
          </ul>
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Charge</th>,
      tdRenderer: ({ marketplaceFeeAndCommissionChargeAggregates }) => (
        <td>
          <ul>
            {marketplaceFeeAndCommissionChargeAggregates
              .filter(({ fulfilmentMethod }) => fulfilmentMethod === 'TABLE')
              .map(
                ({
                  feePercentage,
                  commissionCharge,
                  merchantCommissionChargeTotal,
                }) => (
                  <li key={`${feePercentage}-${commissionCharge}`}>
                    <Currency amount={merchantCommissionChargeTotal} />
                  </li>
                )
              )}
          </ul>
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Fee</th>,
      tdRenderer: ({ marketplaceFeeAndCommissionChargeAggregates }) => (
        <td>
          <ul>
            {marketplaceFeeAndCommissionChargeAggregates
              .filter(({ fulfilmentMethod }) => fulfilmentMethod === 'TABLE')
              .map(
                ({
                  feePercentage,
                  commissionCharge,
                  marketplaceFeeNetTotal,
                }) => (
                  <li key={`${feePercentage}-${commissionCharge}`}>
                    <Currency amount={marketplaceFeeNetTotal} />
                  </li>
                )
              )}
          </ul>
        </td>
      ),
      isVisible: () => true,
    },
    /* totals */
    {
      headingComponent: <th style={border}>Fees & Charges Net</th>,
      tdRenderer: ({ totalFeesAndChargesNet }) => (
        <td style={border}>
          <Currency amount={totalFeesAndChargesNet} />
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>Fees & Charges Gross</th>,
      tdRenderer: ({ totalFeesAndChargesGross }) => (
        <td>
          <Currency amount={totalFeesAndChargesGross} />
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>VAT Rate</th>,
      tdRenderer: ({ vatAggregates }) => (
        <td>
          <ul>
            {vatAggregates.map(({ count, vatRate }) => (
              <li key={vatRate.toString()}>
                {count} {count > 1 ? 'orders' : 'order'} @ {vatRate}%
              </li>
            ))}
          </ul>
        </td>
      ),
      isVisible: () => true,
    },
    {
      headingComponent: <th>VAT</th>,
      tdRenderer: ({ vatAggregates }) => (
        <td>
          <ul>
            {vatAggregates.map(({ vatRate, combinedTotal }) => (
              <li key={vatRate.toString()}>
                <Currency amount={combinedTotal} />
              </li>
            ))}
          </ul>
        </td>
      ),
      isVisible: () => true,
    },
  ]

  return (
    <HTMLTable bordered={false}>
      <thead>
        <tr>
          <th colSpan={5}></th>
          <th colSpan={2} style={border}>
            Card
          </th>
          <th colSpan={2} style={border}>
            Cash
          </th>
          <th colSpan={4} style={border}>
            Orders
          </th>
          <th colSpan={5} style={border}>
            Delivery
          </th>
          <th colSpan={3} style={border}>
            Collection
          </th>
          <th colSpan={3} style={border}>
            Table
          </th>
          <th colSpan={3} style={border}>
            Totals
          </th>
        </tr>
        <tr>
          {columns
            .map(
              ({ headingComponent, isVisible }) =>
                isVisible() && headingComponent
            )
            .filter(Boolean)}
        </tr>
      </thead>

      <tbody>
        {data.map(transactionAggregate => (
          <tr
            key={[
              transactionAggregate.id,
              transactionAggregate.invoice
                ? transactionAggregate.invoice.id
                : 'DRAFT',
            ].join(':')}
          >
            {columns
              .map(
                ({ tdRenderer, isVisible }) =>
                  isVisible() && tdRenderer(transactionAggregate)
              )
              .filter(Boolean)}
          </tr>
        ))}
      </tbody>
    </HTMLTable>
  )
}

export default BillingTable

import React from 'react'
import { func, object } from 'prop-types'
import {
  Classes,
  Button,
  Intent,
  InputGroup,
  FormGroup,
  Icon,
} from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import { Query, Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import get from 'lodash/get'

import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import { validation } from '@components/Restaurant/Menu/validation/itemFormValidation'
import { modalType } from '@utils/types'
import { toggleModal } from '@utils/cacheHelpers'
import GET_SUB_MENUS from '@components/Restaurant/Menu/queries/getSubMenus.query'
import ADD_MENU_ITEM, {
  CREATE_ADD_ON_MENU_ITEM,
} from '@components/Restaurant/Menu/mutations/addMenuItem.mutation'
import GET_MENU_ITEMS from '@components/Restaurant/Menu/queries/getMenuItems.query'
import GET_PARENT_MENUS from '@components/Restaurant/Menu/queries/getParentMenus.query'
import GET_MENU_TREE from '@components/Restaurant/Menu/queries/getMenuTree.query'
import { successToast } from '@utils/toast'
import { numberToPence } from '@utils/helpers'

const AddItemModal = ({
  modalOpen,
  modalClose,
  queryId,
  menuId,
  restaurantId,
}) => (
  <Dialog isOpen={modalOpen} onClose={modalClose} title="Add Item">
    <Query query={GET_MENU_ITEMS} variables={{ id: menuId, restaurantId }}>
      {({ data }) => {
        const position = get(
          data,
          'getMenuItemsGroups.menuItemsGroups[0].menuItems.length',
          0
        )
        const isAddOn = get(
          data,
          'getMenuItemsGroups.menuItemsGroups[0].addOnItemsMenu',
          0
        )

        const allowDefaultMinimumPurchasePercentage = get(
          data,
          'getRestaurants.restaurants[0].allowDefaultMinimumPurchasePercentage',
          0
        )
        const defaultMinimumPurchasePricePercentage = get(
          data,
          'getRestaurants.restaurants[0].defaultMinimumPurchasePricePercentage',
          0
        )

        return isAddOn ? (
          <div className={Classes.DIALOG_BODY}>
            <Mutation
              mutation={CREATE_ADD_ON_MENU_ITEM}
              onError={defaultErrorHandler}
              onCompleted={({ addMenuItem }) => {
                const item = addMenuItem.menuItem
                successToast(
                  `Successfully created 'add-on sale' menu item ${item.name}`
                )
                toggleModal({
                  itemModal: false,
                  itemDetailsOpen: true,
                  itemId: item.id,
                })
              }}
              refetchQueries={[
                {
                  query: GET_MENU_TREE,
                  variables: {
                    id: restaurantId,
                  },
                },
                {
                  query: GET_PARENT_MENUS,
                  variables: {
                    id: restaurantId,
                  },
                },
                {
                  query: GET_SUB_MENUS,
                  variables: { id: queryId, menuId },
                },
                {
                  query: GET_MENU_ITEMS,
                  variables: { id: menuId, restaurantId },
                },
              ]}
            >
              {createAddOnMenuItem => (
                <Formik
                  onSubmit={values => {
                    createAddOnMenuItem({
                      variables: {
                        ...values,
                        position,
                        price: numberToPence(values.price),
                        minimumPurchasePrice: numberToPence(
                          values.minimumPurchasePrice
                        ),
                        menuItemGroupIds: [menuId],
                        restaurantId: restaurantId,
                      },
                    })
                  }}
                  validationSchema={validation}
                  initialValues={{
                    name: '',
                    minimumPurchasePrice: 0,
                    price: 0,
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                    errors,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <p>
                          Items on this menu are 'add-on sale' items and will
                          not show alongside normal menus in the app and web.
                          Instead they will be offered at the minimum purchase
                          price to the customer at checkout.
                        </p>
                        <FormGroup
                          label="Item Name"
                          labelFor="name"
                          helperText={errors.name}
                          intent={errors.name ? Intent.DANGER : Intent.NONE}
                        >
                          <InputGroup
                            type="text"
                            name="name"
                            id="name"
                            onChange={handleChange}
                            intent={errors.name ? Intent.DANGER : Intent.NONE}
                            value={values.name}
                            autoFocus
                          />
                        </FormGroup>
                        <FormGroup
                          label="Gross Price"
                          labelFor="price"
                          labelInfo="(Must include VAT if applicable)"
                          helperText={errors.price}
                          intent={errors.price ? Intent.DANGER : Intent.NONE}
                        >
                          <CurrencyInput
                            name="price"
                            id="price"
                            defaultValue={values.price}
                            onUpdate={amount => {
                              if (allowDefaultMinimumPurchasePercentage) {
                                setFieldValue(
                                  'minimumPurchasePrice',
                                  amount -
                                    amount *
                                      (defaultMinimumPurchasePricePercentage /
                                        100)
                                )
                              }
                              setFieldValue('price', amount)
                            }}
                            intent={errors.price ? Intent.DANGER : Intent.NONE}
                            full={true}
                          />
                        </FormGroup>
                        <FormGroup
                          label="Minimum Purchase Price"
                          labelFor="minimumPurchasePrice"
                          labelInfo="(Must include VAT if applicable)"
                          helperText={
                            errors.price ||
                            'This is the price the add-on sale item will be offered for.'
                          }
                          intent={errors.price ? Intent.DANGER : Intent.NONE}
                        >
                          <CurrencyInput
                            name="minimumPurchasePrice"
                            id="minimumPurchasePrice"
                            defaultValue={values.minimumPurchasePrice}
                            onUpdate={amount => {
                              setFieldValue('minimumPurchasePrice', amount)
                            }}
                            intent={errors.price ? Intent.DANGER : Intent.NONE}
                            full={true}
                          />
                        </FormGroup>
                        {values.minimumPurchasePrice * 100 >
                        values.price * 100 ? (
                          <p style={{ color: 'red' }}>
                            <Icon
                              icon="warning-sign"
                              intent={Intent.DANGER}
                              style={{ paddingRight: '5px' }}
                            />
                            Minimum purchase price cannot be more than gross
                            price.
                          </p>
                        ) : null}
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                          <Button
                            text="Create"
                            type="submit"
                            disabled={
                              values.minimumPurchasePrice * 100 >
                              values.price * 100
                            }
                          />
                        </div>
                      </form>
                    )
                  }}
                </Formik>
              )}
            </Mutation>
          </div>
        ) : (
          <div className={Classes.DIALOG_BODY}>
            <Mutation
              mutation={ADD_MENU_ITEM}
              onError={defaultErrorHandler}
              onCompleted={({ addMenuItem }) => {
                const id = addMenuItem.menuItem.id
                successToast(addMenuItem.message)
                toggleModal({
                  itemModal: false,
                  itemDetailsOpen: true,
                  itemId: id,
                })
              }}
              refetchQueries={[
                {
                  query: GET_MENU_TREE,
                  variables: {
                    id: restaurantId,
                  },
                },
                {
                  query: GET_PARENT_MENUS,
                  variables: {
                    id: restaurantId,
                  },
                },
                {
                  query: GET_SUB_MENUS,
                  variables: { id: queryId, menuId },
                },
                {
                  query: GET_MENU_ITEMS,
                  variables: { id: menuId, restaurantId },
                },
              ]}
            >
              {addMenuItem => (
                <Formik
                  onSubmit={values => {
                    const minimumPurchasePrice =
                      allowDefaultMinimumPurchasePercentage
                        ? numberToPence(
                            values.price -
                              values.price *
                                (defaultMinimumPurchasePricePercentage / 100)
                          )
                        : 0

                    addMenuItem({
                      variables: {
                        ...values,
                        position,
                        price: numberToPence(values.price),
                        menuItemGroupIds: [menuId],
                        restaurantId: restaurantId,
                        minimumPurchasePrice,
                      },
                    })
                  }}
                  validationSchema={validation}
                  initialValues={{
                    name: '',
                    price: 0,
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                    errors,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <FormGroup
                          label="Item Name"
                          labelFor="name"
                          helperText={errors.name}
                          intent={errors.name ? Intent.DANGER : Intent.NONE}
                        >
                          <InputGroup
                            type="text"
                            name="name"
                            id="name"
                            onChange={handleChange}
                            intent={errors.name ? Intent.DANGER : Intent.NONE}
                            value={values.name}
                            autoFocus
                          />
                        </FormGroup>
                        <FormGroup
                          label="Price"
                          labelFor="price"
                          labelInfo="(Must include VAT if applicable)"
                          helperText={errors.price}
                          intent={errors.price ? Intent.DANGER : Intent.NONE}
                        >
                          <CurrencyInput
                            name="price"
                            id="price"
                            defaultValue={values.price}
                            onUpdate={amount => {
                              setFieldValue('price', amount)
                            }}
                            intent={errors.price ? Intent.DANGER : Intent.NONE}
                            full={true}
                          />
                        </FormGroup>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                          <Button
                            text="Create"
                            onClick={() => handleSubmit()}
                          />
                        </div>
                      </form>
                    )
                  }}
                </Formik>
              )}
            </Mutation>
          </div>
        )
      }}
    </Query>
  </Dialog>
)

AddItemModal.propTypes = {
  ...modalType,
  handleChange: func,
  handleSubmit: func,
  values: object,
}

export default AddItemModal

import gql from 'graphql-tag'

const MENU_ITEM = gql`
  fragment getOutletMenuTreeMenuItemFields on MenuItem {
    id
    name
    position
    parentMenus {
      id
    }
  }
`

const GET_OUTLET_MENU_TREE = gql`
  ${MENU_ITEM}
  query getOutletMenuTree($id: String!) {
    getOutlets(id: $id) {
      outlets {
        id
        name
        hiddenMenuItemGroupIds
        hiddenMenuItemIds
        menuItemsGroups(where: { parentMenu: null }, orderBy: position_ASC) {
          id
          name
          position
          parentMenu {
            id
          }
          menuItems {
            ...getOutletMenuTreeMenuItemFields
          }
          childrenMenus {
            id
            name
            position
            parentMenu {
              id
            }
            menuItems {
              ...getOutletMenuTreeMenuItemFields
            }
            childrenMenus {
              id
              name
              position
              parentMenu {
                id
              }
              menuItems {
                ...getOutletMenuTreeMenuItemFields
              }
            }
          }
        }
      }
    }
  }
`

export default GET_OUTLET_MENU_TREE

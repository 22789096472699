import React from 'react'
import { Classes, Button, Intent } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import REINVITE_USER from '../mutations/reinviteUser.mutation'

import { modalType } from '@utils/types'
import { successToast } from '@utils/toast'

const ReinviteModal = ({ modalClose, modalOpen, id }) => {
  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Reinvite User">
      <div className={Classes.DIALOG_BODY}>
        <Mutation
          mutation={REINVITE_USER}
          onError={defaultErrorHandler}
          onCompleted={() => successToast('Invite sent.')}
        >
          {reinviteUser => (
            <div>
              <p>Are you sure you want to resend the invite?</p>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={modalClose}>Cancel</Button>
                <Button
                  intent={Intent.PRIMARY}
                  onClick={() =>
                    reinviteUser({ variables: { id } }) && modalClose()
                  }
                >
                  Resend Invite
                </Button>
              </div>
            </div>
          )}
        </Mutation>
      </div>
    </Dialog>
  )
}

ReinviteModal.propTypes = {
  ...modalType,
}

export default ReinviteModal

import React from 'react'
import Dropdown from '@components/Toolbar/RadioFilter'
import TERMINAL_SOFTWARE_VERSIONS from './queries/terminalSoftwareVersions.query'
import { Query } from 'react-apollo'
import { errorToast } from '../../../utils/toast'
import { Button } from '@blueprintjs/core'

const SoftwareVersionDropdown = ({
  disabled,
  softwareVersion,
  onChangeSoftwareVersion,
}) => (
  <Query query={TERMINAL_SOFTWARE_VERSIONS}>
    {({ loading, error, data }) => {
      if (error) {
        errorToast('Error loading software versions')
        return null
      }
      if (loading) {
        return <Button loading />
      }
      if (data && data.terminalSoftwareVersions) {
        const items = data.terminalSoftwareVersions.map(version => ({
          id: version,
          name: version,
        }))
        return (
          <Dropdown
            disabled={disabled}
            selected={softwareVersion}
            setSelected={onChangeSoftwareVersion}
            placeholder="Software Version"
            items={[{ name: 'All' }, ...items]}
          />
        )
      }

      return null
    }}
  </Query>
)

export default SoftwareVersionDropdown

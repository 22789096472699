import {
  Button,
  ButtonGroup,
  Checkbox,
  Classes,
  Divider,
  Drawer,
  HTMLTable,
  NonIdealState,
  Popover,
  Position,
} from '@blueprintjs/core'
import Currency from '@components/Currency/Currency'
import FilterRow from '@components/FilterRow/FilterRow'
import Query from '@components/Query/Query'
import DateRangeFilter from '@components/Toolbar/DateRangeFilter/DateRangeFilter'
import { useDateRangeQueryParams } from '@components/Toolbar/DateRangeFilter/useDateRangeQueryParams'
import { OutletsFilterByMarketplace } from '@components/Toolbar/OutletsFilterByMarketplace'
import { Pager } from '@components/Toolbar/Pager'
import { format, startOfMonth } from 'date-fns'
import { union, without } from 'lodash'
import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { DATE_FILTER_TYPES } from '@utils/datetime'
import GET_VOUCHER_ORDERS from './queries/orders'
import { htmlTableToCSV } from './utils/htmlTableToCSV'

const DEFAULT_RECORDS = 50

const UsageReportDrawer = ({ orderIds, discountName, isOpen, setIsOpen }) => {
  const initialPagination = {
    total: null,
    skip: 0,
    first: DEFAULT_RECORDS,
    defaultNmbRecords: DEFAULT_RECORDS,
    outcomeLength: null,
    navigationDisabled: false,
  }

  const [outletsFilter, setOutletsFilter] = useState([])
  const [ordersPagination, setOrdersPagination] = useState(initialPagination)
  const [showPendingOrders, setShowPendingOrders] = useState(false)

  const dateRangeFilterArgs = {
    defaultFilterTypeKey: DATE_FILTER_TYPES.CUSTOM.key,
    defaultStartOfRangeDate: startOfMonth(new Date()),
    defaultEndOfRangeDate: new Date(),
    filterTypes: [
      DATE_FILTER_TYPES.DAY,
      DATE_FILTER_TYPES.WEEK,
      DATE_FILTER_TYPES.CUSTOM,
    ],
  }
  const {
    shiftedStartOfRangeDateTime,
    shiftedEndOfRangeDateTime,
    resetRange,
    unsetRange,
  } = useDateRangeQueryParams(dateRangeFilterArgs)

  const setFilterState = (count, totalCount) => {
    if (ordersPagination.total !== totalCount) {
      setOrdersPagination({
        ...ordersPagination,
        total: totalCount,
      })
    }
    if (ordersPagination.outcomeLength !== count) {
      setOrdersPagination({
        ...ordersPagination,
        outcomeLength: count,
      })
    }
  }

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (
      ordersPagination.skip + ordersPagination.first <
      ordersPagination.total
    ) {
      setOrdersPagination({
        ...ordersPagination,
        skip: limitNext(ordersPagination.skip, ordersPagination.first, limit),
        first: DEFAULT_RECORDS,
      })
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < ordersPagination.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setOrdersPagination({
      ...ordersPagination,
      skip: limitPrevious(ordersPagination.skip, ordersPagination.first),
      first: DEFAULT_RECORDS,
    })
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setOrdersPagination({
      ...ordersPagination,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
    })
  }

  const resetFilters = () => {
    setOrdersPagination(initialPagination)
    setOutletsFilter([])
    resetRange()
  }

  return (
    <Drawer
      title="Usage Report"
      isOpen={isOpen}
      size="70%"
      onClose={() => {
        setIsOpen(undefined)
        unsetRange()
      }}
    >
      <div className={Classes.DRAWER_BODY}>
        <FilterRow>
          <ButtonGroup>
            <DateRangeFilter {...dateRangeFilterArgs} />
            <OutletsFilterByMarketplace
              outletsFilter={outletsFilter}
              onChange={e => {
                const { id, checked } = e.currentTarget
                const newFilter = checked
                  ? union(outletsFilter, [id])
                  : without(outletsFilter, id)

                setOutletsFilter(newFilter)
              }}
            />
            <Divider />
            <Popover
              position={Position.BOTTOM_LEFT}
              boundary="window"
              defaultIsOpen={false}
            >
              <Button rightIcon="caret-down" minimal icon="more" />
              <div
                style={{
                  padding: '12px',
                }}
              >
                <Checkbox
                  checked={showPendingOrders}
                  onChange={() => setShowPendingOrders(!showPendingOrders)}
                  label="Show Pending Orders"
                />
              </div>
            </Popover>
          </ButtonGroup>
          <ButtonGroup>
            <Pager
              goToPrevious={goToPrevious}
              goToNext={goToNext}
              goToPage={goToPage}
              defaultNmbRecords={DEFAULT_RECORDS}
              skip={ordersPagination.skip}
              total={ordersPagination.total}
              outcomeLength={ordersPagination.outcomeLength}
              totalCount={ordersPagination.total}
              dataName="Orders"
            />
            <Divider />
            <Button
              icon={'cloud-download'}
              text="Download CSV"
              onClick={e => {
                e.preventDefault()
                htmlTableToCSV(
                  'usageReportTable',
                  `${discountName}_${format(
                    shiftedStartOfRangeDateTime,
                    'yyyy-MM-dd'
                  )}_to_${format(
                    shiftedEndOfRangeDateTime,
                    'yyyy-MM-dd'
                  )}.report.csv`
                )
              }}
            />
          </ButtonGroup>
        </FilterRow>
        <Query
          loaderTitle="Loading Usage Data"
          query={GET_VOUCHER_ORDERS}
          variables={{
            orderIds,
            afterDate: shiftedStartOfRangeDateTime.toISOString(),
            beforeDate: shiftedEndOfRangeDateTime.toISOString(),
            first: ordersPagination.first,
            skip: ordersPagination.skip,
            ...(outletsFilter.length > 0 && {
              outletIds: outletsFilter,
            }),
            ...(!showPendingOrders && {
              orderStatus_not_in: ['PENDING'],
            }),
          }}
          ErrorComponent={
            <NonIdealState icon="error" title="Unable to load usage data" />
          }
        >
          {data => {
            if (!data) {
              return null
            }
            const {
              orders: { orders, totalCount },
            } = data
            if (!orders.length) {
              return (
                <NonIdealState
                  icon="list"
                  title="No usage data"
                  description="No usage data found with these filters"
                  action={
                    <Button icon="filter-remove" onClick={resetFilters}>
                      Reset Filters
                    </Button>
                  }
                />
              )
            }
            setFilterState(orders.length, totalCount)

            return (
              <Fragment>
                <HTMLTable id="usageReportTable" interactive condensed striped>
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Created at</th>
                      <th>Accepted at</th>
                      <th>Customer</th>
                      <th>Business</th>
                      <th>Outlet</th>
                      <th>Discount Code</th>
                      <th>Discount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map(order => (
                      <tr key={order.id}>
                        <td>
                          <Link to={`/orders?viewOrder=${order.id}`}>
                            {order.orderNumber}
                          </Link>
                        </td>
                        <td>
                          {format(new Date(order.createdAt), 'HH:mm-dd/MM/yy')}
                        </td>
                        <td>
                          {order.acceptedAt
                            ? format(
                                new Date(order.acceptedAt),
                                'HH:mm-dd/MM/yy'
                              )
                            : order.orderStatus}
                        </td>
                        <td>
                          <Link to={`/customer/${order.customer.id}/details`}>
                            {order.customer.firstName} {order.customer.lastName}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/business/${order.outlet.restaurant.id}/overview`}
                          >
                            {order.outlet.restaurant.name}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/business/${order.outlet.restaurant.id}/outlets/${order.outlet.id}/overview`}
                          >
                            {order.outlet.name}
                          </Link>
                        </td>
                        <td>{order.voucherKey.split('-')[0]}</td>
                        <td>
                          <Currency amount={order.discountAmount} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </HTMLTable>
              </Fragment>
            )
          }}
        </Query>
      </div>
    </Drawer>
  )
}

export default UsageReportDrawer

import gql from 'graphql-tag'

const GET_PARTNER_IDS = gql`
  {
    getPartners {
      partners {
        id
        name
      }
    }
  }
`

export default GET_PARTNER_IDS

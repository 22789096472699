const styles = () => ({
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  onboardingContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logoContainer: {
    textAlign: 'center',
    padding: '20px 0',
  },
  onboardingForm: {
    width: '60%',
    minWidth: '300px',
    maxWidth: '800px',
    marginBottom: 20,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export default styles

import React, { useContext } from 'react'
import { FormGroup, Button, FileInput, Card } from '@blueprintjs/core'
import { get } from 'lodash'
import { func, string, bool, object } from 'prop-types'

import { errorToast } from '@utils/toast'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { isBase64 } from '../../utils/isBase64'
import { isOutletFinancialUser } from '@stores/userStore'

const handleChange = (
  e,
  setFieldValue,
  setStatus,
  imageId,
  sizeLimit = 1048576
) => {
  setStatus(imageId, e.target.value)
  const file = e.target.files[0]
  // check size
  if (file.size > sizeLimit) {
    errorToast(
      `File is bigger than ${
        sizeLimit / 1000
      }kB. Please choose a smaller image.`
    )
  } else {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = () => {
      // attach extension to string
      const imageData = btoa(reader.result).concat(
        file.name.substr(file.name.lastIndexOf('.'))
      )
      setFieldValue(imageId, imageData)
    }
    reader.onerror = () => {
      errorToast("Can't upload file")
    }

    const previewReader = new FileReader()
    previewReader.readAsDataURL(file)
    previewReader.onload = () => {
      setStatus(imageId, previewReader.result)
      //setFieldValue('value', null)
    }
    previewReader.onerror = () => {
      errorToast("Can't upload file, please try again.")
    }
  }
}

const handleRemove = (e, setFieldValue, setStatus, imageId) => {
  setFieldValue(imageId, '')
  setStatus(imageId, null)
}

const generateImageField = (
  previewImage,
  image,
  imageLabel,
  imageId,
  UIchange
) => {
  let imageField
  const previewBase64 = `data:image/png;base64,${(image || '').replace(
    /\.[^/.]+$/,
    ''
  )}`

  switch (UIchange) {
    case true:
      imageField = (
        <img
          id={imageId + 'Preview'}
          alt={`${imageLabel} preview`}
          height="100"
          src={previewBase64}
        />
      )
      break
    case false:
      if (image) {
        let imgUrl = image
        if (!image.includes('https://')) {
          imgUrl = `https://${process.env.ENVIRONMENT_DOMAIN}/${image}`
        }
        imageField = (
          <img
            id={imageId + 'Preview'}
            alt={imageLabel}
            height="100"
            src={imgUrl}
          />
        )
      }
      break
    default:
      imageField = null
  }

  return imageField
}

const ImageUpload = ({
  values,
  imageLabel,
  imageName,
  disabled,
  setFieldValue,
  setStatus,
  status,
  labelInfo = '(Max 1MB)',
  subLabel,
  helperText,
  sizeLimit,
  accept = 'image/*',
}) => {
  const userIsOutletFinancialUser = isOutletFinancialUser()

  const imageId = imageName || 'image'
  const previewImageValue = get(status, imageId, null)
  let UIchange = status === imageId

  const { dark } = useContext(PageLayoutContext)
  const checkerColor = dark ? '#394B59' : '#E8EBEE'

  let image = get(values, imageId)
  if (image && typeof image === 'object' && image.src) {
    image = image.src
  }

  if (image && isBase64(image)) {
    UIchange = true
  }

  return (
    <FormGroup
      label={imageLabel}
      labelFor="image"
      labelInfo={labelInfo}
      subLabel={subLabel}
      helperText={helperText}
      style={{ width: '100%' }}
    >
      {image ? (
        <div>
          <Card
            style={{
              marginBottom: '8px',
              backgroundImage: `linear-gradient(45deg, ${checkerColor} 25%, transparent 25%), linear-gradient(-45deg, ${checkerColor} 25%, transparent 25%), linear-gradient(45deg, transparent 75%, ${checkerColor} 75%), linear-gradient(-45deg, transparent 75%, ${checkerColor} 75%)`,
              backgroundSize: '20px 20px',
              backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
            }}
          >
            {generateImageField(
              previewImageValue,
              image,
              imageLabel,
              imageId,
              UIchange
            )}
          </Card>
          <Button
            style={{ marginBottom: '-6px' }}
            onClick={e => handleRemove(e, setFieldValue, setStatus, imageId)}
            disabled={disabled || userIsOutletFinancialUser}
            intent={'danger'}
            minimal={true}
            icon={'trash'}
          >
            Remove Image
          </Button>
        </div>
      ) : (
        <FileInput
          fill
          inputProps={{
            name: imageId,
            value: previewImageValue,
            accept: accept,
          }}
          onChange={e =>
            handleChange(e, setFieldValue, setStatus, imageId, sizeLimit)
          }
          disabled={disabled}
        />
      )}
    </FormGroup>
  )
}

ImageUpload.propTypes = {
  values: object,
  imageLabel: string,
  imageName: string,
  disabled: bool,
  setFieldValue: func,
  setStatus: func,
  status: object,
}

export default ImageUpload

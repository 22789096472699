import React, { useContext, useEffect } from 'react'
import get from 'lodash/get'
import { Switch } from 'react-router-dom'
import { object } from 'prop-types'
import { Query as CacheQuery } from 'react-apollo'

import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import List from '@components/Restaurants/List/Restaurants'
import Outlets from '@components/Restaurants/Outlets/Outlets'
import Terminals from '@components/Restaurants/Terminals/Terminals'
import GET_ONBOARDING_COUNT from '@components/Restaurants/List/queries/getOboardingCount.query'
import Query from '@components/Query/Query'
import RestaurantModal from '@components/Restaurant/Restaurant.modal/Restaurant.modal'
import {
  canPerformAction,
  canView,
  isAtLeastBusinessUser,
  isAtLeastMenuEditor,
  isAtLeastOutletFinancialUser,
  isAtLeastOutletUser,
  isMenuEditor,
  isOutletFinancialUser,
  isOutletUser,
} from '@stores/userStore'
import OPEN_MODAL from './List/openModal.clientQuery'
import OnboardingList from './Onboarding/List'
import Route from '@components/UI/Route/Route'

const toggleModal = (client, value) =>
  client.writeData({
    data: {
      restaurantModal: value,
    },
  })

const PageLayoutWrapper = ({ client, product, children, onboardingCount }) => {
  const { configurePageLayout } = useContext(PageLayoutContext)

  useEffect(() => {
    /** @type {Array<{to: string, name: string, count?: string, visible: boolean}>} */
    const tabs = [
      {
        to: '/business',
        name: 'Businesses',
        visible: isAtLeastMenuEditor() && !isOutletUser(),
      },
      {
        to: '/outlets',
        name: 'Outlets',
        visible: isAtLeastOutletFinancialUser(),
      },
      {
        to: '/terminals',
        name: 'Terminals',
        visible: canView('virtualTerminals'),
      },
      {
        to: '/onboardingList',
        name: 'Onboarding',
        count: onboardingCount,
        visible: canView('onboarding'),
      },
    ].filter(tab => tab.visible)

    configurePageLayout({
      product,
      button: {
        text: 'Add Business',
        onClick: () => toggleModal(client, true),
      },
      tabs,
      permissions: canPerformAction('addRestaurant'),
    })
  }, [configurePageLayout, client, product])

  return children
}

const Restaurants = ({ product }) => (
  // fetch count for onboarding if user can view onboarding
  <Query query={GET_ONBOARDING_COUNT} skip={!canView('onboarding')}>
    {({ getRestaurants } = {}) => (
      <CacheQuery query={OPEN_MODAL}>
        {({ data: { restaurantModal }, client }) => (
          <PageLayoutWrapper
            client={client}
            product={product}
            onboardingCount={get(getRestaurants, 'count', 0)}
          >
            <Switch>
              <Route
                path={`/business`}
                exact
                isPermissionAllowed={() =>
                  isAtLeastBusinessUser() || isMenuEditor()
                }
                component={List}
              />
              <Route
                path={`/outlets`}
                exact
                component={Outlets}
                isPermissionAllowed={() =>
                  isAtLeastOutletUser() || isOutletFinancialUser()
                }
              />
              <Route path={'/terminals'} exact component={Terminals} />
              <Route
                path={'/onboardingList'}
                exact
                component={OnboardingList}
                isPermissionAllowed={() => canView('onboarding')}
              />
            </Switch>
            <RestaurantModal
              modalOpen={restaurantModal}
              modalClose={() => toggleModal(client, false)}
            />
          </PageLayoutWrapper>
        )}
      </CacheQuery>
    )}
  </Query>
)

Restaurants.propTypes = {
  product: object,
}

export default Restaurants

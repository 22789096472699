const users = {
  defaults: {
    user: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      role: {
        title: '',
        id: '',
        __typename: 'role',
      },
      ownsPlatforms: [],
      ownsPartners: [],
      ownsRestaurants: [],
      ownsOutlets: [],
      __typename: 'user',
    },
  },
}

export default users

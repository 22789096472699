import * as yup from 'yup'
import { discountShape } from '@components/Discount/validation/validation'

const MAX_VOUCHERS = 2000 // take ~10s to create them
export const createDiscountVouchersValidationSchema = yup.object().shape(
  {
    ...discountShape,
    key: yup.string().notRequired().nullable(true),
    howMany: yup
      .number()
      .required('You must choose how many vouchers you need to create')
      .when('key', {
        is: keyVal => !!keyVal,
        then: yup
          .number()
          .test(
            'howMany should be 1',
            'Only one voucher can be created with a custom code',
            value => value === 1
          ),
        otherwise: yup
          .number()
          .min(1, 'At least one voucher needs to be created')
          .max(
            MAX_VOUCHERS,
            `We don't recommend creating more than ${MAX_VOUCHERS} distinct vouchers`
          ),
      }),
    usageLimit: yup
      .number()
      .required()
      .when('howMany', {
        is: value => value > 1,
        then: yup
          .number()
          .test(
            'howMany and usageLimit',
            'When creating distinct vouchers, each can only be used once',
            value => value === 1
          ),
      }),
    onePerCustomer: yup.bool().required(),
    active: yup.bool().required(),
  },
  [
    ['discountAmount', 'discountPercentage'],
    ['startDate', 'endDate'],
    ['howMany', 'key'],
    ['usageLimit', 'howMany'],
  ]
)

import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import InviteCustomerDiscountModal from './InviteMemberToDiscountModal'
import GET_MEMBER_DISCOUNTS from './queries/getMemberDiscounts.query'
import {
  Button,
  ButtonGroup,
  Card,
  HTMLTable,
  NonIdealState,
} from '@blueprintjs/core'
import { useContext } from 'react'
import { MembersDiscountContext } from './MembersDiscountProvider'
import FilterRow from '@components/FilterRow/FilterRow'
import { Search, Pager } from '@components/Toolbar'
import MemberDiscountsRow from './MemberDiscountsRow'
import EditMemberDiscountDrawer from './EditMemberDiscountDrawer'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import { useQueryParams, StringParam } from 'use-query-params'
import { useSearchQueryParam } from '../Toolbar/Search/useSearchQueryParam'

const DEFAULT_RECORDS = 25
const DEFAULT_PAGINATION_STATE = {
  total: null,
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  navigationDisabled: false,
}

const MemberDiscounts = ({ restaurantId }) => {
  const [{ discountId }, setQueryParams] = useQueryParams({
    discountId: StringParam,
  })
  const { setModalInviteMemberToDiscountOpen } = useContext(
    MembersDiscountContext
  )

  const [memberDiscountsPagination, setMemberDiscountsPagination] = useState(
    DEFAULT_PAGINATION_STATE
  )
  const { searchValue } = useSearchQueryParam()
  useEffect(() => {
    if (searchValue) {
      setMemberDiscountsPagination(DEFAULT_PAGINATION_STATE)
    }
  }, [searchValue, setMemberDiscountsPagination])

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (
      memberDiscountsPagination.skip + memberDiscountsPagination.first <
      memberDiscountsPagination.total
    ) {
      setMemberDiscountsPagination({
        ...memberDiscountsPagination,
        skip: limitNext(
          memberDiscountsPagination.skip,
          memberDiscountsPagination.first,
          limit
        ),
        first: DEFAULT_RECORDS,
        last: null,
      })
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < memberDiscountsPagination.defaultNmbRecords
      ? currentCursor
      : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setMemberDiscountsPagination({
      ...memberDiscountsPagination,
      skip: limitPrevious(
        memberDiscountsPagination.skip,
        memberDiscountsPagination.first
      ),
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const setFilterState = (count, totalCount) => {
    if (memberDiscountsPagination.total !== totalCount) {
      setMemberDiscountsPagination({
        ...memberDiscountsPagination,
        total: totalCount,
      })
    }
    if (memberDiscountsPagination.outcomeLength !== count) {
      setMemberDiscountsPagination({
        ...memberDiscountsPagination,
        outcomeLength: count,
      })
    }
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setMemberDiscountsPagination({
      ...memberDiscountsPagination,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    })
  }

  const renderFilterBar = () => (
    <FilterRow>
      <ButtonGroup>
        <Search autoFocus placeholder="Discount Name, Customer ..." />
      </ButtonGroup>
      <Pager
        defaultNmbRecords={DEFAULT_RECORDS}
        goToPrevious={goToPrevious}
        goToNext={goToNext}
        goToPage={goToPage}
        skip={memberDiscountsPagination.skip}
        total={memberDiscountsPagination.total}
        outcomeLength={memberDiscountsPagination.outcomeLength}
        totalCount={memberDiscountsPagination.total}
        dataName={`Member${memberDiscountsPagination.total !== 1 ? 's' : ''}`}
      />
    </FilterRow>
  )

  return (
    <div className="bp3-table-frame">
      {renderFilterBar()}
      <DebouncedQuery
        query={GET_MEMBER_DISCOUNTS}
        variables={{
          restaurantId,
          skip: memberDiscountsPagination.skip,
          first: memberDiscountsPagination.first,
          searchText: searchValue,
          orderBy: 'createdAt_DESC',
        }}
        loaderTitle={'Loading Members'}
      >
        {({
          getMemberDiscountsByRestaurant: { count, totalCount, discounts },
        }) => {
          // total count = number of records that satisfy the filter
          setFilterState(count, totalCount)

          return discounts.length ? (
            <Fragment>
              <Card className={'bp3-nopad'} style={{ height: '100%' }}>
                <div>
                  <HTMLTable interactive={true} bordered={false}>
                    <thead>
                      <tr>
                        <th colSpan={2}>Discount Name</th>
                        <th>Discount</th>
                        <th>Member</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {discounts.map(discount => (
                        <MemberDiscountsRow
                          key={discount.id}
                          discount={discount}
                          onChangeDiscountId={id =>
                            setQueryParams({ discountId: id })
                          }
                        />
                      ))}
                    </tbody>
                  </HTMLTable>
                </div>
              </Card>
              <EditMemberDiscountDrawer
                discounts={discounts}
                discountId={discountId}
                businessId={restaurantId}
                onChangeDiscountId={id => setQueryParams({ discountId: id })}
                refetchVars={{
                  restaurantId,
                  skip: memberDiscountsPagination.skip,
                  first: memberDiscountsPagination.first,
                  searchText: '',
                  orderBy: 'createdAt_DESC',
                }}
              />
            </Fragment>
          ) : (
            <NonIdealState
              icon="add"
              title="Offer Member Discounts"
              description="You can offer your members individual discounts. Great for promotions and customer retention."
              action={
                <Button
                  icon="add"
                  text="Invite Member"
                  onClick={() => setModalInviteMemberToDiscountOpen(true)}
                />
              }
            />
          )
        }}
      </DebouncedQuery>
      <InviteCustomerDiscountModal
        restaurantId={restaurantId}
        refetchVars={{
          restaurantId,
          skip: memberDiscountsPagination.skip,
          first: memberDiscountsPagination.first,
          searchText: '',
          orderBy: 'createdAt_DESC',
        }}
      />
    </div>
  )
}

export default MemberDiscounts

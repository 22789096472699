import React from 'react'
import { func, bool } from 'prop-types'
import { FormGroup, InputGroup, Button, Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import REQUEST_PASSWORD_RESET from './mutations/requestPasswordReset.mutation'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'

const ForgotPasswordModal = ({ closeModal, modalOpen }) => (
  <Dialog isOpen={modalOpen} onClose={closeModal} title="Forgotten Password?">
    <div className={Classes.DIALOG_BODY}>
      <Mutation
        mutation={REQUEST_PASSWORD_RESET}
        onCompleted={({ requestResetPassword }) => {
          successToast(requestResetPassword.message)
          closeModal()
        }}
        onError={defaultErrorHandler}
      >
        {requestResetPassword => (
          <Formik
            initialValues={{
              email: '',
            }}
            onSubmit={values =>
              requestResetPassword({
                variables: {
                  ...values,
                },
              })
            }
          >
            {({ values, handleChange, handleSubmit }) => (
              <form className={Classes.DIALOG_BODY} onSubmit={handleSubmit}>
                <p>
                  No problem, we’ll email you instructions on how to reset it:
                </p>
                <FormGroup>
                  <InputGroup
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </FormGroup>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button text="Recover Password" type="submit" />
                </div>
              </form>
            )}
          </Formik>
        )}
      </Mutation>
    </div>
  </Dialog>
)

ForgotPasswordModal.propTypes = {
  closeModal: func.isRequired,
  modalOpen: bool.isRequired,
}

export default ForgotPasswordModal

import {
  add,
  endOfDay,
  isBefore,
  isValid,
  isWithinInterval,
  min,
  parseISO,
  startOfDay,
} from 'date-fns'
import {
  DATE_FILTER_TYPES,
  getDefaultStartOfRangeDate,
  calculateEndFromStartOfRangeDate,
  formatToDateOnly,
} from '../../../utils/datetime'
import { useQueryParams, StringParam } from 'use-query-params'
import { shiftToRedboxPreMigration } from '../../../utils/dateTimeRangeShifters'

/**
 * @typedef {Object} DateRange
 * @property {string} dateFilterTypeKey - The date filter type key.
 * @property {string} startOfRangeDate - The start date of the range in 'YYYY-MM-DD' format.
 * @property {string} [endOfRangeDate] - The end date of the range in 'YYYY-MM-DD' format
 */

/**
 * @typedef {Object} DateRangeFilterResult
 * @property {string} dateFilterTypeKey - The date filter type key.
 * @property {Date} startOfRangeDateTime - The start of the range date time.
 * @property {Date} endOfRangeDateTime - The end of the range date time.
 * @property {Date} shiftedStartOfRangeDateTime - The start of the range date time, after applying shifter.
 * @property {Date} shiftedEndOfRangeDateTime - The end of the range date time, after applying shifter.
 * @property {Function} setRange - Function to set the range in query params, accepts a DateRange.
 * @property {Function} resetRange - Function to reset the range to the default.
 * @property {Function} unsetRange - Function to unset the range completely.
 * @property {Boolean} isRangeSameAsDefault - Whether the range is different from the default.
 */

/**
 * @typedef {Object} DateRangeFilterOptions
 * @property {string} [defaultFilterTypeKey=DATE_FILTER_TYPES.DAY.key] - The default filter type key.
 * @property {Object} [maxCustomRangeInterval={ months: 1 }] - The maximum custom range interval.
 * @property {Date} [defaultStartOfRangeDate=getDefaultStartOfRangeDate(options.defaultFilterTypeKey)] - The default start of the range date.
 * @property {Date} [defaultEndOfRangeDate=calculateEndFromStartOfRangeDate({
 *   startOfRangeDate: options.defaultStartOfRangeDate,
 *   dateFilterTypeKey: options.defaultFilterTypeKey
 * })] - The default end of the range date.
 * @property {Function} [shifter=identity] - Function to shift the date range (eg to make it use UTC day-start/end, or Redbox 4am day-start/end).
 */

/**
 * Hook which wraps around the useQueryParams hook.
 * It provides Date objects calculated from the dates in query params and the props provided
 *
 * @param {DateRangeFilterOptions} [options] - The options for configuring the date range filter.
 *
 * @returns {DateRangeFilterResult} - An object containing the date filter type key, start and end of the range date time, and setRange function.
 */
export const useDateRangeQueryParams = (options = {}) => {
  const {
    defaultFilterTypeKey = DATE_FILTER_TYPES.DAY.key,
    maxCustomRangeInterval = { months: 1 },
    defaultStartOfRangeDate = getDefaultStartOfRangeDate(defaultFilterTypeKey),
    defaultEndOfRangeDate = calculateEndFromStartOfRangeDate({
      startOfRangeDate: defaultStartOfRangeDate,
      dateFilterTypeKey: defaultFilterTypeKey,
    }),
    shifter = shiftToRedboxPreMigration,
  } = options
  const [
    {
      dateFilterTypeKey = defaultFilterTypeKey,
      startOfRangeDate: startOfRangeDateStr,
      endOfRangeDate: endOfRangeDateStr,
    },
    setRangeQueryParams,
  ] = useQueryParams({
    dateFilterTypeKey: StringParam,
    startOfRangeDate: StringParam,
    endOfRangeDate: StringParam,
  })

  const getStartOfRangeDateTime = () => {
    const parsedDate = parseISO(startOfRangeDateStr)
    // prevent invalid or future dates being pulled from url
    if (isValid(parsedDate) && isBefore(parsedDate, new Date())) {
      return parsedDate
    }
    return defaultStartOfRangeDate
  }
  const startOfRangeDateTime = getStartOfRangeDateTime()

  const getEndOfRangeDateTime = () => {
    // use query param
    if (endOfRangeDateStr) {
      const parsedDate = parseISO(endOfRangeDateStr)
      if (
        isValid(parsedDate) &&
        isWithinInterval(parsedDate, {
          start: startOfRangeDateTime,
          end: new Date(),
        })
      ) {
        return endOfDay(parsedDate)
      }
    }
    // missing/invalid/future end date in url, but there is a start date, so calculate from that
    if (startOfRangeDateStr) {
      return calculateEndFromStartOfRangeDate({
        startOfRangeDate: startOfRangeDateTime,
        dateFilterTypeKey: dateFilterTypeKey,
        maxCustomRangeInterval,
      })
    }
    // fallback to default
    return endOfDay(defaultEndOfRangeDate)
  }
  const endOfRangeDateTime = getEndOfRangeDateTime()

  const {
    range: {
      start: shiftedStartOfRangeDateTime,
      end: shiftedEndOfRangeDateTime,
    },
  } = shifter({
    range: { start: startOfRangeDateTime, end: endOfRangeDateTime },
  })

  // set range query params: unsetting unnecessary values, calculating default values, and formatting dates
  const setRange = range => {
    // non-custom range: format start date and clear end date
    if (range.dateFilterTypeKey !== DATE_FILTER_TYPES.CUSTOM.key) {
      setRangeQueryParams({
        dateFilterTypeKey: range.dateFilterTypeKey,
        startOfRangeDate: formatToDateOnly(startOfDay(range.startOfRangeDate)),
        endOfRangeDate: undefined,
      })
      return
    }
    // custom range with defined end date: clamp values and format dates
    if (range.endOfRangeDate) {
      const clampedEnd = min([
        range.endOfRangeDate,
        endOfDay(add(range.startOfRangeDate, maxCustomRangeInterval)),
        endOfDay(new Date()),
      ])
      setRangeQueryParams({
        dateFilterTypeKey: range.dateFilterTypeKey,
        startOfRangeDate: formatToDateOnly(range.startOfRangeDate),
        endOfRangeDate: formatToDateOnly(clampedEnd),
      })
      return
    }
    // custom range with not defined end date: calculate end and format dates
    setRangeQueryParams({
      dateFilterTypeKey: range.dateFilterTypeKey,
      startOfRangeDate: formatToDateOnly(range.startOfRangeDate),
      endOfRangeDate: formatToDateOnly(
        calculateEndFromStartOfRangeDate({
          ...range,
          maxCustomRangeInterval,
        })
      ),
    })
  }

  // reset the range to the defaults
  const resetRange = () => {
    setRangeQueryParams({
      dateFilterTypeKey: defaultFilterTypeKey,
      startOfRangeDate: formatToDateOnly(defaultStartOfRangeDate),
      endOfRangeDate: formatToDateOnly(defaultEndOfRangeDate),
    })
  }
  const isRangeSameAsDefault =
    dateFilterTypeKey !== defaultFilterTypeKey ||
    startOfRangeDateStr !== formatToDateOnly(defaultStartOfRangeDate) ||
    endOfRangeDateStr !== formatToDateOnly(defaultEndOfRangeDate)

  // unset the range completely
  const unsetRange = () => {
    setRangeQueryParams({
      dateFilterTypeKey: undefined,
      startOfRangeDate: undefined,
      endOfRangeDate: undefined,
    })
  }

  return {
    dateFilterTypeKey,
    startOfRangeDateTime,
    endOfRangeDateTime,
    shiftedStartOfRangeDateTime,
    shiftedEndOfRangeDateTime,
    setRange,
    resetRange,
    unsetRange,
    isRangeSameAsDefault,
  }
}

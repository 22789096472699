import React from 'react'
import cx from 'classnames'
import injectSheet from 'react-jss'
import { styles } from './LinkStyledButton.styles'

const LinkStyledButton = ({
  children,
  classes,
  className = 'bp3-link-text',
  ...buttonProps
}) => (
  <button className={cx(classes.linkStyledButton, className)} {...buttonProps}>
    {children}
  </button>
)

export default injectSheet(styles)(LinkStyledButton)

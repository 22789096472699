import React, { useRef, useContext } from 'react'
import { useDrop } from 'react-dnd'

import ItemTypes from './ItemTypes'
import Row from './Row'
import { MenuListContext } from '@components/Restaurant/Menu/MenuList/menu-list-context'
import { toggleModal, setValue } from '@utils/cacheHelpers'

const MenuItemGroupEmptyDropZone = ({
  outlet,
  path,
  depth,
  parentMenuId,
  menu,
}) => {
  const { dragging, editMenuOrder, saveMenuOrder } = useContext(MenuListContext)
  const ref = useRef(null)
  const newPath = depth === 0 ? [...path] : [...path, 0]

  const openAddItemModal = () => {
    setValue({ parentMenuId, menuId: menu.id })
    toggleModal({ itemModal: true })
  }

  const openAddMenuModal = () => {
    toggleModal({ parentMenuModal: true })
  }

  const [{ canDropMenuItem }, dropMenuItem] = useDrop({
    accept: ItemTypes.MENU_ITEM,
    hover(item, monitor) {
      const over = monitor.isOver({ shallow: true })
      if (over && depth > 0) {
        editMenuOrder({ item, hoverPath: newPath })
      }
    },
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop || depth === 0) {
        return
      }
      saveMenuOrder(item, path, parentMenuId)
    },
    collect: monitor => ({
      canDropMenuItem: monitor.canDrop(),
    }),
  })

  const getCanAcceptMenu = item => {
    const isItsSelf = item.id === menu.id
    const hasChildrenMenus = !!item.menu.childrenMenus.length
    if (hasChildrenMenus || isItsSelf) {
      return false
    }
    return true
  }

  const [{ canDropMenu }, dropMenu] = useDrop({
    accept: [ItemTypes.MENU, ItemTypes.SUB_MENU],
    hover(item, monitor) {
      const over = monitor.isOver({ shallow: true })
      if (over && getCanAcceptMenu(item)) {
        editMenuOrder({ item, hoverPath: newPath })
      }
    },
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (!didDrop && !getCanAcceptMenu(item)) {
        saveMenuOrder(item, path, parentMenuId)
      }
    },
    collect: monitor => {
      const item = monitor.getItem() || {}
      return {
        canDropMenu: monitor.canDrop() && getCanAcceptMenu(item),
      }
    },
  })

  if (!outlet) {
    if (depth === 0) {
      dropMenu(ref)
    } else {
      dropMenuItem(dropMenu(ref))
    }
  }

  const canDrop = canDropMenuItem || canDropMenu
  const opacity = dragging ? (canDrop ? 1 : 0) : 1

  return (
    <div ref={ref} style={{ color: '#0d8050', opacity }}>
      {depth === 0 ? (
        <Row
          icon="plus"
          label="Add Menu"
          canDrag={false}
          iconColor="#0d8050"
          onClick={openAddMenuModal}
        />
      ) : (
        <Row
          icon="plus"
          label="Add Item"
          canDrag={false}
          iconColor="#0d8050"
          onClick={openAddItemModal}
        />
      )}
    </div>
  )
}

export default MenuItemGroupEmptyDropZone

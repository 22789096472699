import React, { Fragment } from 'react'
import {
  FormGroup,
  InputGroup,
  Radio,
  RadioGroup,
  Intent,
  ControlGroup,
  Tag,
  Icon,
} from '@blueprintjs/core'
import { string, func, shape, number, array, object, bool } from 'prop-types'

const Form = ({ errors, setFieldValue, values, handleChange }) => (
  <Fragment>
    <FormGroup
      label="Option Name"
      helperText={errors.name}
      intent={errors.name ? Intent.DANGER : Intent.NONE}
    >
      <InputGroup
        placeholder="Burger toppings"
        name="name"
        onChange={handleChange}
        large
        value={values.name}
        autoFocus
      />
    </FormGroup>
    <RadioGroup
      label="Is this option required?"
      inline={true}
      name="required"
      selectedValue={values.required}
      onChange={event => {
        event.currentTarget.value === 'required'
          ? setFieldValue('minOptions', 1)
          : setFieldValue('minOptions', 0)
        handleChange(event)
      }}
    >
      <Radio label="Required" value="required" />
      <Radio label="Optional" value="optional" />
    </RadioGroup>
    <br />
    <FormGroup label="How many items can the customer choose?" />
    <ControlGroup>
      {values.required === 'required' && (
        <Fragment>
          <Tag minimal>Min</Tag>
          <InputGroup
            type="number"
            name="minOptions"
            onChange={handleChange}
            value={values.minOptions}
            intent={errors.minOptions ? Intent.DANGER : Intent.NONE}
          />
        </Fragment>
      )}
      <Tag minimal>Max</Tag>
      <InputGroup
        type="number"
        name="maxOptions"
        max={values.optionItems.length + values.newOptionItems.length}
        onChange={handleChange}
        value={values.maxOptions}
        intent={errors.maxOptions ? Intent.DANGER : Intent.NONE}
      />
    </ControlGroup>
    <br />
    {values.maxOptions >
    values.optionItems.length + values.newOptionItems.length ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#EC615B1A',
          color: '#EC615B',
          margin: '5px',
          marginBottom: '10px',
          padding: ' 10px 0px 0px 15px',
          alignContent: 'center',
        }}
      >
        <Icon icon="warning-sign" style={{ marginRight: '2px' }} />
        <p>
          Option max cannot be higher than number of options available. Please
          set max to {values.optionItems.length + values.newOptionItems.length}{' '}
          or less.
        </p>
      </div>
    ) : null}
  </Fragment>
)

Form.propTypes = {
  option: shape({
    name: string,
    minOptions: number,
    maxOptions: number,
    optionItems: array,
  }),
  values: object,
  handleChange: func,
  setFieldValue: func,
  handleSubmit: func,
  classes: object,
  setFieldTouched: func,
  touched: object,
  addOption: func,
  errors: object,
  mutation: func,
  edit: bool,
}

export default Form

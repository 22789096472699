import React, { Fragment, useContext, useMemo, useState } from 'react'
import {
  Button,
  Intent,
  Card,
  AnchorButton,
  Callout,
  H5,
  FormGroup,
  Icon,
  H4,
  Divider,
  Drawer,
  Classes,
} from '@blueprintjs/core'

import Query from '@components/Query/Query'
import { matchType } from '@utils/types'

import { Row, Col } from 'react-simple-flex-grid'
import GET_OUTLET_FINANCIALS from './queries/getOutletFinancials.query'
import CONNECT_OUTLET_LINK from './mutations/connectOutlet.mutation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { Mutation } from 'react-apollo'
import { isAtLeastPartner } from '@stores/userStore'
import CopyText from '@components/CopyText/CopyText'
import {
  stripeAccountSessionAppearanceLight,
  stripeAccountSessionAppearanceDark,
} from '@utils/stripe'
import { loadConnectAndInitialize } from '@stripe/connect-js'
import {
  ConnectComponentsProvider,
  ConnectPayouts,
  ConnectNotificationBanner,
  ConnectAccountManagement,
  ConnectAccountOnboarding,
} from '@stripe/react-connect-js'
import { KycCallout } from '../shared/KycCallout'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'

const Financials = ({ match }) => {
  const outletId = match.params.outlet
  const [isLoading, setLoading] = useState(false)
  const [isBannerVisible, setBannerVisible] = useState(false)
  const [isOnboarding, setOnboarding] = useState(false)
  const [stripeAccountSession, setStripeAccountSession] = useState({
    clientPublicKey: null,
    clientSecret: null,
  })
  const { dark } = useContext(PageLayoutContext)
  const stripeConnectInstance = useMemo(
    () =>
      stripeAccountSession && stripeAccountSession.clientPublicKey
        ? loadConnectAndInitialize({
            publishableKey: stripeAccountSession.clientPublicKey || null,
            appearance: dark
              ? stripeAccountSessionAppearanceDark
              : stripeAccountSessionAppearanceLight,
            fetchClientSecret: async () => {
              return stripeAccountSession.clientSecret || null
            },
          })
        : null,
    [stripeAccountSession, dark]
  )

  const handleBannerStateChange = response => {
    setBannerVisible(response.total > 0)
  }

  return (
    <Query
      query={GET_OUTLET_FINANCIALS}
      variables={{ id: outletId }}
      loaderTitle={'Loading Financials'}
      onCompleted={data => {
        const [outlet] = data.getOutlets.outlets
        setStripeAccountSession(outlet.stripeAccountSession)
      }}
    >
      {({ getOutlets: { outlets } }) => {
        const [outlet] = outlets
        if (!outlet) return 'Unable to find outlet.'

        const getOutletValidationErrors = () => {
          if (!outlet.contactAddress) {
            return {
              message:
                'Unable to find outlet address, please setup your outlet business contact details and location.',
              link: `/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`,
            }
          }

          if (!outlet.contactName) {
            return {
              message:
                'Unable to find outlet contact name, please setup your outlet business contact details and location.',
              link: `/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`,
            }
          }

          if (!outlet.contactEmail) {
            return {
              message:
                'Unable to find outlet contact email, please setup your outlet business contact details and location.',
              link: `/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`,
            }
          }

          if (!outlet.companyType) {
            return {
              message:
                'Business type (Limited / Individual) has not been configured.',
              link: `/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`,
            }
          }

          if (
            outlet.companyType === 'COMPANY' &&
            (!outlet.companyLegalName || !outlet.companyNumber)
          ) {
            return {
              message:
                'Legal Company Name or Registration Number has not been provided.',
              link: `/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`,
            }
          }

          return null // No errors
        }

        setStripeAccountSession(outlet.stripeAccountSession)

        const validationError = getOutletValidationErrors()

        // Onboarding:

        if (!outlet.stripeConnect) {
          return (
            <Fragment>
              <Row gutter={24}>
                <Col lg={8} md={7} sm={12} xs={12}>
                  <Card>
                    {outlet.marketplace.stripeOnboarding ? (
                      <Fragment>
                        <Drawer
                          canOutsideClickClose={false}
                          isOpen={isOnboarding}
                          title={'Account Verification'}
                          onClose={() => setOnboarding(false)}
                        >
                          {stripeConnectInstance && (
                            <ConnectComponentsProvider
                              connectInstance={stripeConnectInstance}
                            >
                              <div className={Classes.DRAWER_BODY}>
                                <div className={Classes.DIALOG_BODY}>
                                  <ConnectAccountOnboarding
                                    onExit={() => {
                                      setOnboarding(false)
                                      window.location.reload()
                                    }}
                                  />
                                </div>
                              </div>
                            </ConnectComponentsProvider>
                          )}
                        </Drawer>
                        <H4>Paybox - Payment Processing Onboarding</H4>
                        <p className="bp3-running-text">
                          To start accepting payments and enabling direct
                          transfers to your bank account, we need to verify your
                          business details. This process ensures that your
                          transactions are secure and compliant with financial
                          regulations.
                        </p>
                        <Divider />
                        <br />

                        {validationError ? (
                          <Callout
                            intent={Intent.WARNING}
                            title="Incomplete Details"
                          >
                            <p>{validationError.message}</p>
                            <AnchorButton href={validationError.link} outlined>
                              Update Details
                            </AnchorButton>
                          </Callout>
                        ) : (
                          <Fragment>
                            <H5>What You’ll Need</H5>
                            <div className="bp3-running-text">
                              <p>
                                Please have these details ready to ensure a
                                smooth and quick onboarding process.
                              </p>

                              <Callout
                                title="Mobile Phone Authentication"
                                intent="warning"
                              >
                                As part of verifying your identity, we will
                                perform a two-factor authentication (2FA) check
                                by sending a code to your mobile phone.{' '}
                                <strong>
                                  Please ensure you have access to your phone.
                                </strong>
                              </Callout>

                              <strong>
                                Business or Sole Trader Information:
                              </strong>
                              <ul>
                                <li>Legal Business Name or Sole Trader Name</li>
                                <li>Registered business address</li>
                                <li>
                                  Business type (e.g., Limited Company, Sole
                                  Trader, Non-Profit)
                                </li>
                                <li>
                                  Company Registration Number
                                  <span>(if applicable)</span>
                                </li>
                              </ul>

                              <strong>Personal Identification:</strong>
                              <ul>
                                <li>
                                  <strong>Government-Issued ID</strong>
                                  <br />A valid passport, driver's license, or
                                  national ID card.
                                </li>
                                <li>
                                  <strong>Proof of Address</strong>
                                  <br />A recent utility bill, bank statement,
                                  or official government document showing your
                                  name and address.
                                </li>
                                <li>
                                  <strong>Date of Birth</strong>
                                  <br />
                                  To verify your identity as required by
                                  financial regulations.
                                </li>
                              </ul>

                              <strong>Bank Account Information:</strong>
                              <ul>
                                <li>
                                  <strong>Bank Account Number</strong>
                                  <br />
                                  The account number where you'd like to receive
                                  your payouts.
                                </li>
                                <li>
                                  <strong>Sort Code</strong>
                                  <br />
                                  (for UK accounts) or equivalent routing number
                                  for your country.
                                </li>
                                <li>
                                  <strong>Bank Statement</strong>
                                  <br />A recent statement to confirm ownership
                                  of the bank account.
                                </li>
                              </ul>
                            </div>
                            <br />

                            <div className="bp-card-footer-actions">
                              {outlet.stripeAccountSession ? (
                                <Fragment>
                                  <Button
                                    text="Continue Verification"
                                    rightIcon="chevron-right"
                                    intent="success"
                                    onClick={() => {
                                      setOnboarding(true)
                                    }}
                                  />
                                </Fragment>
                              ) : (
                                <Mutation
                                  mutation={CONNECT_OUTLET_LINK}
                                  onCompleted={() => {
                                    setLoading(false)
                                    setOnboarding(true)
                                  }}
                                  onError={error => {
                                    setLoading(false)
                                    defaultErrorHandler(error)
                                  }}
                                  refetchQueries={[
                                    {
                                      query: GET_OUTLET_FINANCIALS,
                                      variables: { id: outletId },
                                    },
                                  ]}
                                >
                                  {connectOutlet => {
                                    return (
                                      <Button
                                        text="Begin Verification"
                                        rightIcon="chevron-right"
                                        disabled={
                                          !outlet.marketplace.stripeOnboarding
                                        }
                                        intent="success"
                                        loading={isLoading}
                                        onClick={() => {
                                          setLoading(true)
                                          connectOutlet({
                                            variables: { outletId: outlet.id },
                                          })
                                        }}
                                      />
                                    )
                                  }}
                                </Mutation>
                              )}{' '}
                              <AnchorButton
                                href="https://support.redbox.systems/docs/business-guide-to-direct-payouts-from-paybox"
                                minimal
                                rightIcon="help"
                                intent={Intent.PRIMARY}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Help Guides
                              </AnchorButton>
                            </div>
                          </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Callout intent={Intent.WARNING}>
                          {outlet.marketplace.name} does not support direct
                          payment at this time.
                        </Callout>
                        <br />
                        <div className="bp-card-footer-actions">
                          <Button
                            text="Set Up Payments"
                            intent={Intent.NONE}
                            rightIcon="share"
                            disabled
                          />
                        </div>
                      </Fragment>
                    )}
                  </Card>
                </Col>
                <Col lg={4} md={5} sm={12} xs={12}>
                  <KycCallout />
                </Col>
              </Row>
            </Fragment>
          )
        }

        return (
          stripeConnectInstance && (
            <Fragment>
              {outlet.stripeConnect && !outlet.stripeConnect.payoutsEnabled && (
                <Fragment>
                  <Callout
                    intent={Intent.WARNING}
                    icon={'error'}
                    title="Earnings Payments Suspended"
                  >
                    Your account may need further verification to enable bank
                    payments.
                  </Callout>
                  <br />
                </Fragment>
              )}
              {outlet.stripeConnect && !outlet.stripeConnect.chargesEnabled && (
                <Fragment>
                  <Callout
                    intent={Intent.DANGER}
                    icon={'ban-circle'}
                    title="Ordering Disabled"
                  >
                    Your account may need further verification to process card
                    payments.
                  </Callout>
                  <br />
                </Fragment>
              )}
              <ConnectComponentsProvider
                connectInstance={stripeConnectInstance}
              >
                <div
                  style={
                    isBannerVisible
                      ? {
                          marginBottom: '24px',
                        }
                      : {}
                  }
                >
                  <ConnectNotificationBanner
                    collectionOptions={{
                      fields: 'eventually_due',
                      futureRequirements: 'include',
                    }}
                    onNotificationsChange={handleBannerStateChange}
                  />
                </div>
                <Row gutter={24}>
                  <Col lg={8} md={7} sm={12} xs={12}>
                    <Card>
                      <H5>Payouts</H5>
                      <ConnectPayouts />
                    </Card>
                  </Col>
                  <Col lg={4} md={5} sm={12} xs={12}>
                    {outlet.stripeConnect && (
                      <Fragment>
                        <Card>
                          <H5 className="bp3-heading">Account</H5>
                          {outlet.stripeConnect.payoutSchedule && (
                            <span className="bp3-text-small bp3-text-muted">
                              {`Earnings are automatically paid to your account ${outlet.stripeConnect.payoutSchedule.interval} on a ${outlet.stripeConnect.payoutSchedule.anchor}.`}
                            </span>
                          )}
                          {outlet.stripeId && isAtLeastPartner() && (
                            <div style={{ marginTop: 12 }}>
                              {outlet.stripeId && (
                                <FormGroup label="Paybox Account">
                                  <CopyText
                                    leftIcon={
                                      <Icon
                                        icon="tick-circle"
                                        intent="success"
                                      />
                                    }
                                    text={outlet.stripeId}
                                    mono
                                  />
                                </FormGroup>
                              )}
                              {outlet.stripeCustomerPayboxId && (
                                <FormGroup
                                  label="Partner Customer"
                                  helperText="Customer used for Partners Billing"
                                >
                                  <CopyText
                                    leftIcon={
                                      <Icon
                                        icon={'tick-circle'}
                                        intent="success"
                                      />
                                    }
                                    text={outlet.stripeCustomerPayboxId}
                                    mono
                                  />
                                </FormGroup>
                              )}
                            </div>
                          )}
                          <div style={{ marginTop: 24 }}>
                            <ConnectAccountManagement />
                          </div>
                        </Card>
                      </Fragment>
                    )}
                  </Col>
                </Row>
              </ConnectComponentsProvider>
            </Fragment>
          )
        )
      }}
    </Query>
  )
}

Financials.propTypes = {
  match: matchType,
}

export default Financials

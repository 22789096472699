import React from 'react'
import { string, object } from 'prop-types'
import { Classes } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { modalType } from '@utils/types'
import { Query, Mutation } from 'react-apollo'
import ADD_MENU_ITEMS_GROUP from '@components/Restaurant/Menu/mutations/addMenuItemsGroup.mutation'
import GET_PARENT_MENUS from '@components/Restaurant/Menu/queries/getParentMenus.query'
import GET_SUB_MENUS from '@components/Restaurant/Menu/queries/getSubMenus.query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import GET_MENU_TREE from '@components/Restaurant/Menu/queries/getMenuTree.query'

import MenuForm from './MenuForm'
import { successToast } from '@utils/toast'
import { toggleModal } from '@utils/cacheHelpers'
import get from 'lodash/get'

const AddSubMenuModal = ({
  modalOpen,
  modalClose,
  restaurantId,
  parentMenuId,
}) => (
  <Dialog
    isOpen={modalOpen}
    onClose={modalClose}
    title="Add Menu"
    canOutsideClickClose={false}
  >
    <Query
      query={GET_SUB_MENUS}
      variables={{ id: restaurantId, menuId: parentMenuId }}
    >
      {({ data }) => {
        const position = get(
          data,
          'getRestaurants.restaurants[0].menuItemsGroups[0].childrenMenus.length',
          0
        )

        return (
          <div className={Classes.DIALOG_BODY}>
            <Mutation
              mutation={ADD_MENU_ITEMS_GROUP}
              onError={defaultErrorHandler}
              onCompleted={({ addMenuItemsGroup }) => {
                successToast(addMenuItemsGroup.message)
                toggleModal({
                  subMenuModal: false,
                  subMenuId: addMenuItemsGroup.menuItemsGroup.id,
                })
              }}
              refetchQueries={[
                {
                  query: GET_MENU_TREE,
                  variables: {
                    id: restaurantId,
                  },
                },
                {
                  query: GET_PARENT_MENUS,
                  variables: {
                    id: restaurantId,
                  },
                },
                {
                  query: GET_SUB_MENUS,
                  variables: {
                    id: restaurantId,
                    menuId: parentMenuId,
                  },
                },
              ]}
            >
              {addMenuItemsGroup => (
                <MenuForm
                  mutation={addMenuItemsGroup}
                  restaurantId={restaurantId}
                  menuId={parentMenuId}
                  position={position}
                />
              )}
            </Mutation>
          </div>
        )
      }}
    </Query>
  </Dialog>
)

AddSubMenuModal.propTypes = {
  ...modalType,
  classes: object,
  editedMenu: string,
}

export default AddSubMenuModal

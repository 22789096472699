import * as yup from 'yup'
import { editPartnerStructure } from '../../../validation/partner'
import { address } from '../../../validation/address'
import { vatNumberStructure } from '../../../validation/vat-number'

export const validation = yup
  .object()
  .shape(editPartnerStructure)
  .concat(yup.object().shape({ contactAddress: yup.object().shape(address) }))
  .concat(yup.object().shape(vatNumberStructure))

import React, { Fragment, useEffect, useState } from 'react'
import Query from '@components/Query/Query'
import injectSheet from 'react-jss'
import { bool, object, func, string } from 'prop-types'
import {
  Divider,
  FormGroup,
  Button,
  TextArea,
  Icon,
  Intent,
  Checkbox,
  Switch,
  InputGroup,
  HTMLSelect,
  Tag,
  Popover,
  PopoverInteractionKind,
  Classes,
  RadioGroup,
  Radio,
  Callout,
  Card,
  HTMLTable,
  Drawer,
  HotkeysTarget2,
} from '@blueprintjs/core'
import { withFormik } from 'formik'
import { Mutation } from 'react-apollo'
import { penceToPounds, numberToPence } from '@utils/helpers'
import { validation } from '@components/Restaurant/Menu/validation/itemFormValidation'
import EDIT_MENU_ITEM from '@components/Restaurant/Menu/mutations/editMenuItem.mutation'
import ARCHIVE_ITEM from '@components/Restaurant/Menu/mutations/archiveMenuItem.mutation'
import GET_MENU_ITEMS from '@components/Restaurant/Menu/queries/getMenuItems.query'
import GET_OUTLET_MENU_ITEMS from '@components/Restaurant/Menu/queries/getOutletMenuItems.query'
import MARK_OUTLET_MENU_ITEM_SOLD_OUT from '@components/Restaurant/Menu/mutations/markOutletMenuItemSoldOut.mutation'
import GET_ITEM_DETAILS, {
  GET_ITEM_OPTIONS,
} from '@components/Restaurant/Menu/queries/getItemDetails.query'
import GET_OUTLET_ITEM_DETAILS from '@components/Restaurant/Menu/queries/getOutletItemDetails.query'
import GET_VATRATE from '../queries/getVatRate.query'

import { setValue, togglePane } from '@utils/cacheHelpers'
import { loadingToast, successToast } from '@utils/toast'
import { styles } from './ItemDetails.styles'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import Currency from '@components/Currency/Currency'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { withRouter } from 'react-router'
import get from 'lodash/get'
import GET_PARENT_MENUS from '@components/Restaurant/Menu/queries/getParentMenus.query'
import GET_MENU_TREE from '@components/Restaurant/Menu/queries/getMenuTree.query'
import moment from 'moment-timezone'
import { DatePicker } from '@blueprintjs/datetime'
import GET_SOLD_OUT_AT_OUTLETS from '../queries/getSoldOutAtOutlets.query'
import GET_SUB_MENUS from '../queries/getSubMenus.query'
import UploadedImages from '@components/ImageUpload/UploadedImages'
import ImageUploadDirect, {
  ImageUploadDirectSingle,
} from '@components/ImageUpload/ImageUploadDirect'
import { Row, Col } from 'react-simple-flex-grid'
import MenuItemOptions from './MenuItemOptions'
import chiliOutline from '@assets/icons/allergies/chiliOutline.png'

const checkIfRequiredOptionsAreSoldOut = values => {
  if (values.options) {
    const dangerousItem = values.options
      .map(option => {
        return {
          minOptions: option.minOptions,
          availableOptions: option.optionItems.filter(
            optionItem => !optionItem.soldOut
          ),
        }
      })
      .filter(
        option =>
          option.minOptions > 0 && option.availableOptions < option.minOptions
      )
    return dangerousItem.length > 0
  }
}

const ItemDetailsForm = withRouter(
  ({
    handleChange,
    values,
    setFieldValue,
    classes,
    errors,
    setStatus,
    outlet,
    match,
    restaurantId,
    outletId,
    menuId,
    closeMenuItem,
    addOnMenuItem,
  }) => {
    const [loading, setLoading] = useState(false)
    const [nextOpen, setNextOpen] = useState('')
    const [images, setImages] = useState(values.images)

    const [outletDrawerOpen, setOutletDrawerOpen] = useState({
      id: '',
      name: '',
      open: false,
      nextOpen: '',
    })
    const [timescaleValue, setTimescaleValue] = useState({
      name: 'NO_TIMESCALE/no timescale',
      value: 'no timescale',
    })
    const dateNow = moment.parseZone(Date()).add(1, 'h').utc().format()

    const submit = ({ mutation, values, outlet, match, outletId }) => {
      setLoading(true)
      loadingToast('Saving...')

      const variables = outlet
        ? {
            outletId: outletId || match.params.outlet,
            menuItemIds: [values.id],
            areSoldOut: values.outletSoldOut || false,
            areHidden:
              values.outletHidden ||
              (values.outletHidden && values.outletHidden[0] === 'on')
                ? true
                : false,
            soldOutUntil: values.id
              ? [
                  {
                    id: values.id,
                    soldOutUntil:
                      values.soldOutUntil &&
                      values.soldOutUntil !== 'no timescale' &&
                      values.outletSoldOut
                        ? values.soldOutUntil
                        : null,
                  },
                ]
              : null,
            menuId: values.menuId,
          }
        : {
            ...values,
            price: numberToPence(values.price),
            costPrice: numberToPence(values.costPrice),
            minimumPurchasePrice: numberToPence(values.minimumPurchasePrice),
            vatRate: parseFloat(values.vatRate),
            spiceLevel: parseInt(values.spiceLevel),
            maxPurchaseQuantity: parseInt(values.maxPurchaseQuantity),
          }

      mutation({
        variables,
      })
    }

    const isSoldOut = (itemId, soldOutUntilItems) =>
      soldOutUntilItems.find(
        ({ id, soldOutUntil }) =>
          id === itemId &&
          soldOutUntil < moment.parseZone(Date()).utc().format()
      )

    const findNextOpeningTime = () => {
      const newOpeningTime = values.nextOpen
      setNextOpen(newOpeningTime)

      if (values.outletSoldOut) {
        const soldOutUntil = values.soldOutUntilItems || []
        const hasSoldOutUntil = soldOutUntil.find(({ id }) => id === values.id)
        const soldOutUntilItemValue = hasSoldOutUntil
          ? hasSoldOutUntil.soldOutUntil
          : null

        if (soldOutUntilItemValue)
          setTimescaleValue({
            name:
              soldOutUntilItemValue === newOpeningTime
                ? `NEXT_OPEN/${newOpeningTime}`
                : 'CUSTOM',
            value: soldOutUntilItemValue,
          })
      }
    }

    const noIdImages = (imagesToProcess = []) =>
      imagesToProcess.map(({ id: _, ...img }, position) => ({
        ...img,
        position,
      }))

    useEffect(() => {
      if (outlet) findNextOpeningTime()
    }, [])

    const handleReCache = () => {
      const soldOutUntilParsed = values.soldOutUntilItems || []
      const filtered = soldOutUntilParsed.filter(({ id }) => id !== values.id)
      if (values.soldOutUntil !== 'no timescale')
        filtered.push({ id: values.id, soldOutUntil: values.soldOutUntil })
      setValue({
        soldOutUntilItems: JSON.stringify([...filtered]),
      })
    }
    return (
      <Mutation
        mutation={outlet ? MARK_OUTLET_MENU_ITEM_SOLD_OUT : EDIT_MENU_ITEM}
        onError={e => {
          defaultErrorHandler(e)
          setLoading(false)
        }}
        refetchQueries={
          outlet
            ? [
                { query: GET_MENU_TREE, variables: { id: restaurantId } },
                {
                  query: GET_OUTLET_MENU_ITEMS,
                  variables: {
                    outletId,
                    menuItemGroupId: menuId,
                  },
                },
                {
                  query: GET_PARENT_MENUS,
                  variables: { id: restaurantId },
                },
                {
                  query: GET_ITEM_DETAILS,
                  variables: { id: values.id, menuId },
                },
                {
                  query: GET_SUB_MENUS,
                  variables: { id: restaurantId, menuId },
                },
                {
                  query: GET_SOLD_OUT_AT_OUTLETS,
                  variables: {
                    restaurantId,
                  },
                },
              ]
            : [{ query: GET_MENU_TREE, variables: { id: restaurantId } }]
        }
        onCompleted={complete => {
          setLoading(false)
          if (outlet) {
            handleReCache()
          } else {
            //set images to save response
            setImages(complete.editMenuItem.menuItem.images)
          }
          successToast(
            get(
              complete,
              outlet
                ? 'outletMenuItemSoldOutStatus.message'
                : 'editMenuItem.message'
            )
          )
        }}
      >
        {mutation => (
          <HotkeysTarget2
            hotkeys={[
              {
                combo: 'cmd+s',
                preventDefault: true,
                global: true,
                label: 'Save',
                onKeyDown: () => {
                  if (outlet) {
                    values.soldOutUntil = timescaleValue.value
                  }
                  if (
                    values.thumbnail &&
                    typeof values.thumbnail === 'object'
                  ) {
                    values.thumbnail = values.thumbnail.src
                  } else if (values.thumbnail === undefined) {
                    values.thumbnail = null
                  }
                  values.images = noIdImages(images)
                  submit({ mutation, values, outlet, match, outletId })
                },
              },
            ]}
          >
            {() => (
              <Fragment>
                <form
                  className="bp3-drawer-form"
                  onSubmit={e => {
                    e.preventDefault()
                    if (outlet) {
                      values.soldOutUntil = timescaleValue.value
                    }
                    if (
                      values.thumbnail &&
                      typeof values.thumbnail === 'object'
                    ) {
                      values.thumbnail = values.thumbnail.src
                    } else if (values.thumbnail === undefined) {
                      values.thumbnail = null
                    }
                    values.images = noIdImages(images)
                    submit({ mutation, values, outlet, match, outletId })
                  }}
                >
                  {outlet ? (
                    <Fragment>
                      <div className="bp3-drawer-content">
                        <FormGroup label="Is this item sold out?">
                          <Switch
                            label="Sold Out"
                            onChange={e =>
                              setFieldValue(
                                'outletSoldOut',
                                e.currentTarget.checked
                              )
                            }
                            name="outletSoldOut"
                            checked={values.outletSoldOut}
                          />
                        </FormGroup>
                        {values.outletSoldOut ? (
                          <div style={{ padding: '0px 0px 10px 10px' }}>
                            <RadioGroup
                              name="soldOutUntil"
                              onChange={e => {
                                //name or label cannot be accessed through on change event, so find name and value in event value
                                setTimescaleValue({
                                  name: e.target.value,
                                  value:
                                    e.target.value === 'CUSTOM'
                                      ? dateNow
                                      : e.target.value.split('/')[1],
                                })
                                setFieldValue('soldOutUntil', e.target.value)
                                handleChange(e)
                              }}
                              selectedValue={timescaleValue.name}
                            >
                              <Radio
                                name="NO_TIMESCALE"
                                label="No timescale"
                                value={'NO_TIMESCALE/no timescale'}
                              />
                              {values.nextOpen ? (
                                <Radio
                                  name="NEXT_OPEN"
                                  label={`Until next opening time (${moment(
                                    values.nextOpen
                                  ).fromNow()})`}
                                  value={`NEXT_OPEN/${nextOpen}`}
                                />
                              ) : null}

                              <Radio
                                name="CUSTOM"
                                label="Custom (please select)"
                                value={`CUSTOM`}
                              />
                            </RadioGroup>
                            {timescaleValue.name === 'CUSTOM' ? (
                              <DatePicker
                                defaultValue={new Date(timescaleValue.value)}
                                highlightCurrentDay
                                locale="en_GB"
                                timePrecision="minute"
                                onChange={val => {
                                  setTimescaleValue({
                                    name: `CUSTOM`,
                                    value: moment.parseZone(val).utc().format(),
                                  })
                                }}
                              />
                            ) : null}
                            {timescaleValue.name === 'CUSTOM' ? (
                              <Callout
                                style={{
                                  margin: '10px 0px',
                                  padding: '10px',
                                }}
                              >
                                <Icon
                                  icon="stopwatch"
                                  style={{ padding: '0px 5px' }}
                                />
                                Back in stock{' '}
                                {moment(timescaleValue.value).fromNow()}
                              </Callout>
                            ) : null}
                          </div>
                        ) : null}

                        <FormGroup label="Do you want to hide this item?">
                          <Switch
                            label="Hide Item"
                            onChange={handleChange}
                            name="outletHidden"
                            checked={values.outletHidden}
                          />
                        </FormGroup>
                      </div>
                      <div className="bp3-drawer-footer-actions">
                        <Button
                          text="Save"
                          type="submit"
                          loading={loading}
                          intent={Intent.DEFAULT}
                        />
                      </div>
                    </Fragment>
                  ) : (
                    <Query
                      fetchPolicy="network-only"
                      query={outlet ? [] : GET_ITEM_OPTIONS}
                      variables={outlet ? {} : { id: values.id }}
                    >
                      {menuData => {
                        const itemOptions = get(
                          menuData,
                          'getMenuItems.menuItems[0].options'
                        )

                        return (
                          <Fragment>
                            <div className={'bp3-drawer-content'}>
                              <div className={classes.bottomDetails}>
                                {addOnMenuItem ? (
                                  <p>
                                    This is an 'add-on sale' menu item. It will
                                    not show alongside normal menus in the app
                                    and web. Instead it will be offered to the
                                    customer at checkout at the minimum purchase
                                    price.
                                  </p>
                                ) : null}
                                <FormGroup
                                  label="Item Name"
                                  intent={
                                    errors.name ? Intent.DANGER : Intent.NONE
                                  }
                                  helperText={errors.name}
                                  labelFor="name"
                                >
                                  <InputGroup
                                    value={values.name}
                                    onChange={handleChange}
                                    name="name"
                                    large={true}
                                    intent={
                                      errors.name ? Intent.DANGER : Intent.NONE
                                    }
                                  />
                                </FormGroup>
                                {
                                  <FormGroup
                                    label="Description"
                                    intent={
                                      errors.description
                                        ? Intent.DANGER
                                        : Intent.NONE
                                    }
                                    helperText={
                                      errors.description ||
                                      (addOnMenuItem
                                        ? 'Describe what customer should expect.'
                                        : 'Describe what customer should expect, this will be shown on the item detail page.')
                                    }
                                    labelFor="description"
                                  >
                                    <TextArea
                                      value={values.description || ''}
                                      onChange={handleChange}
                                      name="description"
                                      fill={true}
                                    />
                                  </FormGroup>
                                }
                              </div>
                              <Divider />
                              <div className={classes.bottomDetails}>
                                <h3>Pricing</h3>

                                <Row gutter={40}>
                                  <Col md={6} sm={6} xs={12}>
                                    <FormGroup
                                      label="Gross Item Price"
                                      labelInfo={
                                        values.VATinclusive
                                          ? '(Include VAT if applicable)'
                                          : '(Required)'
                                      }
                                      labelFor="price"
                                      intent={
                                        errors.price
                                          ? Intent.DANGER
                                          : Intent.NONE
                                      }
                                      helperText={
                                        errors.price
                                          ? errors.price
                                          : 'The price the customer pays.'
                                      }
                                    >
                                      <CurrencyInput
                                        name="price"
                                        id="price"
                                        fill
                                        defaultValue={values.price}
                                        onUpdate={amount => {
                                          setFieldValue('price', amount)
                                        }}
                                        onKeyPress={e => {
                                          if (e.key === 'Enter') {
                                            e.preventDefault()
                                          }
                                        }}
                                        intent={
                                          errors.price
                                            ? Intent.DANGER
                                            : Intent.NONE
                                        }
                                        rightElement={
                                          values.vatRate > 0 && (
                                            <Tag minimal>
                                              <Currency />
                                              {parseFloat(
                                                values.price -
                                                  values.price /
                                                    (1 +
                                                      1 /
                                                        (values.vatRate / 100))
                                              ).toFixed(2)}{' '}
                                              Net
                                            </Tag>
                                          )
                                        }
                                      >
                                        {values.restaurant.isVATregistered && (
                                          <Fragment>
                                            <Query
                                              query={GET_VATRATE}
                                              showLoader={false}
                                            >
                                              {({
                                                getPlatformVAT: platformVATRate,
                                              }) => {
                                                return (
                                                  <HTMLSelect
                                                    name="vatRate"
                                                    value={values.vatRate}
                                                    onChange={handleChange}
                                                    onBlur={event => {
                                                      const vatRate =
                                                        event.currentTarget
                                                          .value
                                                      setFieldValue(
                                                        'vatRate',
                                                        vatRate
                                                      )
                                                      setFieldValue(
                                                        'VATinclusive',
                                                        event.currentTarget
                                                          .value > 0
                                                      )
                                                    }}
                                                    options={[
                                                      {
                                                        value: '0',
                                                        label: 'VAT 0%',
                                                      },
                                                      {
                                                        value: '5',
                                                        label: 'VAT 5%',
                                                      },
                                                      {
                                                        value: '12.5',
                                                        label: 'VAT 12.5%',
                                                      },
                                                      {
                                                        value: platformVATRate,
                                                        label: `VAT ${platformVATRate}%`,
                                                      },
                                                    ]}
                                                  />
                                                )
                                              }}
                                            </Query>
                                          </Fragment>
                                        )}
                                      </CurrencyInput>
                                      {addOnMenuItem &&
                                      parseFloat(values.price) === 0 ? (
                                        <p
                                          style={{
                                            color: 'red',
                                            paddingTop: '10px',
                                          }}
                                        >
                                          <Icon
                                            icon="warning-sign"
                                            intent={Intent.DANGER}
                                            style={{ paddingRight: '5px' }}
                                          />
                                          Add-on items cannot have a gross item
                                          price of zero.
                                        </p>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} sm={6} xs={12}>
                                    <FormGroup
                                      label="Cost Price"
                                      labelInfo="(Optional)"
                                      labelFor="costPrice"
                                      helperText="The production or cost price of this item, used for managing discounts."
                                      intent={
                                        errors.costPrice
                                          ? Intent.DANGER
                                          : Intent.NONE
                                      }
                                    >
                                      <CurrencyInput
                                        name="costPrice"
                                        id="costPrice"
                                        fill
                                        defaultValue={values.costPrice || 0}
                                        onUpdate={amount => {
                                          setFieldValue('costPrice', amount)
                                        }}
                                        onKeyPress={e => {
                                          if (e.key === 'Enter') {
                                            e.preventDefault()
                                          }
                                        }}
                                        intent={
                                          errors.price
                                            ? Intent.DANGER
                                            : Intent.NONE
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} sm={6} xs={12}>
                                    <FormGroup
                                      label="Minimum Purchase Price"
                                      labelInfo="(Optional)"
                                      labelFor="minimumPurchasePrice"
                                      helperText="This is the price this item will be offered for ('add-on sale' menu items only)."
                                      intent={
                                        errors.minimumPurchasePrice
                                          ? Intent.DANGER
                                          : Intent.NONE
                                      }
                                    >
                                      <CurrencyInput
                                        name="minimumPurchasePrice"
                                        id="minimumPurchasePrice"
                                        fill
                                        defaultValue={
                                          values.minimumPurchasePrice
                                        }
                                        onUpdate={amount => {
                                          setFieldValue(
                                            'minimumPurchasePrice',
                                            amount
                                          )
                                        }}
                                        onKeyPress={e => {
                                          if (e.key === 'Enter') {
                                            e.preventDefault()
                                          }
                                        }}
                                        intent={
                                          errors.price
                                            ? Intent.DANGER
                                            : Intent.NONE
                                        }
                                        rightElement={
                                          values.vatRate > 0 && (
                                            <Tag minimal>
                                              <Currency />
                                              {parseFloat(
                                                values.minimumPurchasePrice -
                                                  values.minimumPurchasePrice /
                                                    (1 +
                                                      1 /
                                                        (values.vatRate / 100))
                                              ).toFixed(2)}{' '}
                                              Net
                                            </Tag>
                                          )
                                        }
                                      />
                                      {parseFloat(values.minimumPurchasePrice) >
                                      parseFloat(values.price) ? (
                                        <p
                                          style={{
                                            color: 'red',
                                            paddingTop: '10px',
                                          }}
                                        >
                                          <Icon
                                            icon="warning-sign"
                                            intent={Intent.DANGER}
                                            style={{ paddingRight: '5px' }}
                                          />
                                          Minimum purchase price cannot be more
                                          than gross item price.
                                        </p>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} sm={6} xs={12}>
                                    <FormGroup
                                      label="Maximum Purchase Quantity"
                                      labelInfo={
                                        addOnMenuItem
                                          ? '(Required)'
                                          : '(Optional)'
                                      }
                                      labelFor="maxPurchaseQuantity"
                                      helperText={
                                        errors.maxPurchaseQuantity
                                          ? errors.maxPurchaseQuantity
                                          : 'The maximum number of this menu item a customer can add to their basket.'
                                      }
                                      intent={
                                        errors.maxPurchaseQuantity
                                          ? Intent.DANGER
                                          : Intent.NONE
                                      }
                                    >
                                      <InputGroup
                                        name="maxPurchaseQuantity"
                                        id="maxPurchaseQuantity"
                                        fill
                                        type="number"
                                        value={values.maxPurchaseQuantity}
                                        onChange={e => {
                                          setFieldValue(
                                            'maxPurchaseQuantity',
                                            e.currentTarget.value
                                          )
                                        }}
                                        onKeyPress={e => {
                                          if (e.key === 'Enter') {
                                            e.preventDefault()
                                          }
                                        }}
                                        intent={
                                          errors.price
                                            ? Intent.DANGER
                                            : Intent.NONE
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                              <Divider />
                              <div className={classes.bottomDetails}>
                                <h3>Flags</h3>
                                <Row gutter={40}>
                                  <Col md={4} sm={4} xs={12}>
                                    <FormGroup label="Dietary Information">
                                      <Checkbox
                                        name="isVegan"
                                        label="Vegan"
                                        checked={values.isVegan}
                                        onChange={handleChange}
                                      />
                                      <Checkbox
                                        name="isVegetarian"
                                        label="Vegetarian"
                                        checked={values.isVegetarian}
                                        onChange={handleChange}
                                      />
                                      <Checkbox
                                        name="isDairyFree"
                                        label="Dairy Free"
                                        checked={values.isDairyFree}
                                        onChange={handleChange}
                                      />
                                      <Checkbox
                                        name="isGlutenFree"
                                        label="Gluten Free"
                                        checked={values.isGlutenFree}
                                        onChange={handleChange}
                                      />
                                      <Checkbox
                                        name="isKeto"
                                        label="Keto"
                                        checked={values.isKeto}
                                        onChange={handleChange}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4} sm={4} xs={12}>
                                    <RadioGroup
                                      label="Spice Level"
                                      name="spiceLevel"
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                      selectedValue={values.spiceLevel}
                                    >
                                      <Radio
                                        name="None"
                                        label="None"
                                        value={'0'}
                                      />
                                      <Radio
                                        name="Mild"
                                        labelElement={
                                          <Fragment>
                                            Mild{' '}
                                            <img
                                              src={chiliOutline}
                                              height="15"
                                              width="15"
                                            />
                                          </Fragment>
                                        }
                                        value={'1'}
                                      />
                                      <Radio
                                        name="Medium"
                                        labelElement={
                                          <Fragment>
                                            Medium{' '}
                                            {Array.from({ length: 2 }).map(
                                              (_, index) => (
                                                <img
                                                  key={index}
                                                  src={chiliOutline}
                                                  height="15"
                                                  width="15"
                                                />
                                              )
                                            )}
                                          </Fragment>
                                        }
                                        value={'2'}
                                      />
                                      <Radio
                                        name="Hot"
                                        labelElement={
                                          <Fragment>
                                            Hot{' '}
                                            {Array.from({ length: 3 }).map(
                                              (_, index) => (
                                                <img
                                                  key={index}
                                                  src={chiliOutline}
                                                  height="15"
                                                  width="15"
                                                />
                                              )
                                            )}
                                          </Fragment>
                                        }
                                        value={'3'}
                                      />
                                    </RadioGroup>
                                  </Col>
                                  <Col md={4} sm={4} xs={12}>
                                    <FormGroup label="Is this item age restricted?">
                                      <Switch
                                        label="Age Restricted"
                                        checked={values.ageRestricted}
                                        onChange={handleChange}
                                        name="ageRestricted"
                                      />
                                    </FormGroup>
                                    <FormGroup label="Is this item a bulky item?">
                                      <Checkbox
                                        name="bulkyItem"
                                        label="Bulky Item"
                                        checked={values.bulkyItem}
                                        onChange={handleChange}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                              <Divider />
                              <div className={classes.bottomDetails}>
                                <h3>Images</h3>

                                <ImageUploadDirectSingle
                                  imageName={'thumbnail'}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  imageLabel="Thumbnail"
                                  setStatus={setStatus}
                                  sizeLimit={1000000}
                                  replace={true}
                                />
                                {!addOnMenuItem ? (
                                  <FormGroup
                                    label="Featured Images"
                                    intent={
                                      errors.images
                                        ? Intent.DANGER
                                        : Intent.NONE
                                    }
                                    helperText={errors.images || ''}
                                    labelFor="images"
                                  >
                                    {images && (
                                      <UploadedImages
                                        images={images}
                                        setImages={setImages}
                                      />
                                    )}
                                    <ImageUploadDirect
                                      images={images}
                                      setImages={setImages}
                                      labelInfo="(Max 1MB)"
                                      sizeLimit={1048576}
                                      helperText={
                                        'Choose images to upload. If you have uploaded a thumbnail, it will be the first image in the carousel.'
                                      }
                                    />
                                  </FormGroup>
                                ) : null}
                              </div>

                              <Divider />
                              <div className={classes.bottomDetails}>
                                <Query
                                  query={GET_SOLD_OUT_AT_OUTLETS}
                                  variables={{ restaurantId }}
                                  showLoader={false}
                                >
                                  {({ getOutlets: { outlets } }) => (
                                    <Fragment>
                                      <h3>Stock Control</h3>
                                      <Card className="bp3-nopad">
                                        <HTMLTable interactive={true}>
                                          <thead>
                                            <tr>
                                              <th>Outlet</th>
                                              <th>Hidden</th>
                                              <th>Sold Out</th>
                                              <th>Back In Stock</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {outlets.map(outlet => (
                                              <tr
                                                key={outlet.id}
                                                onClick={() => {
                                                  setOutletDrawerOpen({
                                                    id: outlet.id,
                                                    name: outlet.name,
                                                    open: true,
                                                    nextOpen: outlet.nextOpen,
                                                  })
                                                  values.openingTimes =
                                                    outlet.openingTimes
                                                  values.soldOutUntilItems =
                                                    outlet.soldOutUntilItems ||
                                                    []
                                                  const soldOutUntilTime =
                                                    values.soldOutUntilItems.find(
                                                      ({ id }) =>
                                                        id === values.id
                                                    )
                                                  if (soldOutUntilTime)
                                                    values.soldOutUntil =
                                                      soldOutUntilTime.soldOutUntil
                                                }}
                                              >
                                                <td className="bp3-link-text">
                                                  {outlet.name}
                                                </td>
                                                <td>
                                                  <Icon
                                                    icon={
                                                      outlet.hiddenMenuItemIds.includes(
                                                        values.id
                                                      )
                                                        ? 'selection'
                                                        : 'circle'
                                                    }
                                                    style={{
                                                      paddingLeft: '12%',
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  {outlet.soldOutMenuItemIds.includes(
                                                    values.id
                                                  ) &&
                                                  !isSoldOut(
                                                    values.id,
                                                    outlet.soldOutUntilItems ||
                                                      []
                                                  ) ? (
                                                    <Icon
                                                      icon="selection"
                                                      style={{
                                                        paddingLeft: '15%',
                                                      }}
                                                    />
                                                  ) : checkIfRequiredOptionsAreSoldOut(
                                                      values
                                                    ) ? (
                                                    <Popover
                                                      interactionKind={
                                                        PopoverInteractionKind.HOVER
                                                      }
                                                      popoverClassName={
                                                        Classes.POPOVER_CONTENT_SIZING
                                                      }
                                                      content={
                                                        <p
                                                          style={{
                                                            textAlign: 'center',
                                                          }}
                                                        >
                                                          Customers will not be
                                                          able to purchase this
                                                          item as it requires a
                                                          selection from options
                                                          that are sold out. We
                                                          recommend marking this
                                                          item as sold out.
                                                        </p>
                                                      }
                                                    >
                                                      <Icon
                                                        icon="warning-sign"
                                                        intent={Intent.DANGER}
                                                        style={{
                                                          paddingLeft: '20px',
                                                        }}
                                                      />
                                                    </Popover>
                                                  ) : (
                                                    <Icon
                                                      icon="circle"
                                                      style={{
                                                        paddingLeft: '15%',
                                                      }}
                                                    />
                                                  )}
                                                </td>
                                                <td>
                                                  {outlet.soldOutMenuItemIds.includes(
                                                    values.id
                                                  ) &&
                                                    (
                                                      outlet.soldOutUntilItems ||
                                                      []
                                                    ).map(
                                                      ({ id, soldOutUntil }) =>
                                                        id === values.id &&
                                                        moment
                                                          .parseZone(Date())
                                                          .isSameOrBefore(
                                                            moment(soldOutUntil)
                                                          )
                                                          ? moment(
                                                              soldOutUntil
                                                            ).fromNow()
                                                          : null
                                                    )}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </HTMLTable>
                                      </Card>
                                    </Fragment>
                                  )}
                                </Query>
                                <Row gutter={40}>
                                  <Col md={6} sm={6} xs={12}>
                                    <FormGroup
                                      label="SKU"
                                      labelFor="SKU"
                                      intent={
                                        errors.SKU ? Intent.DANGER : Intent.NONE
                                      }
                                      helperText="Unique identifier for this item and retailer."
                                    >
                                      <InputGroup
                                        value={values.SKU}
                                        onChange={handleChange}
                                        name="SKU"
                                        fill
                                        intent={
                                          errors.SKU
                                            ? Intent.DANGER
                                            : Intent.NONE
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} sm={6} xs={12}>
                                    <FormGroup
                                      label="UPC"
                                      labelFor="UPC"
                                      helperText="12 digit number, consistent across retailers."
                                      intent={
                                        errors.UPC ? Intent.DANGER : Intent.NONE
                                      }
                                    >
                                      <InputGroup
                                        value={values.UPC}
                                        onChange={handleChange}
                                        name="UPC"
                                        fill
                                        intent={
                                          errors.UPC
                                            ? Intent.DANGER
                                            : Intent.NONE
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>

                              {!addOnMenuItem ? (
                                <Fragment>
                                  <Divider />
                                  <MenuItemOptions
                                    menuItemOptions={itemOptions}
                                    values={values}
                                    classes={classes}
                                    isLoading={loading}
                                  />
                                </Fragment>
                              ) : null}
                            </div>
                            <div className={'bp3-drawer-footer-actions'}>
                              <Mutation
                                mutation={ARCHIVE_ITEM}
                                refetchQueries={[
                                  {
                                    query: GET_MENU_ITEMS,
                                    variables: {
                                      id: values.menuId,
                                      restaurantId: restaurantId,
                                    },
                                  },
                                  {
                                    query: GET_PARENT_MENUS,
                                    variables: { id: values.restaurantId },
                                  },
                                ]}
                                onError={defaultErrorHandler}
                                onCompleted={({ archiveMenuItem }) => {
                                  successToast(archiveMenuItem.message)
                                  togglePane({
                                    itemDetailsOpen: false,
                                  })
                                }}
                              >
                                {archiveMenuItem => (
                                  <ConfirmationPopover
                                    remove={() => {
                                      archiveMenuItem({
                                        variables: {
                                          id: values.id,
                                        },
                                      })
                                      closeMenuItem()
                                    }}
                                    confirmationText="Are you sure you want to delete this menu item?"
                                  >
                                    <Button
                                      text="Delete"
                                      type="button"
                                      intent={Intent.DANGER}
                                      disabled={loading}
                                      minimal="true"
                                      style={{ float: 'left' }}
                                    />
                                  </ConfirmationPopover>
                                )}
                              </Mutation>
                              &nbsp;
                              <Button
                                text="Save"
                                type="submit"
                                loading={loading}
                                intent={Intent.DEFAULT}
                                disabled={
                                  parseFloat(values.minimumPurchasePrice) >
                                    parseFloat(values.price) ||
                                  (addOnMenuItem &&
                                    parseFloat(values.price) === 0)
                                }
                              />
                            </div>
                          </Fragment>
                        )
                      }}
                    </Query>
                  )}
                </form>
                <Drawer
                  title={`Edit Item for ${outletDrawerOpen.name}`}
                  isOpen={outletDrawerOpen.open}
                  onClose={() => {
                    setOutletDrawerOpen({
                      id: '',
                      name: '',
                      open: false,
                      nextOpen: '',
                    })
                    values.soldOutUntil = ''
                  }}
                  size={Drawer.SIZE_SMALL}
                >
                  <QueryProvider
                    restaurantId={values.restaurantId}
                    outletId={outletDrawerOpen.id}
                    nextOpen={outletDrawerOpen.nextOpen}
                    menuId={values.menuId}
                    menuItemId={values.id}
                    outlet={true}
                    closeMenuItem={closeMenuItem}
                    openingTimes={values.openingTimes}
                    soldOutUntilItems={values.soldOutUntilItems}
                  />
                </Drawer>
              </Fragment>
            )}
          </HotkeysTarget2>
        )}
      </Mutation>
    )
  }
)

const BLANK_ITEM = {
  name: '',
  price: 0,
  costPrice: 0,
  minimumPurchasePrice: 0,
  vatRate: 0,
  description: '',
  optionIds: [],
  isVegan: false,
  isVegetarian: false,
  isDairyFree: false,
  isGlutenFree: false,
  isKeto: false,
  spiceLevel: 0,
  ageRestricted: false,
  soldOut: false,
  restaurant: {
    isVATregistered: false,
  },
}

// Allows props to be mapped to form values and form to chance when item is edited.

const ItemDetails = withFormik({
  mapPropsToValues: props => {
    const item = props.item ? props.item : BLANK_ITEM
    return {
      ...item,
      menuId: props.menuId,
      openingTimes:
        typeof props.openingTimes === 'string'
          ? JSON.parse(props.openingTimes)
          : props.openingTimes,
      soldOutUntilItems:
        typeof props.soldOutUntilItems === 'string'
          ? JSON.parse(props.soldOutUntilItems)
          : props.soldOutUntilItems,
      price: penceToPounds(item.price),
      minimumPurchasePrice: penceToPounds(item.minimumPurchasePrice),
      costPrice: penceToPounds(item.costPrice),
      selectedOption: '',
      restaurantId: props.restaurantId,
      nextOpen: props.nextOpen,
      spiceLevel: item.spiceLevel.toString(),
    }
  },
  mapPropsToStatus: () => {
    return {
      previewImageValue: null,
      path: '',
    }
  },
  validationSchema: validation,
})(ItemDetailsForm)

ItemDetailsForm.propTypes = {
  item: object.isRequired,
  classes: object,
  handleChange: func,
  values: object,
  setFieldValue: func,
  errors: object,
  setStatus: func,
  status: object,
  outlet: bool,
  outletId: string,
  nextOpen: string,
}

const ItemDetailsWrapper = injectSheet(styles)(ItemDetails)

const QueryProvider = ({
  restaurantId,
  outletId,
  menuId,
  menuItemId,
  outlet,
  nextOpen,
  closeMenuItem,
  openingTimes,
  soldOutUntilItems,
}) => {
  return (
    <Query
      fetchPolicy="network-only"
      query={outlet ? GET_OUTLET_ITEM_DETAILS : GET_ITEM_DETAILS}
      variables={
        outlet
          ? {
              outletId,
              menuItemId,
            }
          : { id: menuItemId, menuId }
      }
    >
      {menuData => {
        const item = get(
          menuData,
          outlet ? 'getOutletMenuItem' : 'getMenuItems.menuItems[0]'
        )
        const isAddOnMenuItem = get(
          menuData,
          outlet ? '' : 'getMenuItemsGroups.menuItemsGroups[0].addOnItemsMenu'
        )

        return (
          <ItemDetailsWrapper
            item={item}
            menuId={menuId}
            restaurantId={restaurantId}
            outletId={outletId || ''}
            outlet={outlet}
            nextOpen={nextOpen || ''}
            closeMenuItem={closeMenuItem}
            openingTimes={openingTimes || []}
            soldOutUntilItems={soldOutUntilItems || []}
            addOnMenuItem={isAddOnMenuItem}
          />
        )
      }}
    </Query>
  )
}

export default QueryProvider

import gql from 'graphql-tag'

const GET_DPRACTIVITIES = gql`
  query activities(
    $afterDate: DateTime!
    $beforeDate: DateTime!
    $marketplaceId: ID!
    $outletId: ID!
  ) {
    activities(
      afterDate: $afterDate
      beforeDate: $beforeDate
      marketplaceId: $marketplaceId
      outletId: $outletId
      period: QUARTER
    ) {
      periods {
        period
        periodStart
        periodEnd
        totalGrossSales
        totalGrossMarketplace
        totalTransactions
        totalGrossRefunds
        totalNetSalesAfterRefunds
        totalTaxes
      }
    }
  }
`
export default GET_DPRACTIVITIES

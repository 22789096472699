import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  HTMLTable,
  Card,
  Tag,
  NonIdealState,
  ButtonGroup,
  Popover,
  Position,
  Icon,
  ControlGroup,
  Divider,
  PopoverInteractionKind,
  Button,
  Tooltip,
} from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { upperFirst, get, union, without } from 'lodash'
import Query from '@components/Query/Query'
import { removeUnderscores } from '@utils/helpers'
import {
  getOrderStatusMapping,
  DELIVERY_PROVIDER_TO_TAG_ICON,
} from './helpers/helpers'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import Currency from '@components/Currency/Currency'
import FulfilmentStatus from '@components/Orders/Order/FulfilmentStatus'
import { canView, isAtLeastMarketplaceUser } from '@stores/userStore'
import {
  Pager,
  Search,
  MarketplaceFilter,
  RestaurantFilter,
} from '@components/Toolbar'
import Sound from 'react-sound'
// @ts-ignore
import alarmSound from '@assets/sounds/alarm.mp3'
import GET_MARKETPLACE_IDS from './queries/getMarketplaceIds.query'
import GET_ORDERS from './queries/getOrders.query'
import GET_MODALS from './queries/getModals.clientQuery'
import FilterRow from '@components/FilterRow/FilterRow'
import PrintOrderModal from '@components/Orders/Order/PrintOrderModal'
import Favicon from '@components/Favicon/Favicon'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import ls from '@utils/localStorage'
import SettingsToggle from '@components/Toolbar/SettingsToggle'
import FulfilmentFilter from '@components/Toolbar/FulfilmentFilter'
import Dropdown from '@components/Toolbar/RadioFilter'
import { isInAlarm } from '@utils/order/isInAlarm'
import { DATE_FILTER_TYPES } from '../../utils/datetime'
import { format, isSameDay } from 'date-fns'
import DateRangeFilter from '../Toolbar/DateRangeFilter/DateRangeFilter'
import { useDateRangeQueryParams } from '../Toolbar/DateRangeFilter/useDateRangeQueryParams'
import {
  DelimitedArrayParam,
  StringParam,
  useQueryParams,
} from 'use-query-params'
import { useSearchQueryParam } from '../Toolbar/Search/useSearchQueryParam'
import { useRoleAwareBusinessFilterQueryParams } from '../Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'
import OutletLink from '@components/OpenStatus/OutletLink'
import DateTime from '@components/DateTime/DateTime'
import OrderStatusInfo from './Order/OrderStatusInfo'
import OrderStatusEnum from '@components/Enum/OrderStatusEnum'

const DEFAULT_RECORDS = 25
const DEFAULT_PAGINATION_STATE = {
  total: 0,
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  navigationDisabled: false,
  orderBy: 'createdAt_DESC',
}

const ORDER_SEARCH_MINIMUM_CHARACTERS = 7

const PageLayoutWrapper = ({
  product,
  totalCount,
  totalPending,
  totalInprogress,
  totalCompleted,
  totalRejected,
  totalRefund,
  children,
}) => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    configurePageLayout({
      product,
      tabs: [
        { to: '/orders/', name: 'All', count: totalCount },
        {
          to: '/orders/pending',
          name: 'Pending',
          count: totalPending,
          intent: 'danger',
        },
        {
          to: '/orders/preparing',
          name: 'In Progress',
          count: totalInprogress,
        },
        { to: '/orders/complete', name: 'Complete', count: totalCompleted },
        { to: '/orders/rejected', name: 'Rejected', count: totalRejected },
        { to: '/orders/refund', name: 'Refund', count: totalRefund },
      ],
      persistQueryParamsBetweenTabs: true,
    })
  }, [
    configurePageLayout,
    product,
    totalCount,
    totalPending,
    totalInprogress,
    totalCompleted,
    totalRejected,
    totalRefund,
  ])

  return children
}

const Orders = props => {
  const [
    { restaurantFilter = [], fulfilmentFilter = [], paymentFilter = null },
    setQueryParams,
  ] = useQueryParams({
    viewOrder: StringParam,
    restaurantFilter: DelimitedArrayParam,
    fulfilmentFilter: DelimitedArrayParam,
    paymentFilter: StringParam,
  })

  const [state, setState] = useState({
    ...DEFAULT_PAGINATION_STATE,
    showAwaitingPayment: ls.get('showAwaitingPayment'),
    useExactTime: ls.get('useExactTime'),
    showDeliveryWindow: ls.get('showDeliveryWindow'),
    muteAlarms: ls.get('muteAlarms'),
    pollInterval: ls.get('pollInterval'),
  })
  const resetPaginationState = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      ...DEFAULT_PAGINATION_STATE,
    }))
  }, [])

  const { searchValue, resetSearch } = useSearchQueryParam()
  useEffect(() => {
    if (searchValue) {
      resetPaginationState()
    }
  }, [searchValue, resetPaginationState])

  const {
    shiftedStartOfRangeDateTime: startOfRangeDateTime,
    shiftedEndOfRangeDateTime: endOfRangeDateTime,
  } = useDateRangeQueryParams()

  const {
    marketplaceIds: marketplaceFilter,
    onChangeMarketplaceIds: onChangeMarketplaceFilter,
  } = useRoleAwareBusinessFilterQueryParams()

  const setTotalCount = (totalCount, returnCount) => {
    if (state.total !== totalCount) {
      setState(prevState => ({ ...prevState, total: totalCount }))
    }
    if (state.outcomeLength !== returnCount) {
      setState(prevState => ({ ...prevState, outcomeLength: returnCount }))
    }
  }

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (state.skip + state.first < state.total) {
      setState(prevState => ({
        ...prevState,
        skip: limitNext(prevState.skip, prevState.first, limit),
        first: prevState.defaultNmbRecords,
        last: null,
      }))
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < state.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setState(prevState => ({
      ...prevState,
      skip: limitPrevious(prevState.skip, prevState.first),
      first: prevState.defaultNmbRecords,
      last: null,
    }))
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const handleFulfilmentFilter = ({ currentTarget }) => {
    const { id, checked } = currentTarget
    const newFilter = checked
      ? union(fulfilmentFilter, [id])
      : without(fulfilmentFilter, id)

    setQueryParams({ fulfilmentFilter: newFilter })
    resetPaginationState()
    resetSearch()
  }

  const handleRestaurantFilter = e => {
    const { id, checked } = e.currentTarget
    const newFilter = checked
      ? union(restaurantFilter, [id])
      : without(restaurantFilter, id)

    setQueryParams({ restaurantFilter: newFilter })
    resetPaginationState()
    resetSearch()
  }

  const handlePaymentFilter = ({ id }) => {
    setQueryParams({ paymentFilter: paymentFilter === id ? undefined : id })
    resetPaginationState()
    resetSearch()
  }

  const resetFilters = () => {
    setQueryParams({
      restaurantFilter: undefined,
      fulfilmentFilter: undefined,
      paymentFilter: undefined,
    })
    onChangeMarketplaceFilter([])
    resetPaginationState()
    resetSearch()
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setState(prevState => ({
      ...prevState,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    }))
  }

  useEffect(() => {
    if (state.showDeliveryWindow !== ls.get('showDeliveryWindow')) {
      ls.set('showDeliveryWindow', state.showDeliveryWindow)
    }
    if (state.useExactTime !== ls.get('useExactTime')) {
      ls.set('useExactTime', state.useExactTime)
    }
    if (state.muteAlarms !== ls.get('muteAlarms')) {
      ls.set('muteAlarms', state.muteAlarms)
    }
    if (state.showAwaitingPayment !== ls.get('showAwaitingPayment')) {
      ls.set('showAwaitingPayment', state.showAwaitingPayment)
    }
    if (state.pollInterval !== ls.get('pollInterval')) {
      ls.set('pollInterval', state.pollInterval)
    }
  }, [
    state.showDeliveryWindow,
    state.useExactTime,
    state.muteAlarms,
    state.showAwaitingPayment,
    state.pollInterval,
  ])

  const disableFilters = Boolean(searchValue)
  const isOrderSearchLongerThanMinLength =
    searchValue.length > ORDER_SEARCH_MINIMUM_CHARACTERS
  const orderQueryVariables = {
    ...(isOrderSearchLongerThanMinLength ? { orderNumber: searchValue } : {}),
    marketplaceIds: marketplaceFilter,
    ...state,
    afterDate: isOrderSearchLongerThanMinLength ? null : startOfRangeDateTime,
    beforeDate: isOrderSearchLongerThanMinLength ? null : endOfRangeDateTime,
    restaurantIds: restaurantFilter.length > 0 ? restaurantFilter : undefined,
    fulfilmentMethod:
      fulfilmentFilter.length > 0 ? fulfilmentFilter : undefined,
    paymentMethod:
      paymentFilter === 'CARD'
        ? ['CARD_ON_DELIVERY', 'CARD_ON_COLLECTION', 'CARD_ON_TABLE']
        : paymentFilter === 'CASH'
        ? ['CASH_ON_DELIVERY', 'CASH_ON_COLLECTION']
        : undefined,
    ...getOrderStatusMapping(
      props.match.params.statusFilter,
      state.showAwaitingPayment
    ),
  }

  return (
    <Query query={GET_MODALS} showLoader={false}>
      {({ printOrderModal }) => (
        <Fragment>
          <PrintOrderModal open={printOrderModal} />
          <Query query={GET_MARKETPLACE_IDS} showLoader={false}>
            {({ getMarketplaces: { regions: marketplaces } }) => (
              <div className="bp3-table-frame">
                <FilterRow>
                  <ButtonGroup>
                    <DateRangeFilter
                      filterTypes={[
                        DATE_FILTER_TYPES.DAY,
                        DATE_FILTER_TYPES.WEEK,
                        DATE_FILTER_TYPES.CUSTOM,
                      ]}
                    />
                    <Divider />
                    <ControlGroup>
                      <Search
                        placeholder="Order number..."
                        data-test-id="order-search"
                      />
                      {marketplaces.length > 1 && (
                        <MarketplaceFilter
                          icon="filter"
                          disabled={disableFilters}
                          defaultIsOpen={false}
                        />
                      )}
                      {(marketplaces.length === 1 ||
                        !!marketplaceFilter.length ||
                        !!restaurantFilter.length) && (
                        <RestaurantFilter
                          restaurantFilter={restaurantFilter}
                          marketplaceFilter={marketplaceFilter}
                          onChange={e => handleRestaurantFilter(e)}
                          disabled={disableFilters}
                        />
                      )}
                      <FulfilmentFilter
                        fulfilmentFilter={fulfilmentFilter}
                        onChange={e => handleFulfilmentFilter(e)}
                        disabled={disableFilters}
                      />
                      <Dropdown
                        disabled={state.total === null || disableFilters}
                        selected={paymentFilter}
                        setSelected={handlePaymentFilter}
                        placeholder="Payment"
                        items={[
                          { name: 'All', id: null },
                          { name: 'Card', id: 'CARD' },
                          { name: 'Cash', id: 'CASH' },
                        ]}
                      />
                      <Button
                        icon="filter-remove"
                        onClick={resetFilters}
                        disabled={
                          paymentFilter === null &&
                          marketplaceFilter.length === 0 &&
                          restaurantFilter.length === 0 &&
                          searchValue.length === 0
                        }
                      />
                    </ControlGroup>
                  </ButtonGroup>

                  <ButtonGroup>
                    <SettingsToggle
                      useExactTime={state.useExactTime}
                      showAwaitingPayment={state.showAwaitingPayment}
                      showDeliveryWindow={state.showDeliveryWindow}
                      pollInterval={state.pollInterval}
                      isMuted={state.muteAlarms}
                      updateState={(setting, value = null) => {
                        switch (setting) {
                          case 'muteAlarms':
                            setState(prevState => ({
                              ...prevState,
                              muteAlarms: !prevState.muteAlarms,
                            }))
                            break
                          case 'useExactTime':
                            setState(prevState => ({
                              ...prevState,
                              useExactTime: !prevState.useExactTime,
                            }))
                            break
                          case 'showDeliveryWindow':
                            setState(prevState => ({
                              ...prevState,
                              showDeliveryWindow: !prevState.showDeliveryWindow,
                            }))
                            break
                          case 'showAwaitingPayment':
                            setState(prevState => ({
                              ...prevState,
                              showAwaitingPayment:
                                !prevState.showAwaitingPayment,
                            }))
                            break
                          case 'pollInterval':
                            setState(prevState => ({
                              ...prevState,
                              pollInterval: value,
                            }))
                            break
                        }
                      }}
                    />
                    <Pager
                      goToPrevious={e => goToPrevious(e)}
                      goToNext={e => goToNext(e)}
                      goToPage={goToPage}
                      defaultNmbRecords={DEFAULT_RECORDS}
                      skip={state.skip}
                      total={state.total}
                      outcomeLength={state.outcomeLength}
                      totalCount={state.total}
                      dataName="Orders"
                    />
                  </ButtonGroup>
                </FilterRow>
                <DebouncedQuery
                  query={GET_ORDERS}
                  variables={orderQueryVariables}
                  onCompleted={data => {
                    if (data.orders) {
                      setTotalCount(data.orders.totalCount, data.orders.count)
                    }
                  }}
                  loaderTitle="Loading Orders"
                  ErrorComponent={() => (
                    <div style={{ padding: 100 }}>
                      <NonIdealState
                        icon="th-list"
                        title="Loading Error"
                        description="Unable to load order data, please retry."
                      />
                    </div>
                  )}
                  pollInterval={state.pollInterval || undefined}
                >
                  {data => {
                    const {
                      totalCount = 0,
                      totalPending = 0,
                      totalInprogress = 0,
                      totalCompleted = 0,
                      totalRejected = 0,
                      totalRefund = 0,
                    } = data.orders || {}

                    const refetchQueries = [
                      {
                        query: GET_ORDERS,
                        variables: orderQueryVariables,
                      },
                    ]

                    return (
                      <PageLayoutWrapper
                        product={props.product}
                        totalCount={totalCount}
                        totalPending={totalPending}
                        totalInprogress={totalInprogress}
                        totalCompleted={totalCompleted}
                        totalRejected={totalRejected}
                        totalRefund={totalRefund}
                      >
                        {!data || !data.orders || !data.orders.orders.length ? (
                          <div style={{ padding: 100 }}>
                            <NonIdealState
                              icon="th-list"
                              title="No Orders Found"
                              description={
                                searchValue
                                  ? 'There are no orders that meet your search criteria.'
                                  : 'There are no orders to display for the chosen filters.'
                              }
                              action={
                                searchValue || marketplaceFilter.length > 0 ? (
                                  <Button
                                    onClick={resetFilters}
                                    minimal
                                    intent="primary"
                                  >
                                    Clear Filters
                                  </Button>
                                ) : null
                              }
                            />
                          </div>
                        ) : (
                          <div className="bp3-table-container bp3-scrollable">
                            <Card className="bp3-noPad">
                              <HTMLTable bordered={false} interactive={true}>
                                <thead>
                                  <tr>
                                    <th>Order</th>
                                    <th>Customer</th>
                                    {canView('customers') && <th>Value</th>}
                                    <th>Outlet</th>
                                    <th>Total</th>
                                    <th>Creation</th>
                                    <th colSpan={2}>Status</th>
                                    <th>Last Update</th>
                                    <th>Fulfilment</th>
                                    {state.showDeliveryWindow && (
                                      <th>Window</th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.orders.orders.map(order => {
                                    const {
                                      id,
                                      orderNumber,
                                      outlet,
                                      customer,
                                      grossTotal,
                                      createdAt,
                                      updatedAt,
                                      orderStatus,
                                      fulfillmentMethod,
                                      estimatedDeliveryDate,
                                      estimatedCompletionTime,
                                      paymentMethod,
                                      asap,
                                      deliveryNetworkBookingStatus,
                                      deliveryNetworkProvider,
                                      tableSnapshot,
                                      selectedDeliveryWindow,
                                      autoRejectAt,
                                      autoAcceptedAt,
                                      isAutoRejected,
                                      cancellationNotes,
                                    } = order

                                    const isAlarm = isInAlarm(
                                      orderStatus,
                                      autoRejectAt
                                    )

                                    const customerName = `${upperFirst(
                                      customer.firstName
                                    )} ${upperFirst(
                                      customer.lastName.charAt(0)
                                    )}`

                                    return (
                                      <tr key={id}>
                                        <td data-test-id="orders-table-row-order-number">
                                          <a
                                            onClick={() => {
                                              setQueryParams({
                                                viewOrder: id,
                                              })
                                            }}
                                          >
                                            #{orderNumber}
                                          </a>
                                        </td>
                                        <td>
                                          {canView('customers') ? (
                                            <Fragment>
                                              {isAtLeastMarketplaceUser() ? (
                                                <Link
                                                  to={`/marketplaces/${outlet.marketplace.id}`}
                                                >
                                                  <Favicon
                                                    src={
                                                      outlet.marketplace
                                                        .faviconImage
                                                    }
                                                    label={
                                                      outlet.marketplace.name
                                                    }
                                                  />
                                                </Link>
                                              ) : (
                                                <Favicon
                                                  src={
                                                    outlet.marketplace
                                                      .faviconImage
                                                  }
                                                  label={
                                                    outlet.marketplace.name
                                                  }
                                                />
                                              )}
                                              &nbsp;
                                              <Link
                                                to={`/customer/${customer.id}`}
                                              >
                                                {customerName}
                                              </Link>
                                            </Fragment>
                                          ) : (
                                            customerName
                                          )}
                                        </td>
                                        {canView('customers') && (
                                          <td>
                                            <Popover
                                              interactionKind={
                                                PopoverInteractionKind.HOVER
                                              }
                                              position={Position.RIGHT_TOP}
                                              content={
                                                <HTMLTable>
                                                  <thead>
                                                    <tr>
                                                      <th>Total Orders</th>
                                                      <td>
                                                        <Link
                                                          to={`/customer/${customer.id}/orders`}
                                                        >
                                                          {
                                                            customer.totalCompletedOrders
                                                          }
                                                        </Link>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <th>Total Value</th>
                                                      <td>
                                                        <Currency
                                                          amount={
                                                            customer.totalValue
                                                          }
                                                        />
                                                      </td>
                                                    </tr>
                                                  </thead>
                                                </HTMLTable>
                                              }
                                            >
                                              {customer.totalCompletedOrders >
                                              1 ? (
                                                <Currency
                                                  amount={customer.totalValue}
                                                />
                                              ) : (
                                                <Icon
                                                  icon={'star'}
                                                  color={'#FFC12F'}
                                                  iconSize={14}
                                                />
                                              )}
                                            </Popover>
                                          </td>
                                        )}
                                        <td>
                                          <OutletLink
                                            outlet={outlet}
                                            displayBusinessName
                                          />
                                        </td>
                                        <td>
                                          {orderStatus ==
                                          'AUTH_ACTION_FAILURE' ? (
                                            <Tooltip
                                              content={
                                                'The customer failed to authorise payment with 3DS/PSD2.'
                                              }
                                            >
                                              <Icon
                                                icon="credit-card"
                                                color={'red'}
                                              />
                                            </Tooltip>
                                          ) : (
                                            <Icon
                                              icon="credit-card"
                                              color={
                                                paymentMethod.split('_')[0] ===
                                                'CARD'
                                                  ? '#30404d'
                                                  : '#CDD6DD'
                                              }
                                            />
                                          )}{' '}
                                          <Currency amount={grossTotal} />
                                        </td>
                                        <td>
                                          <span
                                            style={
                                              isAlarm ? { color: 'red' } : {}
                                            }
                                          >
                                            {format(
                                              new Date(createdAt),
                                              'HH:mm'
                                            )}
                                          </span>
                                          &nbsp;
                                          {!isSameDay(
                                            new Date(),
                                            new Date(createdAt)
                                          ) &&
                                            format(new Date(createdAt), 'd/MM')}
                                        </td>
                                        <td>
                                          <OrderStatusEnum
                                            orderStatus={orderStatus}
                                            autoRejectAt={autoRejectAt}
                                            autoAcceptedAt={autoAcceptedAt}
                                            isAutoRejected={isAutoRejected}
                                            asap={asap}
                                            onClick={() => {
                                              setQueryParams({
                                                viewOrder: id,
                                              })
                                            }}
                                            details={
                                              isAutoRejected &&
                                              cancellationNotes
                                            }
                                            actions={
                                              <OrderStatusInfo
                                                order={order}
                                                minimal
                                                refetchQueries={refetchQueries}
                                              />
                                            }
                                          />
                                          {/* TODO: Fix <Sound> so it loads only once and is not a react component. */}
                                          {!state.muteAlarms && isAlarm && (
                                            <Sound
                                              url={alarmSound}
                                              playStatus={
                                                // @ts-ignore
                                                Sound.status.PLAYING
                                              }
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {fulfillmentMethod === 'NETWORK' && (
                                            <Tag
                                              icon={
                                                DELIVERY_PROVIDER_TO_TAG_ICON[
                                                  deliveryNetworkProvider
                                                ] || 'taxi'
                                              }
                                              intent={'primary'}
                                              minimal
                                            >
                                              {removeUnderscores(
                                                deliveryNetworkBookingStatus
                                              )}
                                            </Tag>
                                          )}
                                          {fulfillmentMethod === 'TABLE' && (
                                            <Tooltip content="Table Service">
                                              <Tag
                                                icon={'locate'}
                                                intent={'primary'}
                                                minimal
                                              >
                                                {`${get(
                                                  tableSnapshot,
                                                  'friendlyName',
                                                  'unknown'
                                                ).replace(/^table\s/i, '')}`}
                                              </Tag>
                                            </Tooltip>
                                          )}
                                        </td>
                                        <td>
                                          <DateTime
                                            dateTime={updatedAt}
                                            timeAgo={!state.useExactTime}
                                          />
                                        </td>
                                        <td>
                                          <FulfilmentStatus
                                            orderStatus={orderStatus}
                                            useExactTime={state.useExactTime}
                                            fulfillmentMethod={
                                              fulfillmentMethod
                                            }
                                            createdAt={createdAt}
                                            updatedAt={updatedAt}
                                            estimatedDeliveryDate={
                                              deliveryNetworkProvider ===
                                              'WAYBOX'
                                                ? estimatedDeliveryDate
                                                : selectedDeliveryWindow
                                                ? selectedDeliveryWindow.end
                                                : estimatedDeliveryDate
                                            }
                                            estimatedCompletionTime={
                                              selectedDeliveryWindow
                                                ? selectedDeliveryWindow.start
                                                : estimatedCompletionTime
                                            }
                                            asap={asap}
                                            selectedDeliveryWindow={
                                              selectedDeliveryWindow
                                            }
                                          />
                                        </td>
                                        {state.showDeliveryWindow && (
                                          <td>
                                            {selectedDeliveryWindow ? (
                                              <Fragment>
                                                {selectedDeliveryWindow.start
                                                  ? format(
                                                      new Date(
                                                        selectedDeliveryWindow.start
                                                      ),
                                                      'HH:mm'
                                                    )
                                                  : estimatedCompletionTime
                                                  ? format(
                                                      new Date(
                                                        estimatedCompletionTime
                                                      ),
                                                      'HH:mm'
                                                    )
                                                  : null}
                                                {' - '}
                                                {selectedDeliveryWindow.end &&
                                                  format(
                                                    new Date(
                                                      selectedDeliveryWindow.end
                                                    ),
                                                    'HH:mm'
                                                  )}
                                              </Fragment>
                                            ) : estimatedDeliveryDate ? (
                                              format(
                                                new Date(estimatedDeliveryDate),
                                                'HH:mm'
                                              )
                                            ) : null}
                                          </td>
                                        )}
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </HTMLTable>
                            </Card>
                          </div>
                        )}
                      </PageLayoutWrapper>
                    )
                  }}
                </DebouncedQuery>
              </div>
            )}
          </Query>
        </Fragment>
      )}
    </Query>
  )
}

export default Orders

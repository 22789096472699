import React from 'react'
import {
  ButtonGroup,
  Tag,
  Button,
  Popover,
  Position,
  NumericInput,
} from '@blueprintjs/core'
import { clamp } from 'lodash'

/**
 * @param {Object} props
 * @param {() => void} props.goToPrevious
 * @param {(e: Event, outcomeLength: number) => void} props.goToNext
 * @param {(pageNum: number) => void} props.goToPage
 * @param {number} props.defaultNmbRecords - Page Size
 * @param {number} props.skip
 * @param {number} props.total
 * @param {number} props.outcomeLength - Records in current page
 * @param {string} [props.dataName]
 * @param {number} [props.totalCount] - Total number of records in all pages, undefined if data not yet available
 * @param {boolean} [props.countOnly]
 */
export const Pager = ({
  goToPrevious,
  goToNext,
  goToPage,
  defaultNmbRecords,
  skip,
  total,
  outcomeLength,
  dataName = 'Records',
  totalCount,
  countOnly,
}) => {
  const minimum = 1
  const maximum = Math.ceil(total / defaultNmbRecords) || 1
  const pageNumber = skip / defaultNmbRecords + 1
  const maximumPageNumber = Math.ceil(total / defaultNmbRecords)

  return (
    <div
      style={{
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {totalCount !== undefined && (
        <Tag minimal={true} large={true} style={{ marginRight: '10px' }}>
          {Intl.NumberFormat().format(totalCount)} {dataName}
        </Tag>
      )}
      {!countOnly && (
        <ButtonGroup id="navigationGroup">
          <Button
            type="button"
            disabled={skip === 0}
            icon="chevron-left"
            onClick={goToPrevious}
          />
          <Popover
            content={
              <div style={{ padding: 10 }}>
                <NumericInput
                  autoFocus
                  onValueChange={val => goToPage(clamp(val, 1, maximum))}
                  buttonPosition={Position.RIGHT}
                  stepSize={1}
                  min={minimum}
                  max={maximum}
                  placeholder="Page..."
                  style={{ width: '100px' }}
                />
              </div>
            }
            disabled={maximum <= 1}
            position={Position.BOTTOM}
          >
            <Button
              disabled={totalCount <= 1}
              style={{
                width: '90px',
              }}
            >
              {pageNumber}&nbsp;of&nbsp;{Intl.NumberFormat().format(maximum)}
            </Button>
          </Popover>
          <Button
            disabled={pageNumber >= maximumPageNumber}
            rightIcon="chevron-right"
            onClick={e => goToNext(e, outcomeLength)}
          />
        </ButtonGroup>
      )}{' '}
    </div>
  )
}

import {
  Button,
  ButtonGroup,
  Popover,
  PopoverInteractionKind,
  Position,
  Menu,
  MenuItem,
} from '@blueprintjs/core'
import React, { Fragment } from 'react'

const SettingsPopover = ({
  simpleView = false,
  showDeliveryWindow = false,
  updateState,
  showAwaitingPayment = false,
  useExactTime = false,
  isMuted,
  pollInterval = null,
}) => {
  return (
    <ButtonGroup
      style={{
        opacity: 0.6,
        paddingRight: '10px',
      }}
    >
      <Popover
        position={Position.BOTTOM_LEFT}
        interactionKind={PopoverInteractionKind.CLICK}
        content={
          <Menu>
            <Menu.Divider title="Alarm" />
            <MenuItem
              text={isMuted ? 'Unmute' : 'Mute'}
              shouldDismissPopover={false}
              onClick={() => {
                updateState('muteAlarms')
              }}
              icon={isMuted ? 'volume-off' : 'volume-up'}
            />
            <Menu.Divider title="Show" />
            <MenuItem
              text="Delivery Window"
              shouldDismissPopover={false}
              onClick={() => {
                updateState('showDeliveryWindow')
              }}
              icon={showDeliveryWindow ? 'tick' : 'blank'}
            />
            {!simpleView && (
              <Fragment>
                <MenuItem
                  text="Awaiting Payment Status"
                  shouldDismissPopover={false}
                  onClick={() => {
                    updateState('showAwaitingPayment')
                  }}
                  icon={showAwaitingPayment ? 'tick' : 'blank'}
                />
                <Menu.Divider title="Time" />
                <MenuItem
                  text="Exact"
                  shouldDismissPopover={false}
                  onClick={() => {
                    updateState('useExactTime')
                  }}
                  icon={useExactTime ? 'selection' : 'circle'}
                />
                <MenuItem
                  text="Relative"
                  shouldDismissPopover={false}
                  onClick={() => {
                    updateState('useExactTime')
                  }}
                  icon={useExactTime ? 'circle' : 'selection'}
                />
                <Menu.Divider title="Auto Refresh" />
                <MenuItem
                  text="Off"
                  shouldDismissPopover={false}
                  onClick={() => {
                    updateState('pollInterval', null)
                  }}
                  icon={!pollInterval ? 'selection' : 'circle'}
                />
                <MenuItem
                  text="1 Minute"
                  shouldDismissPopover={false}
                  onClick={() => {
                    updateState('pollInterval', 60000)
                  }}
                  icon={pollInterval === 60000 ? 'selection' : 'circle'}
                />
                <MenuItem
                  text="5 Minutes"
                  shouldDismissPopover={false}
                  onClick={() => {
                    updateState('pollInterval', 60000 * 5)
                  }}
                  icon={pollInterval === 60000 * 5 ? 'selection' : 'circle'}
                />
                <MenuItem
                  text="10 Minutes"
                  shouldDismissPopover={false}
                  onClick={() => {
                    updateState('pollInterval', 60000 * 10)
                  }}
                  icon={pollInterval === 60000 * 10 ? 'selection' : 'circle'}
                />
                <MenuItem
                  text="30 Minutes"
                  shouldDismissPopover={false}
                  onClick={() => {
                    updateState('pollInterval', 60000 * 30)
                  }}
                  icon={pollInterval === 60000 * 30 ? 'selection' : 'circle'}
                />
              </Fragment>
            )}
          </Menu>
        }
      >
        <Button icon="cog" minimal />
      </Popover>
    </ButtonGroup>
  )
}

export default SettingsPopover

import React, { useEffect, useRef, useContext } from 'react'
import countBy from 'lodash/countBy'
import sumBy from 'lodash/sumBy'
import { useDrag, useDrop } from 'react-dnd'
import {
  Collapse,
  Icon,
  Popover,
  PopoverInteractionKind,
} from '@blueprintjs/core'
import get from 'lodash/get'

import MenuItemGroups from './MenuItemGroups'
import DepthContainer from './DepthContainer'
import Row from './Row'
import MenuItems from './MenuItems'
import MenuItemGroupEmptyDropZone from './MenuItemGroupEmptyDropZone'
import dndHelpers from './dndHelpers'
import ItemTypes from './ItemTypes'
import { MenuListContext } from '@components/Restaurant/Menu/MenuList/menu-list-context'
import { removeExpiredSoldOut } from '@utils/removeExpiredSoldOut'
import OutletSubMenu from '@components/Restaurant/Menu/MenuTree/OutletSubMenu'
import SubMenu from '../MenuTree/SubMenu'

const MenuItemGroup = ({
  outlet,
  menu,
  menus,
  queryId,
  depth,
  index,
  itemType,
  path,
  parentMenuId,
  outletsSoldOut,
  openMenuGroups = [],
  toggleOpenOrClosed,
  restaurantId,
  hiddenMenuItemGroupIds,
  hiddenMenuItemIds,
  outletId,
}) => {
  const { setDragging, editMenuOrder, saveMenuOrder } =
    useContext(MenuListContext)
  const ref = useRef(null)

  // if no children menus add empty array
  if (!menu.childrenMenus) {
    menu.childrenMenus = []
  }
  const hasChildrenSubMenus = !!menu.childrenMenus.length
  const hasChildrenMenuItems = !!menu.menuItems.length

  const getCanAccept = item => {
    const itemHasSubMenus = !!get(item, 'menu.childrenMenus.length', 0)
    if (item.id === menu.id) {
      return false
    }
    if (depth > 0) {
      return !itemHasSubMenus
    }

    return true
  }

  const [{ canDrop, isAbove, isSelf }, dropMenu] = useDrop({
    accept: [ItemTypes.MENU, ItemTypes.SUB_MENU],
    hover(item, monitor) {
      const over = monitor.isOver({ shallow: true })

      if (over && getCanAccept(item)) {
        dndHelpers.dragHalfway({
          item,
          index: dndHelpers.buildIndexFromPath(path),
          ref,
          monitor,
          path,
          callback: (dragIndex, hoverIndex, item) => {
            editMenuOrder({ item, hoverPath: path })
          },
        })
      }
    },
    collect: monitor => {
      const item = monitor.getItem() || {}
      return {
        isAbove: dndHelpers.buildIndexFromPath(path) < item.index,
        isSelf: item.id === menu.id,
        canDrop: monitor.canDrop() && getCanAccept(item),
      }
    },
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop && getCanAccept(item)) {
        return
      }
      saveMenuOrder(item, path, parentMenuId)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: itemType,
      id: menu.id,
      index: dndHelpers.buildIndexFromPath(path),
      menu,
      path,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    setDragging(isDragging)
  }, [isDragging, setDragging])

  const opacity = isDragging ? 0.2 : 1

  if (!outlet) {
    drag(dropMenu(ref))
  }

  const soldCount = sumBy([menu, ...menu.childrenMenus], menu => {
    const soldOutOutlets = removeExpiredSoldOut(outletsSoldOut)
    return (
      countBy(menu.menuItems, item => {
        return outlet ? item.outletSoldOut : soldOutOutlets.includes(item.id)
      }).true || 0
    )
  })

  return (
    <div
      ref={ref}
      style={{
        opacity,
      }}
    >
      <Row
        isOpen={openMenuGroups.includes(menu.id)}
        onClick={() => {
          toggleOpenOrClosed(menu.id)
        }}
        hidden={hiddenMenuItemGroupIds.includes(menu.id)}
        icon={dndHelpers.getDragIcon({
          isDragging,
          isSelf,
          isAbove,
          canDrop,
          defaultIcon: 'clipboard',
        })}
        label={menu.name}
        soldCount={soldCount}
        canDrag={!outlet}
        secondaryLabel={
          <div
            onClick={e => {
              e.stopPropagation()
            }}
          >
            {/* sub menu for tree view */}
            <Popover
              content={
                outlet ? (
                  <OutletSubMenu
                    id={menu.id}
                    outletId={outletId}
                    hidden={hiddenMenuItemGroupIds.includes(menu.id)}
                  />
                ) : (
                  <SubMenu
                    id={menu.id}
                    queryId={queryId}
                    menuId={menu.id}
                    parentMenu={depth === 0}
                    showMenuItem
                    menus={menus}
                    index={index}
                    noSort
                  />
                )
              }
              interactionKind={PopoverInteractionKind.CLICK}
            >
              <Icon icon="more" color="#5c7080" />
            </Popover>
          </div>
        }
      />

      <DepthContainer>
        <Collapse isOpen={openMenuGroups.includes(menu.id)}>
          {!hasChildrenSubMenus && !hasChildrenMenuItems && (
            <MenuItemGroupEmptyDropZone
              path={path}
              depth={depth + 1}
              parentMenuId={parentMenuId}
              menu={menu}
              outlet={outlet}
            />
          )}

          {hasChildrenSubMenus && (
            // Sub menus
            <MenuItemGroups
              outlet={outlet}
              menuItemsGroups={menu.childrenMenus}
              queryId={queryId}
              parentMenuId={menu.id}
              depth={depth + 1}
              itemType={ItemTypes.SUB_MENU}
              path={path}
              outletsSoldOut={outletsSoldOut}
              restaurantId={restaurantId}
              openMenuGroups={openMenuGroups}
              toggleOpenOrClosed={toggleOpenOrClosed}
              hiddenMenuItemGroupIds={hiddenMenuItemGroupIds}
              hiddenMenuItemIds={hiddenMenuItemIds}
              outletId={outletId}
            />
          )}

          {hasChildrenMenuItems && (
            <MenuItems
              menuItems={menu.menuItems}
              menuItemsGroupId={parentMenuId}
              outlet={outlet}
              depth={depth + 1}
              path={path}
              parentMenuId={menu.id}
              outletsSoldOut={outletsSoldOut}
              hiddenMenuItemIds={hiddenMenuItemIds}
              restaurantId={restaurantId}
            />
          )}
        </Collapse>
      </DepthContainer>
      {path[0] === menus.length - 1 && (
        <MenuItemGroupEmptyDropZone
          path={[menus.length]}
          depth={depth}
          parentMenuId={parentMenuId}
          menu={menu}
          outlet={outlet}
        />
      )}
    </div>
  )
}

export default MenuItemGroup

import React from 'react'
import injectSheet from 'react-jss/lib/injectSheet'
import OnboardingForm from './OnboardingForm'
import styles from './Onboarding.styles'
import { Card } from '@blueprintjs/core'
import { Query } from 'react-apollo'
import ONBOARDING_INFO from './queries/onboardingInfo.query'
import BlankPage from '@components/BlankPage/BlankPage'

const getMarketplaceKey = () => {
  if (location.search) {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('onboarding_marketplace')
  }
  return null
}

const Onboarding = props => {
  const { container, onboardingContainer, onboardingForm, logoContainer } =
    props.classes
  const marketplaceKey = getMarketplaceKey()

  if (!marketplaceKey) {
    return <BlankPage />
  }

  return (
    <Query query={ONBOARDING_INFO} variables={{ marketplaceKey }}>
      {({ data: { onboardingInfo } }) => {
        if (!onboardingInfo) {
          return null
        }
        // onboarding for web v2 is handled on that client
        if (onboardingInfo.enableCustomerV2Client) {
          window.location.href = `https://${onboardingInfo.cname}/add-business`
          return null
        }
        return (
          <div className={container}>
            <div className={onboardingContainer}>
              <Card className={onboardingForm}>
                <div
                  className={logoContainer}
                  style={{
                    background: onboardingInfo.heroImage
                      ? `url(https://${process.env.ENVIRONMENT_DOMAIN}/${onboardingInfo.heroImage})`
                      : 'none',
                    backgroundSize: 'cover',
                  }}
                >
                  {onboardingInfo.logoImage && (
                    <img
                      src={`https://${process.env.ENVIRONMENT_DOMAIN}/${onboardingInfo.logoImage}`}
                      alt={`logo`}
                      style={{ maxWidth: '50%' }}
                    />
                  )}
                </div>
                {onboardingInfo.onboardingDescription && (
                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      lineHeight: '10px',
                    }}
                  >
                    <p
                      style={{
                        font: 'inherit',
                        lineHeight: '20px',
                      }}
                    >
                      {onboardingInfo.onboardingDescription}
                    </p>
                  </div>
                )}
                <OnboardingForm marketplaceKey={marketplaceKey} />
              </Card>
            </div>
          </div>
        )
      }}
    </Query>
  )
}

export default injectSheet(styles)(Onboarding)

import React, { useState } from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import { Spinner, Classes } from '@blueprintjs/core'

import { successToast } from '@utils/toast'
import GET_SEGMENT from '../../queries/getSegment.query'
import EDIT_SEGMENT from '../../mutations/editSegment.mutation'

import InnerForm from './InnerForm'
import { string, func, bool } from 'prop-types'
import { Mutation } from 'react-apollo'
import Query from '@components/Query/Query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { editSegmentStructure } from '@validation/segment'
import GET_SEGMENTS_FOR_MARKETPLACE from '@components/Marketing/queries/getSegmentsForMarketplace.query'

const EditSegment = ({ id, marketplaceId, closeDrawer, dark }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  if (loading) return <Spinner />

  if (error) return <code>{error.message}</code>

  return (
    <div className={Classes.DRAWER_BODY}>
      <div className={Classes.DIALOG_BODY}>
        <Mutation
          mutation={EDIT_SEGMENT}
          onError={error => {
            setLoading(false)
            defaultErrorHandler(error)
          }}
          refetchQueries={[
            {
              query: GET_SEGMENTS_FOR_MARKETPLACE,
              variables: {
                marketplaceId,
                first: 20,
                skip: 0,
              },
            },
          ]}
        >
          {editSegment => (
            <Query
              query={GET_SEGMENT}
              variables={{ segmentId: id, marketplaceId }}
            >
              {({ getSegment: { segment } }) => {
                if (!segment.filterGroups.length) {
                  segment.filterGroups.push({
                    filterGroupType: 'ALL',
                    filters: [
                      {
                        attribute: '',
                        comparator: '',
                        value: '',
                      },
                    ],
                  })
                }

                return (
                  <Formik
                    initialValues={segment || {}}
                    onSubmit={async values => {
                      const variables = { ...values, id, marketplaceId }

                      setLoading(true)

                      try {
                        await editSegment({
                          variables,
                        })
                      } catch (e) {
                        setLoading(false)
                        setError(e)
                      }

                      setLoading(false)
                      successToast(`Saved ${values.name} segment`)
                      closeDrawer()
                    }}
                    validationSchema={yup.object().shape(editSegmentStructure)}
                  >
                    {props => (
                      <InnerForm
                        marketplaceId={marketplaceId}
                        dark={dark}
                        {...props}
                      />
                    )}
                  </Formik>
                )
              }}
            </Query>
          )}
        </Mutation>
      </div>
    </div>
  )
}

EditSegment.propTypes = {
  id: string,
  marketplaceId: string.isRequired,
  closeDrawer: func.isRequired,
  dark: bool.isRequired,
}

export default EditSegment

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import ReviewStatus from '@components/Review/ReviewStatus'
import StarsRating from '@components/Stars/Stars'
import moment from 'moment'
import injectSheet from 'react-jss'
import { object, array } from 'prop-types'
import get from 'lodash/get'
import { toggleModal, setValue } from '@utils/cacheHelpers'
import EditReviewModal from '@components/Review/modals/editReviewModal'
import { Query as CacheQuery } from 'react-apollo'
import OPEN_MODAL from '@components/Review/queries/openModal.clientQuery'

const style = () => ({
  reviewNumber: {
    width: '4rem',
    maxWidth: '4rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  reviewText: {
    width: '20rem',
    maxWidth: '20rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

const ReviewsTableBody = ({ classes, reviews, queryVars }) => {
  let expandedReview = {}

  const hideCustomer = get(queryVars, 'hideCustomer')
  const hideOutlet = get(queryVars, 'hideOutlet')

  return (
    <CacheQuery query={OPEN_MODAL}>
      {({ data: { editReviewModal } }) => (
        <Fragment>
          <tbody>
            {reviews.map(review => {
              const {
                id,
                createdAt,
                customer,
                order,
                outlet,
                foodQuality,
                restaurantService,
                deliveryTime,
                reviewText,
                approved,
              } = review
              return (
                <tr key={id}>
                  <td className={classes.reviewNumber}>{id}</td>
                  <td>{moment(createdAt).format('HH:mm D/MM/YYYY')}</td>
                  {!hideCustomer && (
                    <td>
                      <Link to={`/customer/${customer.id}`}>
                        {customer.firstName}
                      </Link>
                    </td>
                  )}
                  <td>
                    <Link to={`/orders?id=${order.id}`}>
                      {order.orderNumber}
                    </Link>
                  </td>
                  {!hideOutlet && (
                    <td>
                      <Link
                        to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}`}
                      >
                        {outlet.name}
                      </Link>
                    </td>
                  )}
                  <td>
                    <StarsRating starsNumber={foodQuality} />
                  </td>
                  <td>
                    <StarsRating starsNumber={restaurantService} />
                  </td>
                  <td>
                    <StarsRating starsNumber={deliveryTime} />
                  </td>
                  <td className={classes.reviewText}>{reviewText}</td>
                  <td
                    onClick={() => {
                      toggleModal({
                        editReviewModal: true,
                      })
                      expandedReview = review
                    }}
                  >
                    <ReviewStatus approved={approved} />
                  </td>
                </tr>
              )
            })}
          </tbody>
          <EditReviewModal
            review={expandedReview}
            modalOpen={editReviewModal}
            modalClose={() => {
              toggleModal({
                editReviewModal: false,
              })
              setValue({
                reviewId: '',
              })
            }}
          />
        </Fragment>
      )}
    </CacheQuery>
  )
}

ReviewsTableBody.propTypes = {
  classes: object,
  reviews: array,
  queryVars: object,
}

export default injectSheet(style)(ReviewsTableBody)

import gql from 'graphql-tag'

const EDIT_FULFILMENT_TIMES = gql`
  mutation editFulfilmentTimes(
    $id: String!
    $defaultDeliveryTime: Int
    $defaultCollectionTime: Int
    $daysOfferedInAdvanceMin: Int
    $daysOfferedInAdvanceMax: Int
    $autoCompleteAfter: Int
    $noPreordersBeforeOpening: Boolean
    $allowPreorders: Boolean
    $asapAllowed: Boolean
  ) {
    editOutlet(
      id: $id
      defaultDeliveryTime: $defaultDeliveryTime
      defaultCollectionTime: $defaultCollectionTime
      daysOfferedInAdvanceMin: $daysOfferedInAdvanceMin
      daysOfferedInAdvanceMax: $daysOfferedInAdvanceMax
      autoCompleteAfter: $autoCompleteAfter
      noPreordersBeforeOpening: $noPreordersBeforeOpening
      allowPreorders: $allowPreorders
      asapAllowed: $asapAllowed
    ) {
      message
      outlet {
        id
        name
        defaultDeliveryTime
        defaultCollectionTime
        daysOfferedInAdvanceMin
        daysOfferedInAdvanceMax
        autoCompleteAfter
        noPreordersBeforeOpening
        allowPreorders
        asapAllowed
      }
    }
  }
`

export default EDIT_FULFILMENT_TIMES

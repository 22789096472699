import React, { Fragment, useState } from 'react'
import {
  Card,
  HTMLTable,
  NonIdealState,
  Text,
  Button,
  Icon,
  Popover,
  PopoverInteractionKind,
  Menu,
  ButtonGroup,
} from '@blueprintjs/core'
import join from 'lodash/join'
import { toggleModal } from '@utils/cacheHelpers'
import { Query as CacheQuery } from 'react-apollo'
import { matchType } from '@utils/types'
import Query from '@components/Query/Query'
import GET_OPTIONS from '@components/Restaurant/Menu/queries/getOptions.query'
import EDIT_OPTIONS_ORDER from '@components/Restaurant/Menu/mutations/editOptionsOrder.mutation'
import MoveUpDown from '@components/MoveUpDown/MoveUpDown'
import DuplicateMenuOption from './DuplicateMenuOption'
import FilterRow from '@components/FilterRow/FilterRow'
import { ExportPopover } from '../ExportPopover/ExportPopover'
import { MenuOptionItemDefinition } from '@components/Restaurant/Options/definitions/MenuOptionItemDefinition'
import { MenuOptionDefinition } from '@components/Restaurant/Options/definitions/MenuOptionDefinition'
import { GET_OPTION_ITEMS_FOR_DOWNLOAD } from './queries/getOptionItemsForDownload.query'
import { kebabCase } from 'lodash'
import { client } from '@root/services/client'
import { ImportPopover } from '../ImportPopover/ImportPopover'
import { OptionsImport } from './OptionsImport/OptionsImport'
import EditOptionModal from '../Menu/modals/EditOption.modal'

import AddOptionModal from '../Menu/modals/AddOption.modal'
import OPEN_MODAL from './queries/openModal.clientQuery'
import { StringParam, useQueryParam } from 'use-query-params'

const tableHead = [
  {
    key: 'name',
    content: 'Name',
  },
  {
    key: 'options',
    content: 'Options',
  },
  {
    key: 'inUse',
    content: 'Used',
  },
]

const generateOptionItemsCSV = async (restaurantId, includeIds) => {
  const response = await client.query({
    query: GET_OPTION_ITEMS_FOR_DOWNLOAD,
    variables: {
      restaurantId,
    },
  })

  const fileName = `${kebabCase(
    response.data.getRestaurants.restaurants[0].name
  )}-menu-option-items.csv`
  let options = response.data.getOptions.options

  if (!includeIds) {
    options = options.map(option => ({
      ...option,
      optionItems: option.optionItems.map(optionItem => ({
        ...optionItem,
        id: '',
      })),
      id: '',
    }))
  }

  const csvData = MenuOptionItemDefinition.menuOptionItemsToCSV(options)

  return { fileName, csvData }
}

const generateOptionsCSV = async (restaurantId, includeIds) => {
  const response = await client.query({
    query: GET_OPTION_ITEMS_FOR_DOWNLOAD,
    variables: {
      restaurantId,
    },
  })

  const fileName = `${kebabCase(
    response.data.getRestaurants.restaurants[0].name
  )}-menu-options.csv`
  let options = response.data.getOptions.options

  if (!includeIds) {
    options = options.map(option => ({
      ...option,
      optionItems: option.optionItems.map(optionItem => ({
        ...optionItem,
        id: '',
      })),
      id: '',
    }))
  }

  const csvData = MenuOptionDefinition.menuOptionsToCSV(options)

  return { fileName, csvData }
}

const Options = ({ match }) => {
  const [optionId, onChangeOptionId] = useQueryParam('optionId', StringParam)
  const restaurantId = match.params.restaurants

  const [importFileData, setImportFileData] = useState(null)
  const [showImport, setShowImport] = useState(false)

  return (
    <div className={'bp3-table-frame bp3-nopad'}>
      <CacheQuery query={OPEN_MODAL}>
        {({ data: { optionModal } }) => (
          <Fragment>
            <FilterRow>
              <ButtonGroup>
                <ImportPopover
                  startImport={data => {
                    setImportFileData(data)
                    setShowImport(true)
                  }}
                />

                <ExportPopover
                  options={[
                    {
                      label: 'Options',
                      value: 'options',
                      generateCSV: includeIds =>
                        generateOptionsCSV(restaurantId, includeIds),
                    },
                    {
                      label: 'Option Items',
                      value: 'option-items',
                      generateCSV: includeIds =>
                        generateOptionItemsCSV(restaurantId, includeIds),
                    },
                  ]}
                />
              </ButtonGroup>
            </FilterRow>
            <div className="bp3-table-container bp3-scrollable">
              <Card className="bp3-nopad ">
                <Query
                  query={GET_OPTIONS}
                  variables={{ restaurantId: match.params.restaurants }}
                  loaderTitle={'Loading Options'}
                >
                  {({ getOptions, getRestaurants }) => {
                    const [restaurant] = getRestaurants.restaurants
                    const options = getOptions.options
                    if (options.length) {
                      const selectedOption = optionId
                        ? options.find(option => option.id === optionId)
                        : null
                      return (
                        <Fragment>
                          <HTMLTable interactive={true} bordered={false}>
                            <thead>
                              <tr>
                                {tableHead.map(({ key, content }) => (
                                  <th key={key}>{content}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {options.map(
                                (
                                  { name, id, optionItems, parentMenu },
                                  index,
                                  initialOptions
                                ) => {
                                  const optionNames = optionItems.map(
                                    option => option.name
                                  )

                                  return (
                                    <tr key={id}>
                                      <td>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-end',
                                          }}
                                        >
                                          <a
                                            onClick={() => {
                                              onChangeOptionId(id)
                                            }}
                                          >
                                            {name}
                                          </a>
                                        </div>
                                      </td>
                                      <td style={{ maxWidth: 500 }}>
                                        <Text ellipsize={true}>
                                          {join(optionNames, ', ')}
                                        </Text>
                                      </td>
                                      <td>{parentMenu.length}</td>
                                      <td className={'bp3-action-cell'}>
                                        <Popover
                                          content={
                                            <Menu>
                                              <MoveUpDown
                                                mutationToExecute={
                                                  EDIT_OPTIONS_ORDER
                                                }
                                                refetchQueries={[
                                                  {
                                                    query: GET_OPTIONS,
                                                    variables: {
                                                      restaurantId:
                                                        match.params
                                                          .restaurants,
                                                    },
                                                  },
                                                ]}
                                                index={index}
                                                initialList={initialOptions}
                                              />
                                              <DuplicateMenuOption
                                                restaurantId={
                                                  match.params.restaurants
                                                }
                                                option={options[index]}
                                              />
                                            </Menu>
                                          }
                                          interactionKind={
                                            PopoverInteractionKind.CLICK
                                          }
                                        >
                                          <Icon icon="more" />
                                        </Popover>
                                      </td>
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </HTMLTable>
                          {selectedOption && (
                            <EditOptionModal
                              option={selectedOption}
                              restaurant={restaurant}
                              isOpen={selectedOption !== null}
                              onClose={() => {
                                onChangeOptionId(undefined)
                              }}
                            />
                          )}
                        </Fragment>
                      )
                    } else {
                      return (
                        <div style={{ minHeight: '600px' }}>
                          <NonIdealState
                            icon="add"
                            title="Create an Option"
                            description="You can use options to add toppings to a pizza, fillings for a sandwich or sizes of a drink."
                            action={
                              <Button
                                text="Add Option"
                                icon="plus"
                                onClick={() =>
                                  toggleModal({
                                    optionModal: true,
                                  })
                                }
                              />
                            }
                          />
                        </div>
                      )
                    }
                  }}
                </Query>
                <OptionsImport
                  show={showImport}
                  importData={importFileData}
                  restaurantId={match.params['restaurants']}
                  onClose={() => {
                    setShowImport(false)
                  }}
                />
              </Card>
            </div>
            <AddOptionModal
              modalOpen={optionModal}
              modalClose={() =>
                toggleModal({
                  optionModal: false,
                })
              }
              restaurantId={match.params.restaurants}
            />
          </Fragment>
        )}
      </CacheQuery>
    </div>
  )
}

Options.propTypes = {
  match: matchType,
}

export default Options

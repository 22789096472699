import { Drawer, DrawerSize, Spinner } from '@blueprintjs/core'
import Query from '@components/Query/Query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import React, { Fragment, useState } from 'react'
import { Mutation } from 'react-apollo'
import { editBusinessSegmentStructure } from '../../../validation/business-segment'
import EditSegmentForm from './EditSegmentForm'
import EDIT_SEGMENT from './mutations/editSegment.mutation'
import GET_SEGMENT from './queries/getSegment.query'
import * as yup from 'yup'
import { setKeys } from '../../../utils/helpers'
import shortid from 'shortid'
import GET_SEGMENTS from './queries/getSegments.query'
import { BusinessSegmentType } from '../../../../../../server-services/src/shared/types/enums'
import EditCategorySegmentForm from './EditCategorySegmentForm'

const BLANK_INTERVAL = {
  start: { day: '1', time: '00:00' },
  end: { day: '7', time: '23:59' },
  key: shortid.generate(),
}

const EditSegmentDrawer = ({ id, isOpen, marketplaceId, onClose }) => {
  const [loading, setLoading] = useState(false)

  if (loading) return <Spinner />
  return (
    <Drawer
      title="Edit Business Segment"
      isOpen={isOpen}
      size={DrawerSize.STANDARD}
      onClose={() => onClose()}
    >
      <Query query={GET_SEGMENT} variables={{ id }}>
        {({ getBusinessSegment: segment }) => {
          if (!segment) {
            return null
          }
          if (!segment.groups.length) {
            segment.groups = [
              {
                filters: [
                  {
                    comparator: '',
                    attribute: '',
                    values: [],
                  },
                ],
              },
            ]
          }
          return (
            <Mutation
              mutation={EDIT_SEGMENT}
              onError={error => {
                setLoading(false)
                defaultErrorHandler(error)
              }}
              onCompleted={() => {
                setLoading(false)
                onClose()
                successToast(`Segment Updated Successfully`)
              }}
              refetchQueries={[
                {
                  query: GET_SEGMENT,
                  variables: {
                    id,
                  },
                },
                {
                  query: GET_SEGMENTS,
                  variables: {
                    marketplaceId,
                  },
                },
              ]}
            >
              {editSegment => (
                <Fragment>
                  {segment && (
                    <Formik
                      initialValues={{
                        ...segment,
                        limitedAvailability:
                          segment.availabilityEndDate &&
                          segment.availabilityStartDate
                            ? 'SCHEDULED'
                            : 'ALWAYS_AVAILABLE',
                        availabilityTimes:
                          segment.availabilityTimes &&
                          segment.availabilityTimes.length
                            ? setKeys(segment.availabilityTimes)
                            : [BLANK_INTERVAL],
                        availabilityStartDate: segment.availabilityStartDate
                          ? new Date(segment.availabilityStartDate)
                          : null,
                        availabilityEndDate: segment.availabilityEndDate
                          ? new Date(segment.availabilityEndDate)
                          : null,
                        showFeaturedFlags: segment.showFeaturedFlags,
                        showDiscountLabels: segment.showDiscountLabels,
                        openStatusFilter: segment.openStatusFilter
                          ? segment.openStatusFilter
                          : 'ALL',
                        outletLimit: segment.outletLimit,
                        categories:
                          segment.type === BusinessSegmentType.CATEGORIES
                            ? segment.businessSegmentCategories.map(
                                segmentCat => segmentCat.category
                              )
                            : [],
                        groups:
                          segment.type === BusinessSegmentType.OUTLETS
                            ? segment.groups
                            : [],
                      }}
                      onSubmit={async values => {
                        values.categories = values.categories.map(category => ({
                          id: category.id,
                        }))

                        if (values.limitedAvailability === 'ALWAYS_AVAILABLE') {
                          values.availabilityEndDate = null
                          values.availabilityStartDate = null
                        }
                        if (values.orderBy === '') {
                          values.orderBy = null
                        }

                        values.groups.map(group =>
                          group.filters.map(filter => {
                            delete filter.value
                            return filter
                          })
                        )

                        values.showFeaturedFlags =
                          typeof values.showFeaturedFlags === 'boolean'
                            ? values.showFeaturedFlags
                            : values.showFeaturedFlags[0] === 'on'

                        values.showDiscountLabels =
                          typeof values.showDiscountLabels === 'boolean'
                            ? values.showDiscountLabels
                            : values.showDiscountLabels[0] === 'on'

                        values.openStatusFilter =
                          values.openStatusFilter === 'ALL'
                            ? null
                            : values.openStatusFilter

                        await editSegment({
                          variables: values,
                        })
                      }}
                      validationSchema={yup
                        .object()
                        .shape(editBusinessSegmentStructure)}
                    >
                      {props => {
                        return (
                          <Fragment>
                            {segment.type === BusinessSegmentType.OUTLETS && (
                              <EditSegmentForm
                                {...props}
                                marketplaceId={marketplaceId}
                              />
                            )}
                            {segment.type ===
                              BusinessSegmentType.CATEGORIES && (
                              <EditCategorySegmentForm
                                {...props}
                                marketplaceId={marketplaceId}
                                categoryEmojiOrImage={
                                  segment.marketplace.categoryEmojiOrImage
                                }
                              />
                            )}
                          </Fragment>
                        )
                      }}
                    </Formik>
                  )}
                </Fragment>
              )}
            </Mutation>
          )
        }}
      </Query>
    </Drawer>
  )
}

export default EditSegmentDrawer

import React, { Fragment, useState } from 'react'
import get from 'lodash/get'
import { func, object } from 'prop-types'
import {
  Button,
  Intent,
  FormGroup,
  InputGroup,
  ControlGroup,
  HTMLSelect,
  TextArea,
  Text,
  Checkbox,
  Tabs,
  Tab,
  Tag,
  Radio,
  RadioGroup,
  H5,
} from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import { countryList, selectionUpdate } from '@utils/helpers'
import PartnerSelect from '../PartnerSelect/PartnerSelect'
import {
  canPerformAction,
  isAtLeastMarketplaceAdmin,
  isAtLeastPartner,
  isPlatformLevelUser,
} from '@stores/userStore'
import moment from 'moment'
import LinkTag from '@components/LinkTag/LinkTag'
import EntitySelectGroup from '@components/EnitySelect/EntitySelect'
import { format, subYears } from 'date-fns'

const editableField = () => {
  return !canPerformAction('editMarketplace')
}

const Form = ({
  handleChange,
  handleSubmit,
  errors,
  values,
  setFieldValue,
  initialValues,
  awsOptions,
  isCreate,
}) => {
  const [tabTerminal, setTabTerminal] = useState('terminal-reject')
  const [tabCheckout, setTabCheckout] = useState('checkout-age')

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup
        label={
          <span style={{ fontSize: 16, fontWeight: 'bold' }}>
            Marketplace Name
          </span>
        }
        labelFor="name"
        helperText={errors.name ? errors.name : ''}
        intent={Intent.DANGER}
      >
        <InputGroup
          name="name"
          id="name"
          type="text"
          onChange={handleChange}
          intent={errors.name ? Intent.DANGER : Intent.NONE}
          value={values.name}
          disabled={initialValues.name}
          large={true}
        />
      </FormGroup>

      {isPlatformLevelUser() && (
        <FormGroup
          label="Partner"
          labelInfo="(Required)"
          labelFor="partnerId"
          helperText={errors.partnerId ? errors.partnerId : ''}
          intent={Intent.DANGER}
        >
          <PartnerSelect
            partnerId={values.partnerId}
            disabled={values.partnerId === null || editableField()}
            onChange={event => {
              const selection = selectionUpdate(event)
              setFieldValue('partnerId', selection.value, false)
            }}
          />
        </FormGroup>
      )}

      <FormGroup
        label="Launch Date"
        labelFor="launchedAt"
        helperText={
          errors.launchedAt ||
          'Provide the date the marketplace is expected to go live or has gone live.'
        }
        intent={errors.launchedAt ? Intent.DANGER : Intent.NONE}
      >
        <DateInput
          parseDate={str => new Date(str)}
          placeholder="DD/MM/YYYY"
          formatDate={date => moment(date).format('DD/MM/YYYY')}
          value={values.launchedAt ? new Date(values.launchedAt) : null}
          onChange={date => setFieldValue('launchedAt', date)}
          disabled={!isAtLeastPartner()}
        />
      </FormGroup>
      <br />
      <H5>Business Details</H5>

      <EntitySelectGroup
        name="companyType"
        selectedValue={values.companyType}
        onChange={value => setFieldValue('companyType', value)}
      />

      {['COMPANY', 'PARTNERSHIP', 'NON_PROFIT'].includes(
        values.companyType
      ) && (
        <FormGroup
          label="Company Information"
          labelInfo="(Required)"
          intent={Intent.DANGER}
          helperText={
            [errors.companyLegalName, errors.companyNumber].filter(Boolean)
              .length ? (
              <ul className="bp3-list-unstyled">
                {[errors.companyLegalName, errors.companyNumber]
                  .filter(Boolean)
                  .map((errorString, index) => (
                    <li key={index}>{errorString}</li>
                  ))}
              </ul>
            ) : null
          }
        >
          <ControlGroup vertical={true}>
            <InputGroup
              name="companyLegalName"
              id="companyLegalName"
              type="text"
              leftIcon="office"
              onChange={handleChange}
              placeholder="Legal Company Name (e.g. Ltd, Inc, etc.)"
              intent={errors.companyLegalName ? Intent.DANGER : Intent.NONE}
              value={values.companyLegalName}
              disabled={editableField()}
              rightElement={
                !values.companyLegalName && (
                  <Tag icon="warning-sign" intent="warning" minimal />
                )
              }
            />

            <InputGroup
              name="companyNumber"
              id="companyNumber"
              type="text"
              leftIcon="document"
              placeholder="Company Number"
              onChange={handleChange}
              intent={errors.companyNumber ? Intent.DANGER : Intent.NONE}
              value={values.companyNumber}
              disabled={editableField()}
              rightElement={
                !values.companyNumber && (
                  <Tag icon="warning-sign" intent="warning" minimal />
                )
              }
            />
          </ControlGroup>
        </FormGroup>
      )}

      {values.companyType === 'PARTNERSHIP' && (
        <FormGroup
          label=" Unique Taxpayer Reference (UTR)"
          labelFor="taxReference"
          helperText={
            errors.taxReference ? 'Highlighted fields are required' : ''
          }
          intent={errors.taxReference ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            name="taxReference"
            type="text"
            placeholder=""
            onChange={handleChange}
            intent={errors.taxReference ? Intent.DANGER : Intent.NONE}
            value={values.taxReference}
          />
        </FormGroup>
      )}

      <FormGroup
        label="Owner Contact"
        labelInfo="(Required)"
        helperText={
          [errors.contactName, errors.contactEmail, errors.contactPhone].filter(
            Boolean
          ).length ? (
            <ul className="bp3-list-unstyled">
              {[errors.contactName, errors.contactEmail, errors.contactPhone]
                .filter(Boolean)
                .map((errorString, index) => (
                  <li key={index}>{errorString}</li>
                ))}
            </ul>
          ) : null
        }
        intent={Intent.DANGER}
      >
        <ControlGroup vertical={true}>
          <InputGroup
            name="contactName"
            id="contactName"
            type="text"
            onChange={handleChange}
            intent={errors.contactName ? Intent.DANGER : Intent.NONE}
            value={values.contactName}
            disabled={editableField()}
            rightElement={
              values.contactName === '' ? (
                <Tag icon="warning-sign" intent="warning" minimal />
              ) : null
            }
            placeholder="Full Name"
            leftIcon="person"
          />
          <InputGroup
            name="contactPhone"
            id="contactPhone"
            type="tel"
            onChange={handleChange}
            intent={errors.contactPhone ? Intent.DANGER : Intent.NONE}
            value={values.contactPhone}
            disabled={editableField()}
            leftIcon="phone"
            placeholder="Phone Number"
          />
          <InputGroup
            name="contactEmail"
            id="contactEmail"
            type="email"
            onChange={handleChange}
            intent={errors.contactEmail ? Intent.DANGER : Intent.NONE}
            value={values.contactEmail}
            disabled={editableField()}
            leftIcon="envelope"
            placeholder="Email Address"
          />
        </ControlGroup>
      </FormGroup>

      <FormGroup
        label="Notification Settings"
        labelFor="contactEmail"
        helperText={
          errors.contactEmail
            ? errors.contactEmail
            : 'Owners email will receive important marketplace and billing notifications.'
        }
        intent={errors.contactEmail ? Intent.DANGER : null}
        disabled={editableField()}
      >
        <Checkbox
          label={'Email a copy of receipt on each order.'}
          checked={values.emailReceiptOnOrder}
          onChange={handleChange}
          name="emailReceiptOnOrder"
        />
      </FormGroup>

      {values.companyType === 'INDIVIDUAL' && (
        <Fragment>
          <FormGroup
            label="National Insurance Number"
            labelFor="taxReference"
            helperText={
              errors.taxReference ? 'Highlighted fields are required' : ''
            }
            intent={errors.taxReference ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup
              name="taxReference"
              type="text"
              placeholder=""
              onChange={handleChange}
              intent={errors.taxReference ? Intent.DANGER : Intent.NONE}
              value={values.taxReference}
              rightElement={
                values.taxReference ? null : (
                  <Tag icon="warning-sign" intent="warning" minimal />
                )
              }
            />
          </FormGroup>
          <FormGroup
            label="Date of Birth"
            labelFor="taxReference"
            helperText={
              errors.taxReference ? 'Highlighted fields are required' : ''
            }
            intent={errors.taxReference ? Intent.DANGER : Intent.NONE}
          >
            <DateInput
              parseDate={str => new Date(str)}
              placeholder="Date of Birth"
              maxDate={new Date(subYears(new Date(), 16))} // 16 years old min
              minDate={new Date(subYears(new Date(), 100))} // over 100, retire!
              formatDate={date => format(date, 'dd/MM/yyyy')}
              value={values.contactDoB ? new Date(values.contactDoB) : null}
              onChange={date => setFieldValue('contactDoB', date)}
              rightElement={
                values.contactDoB ? null : (
                  <Tag icon="warning-sign" intent="warning" minimal />
                )
              }
            />
          </FormGroup>
        </Fragment>
      )}

      <FormGroup
        label={`${
          values.companyType === 'INDIVIDUAL' ? 'Home' : 'Registered'
        } Address`}
        labelInfo="(Required)"
        labelFor="firstLine"
        helperText={
          errors.contactAddress ? 'Highlighted fields are required' : ''
        }
        intent={Intent.DANGER}
      >
        <ControlGroup vertical={true}>
          <InputGroup
            name="contactAddress.firstLine"
            id="firstLine"
            type="text"
            placeholder="Office Address"
            onChange={handleChange}
            intent={
              get(errors, 'contactAddress.firstLine')
                ? Intent.DANGER
                : Intent.NONE
            }
            value={values.contactAddress.firstLine}
            disabled={editableField()}
          />
          <InputGroup
            name="contactAddress.secondLine"
            type="text"
            placeholder=""
            onChange={handleChange}
            value={values.contactAddress.secondLine}
            disabled={editableField()}
          />
          <InputGroup
            name="contactAddress.thirdLine"
            type="text"
            placeholder=""
            onChange={handleChange}
            value={values.contactAddress.thirdLine}
            disabled={editableField()}
          />
          <InputGroup
            name="contactAddress.city"
            type="text"
            placeholder="City"
            onChange={handleChange}
            intent={
              get(errors, 'contactAddress.city') ? Intent.DANGER : Intent.NONE
            }
            value={values.contactAddress.city}
            disabled={editableField()}
          />

          <InputGroup
            name="contactAddress.postcode"
            type="text"
            placeholder="Postcode"
            onChange={handleChange}
            intent={
              get(errors, 'contactAddress.postcode')
                ? Intent.DANGER
                : Intent.NONE
            }
            value={values.contactAddress.postcode}
            disabled={editableField()}
          />

          <HTMLSelect
            name="country"
            options={countryList()}
            value={values.contactAddress.country.code}
            onChange={event => {
              const selection = selectionUpdate(event)
              setFieldValue(
                'contactAddress.country',
                {
                  name: selection.label,
                  code: selection.value,
                },
                false
              )
            }}
            disabled={editableField()}
          />
        </ControlGroup>
      </FormGroup>
      {!isCreate && (
        <Fragment>
          <br />
          <H5>Support Contact</H5>

          <FormGroup
            label="Support Email Address"
            labelFor="supportEmail"
            subLabel={errors.supportEmail ? errors.supportEmail : ''}
            helperText="This email is public and will receive support requests from customers."
            intent={errors.supportEmail ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup
              name="supportEmail"
              id="supportEmail"
              type="email"
              leftIcon="envelope"
              onChange={handleChange}
              intent={errors.supportEmail ? Intent.DANGER : Intent.NONE}
              value={values.supportEmail}
            />
          </FormGroup>

          <FormGroup
            label="Support Phone Number"
            labelFor="supportPhone"
            subLabel={errors.supportPhone ? errors.supportPhone : ''}
            helperText="This phone number is public, shown on the ordering clients and will receive support calls from customers."
            intent={errors.supportPhone ? Intent.DANGER : Intent.NONE}
          >
            <InputGroup
              name="supportPhone"
              id="supportPhone"
              leftIcon="phone"
              type="tel"
              onChange={handleChange}
              intent={errors.supportPhone ? Intent.DANGER : Intent.NONE}
              value={values.supportPhone}
            />
          </FormGroup>
        </Fragment>
      )}
      {isAtLeastMarketplaceAdmin() && (
        <Fragment>
          {!isCreate && (
            <Fragment>
              <br />
              <H5>Analytics</H5>

              <FormGroup
                label="Google Universal Analytics ID"
                labelInfo="(UA-XXXXX-XX)"
                labelFor="trackingId"
                helperText={errors.trackingId ? errors.trackingId : ''}
                intent={Intent.DANGER}
              >
                <InputGroup
                  fill
                  name="trackingId"
                  onChange={handleChange}
                  value={values.trackingId}
                  rightElement={
                    <LinkTag
                      type="deprecated"
                      message={
                        <Fragment>
                          <p>
                            Google Analytics is transitioning to Google
                            Analytics 4 (GA4), making Universal Analytics (UA)
                            properties obsolete. To ensure continuous tracking
                            and access to new features, please update to a GA4
                            property ID.
                          </p>
                          <p>
                            <a
                              href="https://support.google.com/analytics/answer/9744165"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Learn more about migrating to GA4.
                            </a>
                          </p>
                        </Fragment>
                      }
                    />
                  }
                  disabled={editableField()}
                />
              </FormGroup>
              <FormGroup
                label="Google Analytics 4 ID"
                labelInfo="(G-XXXXX-XX)"
                labelFor="ga4Id"
                helperText={errors.ga4Id ? errors.ga4Id : ''}
                intent={Intent.DANGER}
              >
                <InputGroup
                  fill
                  name="ga4Id"
                  onChange={handleChange}
                  value={values.ga4Id}
                  disabled={editableField()}
                  rightElement={<LinkTag type="web-v2" />}
                />
              </FormGroup>
            </Fragment>
          )}
          <br />
          <H5>Settings</H5>

          <FormGroup
            label="Domain"
            labelInfo="(cname)"
            labelFor="cname"
            helperText={errors.cname ? errors.cname : ''}
            intent={Intent.DANGER}
            disabled={editableField()}
          >
            <InputGroup
              name="cname"
              id="cname"
              type="text"
              onChange={handleChange}
              intent={errors.cname ? Intent.DANGER : Intent.NONE}
              value={values.cname || null}
              disabled={editableField()}
            />
          </FormGroup>
          <FormGroup
            label="URL Path"
            labelFor="urlPath"
            helperText={
              errors.urlPath
                ? errors.urlPath
                : 'Changes may impact SEO backlinks & search rankings.'
            }
            intent={errors.urlPath ? Intent.DANGER : Intent.WARNING}
          >
            <InputGroup
              name="urlPath"
              id="urlPath"
              type="text"
              onChange={handleChange}
              value={values.urlPath || null}
              placeholder="takeaways"
              disabled={editableField()}
              rightElement={<LinkTag type="web-v2" />}
            />
          </FormGroup>
          <FormGroup
            label="Ordering Mode"
            labelInfo="(Required)"
            labelFor="orderMode"
            helperText={errors.orderMode ? errors.orderMode : ''}
            intent={Intent.DANGER}
          >
            <HTMLSelect
              name="orderMode"
              options={[
                { value: null, label: 'Select...' },
                { value: 'LIST', label: 'List' },
                { value: 'POSTCODE', label: 'Postcode' },
                { value: 'SINGLE', label: 'Single Outlet' },
              ]}
              value={values.orderMode}
              onChange={event => {
                const selection = selectionUpdate(event)
                setFieldValue('orderMode', selection.value, false)
              }}
              fill={true}
              disabled={editableField() || !isAtLeastPartner()}
            />
          </FormGroup>

          <FormGroup
            label="Business Type"
            labelInfo="(Required)"
            labelFor="merchantType"
            helperText={errors.merchantType ? errors.merchantType : ''}
            intent={Intent.DANGER}
          >
            <HTMLSelect
              name="merchantType"
              options={[
                { value: null, label: 'Select...' },
                { value: 'RESTAURANT', label: 'Restaurant' },
                { value: 'RETAIL', label: 'Retail' },
              ]}
              value={values.merchantType}
              onChange={event => {
                const selection = selectionUpdate(event)
                setFieldValue('merchantType', selection.value, false)
                if (values.merchantType === 'RETAIL') {
                  setFieldValue(
                    'outletOpenStatus',
                    'ORDER_PREORDER_VIEW',
                    false
                  )
                }
              }}
              fill={true}
              disabled={editableField()}
            />
          </FormGroup>

          {isPlatformLevelUser() && (
            <FormGroup label="Email Configuration">
              <HTMLSelect
                name="pinpointAwsAccountId"
                options={awsOptions}
                value={values.pinpointAwsAccountId}
                disabled={editableField() || !isPlatformLevelUser()}
                onChange={handleChange}
                fill={true}
              />
            </FormGroup>
          )}

          {!isCreate && values.merchantType === 'RESTAURANT' && (
            <Fragment>
              <br />
              <H5>
                Outlet Status <LinkTag type="web-v2" />
              </H5>
              <FormGroup
                label="Open Status Wording"
                subLabel="Customise the style of the open statues."
                disabled={!isAtLeastPartner()}
              >
                <RadioGroup
                  name={'outletOpenStatus'}
                  onChange={e => {
                    handleChange(e)
                  }}
                  selectedValue={values.outletOpenStatus}
                >
                  <Radio
                    name="Order, Preorder & View"
                    label="Order, Preorder & View"
                    value={'ORDER_PREORDER_VIEW'}
                  />
                  <Radio
                    name="Open, Preorder & Closed"
                    label="Open, Preorder & Closed"
                    value={'OPEN_PREORDER_CLOSED'}
                  />
                </RadioGroup>
              </FormGroup>
            </Fragment>
          )}

          <Tabs
            id="terminalTabs"
            onChange={navbarTabId => {
              setTabTerminal(navbarTabId.toString())
            }}
            selectedTabId={tabTerminal}
          >
            <H5>Terminal Messages</H5>
            <Tabs.Expander />
            <Tab
              id="terminal-reject"
              title="Reject Order"
              panel={
                <FormGroup
                  label="Send Message"
                  labelInfo="(Required)"
                  labelFor="defaultRejectText"
                  helperText={
                    errors.defaultRejectText ? errors.defaultRejectText : ''
                  }
                  intent={Intent.DANGER}
                >
                  <TextArea
                    fill
                    name="defaultRejectText"
                    onChange={handleChange}
                    value={values.defaultRejectText}
                    style={{ height: 140 }}
                  />
                </FormGroup>
              }
            />
            <Tab
              id="terminal-cancel"
              title="Cancel Order"
              panel={
                <FormGroup
                  label="Send Message"
                  labelInfo="(Required)"
                  labelFor="defaultCancelText"
                  helperText={
                    errors.defaultCancelText ? errors.defaultCancelText : ''
                  }
                  intent={Intent.DANGER}
                >
                  <Fragment>
                    <TextArea
                      fill
                      name="defaultCancelText"
                      onChange={handleChange}
                      value={values.defaultCancelText}
                      style={{ height: 140 }}
                    />
                  </Fragment>
                </FormGroup>
              }
            />
          </Tabs>
          <Text className="bp3-text-muted bp3-text-small">
            The following text will always be displayed at the end of your
            messages:
          </Text>
          {values.receiptHideMarketplace ? (
            <Text>
              {' '}
              <i>Business Name</i>
            </Text>
          ) : (
            <Text>
              {' '}
              <i>Business Name</i> & {values.name}
            </Text>
          )}

          <br />

          {!isCreate && (
            <Fragment>
              <H5>
                Checkout Message <LinkTag type="web-v2" />
              </H5>
              <Tabs
                id="checkoutTabs"
                onChange={navbarTabId => {
                  setTabCheckout(navbarTabId.toString())
                }}
                selectedTabId={tabCheckout}
              >
                <Tabs.Expander />
                <Tab
                  id="checkout-age"
                  title="Age Restriction"
                  panel={
                    <FormGroup
                      label="Display Message"
                      labelFor="ageRestrictionText"
                      helperText={
                        errors.ageRestrictionText
                          ? errors.ageRestrictionText
                          : 'Displays when customers add age-restricted items to the basket.'
                      }
                      intent={
                        errors.ageRestrictionText ? Intent.DANGER : Intent.NONE
                      }
                    >
                      <Fragment>
                        <TextArea
                          fill
                          name="ageRestrictionText"
                          placeholder="e.g.  I am over 18 and have valid ID."
                          onChange={handleChange}
                          value={values.ageRestrictionText}
                          style={{ height: 80 }}
                          maxLength={80}
                        />
                      </Fragment>
                    </FormGroup>
                  }
                />
                <Tab
                  id="special-instructions"
                  title="Special Instructions"
                  panel={
                    <FormGroup
                      label="Display Message"
                      labelFor="specialInstructionsText"
                      helperText={
                        errors.specialInstructionsText
                          ? errors.specialInstructionsText
                          : "Displays as a placeholder message on the menu item's special instructions input field."
                      }
                      intent={
                        errors.specialInstructionsText
                          ? Intent.DANGER
                          : Intent.NONE
                      }
                    >
                      <Fragment>
                        <TextArea
                          fill
                          name="specialInstructionsText"
                          placeholder=""
                          onChange={handleChange}
                          value={values.specialInstructionsText}
                          style={{ height: 80 }}
                          maxLength={80}
                        />
                      </Fragment>
                    </FormGroup>
                  }
                />
                <Tab
                  id="checkout-message"
                  title="Checkout"
                  panel={
                    <FormGroup
                      label="Display Message"
                      labelFor="checkoutText"
                      helperText={
                        errors.checkoutText
                          ? errors.checkoutText
                          : 'Displays in checkout when the customer chooses to pay by card.'
                      }
                      intent={errors.checkoutText ? Intent.DANGER : Intent.NONE}
                    >
                      <Fragment>
                        <TextArea
                          fill
                          name="checkoutText"
                          onChange={handleChange}
                          placeholder=""
                          value={values.checkoutText}
                          style={{ height: 80 }}
                          maxLength={80}
                        />
                      </Fragment>
                    </FormGroup>
                  }
                />
                <Tab
                  id="order-note-text"
                  title="Order"
                  panel={
                    <FormGroup
                      label="Placeholder Text"
                      labelFor="orderNoteText"
                      helperText={
                        errors.orderNoteText
                          ? errors.orderNoteText
                          : '"Displays as a placeholder message on the baskets Order Note input field.'
                      }
                      intent={
                        errors.orderNoteText ? Intent.DANGER : Intent.NONE
                      }
                    >
                      <Fragment>
                        <TextArea
                          fill
                          name="orderNoteText"
                          onChange={handleChange}
                          value={values.orderNoteText}
                          placeholder=""
                          style={{ height: 80 }}
                          maxLength={80}
                        />
                      </Fragment>
                    </FormGroup>
                  }
                />
                <Tab
                  id="checkout-delivery-note"
                  title="Delivery"
                  panel={
                    <FormGroup
                      label="Placeholder Text"
                      labelFor="deliveryNoteText"
                      helperText={
                        errors.deliveryNoteText
                          ? errors.deliveryNoteText
                          : 'Displays as a placeholder message on the Delivery Note input field.'
                      }
                      intent={
                        errors.deliveryNoteText ? Intent.DANGER : Intent.NONE
                      }
                    >
                      <Fragment>
                        <TextArea
                          fill
                          name="deliveryNoteText"
                          onChange={handleChange}
                          placeholder=""
                          value={values.deliveryNoteText}
                          style={{ height: 80 }}
                          maxLength={80}
                        />
                      </Fragment>
                    </FormGroup>
                  }
                />
                <Tab
                  id="checkout-connection-note"
                  title="Collection"
                  panel={
                    <FormGroup
                      label="Placeholder Text"
                      labelFor="collectionNoteText"
                      helperText={
                        errors.collectionNoteText
                          ? errors.collectionNoteText
                          : 'Displays a placeholder message on the Collection Note input field.'
                      }
                      intent={
                        errors.collectionNoteText ? Intent.DANGER : Intent.NONE
                      }
                    >
                      <Fragment>
                        <TextArea
                          fill
                          name="collectionNoteText"
                          onChange={handleChange}
                          value={values.collectionNoteText}
                          placeholder=""
                          style={{ height: 80 }}
                          maxLength={80}
                        />
                      </Fragment>
                    </FormGroup>
                  }
                />
                <Tab
                  id="checkout-table-note"
                  title="Table Service"
                  panel={
                    <FormGroup
                      label="Placeholder Text"
                      labelFor="tableNoteText"
                      helperText={
                        errors.tableNoteText
                          ? errors.tableNoteText
                          : 'Displays a placeholder message on the Table Service Note input field.'
                      }
                      intent={
                        errors.tableNoteText ? Intent.DANGER : Intent.NONE
                      }
                    >
                      <Fragment>
                        <TextArea
                          fill
                          name="tableNoteText"
                          onChange={handleChange}
                          value={values.tableNoteText}
                          placeholder=""
                          style={{ height: 80 }}
                          maxLength={80}
                        />
                      </Fragment>
                    </FormGroup>
                  }
                />
              </Tabs>

              <br />
              <H5>Onboarding Message</H5>

              <FormGroup
                label="This text is displayed at the start of the business onboarding
          experience."
                labelFor="onboardingDescription"
                helperText={
                  errors.onboardingDescription
                    ? errors.onboardingDescription
                    : ''
                }
                intent={Intent.DANGER}
              >
                <Fragment>
                  <TextArea
                    fill
                    name="onboardingDescription"
                    onChange={handleChange}
                    value={values.onboardingDescription}
                    style={{ height: 140 }}
                  />
                </Fragment>
              </FormGroup>
            </Fragment>
          )}
        </Fragment>
      )}

      <div className="bp-card-footer-actions">
        <Button text="Save" type="submit" />
      </div>
    </form>
  )
}

Form.propTypes = {
  handleChange: func,
  handleSubmit: func,
  setFieldValue: func,
  errors: object,
  values: object,
}

export default Form

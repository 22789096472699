import background from '@assets/background.jpg'

const styles = () => ({
  container: {
    display: 'flex',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
    backgroundPosition: 'center',
  },
  loginContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logoContainer: {
    height: 111,
    width: 89,
  },
  loginForm: {
    width: 340,
    marginBottom: 20,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
})

export default styles

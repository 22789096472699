import React, { useState } from 'react'
import { Suggest } from '@blueprintjs/select'
import {
  Button,
  Icon,
  Intent,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core'
import { sortBy } from 'lodash'

const TypeaheadSingle = ({
  selected,
  setSelected,
  items,
  placeholder = 'Select an item',
  noResults = 'No matches',
  disabled = false,
  clear = true,
  isOpenFilter = false,
  selectedIcon = 'tick',
  unSelectedIcon = 'blank',
  rightElement,
  intent = Intent.NONE,
}) => {
  const isSelected = item => selected === item.id
  const [searchVal, setSearchVal] = useState('')
  const [selectedIsOpen, setSelectedIsOpen] = useState(false)

  const ItemRenderer = (item, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }

    return (
      <MenuItem
        active={modifiers.active}
        icon={
          isSelected(item)
            ? selectedIcon
            : isOpenFilter && !item.isOpen
            ? 'warning-sign'
            : unSelectedIcon
        }
        key={item.id}
        label={item.label || ''}
        onClick={() => {
          handleClick()
          setSelectedIsOpen(item.isOpen)
        }}
        text={item.name}
        shouldDismissPopover={false}
      />
    )
  }

  return (
    <Suggest
      onQueryChange={query => setSearchVal(query)}
      items={sortBy(items, 'name')}
      itemRenderer={ItemRenderer}
      itemPredicate={(query, item) => {
        const search =
          item.name.toLowerCase() + (item.label || '').toLowerCase()

        return search.includes(query.toLowerCase())
      }}
      onItemSelect={({ id }) => setSelected(id)}
      itemsEqual={(a, b) => a.id === b.id}
      inputValueRenderer={selId => {
        const item = items.find(({ id }) => id === selId)

        return item ? item.name : null
      }}
      inputProps={{
        placeholder,
        intent,
        rightElement:
          rightElement ||
          (clear && (
            <Button
              minimal
              disabled={disabled}
              icon="cross"
              onClick={() => {
                setSelected(null)
                setSearchVal('')
              }}
              style={searchVal || selected ? {} : { visibility: 'hidden' }}
            />
          )),
        leftElement:
          !isOpenFilter ||
          (isOpenFilter && selectedIsOpen) ||
          (isOpenFilter && !selected) ? null : (
            <Popover
              position={Position.BOTTOM}
              interactionKind={PopoverInteractionKind.HOVER}
              content={
                <div style={{ padding: '5px' }}>
                  This outlet is currently closed
                </div>
              }
            >
              <Icon icon="warning-sign" style={{ padding: ' 6px 6px 0 6px' }} />
            </Popover>
          ),
      }}
      selectedItem={selected}
      popoverProps={{
        minimal: true,
        position: Position.BOTTOM_LEFT,
        popoverClassName: 'bp3-popover-select',
        modifiers: {},
      }}
      noResults={
        <MenuItem icon="warning-sign" label={noResults} disabled={true} />
      }
      disabled={disabled}
      resetOnClose
    />
  )
}

export default TypeaheadSingle

import React, { Fragment, useState } from 'react'
import first from 'lodash/first'
import { string } from 'prop-types'
import {
  Tag,
  Card,
  Intent,
  Callout,
  H5,
  HTMLTable,
  NonIdealState,
  H4,
  Icon,
  Divider,
  FormGroup,
  AnchorButton,
} from '@blueprintjs/core'

import Query from '@components/Query/Query'
import GET_MARKETPLACE_BILLING from './queries/getMarketplaceBilling.query'
import AddPaymentMethodButton from '@components/Billing/AddBillingPaymentMethodButton'
import DeletePaymentMethodButton from '@components/Billing/DeleteBillingPaymentMethodButton'
import Currency from '../../Currency/Currency'
import moment from 'moment'
import MarketingDialog from '../../Billing/dialogs/Marketing.dialog'
import { Row, Col } from 'react-simple-flex-grid'
import WayboxDialog from '@components/Billing/dialogs/Waybox.dialog'
import { isAtLeastPartner, isPlatformLevelUser } from '@stores/userStore'
import { startCase } from 'lodash'
import CopyText from '@components/CopyText/CopyText'

const Billing = ({ match }) => {
  const { marketplace: marketplaceId } = match.params
  function getInvoiceIntent(status) {
    switch (status) {
      case 'paid':
        return Intent.SUCCESS
      case 'due':
        return Intent.WARNING
      case 'overdue':
        return Intent.DANGER
      default:
        return Intent.NONE
    }
  }

  const [showFeature, setShowFeature] = useState(null)

  return (
    <Fragment>
      <MarketingDialog
        marketplaceId={marketplaceId}
        isOpen={showFeature === 'MARKETING'}
        onClose={() => setShowFeature(null)}
      />
      <WayboxDialog
        marketplaceId={marketplaceId}
        isOpen={showFeature === 'WAYBOX'}
        onClose={() => setShowFeature(null)}
      />
      <Query
        query={GET_MARKETPLACE_BILLING}
        variables={{ id: marketplaceId }}
        loaderTitle={'Loading Billing'}
      >
        {({ getMarketplaces: { regions } }) => {
          const marketplace = first(regions)
          if (!marketplace) {
            return 'Unable to find marketplace'
          }
          if (!marketplace.allowBilling && !marketplace.billing) {
            return (
              <NonIdealState
                title="Billing Disabled"
                icon="disable"
                description={
                  'This marketplace does not have access to premium features.'
                }
              />
            )
          }
          const paymentMethods =
            (marketplace.billing && marketplace.billing.paymentMethods) || []
          const isPaymentSetup = paymentMethods.length > 0
          const invoices = marketplace.invoices || []

          const upcomingInvoiceItems =
            (marketplace.billing &&
              marketplace.billing.upcomingInvoice &&
              marketplace.billing.upcomingInvoice.items.filter(item => {
                return item.amount > 0
              })) ||
            []

          return (
            <Fragment>
              <Fragment>
                {marketplace.billing && marketplace.billing.delinquent && (
                  <Callout
                    intent="danger"
                    title="Services Suspended"
                    style={{ marginBottom: 24 }}
                  >
                    We have been unable to collect payment for redbox add-ons
                    and services, please settle all open invoices before
                    reactivating features.
                  </Callout>
                )}

                {!isPaymentSetup && (
                  <Callout intent="warning" style={{ marginBottom: 24 }}>
                    A payment method has not been configured on this
                    marketplace,{' '}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://support.redbox.systems/docs/redbox-billing-setting-up-your-direct-debit"
                    >
                      learn more about billing
                    </a>
                    .
                    <AddPaymentMethodButton
                      id={marketplaceId}
                      minimal
                      intent="warning"
                      style={{ position: 'absolute', right: 5, top: 5 }}
                    />
                  </Callout>
                )}
              </Fragment>

              <Row gutter={24}>
                <Col xs={12} sm={12} md={6} lg={8} order={2}>
                  <Row gutter={24}>
                    {marketplace.billing.products.map(product => {
                      return (
                        product.featured &&
                        product.service && (
                          <Col xs={12} sm={12} md={12} lg={6}>
                            <Card
                              onClick={() => setShowFeature(product.service)}
                              interactive={!product.label}
                              style={{ minHeight: 160, padding: 12 }}
                            >
                              <Callout
                                icon={
                                  product.icon && (
                                    <img
                                      className="bp3-icon"
                                      src={product.icon}
                                      height="22"
                                      width="22"
                                    />
                                  )
                                }
                                title={product.name}
                                style={{ background: 'transparent' }}
                              >
                                <p>
                                  {product.description}
                                  <br />
                                  <a>Learn More</a>
                                </p>
                                {product.subscribed ? (
                                  <Tag
                                    icon="small-tick"
                                    intent="success"
                                    style={{
                                      position: 'absolute',
                                      right: 12,
                                      top: 12,
                                    }}
                                  >
                                    ACTIVE
                                  </Tag>
                                ) : product.label ? (
                                  <Fragment>
                                    <Tag
                                      intent="primary"
                                      minimal
                                      style={{
                                        position: 'absolute',
                                        right: 12,
                                        top: 12,
                                      }}
                                    >
                                      {product.label}
                                    </Tag>
                                  </Fragment>
                                ) : (
                                  <Tag
                                    rightIcon="plus"
                                    intent="success"
                                    minimal
                                    style={{
                                      position: 'absolute',
                                      right: 12,
                                      top: 12,
                                    }}
                                  >
                                    ADD
                                  </Tag>
                                )}
                              </Callout>
                            </Card>
                          </Col>
                        )
                      )
                    })}
                  </Row>

                  <Card className="bp3-nopad">
                    {invoices !== null && invoices.length > 0 ? (
                      <HTMLTable bordered={false} interactive={false}>
                        <thead>
                          <tr>
                            <th>Invoices</th>
                            <th>Date</th>
                            <th>Net</th>
                            <th>Gross</th>
                            <th>Status</th>
                            <th
                              colSpan={2}
                              style={{ width: '0.1%', whiteSpace: 'nowrap' }}
                            >
                              Due
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoices.map(invoice => (
                            <tr key={invoice.id}>
                              <td>
                                <a
                                  href={invoice.invoicePdf}
                                  rel="noopener noreferrer"
                                >
                                  <code>{invoice.invoiceNumber}</code>
                                </a>
                              </td>
                              <td>
                                {moment.unix(invoice.dueDate).format('DD MMM')}
                              </td>
                              <td>
                                <Currency amount={invoice.subtotal} />
                              </td>
                              <td>
                                <Currency amount={invoice.amountDue} />
                              </td>
                              <td>
                                <Tag
                                  minimal
                                  intent={getInvoiceIntent(invoice.status)}
                                >
                                  {invoice.status.toUpperCase()}
                                </Tag>
                              </td>
                              <td>
                                <span
                                  className={
                                    invoice.amountRemaining === 0 &&
                                    'bp3-text-muted'
                                  }
                                  style={
                                    invoice.status === 'overdue'
                                      ? { color: 'red' }
                                      : {}
                                  }
                                >
                                  <Currency amount={invoice.amountRemaining} />
                                </span>
                              </td>
                              <td>
                                {invoice.status === 'overdue' && (
                                  <AnchorButton
                                    minimal
                                    target="_blank"
                                    rightIcon="log-in"
                                    intent="primary"
                                    href={invoice.invoiceUrl}
                                    style={{ marginLeft: 8 }}
                                  >
                                    Pay
                                  </AnchorButton>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </HTMLTable>
                    ) : (
                      <div style={{ padding: '100px' }}>
                        <NonIdealState
                          icon="th-list"
                          title="No Marketplace Invoices"
                          description="Please check back at the end of your billing period."
                        />
                      </div>
                    )}
                  </Card>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} order={1}>
                  <Card>
                    <H4>
                      Payment{' '}
                      {marketplace.billing.livemode === false && (
                        <Tag intent={Intent.WARNING}>TEST</Tag>
                      )}
                    </H4>
                    {isPlatformLevelUser() && (
                      <Fragment>
                        <FormGroup label="Stripe Customer">
                          <CopyText
                            leftIcon={
                              <Icon icon="tick-circle" intent="success" />
                            }
                            text={marketplace.billing.id}
                            mono
                          />
                        </FormGroup>
                      </Fragment>
                    )}
                    <p>
                      Monthly billing for add-on features, services and
                      overages.
                    </p>
                    {paymentMethods.length > 0 ? (
                      paymentMethods.map(method => (
                        <Fragment key={method.id}>
                          <Callout key={method.account}>
                            <strong>{startCase(method.type)}</strong>
                            {paymentMethods.length > 1 && method.default && (
                              <Tag
                                minimal
                                style={{
                                  position: 'absolute',
                                  right: 6,
                                  top: 6,
                                }}
                              >
                                DEFAULT
                              </Tag>
                            )}
                            <br />
                            <code>
                              {method.account} - {method.sort}
                            </code>

                            {isAtLeastPartner() && (
                              <DeletePaymentMethodButton
                                id={marketplaceId}
                                level="MARKETPLACE"
                                paymentMethodId={method.id}
                                style={{
                                  position: 'absolute',
                                  right: 6,
                                  bottom: 6,
                                }}
                              />
                            )}
                          </Callout>

                          {method.type === 'bacs_debit' && (
                            <div style={{ marginTop: 8 }}>
                              <a
                                className="bp3-text-small"
                                href="https://www.directdebit.co.uk/direct-debit-explained/what-is-direct-debit/"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                How do Direct Debits work?
                              </a>
                              <br />
                            </div>
                          )}
                          <br />
                        </Fragment>
                      ))
                    ) : (
                      <AddPaymentMethodButton
                        id={marketplaceId}
                        level="MARKETPLACE"
                        fill
                      />
                    )}

                    {marketplace.billing.upcomingInvoice && (
                      <Fragment>
                        {upcomingInvoiceItems &&
                          upcomingInvoiceItems.length > 0 && (
                            <Fragment>
                              <br />
                              <br />
                              <Divider />
                              <br />
                              <H5>Billing Estimate</H5>
                              <p>
                                <Icon icon="time" /> Usage during{' '}
                                <strong>
                                  {moment
                                    .unix(
                                      marketplace.billing.upcomingInvoice
                                        .periodStart
                                    )
                                    .format('MMM')}
                                </strong>
                                , next invoice{' '}
                                <strong>
                                  {moment
                                    .unix(
                                      marketplace.billing.upcomingInvoice
                                        .periodEnd
                                    )
                                    .format('MMM Qo')}
                                </strong>
                                .
                              </p>
                              <Card
                                className="bp3-nopad"
                                style={{ marginBottom: 0 }}
                              >
                                <HTMLTable interactive={false}>
                                  <thead>
                                    <tr>
                                      <th>Item</th>
                                      <th style={{ textAlign: 'right' }}>
                                        Amount
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {upcomingInvoiceItems.map(item => {
                                      return (
                                        <tr key={item.id}>
                                          <td className="bp3-text-small">
                                            {item.description}
                                          </td>
                                          <td
                                            className="bp3-text-small"
                                            style={{ textAlign: 'right' }}
                                          >
                                            <Currency amount={item.amount} />
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <th className="bp3-text-small">
                                        Subtotal
                                      </th>
                                      <th
                                        className="bp3-text-small"
                                        style={{ textAlign: 'right' }}
                                      >
                                        <Currency
                                          amount={
                                            marketplace.billing.upcomingInvoice
                                              .subtotal
                                          }
                                        />
                                      </th>
                                    </tr>
                                    <tr>
                                      <th className="bp3-text-small">VAT</th>
                                      <th
                                        className="bp3-text-small"
                                        style={{ textAlign: 'right' }}
                                      >
                                        <Currency
                                          amount={
                                            marketplace.billing.upcomingInvoice
                                              .tax
                                          }
                                        />
                                      </th>
                                    </tr>
                                    <tr>
                                      <th>Total</th>
                                      <th
                                        style={{
                                          textAlign: 'right',
                                        }}
                                      >
                                        <Currency
                                          amount={
                                            marketplace.billing.upcomingInvoice
                                              .total
                                          }
                                        />
                                      </th>
                                    </tr>
                                  </tfoot>
                                </HTMLTable>
                              </Card>
                              <br />
                              <span className="bp3-text-small">
                                <p className="bp3-text-muted">
                                  Plans billed in advance on the 1st of the
                                  month, usage is billed in arrears.
                                </p>
                                <a
                                  href="https://support.redbox.systems/docs/billing-faq"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  How does Redbox Billing work?
                                </a>
                              </span>
                            </Fragment>
                          )}
                      </Fragment>
                    )}
                  </Card>
                </Col>
              </Row>
            </Fragment>
          )
        }}
      </Query>
    </Fragment>
  )
}

Billing.propTypes = {
  marketplaceId: string.isRequired,
}

export default Billing

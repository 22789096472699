import React from 'react'
import { Card, HTMLTable, Tag } from '@blueprintjs/core'
import CopyText from '@components/CopyText/CopyText'
import { format } from 'date-fns'

const DELIVERY_PROVIDER_DISPLAY_MAP = {
  STUART: 'Stuart',
  ICABBI: 'iCabbi',
  WAYBOX: 'Waybox',
  UBER_DIRECT: 'Uber Direct',
}

const DeliveryNetwork = ({ order }) => {
  let deliveryStatusToDisplay =
    order.orderStatus === 'PENDING'
      ? 'PENDING'
      : order.deliveryNetworkBookingStatus
      ? order.deliveryNetworkBookingStatus.replace(/_/g, ' ')
      : 'UNKNOWN'

  let driverNameToDisplay =
    order.orderStatus === 'PENDING'
      ? 'PENDING'
      : order.deliveryNetworkDriverName || 'Unknown'

  let jobIdToDisplay =
    order.orderStatus === 'PENDING'
      ? 'PENDING'
      : order.deliveryNetworkBookingId || 'Unknown'

  // if PENDING, waybox already has a job id and driver name, depending if the order is ASAP or not
  if (
    order.orderStatus === 'PENDING' &&
    order.deliveryNetworkProvider === 'WAYBOX'
  ) {
    deliveryStatusToDisplay = order.deliveryNetworkBookingStatus
      ? order.deliveryNetworkBookingStatus.replace(/_/g, ' ')
      : 'PENDING'
    driverNameToDisplay = order.deliveryNetworkDriverName || 'Unknown'
    jobIdToDisplay = order.deliveryNetworkBookingId || 'Unknown'
  }

  return (
    <Card className="bp3-nopad">
      <HTMLTable bordered={true} condensed={true}>
        <thead>
          <tr>
            <td>
              <strong>Delivery Network</strong>
            </td>
            <td>
              {DELIVERY_PROVIDER_DISPLAY_MAP[order.deliveryNetworkProvider] ||
                'Unknown'}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Status</td>
            <td>
              <Tag minimal={true}>{deliveryStatusToDisplay}</Tag>
            </td>
          </tr>
          <tr>
            <td>Driver Name</td>
            <td>{driverNameToDisplay}</td>
          </tr>
          <tr>
            <td>Job ID</td>
            <td>
              <CopyText minimal text={jobIdToDisplay} />
            </td>
          </tr>
          {order.deliveryNetworkPickupEta && (
            <tr>
              <td>Estimated Pickup</td>
              <td>
                {format(
                  new Date(order.deliveryNetworkPickupEta),
                  'HH:mm:ss dd/MM/yy'
                )}
              </td>
            </tr>
          )}
          <tr>
            {order.deliveryNetworkJobTrackerURL && (
              <td style={{ textAlign: 'center' }}>
                <a
                  href={order.deliveryNetworkJobTrackerURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tracking
                </a>
              </td>
            )}
            {order.deliveryNetworkDashboardURL && (
              <td style={{ textAlign: 'center' }}>
                <a
                  href={order.deliveryNetworkDashboardURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dashboard
                </a>
              </td>
            )}
          </tr>
        </tbody>
      </HTMLTable>
    </Card>
  )
}

export default DeliveryNetwork

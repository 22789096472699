import React from 'react'
import { Button } from '@blueprintjs/core'
import { Formik } from 'formik'
import DeliveryZoneSelect from './Shared/DeliveryZoneSelect'
import { deliveryZoneType } from '../../utils/constants'
import { Fragment } from 'react'
import RadiusAroundOutletFormGroup from './Shared/RadiusAroundOutletFormGroup'
import CoordinatePolygonFormGroup from './Shared/CoordinatePolygonFormGroup'
import WayboxFormGroup from './Shared/WayboxFormGroup'
import StuartFormGroup from './Shared/StuartFormGroup'
import { numberToPence } from '../../utils/helpers'
import Mutation from 'react-apollo/Mutation'
import ADD_OUTLET_DELIVERY_ZONE from './mutations/addOutletDeliveryZone.mutation'
import defaultErrorHandler from '../../utils/defaultErrorHandler'
import { successToast } from '../../utils/toast'
import { toggleModal } from '../../utils/cacheHelpers'
import GET_DELIVERY_ZONES from '../Outlet/Fulfilment/queries/getDeliveryZones.query'
import GET_AVAILABLE_DELIVERY_ZONES_FOR_OUTLET from './queries/getAvailableDeliveryZonesForOutlet.query'
import UberDirectFormGroup from './Shared/UberDirectFormGroup'
import { outletDeliveryValidation } from './validation/outletDeliveryValidation'

const AddOutletDeliveryZoneForm = ({ outletId }) => {
  return (
    <Mutation
      mutation={ADD_OUTLET_DELIVERY_ZONE}
      onError={defaultErrorHandler}
      onCompleted={() => {
        successToast('Delivery Zone successfully added')
        toggleModal({
          outletDeliveryZoneModal: false,
        })
      }}
      refetchQueries={[
        {
          query: GET_DELIVERY_ZONES,
          variables: { outletId },
        },
        {
          query: GET_AVAILABLE_DELIVERY_ZONES_FOR_OUTLET,
          variables: { outletId },
        },
      ]}
    >
      {addOutletDeliveryZone => (
        <Formik
          initialValues={{
            outletId,
            deliveryZone: null,
            cost: 0,
            radiusMiles: '',
            apiClientId: '',
            apiSecret: '',
            subsidiseDelivery: false,
            outletDeliveryZoneType: '',
            fixedSubsidy: '',
            pickupNotes: '',
            uberDirectUndeliverableAction: '',
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={outletDeliveryValidation}
          onSubmit={({
            outletId,
            deliveryZone,
            cost,
            radiusMiles,
            apiClientId,
            apiSecret,
            fixedSubsidy,
            subsidiseDelivery,
            pickupNotes,
            uberDirectUndeliverableAction,
          }) => {
            const deliveryCostNum = numberToPence(cost)
            const deliveryZoneId = deliveryZone.id

            if (
              deliveryZone.deliveryZoneType === deliveryZoneType.UBER_DIRECT
            ) {
              return addOutletDeliveryZone({
                variables: {
                  outletId,
                  deliveryZoneId,
                  outletDeliveryZoneType: 'UBER_DIRECT',
                  cost: deliveryCostNum,
                  radiusMiles: parseFloat(radiusMiles),
                  ...(subsidiseDelivery && {
                    fixedSubsidy: numberToPence(fixedSubsidy),
                  }),
                  ...(pickupNotes && { pickupNotes }),
                  uberDirectUndeliverableAction,
                },
              })
            }

            // create stuart odz
            if (deliveryZone.deliveryZoneType === deliveryZoneType.STUART) {
              const isOutletStuartAccountRequired =
                deliveryZone.deliveryNetworkType === 'MAPPING'
              return addOutletDeliveryZone({
                variables: {
                  outletId,
                  deliveryZoneId,
                  outletDeliveryZoneType: 'STUART',
                  cost: deliveryCostNum,
                  ...(isOutletStuartAccountRequired &&
                    apiClientId && { apiClientId }),
                  ...(isOutletStuartAccountRequired &&
                    apiSecret && { apiSecret }),
                  radiusMiles: parseFloat(radiusMiles),
                  ...(subsidiseDelivery && {
                    fixedSubsidy: numberToPence(fixedSubsidy),
                  }),
                  ...(pickupNotes && { pickupNotes }),
                },
              })
            }

            // create waybox odz
            if (deliveryZone.deliveryZoneType === deliveryZoneType.WAYBOX) {
              return addOutletDeliveryZone({
                variables: {
                  outletId,
                  deliveryZoneId,
                  outletDeliveryZoneType: 'WAYBOX',
                  radiusMiles: parseFloat(radiusMiles),
                  cost: deliveryCostNum,
                },
              })
            }

            // create radius-based odz
            if (
              deliveryZone.deliveryZoneType ===
              deliveryZoneType.RADIUS_AROUND_OUTLET
            ) {
              return addOutletDeliveryZone({
                variables: {
                  outletId,
                  deliveryZoneId,
                  outletDeliveryZoneType: 'RADIUS_AROUND_OUTLET',
                  cost: deliveryCostNum,
                  radiusMiles: parseFloat(radiusMiles),
                },
              })
            }

            // create polygon based odz
            return addOutletDeliveryZone({
              variables: {
                outletId,
                deliveryZoneId,
                outletDeliveryZoneType: 'COORDINATE_POLYGON',
                cost: deliveryCostNum,
              },
            })
          }}
        >
          {formikProps => (
            <div className="bp3-drawer-form">
              <div className="bp3-drawer-content">
                <DeliveryZoneSelect {...formikProps} />
                {formikProps.values.deliveryZone && (
                  <Fragment>
                    {formikProps.values.deliveryZone.deliveryZoneType ===
                      deliveryZoneType.RADIUS_AROUND_OUTLET && (
                      <RadiusAroundOutletFormGroup {...formikProps} />
                    )}
                    {formikProps.values.deliveryZone.deliveryZoneType ===
                      deliveryZoneType.COORDINATE_POLYGON && (
                      <CoordinatePolygonFormGroup {...formikProps} />
                    )}
                    {formikProps.values.deliveryZone.deliveryZoneType ===
                      deliveryZoneType.WAYBOX && (
                      <WayboxFormGroup {...formikProps} />
                    )}
                    {formikProps.values.deliveryZone.deliveryZoneType ===
                      deliveryZoneType.STUART && (
                      <StuartFormGroup {...formikProps} />
                    )}
                    {formikProps.values.deliveryZone.deliveryZoneType ===
                      deliveryZoneType.UBER_DIRECT && (
                      <UberDirectFormGroup {...formikProps} />
                    )}
                  </Fragment>
                )}
              </div>

              <div className="bp3-drawer-footer-actions">
                <Button
                  type="submit"
                  text="Save"
                  onClick={formikProps.handleSubmit}
                  disabled={!formikProps.values.deliveryZone}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Mutation>
  )
}

export default AddOutletDeliveryZoneForm

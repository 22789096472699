import React from 'react'
import { NonIdealState, Button } from '@blueprintjs/core'
import { toggleModal } from '@utils/cacheHelpers'

const NoParentMenus = () => (
  <NonIdealState
    icon="clipboard"
    title="No Menus"
    description="Start building a menu for this business."
    action={
      <Button
        text="Add Menu"
        icon="plus"
        onClick={() =>
          toggleModal({
            parentMenuModal: true,
          })
        }
      />
    }
    className="bp3-card-non-ideal-state"
  />
)

export default NoParentMenus

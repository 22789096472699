import gql from 'graphql-tag'

const GET_MARKETPLACES = gql`
  query getPartnerMarketplaces(
    $id: ID
    $marketplaceIds: [ID]
    $name: String
    $partnerIds: [ID]
    $archived: Boolean
    $allowMarketing: Boolean
    $pinpointApplicationId: String
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $name_contains: String
    $orderBy: RegionalMarketplaceOrderByInput
    $sesMessagingRequired: Boolean
  ) {
    getMarketplaces(
      id: $id
      marketplaceIds: $marketplaceIds
      name: $name
      partnerIds: $partnerIds
      archived: $archived
      allowMarketing: $allowMarketing
      pinpointApplicationId: $pinpointApplicationId
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
      name_contains: $name_contains
      orderBy: $orderBy
      sesMessagingRequired: $sesMessagingRequired
    ) {
      count
      totalCount
      regions {
        id
        name
        companyLegalName
        key
        partner {
          id
          name
        }
        outlets {
          id
        }
        faviconImage
        orderMode
        sesMessaging
        messages {
          id
          readAt
        }
      }
    }
  }
`

export default GET_MARKETPLACES

import React, { Fragment, useEffect, useState } from 'react'
import {
  HTMLTable,
  Tag,
  Card,
  Icon,
  Tooltip,
  Popover,
  Classes,
  Position,
  PopoverInteractionKind,
  H5,
  NonIdealState,
  ButtonGroup,
  ControlGroup,
  Intent,
  Button,
} from '@blueprintjs/core'
import { Query, Mutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import { get, union, without } from 'lodash'
import {
  DelimitedArrayParam,
  StringParam,
  useQueryParams,
} from 'use-query-params'

import TableHeader from './tableHead'
import GET_RESTAURANTS from './queries/getRestaurants.query'
import DELETE_RESTAURANT from './mutations/deleteRestaurant.mutation'
import OPEN_MODAL from './openModal.clientQuery'

import defaultErrorHandler from '@utils/defaultErrorHandler'

import { successToast } from '@utils/toast'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import { Pager, Search } from '@components/Toolbar'

import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import RestaurantModal from '@components/Restaurant/Restaurant.modal/Restaurant.modal'

import StarsRating from '@components/Stars/Stars'
import { averageRatings } from '@utils/averageRatings'

import { formatAddressObj } from '../../../utils/helpers'

import FilterRow from '@components/FilterRow/FilterRow'
import { PartnerFilter } from '@components/Toolbar'
import { CuisineFilter } from '@components/Toolbar/CuisineFilter'
import Dropdown from '@components/Toolbar/RadioFilter'
import EmojiImageLabel from '@components/EmojiImageLabel'
import { useSearchQueryParam } from '../../Toolbar/Search/useSearchQueryParam'

import { isAtLeastPartner, isMenuEditor } from '@stores/userStore'

const DEFAULT_RECORDS = 50
const DEFAULT_SORT = 'name_ASC'
const DEFAULT_PAGINATION_STATE = {
  total: 0,
  skip: 0,
  first: DEFAULT_RECORDS,
  last: null,
  defaultNmbRecords: DEFAULT_RECORDS,
  outcomeLength: null,
  navigationDisabled: false,
}

const toggleModal = (client, value) =>
  client.writeData({
    data: {
      restaurantModal: value,
    },
  })

const Restaurants = () => {
  const userIsMenuEditor = isMenuEditor()

  const [
    {
      partnerFilter = [],
      tagsFilter = [],
      vatFilter,
      activeFilter,
      promotedFilter,
      orderBy = null,
    },
    setQueryParams,
  ] = useQueryParams({
    partnerFilter: DelimitedArrayParam,
    tagsFilter: DelimitedArrayParam,
    vatFilter: StringParam,
    activeFilter: StringParam,
    promotedFilter: StringParam,
    orderBy: StringParam,
  })

  const [state, setState] = useState(DEFAULT_PAGINATION_STATE)

  const { searchValue: restaurantSearch, resetSearch } = useSearchQueryParam()

  useEffect(() => {
    if (restaurantSearch) {
      setState(prevState => ({
        ...prevState,
        ...DEFAULT_PAGINATION_STATE,
      }))
    }
  }, [restaurantSearch])

  const setTotalCount = (totalCount, returnCount) => {
    if (state.total !== totalCount) {
      setState(prevState => ({ ...prevState, total: totalCount }))
    }
    if (state.outcomeLength !== returnCount) {
      setState(prevState => ({ ...prevState, outcomeLength: returnCount }))
    }
  }

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < state.defaultNmbRecords ? currentCursor : skip
  }

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (state.skip + state.first < state.total) {
      setState(prevState => ({
        ...prevState,
        skip: limitNext(prevState.skip, prevState.first, limit),
        first: prevState.defaultNmbRecords,
        last: null,
      }))
    }
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const goToPrevious = e => {
    e.preventDefault()
    setState(prevState => ({
      ...prevState,
      skip: limitPrevious(prevState.skip, prevState.first),
      first: prevState.defaultNmbRecords,
      last: null,
    }))
  }

  const goToPage = value => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setState(prevState => ({
      ...prevState,
      skip: numberToSkip,
      first: DEFAULT_RECORDS,
      last: null,
    }))
  }

  const handlePartnerFilter = e => {
    const { id, checked } = e.currentTarget
    setQueryParams({
      partnerFilter: checked
        ? union(partnerFilter, [id])
        : without(partnerFilter, id),
    })
  }

  const handleTagsFilter = e => {
    const { id, checked } = e.currentTarget
    setQueryParams({
      tagsFilter: checked ? union(tagsFilter, [id]) : without(tagsFilter, id),
    })
  }

  const handleVatFilter = ({ id }) => {
    setQueryParams({
      vatFilter: vatFilter === id ? undefined : id,
    })
  }

  const handleActiveFilter = ({ id }) => {
    setQueryParams({
      activeFilter: activeFilter === id ? undefined : id,
    })
  }

  const handlePromotedFilter = ({ id }) => {
    setQueryParams({
      promotedFilter: promotedFilter === id ? undefined : id,
    })
  }

  const resetFilters = () => {
    setQueryParams({
      partnerFilter: undefined,
      tagsFilter: undefined,
      vatFilter: undefined,
      activeFilter: undefined,
      promotedFilter: undefined,
      orderBy: undefined,
    })
    resetSearch()
  }

  const setOrderBySelected = (key, order) => {
    setQueryParams({
      orderBy: order === 'NONE' ? undefined : `${key}_${order}`,
    })
  }

  const renderFilterBar = () => (
    <FilterRow>
      <ButtonGroup>
        <ControlGroup>
          <Search autoFocus placeholder="Name, contact..." />

          <PartnerFilter
            icon="filter"
            partnerFilter={partnerFilter}
            onChange={handlePartnerFilter}
          />

          {!userIsMenuEditor && (
            <Dropdown
              disabled={state.total === null}
              selected={vatFilter}
              setSelected={handleVatFilter}
              placeholder="VAT"
              items={[
                { name: 'All', id: null },
                { name: 'VAT Registered', id: 'true' },
                { name: 'Not VAT Registered', id: 'false' },
              ]}
            />
          )}

          <Dropdown
            disabled={state.total === null}
            selected={activeFilter}
            setSelected={handleActiveFilter}
            placeholder="Active"
            items={[
              { name: 'All', id: null },
              { name: 'Active', id: 'true' },
              { name: 'Not Active', id: 'false' },
            ]}
          />

          <Dropdown
            disabled={state.total === null}
            selected={promotedFilter}
            setSelected={handlePromotedFilter}
            placeholder="Promoted"
            items={[
              { name: 'All', id: null },
              { name: 'Promoted', id: 'true' },
              { name: 'Not Promoted', id: 'false' },
            ]}
          />
          <CuisineFilter
            tagsFilter={tagsFilter}
            onChange={handleTagsFilter}
            disabled={state.total === null}
          />
          <Button
            icon="filter-remove"
            onClick={resetFilters}
            disabled={
              partnerFilter.length === 0 &&
              tagsFilter.length === 0 &&
              !vatFilter &&
              !activeFilter &&
              !promotedFilter
            }
          />
        </ControlGroup>
      </ButtonGroup>

      <Pager
        goToPrevious={goToPrevious}
        goToNext={goToNext}
        goToPage={goToPage}
        defaultNmbRecords={DEFAULT_RECORDS}
        skip={state.skip}
        total={state.total}
        outcomeLength={state.outcomeLength}
        totalCount={state.total}
        dataName={`Business${state.total !== 1 ? 'es' : ''}`}
      />
    </FilterRow>
  )

  return (
    <Query query={OPEN_MODAL}>
      {({ data: { restaurantModal }, client }) => (
        <Fragment>
          <div className="bp3-table-frame">
            {renderFilterBar()}
            <DebouncedQuery
              query={GET_RESTAURANTS}
              variables={{
                orderBy: orderBy || DEFAULT_SORT,
                search: restaurantSearch.length > 2 ? restaurantSearch : '',
                partnerIds: partnerFilter,
                cuisineIds: tagsFilter,
                isVATregistered: vatFilter ? vatFilter === 'true' : undefined,
                isActive: activeFilter ? activeFilter === 'true' : undefined,
                statusOnboarding: null,
                isPromoted: promotedFilter
                  ? promotedFilter === 'true'
                  : undefined,
                ...state,
              }}
              loaderTitle="Loading Businesses"
              ErrorComponent={
                <NonIdealState
                  icon="th-list"
                  title="Loading Error"
                  description="Unable to load business data, please retry."
                />
              }
            >
              {data => {
                if (data && data.getRestaurants) {
                  setTotalCount(
                    data.getRestaurants.totalCount,
                    data.getRestaurants.count
                  )
                }

                return !data ||
                  !data.getRestaurants ||
                  !data.getRestaurants.restaurants.length ? (
                  <NonIdealState
                    icon="th-list"
                    title="No Businesses Found"
                    description="There are no businesses to display."
                    action={
                      <Button onClick={resetFilters} minimal intent="primary">
                        Clear Filters
                      </Button>
                    }
                  />
                ) : (
                  <div className="bp3-table-container bp3-scrollable">
                    <Card className={'bp3-nopad'}>
                      <HTMLTable bordered={false} interactive={true}>
                        <TableHeader
                          setSelected={setOrderBySelected}
                          selected={orderBy}
                        />
                        <tbody>
                          {data &&
                            data.getRestaurants &&
                            data.getRestaurants.restaurants.map(restaurant => {
                              // aggregate reviews for each restaurant
                              const reviews = get(
                                restaurant,
                                'outlets',
                                []
                              ).reduce((acc, outlet) => {
                                return acc.concat(outlet.reviews)
                              }, [])
                              const allowReviews = get(
                                restaurant,
                                'outlets[0].marketplace.allowReviews',
                                false
                              )

                              const fee = get(
                                restaurant,
                                'outlets[0].marketplace.partnerFee',
                                0
                              )

                              const terminals = get(
                                restaurant,
                                'outlets',
                                []
                              ).reduce((acc, outlet) => {
                                return acc.concat(outlet.terminals)
                              }, [])

                              const {
                                tableDeal,
                                collectionDeal,
                                deliveryDeal,
                                allowAddOnItems,
                              } = restaurant

                              const getPromotedFor =
                                promotedForFulfilmentMethods =>
                                  promotedForFulfilmentMethods.length === 3
                                    ? 'Special offer flags active for all fulfilments'
                                    : `Special offer flags active for ${promotedForFulfilmentMethods.join(
                                        ' and '
                                      )}`

                              const preferEmoji =
                                get(
                                  restaurant,
                                  'outlets[0].marketplace.categoryEmojiOrImage',
                                  'EMOJI'
                                ) === 'EMOJI'

                              return (
                                <tr key={restaurant.id}>
                                  <td>
                                    <Tooltip
                                      content={
                                        restaurant.active ? (
                                          <strong>Active</strong>
                                        ) : (
                                          <Fragment>
                                            <strong>
                                              Deactivated Business
                                            </strong>{' '}
                                            - <em>Not visible to customers</em>
                                          </Fragment>
                                        )
                                      }
                                    >
                                      <Icon
                                        icon="small-tick"
                                        color={
                                          restaurant.active
                                            ? '#5BB70D'
                                            : '#CDD6DD'
                                        }
                                      />
                                    </Tooltip>
                                    &nbsp;
                                    <Link to={`/business/${restaurant.id}`}>
                                      {restaurant.name}
                                    </Link>
                                    &nbsp;
                                    {restaurant.promoted && (
                                      <Tooltip
                                        content={
                                          'This business is promoted and will appear at the top of the search listings.'
                                        }
                                      >
                                        <Icon
                                          icon={'endorsed'}
                                          intent={Intent.SUCCESS}
                                        />
                                      </Tooltip>
                                    )}
                                  </td>
                                  <td>
                                    <Popover
                                      interactionKind={
                                        PopoverInteractionKind.HOVER
                                      }
                                      position={Position.RIGHT_TOP}
                                      popoverClassName={
                                        Classes.POPOVER_CONTENT_SIZING
                                      }
                                      content={
                                        <Fragment>
                                          <H5>{restaurant.contactName}</H5>
                                          <p>
                                            {restaurant.contactPhone}
                                            <br />
                                            {restaurant.contactEmail && (
                                              <a
                                                href={`mailto:${restaurant.contactEmail}`}
                                              >
                                                {restaurant.contactEmail}
                                              </a>
                                            )}
                                          </p>
                                          <p>
                                            {formatAddressObj(
                                              restaurant.contactAddress
                                            )}
                                          </p>
                                        </Fragment>
                                      }
                                      className={Classes.TOOLTIP_INDICATOR}
                                    >
                                      {restaurant.contactName}
                                    </Popover>
                                  </td>
                                  <td>
                                    {restaurant.partner &&
                                    restaurant.partner.id &&
                                    restaurant.partner.name ? (
                                      isAtLeastPartner() ? (
                                        <Link
                                          to={`/partners/${restaurant.partner.id}`}
                                        >
                                          {restaurant.partner.name}
                                        </Link>
                                      ) : (
                                        restaurant.partner.name
                                      )
                                    ) : (
                                      <em className="bp3-text-muted">
                                        Unknown
                                      </em>
                                    )}
                                  </td>
                                  <td>
                                    <div style={{ display: 'flex' }}>
                                      {restaurant.cuisines.map(cuisine => (
                                        <Tag
                                          minimal={true}
                                          key={`${cuisine.name}${restaurant.id}`}
                                          style={{ marginRight: 5 }}
                                        >
                                          <EmojiImageLabel
                                            label={cuisine.name}
                                            emoji={cuisine.emoji}
                                            imageSrc={cuisine.imageSrc}
                                            preferEmoji={preferEmoji}
                                          />
                                        </Tag>
                                      ))}
                                    </div>
                                  </td>
                                  {!userIsMenuEditor && (
                                    <td>
                                      {restaurant.partnerFee ? (
                                        <span className={'bp3-text-muted '}>
                                          {restaurant.partnerFee} %
                                        </span>
                                      ) : (
                                        <span>{fee} %</span>
                                      )}
                                    </td>
                                  )}
                                  {!userIsMenuEditor && (
                                    <td>
                                      <Link
                                        to={`/business/${restaurant.id}/outlets`}
                                      >
                                        {restaurant.outlets.length}
                                      </Link>
                                    </td>
                                  )}
                                  {!userIsMenuEditor && (
                                    <td>{terminals.length}</td>
                                  )}
                                  {!userIsMenuEditor && (
                                    <td>
                                      <Link
                                        to={`/business/${restaurant.id}/discounts`}
                                      >
                                        {restaurant.discounts.length}
                                      </Link>
                                      {'  '}
                                      {allowAddOnItems && (
                                        <Tag minimal={true} intent="primary">
                                          ADD ON
                                        </Tag>
                                      )}
                                      {(tableDeal ||
                                        collectionDeal ||
                                        deliveryDeal) && (
                                        <Tooltip
                                          content={getPromotedFor(
                                            [
                                              tableDeal && 'table service',
                                              collectionDeal && 'collection',
                                              deliveryDeal && 'delivery',
                                            ].filter(Boolean)
                                          )}
                                        >
                                          <Tag minimal intent="success">
                                            SPECIAL
                                          </Tag>
                                        </Tooltip>
                                      )}
                                    </td>
                                  )}
                                  {!userIsMenuEditor && (
                                    <td>
                                      {allowReviews ? (
                                        reviews.length > 0 ? (
                                          <StarsRating
                                            starsNumber={averageRatings(
                                              reviews
                                            )}
                                          />
                                        ) : (
                                          'No reviews'
                                        )
                                      ) : (
                                        <Icon
                                          icon="small-tick"
                                          color={'#CDD6DD'}
                                        />
                                      )}
                                    </td>
                                  )}
                                  {!userIsMenuEditor && (
                                    <td>
                                      <Icon
                                        icon="small-tick"
                                        color={
                                          restaurant.isVATregistered
                                            ? '#5BB70D'
                                            : '#CDD6DD'
                                        }
                                      />
                                    </td>
                                  )}
                                  {!userIsMenuEditor && (
                                    <td className="bp3-action-cell">
                                      <Mutation
                                        mutation={DELETE_RESTAURANT}
                                        onError={defaultErrorHandler}
                                        onCompleted={() =>
                                          successToast('Business deleted.')
                                        }
                                        refetchQueries={[
                                          {
                                            query: GET_RESTAURANTS,
                                          },
                                        ]}
                                      >
                                        {deleteRestaurant => (
                                          <ConfirmationPopover
                                            remove={async () => {
                                              await deleteRestaurant({
                                                variables: {
                                                  id: restaurant.id,
                                                },
                                              })
                                            }}
                                            confirmationText={`Are you sure you want to delete "${restaurant.name}"?`}
                                          >
                                            <Icon icon="trash" />
                                          </ConfirmationPopover>
                                        )}
                                      </Mutation>
                                    </td>
                                  )}
                                </tr>
                              )
                            })}
                        </tbody>
                      </HTMLTable>
                    </Card>
                  </div>
                )
              }}
            </DebouncedQuery>
          </div>
          <RestaurantModal
            modalOpen={restaurantModal}
            modalClose={() => toggleModal(client, false)}
          />
        </Fragment>
      )}
    </Query>
  )
}

export default Restaurants

import { Button, ButtonGroup, HTMLTable } from '@blueprintjs/core'
import EmojiImageLabel from '@components/EmojiImageLabel'
import TypeaheadMulti from '@components/Typeahead/TypeaheadMulti'
import React from 'react'

export const Categories = ({
  allCategories,
  categories,
  setFieldValue,
  preferEmoji,
}) => {
  return (
    <HTMLTable className="bp3-cell-nopad">
      {categories.map((category, index) => (
        <tr key={category.id}>
          <td>
            <EmojiImageLabel
              label={category.name}
              emoji={category.emoji}
              imageSrc={category.imageSrc}
              preferEmoji={preferEmoji}
            />
          </td>
          <td style={{ textAlign: 'right' }}>
            <ButtonGroup>
              <Button
                minimal
                icon="chevron-up"
                disabled={index === 0}
                onClick={() => {
                  const cloned = structuredClone(categories)
                  ;[cloned[index], cloned[index - 1]] = [
                    cloned[index - 1],
                    cloned[index],
                  ]
                  setFieldValue('categories', cloned)
                }}
              ></Button>
              <Button
                minimal
                icon="chevron-down"
                disabled={index === categories.length - 1}
                onClick={() => {
                  const cloned = structuredClone(categories)
                  ;[cloned[index], cloned[index + 1]] = [
                    cloned[index + 1],
                    cloned[index],
                  ]
                  setFieldValue('categories', cloned)
                }}
              ></Button>
              <Button
                minimal
                icon="trash"
                onClick={() => {
                  const categoriesWithoutItem = categories.filter(
                    x => x.id !== category.id
                  )
                  setFieldValue('categories', categoriesWithoutItem)
                }}
              ></Button>
            </ButtonGroup>
          </td>
        </tr>
      ))}
      <tr>
        <td colSpan="2">
          <TypeaheadMulti
            fill={true}
            unSelectedIcon={null}
            placeholder="Add Category"
            setSelected={categoryIds => {
              const newCategories = categoryIds.map(categoryId =>
                allCategories.find(x => x.id === categoryId)
              )
              const categoriesWithNew = [...categories, ...newCategories]
              setFieldValue('categories', categoriesWithNew)
            }}
            items={allCategories.filter(
              x => !categories.find(y => y.id === x.id)
            )}
            selected={[]}
            showEmojis={preferEmoji}
            preferEmoji={preferEmoji}
          />
        </td>
      </tr>
    </HTMLTable>
  )
}

import React from 'react'
import cx from 'classnames'

const AgeRestriction = ({ classes, order: { customerOrderNotes } }) => (
  <section className={cx(classes.section, classes.center)}>
    <h2 className={classes.title}>Age Verification Required</h2>
  </section>
)

export default AgeRestriction

import React from 'react'
import NumberFormat from 'react-number-format'
import { func, string } from 'prop-types'
import cx from 'classnames'
import moment from 'moment'
import { errorToast } from '@utils/toast'

const TimeInput = ({ value, handleChange, intent, minimumMin = null }) => (
  <NumberFormat
    value={value}
    format="##:##"
    type="text"
    onValueChange={values => handleChange(values.formattedValue)}
    className={cx('bp3-input bp3-time', {
      ['bp3-intent-danger']: intent === 'danger',
    })}
    isAllowed={values => {
      const { formattedValue } = values

      return formattedValue === '' || moment(formattedValue, 'HH:mm').isValid()
    }}
    onBlur={e => {
      const hour = e.target.value.substring(0, 2) // two digits before colon
      let minutes = e.target.value.substring(3, 5) // two digits after colon
      if (
        minimumMin &&
        parseInt(hour) === 0 &&
        parseInt(minutes) < minimumMin
      ) {
        errorToast(`${minimumMin} minutes is the shortest time allowed`)
        minutes = minimumMin
      }

      if (!hour) return

      handleChange(moment().hour(hour).minute(minutes).format('HH:mm'))
    }}
  />
)

TimeInput.propTypes = {
  value: string,
  handleChange: func,
  intent: string,
}

export default TimeInput

import { useCallback } from 'react'
import { DelimitedArrayParam, useQueryParam } from 'use-query-params'

export const useRoleAwareBusinessFilterQueryParams = () => {
  const [marketplaceIds = [], onChangeMarketplaceIds] = useQueryParam(
    'marketplaceIds',
    DelimitedArrayParam
  )
  const [marketplaceOutletIds = [], onChangeMarketplaceOutletIds] =
    useQueryParam('marketplaceOutletIds', DelimitedArrayParam)
  const resetMarketplaceFilters = useCallback(() => {
    onChangeMarketplaceIds(undefined, 'replaceIn')
    onChangeMarketplaceOutletIds(undefined, 'replaceIn')
  }, [onChangeMarketplaceIds, onChangeMarketplaceOutletIds])
  return {
    marketplaceIds,
    marketplaceOutletIds,
    onChangeMarketplaceIds,
    onChangeMarketplaceOutletIds,
    resetMarketplaceFilters,
  }
}

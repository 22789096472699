import React from 'react'
import { FormGroup, InputGroup, Button } from '@blueprintjs/core'
import { func, object, bool } from 'prop-types'

const LoginForm = ({
  handleChange,
  values,
  isSubmitting,
  classes,
  mutation,
}) => (
  <form
    onSubmit={e => {
      e.preventDefault()
      mutation({
        variables: { ...values },
      })
    }}
  >
    <FormGroup>
      <InputGroup
        placeholder={'Email'}
        value={values.email}
        onChange={handleChange}
        type="email"
        name="email"
        large="true"
        data-test-id="sign-in-email-input"
      />
    </FormGroup>
    <FormGroup>
      <InputGroup
        placeholder={'Password'}
        value={values.password}
        onChange={handleChange}
        type="password"
        name="password"
        large="true"
        data-test-id="sign-in-password-input"
      />
    </FormGroup>
    <div className={classes.buttonContainer}>
      <FormGroup>
        <Button
          type="submit"
          disabled={isSubmitting}
          text="Login"
          data-test-id="sign-in-submit-button"
        />
      </FormGroup>
    </div>
  </form>
)

LoginForm.propTypes = {
  handleChange: func,
  values: object,
  isSubmitting: bool,
  classes: object,
  mutation: func,
}

export default LoginForm

export const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    padding: '0px',
    marginTop: 20,
    flexDirection: 'row',
  },
  deliveryNotesContainer: {
    width: '50%',
    flexGrow: 1,
  },
  orderNotesContainer: {
    width: '50%',
    flexGrow: 1,
  },
  note: {
    color: theme.colors.orderHighlight,
  },
  noteTitle: {
    fontWeight: 'bold',
  },
})

import {
  Button,
  Card,
  Checkbox,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  TextArea,
} from '@blueprintjs/core'
import React, { Fragment } from 'react'

export const ReplyMessageForm = ({
  errors,
  values,
  handleChange,
  handleSubmit,
  customersList,
  emailSent,
  isInbox,
}) => {
  //   last message might be from management so double check if email is not from redbox - receiver is always the client
  const receivers = isInbox
    ? values.from.includes('@messaging')
      ? values.to
      : [values.from]
    : values.to.filter(toEmail => toEmail.includes('@messaging')).length
    ? [values.from]
    : values.to

  return (
    <Fragment>
      {values !== null && (
        <Card className="bp3-layout-col">
          <form onSubmit={handleSubmit}>
            <FormGroup
              label="Receivers"
              labelFor="receivers"
              helperText={errors.receivers ? errors.receivers : ''}
              intent={errors.receivers ? Intent.DANGER : Intent.NONE}
            >
              <InputGroup
                name="receivers"
                id="receivers"
                type="text"
                disabled={true}
                value={
                  receivers &&
                  receivers.length &&
                  receivers.map(email => `${email} `)
                }
                hidden={true}
              />
              <div style={{ marginTop: '10px' }}>
                {receivers &&
                  receivers
                    .map(toEmail => {
                      return customersList.find(
                        customer => customer.email === toEmail
                      )
                        ? customersList.find(
                            customer => customer.email === toEmail
                          )
                        : { email: toEmail }
                    })
                    .map(cust => {
                      return cust.id ? (
                        <a
                          href={`/customer/${cust.id}/details`}
                          key={`msg-${cust.id}-link`}
                        >
                          {cust.email}
                        </a>
                      ) : (
                        <span> {cust.email} </span>
                      )
                    })}
              </div>
            </FormGroup>
            <FormGroup
              label="Subject"
              labelFor="subject"
              helperText={errors.subject ? errors.subject : ''}
              intent={errors.subject ? Intent.DANGER : Intent.NONE}
            >
              <InputGroup
                name="subject"
                id="subject"
                type="text"
                onChange={handleChange}
                intent={errors.subject ? Intent.DANGER : Intent.NONE}
                value={`RE: ${values.subject}`}
                disabled={true}
              />
            </FormGroup>
            <FormGroup
              label="Content"
              labelFor="content"
              helperText={errors.content ? errors.content : ''}
              intent={errors.content ? Intent.DANGER : Intent.NONE}
            >
              <TextArea
                name="content"
                id="content"
                type="text"
                onChange={handleChange}
                fill={true}
                value={values.content}
                style={{ height: 100 }}
              />
            </FormGroup>
            <FormGroup>
              <Checkbox
                defaultChecked={values.addMarketplaceTheme}
                onChange={handleChange}
                name="addMarketplaceTheme"
              >
                Add marketplace theme
              </Checkbox>
            </FormGroup>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                text="Submit"
                intent={Intent.NONE}
                type="submit"
                disabled={emailSent}
              />
            </div>
          </form>
        </Card>
      )}
    </Fragment>
  )
}

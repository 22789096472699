import gql from 'graphql-tag'

const EDIT_SEGMENT = gql`
  mutation editBusinessSegment(
    $id: String!
    $name: String
    $showName: Boolean!
    $description: String
    $position: Int
    $groups: [BusinessSegmentsGroupInput!]
    $availabilityTimes: [AvailabilityTimeInput!]
    $availabilityStartDate: DateTime
    $availabilityEndDate: DateTime
    $orderBy: SegmentOrderBy
    $showFeaturedFlags: Boolean!
    $showDiscountLabels: Boolean!
    $showOnApp: Boolean!
    $openStatusFilter: OpenStatusFilter
    $outletLimit: Int!
    $categories: [BusinessSegmentCategoryInput!]
  ) {
    editBusinessSegment(
      id: $id
      name: $name
      showName: $showName
      description: $description
      position: $position
      groups: $groups
      availabilityTimes: $availabilityTimes
      availabilityStartDate: $availabilityStartDate
      availabilityEndDate: $availabilityEndDate
      orderBy: $orderBy
      showFeaturedFlags: $showFeaturedFlags
      showDiscountLabels: $showDiscountLabels
      showOnApp: $showOnApp
      openStatusFilter: $openStatusFilter
      outletLimit: $outletLimit
      categories: $categories
    ) {
      message
    }
  }
`

export default EDIT_SEGMENT

import { Button, Spinner } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { successToast, warningToast } from '@utils/toast'
import React, { Fragment, useState } from 'react'
import { Query } from 'react-apollo'
import GET_IMAGES from './queries/get-images.query'
import FilterRow from '@components/FilterRow/FilterRow'
import { Pager } from '@components/Toolbar/Pager'
import queryString from 'query-string'

const ImagePicker = ({
  setImages,
  replace = false,
  menuItemId = null,
  optionItemId,
}) => {
  const DEFAULT_IMG_COUNT = 20

  const [imagePagination, setImagePagination] = useState({
    total: null,
    skip: 0,
    first: DEFAULT_IMG_COUNT,
    last: null,
    defaultNmbRecords: DEFAULT_IMG_COUNT,
  })

  const goToNext = e => {
    e.preventDefault()
    setImagePagination({
      ...imagePagination,
      skip:
        imagePagination.skip + imagePagination.first < imagePagination.total
          ? limitNext(imagePagination.skip, imagePagination.first)
          : imagePagination.skip,
    })
  }

  const limitNext = (currentCursor, amount) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return DEFAULT_IMG_COUNT < imagePagination.defaultNmbRecords
      ? currentCursor
      : skip
  }

  const goToPrevious = e => {
    e.preventDefault()
    setImagePagination({
      ...imagePagination,
      skip: limitPrevious(imagePagination.skip, imagePagination.first),
      first: DEFAULT_IMG_COUNT,
      last: null,
    })
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  return (
    <div>
      <Query
        query={GET_IMAGES}
        variables={{
          ...imagePagination,
          menuItemId,
          optionItemId,
        }}
        onCompleted={data => {
          setImagePagination({
            ...imagePagination,
            total: data.getImages.totalCount,
          })
        }}
        fetchPolicy="no-cache"
      >
        {({ data, refetch, loading }) => {
          if (!data || !data.getImages) {
            return <Fragment>No Images Found</Fragment>
          }

          const { getImages } = data

          if (getImages && getImages.totalCount && !imagePagination.total) {
            setImagePagination({
              ...imagePagination,
              total: getImages.totalCount,
            })
          }
          return (
            <Fragment>
              {!loading && getImages.totalCount ? (
                <Fragment>
                  <div className="image-grid">
                    {getImages.images &&
                      getImages.images.map(imageFromDb => (
                        <div
                          key={`wr${imageFromDb.src}`}
                          className="image-grid-item-wrapper"
                          style={{ flex: '0 10%' }}
                        >
                          <div
                            className="image-grid-image"
                            style={{
                              backgroundImage: `url(https://${process.env.ENVIRONMENT_DOMAIN}/${imageFromDb.src})`,
                            }}
                            onClick={_ => {
                              if (replace) {
                                setImages(imageFromDb.src)
                                refetch()
                              } else {
                                setImages(images => {
                                  if (
                                    images.find(
                                      img => img.src === imageFromDb.src
                                    )
                                  ) {
                                    warningToast(
                                      'This image is already present'
                                    )
                                    return images
                                  } else {
                                    successToast('Image added')
                                    return [
                                      ...images,
                                      {
                                        id: imageFromDb.id,
                                        src: imageFromDb.src,
                                        position: imageFromDb.position,
                                        caption: imageFromDb.caption,
                                      },
                                    ]
                                  }
                                })
                              }
                            }}
                          ></div>
                        </div>
                      ))}
                  </div>
                  <FilterRow>
                    <Pager
                      goToPrevious={goToPrevious}
                      goToNext={goToNext}
                      defaultNmbRecords={DEFAULT_IMG_COUNT}
                      skip={imagePagination.skip}
                      total={imagePagination.total}
                      totalCount={imagePagination.total}
                      dataName="Images"
                    />
                  </FilterRow>
                </Fragment>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <h2>
                    {loading ? <Spinner /> : 'There are no uploaded images'}
                  </h2>
                </div>
              )}
            </Fragment>
          )
        }}
      </Query>
    </div>
  )
}

export const ImagePickerDialog = ({
  setImages,
  replace = false,
  style = null,
  optionItemId,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { menuItemId } = queryString.parse(location.search)

  return (
    <Fragment>
      <Button onClick={_ => setModalOpen(true)} style={style} icon="media">
        Image Library
      </Button>
      <Dialog
        isOpen={modalOpen}
        onClose={_ => setModalOpen(false)}
        title={`Image Library`}
        style={{ width: '80%' }}
      >
        {modalOpen ? (
          <ImagePicker
            setImages={setImages}
            replace={replace}
            menuItemId={menuItemId}
            optionItemId={optionItemId}
          />
        ) : null}
      </Dialog>
    </Fragment>
  )
}

export default ImagePicker

import React, { useEffect, useState, useContext } from 'react'
import Query from '@components/Query/Query'
import { Card, HTMLTable, Icon, Button, NonIdealState } from '@blueprintjs/core'
import { Link } from 'react-router-dom'

import { matchType } from '@utils/types'
import GET_PAYOUT from './queries/getPayout.query'
import Currency from '@components/Currency/Currency'
import { sumBy, orderBy, get, startCase } from 'lodash'
import { MarketplaceFilter } from '@components/Toolbar'
import { toast } from '@utils/toast'
import { client } from '@services/client'
import { penceToPounds } from '@utils/helpers'
import FilterRow from '@components/FilterRow/FilterRow'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { canView } from '@stores/userStore'
import { DATE_FILTER_TYPES } from '@utils/datetime'
import { format } from 'date-fns'
import { useDateRangeQueryParams } from '@components/Toolbar/DateRangeFilter/useDateRangeQueryParams'
import DateRangeFilter from '@components/Toolbar/DateRangeFilter/DateRangeFilter'
import { useRoleAwareBusinessFilterQueryParams } from '../../Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'

const PageLayoutWrapper = ({ product, children }) => {
  const { configurePageLayout } = useContext(PageLayoutContext)

  const tabs = []

  if (canView('reports')) {
    tabs.push({ to: '/reports/payments', name: 'Payments' })
  }

  if (canView('billing')) {
    tabs.push({ to: '/reports/invoicing', name: 'Invoicing' })
  }

  useEffect(() => {
    configurePageLayout({
      product,
      tabs,
    })
  }, [configurePageLayout, product])

  return children
}

const Payout = ({ product }) => {
  const { marketplaceIds: marketplaceFilter } =
    useRoleAwareBusinessFilterQueryParams()
  const { shiftedStartOfRangeDateTime, shiftedEndOfRangeDateTime } =
    useDateRangeQueryParams()

  const [downloading, setDownloading] = useState(false)

  const downloadCSV = async e => {
    e.preventDefault()
    setDownloading(true)

    const csvHeader = [
      'restaurant',
      'outlet',
      'orderNetTotal',
      'orderGrossTotal',
      'totalOrders',
      'totalDiscountValue',
      'totalCustomerDeliveryValue',
      'totalRestaurantDeliveryValue',
      'totalNetworkDeliveryValue',
      'totalSubsidyDeliveryValue',
      'totalMarketplaceFee',
      'totalMerchantCharge',
      'totalMarketplaceCharge',
      'totalPaidCashValue',
      'totalRefunds',
      'totalRefundsValue',
      'totalPayout',
      'accountNumber',
      'sortCode',
    ]

    let csvData = [
      csvHeader
        .map(column => {
          return startCase(column)
        })
        .join(','),
    ]

    await client
      .query({
        query: GET_PAYOUT,
        variables: {
          afterDate: shiftedStartOfRangeDateTime,
          beforeDate: shiftedEndOfRangeDateTime,
          marketplaceIds: marketplaceFilter,
        },
      })
      .then(data => {
        orderBy(data.data.payout.outlets, 'totalOrdersValue', 'desc').map(
          report => {
            csvData.push(
              csvHeader
                .map(column => {
                  return {
                    restaurant: `"${report.outlet.restaurant.name}"`,
                    outlet: `"${report.outlet.name}"`,
                    totalOrders: report.totalOrders,
                    orderGrossTotal: penceToPounds(report.orderGrossTotal),
                    orderNetTotal: penceToPounds(report.orderNetTotal),
                    totalDiscountValue: penceToPounds(
                      report.totalDiscountValue
                    ),
                    totalCustomerDeliveryValue: penceToPounds(
                      report.totalCustomerDeliveryValue
                    ),
                    totalRestaurantDeliveryValue: penceToPounds(
                      report.totalDeliveryValue
                    ),
                    totalNetworkDeliveryValue: penceToPounds(
                      report.totalNetworkValue
                    ),
                    totalSubsidyDeliveryValue: penceToPounds(
                      report.totalSubsidyValue
                    ),
                    totalMarketplaceFee: penceToPounds(
                      report.totalMarketplaceFee
                    ),
                    totalMerchantCharge: penceToPounds(
                      report.totalMerchantCharge
                    ),
                    totalMarketplaceCharge: penceToPounds(
                      report.totalMarketplaceCharge
                    ),
                    totalPaidCashValue: penceToPounds(
                      report.totalPaidCashValue
                    ),
                    totalRefunds: report.totalRefunds,
                    totalRefundsValue: penceToPounds(report.totalRefundsValue),
                    totalPayout: penceToPounds(report.totalPayout),
                    accountNumber: report.outlet.bankAccount,
                    sortCode: report.outlet.bankSort,
                  }[column]
                })
                .join(',')
            )
          }
        )
      })

    const blob = new Blob([csvData.join('\n')], { type: 'octet/stream' })
    const url = window.URL.createObjectURL(blob)

    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = `payout-${format(
      shiftedStartOfRangeDateTime,
      'yyyyMMdd-HHmm'
    )}-to-${format(shiftedEndOfRangeDateTime, 'yyyyMMdd-HHmm')}.csv`
    a.click()

    window.URL.revokeObjectURL(url)

    setDownloading(false)

    toast({
      message: 'Download complete.',
      intent: 'success',
      icon: 'saved',
    })
  }

  return (
    <PageLayoutWrapper product={product}>
      <div className="bp3-table-frame">
        <FilterRow>
          <div>
            <MarketplaceFilter divider />
            <DateRangeFilter
              filterTypes={[
                DATE_FILTER_TYPES.DAY,
                DATE_FILTER_TYPES.WEEK,
                DATE_FILTER_TYPES.CUSTOM,
              ]}
              disabled={marketplaceFilter.length === 0}
            />
          </div>
          <Button
            loading={downloading}
            icon={'cloud-download'}
            style={{
              float: 'right',
            }}
            onClick={downloadCSV}
          >
            Download CSV
          </Button>
        </FilterRow>

        <Query
          query={GET_PAYOUT}
          variables={{
            afterDate: shiftedStartOfRangeDateTime,
            beforeDate: shiftedEndOfRangeDateTime,
            marketplaceIds: marketplaceFilter,
          }}
          loaderTitle="Loading Payout Reports"
        >
          {({ payout: { outlets } }) => {
            if (outlets && outlets.length > 0) {
              return (
                <div className="bp3-table-container bp3-scrollable">
                  <Card className={'bp3-nopad'}>
                    <HTMLTable bordered={false} interactive={true}>
                      <thead>
                        <tr>
                          <th colSpan={2} />
                          <th
                            colSpan={4}
                            style={{ borderLeft: ' 1px solid #DCDCDD' }}
                          >
                            Orders
                          </th>
                          <th
                            colSpan={4}
                            style={{ borderLeft: ' 1px solid #DCDCDD' }}
                          >
                            Delivery
                          </th>
                          <th
                            colSpan={3}
                            style={{ borderLeft: ' 1px solid #DCDCDD' }}
                          >
                            Gross Commission
                          </th>
                          <th
                            colSpan={4}
                            style={{ borderLeft: ' 1px solid #DCDCDD' }}
                          >
                            Business
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={2} />
                          <th style={{ borderLeft: ' 1px solid #DCDCDD' }}>
                            Total
                          </th>
                          <th>Gross</th>
                          <th>Net</th>
                          <th>Discounts</th>
                          <th style={{ borderLeft: ' 1px solid #DCDCDD' }}>
                            Customer
                          </th>
                          <th>Restaurant</th>
                          <th>Network</th>
                          <th>Subsidy</th>
                          <th style={{ borderLeft: ' 1px solid #DCDCDD' }}>
                            Marketplace
                          </th>
                          <th>Charge</th>
                          <th>Service</th>
                          <th style={{ borderLeft: ' 1px solid #DCDCDD' }}>
                            Cash
                          </th>
                          <th>Payout</th>
                          <th colSpan={2}>Refunds</th>
                        </tr>
                        <tr>
                          <th style={{ width: '200px' }}>Restaurant</th>
                          <th style={{ width: '200px' }}>Outlet</th>
                          <th
                            style={{
                              width: '50px',
                              borderLeft: ' 1px solid #DCDCDD',
                            }}
                          >
                            {sumBy(outlets, report => {
                              return report.totalOrders
                            })}
                          </th>
                          <th style={{ width: '100px' }}>
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.orderGrossTotal
                              })}
                            />
                          </th>
                          <th style={{ width: '100px' }}>
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.orderNetTotal
                              })}
                            />
                          </th>
                          <th style={{ width: '100px' }}>
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.totalDiscountValue
                              })}
                            />
                          </th>
                          <th
                            style={{
                              width: '100px',
                              borderLeft: ' 1px solid #DCDCDD',
                            }}
                          >
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.totalCustomerDeliveryValue
                              })}
                            />
                          </th>
                          <th style={{ width: '100px' }}>
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.totalDeliveryValue
                              })}
                            />
                          </th>
                          <th style={{ width: '100px' }}>
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.totalNetworkValue
                              })}
                            />
                          </th>
                          <th style={{ width: '100px' }}>
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.totalSubsidyValue
                              })}
                            />
                          </th>
                          <th
                            style={{
                              width: '100px',
                              borderLeft: ' 1px solid #DCDCDD',
                            }}
                          >
                            (
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.totalMarketplaceFee
                              })}
                            />
                            )
                          </th>
                          <th style={{ width: '100px' }}>
                            (
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.totalMerchantCharge
                              })}
                            />
                            )
                          </th>
                          <th style={{ width: '100px' }}>
                            (
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.totalMarketplaceCharge
                              })}
                            />
                            )
                          </th>
                          <th
                            style={{
                              width: '100px',
                              borderLeft: ' 1px solid #DCDCDD',
                            }}
                          >
                            (
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.totalPaidCashValue
                              })}
                            />
                            )
                          </th>
                          <th style={{ width: '100px' }}>
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.totalPayout
                              })}
                            />
                          </th>
                          <th style={{ width: '50px', color: 'red' }}>
                            {sumBy(outlets, report => {
                              return report.totalRefunds
                            })}
                          </th>
                          <th style={{ width: '100px', color: 'red' }}>
                            <Currency
                              amount={sumBy(outlets, report => {
                                return report.totalRefundsValue
                              })}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderBy(outlets, 'totalRevenue', 'desc').map(
                          report => (
                            <tr key={report.outlet.id}>
                              <td>
                                <Link
                                  className="bp3-text-overflow-ellipsis"
                                  to={`/business/${report.outlet.restaurant.id}`}
                                >
                                  {report.outlet.restaurant.name}
                                </Link>
                              </td>
                              <td>
                                <span className="bp3-text-overflow-ellipsis">
                                  <Icon
                                    icon="symbol-circle"
                                    color={
                                      report.outlet.isOnline
                                        ? '#5bb70d'
                                        : '#CDD6DD'
                                    }
                                  />
                                  <Link
                                    to={`/business/${report.outlet.restaurant.id}/outlets/${report.outlet.id}`}
                                  >
                                    {report.outlet.name}
                                  </Link>
                                </span>
                              </td>
                              <td
                                style={{
                                  width: '50px',
                                  borderLeft: ' 1px solid #DCDCDD',
                                }}
                              >
                                {get(report, 'totalOrders', 0)}
                              </td>
                              <td>
                                <Currency amount={report.orderGrossTotal} />
                              </td>
                              <td>
                                <Currency amount={report.orderNetTotal} />
                              </td>
                              <td>
                                <Currency amount={report.totalDiscountValue} />
                              </td>
                              <td style={{ borderLeft: ' 1px solid #DCDCDD' }}>
                                <Currency
                                  amount={report.totalCustomerDeliveryValue}
                                />
                              </td>
                              <td>
                                <Currency amount={report.totalDeliveryValue} />
                              </td>
                              <td>
                                <Currency amount={report.totalNetworkValue} />
                              </td>
                              <td>
                                <Currency amount={report.totalSubsidyValue} />
                              </td>
                              <td style={{ borderLeft: ' 1px solid #DCDCDD' }}>
                                (
                                <Currency amount={report.totalMarketplaceFee} />
                                )
                              </td>
                              <td>
                                (
                                <Currency amount={report.totalMerchantCharge} />
                                )
                              </td>
                              <td>
                                (
                                <Currency
                                  amount={report.totalMarketplaceCharge}
                                />
                                )
                              </td>
                              <td style={{ borderLeft: '1px solid #DCDCDD' }}>
                                (
                                <Currency amount={report.totalPaidCashValue} />)
                              </td>
                              <td>
                                <Currency amount={report.totalPayout} />
                              </td>
                              <td style={{ width: '50px', color: 'red' }}>
                                {get(report, 'totalRefunds', 0)}
                              </td>
                              <td style={{ color: 'red' }}>
                                <Currency amount={report.totalRefundsValue} />
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </HTMLTable>
                  </Card>
                </div>
              )
            } else {
              return marketplaceFilter.length === 0 ? (
                <NonIdealState
                  icon="property"
                  title="Select Marketplace"
                  description="Please select a marketplace to load this report."
                />
              ) : (
                <NonIdealState
                  icon="th-list"
                  title="No Sales Data"
                  description="There is no data for the selected filter."
                />
              )
            }
          }}
        </Query>
      </div>
    </PageLayoutWrapper>
  )
}

Payout.propTypes = {
  match: matchType,
}

export default Payout

import React, { Fragment, useState } from 'react'
import {
  Card,
  HTMLTable,
  NonIdealState,
  Button,
  Tag,
  Tooltip,
  Callout,
} from '@blueprintjs/core'
import { matchType } from '@utils/types'
import { Query as CacheQuery } from 'react-apollo'
import get from 'lodash/get'
import first from 'lodash/first'

import Query from '@components/Query/Query'
import GET_DELIVERY_ZONES from '@components/Outlet/Fulfilment/queries/getDeliveryZones.query'
import OPEN_MODAL from './queries/openModal.clientQuery'
import Currency from '@components/Currency/Currency'
import OutletDeliveryZoneModal from '@components/OutletDeliveryZoneModal/OutletDeliveryZoneModal'
import { toggleModal } from '@utils/cacheHelpers'
import GeoDeliveryZones from '@components/GeoJSON/GeoDeliveryZones'
import AddressNonIdealState from './AddressNonIdealState'
import { isOutletUser } from '@stores/userStore'

const WEBHOOK_STATUS_TO_INTENT_MAP = {
  NOT_APPLICABLE: 'none',
  SUCCESS: 'success',
  FAILURE: 'danger',
  ERROR: 'danger',
}

const tableHead = [
  { key: 'deliveryZone', content: 'Delivery Zone' },
  { key: 'fulfilment', content: 'Fulfilment' },
  { key: 'deliveryCost', content: 'Delivery Cost' },
  { key: 'radiusMiles', content: 'Delivery Radius (miles)' },
  { key: 'subsidy', content: 'Subsidy' },
  { key: 'webhookStatus', content: 'Webhook' },
]

const displayFixedSubsidy = deliveryNetwork =>
  deliveryNetwork &&
  ['STUART', 'WAYBOX'].includes(deliveryNetwork.deliveryProvider) &&
  deliveryNetwork.fixedSubsidy !== null ? (
    <Currency amount={deliveryNetwork.fixedSubsidy} />
  ) : (
    <Currency amount={0} className="bp3-text-muted" />
  )

const DeliveryZones = ({ match }) => {
  const userIsOutletUser = isOutletUser()

  const [activeZone, setActiveZone] = useState({})
  const clearActiveZone = () => setActiveZone({})

  return (
    <Query
      query={GET_DELIVERY_ZONES}
      variables={{ outletId: match.params.outlet }}
      loaderTitle={'Loading Delivery Zones'}
    >
      {({ getOutlets: { outlets } }) => {
        const outlet = first(outlets)
        const marketplaceId = get(outlet, 'marketplace.id')
        const outletDeliveryZoneCosts = get(
          first(outlets),
          'deliveryZoneCosts',
          []
        )

        return (
          <CacheQuery query={OPEN_MODAL}>
            {({ data: { outletDeliveryZoneModal } }) => (
              <Fragment>
                {!outletDeliveryZoneCosts.length ? (
                  <NonIdealState
                    icon="drive-time"
                    title="No Delivery Zones"
                    description={
                      !userIsOutletUser
                        ? 'Create delivery zones for this outlet.'
                        : 'A business or marketplace owner can create delivery zones for this outlet.'
                    }
                    action={
                      !userIsOutletUser && (
                        <Button
                          text="Add Delivery Zone"
                          icon="plus"
                          onClick={() =>
                            toggleModal({
                              outletDeliveryZoneModal: true,
                            })
                          }
                        />
                      )
                    }
                    className="bp3-card-non-ideal-state"
                  />
                ) : (
                  <Fragment>
                    {!outlet.active && (
                      <Callout
                        style={{ marginBottom: 24 }}
                        intent="danger"
                        icon="ban-circle"
                      >
                        This Outlet is Deactivated and will not allow orders.
                      </Callout>
                    )}
                    <Card className="bp3-nopad">
                      {!outlet.outletAddress || !outlet.outletAddress.geo ? (
                        <AddressNonIdealState
                          outletId={outlet.id}
                          description={
                            outlet.outletAddress
                              ? "We couldn't find the outlet address on the map, please validate the address and try again."
                              : 'Outlet must have a pickup address before the delivery zone map can be viewed.'
                          }
                          refetchQueries={[
                            {
                              query: GET_DELIVERY_ZONES,
                              variables: { outletId: outlet.id },
                            },
                          ]}
                        />
                      ) : (
                        <GeoDeliveryZones
                          center={outlet.outletAddress.geo.coordinates}
                          deliveryZones={outletDeliveryZoneCosts}
                        />
                      )}
                    </Card>
                    <Card className="bp3-nopad bp3-scrollable">
                      <HTMLTable interactive={true} bordered={false}>
                        <thead>
                          <tr>
                            {tableHead.map(({ key, content }) => (
                              <th key={key}>{content}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {outletDeliveryZoneCosts.map(zone => {
                            const {
                              id,
                              deliveryCost,
                              deliveryZone,
                              deliveryNetwork,
                              radiusMiles,
                            } = zone
                            const webhookStatus = get(
                              deliveryNetwork,
                              'webhookStatus',
                              'NOT APPLICABLE'
                            )
                            return (
                              <tr key={id}>
                                <td>
                                  {userIsOutletUser ? (
                                    deliveryZone.name
                                  ) : (
                                    <a
                                      onClick={() => {
                                        toggleModal({
                                          outletDeliveryZoneModal: true,
                                        })
                                        setActiveZone(zone)
                                      }}
                                    >
                                      {deliveryZone.name}
                                    </a>
                                  )}
                                </td>
                                <td>
                                  <Tag minimal>
                                    {get(
                                      deliveryZone,
                                      'deliveryZoneType',
                                      'Restaurant'
                                    ).replace(/_/g, ' ')}
                                  </Tag>
                                </td>
                                <td>
                                  <Currency amount={deliveryCost} />
                                </td>
                                <td>{radiusMiles || 'N/A'}</td>
                                <td>{displayFixedSubsidy(deliveryNetwork)}</td>
                                <td>
                                  <Tooltip
                                    content={'An unexpected error occurred'}
                                    disabled={webhookStatus !== 'ERROR'}
                                  >
                                    <Tag
                                      minimal
                                      intent={
                                        WEBHOOK_STATUS_TO_INTENT_MAP[
                                          webhookStatus
                                        ]
                                      }
                                    >
                                      {webhookStatus}
                                    </Tag>
                                  </Tooltip>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </HTMLTable>
                    </Card>
                  </Fragment>
                )}
                <OutletDeliveryZoneModal
                  modalOpen={outletDeliveryZoneModal}
                  modalClose={() => {
                    toggleModal({
                      outletDeliveryZoneModal: false,
                    })
                  }}
                  marketplaceId={marketplaceId}
                  outletDeliveryZones={outletDeliveryZoneCosts}
                  activeOutletDeliveryZone={activeZone}
                  clearActiveZone={clearActiveZone}
                  outletId={match.params.outlet}
                />
              </Fragment>
            )}
          </CacheQuery>
        )
      }}
    </Query>
  )
}

DeliveryZones.propTypes = {
  match: matchType,
}

export default DeliveryZones

import React, { useState, useEffect } from 'react'
import { InputGroup, Intent } from '@blueprintjs/core'
import injectSheet from 'react-jss'

const ColorPicker = ({
  classes,
  value,
  onChange,
  error,
  name,
  disabled = false,
  ...rest
}) => {
  const [color, setColor] = useState('')

  useEffect(() => {
    setColor(value ? value.replace(/#/g, '') : '')
  }, [value])

  const handleChange = e => {
    if (e.target.type === 'text' && e.target.value) {
      const value = e.target.value.includes('#')
        ? e.target.value
        : '#' + e.target.value
      e.target.value = value
    } else {
      setColor(e.target.value.replace(/#/g, '').toUpperCase())
    }
    onChange(e)
  }

  return (
    <InputGroup
      value={color}
      leftIcon={<span className={classes.hash}>#</span>}
      rightElement={
        !error && (
          <input
            type="color"
            name={name}
            value={`#${color}`}
            onChange={handleChange}
            disabled={disabled}
            style={{
              height: '30',
              background: 'none',
              borderColor: 'transparent',
            }}
          />
        )
      }
      onChange={handleChange}
      intent={error ? Intent.DANGER : Intent.NONE}
      name={name}
      disabled={disabled}
      {...rest}
    />
  )
}

const styles = {
  hash: {
    width: '25px',
    height: '30px',
    fontSize: '1.2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 1,
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
  },
  colorSwatch: {
    height: '30px',
    width: '30px',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  },
}

export default injectSheet(styles)(ColorPicker)

import React, { useEffect, useContext, Fragment, useState } from 'react'
import {
  Drawer,
  ButtonGroup,
  Button,
  AnchorButton,
  Divider,
} from '@blueprintjs/core'
import { Query as CacheQuery } from 'react-apollo'
import Query from '@components/Query/Query'

import MENU_QUERYS from '@components/Restaurant/Menu/queries/menuQueries.clientQuery'
import { toggleModal, setValue, clearStore } from '@utils/cacheHelpers'

import MenuTree from '@components/Restaurant/Menu/MenuTree/Menu'
import MenuList from '@components/Restaurant/Menu/MenuList/MenuList'
import FilterRow from '@components/FilterRow/FilterRow'
import ItemDetails from '@components/Restaurant/Menu/MenuTree/ItemDetails'

import ParentMenuModal from '@components/Restaurant/Menu/modals/AddNewParentMenu.modal'
import AddSubMenuModal from '@components/Restaurant/Menu/modals/AddSubMenu.modal'
import AddItemModal from '@components/Restaurant/Menu/modals/AddItem.modal'
import EditMenuModal from '@components/Restaurant/Menu/modals/EditMenu.modal'
import AddOptionModal from '@components/Restaurant/Menu/modals/AddOption.modal'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import PrintContent from '@components/PrintContent/PrintContent'
import MenuPrintout from './MenuPrintout'
import GET_OUTLET from '@components/Outlet/Details/queries/getOutlet.query'
import { first, orderBy, toLower } from 'lodash'
import GET_RESTAURANT_OUTLETS from './queries/getRestaurantOutlets.query'
import queryString from 'query-string'
import { client } from '@root/services/client'
import GET_MENU_FOR_DOWNLOAD from './queries/getMenuForDownload.query'
import { kebabCase } from 'lodash'
import { MenuImport } from './MenuImport/MenuImport'
import { MenuDefinition } from '@components/Restaurant/Menu/schema/menu'
import { MenuGroupDefinition } from '@components/Restaurant/Menu/schema/menu-group'
import { ExportPopover } from '../ExportPopover/ExportPopover'
import { ImportPopover } from '../ImportPopover/ImportPopover'
import OpenMenuTreeProvider from './MenuList/OpenMenuTreeProvider'
import { StringParam, useQueryParams } from 'use-query-params'

const getDeepestMenuId = ({ menuId, subMenuId, subSubMenuId }) => {
  if (subSubMenuId) {
    return subSubMenuId
  }
  if (subMenuId) {
    return subMenuId
  }
  if (menuId) {
    return menuId
  }
}

const generateMenuItemsCSV = async (queryId, includeIds) => {
  const response = await client.query({
    query: GET_MENU_FOR_DOWNLOAD,
    variables: {
      id: queryId,
    },
  })

  const fileName = `${kebabCase(
    response.data.getRestaurants.restaurants[0].name
  )}-menu-items.csv`
  let menuItemsGroups =
    response.data.getRestaurants.restaurants[0].menuItemsGroups

  if (!includeIds) {
    menuItemsGroups = menuItemsGroups.map(({ id: _, ...menuItemsGroup }) => ({
      ...menuItemsGroup,
      menuItems: menuItemsGroup.menuItems.map(
        ({ id: __, options, ...menuItem }) => ({
          ...menuItem,
          options: [],
        })
      ),
    }))
  }

  const sortedMenuItemGroups = orderBy(menuItemsGroups, 'name', 'desc')

  const csvData = MenuDefinition.menuItemGroupsToCSV(sortedMenuItemGroups)
  return { fileName, csvData }
}

const generateMenuItemGroupsCSV = async (queryId, includeIds) => {
  const response = await client.query({
    query: GET_MENU_FOR_DOWNLOAD,
    variables: {
      id: queryId,
    },
  })

  const fileName = `${kebabCase(
    response.data.getRestaurants.restaurants[0].name
  )}-menu.csv`

  let menuItemsGroups =
    response.data.getRestaurants.restaurants[0].menuItemsGroups

  if (!includeIds) {
    menuItemsGroups = menuItemsGroups.map(({ id: _, ...menuItemsGroup }) => ({
      ...menuItemsGroup,
      menuItems: menuItemsGroup.menuItems.map(
        ({ id: __, options, ...menuItem }) => ({
          ...menuItem,
          options: options.map(({ id: ___, ...option }) => option),
        })
      ),
    }))
  }

  const sortedMenuItemGroups = orderBy(menuItemsGroups, 'name', 'desc')

  const csvData = MenuGroupDefinition.menuItemGroupsToCSV(sortedMenuItemGroups)
  return { fileName, csvData }
}

const Menu = ({ match, location, history, outlet }) => {
  const [{ menuId, menuItemId }, setQueryParams] = useQueryParams({
    menuId: StringParam,
    menuItemId: StringParam,
  })
  const { dark } = useContext(PageLayoutContext)
  const [importFileData, setImportFileData] = useState(null)
  const [showImport, setShowImport] = useState(false)

  useEffect(() => {
    return clearStore
  }, [])

  const queryId = match.params[outlet ? 'outlet' : 'restaurants']
  const menuQuery = outlet ? GET_OUTLET : GET_RESTAURANT_OUTLETS
  const entityId = outlet ? match.params['outlet'] : match.params['restaurants']
  const { view } = queryString.parse(location.search)

  const closeMenuItem = () => {
    setQueryParams({ menuId: undefined, menuItemId: undefined })
  }

  return (
    <OpenMenuTreeProvider>
      <CacheQuery query={MENU_QUERYS}>
        {({ data }) => {
          const deepestMenuId = getDeepestMenuId(data)
          return (
            <div className="bp3-table-frame">
              <FilterRow>
                <div>
                  <ButtonGroup>
                    <Button
                      icon="list-columns"
                      text="Column"
                      active={view != 'tree'}
                      onClick={() => history.push(`${match.url}`)}
                    />

                    <Button
                      icon="list"
                      text="Tree"
                      active={view == 'tree'}
                      onClick={() => history.push(`${match.url}?view=tree`)}
                    />
                  </ButtonGroup>
                  <Divider />

                  {!outlet && (
                    <Fragment>
                      <ButtonGroup>
                        <ImportPopover
                          startImport={data => {
                            setImportFileData(data)
                            setShowImport(true)
                          }}
                        />
                        <ExportPopover
                          options={[
                            {
                              label: 'Items',
                              value: 'menu-items',
                              generateCSV: includeIds =>
                                generateMenuItemsCSV(queryId, includeIds),
                            },
                            {
                              label: 'Menu',
                              value: 'menu-item-groups',
                              generateCSV: includeIds =>
                                generateMenuItemGroupsCSV(queryId, includeIds),
                            },
                          ]}
                        />
                      </ButtonGroup>
                      <Divider />
                    </Fragment>
                  )}

                  <ButtonGroup>
                    <PrintContent buttonName="Print">
                      <MenuPrintout
                        restaurantId={match.params['restaurants']}
                      />
                    </PrintContent>
                    <Query
                      query={menuQuery}
                      variables={{ id: entityId }}
                      showLoader={false}
                    >
                      {data => {
                        let name = null
                        let foundOutlet = {}

                        if (outlet) {
                          foundOutlet = first(data.getOutlets.outlets)
                          name = foundOutlet.name
                            ? foundOutlet.name.replace(/[^A-Z0-9]+/gi, '-')
                            : null
                        } else if (data.getRestaurants.restaurants.length > 0) {
                          const restaurant = first(
                            data.getRestaurants.restaurants
                          )
                          foundOutlet = first(restaurant.outlets)
                          name = restaurant.name
                            ? restaurant.name.replace(/[^A-Z0-9]+/gi, '-')
                            : null
                        }

                        let loc = `https://${foundOutlet.marketplace.cname}/${
                          name ? name + '/' : ''
                        }${foundOutlet.id}/menu?showInactive=true`

                        if (
                          foundOutlet.marketplace &&
                          foundOutlet.marketplace.enableCustomerV2Client &&
                          foundOutlet.id &&
                          foundOutlet.name
                        ) {
                          const restaurantName = outlet
                            ? foundOutlet.restaurant.name
                            : first(data.getRestaurants.restaurants).name

                          const cnameAndProtocol =
                            process.env.NODE_ENV === 'development'
                              ? `http://${foundOutlet.marketplace.cname}:8990`
                              : `https://${foundOutlet.marketplace.cname}`

                          // structure is the same for list and postcode marketplaces
                          loc = `${cnameAndProtocol}/${
                            foundOutlet.marketplace.urlPath
                          }/${foundOutlet.id}/${kebabCase(
                            toLower(restaurantName)
                          )}/menu?showInactive=1`
                        } else {
                          //default is takeaway
                          if (foundOutlet.marketplace.orderMode === 'LIST') {
                            // list marketplaces
                            const deliveryZoneCosts = first(
                              foundOutlet.deliveryZoneCosts
                            )

                            if (deliveryZoneCosts) {
                              const deliveryZone =
                                deliveryZoneCosts.deliveryZone
                              loc = `https://${foundOutlet.marketplace.cname}/${deliveryZone.name}-${foundOutlet.marketplace.urlpath}/${name}/${foundOutlet.id}/menu?showInactive=true`
                            } else {
                              // no delivery zones - disable web preview
                              loc = null
                            }
                          } else if (
                            foundOutlet.marketplace.orderMode === 'SINGLE'
                          ) {
                            // single outlet marketplaces
                            const merchantCategory = foundOutlet.marketplace
                              .urlPath
                              ? foundOutlet.marketplace.urlPath
                              : 'takeaways'

                            loc = `https://${foundOutlet.marketplace.cname}/${merchantCategory}/${foundOutlet.id}/menu?showInactive=true`
                          } else {
                            // postcode marketplaces
                            loc = `https://${foundOutlet.marketplace.cname}/${name}/${foundOutlet.id}/menu?showInactive=true`
                          }
                        }

                        return (
                          <Fragment>
                            {foundOutlet && foundOutlet.marketplace.cname && (
                              <AnchorButton
                                icon="eye-open"
                                rightIcon="share"
                                text="Web Preview"
                                href={loc}
                                target="_blank"
                                rel="noopener noreferrer"
                                disabled={
                                  !foundOutlet.menuItemsGroups.length || !loc
                                }
                              />
                            )}
                          </Fragment>
                        )
                      }}
                    </Query>
                  </ButtonGroup>
                </div>
              </FilterRow>
              {view === 'tree' ? (
                //TREE view
                <MenuList
                  outlet={outlet}
                  queryId={queryId}
                  onChangeMenuId={menuId => setQueryParams({ menuId })}
                  onChangeMenuItemId={menuItemId =>
                    setQueryParams({ menuItemId })
                  }
                  setViewMenuItem={setQueryParams}
                  selectedMenuItemId={menuItemId}
                />
              ) : (
                // LIST VIEW
                <MenuTree
                  outlet={outlet}
                  deepestMenuId={deepestMenuId}
                  queryId={queryId}
                  setViewMenuItem={setQueryParams}
                />
              )}
              <MenuImport
                show={showImport}
                importData={importFileData}
                restaurantId={match.params['restaurants']}
                onClose={() => {
                  setShowImport(false)

                  // sneaky: switch the view to tree and back to list to force a refresh
                  const currentView = view || 'list'
                  // dont use push cause it will add to the history stack
                  history.replace(
                    `${match.url}?view=${view === 'tree' ? 'list' : 'tree'}`
                  )
                  history.replace(`${match.url}?view=${currentView}`)
                }}
              />

              <Drawer
                isOpen={menuId && menuItemId}
                onClose={closeMenuItem}
                title="Edit Menu Item"
                size={`${window.innerWidth > 1100 ? '1000px' : '95%'}`}
                className={dark ? 'bp3-dark' : ''}
              >
                <ItemDetails
                  menuId={menuId}
                  menuItemId={menuItemId}
                  restaurantId={match.params.restaurants}
                  outletId={match.params.outlet}
                  outlet={outlet}
                  closeMenuItem={closeMenuItem}
                  openingTimes={data.openingTimes}
                  soldOutUntilItems={data.soldOutUntilItems}
                  nextOpen={data.nextOpen}
                />
              </Drawer>
              <ParentMenuModal
                modalOpen={data.parentMenuModal}
                modalClose={() =>
                  toggleModal({
                    parentMenuModal: false,
                  })
                }
                restaurantId={match.params.restaurants}
                parentMenuId={data.parentMenuId}
              />
              <AddSubMenuModal
                modalOpen={data.subMenuModal}
                modalClose={() => {
                  toggleModal({
                    subMenuModal: false,
                  })
                }}
                restaurantId={match.params.restaurants}
                parentMenuId={data.parentMenuId}
              />
              <AddItemModal
                modalOpen={data.itemModal}
                modalClose={() => toggleModal({ itemModal: false })}
                menuId={deepestMenuId}
                queryId={queryId}
                restaurantId={match.params.restaurants}
              />
              <EditMenuModal
                modalOpen={data.editMenuModal}
                modalClose={() => {
                  toggleModal({
                    editMenuModal: false,
                  })
                }}
                restaurantId={match.params.restaurants}
                menuId={data.editedMenu}
              />
              <AddOptionModal
                modalOpen={data.optionModal}
                modalClose={() => {
                  toggleModal({ optionModal: false })
                  setValue({ optionId: '' })
                }}
                optionId={data.optionId}
                restaurantId={match.params.restaurants}
              />
            </div>
          )
        }}
      </CacheQuery>
    </OpenMenuTreeProvider>
  )
}

export default Menu

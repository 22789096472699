import React from 'react'
import { Formik } from 'formik'
import {
  Card,
  FormGroup,
  Button,
  NonIdealState,
  Slider,
  RangeSlider,
  Checkbox,
  Callout,
  Intent,
  AnchorButton,
} from '@blueprintjs/core'
import first from 'lodash/first'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import { matchType } from '@utils/types'
import { successToast } from '@utils/toast'
import Query from '@components/Query/Query'
import EDIT_FULFILMENT_TIMES from './mutations/editFulfilmentTimes.mutation'
import GET_FULFILMENT_TIMES from './queries/getFulfilmentTimes.query'
import GET_OUTLET_DELIVERY_WINDOWS from './queries/deliveryWindows.query'
import { Fragment } from 'react'
import FulfilmentTimesSetupValidattionSchema from './fulfulmentTimesValidation'

const FulfilmentTimes = ({ match }) => (
  <Card>
    <Query
      query={GET_FULFILMENT_TIMES}
      variables={{ id: match.params.outlet }}
      loaderTitle={'Fulfilment Times'}
    >
      {({ getOutlets: { outlets } }) => {
        const outlet = first(outlets)

        const {
          defaultCollectionTime = 0,
          defaultDeliveryTime = 0,
          daysOfferedInAdvanceMin = 0,
          daysOfferedInAdvanceMax = 1,
          autoCompleteAfter,
          noPreordersBeforeOpening,
          allowPreorders,
          marketplace,
          asapAllowed,
        } = outlet

        return outlet ? (
          <Mutation
            mutation={EDIT_FULFILMENT_TIMES}
            onError={defaultErrorHandler}
            onCompleted={() =>
              successToast('Successfully edited fulfilment times')
            }
            refetchQueries={[
              {
                query: GET_OUTLET_DELIVERY_WINDOWS,
                variables: { outletId: match.params.outlet },
              },
            ]}
          >
            {editFulfilmentTimes => (
              <Formik
                initialValues={{
                  defaultCollectionTime,
                  defaultDeliveryTime,
                  daysOfferedInAdvanceMin,
                  daysOfferedInAdvanceMax,
                  asapAllowed: Boolean(asapAllowed),
                  noPreordersBeforeOpening: Boolean(noPreordersBeforeOpening),
                  allowPreorders: Boolean(allowPreorders),
                  autoCompleteAfter:
                    autoCompleteAfter === null ? -10 : autoCompleteAfter, // -10 is used as the equivalent of null for the slider
                }}
                validationSchema={FulfilmentTimesSetupValidattionSchema}
                onSubmit={({ autoCompleteAfter, ...values }) => {
                  const variables = {
                    id: match.params.outlet,
                    ...values,
                  }
                  if (outlet.isOrderToTableEnabled) {
                    variables.autoCompleteAfter =
                      autoCompleteAfter && autoCompleteAfter < 0
                        ? null
                        : autoCompleteAfter
                  }
                  return editFulfilmentTimes({
                    variables,
                  })
                }}
              >
                {({
                  values,
                  errors,
                  handleSubmit,
                  setFieldValue,
                  handleChange,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <h5 className="bp3-heading">Fulfilment Times</h5>
                    <FormGroup
                      helperText={errors.asapAllowed || ''}
                      intent={Intent.DANGER}
                    >
                      <Checkbox
                        id="asapAllowed"
                        name="asapAllowed"
                        checked={values.asapAllowed}
                        onChange={handleChange}
                        label="Allow ASAP"
                      />
                    </FormGroup>
                    <FormGroup
                      label="Standard Delivery Time"
                      labelInfo="(minutes)"
                      helperText="Delivery times now use windows and are no longer required."
                    >
                      <Slider
                        name="defaultDeliveryTime"
                        max={120}
                        labelStepSize={20}
                        stepSize={5}
                        value={values.defaultDeliveryTime}
                        disabled={true}
                      />
                    </FormGroup>

                    <FormGroup
                      label="Prep / Collection Time"
                      labelInfo="(minutes)"
                      helperText="Time needed to prepare the order before pickup."
                    >
                      <Slider
                        name="defaultCollectionTime"
                        max={120}
                        labelStepSize={20}
                        stepSize={5}
                        value={values.defaultCollectionTime}
                        onChange={val =>
                          setFieldValue('defaultCollectionTime', val)
                        }
                      />
                    </FormGroup>
                    {defaultCollectionTime != values.defaultCollectionTime ? (
                      <Fragment>
                        <Callout intent="warning">
                          Updating your prep time will edit your delivery
                          windows and reset your order limits.
                        </Callout>
                        <br />
                      </Fragment>
                    ) : null}

                    <br />
                    <h5 className="bp3-heading">Preordering</h5>
                    <FormGroup
                      helperText={errors.allowPreorders || ''}
                      intent={Intent.DANGER}
                    >
                      <Checkbox
                        id="allowPreorders"
                        name="allowPreorders"
                        checked={values.allowPreorders}
                        onChange={handleChange}
                        label="Allow Preorders"
                      />
                    </FormGroup>
                    <FormGroup
                      label="Days Offered"
                      helperText="How many days in advance a customer can preorder"
                      disabled={!values.allowPreorders}
                    >
                      <RangeSlider
                        name="daysOfferedInAdvance"
                        min={1}
                        max={marketplace.allowExtendedPreorders ? 31 : 5}
                        labelValues={
                          marketplace.allowExtendedPreorders
                            ? [1, 5, 10, 15, 20, 25, 31]
                            : [1, 2, 3, 4, 5]
                        }
                        stepSize={1}
                        value={[
                          // add one to each value as having today as a 0 index was confusing to users
                          values.daysOfferedInAdvanceMin + 1,
                          values.daysOfferedInAdvanceMax + 1,
                        ]}
                        onChange={([min, max]) => {
                          // remove one so that when we consume the info in the client today is 0 based index
                          setFieldValue('daysOfferedInAdvanceMin', min - 1)
                          setFieldValue('daysOfferedInAdvanceMax', max - 1)
                        }}
                        disabled={!values.allowPreorders}
                      />
                    </FormGroup>
                    {outlet.isOrderToTableEnabled && (
                      <Fragment>
                        <br />
                        <h5 className="bp3-heading">Table Ordering</h5>
                        <FormGroup
                          label="Autocomplete Table Orders"
                          helperText="After how many minutes should a table order be automatically completed"
                        >
                          <Slider
                            min={-10}
                            max={60}
                            showTrackFill={false}
                            labelStepSize={10}
                            stepSize={10}
                            labelRenderer={val =>
                              val < 0 ? 'Off' : val.toString()
                            }
                            value={values.autoCompleteAfter}
                            onChange={val =>
                              setFieldValue('autoCompleteAfter', val)
                            }
                          />
                        </FormGroup>
                      </Fragment>
                    )}
                    <div className="bp-card-footer-actions">
                      <Button
                        text="Save"
                        type="submit"
                        loading={isSubmitting}
                        disabled={!values.asapAllowed && !values.allowPreorders}
                      />
                      <AnchorButton
                        text="Ordering Guide"
                        href="https://support.redbox.systems/docs/outlet-ordering-settings"
                        target="_blank"
                        rel="noopener noreferrer"
                        rightIcon="help"
                        intent={Intent.PRIMARY}
                        minimal
                      />
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </Mutation>
        ) : (
          <NonIdealState
            title="No fulfilment times found"
            icon="time"
            className="bp3-card-non-ideal-state"
          />
        )
      }}
    </Query>
  </Card>
)

FulfilmentTimes.propTypes = {
  match: matchType,
}

export default FulfilmentTimes

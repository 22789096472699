import React, { Fragment, useState } from 'react'
import cx from 'classnames'
import injectSheet from 'react-jss'
import { MultiSelect } from '@blueprintjs/select'
import {
  Checkbox,
  Drawer,
  HTMLSelect,
  InputGroup,
  TextArea,
  FormGroup,
  MenuItem,
  Tag,
  Tooltip,
  Button,
  Intent,
} from '@blueprintjs/core'
import CurrencyInput from '@components/CurrencyInput/CurrencyInput'
import { styles } from './FieldSelector.styles'
import NumericInput from '@components/NumericInput/NumericInput'

const FieldError = ({ children, error }) => {
  return (
    <Tooltip content={error} intent="danger">
      <div style={{ border: '1px solid #db3737' }}>{children}</div>
    </Tooltip>
  )
}

const Field = ({ row, name, field, handleChange, options, classes }) => {
  const fieldValue = row.importItem[field.id]

  const [drawerOpen, setDrawerOpen] = useState(false)

  switch (field.type) {
    case 'number':
      return (
        <NumericInput
          name={name}
          defaultValue={fieldValue}
          minimal
          style={{ width: field.width }}
          onUpdate={value => {
            handleChange({
              target: { name, type: 'number', value: Number(value) },
            })
          }}
          fill
        />
      )
    case 'currency':
      return (
        <CurrencyInput
          name={name}
          defaultValue={fieldValue}
          minimal
          style={{ width: field.width }}
          onUpdate={value => {
            handleChange({
              target: { name, type: 'currency', value: Number(value) },
            })
          }}
          fill
        />
      )

    case 'text':
      return (
        <Fragment>
          <div
            onClick={() => {
              if (field.drawer) {
                setDrawerOpen(true)
              }
            }}
          >
            <InputGroup
              name={name}
              value={fieldValue || ''}
              onChange={handleChange}
              className={'bp3-minimal'}
              style={{ width: field.width }}
            />
          </div>

          <Drawer
            isOpen={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            size="640px"
            title="Edit menu item description"
          >
            <FormGroup
              label={field.label}
              labelFor={field.id}
              className={classes.drawerFormGroup}
            >
              <TextArea
                id={field.id}
                name={name}
                value={fieldValue || ''}
                onChange={handleChange}
                fill
                className={classes.drawerField}
              />
            </FormGroup>

            <div
              className={cx('bp3-drawer-footer-actions', classes.drawerFooter)}
            >
              <Button
                onClick={() => setDrawerOpen(false)}
                intent={Intent.DEFAULT}
                text="Save"
                className={classes.drawerFooterSaveButton}
              />
            </div>
          </Drawer>
        </Fragment>
      )
    case 'bool':
      return (
        <Checkbox name={name} checked={fieldValue} onChange={handleChange} />
      )
    case 'selectSingle':
      if (options && options.length > 0) {
        return (
          <HTMLSelect
            name={name}
            value={fieldValue || ''}
            onChange={handleChange}
            style={{ width: field.width }}
            minimal
            options={options}
          />
        )
      } else {
        return (
          <Tag intent="warning" minimal>
            No Options Available
          </Tag>
        )
      }
    case 'selectMultiple':
      if (options && options.length > 0) {
        const OptionRenderer = (option, { handleClick, modifiers }) => {
          return (
            <MenuItem
              key={option.id}
              text={option.label}
              active={modifiers.active}
              disabled={modifiers.disabled}
              onClick={handleClick}
            />
          )
        }

        const selectedItems = fieldValue
          ? fieldValue
              .split('|')
              .map(selectedItemId =>
                options.find(option => option.id === selectedItemId)
              )
              .filter(Boolean)
          : []

        return (
          <MultiSelect
            placeholder="No options"
            className="bp3-minimal"
            selectedItems={selectedItems}
            onItemSelect={item => {
              const value = [
                ...selectedItems.map(({ id }) => id),
                item.id,
              ].join('|')

              handleChange({
                target: {
                  name,
                  type: 'multi-select',
                  value,
                },
              })
            }}
            items={options}
            itemRenderer={OptionRenderer}
            itemDisabled={item => fieldValue && fieldValue.includes(item.id)}
            tagRenderer={item => item.label}
            tagInputProps={{
              tagProps: { minimal: true },
              onRemove: item => {
                const value = selectedItems
                  .filter(({ label }) => label !== item)
                  .map(({ id }) => id)
                  .join('|')

                handleChange({
                  target: {
                    name,
                    type: 'multi-select',
                    value,
                  },
                })
              },
            }}
            fill
          />
        )
      } else {
        return (
          <Tag intent="warning" minimal>
            No Options Available
          </Tag>
        )
      }

    default:
      return null
  }
}

export const FieldSelector = injectSheet(styles)(function ({
  field,
  handleChange,
  row,
  error,
  options,
  classes,
}) {
  const name = `importItem.${field.id}`

  const fieldProps = {
    row,
    error,
    name,
    field,
    handleChange,
    options,
    classes,
  }

  const fieldError = error && error.importItem[field.id]
  if (fieldError) {
    return (
      <FieldError error={fieldError}>
        <Field {...fieldProps} />
      </FieldError>
    )
  }

  return <Field {...fieldProps} />
})

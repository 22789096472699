export const styles = theme => ({
  container: {
    display: 'flex',
  },
  dropDown: {
    borderRight: `1px solid ${theme.colors.borderColor}`,
    width: '33%',
    minWidth: 200,
    '& .bp3-tree-node-caret': {
      color: theme.colors.primaryNavActiveColor,
    },
    '& .bp3-tree-node-caret-none': {
      minWidth: '10px',
    },
    '& .bp3-tag': {
      fontSize: '11px',
    },
  },
  secondaryDropdown: {
    borderLeft: 0,
  },
  field: {
    '&:hover': {
      borderLeft: `3px solid ${theme.colors.primaryNavActiveColor}`,
    },
    '&:last-child': {
      borderBottom: `1px solid ${theme.colors.borderColor}`,
    },
  },
  bottomBorder: {
    borderBottom: `1px solid ${theme.colors.borderColor}`,
  },
  item: {
    '&:first-child': {
      borderTop: 0,
    },
  },
  add: {
    borderTop: `1px solid ${theme.colors.borderColor}`,
    '& .bp3-icon': {
      padding: '4px 1px 4px 7px',
    },
    '& .bp3-tree-node-caret-none': {
      minWidth: 0,
    },
  },
  editButton: {
    '&:hover': {
      color: theme.colors.highlight,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.padding.small,
  },
  errorMessage: {
    color: theme.colors.highlight,
  },

  menuModal: {
    width: 600,
  },
  subMenu: {
    borderLeft: 0,
  },
  selected: {
    backgroundColor: theme.colors.menuSelect,
    color: theme.colors.menuSelectText,
    border: 0,
    '& .bp3-icon': {
      color: theme.colors.menuSelectText,
    },
  },
  hidden: {
    opacity: '0.4',
  },
})

import React, { useEffect, useState, useCallback, useContext } from 'react'
import get from 'lodash/get'
import Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { Query, Mutation } from 'react-apollo'
import {
  ProgressBar,
  Card,
  Spinner,
  ButtonGroup,
  Button,
  NonIdealState,
} from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import some from 'lodash/some'
import debounce from 'lodash/debounce'

import GET_MENU_TREE from '@components/Restaurant/Menu/queries/getMenuTree.query'
import GET_OUTLET_MENU_TREE from '@components/Restaurant/Menu/queries/getOutletMenuTree.query'

import EDIT_MENUS_ORDER from '@components/Restaurant/Menu/mutations/editMenusOrder.mutation'
import EDIT_MENU_ITEMS_GROUP_PARENT_ID from '@components/Restaurant/Menu/mutations/editMenuItemsGroupParentId.mutation'
import EDIT_MENU_ITEMS_ORDER from '@components/Restaurant/Menu/mutations/editMenuItemsOrder.mutation'
import EDIT_MENU_ITEM_PARENT_ID from '@components/Restaurant/Menu/mutations/editMenuItemParentId.mutation'

import MenuItemGroups from './MenuItemGroups'
import ItemTypes from './ItemTypes'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import NoParentMenus from '@components/Restaurant/Menu/components/NoParentMenus'
import { MenuListContext } from '@components/Restaurant/Menu/MenuList/menu-list-context'
import { flatMap, union, without } from 'lodash'
import FilterRow from '@components/FilterRow/FilterRow'
import { Fragment } from 'react'
import { OpenMenuContext } from './OpenMenuTreeProvider'

const parseData = async ({
  menuItemsGroups,
  editMenusOrder,
  editMenuItemsOrder,
  setMutationsToLoad,
}) => {
  const checkBadMenuData = data => some(data, { position: null })

  const mapToPosition = data =>
    data.map(({ id }, index) => ({ id, position: index }))

  const orderMutations = []

  const parseMenuItems = menuItems => {
    if (checkBadMenuData(menuItems)) {
      orderMutations.push(
        async () =>
          await editMenuItemsOrder({
            variables: {
              input: mapToPosition(menuItems),
            },
          })
      )
    }
  }

  const parseChildrenMenus = childrenMenus => {
    if (checkBadMenuData(childrenMenus)) {
      orderMutations.push(
        async () =>
          await editMenusOrder({
            variables: {
              input: mapToPosition(childrenMenus),
            },
          })
      )
    }

    childrenMenus.forEach(menu => {
      if (menu.menuItems.length) {
        parseMenuItems(menu.menuItems)
      }
      if (menu.childrenMenus.length) {
        parseChildrenMenus(menu.childrenMenus)
      }
    })
  }

  if (checkBadMenuData(menuItemsGroups)) {
    orderMutations.push(
      async () =>
        await editMenusOrder({
          variables: {
            input: mapToPosition(menuItemsGroups),
          },
        })
    )
  }

  menuItemsGroups.forEach(menu => {
    if (menu.menuItems.length) {
      parseMenuItems(menu.menuItems)
    }
    if (menu.childrenMenus.length) {
      parseChildrenMenus(menu.childrenMenus)
    }
  })

  const runMutations = async mutations => {
    for (let index = 0; index < mutations.length; index++) {
      const mutation = mutations[index]
      await mutation()
      setMutationsToLoad({ total: orderMutations.length, loaded: index + 1 })
    }

    setMutationsToLoad({ total: 0, loaded: 0 })
  }
  setMutationsToLoad({ total: orderMutations.length, loaded: 0 })
  runMutations(orderMutations)
}

// This is used for TREE VIEW
const MenuList = ({
  refetchMenuList,
  saving,
  setSaving,
  outlet,
  data,
  editMenusOrder,
  editMenuItemsGroupParentId,
  editMenuItemsOrder,
  editMenuItemParentId,
  setViewMenuItem,
  queryId,
  selectedMenuItemId,
}) => {
  const { openMenuGroups, setOpenMenuGroups, currentMenuGroups } =
    useContext(OpenMenuContext)
  const [mutationsToLoad, setMutationsToLoad] = useState({
    total: 0,
    loaded: 0,
  })
  const [menus, setMenus] = useState([])
  const [dragging, setDragging] = useState(false)
  const [outletsSoldOut, setOutletsSoldOut] = useState([])
  const [hiddenMenuItemGroupIds, setHiddenMenuItemGroupIds] = useState([])
  const [hiddenMenuItemIds, setHiddenMenuItemIds] = useState([])
  const [outletId, setOutletId] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const menuItemsGroups = get(
      data,
      outlet
        ? 'getOutlets.outlets[0].menuItemsGroups'
        : 'getRestaurants.restaurants[0].menuItemsGroups',
      []
    )

    const getSoldOutOutletItems = data =>
      get(data, outlet ? [] : 'getRestaurants.restaurants[0].outlets', [])

    const soldOutOutletItems = getSoldOutOutletItems(data)

    const getHiddenMenuItemGroupIds = data =>
      get(
        data,
        outlet ? 'getOutlets.outlets[0].hiddenMenuItemGroupIds' : [],
        []
      )

    const getHiddenMenuItemIds = data =>
      get(data, outlet ? 'getOutlets.outlets[0].hiddenMenuItemIds' : [], [])

    const getOutletId = data =>
      get(
        data,
        outlet
          ? 'getOutlets.outlets[0].id'
          : 'getRestaurants.restaurants[0].outlets[0].id',
        null
      )

    setOutletsSoldOut(soldOutOutletItems)
    setHiddenMenuItemGroupIds(getHiddenMenuItemGroupIds(data))
    setHiddenMenuItemIds(getHiddenMenuItemIds(data))
    setOutletId(getOutletId(data))

    parseData({
      menuItemsGroups,
      editMenusOrder,
      editMenuItemsOrder,
      setMutationsToLoad,
    })

    setMenus(menuItemsGroups)
    if (!selectedMenuItemId && !openMenuGroups.length) {
      setOpenMenuGroups(
        flatMap(menuItemsGroups, ({ id, childrenMenus }) => {
          if (childrenMenus.length)
            return [...childrenMenus.map(({ id }) => id), id]
          return id
        })
      )
    }

    setLoading(false)
  }, [data, outlet, editMenusOrder, editMenuItemsOrder])

  const removeMenu = (menusCopy, removePath, finalPath) => {
    if (removePath.length === 1) {
      menusCopy.splice(removePath[0], 1)
    }
    if (removePath.length === 2) {
      menusCopy[removePath[0]][finalPath].splice(removePath[1], 1)
    }
    if (removePath.length === 3) {
      menusCopy[removePath[0]].childrenMenus[removePath[1]][finalPath].splice(
        removePath[2],
        1
      )
    }
  }

  const injectMenu = (menusCopy, injectPath, menu, finalPath) => {
    if (injectPath.length === 1) {
      menusCopy.splice(injectPath[0], 0, menu)
    }
    if (injectPath.length === 2) {
      menusCopy[injectPath[0]][finalPath].splice(injectPath[1], 0, menu)
    }
    if (injectPath.length === 3) {
      menusCopy[injectPath[0]].childrenMenus[injectPath[1]][finalPath].splice(
        injectPath[2],
        0,
        menu
      )
    }
  }

  const rePositionInjectionMenu = (menusCopy, injectPath, finalPath) => {
    if (injectPath.length === 1) {
      return menusCopy.map((menu, index) => ({ ...menu, position: index }))
    }
    if (injectPath.length === 2) {
      menusCopy[injectPath[0]][finalPath] = menusCopy[injectPath[0]][
        finalPath
      ].map((menu, index) => ({ ...menu, position: index }))
      return menusCopy
    }
    if (injectPath.length === 3) {
      menusCopy[injectPath[0]].childrenMenus[injectPath[1]][finalPath] =
        menusCopy[injectPath[0]].childrenMenus[injectPath[1]][finalPath].map(
          (menu, index) => ({ ...menu, position: index })
        )
      return menusCopy
    }
  }

  const editMenuOrder = ({ item, hoverPath }) => {
    setMenus(currentMenus => {
      const currentMenusCopy = [...currentMenus]
      const movingDeeper = item.path.length < hoverPath.length
      const movingDown = item.path[0] < hoverPath[0]
      if (item.type === ItemTypes.MENU && movingDeeper && movingDown) {
        const [first, ...rest] = hoverPath
        hoverPath = [first - 1, ...rest]
      }

      const finalPath =
        item.type === ItemTypes.MENU_ITEM ? 'menuItems' : 'childrenMenus'

      removeMenu(currentMenusCopy, item.path, finalPath)

      if (hoverPath.length > 1 && currentMenusCopy.length - 1 < hoverPath[0]) {
        hoverPath[0] = currentMenusCopy.length - 1
      }

      injectMenu(currentMenusCopy, hoverPath, item.menu, finalPath)

      item.path = hoverPath
      return rePositionInjectionMenu(currentMenusCopy, hoverPath, finalPath)
    })
  }

  const onCompleted = debounce(() => {
    successToast('Saved Menu')
  }, 200)

  const saveMenuOrder = useCallback(
    (item, path, parentMenuId = null) => {
      setSaving(true)
      const isMenuItem = item.type === ItemTypes.MENU_ITEM

      const menuParentId = get(
        item,
        isMenuItem ? 'menu.parentMenus[0].id' : 'menu.parentMenu.id',
        null
      )
      const parentIdChanged = parentMenuId !== menuParentId
      if (parentIdChanged) {
        if (isMenuItem) {
          editMenuItemParentId({
            variables: { id: item.menu.id, menuItemGroupIds: [parentMenuId] },
          }).then(() => onCompleted())
        } else {
          editMenuItemsGroupParentId({
            variables: { id: item.menu.id, parentMenuId },
          }).then(() => onCompleted())
        }
      }

      const getMenusAtPath = (path, isMenuItem) => {
        const finalPath = isMenuItem ? 'menuItems' : 'childrenMenus'
        if (!path || path.length === 1) {
          return menus
        }
        if (path.length === 2) {
          return menus[path[0]][finalPath]
        }
        if (path.length === 3) {
          return menus[path[0]].childrenMenus[path[1]][finalPath]
        }
      }

      const positionData = getMenusAtPath(path, isMenuItem).map(
        ({ id }, index) => ({
          id,
          position: index,
        })
      )
      if (isMenuItem) {
        editMenuItemsOrder({ variables: { input: positionData } }).then(() =>
          onCompleted()
        )
      } else {
        editMenusOrder({ variables: { input: positionData } }).then(() =>
          onCompleted()
        )
      }

      refetchMenuList()

      setSaving(false)
    },
    [
      onCompleted,
      setSaving,
      menus,
      editMenusOrder,
      editMenuItemsGroupParentId,
      editMenuItemParentId,
      editMenuItemsOrder,
    ]
  )

  const showMutationProgress =
    mutationsToLoad.total > mutationsToLoad.loaded ||
    !!(mutationsToLoad.loaded / mutationsToLoad.total)

  const toggleOpenOrClosed = id => {
    setOpenMenuGroups(
      openMenuGroups.includes(id)
        ? without(openMenuGroups, id)
        : union(openMenuGroups, [id])
    )
  }

  const expandAllGroups = () => {
    setOpenMenuGroups(
      flatMap(menus, ({ id, childrenMenus }) => {
        if (childrenMenus.length)
          return [...childrenMenus.map(({ id }) => id), id]
        return id
      })
    )
  }

  const collapseAllGroups = () => {
    setOpenMenuGroups([])
  }

  return (
    <MenuListContext.Provider
      value={{
        dragging,
        setDragging,
        selectedMenuItemId,
        setViewMenuItem,
        editMenuOrder,
        saveMenuOrder,
        refetchMenuList,
      }}
    >
      <Fragment>
        <FilterRow>
          <ButtonGroup />
          <ButtonGroup>
            <Button icon="expand-all" onClick={expandAllGroups} minimal />
            <Button icon="collapse-all" onClick={collapseAllGroups} minimal />
          </ButtonGroup>
        </FilterRow>

        {!loading ? (
          <Card
            className="bp3-nopad"
            style={{
              flexGrow: 1,
              overflowY: 'auto',
              cursor: dragging ? '-webkit-grabbing' : undefined,
            }}
          >
            {menus.length ? (
              <Fragment>
                <div style={{ position: 'relative' }}>
                  <Dialog
                    isOpen={showMutationProgress}
                    title="Updating Menu Position Information"
                    isCloseButtonShown={false}
                  >
                    <div style={{ padding: '12px' }}>
                      <ProgressBar
                        intent={'primary'}
                        value={mutationsToLoad.loaded / mutationsToLoad.total}
                      />
                    </div>
                  </Dialog>

                  {saving && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10,
                        cursor: 'not-allowed',
                      }}
                    >
                      <Spinner size="60" value={null} />
                    </div>
                  )}
                  <div style={{ maxWidth: '800px' }}>
                    {/* TREE VIEW */}
                    <DndProvider backend={Backend}>
                      <MenuItemGroups
                        menuItemsGroups={menus}
                        openMenuGroups={
                          selectedMenuItemId
                            ? currentMenuGroups
                            : openMenuGroups
                        }
                        toggleOpenOrClosed={toggleOpenOrClosed}
                        queryId={queryId}
                        depth={0}
                        itemType={ItemTypes.MENU}
                        parentMenuId={null}
                        path={[]}
                        outlet={outlet}
                        outletsSoldOut={outletsSoldOut}
                        hiddenMenuItemGroupIds={hiddenMenuItemGroupIds}
                        hiddenMenuItemIds={hiddenMenuItemIds}
                        outletId={outletId}
                      />
                    </DndProvider>
                  </div>
                </div>
              </Fragment>
            ) : (
              <NoParentMenus />
            )}
          </Card>
        ) : (
          <NonIdealState
            icon={<Spinner size={60} value={null} />}
            title="Loading menu tree"
            description="Please wait..."
          />
        )}
      </Fragment>
    </MenuListContext.Provider>
  )
}

const MutationProvider = ({ match, outlet, data, queryId, ...rest }) => {
  const [saving, setSaving] = useState(false)

  const onError = debounce(error => {
    setSaving(false)
    defaultErrorHandler(error)
  }, 200)

  const sharedProps = {
    onError,
  }

  return (
    <Mutation mutation={EDIT_MENUS_ORDER} {...sharedProps}>
      {editMenusOrder => (
        <Mutation mutation={EDIT_MENU_ITEMS_GROUP_PARENT_ID} {...sharedProps}>
          {editMenuItemsGroupParentId => (
            <Mutation mutation={EDIT_MENU_ITEMS_ORDER} {...sharedProps}>
              {editMenuItemsOrder => (
                <Mutation mutation={EDIT_MENU_ITEM_PARENT_ID} {...sharedProps}>
                  {editMenuItemParentId => (
                    <div className="bp3-table-frame">
                      <MenuList
                        match={match}
                        outlet={outlet}
                        data={data}
                        editMenusOrder={editMenusOrder}
                        editMenuItemsGroupParentId={editMenuItemsGroupParentId}
                        editMenuItemsOrder={editMenuItemsOrder}
                        editMenuItemParentId={editMenuItemParentId}
                        saving={saving}
                        setSaving={setSaving}
                        queryId={queryId}
                        {...rest}
                      />
                    </div>
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </Mutation>
  )
}

const QueryProvider = ({ match, outlet, queryId, ...rest }) => {
  return (
    <Query
      query={outlet ? GET_OUTLET_MENU_TREE : GET_MENU_TREE}
      // using no-cache so it never uses cache
      // antiCache variable is to trick apollo into always fetching new data
      // when other components tell it to refetch
      variables={{ id: queryId, antiCache: `${+new Date()}` }}
      fetchPolicy="no-cache"
    >
      {({ data = null, refetch, loading }) => {
        if (loading) {
          return (
            <NonIdealState
              icon={<Spinner size={60} value={null} />}
              title="Loading menu tree"
              description="Please wait..."
            />
          )
        }

        return (
          <MutationProvider
            match={match}
            outlet={outlet}
            data={data}
            queryId={queryId}
            refetchMenuList={refetch}
            {...rest}
          />
        )
      }}
    </Query>
  )
}

export default QueryProvider

import React, { useCallback } from 'react'
import { arrayOf, string, func, bool, oneOf } from 'prop-types'
import { Query } from 'react-apollo'
import { Intent, Spinner } from '@blueprintjs/core'

import TypeaheadSearch from '@components/Typeahead/TypeaheadSearch'
import { errorToast } from '@utils/toast'
import GET_OUTLETS_BY_NAME from './queries/getOutletsByName.query'
import GET_OUTLETS_BY_IDS from './queries/getOutletByIds.query'
import { debounce } from 'lodash'
import TypeaheadSearchSingle from '@components/Typeahead/TypeaheadSearchSingle'
import GET_RESTAURANT_OUTLETS_BY_ID from './queries/getRestaurantOutletsById.query'

const OutletTypeahead = ({
  outletIds,
  onChange,
  placeholder,
  showRestaurantName,
  showMarketplaceName,
  alwaysMultiSelect = false,
  outletIdOnRoute = null,
  disabled = false,
  marketplaceId,
  intent,
  restaurantId = null,
}) => {
  const formatOutletData = useCallback(
    ({ id, name, restaurant, marketplace }) => ({
      id,
      name,
      label: [
        showRestaurantName && restaurant && restaurant.name,
        showMarketplaceName && marketplace && marketplace.name,
      ]
        .filter(Boolean)
        .join(' - '),
    }),
    [showMarketplaceName, showRestaurantName]
  )

  const allOutletIds = [...outletIds, outletIdOnRoute].filter(Boolean)

  return (
    // this Query refetches when an outlet has been selected
    <Query
      query={GET_OUTLETS_BY_IDS}
      variables={{
        // add on the outletId from the router
        // this means we can get marketplace data when no outlets are selected
        ids: allOutletIds,
      }}
    >
      {({ data: outerData, error = null }) => {
        if (error) {
          errorToast(error.message)
          return null
        }

        if (!outerData || !outerData.getOutletsByIds) {
          return <Spinner size={20} />
        }

        const { outlets: initialOutlets } = outerData.getOutletsByIds

        const enableMultiSelect =
          alwaysMultiSelect ||
          initialOutlets.filter(
            ({ marketplace }) =>
              marketplace && marketplace.allowMultiOutletTerminal
          ).length > 0

        return (
          // this Query refetches on key press + 250ms
          // when using this on the restaurant we only want to return the outlets for that restaurant id
          <Query
            query={
              restaurantId ? GET_RESTAURANT_OUTLETS_BY_ID : GET_OUTLETS_BY_NAME
            }
            variables={
              restaurantId
                ? { id: restaurantId }
                : {
                    marketplaceIds: marketplaceId ? [marketplaceId] : undefined,
                  }
            }
          >
            {({ data, error = null, loading, refetch }) => {
              const debouncedRefetch = debounce(refetch)

              if (error) {
                errorToast(error.message)
                return null
              }

              // store use previous data for silent fetching
              const usingLiveData = !loading

              if (
                (!restaurantId && !data.getOutlets) ||
                (restaurantId && !data.getRestaurants)
              ) {
                return <Spinner size={20} />
              }

              const outlets = restaurantId
                ? data.getRestaurants.restaurants[0].outlets
                : data.getOutlets.outlets

              return enableMultiSelect ? (
                <TypeaheadSearch
                  initialSelectedItems={initialOutlets.map(formatOutletData)}
                  items={outlets.map(formatOutletData)}
                  selected={outletIds}
                  // onChange will trigger refetch of initialOutlets query
                  usingLiveData={usingLiveData}
                  intent={intent}
                  setSelected={onChange}
                  disabled={disabled}
                  placeholder={placeholder}
                  onQueryChange={searchName => {
                    // trigger refetch of typeahead query
                    debouncedRefetch({ searchName })
                  }}
                />
              ) : (
                <TypeaheadSearchSingle
                  initialSelectedItems={initialOutlets.map(formatOutletData)}
                  items={outlets.map(formatOutletData)}
                  selected={outletIds}
                  setSelected={onChange}
                  usingLiveData={usingLiveData}
                  onQueryChange={searchName => {
                    // trigger refetch of typeahead query
                    debouncedRefetch({ searchName })
                  }}
                  placeholder="Search..."
                  noResults="No matches"
                  disabled={disabled}
                />
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

OutletTypeahead.propTypes = {
  outletId: arrayOf(string),
  showRestaurantName: bool,
  isOnlineOverride: bool,
  placeholder: string,
  onChange: func,
  marketplaceId: string,
  intent: oneOf([
    Intent.NONE,
    Intent.PRIMARY,
    Intent.SUCCESS,
    Intent.WARNING,
    Intent.DANGER,
  ]),
}

export default OutletTypeahead

import find from 'lodash/find'
import React, { Fragment } from 'react'
import { getNames, getCode } from 'country-list'
import {
  map,
  replace,
  omit,
  isEmpty,
  isObject,
  each,
  filter,
  startCase,
  toLower,
} from 'lodash'
import shortid from 'shortid'
import moment from 'moment'
import { Intent } from '@blueprintjs/core'

export const selectionUpdate = data => {
  // data.currentTarget which blueprint uses does not give access to label value. This loops through options and finds the one matching the value.

  const options = data.currentTarget.childNodes
  const selection = find(
    options,
    option => option.value === data.currentTarget.value
  )

  return { label: selection.label, value: data.currentTarget.value }
}

export function getInvoiceIntent(status) {
  switch (status) {
    case 'paid':
      return Intent.SUCCESS
    case 'open':
      return Intent.WARNING
    default:
      return Intent.NONE
  }
}

// List from https://stripe.com/global
const STRIPE_SUPPORTED_COUNTRIES = [
  'AU', // Australia
  'AT', // Austria
  'BE', // Belgium
  'BR', // Brazil
  'BG', // Bulgaria
  'CA', // Canada
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HK', // Hong Kong
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'JP', // Japan
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'MX', // Mexico
  'NL', // Netherlands
  'NZ', // New Zealand
  'NO', // Norway
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SG', // Singapore
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
  'CH', // Switzerland
  'GB', // United Kingdom
  'US', // United States
]

export const countryList = () => {
  const countries = getNames()
  return countries
    .filter(country => STRIPE_SUPPORTED_COUNTRIES.includes(getCode(country)))
    .map(country => ({
      value: getCode(country),
      label: country,
    }))
}

export const formatAddressObj = addressObject => {
  const lines = Object.values(omit(addressObject, ['__typename', 'id']))
  return (
    <Fragment>
      {lines.map(
        (line, index) =>
          !isEmpty(line) && (
            <Fragment key={index}>
              {isObject(line) ? line.name : line}
              {lines.length - 1 !== index && (
                <Fragment>
                  ,<br />
                </Fragment>
              )}
            </Fragment>
          )
      )}
    </Fragment>
  )
}

export const formatAddressObjToString = addressObject => {
  const lines = Object.values(
    omit(addressObject, ['__typename', 'id', 'country'])
  )
  return lines
    .filter(line => !isEmpty(line))
    .map((line, index, arr) => (index === arr.length - 1 ? line : `${line}, `))
    .join('')
}

export const formatOpenTimes = openingTimes => {
  const fullWeek = [1, 2, 3, 4, 5, 6, 7]
  let formattedTimes = []

  const createDateRow = ({ day, times }) => {
    if (!times.start || !times.end) return false

    const timesLbl = `${times.start.time} - ${times.end.time}`
    return { day: day, timeBracket: timesLbl }
  }

  each(fullWeek, weekDay => {
    const dayName = moment().clone().day(weekDay).format('dddd')

    const openingTimesForThisDay = filter(
      openingTimes,
      times => times.start.day.toString() === weekDay.toString()
    )
    each(openingTimesForThisDay, (day, i) => {
      const row = createDateRow({
        day: i === 0 ? dayName : '',
        times: day,
      })
      formattedTimes.push(row)
    })
  })

  return formattedTimes
}

const PENCE_IN_A_POUND = 100
export const poundsToPence = pounds =>
  (Number(pounds) * PENCE_IN_A_POUND).toFixed(0)

export const penceToPounds = pence =>
  (Number(pence) / PENCE_IN_A_POUND).toFixed(2)

export const numberToPence = num => {
  return Number(Number(num).toFixed(2).replace('.', ''))
}

export const checkForBlankInterval = (
  intervalArray,
  setFieldError,
  push,
  BLANK_INTERVAL,
  fieldName
) => {
  const lastTimeInterval = intervalArray[intervalArray.length - 1]

  const isIncompleteInterval = interval =>
    !interval.start.time ||
    !interval.start.day ||
    !interval.end.time ||
    !interval.end.day

  if (isIncompleteInterval(lastTimeInterval)) {
    setFieldError(`${fieldName}`, 'Please enter a time interval')
  } else {
    push({ ...BLANK_INTERVAL, key: shortid.generate() })
  }
}

export const removeUnderscores = str => replace(str, /_/g, ' ')

export const formatEnum = str => startCase(toLower(removeUnderscores(str)))

export const setKeys = arr =>
  map(arr, obj => {
    obj.key = shortid.generate()
    return obj
  })

export const BLANK_OPENING_TIMES = [
  {
    start: {
      day: 1,
      time: '09:00',
    },
    end: { day: 1, time: '17:00' },
    key: shortid.generate(),
  },
  {
    start: {
      day: 2,
      time: '09:00',
    },
    end: { day: 2, time: '17:00' },
    key: shortid.generate(),
  },
  {
    start: {
      day: 3,
      time: '09:00',
    },
    end: { day: 3, time: '17:00' },
    key: shortid.generate(),
  },
  {
    start: {
      day: 4,
      time: '09:00',
    },
    end: { day: 4, time: '17:00' },
    key: shortid.generate(),
  },
  {
    start: {
      day: 5,
      time: '09:00',
    },
    end: { day: 5, time: '17:00' },
    key: shortid.generate(),
  },
]

import React, { Fragment } from 'react'
import Query from '@components/Query/Query'
import { Button } from '@blueprintjs/core'
import { FieldArray } from 'formik'
import GET_OPTIONS from '@components/Restaurant/Menu/queries/getOptions.query'

import { toggleModal } from '@utils/cacheHelpers'
import OptionSelect from './OptionSelect'
import OptionList from './OptionList'
import OptionItemSecondaryLabel from './OptionItemSecondaryLabel'
import Currency from '../../../Currency/Currency'
import EditOptionModal from '../modals/EditOption.modal'
import { StringParam, useQueryParam } from 'use-query-params'

const MenuItemOptions = ({ menuItemOptions, values, classes, isLoading }) => {
  const [optionId, onChangeOptionId] = useQueryParam('optionId', StringParam)
  return (
    <div className={classes.bottomDetails}>
      <h3>Item Options</h3>
      <Query
        query={GET_OPTIONS}
        variables={{
          restaurantId: values.restaurantId,
        }}
        fetchPolicy="network-only"
        showLoader={false}
      >
        {({ getOptions, getRestaurants }) => {
          if (!getOptions || !getRestaurants) return null

          const [restaurant] = getRestaurants.restaurants
          const restaurantOptions = getOptions.options
          const selectedOption = optionId
            ? restaurantOptions.find(option => option.id === optionId)
            : null
          const optionsForList = menuItemOptions.map(menuItemOption => ({
            id: menuItemOption.id,
            key: menuItemOption.id,
            label: menuItemOption.name,
            hasCaret: true,
            secondaryLabel: (
              <OptionItemSecondaryLabel
                classes={classes}
                menuItemId={values.id}
                optionId={menuItemOption.id}
                optionIds={menuItemOptions.map(({ id }) => id)}
                openEditOptionModal={() => onChangeOptionId(menuItemOption.id)}
              />
            ),
            childNodes: menuItemOption.optionItems.map(item => ({
              id: item.id,
              key: item.id,
              label: item.name,
              secondaryLabel: <Currency amount={item.price} />,
            })),
          }))

          return (
            <Fragment>
              <FieldArray
                name="options"
                render={({ form: { values: menuItem }, push }) => (
                  <Fragment>
                    <OptionList
                      options={optionsForList}
                      menuItemId={menuItem.id}
                      restaurantId={menuItem.restaurantId}
                    />
                    <OptionSelect
                      values={menuItem}
                      push={push}
                      menuItemId={menuItem.id}
                      selectedOptions={menuItemOptions || []}
                      optionList={
                        // only show options not already selected
                        restaurantOptions.filter(
                          option =>
                            !(menuItemOptions || []).some(
                              menuItemOption => menuItemOption.id === option.id
                            )
                        )
                      }
                    />
                  </Fragment>
                )}
              />
              {selectedOption && (
                <EditOptionModal
                  option={selectedOption}
                  restaurant={restaurant}
                  menuItemId={values.id}
                  isOpen={selectedOption !== null}
                  onClose={() => {
                    onChangeOptionId(undefined)
                  }}
                />
              )}
            </Fragment>
          )
        }}
      </Query>
      <Button
        disabled={isLoading}
        text="Create New Option"
        minimal={true}
        icon="add-to-artifact"
        onClick={() => toggleModal({ optionModal: true })}
      />
    </div>
  )
}
export default MenuItemOptions

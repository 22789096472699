import React from 'react'

import { string, bool } from 'prop-types'
import { Menu, MenuItem } from '@blueprintjs/core'
import { Mutation } from 'react-apollo'
import CHANGE_MENU_VISIBILITY from '@components/Restaurant/Menu/mutations/changeMenuVisibility.mutation'
import { errorToast, successToast } from '@utils/toast'

const OutletSubMenu = ({ id, outletId, hidden }) => (
  <Menu>
    <Mutation
      mutation={CHANGE_MENU_VISIBILITY}
      onCompleted={({ changeMenuVisibility }) => {
        changeMenuVisibility
          ? successToast(`Successfully updated menu`)
          : errorToast('Something went wrong')
      }}
    >
      {changeMenuVisibility => {
        return (
          <MenuItem
            text={`${hidden ? 'Show' : 'Hide'} menu`}
            icon={hidden ? 'eye-open' : 'eye-off'}
            onClick={() => {
              changeMenuVisibility({
                variables: {
                  id,
                  outletId,
                  hidden: !hidden,
                },
              })
            }}
          ></MenuItem>
        )
      }}
    </Mutation>
  </Menu>
)

OutletSubMenu.propTypes = {
  id: string,
  outletId: string,
  hidden: bool,
}

export default OutletSubMenu

import gql from 'graphql-tag'

const MENU_QUERIES = gql`
  {
    subMenuModal @client
    itemModal @client
    itemMenuOpen @client
    menuId @client
    itemDetailsOpen @client
    itemId @client
    optionModal @client
    optionId @client
    subMenuId @client
    subSubMenuId @client
    paneNumber @client
    parentMenuId @client
    itemQueryId @client
    editedMenu @client
    parentMenuModal @client
    editMenuModal @client
    openingTimes @client
    soldOutUntilItems @client
    nextOpen @client
  }
`

export default MENU_QUERIES

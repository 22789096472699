import React, { useState, useContext, useEffect } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { Route, Switch, Redirect } from 'react-router-dom'
import { locationType, matchType } from '@utils/types'
import Query from '@components/Query/Query'
import { Mutation } from 'react-apollo'
import GET_PLATFORM from './queries/getPlatform.query'
import EDIT_PLATFORM from './mutations/editPlatform.mutation'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import split from 'lodash/split'
import FinancialSettings from '@components/Platform/FinancialSettings/FinancialSettings'
import GeneralSettings from '@components/Platform/GeneralSettings/GeneralSettings'
import CategoriesWrapper from '@components/Platform/Categories/CategoriesWrapper'
import { successToast } from '@utils/toast'
import { PlatformContext } from './platform-context'
import TestNotification from '@components/Platform/TestNotification/TestNotification'
import MarketplaceMap from '@components/MarketplaceMap/MarketplaceMap'
import { StringParam, useQueryParam } from 'use-query-params'
import { PlatformIntegrations } from './Intergrations/Intergrations'

const Platform = ({ match, location, product }) => {
  const [editCategoryId, onChangeEditCategoryId] = useQueryParam(
    'editCategoryId',
    StringParam
  )
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false)
  const { configurePageLayout } = useContext(PageLayoutContext)

  const tabButton = pathname => {
    const path = split(pathname, '/')[2]
    let button
    switch (path) {
      case 'categories':
        button = {
          text: 'Add Category',
          onClick: () => setAddCategoryModalOpen(true),
        }
        break
      default:
        button = null
    }

    return button
  }

  useEffect(() => {
    configurePageLayout({
      product,
      tabs: [
        { to: '/platform/general', name: 'General' },
        { to: '/platform/financial', name: 'Connect' },
        { to: '/platform/categories', name: 'Categories' },
        { to: '/platform/test-notification', name: 'Test Notification' },
        { to: '/platform/map', name: 'Map' },
        { to: '/platform/integrations', name: 'Integrations' },
      ],
      button: tabButton(location.pathname),
    })
  }, [configurePageLayout, product, location])

  return (
    <PlatformContext.Provider
      value={{
        editCategoryId,
        onChangeEditCategoryId,
        addCategoryModalOpen,
        setAddCategoryModalOpen,
      }}
    >
      <Query query={GET_PLATFORM} loaderTitle="Loading Platform">
        {({ getPlatform }) => (
          <Mutation
            mutation={EDIT_PLATFORM}
            onError={defaultErrorHandler}
            onCompleted={({ editPlatform }) =>
              successToast(
                editPlatform.message || 'Platform successfully edited'
              )
            }
            refetchQueries={[{ query: GET_PLATFORM }]}
            awaitRefetchQueries={true}
          >
            {editPlatform => (
              <Switch>
                <Redirect from="/platform" to="/platform/general" exact />
                <Route
                  path={`${match.path}/general`}
                  render={() => (
                    <GeneralSettings
                      platform={getPlatform.platform}
                      editPlatform={editPlatform}
                    />
                  )}
                />

                <Route
                  path={`${match.path}/financial`}
                  render={() => (
                    <FinancialSettings
                      platform={getPlatform.platform}
                      editPlatform={editPlatform}
                    />
                  )}
                />
                <Route path={`${match.path}/map`} render={MarketplaceMap} />
                <Route
                  path={`${match.path}/integrations`}
                  render={() => (
                    <PlatformIntegrations
                      platform={getPlatform.platform}
                      editPlatform={editPlatform}
                    />
                  )}
                />

                <Route
                  path={`${match.path}/categories`}
                  render={() => <CategoriesWrapper />}
                />

                <Route
                  path={`${match.path}/test-notification`}
                  render={() => <TestNotification />}
                />
              </Switch>
            )}
          </Mutation>
        )}
      </Query>
    </PlatformContext.Provider>
  )
}

Platform.propTypes = {
  match: matchType,
  location: locationType,
}

export default Platform

import gql from 'graphql-tag'

const GET_PLATFORM_ID = gql`
  {
    getPlatform {
      platform {
        id
        name
      }
    }
  }
`

export default GET_PLATFORM_ID

import React, { Fragment } from 'react'
import { Select } from '@blueprintjs/select'
import { MenuItem, Button, NonIdealState } from '@blueprintjs/core'
import join from 'lodash/join'
import findIndex from 'lodash/findIndex'
import { func, object, array, string } from 'prop-types'
import injectSheet from 'react-jss'
import { Mutation } from 'react-apollo'
import ADD_OPTION_TO_MENU_ITEM from '../mutations/addOptionToMenuItem'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { GET_ITEM_OPTIONS } from '../queries/getItemDetails.query'

const itemRenderer = (option, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null
  }

  const optionList = option.optionItems.map(item => item.name)
  return (
    <MenuItem
      key={option.id}
      onClick={handleClick}
      multiline={true}
      text={
        <Fragment>
          <strong>{option.name}</strong>
          <br />
          {join(optionList, ', ')}
        </Fragment>
      }
    />
  )
}

const itemPredicate = (query, option) => {
  const match = option.name.toLowerCase().includes(query.toLowerCase())
  const optionItems = option.optionItems.map(item => item.name.toLowerCase())
  const matchOptionItems = optionItems.join(' ').includes(query.toLowerCase())
  if (query.length < 1 || match || matchOptionItems) {
    return true
  }
  return false
}

const addItem = (
  option,
  values,
  push,
  addOptionToMenuItem,
  menuItemId,
  selectedOptions
) => {
  if (findIndex(values.options, option)) {
    push(option)
    const optionIds = selectedOptions.map(({ id }) => id)
    addOptionToMenuItem({
      variables: { id: menuItemId, optionIds: [...optionIds, option.id] },
    })
  } else {
    return
  }
}

const OptionSelect = ({
  values,
  push,
  optionList,
  classes,
  menuItemId,
  selectedOptions,
}) => (
  <Mutation
    mutation={ADD_OPTION_TO_MENU_ITEM}
    onError={defaultErrorHandler}
    onCompleted={({ addOptionToMenuItem }) => {
      successToast(addOptionToMenuItem.message)
    }}
    refetchQueries={[
      {
        query: GET_ITEM_OPTIONS,
        variables: { id: menuItemId },
      },
    ]}
  >
    {addOptionToMenuItem => (
      <Select
        id="options"
        items={optionList}
        itemRenderer={itemRenderer}
        itemPredicate={itemPredicate}
        filterable
        onItemSelect={option => {
          addItem(
            option,
            values,
            push,
            addOptionToMenuItem,
            menuItemId,
            selectedOptions
          )
        }}
        noResults={
          <NonIdealState
            title="No available options"
            description="Please add an option to choose from."
          />
        }
        popoverProps={{ usePortal: false, popoverClassName: classes.popover }}
        resetOnClose
        resetOnQuery
        resetOnSelect
      >
        <Button text="Add Option" icon="add" />
      </Select>
    )}
  </Mutation>
)

OptionSelect.propTypes = {
  values: object.isRequired,
  push: func.isRequired,
  optionList: array.isRequired,
  menuItemId: string,
}

const styles = () => ({
  popover: {
    '& .bp3-menu': {
      maxHeight: '80vh',
      overflowY: 'auto',
    },
  },
})

export default injectSheet(styles)(OptionSelect)

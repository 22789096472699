import React, { useCallback, useRef } from 'react'
import { Query } from 'react-apollo'
import GET_SEGMENTS from '../queries/getSegmentsForMarketplace.query'
import TypeaheadSearchSingle from '@components/Typeahead/TypeaheadSearchSingle'
import { Spinner } from '@blueprintjs/core'
import { errorToast } from '@utils/toast'
import { debounce } from 'lodash'
import GET_SEGMENT from '../queries/getSegment.query'

const formatSegmentData = ({ name, id, estimate }) => ({
  id,
  name,
  label: `${estimate} Endpoints`,
})

const SegmentTypeahead = ({ segment, onChange, marketplaceId, disabled }) => {
  const refetchRef = useRef(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(
    debounce(arg => {
      if (refetchRef.current) {
        refetchRef.current(arg)
      }
    }, 300),
    []
  )

  return (
    <Query
      query={GET_SEGMENT}
      variables={{ marketplaceId, segmentId: segment }}
    >
      {({ data: outerData, loading }) => {
        if (loading) {
          return <Spinner size={20} />
        }

        const initialSegment =
          outerData && outerData.getSegment && outerData.getSegment.segment
            ? [formatSegmentData(outerData.getSegment.segment)]
            : []

        return (
          <Query
            query={GET_SEGMENTS}
            variables={{ marketplaceId, first: 10, skip: 0 }}
          >
            {({ data, error = null, loading, refetch }) => {
              refetchRef.current = refetch

              if (error) {
                errorToast(error.message)
                return null
              }

              const usingLiveData = !loading

              // dont use loading state for this spinner as we only want to show on initial load
              if (!data || !data.getSegments) {
                return <Spinner size={20} />
              }

              const formattedSegments =
                data.getSegments.segments.map(formatSegmentData)

              return (
                <TypeaheadSearchSingle
                  initialSelectedItems={initialSegment}
                  items={formattedSegments}
                  selected={[segment]}
                  setSelected={arr => onChange(arr[0])}
                  usingLiveData={usingLiveData}
                  onQueryChange={nameContains =>
                    debouncedRefetch({ nameContains })
                  }
                  placeholder="Search for a segment..."
                  noResults="No matches"
                  disabled={disabled}
                />
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

export default SegmentTypeahead

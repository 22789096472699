import React, { Fragment } from 'react'
import {
  InputGroup,
  FormGroup,
  Button,
  Intent,
  HTMLSelect,
  Switch,
} from '@blueprintjs/core'
import { func, object, bool, string } from 'prop-types'
import { Mutation } from 'react-apollo'

import DELETE_TERMINAL from './mutations/deleteTerminal.mutation'
import GET_OUTLET_TERMINALS from '@components/Outlet/Terminals/queries/getTerminals.query'
import { canPerformAction, isAtLeastMarketplaceOwner } from '@stores/userStore'
import { toggleModal } from '@utils/cacheHelpers'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import { successToast } from '@utils/toast'
import TerminalIcon from '../TerminalIcon/TerminalIcon'
import { withRouter } from 'react-router'
import OutletTypeahead from '@components/OutletTypeahead/OutletTypeahead'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import CopyText from '@components/CopyText/CopyText'
import { useQueryParam, StringParam } from 'use-query-params'

const Form = ({
  handleSubmit,
  handleChange,
  errors,
  values,
  setFieldValue,
  edit,
  classes,
  match,
}) => {
  const onChangeViewTerminal = useQueryParam('viewTerminal', StringParam)[1]
  return (
    <form className="bp3-drawer-form">
      <div className={'bp3-drawer-content'}>
        {edit && <TerminalIcon serial={values.serialNumber} />}

        <FormGroup
          label={values.outletIds.length < 2 ? 'Outlet' : 'Outlets'}
          helperText={errors.outletIds}
          intent={errors.outletIds ? Intent.DANGER : Intent.NONE}
        >
          <OutletTypeahead
            outletIds={values.outletIds}
            onChange={val => setFieldValue('outletIds', val)}
            showRestaurantName
            showMarketplaceName
            disabled={false}
          />
        </FormGroup>

        <FormGroup
          label="Friendly Name"
          labelFor="friendlyName"
          helperText={
            errors.friendlyName
              ? errors.friendlyName
              : "Description eg. 'Kitchen'"
          }
          intent={errors.friendlyName ? Intent.DANGER : Intent.NONE}
        >
          <InputGroup
            type="text"
            id="friendlyName"
            name="friendlyName"
            value={values.friendlyName}
            onChange={handleChange}
            intent={errors.friendlyName ? Intent.DANGER : Intent.NONE}
          />
        </FormGroup>

        <FormGroup
          label="Serial Number"
          labelFor="serialNumber"
          helperText={
            errors.serialNumber
              ? errors.serialNumber
              : 'This can be found on the bottom right of the login screen or in POS Steward.'
          }
          intent={errors.serialNumber ? Intent.DANGER : Intent.NONE}
        >
          {edit ? (
            <CopyText mono text={values.serialNumber} />
          ) : (
            <InputGroup
              disabled={edit}
              type="text"
              id="serialNumber"
              name="serialNumber"
              intent={errors.serialNumber ? Intent.DANGER : Intent.NONE}
              value={values.serialNumber}
              onChange={handleChange}
            />
          )}
        </FormGroup>

        <FormGroup
          label="Order Alert"
          labelFor="alarm"
          helperText={
            'Audio notification the terminal makes when receiving a new order.'
          }
        >
          <HTMLSelect
            id="alarm"
            name="alarm"
            options={[
              'SILENT',
              'ALARM1',
              'ALARM2',
              'ICECREAM',
              'BELLS1',
              'BELLS2',
            ]}
            fill={true}
            onChange={handleChange}
            value={values.alarm || 'ALARM1'}
          />
        </FormGroup>

        {edit ? (
          <Fragment>
            <FormGroup labelFor="slave" label="Settings">
              <Switch
                id="slave"
                name="slave"
                labelElement={
                  <Fragment>
                    Slave <br />
                    <span className="bp3-text-small bp3-text-muted">
                      A slave terminal won't accept orders, it can update order
                      status and print copies manually.
                    </span>
                  </Fragment>
                }
                checked={values.slave}
                alignIndicator="left"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup labelFor="editableMessages">
              <Switch
                id="editableMessages"
                name="editableMessages"
                labelElement={
                  <Fragment>
                    Message Editing <br />
                    <span className="bp3-text-small bp3-text-muted">
                      Allow this terminal to edit rejection and cancellation
                      messages to be sent directly to the customer.
                    </span>
                  </Fragment>
                }
                checked={values.editableMessages}
                alignIndicator="left"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup labelFor="receiptGroupItems">
              <Switch
                id="receiptGroupItems"
                name="receiptGroupItems"
                labelElement={
                  <Fragment>
                    Group Items on Receipt <br />
                    <span className="bp3-text-small bp3-text-muted">
                      Allow this terminal to print receipts with items grouped
                      by the menu.
                    </span>
                  </Fragment>
                }
                checked={values.receiptGroupItems}
                alignIndicator="left"
                onChange={handleChange}
              />
            </FormGroup>
          </Fragment>
        ) : (
          <FormGroup
            label="PIN"
            labelFor="pin"
            labelInfo="(4 digits long)"
            helperText={errors.pin ? errors.pin : ''}
            intent={Intent.DANGER}
          >
            <InputGroup
              type="text"
              id="pin"
              name="pin"
              value={values.pin}
              intent={errors.pin ? Intent.DANGER : Intent.NONE}
              onChange={handleChange}
            />
          </FormGroup>
        )}
      </div>
      <div className="bp3-drawer-footer-actions">
        {canPerformAction('resetTerminalPin') && edit && (
          <Button
            intent={Intent.PRIMARY}
            minimal
            disabled={!isAtLeastMarketplaceOwner()}
            text="Reset PIN"
            className={classes.leftFooterButton}
            onClick={() => toggleModal({ resetTerminalPinModal: true })}
          />
        )}
        {edit && (
          <Mutation
            mutation={DELETE_TERMINAL}
            onError={defaultErrorHandler}
            onCompleted={({ deleteTerminal }) => {
              onChangeViewTerminal(undefined)
              successToast(deleteTerminal.message)
            }}
            refetchQueries={
              match.params.outlet
                ? [
                    {
                      query: GET_OUTLET_TERMINALS,
                      variables: { outletId: match.params.outlet },
                    },
                  ]
                : []
            }
          >
            {deleteTerminal => (
              <ConfirmationPopover
                remove={() =>
                  deleteTerminal({
                    variables: { id: values.id },
                  })
                }
                confirmationText="Are you sure you want to delete this terminal?"
              >
                <Button
                  className={classes.leftFooterButton}
                  text="Delete"
                  type="button"
                  intent={Intent.DANGER}
                  minimal
                />
              </ConfirmationPopover>
            )}
          </Mutation>
        )}
        &nbsp;
        <Button text="Save" onClick={handleSubmit} />
      </div>
    </form>
  )
}

Form.propTypes = {
  handleSubmit: func,
  handleChange: func,
  errors: object,
  values: object,
  edit: bool,
  classes: object,
  outlet: string,
}

export default withRouter(Form)

import { Button } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import { successToast } from '../../utils/toast'
import DELETE_PAYMENT_METHOD from './mutations/deleteBillingPaymentMethod.mutation'
import GET_MARKETPLACE_BILLING from '../Marketplace/Billing/queries/getMarketplaceBilling.query'
import GET_PARTNER_BILLING from '../Partner/Billing/queries/getPartnerBilling.query'
import { object, string } from 'prop-types'

const DeletePaymentMethodButton = ({ id, level, paymentMethodId, style }) => {
  const [loading, setLoading] = useState(false)

  return (
    <Mutation
      mutation={DELETE_PAYMENT_METHOD}
      onCompleted={() => {
        successToast('Payment method removed')
      }}
      onError={defaultErrorHandler}
      refetchQueries={[
        {
          query:
            level === 'MARKETPLACE'
              ? GET_MARKETPLACE_BILLING
              : GET_PARTNER_BILLING,
          variables: { id },
        },
      ]}
    >
      {deleteBillingPaymentMethod => (
        <Button
          loading={loading}
          style={style}
          minimal
          rightIcon="delete"
          onClick={() => {
            setLoading(true)
            deleteBillingPaymentMethod({
              variables: {
                id,
                level,
                paymentMethodId,
              },
            })
          }}
        />
      )}
    </Mutation>
  )
}

DeletePaymentMethodButton.propTypes = {
  id: string,
  level: string,
  marketplaceId: string,
  paymentMethodId: string,
  style: object,
}

export default DeletePaymentMethodButton

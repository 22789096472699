import React from 'react'
import cx from 'classnames'
import injectSheet from 'react-jss'
import { startCase } from 'lodash'
import { object, string, bool } from 'prop-types'
import { Breadcrumb, Classes } from '@blueprintjs/core'
import { withRouter } from 'react-router-dom'

const styles = ({ colors }) => ({
  breadcrumb: {
    color: `${colors.headerTextColor}!important`,
  },
})

const Crumb = ({ classes, collapsed, text, link, history }) => {
  const href = link ? link : `/${text}`
  return (
    <li>
      <Breadcrumb
        className={cx(
          collapsed ? Classes.BREADCRUMBS_COLLAPSED : classes.breadcrumb
        )}
        text={!collapsed && startCase(text)}
        onClick={() => {
          history.push(href)
        }}
      />
    </li>
  )
}

Crumb.propTypes = {
  classes: object,
  collapsed: bool,
  text: string,
  link: string,
}

export default injectSheet(styles)(withRouter(Crumb))

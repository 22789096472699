import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
  Card,
  Drawer,
  HTMLTable,
  NonIdealState,
  Button,
  Icon,
  ButtonGroup,
  ControlGroup,
} from '@blueprintjs/core'
import { first } from 'lodash'
import { StringParam, useQueryParam } from 'use-query-params'

import GET_MARKETPLACE_APPS_BY_PARTNER_ID from './queries/getMarketplaceAppsByPartnerId.query'
import AppInfoBar from './AppInfoBar'
import Query from '@components/Query/Query'
import ls from '@utils/localStorage'
import Apps from './Apps'
import appPageContext from './appPageContext'
import FilterRow from '@components/FilterRow/FilterRow'
import { isPlatformLevelUser } from '@stores/userStore'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import GET_PARTNER from '@components/Partner/Details/queries/getPartner.query'
import { MinVersionOverrideModal } from './MinVersionOverrideModal'
import { MarketplaceFilter, Pager } from '@components/Toolbar'
import { useRoleAwareBusinessFilterQueryParams } from '../Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'
import { useSearchQueryParam } from '../Toolbar/Search/useSearchQueryParam'

const DEFAULT_RECORDS_PER_PAGE = 50
const DEFAULT_PAGINATION_STATE = {
  total: 0,
  skip: 0,
  first: DEFAULT_RECORDS_PER_PAGE,
  last: null,
  defaultNmbRecords: DEFAULT_RECORDS_PER_PAGE,
  outcomeLength: null,
  navigationDisabled: false,
}

const ListApps = ({ match }) => {
  const [appId, onChangeAppId] = useQueryParam('appId', StringParam)

  const { marketplaceIds, resetMarketplaceFilters } =
    useRoleAwareBusinessFilterQueryParams()

  const {
    setAppId,
    appId: appIdFromContext,
    setAppModalOpen,
    setMarketplaceId,
  } = useContext(appPageContext)

  const [showOverrideModal, setShowOverrideModal] = useState(false)

  const [marketplacePagination, setMarketplacePagination] = useState(
    DEFAULT_PAGINATION_STATE
  )

  const { searchValue, resetSearch } = useSearchQueryParam()

  useEffect(() => {
    setMarketplacePagination(DEFAULT_PAGINATION_STATE)
  }, [searchValue, setMarketplacePagination])

  // TODO: It's silly to store the appId in the URL and in the context. We should only store it in the URL. But that's too big of a refactor for now.
  // So if you're making big changes to this, please consider removing the appId from the context.
  useEffect(() => {
    if (appId !== appIdFromContext) {
      setAppId(appId)
    }
  }, [appId, appIdFromContext, setAppId])

  const limitNext = (currentCursor, amount, limit) => {
    let skip = parseInt(currentCursor) + parseInt(amount)
    return limit < marketplacePagination.defaultNmbRecords
      ? currentCursor
      : skip
  }

  const limitPrevious = (currentCursor, amount) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const goToNext = (e, limit) => {
    e.preventDefault()
    if (
      marketplacePagination.skip + marketplacePagination.first <
      marketplacePagination.total
    ) {
      setMarketplacePagination({
        ...marketplacePagination,
        skip: limitNext(
          marketplacePagination.skip,
          marketplacePagination.first,
          limit
        ),
        first: DEFAULT_RECORDS_PER_PAGE,
        last: null,
      })
    }
  }

  const goToPrevious = e => {
    e.preventDefault()
    setMarketplacePagination({
      ...marketplacePagination,
      skip: limitPrevious(
        marketplacePagination.skip,
        marketplacePagination.first
      ),
      first: DEFAULT_RECORDS_PER_PAGE,
      last: null,
    })
  }

  const clearAllFilters = () => {
    resetSearch()
    resetMarketplaceFilters()
  }

  const setFilterState = (count, totalCount) => {
    setMarketplacePagination({
      ...marketplacePagination,
      total: totalCount,
      outcomeLength: count,
    })
  }

  return (
    <Fragment>
      <Query
        query={GET_MARKETPLACE_APPS_BY_PARTNER_ID}
        variables={{
          partnerId: match.params.partner,
          skip: marketplacePagination.skip,
          first: marketplacePagination.first,
        }}
        fetchPolicy={'network-only'}
        loaderTitle={'Loading Apps'}
        onCompleted={data => {
          if (data && data.getMarketplaces) {
            setFilterState(
              data.getMarketplaces.count,
              data.getMarketplaces.totalCount
            )
          }
        }}
      >
        {data => {
          const {
            getMarketplaces: { regions },
          } = data

          return regions.length ? (
            <div className="bp3-table-frame">
              <div className="bp3-table-container bp3-scrollable">
                <Card className={'bp3-nopad'}>
                  {isPlatformLevelUser && (
                    <Query
                      query={GET_PARTNER}
                      onError={defaultErrorHandler}
                      variables={{
                        id: match.params.partner,
                      }}
                    >
                      {({ getPartners: { partners } }) => {
                        const partner = first(partners)
                        if (!partner) {
                          return 'Unable to find partner'
                        }

                        const goToPage = value => {
                          const numberToSkip =
                            DEFAULT_RECORDS_PER_PAGE * (value - 1)
                          setMarketplacePagination({
                            ...marketplacePagination,
                            skip: numberToSkip,
                            first: DEFAULT_RECORDS_PER_PAGE,
                            last: null,
                          })
                        }

                        return (
                          <div>
                            <FilterRow>
                              <ButtonGroup>
                                <ControlGroup>
                                  <MarketplaceFilter />
                                  <Button
                                    icon="filter-remove"
                                    onClick={clearAllFilters}
                                    disabled={
                                      !marketplaceIds.length && !searchValue
                                    }
                                  />
                                </ControlGroup>
                              </ButtonGroup>

                              <div style={{ display: 'flex', gap: '10px' }}>
                                {isPlatformLevelUser() && (
                                  <Button
                                    icon="changes"
                                    onClick={() => {
                                      setShowOverrideModal(true)
                                    }}
                                  >
                                    Set Minimum Version
                                  </Button>
                                )}

                                <Pager
                                  goToPrevious={goToPrevious}
                                  goToNext={e => goToNext(e)}
                                  goToPage={goToPage}
                                  defaultNmbRecords={DEFAULT_RECORDS_PER_PAGE}
                                  skip={marketplacePagination.skip}
                                  total={marketplacePagination.total}
                                  outcomeLength={
                                    marketplacePagination.outcomeLength
                                  }
                                  totalCount={marketplacePagination.total}
                                  dataName={`Marketplace${
                                    marketplacePagination.total !== 1 ? 's' : ''
                                  }`}
                                />
                              </div>
                            </FilterRow>

                            <MinVersionOverrideModal
                              match={match}
                              partner={partner}
                              isOpen={showOverrideModal}
                              setIsOpen={setShowOverrideModal}
                            />
                          </div>
                        )
                      }}
                    </Query>
                  )}
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        <th>Marketplace</th>
                        <th>Appcenter iOS</th>
                        <th>Apple Store</th>
                        <th>Appcenter Android</th>
                        <th>Google Play Store</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* TODO Return an error from the backend if app is null -> RED-2721 */}
                      {regions
                        .filter(region => region.app)
                        .map(region => {
                          return (
                            <tr key={region.app.id}>
                              <td
                                onClick={() => {
                                  setMarketplaceId(region.id)
                                  onChangeAppId(region.app.id)
                                }}
                                className={`bp3-text-overflow-ellipsis bp3-${
                                  ls.get('darkMode') ? 'dark-' : ''
                                }link-text`}
                              >
                                {region.name}
                              </td>
                              <td>
                                <AppInfoBar
                                  data={region.app.appBuilds}
                                  device="ios"
                                  target="releaseToAppcenter"
                                ></AppInfoBar>
                              </td>
                              <td>
                                <AppInfoBar
                                  data={region.app.appBuilds}
                                  device="ios"
                                  target="releaseToStore"
                                ></AppInfoBar>
                              </td>
                              <td>
                                <AppInfoBar
                                  data={region.app.appBuilds}
                                  device="android"
                                  target="releaseToAppcenter"
                                ></AppInfoBar>
                              </td>
                              <td>
                                <AppInfoBar
                                  data={region.app.appBuilds}
                                  device="android"
                                  target="releaseToStore"
                                ></AppInfoBar>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </HTMLTable>
                </Card>
              </div>
            </div>
          ) : (
            <NonIdealState
              icon="list"
              title="No Apps"
              description="There are no apps for this partner."
            />
          )
        }}
      </Query>
      <Drawer
        title="App Builds"
        isOpen={appId}
        onClose={() => onChangeAppId(null)}
        size={Drawer.SIZE_MEDIUM}
      >
        <FilterRow>
          <Button
            icon={<Icon icon="build" />}
            onClick={() => setAppModalOpen(true)}
          >
            Start Build
          </Button>
        </FilterRow>
        <div
          style={{
            overflowY: 'scroll',
          }}
        >
          <Apps id={appId} />
        </div>
      </Drawer>
    </Fragment>
  )
}

export default ListApps

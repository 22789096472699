import React from 'react'
import { Classes, EditableText } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Mutation } from 'react-apollo'
import { successToast } from '@utils/toast'
import { toggleModal } from '@utils/cacheHelpers'
import { modalType } from '@utils/types'
import { object } from 'prop-types'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import APPROVE_REVIEW from '../mutations/approveReview.mutation'
import REMOVE_REVIEW from '../mutations/removeReview.mutation'
import REPLY from '../mutations/reply.mutation'
import Review from '@components/Review/Review'
import GET_REVIEWS from '@components/Reviews/queries/getReviews.query'
import { canPerformAction } from '@stores/userStore'

const EditReviewModal = ({
  modalOpen,
  modalClose,
  review,
  review: { approved, reply },
  variables,
}) => {
  let replyText = ''
  const canModerate = canPerformAction('moderateReview')

  return (
    <Dialog isOpen={modalOpen} onClose={modalClose} title="Edit Review">
      <div className={Classes.DIALOG_BODY}>
        <Review review={review} shouldDisplayHeader={false} />
        {approved && !reply && (
          <EditableText
            multiline={true}
            minLines={2}
            maxLines={10}
            placeholder="Reply here..."
            onChange={value => (replyText = value)}
          />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className="bp3-dialog-footer-actions">
          {canModerate && (
            <Mutation
              mutation={REMOVE_REVIEW}
              onCompleted={({ removeReview }) => {
                successToast(removeReview.message)
                toggleModal({
                  editReviewModal: false,
                })
              }}
              refetchQueries={[
                {
                  query: GET_REVIEWS,
                  variables,
                },
              ]}
              onError={defaultErrorHandler}
            >
              {removeReview => (
                <button
                  type="button"
                  className="bp3-button bp3-intent-danger"
                  onClick={() => {
                    removeReview({
                      variables: { id: review.id },
                    })
                  }}
                >
                  Delete
                </button>
              )}
            </Mutation>
          )}
          {approved && !reply && (
            <Mutation
              mutation={REPLY}
              onCompleted={({ replyToReview }) => {
                successToast(replyToReview.message)
                toggleModal({
                  editReviewModal: false,
                })
                replyText = ''
              }}
              refetchQueries={[
                {
                  query: GET_REVIEWS,
                  variables,
                },
              ]}
              onError={defaultErrorHandler}
            >
              {replyToReview => (
                <button
                  type="button"
                  className="bp3-button bp3-intent-success"
                  onClick={() => {
                    replyToReview({
                      variables: {
                        id: review.id,
                        reply: replyText,
                      },
                    })
                  }}
                >
                  Reply
                </button>
              )}
            </Mutation>
          )}
          {canModerate && !approved && (
            <Mutation
              mutation={APPROVE_REVIEW}
              onCompleted={({ approveReview }) => {
                successToast(approveReview.message)
                toggleModal({
                  editReviewModal: false,
                })
              }}
              refetchQueries={[
                {
                  query: GET_REVIEWS,
                  variables,
                },
              ]}
              onError={defaultErrorHandler}
            >
              {approveReview => (
                <button
                  type="button"
                  className="bp3-button bp3-intent-primary"
                  onClick={() => {
                    approveReview({
                      variables: { id: review.id },
                    })
                  }}
                >
                  Approve
                </button>
              )}
            </Mutation>
          )}
        </div>
      </div>
    </Dialog>
  )
}

EditReviewModal.propTypes = {
  review: object,
  ...modalType,
}

export default EditReviewModal

import React from 'react'
import GENERATE_PREVIEW_HTML_FOR_EMAIL from '@components/Marketplace/EmailTheme/queries/generatePreviewHtmlForEmail.query'
import { Row, Col } from 'react-flexbox-grid'
import { Spinner, Card, Elevation } from '@blueprintjs/core'
import HTMLEmailPreview from '@components/EmailLivePreview/HTMLEmailPreview'
import GET_EMAIL_THEME from '@components/Marketplace/EmailTheme/queries/getEmailTheme.query'
import InnerFormFragment from '@components/EmailEditor/InnerFormFragment'
import moment from 'moment'
import debouncedPropsHoc from '@utils/debouncedPropsHoc'
import { Query as ApolloQuery } from 'react-apollo'
import Query from '@components/Query/Query'
import { Fragment } from 'react'
import TemplateVariableValidator from './TemplateVariableValidator'

const DebouncedApolloQuery = debouncedPropsHoc(ApolloQuery)

const EmailEditor = ({ marketplaceId, disabled = false, ...formikBag }) => (
  <Query
    query={GET_EMAIL_THEME}
    variables={{ marketplaceId }}
    loaderTitle={'Loading Email Theme'}
  >
    {data => {
      if (!data || !data.getMarketplaces) return <Spinner />
      const {
        emailBackgroundColor,
        emailBodyColor,
        emailButtonColor,
        emailFontColor,
        emailFooter,
        emailFooterColor,
        emailImageUrl,
        emailShowImage,
        brandColor,
        logoImage,
        name,
        cname,
      } = data.getMarketplaces.regions[0]

      const { firstName, lastName, email } = data.getMyDetails.user

      // Build example data that works for all template types
      const marketplace = {
        name,
        cname,
      }

      const customer = {
        firstName,
        lastName,
        email,
        marketplace,
      }

      const contactAddress = {
        firstLine: 'First line of address',
        secondLine: 'Second line of address',
        city: 'City',
        postcode: 'Postcode',
      }

      const outlet = {
        name: 'Outlet Name',
        restaurant: {
          name: 'Business Name',
          contactAddress,
          contactPhone: 'Phone Number',
        },
        contactAddress,
        marketplace,
        contactPhone: 'Phone Number',
      }

      const order = {
        createdAt: moment().format('h:mma [on] MMMM Do'),
        estimatedCompletionTime: moment()
          .add(30, 'minutes')
          .format('h:mma [on] MMMM Do'),
        estimatedDeliveryDate: `${moment()
          .add(1, 'hour')
          .format('MMMM Do h:mma')} - ${moment()
          .add(2, 'hour')
          .format('h:mma')}`,
        orderType: 'delivery',
        dateToShowUser: `${moment()
          .add(1, 'hour')
          .format('MMMM Do h:mma')} - ${moment()
          .add(2, 'hour')
          .format('h:mma')}`,
        orderNumber: '123456-789-987-654',
        grossTotal: '43.21',
        cancellationNotes:
          'We are unfortunately unable to accept your order. If you have paid for your order online we will not be taking any payment.',
        customer,
        outlet,
      }
      const inviteToDiscountExampleData = {
        customerFirstName: firstName,
        customerLastName: lastName,
        businessName: 'Business Name',
        discountPercentage: 10,
        enrolmentKey: 'one-use-key',
        marketplaceCNAME: 'businessWebAddress',
      }

      const exampleData = {
        customer,
        order,
        outlet,
        ...inviteToDiscountExampleData,
      }

      const emailVars = {
        marketplaceId,
        emailBackgroundColor,
        emailBodyColor,
        emailButtonColor: emailButtonColor || brandColor,
        emailFontColor,
        emailFooter,
        emailFooterColor,
        emailImageUrl: emailImageUrl || logoImage,
        emailShowImage,
        content: formikBag.values.emailContent,
      }

      return (
        <Card>
          <Row style={{ paddingBottom: '20px' }}>
            <Col md={6}>
              <InnerFormFragment {...formikBag} disabled={disabled} />
            </Col>
            <Col md={6}>
              <Card
                className="bp3-nopad"
                style={{ overflow: 'hidden' }}
                elevation={Elevation.TWO}
              >
                <Card style={{ marginBottom: 0 }}>
                  To:{' '}
                  <strong className="bp3-skeleton">customer@gmail.com</strong>
                  <br />
                  From:{' '}
                  <strong>
                    {formikBag.values.marketplaceEmail
                      .trim()
                      .replace(/^www\./, '')}
                  </strong>
                  <br />
                  Subject: <strong>{formikBag.values.emailSubject}</strong>
                  <br />
                </Card>

                <DebouncedApolloQuery
                  query={GENERATE_PREVIEW_HTML_FOR_EMAIL}
                  variables={{ ...emailVars }}
                >
                  {({ previousData = null, data: _data }) => {
                    const data = _data.generatePreviewHtmlForEmail
                      ? _data
                      : previousData

                    return !data ? (
                      <Spinner />
                    ) : (
                      <Fragment>
                        <TemplateVariableValidator
                          emailContent={formikBag.values.emailContent}
                        />
                        <HTMLEmailPreview
                          html={data.generatePreviewHtmlForEmail.html}
                          exampleData={exampleData}
                        />
                      </Fragment>
                    )
                  }}
                </DebouncedApolloQuery>
              </Card>
            </Col>
          </Row>
        </Card>
      )
    }}
  </Query>
)

export default EmailEditor

import React, { useEffect, useRef } from 'react'
import * as yup from 'yup'
import { filter, find } from 'lodash'
import { ImportTableRow } from './ImportTableRow'
import { Form, Formik } from 'formik'
import { Button, ControlGroup, HTMLTable } from '@blueprintjs/core'
import { successToast, errorToast } from '@utils/toast'
import { isPeakTime } from '@utils/isPeakTime'

const parseCSVItems = ({ items, fields }) =>
  items.map(item =>
    Object.entries(item).reduce((acc, [key, value]) => {
      if (
        (value === null || value === undefined) &&
        fields[key] &&
        fields[key].defaultValue !== undefined
      ) {
        acc = { ...acc, [key]: fields[key].defaultValue }
      }
      return acc
    }, item)
  )

const generateSchema = importItemSchema => {
  const row = yup.lazy(values => {
    if (values.upload) {
      return yup.object().shape({ importItem: importItemSchema })
    }

    return yup.object()
  })

  return yup.object().shape({
    rows: yup.array().of(row),
  })
}

export function ImportTable({
  fields,
  options = {},
  importData,
  redboxData,
  handleRowSubmission,
  handleClose,
  importItemSchema,
}) {
  const timeoutRef = useRef(null)

  const close = () => {
    timeoutRef.current = setTimeout(handleClose, 1500)
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = null
      }
    }
  })

  const parsedCSVItems = parseCSVItems({ items: importData.data, fields })

  return (
    <Formik
      validationSchema={generateSchema(importItemSchema)}
      validateOnChange
      validateOnMount
      initialValues={{
        rows: parsedCSVItems.map((importItem, index) => {
          const itemData = find(redboxData, {
            id: importItem.id,
          })
          return {
            // This is unique as this only runs once
            UID: index,
            importItem,
            redboxItem: itemData || null,
            upload: true,
            saved: false,
            touched: false,
          }
        }),
      }}
      onSubmit={async (values, actions) => {
        if (isPeakTime()) {
          errorToast('Import is unavailable during peak times')
          return
        }
        actions.setSubmitting(true)

        let successCount = 0
        for (let i = 0; i < values.rows.length; i++) {
          const row = values.rows[i]
          if (!row.upload) {
            continue
          }
          try {
            const savedItem = await handleRowSubmission(row)
            actions.setFieldValue(`rows.${i}`, {
              ...row,
              importItem: {
                ...row.importItem,
                id: savedItem.id,
              },
              saved: true,
              upload: false,
              touched: false,
            })
            successCount = successCount + 1
          } catch (error) {
            // Error will be picked up by default error handler in the mutation setup
          }
        }

        const rowsToSubmit = values.rows.filter(row => row.upload).length
        if (successCount === rowsToSubmit) {
          successToast('Successfully saved')
          close()
        }

        actions.setSubmitting(false)
      }}
    >
      {({ setFieldValue, values, setValues, errors, isSubmitting }) => (
        <Form className={'bp3-drawer-table'}>
          <div className={'bp3-drawer-content'}>
            <HTMLTable bordered={false} interactive={true}>
              <thead>
                <tr>
                  <th colSpan="2">{''}</th>
                  {filter(fields, {
                    visible: true,
                  }).map(field => (
                    <th key={field.id}>{field.label}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {values.rows
                  .reduce((acc, row, index) => {
                    const error = errors.rows && errors.rows[index]
                    const newRow = {
                      row,
                      error,
                      firstError: false,
                    }
                    if (error) {
                      const firstError = acc.find(accRow => accRow.error)
                      if (!firstError) {
                        newRow.firstError = true
                      }
                    }

                    acc = [...acc, newRow]
                    return acc
                  }, [])
                  .map(({ row, error, firstError }, index) => {
                    return (
                      <ImportTableRow
                        key={row.UID}
                        row={row}
                        error={error}
                        firstError={firstError}
                        index={index}
                        setFieldValue={setFieldValue}
                        options={options}
                        fields={fields}
                      />
                    )
                  })}
              </tbody>
            </HTMLTable>
          </div>
          <div className={'bp3-drawer-footer-actions'}>
            <ControlGroup>
              <Button
                text="Select All"
                minimal
                onClick={function selectAll() {
                  const newRows = values.rows.map(value => ({
                    ...value,
                    upload: true,
                  }))
                  setValues({ ...values, rows: newRows })
                }}
                disabled={isSubmitting}
              />
              <Button
                text="Deselect All"
                minimal
                onClick={function deselectAll() {
                  const newRows = values.rows.map(value => ({
                    ...value,
                    upload: false,
                  }))
                  setValues({ ...values, rows: newRows })
                }}
                disabled={isSubmitting}
              />
            </ControlGroup>
            <Button
              text="Import Selected"
              type="submit"
              loading={isSubmitting}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

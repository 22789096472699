import React from 'react'
import NumberFormat from 'react-number-format'
import injectSheet from 'react-jss'
import cx from 'classnames'
import { number, func, object, bool, oneOf } from 'prop-types'
import { styles } from './PercentageInput.styles'
import { Intent } from '@blueprintjs/core'

const IntentToClassMap = {
  [Intent.NONE]: 'bp3-intent-none',
  [Intent.PRIMARY]: 'bp3-intent-primary',
  [Intent.SUCCESS]: 'bp3-intent-success',
  [Intent.WARNING]: 'bp3-intent-warning',
  [Intent.DANGER]: 'bp3-intent-danger',
}

const PercentageInput = ({
  value,
  handleChange,
  fill,
  classes,
  disabled,
  intent,
}) => (
  <div
    className={cx('bp3-input-group', intent ? IntentToClassMap[intent] : '')}
  >
    <NumberFormat
      suffix="%"
      value={value}
      onValueChange={values => handleChange(values)}
      className={cx('bp3-input', { [classes.fill]: fill })}
      allowNegative={false}
      allowEmptyFormatting={true}
      isAllowed={values => {
        if (values.floatValue > 100) {
          return false
        } else if (values.floatValue < 0) {
          return false
        } else {
          return true
        }
      }}
      disabled={disabled}
      decimalScale={2}
    />
  </div>
)

PercentageInput.propTypes = {
  value: number,
  handleChange: func,
  fill: bool,
  classes: object,
  disabled: bool,
  intent: oneOf([
    Intent.NONE,
    Intent.PRIMARY,
    Intent.SUCCESS,
    Intent.WARNING,
    Intent.DANGER,
  ]),
}

export default injectSheet(styles)(PercentageInput)

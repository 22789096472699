import React, { Component } from 'react'
import mapboxgl from 'mapbox-gl'
// eslint-disable-next-line no-undef
const MapboxDraw = require('@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw')
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import { get } from 'lodash'
import * as shortid from 'shortid'

mapboxgl.accessToken = process.env.MAPBOX_KEY

export default class GeoFence extends Component {
  static defaultProps = {
    showDelete: true,
  }

  constructor(props) {
    super(props)

    const geoFence = props.geoFence || {}
    this.state = {
      center: geoFence.center || {
        lng: -1.146159,
        lat: 52.809403,
      },
      zoom: geoFence.zoom || 7,
      coordinates: null,
      type: null,
      geoFence: geoFence || null,
      height: props.height || '100vh',
      width: props.width || '100%',
      disableEdit: props.disableEdit || false,
    }
  }

  updateArea = drawing => {
    this.setState(
      {
        geoFence: {
          coordinates: get(drawing, 'features[0]geometry.coordinates', null),
          type: get(drawing, 'features[0]geometry.type', null),
          center: this.map.transform._center,
          zoom: this.map.transform.tileZoom,
        },
      },
      () => {
        this.props.setFieldValue('geoFence', this.state.geoFence)
        this.props.handleUpdate()
      }
    )
  }

  deleteArea = () => {
    this.setState(
      {
        geoFence: null,
      },
      () => {
        this.props.setFieldValue('geoFence', null)
        this.props.handleUpdate()
      }
    )
  }

  componentDidMount() {
    // create the map
    const { zoom, center } = this.state
    const mapConfig = {
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v10',
      zoom,
      maxZoom: 12,
      attributionControl: false,
    }

    if (center !== null) {
      mapConfig.center = [center.lng, center.lat]
    }

    this.map = new mapboxgl.Map(mapConfig)

    // create the drawing control
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: !this.state.disableEdit,
        trash: this.props.showDelete,
        save: !this.state.disableEdit,
      },
    })

    this.map.addControl(draw, 'top-left')

    if (!this.state.disableEdit) {
      this.map.on('draw.create', this.updateArea)
      this.map.on('draw.update', this.updateArea)
      this.map.on('draw.delete', this.deleteArea)
    }
    this.map.addControl(new mapboxgl.NavigationControl())

    // display incoming geoFence
    const { geoFence } = this.props
    if (geoFence && geoFence.type) {
      const fence = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: geoFence.type,
          coordinates: geoFence.coordinates,
        },
      }

      draw.add(fence)
    }

    // draw incoming features (like deliveryZones, outlets)
    const bounds = new mapboxgl.LngLatBounds()
    const { features } = this.props

    if (features) {
      const featuresToDraw = []

      features.forEach(element => {
        // used for deliveryZones
        if (get(element, 'geoFence')) {
          featuresToDraw.push({
            type: 'Feature',
            geometry: {
              type: element.geoFence.type,
              coordinates: element.geoFence.coordinates,
            },
          })
          //bounds.extend(element.geoFence.coordinates);
        }

        // used for outlets
        if (get(element, 'outletAddress.geo')) {
          featuresToDraw.push({
            type: 'Feature',
            geometry: {
              type: element.outletAddress.geo.type,
              coordinates: element.outletAddress.geo.coordinates,
            },
            properties: {
              name: `${element.name}`,
            },
          })
        }

        // used for outlets
        if (element.__typename === 'Address' && element.geo) {
          featuresToDraw.push({
            type: 'Feature',
            geometry: {
              type: element.geo.type,
              coordinates: element.geo.coordinates,
            },
            properties: {
              name: `${element.firstLine}`,
            },
          })
          bounds.extend(element.geo.coordinates)
        }
      })

      const source = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: featuresToDraw,
        },
      }

      // features can be drawn only after the map loads
      this.map.on('load', () => {
        this.map.addSource(
          'incomingFeatures', // name matters in the following layers
          source
        )
        // draw deliveryZones, marketplace
        this.map.addLayer({
          id: shortid.generate(),
          type: 'fill',
          source: 'incomingFeatures',
          paint: {
            'fill-color': 'blue',
            'fill-opacity': 0.1,
          },
          filter: ['==', '$type', 'Polygon'],
        })

        // draw outlets, orders
        this.map.addLayer({
          id: shortid.generate(),
          type: 'circle',
          source: 'incomingFeatures',
          paint: {
            'circle-radius': 6,
            'circle-color': 'red',
          },
          filter: ['==', '$type', 'Point'],
        })

        if (bounds.du !== false) {
          this.map.fitBounds(bounds)
        }
      })
    }
  } // end componentdidMount

  render() {
    return (
      <div
        style={{
          height: this.state.height,
          width: this.state.width,
        }}
        id="map"
      />
    )
  }
}

import React from 'react'
import { Switch, Redirect } from 'react-router'
import Route from '@ui/Route/Route'

import Query from '@components/Query/Query'
import GET_PINPOINT_MARKETPLACES_SLIM from './queries/getPinpointMarketplacesSlim.query'

import ListMarketplaces from './ListMarketplaces'

import CustomerSegmentsContainer from './Segments/CustomerSegmentsContainer'

import CampaignsContainer from './Campaigns/CampaignsContainer'
import FacebookContainer from './Campaigns/FacebookContainer'
import Dashboard from './Dashboard'

const Marketing = ({ match, product }) => (
  <Query query={GET_PINPOINT_MARKETPLACES_SLIM}>
    {({ getPinpointMarketplaces: { regions } }) => (
      <Switch>
        <Redirect
          from="/marketing"
          to={
            regions.length === 1 && regions[0].id
              ? `/marketing/${regions[0].id}/dashboard`
              : '/marketing/list'
          }
          exact={true}
        />

        <Route
          path={`${match.path}/list`}
          exact={true}
          product={product}
          component={ListMarketplaces}
        />

        <Redirect
          from="/marketing/:id"
          to="/marketing/:id/dashboard"
          exact={true}
        />
        <Route
          path={`${match.path}/:id/segments`}
          exact={true}
          product={product}
          component={CustomerSegmentsContainer}
        />

        <Route
          path={`${match.path}/:id/campaigns`}
          exact={true}
          product={product}
          component={CampaignsContainer}
        />

        <Route
          path={`${match.path}/:id/meta-business`}
          exact={true}
          product={product}
          component={FacebookContainer}
        />

        <Route
          path={`${match.path}/:id/dashboard`}
          exact={true}
          product={product}
          component={Dashboard}
        />
      </Switch>
    )}
  </Query>
)

export default Marketing

import * as yup from 'yup'
import moment from 'moment'

let startDate, endDate // declare here two vars for scope and testing
export const discountShape = {
  name: yup
    .string()
    .required('Discount name is required')
    .min(3, 'Discount name too short'),
  allowedOutletIds: yup.array().when('outletSelected', {
    is: outletSelected => outletSelected === true,
    then: yup.array().min(1, 'At least one outlet needs to be selected'),
  }),
  discountPercentage: yup
    .number()
    .when('discountAmount', {
      is: val => val,
      then: yup
        .number()
        .notRequired()
        .nullable(true)
        .test({
          test: val => !val,
          message:
            "Can't have a flat discount and a percentage one at the same time.",
        }),
      otherwise: yup
        .number()
        .notRequired()
        .min(0, "The discount can't be less than 0%")
        .max(100, "The discount can't be greater than 100%")
        .nullable(true),
    })
    .test({
      name: 'notBothZero',
      test: (value, { parent }) =>
        !(
          (parent.discountAmount === 0 ||
            parent.discountAmount === undefined) &&
          value === 0
        ),
      message: "Percentage discount and fixed discount can't both be zero",
    }),
  discountAmount: yup.number().when('discountPercentage', {
    is: val => val,
    then: yup
      .number()
      .notRequired()
      .test({
        test: val => !val,
        message:
          "Can't have a flat discount and a percentage one at the same time.",
      })
      .test({
        name: 'notBothZero',
        test: (value, { parent }) =>
          !(
            (parent.discountPercentage === 0 ||
              parent.discountPercentage === undefined) &&
            value === 0
          ),
        message: "Percentage discount and fixed discount can't both be zero",
      })
      .min(0, "The discount amount can't be less than 0.")
      .nullable(true),
  }),
  startDate: yup.date().when('endDate', {
    is: val => {
      endDate = val
      return val
    },
    then: yup
      .date()
      .nullable(true)
      .test({
        name: 'Start-yesterday-or-after',
        test: startDate =>
          moment(startDate).isSameOrAfter(moment().subtract(1, 'day'), 'day'),
        message: 'The start date should be more recent than yesterday.',
        exclusive: false,
      })
      .test({
        name: 'Start-before-end',
        test: startDate => moment(startDate).isBefore(moment(endDate)),
        message: 'The start date should be before the end date.',
        params: { endDate },
        exclusive: false,
      })
      .required(
        'Both a startDate and an endDate are required for an interval.'
      ),
    otherwise: yup.date().notRequired().nullable(true),
  }),
  endDate: yup.date().when('startDate', {
    is: val => {
      startDate = val
      return val
    },
    then: yup
      .date()
      .nullable(true)
      .required('Both a startDate and an endDate are required for an interval.')
      .test({
        name: 'End-Date-in-future',
        test: endDate => moment(endDate).isAfter(moment()),
        message: 'The end date should be in the future.',
        exclusive: false,
      })
      .test({
        name: 'End-before-start',
        test: endDate => moment(startDate).isBefore(moment(endDate), 'day'),
        message: 'The start date should be before the end date.',
        params: { startDate },
        exclusive: false,
      }),
    otherwise: yup.date().notRequired().nullable(true),
  }),
  daysOfWeek: yup
    .array()
    .required('At least one day needs to be selected')
    .min(1, 'At least one day needs to be selected')
    .of(yup.number().min(1).max(7)),
  minimumSubtotalGross: yup
    .number()
    .notRequired()
    .min(0, "The minimum can't be less than 0")
    .nullable(true),
}

export const discountValidation = yup.object().shape(discountShape, [
  ['discountAmount', 'discountPercentage'],
  ['startDate', 'endDate'],
])

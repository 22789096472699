import { Dialog } from '@blueprintjs/core'
import { Formik } from 'formik'
import React from 'react'
import { Mutation } from 'react-apollo'
import InnerForm from '../InnerForm'
import BLACKLIST_ADDRESS from '../mutations/BlacklistAddress.mutation'
import GET_BLACKLISTED_ADDRESS_BY_MARKETPLACE_ID from '../queries/MarketplaceBlacklistedAddresses.query'
import blacklistedAddressValidation from '../validation/validation'

const BlacklistAddressModal = ({
  marketplaceId,
  blacklistedAddressModalOpen,
  setBlacklistedAddressModalOpen,
  handleSubmit,
}) => (
  <Dialog
    title="Blacklist Address"
    isOpen={blacklistedAddressModalOpen}
    onClose={() => setBlacklistedAddressModalOpen(false)}
  >
    <Mutation
      mutation={BLACKLIST_ADDRESS}
      refetchQueries={[
        {
          query: GET_BLACKLISTED_ADDRESS_BY_MARKETPLACE_ID,
          variables: {
            marketplaceId,
          },
        },
      ]}
    >
      {addRequest => (
        <Formik
          initialValues={{
            firstLine: '',
            secondLine: '',
            thirdLine: '',
            city: '',
            postcode: '',
            marketplaceId,
            country: '',
          }}
          validationSchema={blacklistedAddressValidation}
          validateOnChange={false}
          onSubmit={vars => handleSubmit(vars, addRequest)}
        >
          {props => (
            <InnerForm
              {...props}
              disabled={false}
              marketplaceId={marketplaceId}
            />
          )}
        </Formik>
      )}
    </Mutation>
  </Dialog>
)

export default BlacklistAddressModal

import React, { Component, Fragment } from 'react'
import { Mutation } from 'react-apollo'
import { first, get } from 'lodash'
import { string, bool, func } from 'prop-types'
import {
  FormGroup,
  TextArea,
  Drawer,
  Button,
  InputGroup,
  DrawerSize,
} from '@blueprintjs/core'

import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { Formik } from 'formik'
import Query from '@components/Query/Query'

import GET_MARKETPLACE_FAQS from './queries/getMarketplaceFAQs.query'
import GET_FAQ from './queries/getFAQ.query'
import EDIT_FAQ from './mutations/editFAQ.mutation'
import ADD_FAQ from './mutations/addFAQ.mutation'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'

class FAQDrawer extends Component {
  static contextType = PageLayoutContext

  getForm = ({ handleSubmit, handleChange, values, isEditing }) => (
    <form onSubmit={handleSubmit}>
      <div className={'bp3-drawer-content'}>
        <FormGroup label="Question" labelFor="question" labelInfo="(required)">
          <InputGroup
            id="question"
            value={get(values, 'question', '')}
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup
          label="Answer"
          labelFor="answer"
          labelInfo="(required)"
          fill={true}
          helperText={
            <Fragment>
              Formatted with{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.redbox.systems/docs/markdown-guide"
              >
                Markdown (Guide)
              </a>
              .
            </Fragment>
          }
        >
          <TextArea
            id="answer"
            fill={true}
            onChange={handleChange}
            style={{ height: '300px' }}
            value={get(values, 'answer', '')}
          />
        </FormGroup>
      </div>
      <div className="bp3-drawer-footer-actions">
        <Button type="submit">{isEditing ? 'Save' : 'Create'}</Button>
      </div>
    </form>
  )

  render() {
    const isEditing = this.props.selectedId != null
    const { dark } = this.context
    return (
      <Drawer
        isOpen={this.props.isOpen}
        onClose={this.props.drawerClose}
        title={isEditing ? `Edit FAQ` : 'New FAQ'}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        className={dark ? 'bp3-dark' : ''}
        size={DrawerSize.LARGE}
      >
        <Fragment>
          {isEditing ? (
            <Query
              query={GET_FAQ}
              variables={{
                id: this.props.selectedId,
              }}
            >
              {({ getFAQs }) => {
                const faq = first(getFAQs)
                return (
                  <Mutation
                    mutation={EDIT_FAQ}
                    onCompleted={({ editFAQ }) => {
                      this.props.drawerClose()
                      successToast(`Saved "${get(editFAQ, 'question', '')}"`)
                    }}
                    onError={defaultErrorHandler}
                    refetchQueries={[
                      {
                        query: GET_MARKETPLACE_FAQS,
                        variables: {
                          marketplaceId: this.props.marketplaceId,
                        },
                      },
                    ]}
                  >
                    {editFAQ => {
                      return (
                        <Formik
                          onSubmit={values => {
                            editFAQ({
                              variables: {
                                id: values.id,
                                ...values,
                              },
                            })
                          }}
                          initialValues={{
                            ...faq,
                          }}
                        >
                          {props => this.getForm({ ...props, isEditing })}
                        </Formik>
                      )
                    }}
                  </Mutation>
                )
              }}
            </Query>
          ) : (
            <Mutation
              mutation={ADD_FAQ}
              onCompleted={({ addFAQ }) => {
                this.props.drawerClose()
                successToast(`Saved "${get(addFAQ, 'question', '')}"`)
              }}
              onError={defaultErrorHandler}
              refetchQueries={[
                {
                  query: GET_MARKETPLACE_FAQS,
                  variables: {
                    marketplaceId: this.props.marketplaceId,
                  },
                },
              ]}
            >
              {addFAQ => {
                return (
                  <Formik
                    onSubmit={values => {
                      addFAQ({
                        variables: {
                          marketplaceId: this.props.marketplaceId,
                          ...values,
                        },
                      })
                    }}
                    initialValues={{
                      question: '',
                      answer: '',
                    }}
                    refetchQueries={[
                      {
                        query: GET_MARKETPLACE_FAQS,
                        variables: {
                          marketplaceId: this.props.marketplaceId,
                        },
                      },
                    ]}
                  >
                    {props => this.getForm({ ...props, isEditing })}
                  </Formik>
                )
              }}
            </Mutation>
          )}
        </Fragment>
      </Drawer>
    )
  }
}

FAQDrawer.propTypes = {
  isOpen: bool,
  drawerClose: func,
  selectedId: string,
  marketplaceId: string,
}

export default FAQDrawer

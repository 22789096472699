import React from 'react'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import EDIT_RESTAURANT from './mutations/editRestaurant.mutation'
import DetailsForm from './DetailsForm'

const RestaurantDetails = ({
  restaurant,
  cuisines,
  categoryEmojiOrImage,
  allowOtterIntegration,
}) => (
  <Mutation
    mutation={EDIT_RESTAURANT}
    onCompleted={({ editRestaurant }) => {
      console.log(editRestaurant)
      if (editRestaurant.restaurant.active) {
        successToast(
          'Business has been successfully activated, please check your Outlets.'
        )
      } else {
        successToast(editRestaurant.message)
      }
    }}
    onError={defaultErrorHandler}
  >
    {editRestaurant => (
      <DetailsForm
        restaurant={restaurant}
        editRestaurantMutation={editRestaurant}
        cuisineList={cuisines}
        categoryEmojiOrImage={categoryEmojiOrImage}
        allowOtterIntegration={allowOtterIntegration}
      />
    )}
  </Mutation>
)

export default RestaurantDetails

import React, { useState, useEffect } from 'react'
import mapboxgl from 'mapbox-gl'

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import createGeoJSONCircle from './createGeoJSONCircle'

mapboxgl.accessToken = process.env.MAPBOX_KEY

const GeoDeliveryZones = ({ center, deliveryZones }) => {
  const sourceID = 'incomingFeatures'
  const config = {
    container: 'map',
    style: 'mapbox://styles/mapbox/streets-v10',
    zoom: 10,
    maxZoom: 12,
    center: center || {
      lng: -1.146159,
      lat: 52.809403,
    },
  }

  const [map, setMap] = useState(null)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const newMap = new mapboxgl.Map(config)
    newMap.on('load', () => {
      setReady(true)
    })
    setMap(newMap)

    return () => {
      map && map.remove()
    }
  }, [])

  const buildSource = () => {
    const featuresToDraw = []

    deliveryZones.forEach(deliveryZone => {
      const zoneGeoFence = deliveryZone.deliveryZone.geoFence
      if (zoneGeoFence) {
        featuresToDraw.push({
          type: 'Feature',
          geometry: {
            type: zoneGeoFence.type,
            coordinates: zoneGeoFence.coordinates,
          },
          properties: {
            name: deliveryZone.deliveryZone.name,
          },
        })
      } else {
        featuresToDraw.push(
          createGeoJSONCircle(center, deliveryZone.radiusMiles)
        )
        featuresToDraw.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: center,
          },
          properties: {
            name: deliveryZone.deliveryZone.name,
          },
        })
      }
    })

    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: featuresToDraw,
      },
    }
  }

  useEffect(() => {
    if (map && ready) {
      const source = buildSource()

      map.addSource(
        sourceID, // name matters in the following layers
        source
      )

      map.addLayer({
        id: 'polygon',
        type: 'fill',
        source: sourceID,
        paint: {
          'fill-color': 'blue',
          'fill-opacity': 0.1,
        },
        filter: ['==', '$type', 'Polygon'],
      })

      map.addLayer({
        id: 'radius',
        type: 'circle',
        source: sourceID,
        paint: {
          'circle-radius': 6,
          'circle-color': 'red',
        },
        filter: ['==', '$type', 'Point'],
      })
    }
  }, [map, ready])

  useEffect(() => {
    if (map && ready) {
      const source = buildSource()

      const activeSource = map.getSource(sourceID)
      activeSource.setData(source.data)
    }
  }, [deliveryZones])

  return (
    <div
      style={{
        height: '400px',
        width: '100%',
      }}
      id="map"
    />
  )
}

export default GeoDeliveryZones

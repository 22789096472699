import React, { Fragment } from 'react'
import {
  HTMLSelect,
  Button,
  ControlGroup,
  NumericInput,
  Popover,
  Position,
  InputGroup,
  Tag,
  Intent,
  MenuItem,
  Icon,
} from '@blueprintjs/core'
import injectSheet from 'react-jss'
import GET_SEGMENT_ATTRIBUTE_VALUES from '../queries/getSegmentAttributeValues.query'
import { object, func, number, array, string } from 'prop-types'
import Query from '@components/Query/Query'
import TypeaheadSingle from '@components/Typeahead/TypeaheadSingle'
import moment from 'moment'
import { DatePicker } from '@blueprintjs/datetime'
import { comparatorMapping } from '@components/Marketing/shared/HumanReadableFilters'
import { Select } from '@blueprintjs/select'

const styles = {
  container: {
    '& > *': {
      marginRight: '5px',
    },
    display: 'flex',
  },
}

const recencyItems = {
  HR_24: 'last 24 hours',
  DAY_7: 'last 7 days',
  DAY_14: 'last 14 days',
  DAY_30: 'last 30 days',
}

const AttributeValueSelector = ({
  filter: { attribute, value },
  selected,
  disabled,
  marketplaceId,
  setFieldValue,
  valueName,
}) => {
  switch (selected.type) {
    case 'date': {
      return (
        <Popover position={Position.BOTTOM_LEFT} boundary="window">
          <Button rightIcon="double-caret-vertical" disabled={disabled}>
            {value
              ? moment(value).format('dddd, MMM Do YYYY')
              : 'Select a date'}
          </Button>
          <DatePicker
            value={value ? moment(value).toDate() : null}
            maxDate={moment().add(2, 'weeks').toDate()}
            minDate={moment(0).toDate()}
            onChange={(selectedDate, isUserChange) => {
              if (selectedDate && isUserChange) {
                setFieldValue(valueName, moment(selectedDate).startOf('day'))
              }
            }}
          />
        </Popover>
      )
    }

    case 'number': {
      return (
        <NumericInput
          disabled={disabled}
          value={value}
          min={0}
          onValueChange={(_valNum, val) => setFieldValue(valueName, val)}
        />
      )
    }

    case 'recency': {
      const ItemRenderer = (item, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
          return null
        }
        const isSelected = value === item.id

        return (
          <MenuItem
            active={false}
            icon={isSelected ? 'selection' : 'circle'}
            color={isSelected ? 'red' : 'blue'}
            key={item.id}
            onClick={handleClick}
            text={item.name}
          />
        )
      }

      return (
        <Select
          disabled={false}
          onItemSelect={value => {
            setFieldValue(valueName, value.id)
          }}
          itemRenderer={ItemRenderer}
          items={Object.entries(recencyItems).map(([id, name]) => ({
            id,
            name,
          }))}
        >
          <Button>
            {recencyItems[value] || (
              <span style={{ marginRight: '10px' }}>Recency</span>
            )}
            <Icon icon="double-caret-vertical" />
          </Button>
        </Select>
      )
    }

    case 'text': {
      return (
        <InputGroup
          disabled={disabled}
          type="text"
          onChange={e =>
            setFieldValue(
              valueName,
              selected.value === 'User.postcode'
                ? e.target.value.toUpperCase()
                : e.target.value
            )
          }
          value={value}
        />
      )
    }

    // type 'select' is default
    default: {
      return (
        <Query
          query={GET_SEGMENT_ATTRIBUTE_VALUES}
          variables={{ marketplaceId, attribute }}
          showLoader={false}
        >
          {({ getSegmentAttributeValues }) => (
            <TypeaheadSingle
              icon={false}
              clear={false}
              disabled={disabled}
              items={getSegmentAttributeValues.attributeValues}
              selected={value}
              setSelected={value => setFieldValue(valueName, value)}
            />
          )}
        </Query>
      )
    }
  }
}

const SegmentAttributeFilter = ({
  filter,
  onChange,
  index,
  arrayHelpers,
  attributes,
  marketplaceId,
  setFieldValue,
  formNamePrefix,
}) => {
  const selected = attributes.find(({ value }) => value === filter.attribute)
  const deprecated = filter.attribute && !selected

  const valueName = `${formNamePrefix}.value`
  const selectedFilter = selected
    ? filter
    : { attribute: '', comparator: '', value: '' }

  return (
    <Fragment>
      <ControlGroup
        vertical={false}
        style={{ borderLeft: '3px solid #1968f6', padding: 4 }}
      >
        {deprecated ? (
          <Tag intent={Intent.DANGER}>
            {filter.attribute} {comparatorMapping[filter.comparator]}{' '}
            {filter.value}
          </Tag>
        ) : (
          <Fragment>
            <HTMLSelect
              name={`${formNamePrefix}.attribute`}
              options={[
                { values: '', label: 'Choose attribute...', key: '0' },
                ...attributes.map(attribute => ({
                  ...attribute,
                  key: attribute.value,
                })),
              ]}
              value={selectedFilter.attribute}
              onChange={e => {
                setFieldValue(`${valueName}`, '')
                onChange(e)
              }}
            />
            {selectedFilter.attribute ? (
              <Fragment>
                <HTMLSelect
                  name={`${formNamePrefix}.comparator`}
                  options={[
                    { value: '', label: 'Select comparison...' },
                    ...selected.comparators,
                  ]}
                  value={selectedFilter.comparator}
                  onChange={onChange}
                />
                <AttributeValueSelector
                  disabled={selectedFilter.comparator === ''}
                  setFieldValue={setFieldValue}
                  index={index}
                  filter={selectedFilter}
                  marketplaceId={marketplaceId}
                  selected={selected}
                  valueName={valueName}
                />
              </Fragment>
            ) : null}
          </Fragment>
        )}
        <Button
          type="button"
          onClick={() => arrayHelpers.remove(index)}
          icon="cross"
        />
        {deprecated && (
          <p style={{ padding: '4 0 0 12' }}>
            This field is now deprecated, please delete.
          </p>
        )}
      </ControlGroup>
    </Fragment>
  )
}

SegmentAttributeFilter.propTypes = {
  filter: object,
  onChange: func.isRequired,
  index: number.isRequired,
  arrayHelpers: object.isRequired,
  attributes: array.isRequired,
  marketplaceId: string.isRequired,
  setFieldValue: func.isRequired,
  formNamePrefix: string.isRequired,
}

export default injectSheet(styles)(SegmentAttributeFilter)

import { Button, Intent } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import React, { useState } from 'react'
import { Mutation } from 'react-apollo'
import ADD_PAYMENT_METHOD from './mutations/addBillingPaymentMethod.mutation'
import { object, string, bool } from 'prop-types'

const AddPaymentMethodButton = ({
  id,
  level = 'MARKETPLACE',
  product = null,
  fill = false,
  disabled = false,
  style = {},
  minimal = false,
  intent = 'none',
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <Mutation
      mutation={ADD_PAYMENT_METHOD}
      onCompleted={data => {
        window.location.href = data.addBillingPaymentMethod.paymentRedirectUrl
      }}
      onError={defaultErrorHandler}
    >
      {addBillingPaymentMethod => (
        <Button
          text="Setup Direct Debit"
          loading={loading}
          rightIcon="share"
          fill={fill}
          disabled={disabled}
          style={style}
          minimal={minimal}
          intent={Intent[intent.toUpperCase()]}
          onClick={() => {
            setLoading(true)
            addBillingPaymentMethod({
              variables: {
                id,
                level,
                product,
              },
            })
          }}
        />
      )}
    </Mutation>
  )
}

AddPaymentMethodButton.propTypes = {
  id: string,
  level: string,
  product: string,
  fill: bool,
  style: object,
  minimal: bool,
  intent: string,
}

export default AddPaymentMethodButton

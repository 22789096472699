import { Intent, Tooltip } from '@blueprintjs/core'
import { Button } from '@blueprintjs/core/lib/esm/components/button/buttons'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import React from 'react'
import { Mutation } from 'react-apollo'
import DISCONNECT_STRIPE from '../mutations/disconnectStripe.mutation'

const DisconnectStripeButton = ({ stripeId, marketplaceId, onCompleted }) => (
  <Mutation
    mutation={DISCONNECT_STRIPE}
    onCompleted={() => {
      successToast('Stripe Disconnected')
      onCompleted()
    }}
    onError={defaultErrorHandler}
  >
    {deleteRequest => {
      const isLocalDev = location.hostname === 'localhost'

      // disabled for local dev to prevent accidental disconnect as data is shared between local and staging
      if (isLocalDev) {
        return (
          <Tooltip content="Disconnect is disabled in a local development environment">
            <Button text="Disconnect Stripe" intent={Intent.DANGER} disabled />
          </Tooltip>
        )
      }

      return stripeId ? (
        <ConfirmationPopover
          remove={() =>
            deleteRequest({
              variables: { stripeId, marketplaceId },
            })
          }
          buttonTitle="Disconnect"
          confirmationText={`Are you sure you want to disconnect the payment processor? This marketplace will not be able to process payments.`}
          // force user to type "disconnect" to confirm
          requiresWrittenConfirmation
        >
          <Button minimal text="Disconnect" intent={Intent.DANGER} />
        </ConfirmationPopover>
      ) : (
        <Button minimal text="Disconnect" intent={Intent.DANGER} disabled />
      )
    }}
  </Mutation>
)

export default DisconnectStripeButton

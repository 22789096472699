import React from 'react'
import cx from 'classnames'
import { lowerCase, startCase } from 'lodash'

const DeliveryNotes = ({
  classes,
  order: { customerDeliveryNotes, fulfillmentMethod },
}) =>
  customerDeliveryNotes && customerDeliveryNotes.length ? (
    <section className={cx(classes.section, classes.center)}>
      <h4 className={classes.title}>
        {fulfillmentMethod === 'NETWORK'
          ? 'Delivery'
          : startCase(lowerCase(fulfillmentMethod))}{' '}
        Notes
      </h4>
      <p className={classes.text}>{customerDeliveryNotes}</p>
    </section>
  ) : null

export default DeliveryNotes

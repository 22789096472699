import {
  Button,
  Callout,
  Card,
  FormGroup,
  H5,
  Icon,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
  Switch,
  TextArea,
  Tooltip,
} from '@blueprintjs/core'
import { Formik } from 'formik'
import get from 'lodash/get'
import { arrayOf, func, number, object, shape, string } from 'prop-types'
import React, { Fragment } from 'react'

import LinkTag from '@components/LinkTag/LinkTag'
import PartnerSelect from '@components/PartnerSelect/PartnerSelect'
import {
  canPerformAction,
  isAtLeastMarketplaceOwner,
  isAtLeastMarketplaceUser,
  isPlatformLevelUser,
} from '@stores/userStore'
import { selectionUpdate } from '@utils/helpers'
import { shortRestaurantType } from '@utils/types'
import { omit } from 'lodash'
import { Col, Row } from 'react-simple-flex-grid'
import ImageUpload from '../../ImageUpload/ImageUpload'
import ShortDetailsForm from '../Restaurant.modal/ShortDetailsForm'
import { Categories } from './Categories'
import RestaurantActivationForm from './RestaurantActivationForm'
import { validation } from './validation'
import CategoriesOverrideSwitch from './CategoriesOverrideSwitch'
import { OtterOAuthButton } from '../Otter/OtterOAuthButton'

const DetailsForm = ({
  restaurant,
  editRestaurantMutation,
  cuisineList,
  categoryEmojiOrImage,
  allowOtterIntegration,
}) => (
  <Formik
    onSubmit={variables => {
      const {
        dealsDelivery,
        dealsCollection,
        dealsTable,
        dealsDeliveryText,
        dealsCollectionText,
        dealsTableText,
        contactAddress,
        allowAddOnItems,
        enableAllergyInformation,
        enableStackedMenu,
        displayAllOutlets,
        outletOfferFlagOverride,
        outletPromoteOverride,
        outletCategoriesOverride,
        categories,
      } = variables

      editRestaurantMutation({
        variables: {
          ...variables,
          categories: categories.map(category => category.id), // the order of the array is what will tell the server how to assign priorities
          contactAddress: omit(contactAddress, ['id']),
          deliveryDeal: dealsDelivery ? dealsDeliveryText : '',
          collectionDeal: dealsCollection ? dealsCollectionText : '',
          tableDeal: dealsTable ? dealsTableText : '',
          allowAddOnItems,
          enableAllergyInformation,
          enableStackedMenu: enableStackedMenu === 'grouped',
          displayAllOutlets,
          outletOfferFlagOverride,
          outletPromoteOverride,
          outletCategoriesOverride,
        },
      })
    }}
    initialValues={{
      ...omit(restaurant, 'outlets'),
      categories: restaurant.cuisines,
      cuisineIds: restaurant.cuisines.map(cuisine => cuisine.id),
      partnerId: get(restaurant, 'partner.id'),
      dealsDelivery: Boolean(get(restaurant, 'deliveryDeal')),
      dealsCollection: Boolean(get(restaurant, 'collectionDeal')),
      dealsTable: Boolean(get(restaurant, 'tableDeal')),
      promoted: Boolean(get(restaurant, 'promoted')),
      dealsDeliveryText: get(restaurant, 'deliveryDeal'),
      dealsCollectionText: get(restaurant, 'collectionDeal'),
      dealsTableText: get(restaurant, 'tableDeal'),
      enableAllergyInformation: restaurant.enableAllergyInformation || false,
      enableStackedMenu: restaurant.enableStackedMenu ? 'grouped' : 'expanded',
      outletOfferFlagOverride: restaurant.outletOfferFlagOverride,
      outletPromoteOverride: restaurant.outletPromoteOverride,
      outletCategoriesOverride: restaurant.outletCategoriesOverride,
      displayAllOutlets: restaurant.displayAllOutlets || false,
      allowAddOnItems: restaurant.allowAddOnItems || false,
      cname: get(restaurant, 'cname'),
      metaDescription: get(restaurant, 'metaDescription'),
      metaKeywords: get(restaurant, 'metaKeywords'),
    }}
    initialStatus={{ previewImageValue: null }}
    validationSchema={validation}
    validateOnBlur={true}
    enableReinitialize={true}
  >
    {props => {
      const {
        handleChange,
        handleSubmit,
        values,
        errors,
        setFieldValue,
        status,
        setStatus,
      } = props

      return (
        <form onSubmit={handleSubmit}>
          {!values.active && (
            <Fragment>
              <Callout intent={Intent.DANGER} icon="ban-circle">
                Deactivated Business, Outlets will not be visible to Customers.
              </Callout>
              <br />
            </Fragment>
          )}
          <Card>
            <Row gutter={48}>
              <Col sm={12} lg={8}>
                {/* Reuses short restaurant details form for validation */}
                <FormGroup
                  label={
                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                      Business Name
                    </span>
                  }
                  labelInfo="(required)"
                  labelFor="name"
                  helperText={errors.name ? errors.name : ''}
                  intent={errors.name ? Intent.DANGER : Intent.NONE}
                >
                  <InputGroup
                    name="name"
                    id="name"
                    type="text"
                    onChange={handleChange}
                    intent={errors.name ? Intent.DANGER : Intent.NONE}
                    value={values.name}
                    large={true}
                  />
                </FormGroup>
                <FormGroup
                  label="Description / Profile"
                  labelFor="description"
                  labelInfo="(required)"
                  subLabel="Shown to Customer on detail expansion / 'More Info', description of what the Business provides."
                  helperText={
                    errors.description ? (
                      errors.description
                    ) : (
                      <Fragment>
                        Formatted with{' '}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://support.redbox.systems/docs/markdown-guide"
                        >
                          Markdown (Guide)
                        </a>
                        .
                      </Fragment>
                    )
                  }
                  intent={errors.description ? Intent.DANGER : Intent.NONE}
                >
                  <TextArea
                    onChange={handleChange}
                    name="description"
                    style={{ resize: 'none', height: '100px' }}
                    fill={true}
                    id="description"
                    value={values.description || ''}
                    intent={errors.description ? Intent.DANGER : Intent.NONE}
                  />
                </FormGroup>

                {isPlatformLevelUser() && (
                  <Fragment>
                    <FormGroup
                      label="Partner"
                      labelInfo="(required)"
                      labelFor="partnerId"
                      helperText={errors.partnerId ? errors.partnerId : ''}
                      intent={Intent.DANGER}
                    >
                      <PartnerSelect
                        partnerId={values.partnerId}
                        onChange={event => {
                          const selection = selectionUpdate(event)
                          setFieldValue('partnerId', selection.value, false)
                        }}
                      />
                    </FormGroup>
                  </Fragment>
                )}
                {isPlatformLevelUser() && (
                  <FormGroup
                    label="Domain"
                    labelInfo="(cname)"
                    labelFor="cname"
                    helperText={errors.cname ? errors.cname : ''}
                    intent={errors.cname ? Intent.DANGER : Intent.NONE}
                  >
                    <InputGroup
                      name="cname"
                      id="cname"
                      type="text"
                      onChange={handleChange}
                      intent={errors.cname ? Intent.DANGER : Intent.NONE}
                      value={values.cname || null}
                    />
                  </FormGroup>
                )}
                <H5>
                  Search Engine Optimisation <LinkTag type="web-v2" />
                </H5>
                <FormGroup
                  label="Description"
                  labelFor="metaDescription"
                  labelInfo="155 Character Limit"
                  helperText={
                    errors.metaDescription
                      ? errors.metaDescription
                      : 'Typically displayed in search engine results beneath the title of the page.'
                  }
                  intent={errors.metaDescription ? Intent.DANGER : Intent.NONE}
                >
                  <InputGroup
                    name=""
                    id="metaDescription"
                    type="text"
                    onChange={handleChange}
                    intent={
                      errors.metaDescription ? Intent.DANGER : Intent.NONE
                    }
                    value={values.metaDescription || null}
                  />
                </FormGroup>

                <FormGroup
                  label="Keywords"
                  labelFor="metaKeywords"
                  helperText={
                    errors.metaKeywords
                      ? errors.metaKeywords
                      : 'List of keywords used by search engines that describe content.'
                  }
                  intent={errors.metaKeywords ? Intent.DANGER : Intent.NONE}
                >
                  <InputGroup
                    name=""
                    id="metaKeywords"
                    type="text"
                    onChange={handleChange}
                    intent={errors.metaKeywords ? Intent.DANGER : Intent.NONE}
                    value={values.metaKeywords || null}
                  />
                </FormGroup>
                <ShortDetailsForm
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </Col>
              <Col sm={12} lg={4} className="bp3-col-divide">
                {!canPerformAction('editRestaurant') ? null : (
                  <Fragment>
                    <RestaurantActivationForm
                      values={values}
                      handleSubmit={handleSubmit}
                      handleChange={handleChange}
                    />
                  </Fragment>
                )}
                {allowOtterIntegration && <OtterOAuthButton />}
                <br />

                <H5>Category Tags</H5>
                <FormGroup
                  labelFor="cuisines"
                  style={{ marginBottom: 5 }}
                  helperText={
                    errors.cuisines
                      ? errors.cuisines
                      : 'Tags allows grouping similar businesses in filters and views.'
                  }
                  intent={errors.cuisines ? Intent.DANGER : null}
                >
                  <Categories
                    setFieldValue={setFieldValue}
                    allCategories={cuisineList}
                    categories={values.categories}
                    preferEmoji={categoryEmojiOrImage === 'EMOJI'}
                  />
                </FormGroup>

                <hr />
                <br />
                <H5>Branding</H5>
                <ImageUpload
                  values={values}
                  setFieldValue={setFieldValue}
                  imageLabel="Logo"
                  status={status}
                  setStatus={setStatus}
                />

                <hr />

                {isAtLeastMarketplaceUser() && (
                  <Fragment>
                    <br />
                    <H5>Promotions</H5>
                    <Switch
                      disabled={values.outletPromoteOverride}
                      checked={values.promoted && !values.outletPromoteOverride}
                      onChange={handleChange}
                      name="promoted"
                    >
                      Promote in Results
                      {values.outletPromoteOverride ? (
                        <Tooltip content="Outlet promotion override is activated. Please disable it to promote at business level">
                          <Icon
                            style={{ marginLeft: 5 }}
                            icon="warning-sign"
                            intent="warning"
                          />
                        </Tooltip>
                      ) : null}
                      <br />
                      <span className={'bp3-text-small bp3-text-muted'}>
                        Moves business to the top of search.
                      </span>
                    </Switch>
                    <br />
                    <FormGroup
                      disabled={values.outletOfferFlagOverride}
                      label={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                          }}
                        >
                          Special Offer Flags&nbsp;
                          <Icon icon="star" color="#ffab00" />
                          {values.outletOfferFlagOverride ? (
                            <Tooltip content="Outlet special offer flags override is activated. Please disable it to add special offer flags at business level">
                              <Icon
                                style={{ marginLeft: 5 }}
                                icon="warning-sign"
                                intent="warning"
                              />
                            </Tooltip>
                          ) : null}
                        </div>
                      }
                      subLabel="Adds flag text to a business to draw attention to special offers."
                    >
                      <Switch
                        disabled={values.outletOfferFlagOverride}
                        defaultChecked={values.dealsDelivery}
                        onChange={handleChange}
                        name="dealsDelivery"
                      >
                        Delivery
                      </Switch>
                      {values.dealsDelivery && (
                        <div
                          style={{
                            marginLeft: 38,
                            marginBottom: 12,
                          }}
                        >
                          <InputGroup
                            disabled={values.outletOfferFlagOverride}
                            name="dealsDeliveryText"
                            id="dealsDeliveryText"
                            type="text"
                            value={values.dealsDeliveryText}
                            onChange={handleChange}
                            maxLength={85}
                            intent={
                              get(errors, 'dealsDeliveryText')
                                ? Intent.DANGER
                                : Intent.NONE
                            }
                            required={values.dealsDelivery}
                          />
                        </div>
                      )}

                      <Switch
                        defaultChecked={values.dealsCollection}
                        onChange={handleChange}
                        name="dealsCollection"
                        disabled={values.outletOfferFlagOverride}
                      >
                        Collection
                      </Switch>
                      {values.dealsCollection && (
                        <div
                          style={{
                            marginLeft: 38,
                            marginBottom: 12,
                          }}
                        >
                          <InputGroup
                            disabled={values.outletOfferFlagOverride}
                            name="dealsCollectionText"
                            id="dealsCollectionText"
                            type="text"
                            value={values.dealsCollectionText}
                            onChange={handleChange}
                            maxLength={85}
                            intent={
                              get(errors, 'dealsCollectionText')
                                ? Intent.DANGER
                                : Intent.NONE
                            }
                            required={values.dealsCollection}
                          />
                        </div>
                      )}

                      <Switch
                        disabled={values.outletOfferFlagOverride}
                        defaultChecked={values.dealsTable}
                        onChange={handleChange}
                        name="dealsTable"
                      >
                        Table Service
                      </Switch>
                      {values.dealsTable && (
                        <div
                          style={{
                            marginLeft: 38,
                            marginBottom: 12,
                          }}
                        >
                          <InputGroup
                            disabled={values.outletOfferFlagOverride}
                            name="dealsTableText"
                            id="dealsTableText"
                            type="text"
                            value={values.dealsTableText}
                            onChange={handleChange}
                            maxLength={85}
                            intent={
                              get(errors, 'dealsTableText')
                                ? Intent.DANGER
                                : Intent.NONE
                            }
                            required={values.dealsTable}
                          />
                        </div>
                      )}
                    </FormGroup>
                  </Fragment>
                )}
                {isAtLeastMarketplaceOwner() && (
                  <Fragment>
                    <hr />
                    <br />
                    <H5>Features</H5>
                    <FormGroup subLabel="Restrict features available to this business.">
                      <Switch
                        label={"Allow 'Add-on Sale' Menus"}
                        defaultChecked={values.allowAddOnItems}
                        onChange={handleChange}
                        name="allowAddOnItems"
                        id="allowAddOnItems"
                      />
                    </FormGroup>
                  </Fragment>
                )}

                {isAtLeastMarketplaceUser() && (
                  <Fragment>
                    <hr />
                    <br />
                    <H5>
                      Display Settings <LinkTag type="web-v2" />
                    </H5>
                    <Switch
                      label={'Display Allergy Information'}
                      checked={values.enableAllergyInformation}
                      onChange={handleChange}
                      name="enableAllergyInformation"
                      id="enableAllergyInformation"
                    />
                    <Switch
                      label={
                        <Fragment>
                          Display All Outlets
                          <br />
                          <span className={'bp3-text-small bp3-text-muted'}>
                            If a business has multiple outlets, display each one
                            separately in the results.
                          </span>
                        </Fragment>
                      }
                      checked={values.displayAllOutlets}
                      onChange={handleChange}
                      name="displayAllOutlets"
                      id="displayAllOutlets"
                    />
                    <br />
                    <RadioGroup
                      label="Mobile Menu"
                      onChange={handleChange}
                      selectedValue={values.enableStackedMenu}
                      name="enableStackedMenu"
                    >
                      <Radio
                        label={
                          <Fragment>
                            Expanded (default)
                            <br />
                            <span className={'bp3-text-small bp3-text-muted'}>
                              For Smaller Menus - All items displayed on a
                              single screen, vertically scrolled.
                            </span>
                          </Fragment>
                        }
                        value="expanded"
                      />
                      <Radio
                        label={
                          <Fragment>
                            Grouped <br />
                            <span className={'bp3-text-small bp3-text-muted'}>
                              For Larger Menus - Grouped by menu title, items on
                              a second screen.
                            </span>
                          </Fragment>
                        }
                        value="grouped"
                      />
                    </RadioGroup>
                    <br />
                  </Fragment>
                )}

                {isAtLeastMarketplaceUser() ? (
                  <Fragment>
                    <hr />
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <H5 style={{ marginRight: 5 }}>Outlet Overrides</H5>
                      <LinkTag type="web-v2" />
                    </div>
                    <FormGroup
                      style={{ marginBottom: 2 }}
                      subLabel="When a feature has an override activated, outlets will no longer use the business level configuration for that feature and instead must set their own."
                    >
                      <Switch
                        label={`Special Offer Flags`}
                        checked={values.outletOfferFlagOverride}
                        onChange={handleChange}
                        name="outletOfferFlagOverride"
                        id="outletOfferFlagOverride"
                      />
                      <Switch
                        label={`Promotion`}
                        checked={values.outletPromoteOverride}
                        onChange={handleChange}
                        name="outletPromoteOverride"
                        id="outletPromoteOverride"
                      />
                      <CategoriesOverrideSwitch
                        handleChange={handleChange}
                        values={values}
                        restaurantId={restaurant.id}
                      />
                    </FormGroup>
                    <br />
                  </Fragment>
                ) : null}
              </Col>
            </Row>
            <div className="bp-card-footer-actions">
              <Button text="Save" type="submit" />
            </div>
          </Card>
        </form>
      )
    }}
  </Formik>
)

DetailsForm.propTypes = {
  values: shape({
    deliveryOptions: arrayOf(string),
    description: string,
    partnerFee: number,
    stripeId: string,
    ...shortRestaurantType,
  }),
  mutation: func,
  cuisineList: arrayOf(
    shape({
      name: string,
    })
  ),
  handleChange: func,
  handleSubmit: func,
  errors: object,
  setFieldValue: func,
  setStatus: func,
  status: object,
}
export default DetailsForm

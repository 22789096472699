import React, { Fragment } from 'react'
import { Card } from '@blueprintjs/core'
import  StarsRating from '@components/Stars/Stars'
import injectSheet from 'react-jss'
import cx from 'classnames'
import moment from 'moment'
import ReviewStatus from './ReviewStatus'
import ReviewBody from './ReviewBody'

const reviewDateFormat = 'hh:mm, MMM Do YYYY'
const oneMinute = 1

const style = () => ({
  justifySpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  reviewHeader: {
    alignItems: 'center'
  }
})

const Review = ({
  classes,
  review,
  review: {
    id,
    createdAt,
    updatedAt,
    foodQuality,
    restaurantService,
    deliveryTime,
    approved,
    reviewText
  },
  shouldDisplayHeader = true
  }) => {
  // since we'll be reusing those
  // it's worth calculating them just once
  const createdMoment = moment(createdAt)
  const updatedMoment = moment(updatedAt)
  const diffInMinutes = createdMoment.diff(updatedMoment, 'minutes')

  return (
    <Card>
      { shouldDisplayHeader && <div className={cx(classes.justifySpaceBetween, classes.reviewHeader)}>
        <h4 className='bp3-heading'>Review</h4>
        <ReviewStatus approved={approved} />
      </div>}
      <Fragment>
        <div className={classes.justifySpaceBetween}>
          <strong>Created: </strong>
          <p>{createdMoment.format(reviewDateFormat)}</p>
        </div>
        {diffInMinutes > oneMinute && <div className={classes.justifySpaceBetween}>
          <strong>Updated: </strong>
          <p>{updatedMoment.format(reviewDateFormat)}</p>
        </div>}
        <StarsRating title={"Food Rating"} starsNumber={foodQuality} />
        <StarsRating title={"Restaurant Service"} starsNumber={restaurantService} />
        <StarsRating title={"Delivery Time"} starsNumber={deliveryTime} />
          {reviewText.length && <ReviewBody review={review} />}
      </Fragment>
    </Card>
  )
}

export default injectSheet(style)(Review)

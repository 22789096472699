import React from 'react'
import { object } from 'prop-types'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import {
  FormGroup,
  InputGroup,
  Button,
  Classes,
  Intent,
} from '@blueprintjs/core'

import { successToast } from '@utils/toast'
import { validation } from './validation'
import CHANGE_PASSWORD from '@components/User/Password/mutations/changePassword.mutation'

const Password = () => (
  <Mutation
    mutation={CHANGE_PASSWORD}
    onError={defaultErrorHandler}
    onCompleted={() => successToast('Password successfully changed.')}
  >
    {changePassword => (
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          passwordConfirm: '',
        }}
        validationSchema={validation}
        onSubmit={values =>
          changePassword({
            variables: {
              ...values,
            },
          })
        }
        validateOnChange={true}
      >
        {({ handleChange, values, handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup label="Current Password" labelInfo="(required)">
              <InputGroup
                value={values.oldPassword}
                name="currentPassword"
                type="password"
                onChange={handleChange}
                intent={errors.currentPassword ? Intent.DANGER : Intent.NONE}
              />
            </FormGroup>

            <FormGroup
              label="Password"
              helperText={errors.newPassword ? errors.newPassword : ''}
            >
              <InputGroup
                value={values.password}
                labelInfo="(required)"
                name="newPassword"
                type="password"
                onChange={handleChange}
                intent={errors.newPassword ? Intent.DANGER : Intent.NONE}
              />
            </FormGroup>

            <FormGroup
              label="Confirm Password"
              labelInfo="(required)"
              helperText={errors.passwordConfirm ? errors.passwordConfirm : ''}
              intent={Intent.DANGER}
            >
              <InputGroup
                value={values.passwordConfirm}
                name="passwordConfirm"
                type="password"
                onChange={handleChange}
                intent={errors.passwordConfirm ? Intent.DANGER : Intent.NONE}
              />
            </FormGroup>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button intent={Intent.PRIMARY} text="Submit" type="submit" />
            </div>
          </form>
        )}
      </Formik>
    )}
  </Mutation>
)

Password.propTypes = {
  classes: object,
}

export default Password

import React, { Fragment, useContext, useEffect } from 'react'
import { object } from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import split from 'lodash/split'
import { Callout } from '@blueprintjs/core'

import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { matchType } from '@utils/types'
import { toggleModal } from '@utils/cacheHelpers'

import Dashboard from './Dashboard/Dashboard'
import Details from './Details/Details'
import Financials from './Financials/Financials'
import Menu from './Menu/Menu'
import Outlets from './Outlets/Outlets'
import Options from './Options/Options'
import Discounts from '@components/Discount/Discounts'
import Users from '@components/Users/Users'
import {
  canPerformAction,
  isMenuEditor,
  isOutletFinancialUser,
  isOutletUser,
} from '@stores/userStore'

import UsersPageWrapper from '../Users/UsersPageWrapper'
import usersPageContext from '@components/Users/usersPageContext'
import MemberDiscounts from '@components/MemberDiscounts/MemberDiscounts'
import MembersDiscountProvider, {
  MembersDiscountContext,
} from '@components/MemberDiscounts/MembersDiscountProvider'
import BusinessAnalyticsContextProvider from './Analytics/BusinessAnalyticsContext'
import { isPeakTimeIncludingFridayAndSaturday } from '@utils/isPeakTime'
import { NoPermissions } from '@components/UI/Permission/Permission'

const showCacheWarning = isPeakTimeIncludingFridayAndSaturday()

const Restaurant = ({ match, location, product }) => {
  const userIsMenuEditor = isMenuEditor()
  const userIsOutletFinancialUser = isOutletFinancialUser()

  const tabButton = (
    pathname,
    openInviteUserModal,
    setModalInviteMemberToDiscountOpen
  ) => {
    const path = split(pathname, '/')[3]

    let button
    switch (path) {
      case 'menu':
        button = {
          text: 'Add Menu',
          onClick: () =>
            toggleModal({
              parentMenuModal: true,
            }),
        }
        break
      case 'outlets':
        if (!userIsMenuEditor && !userIsOutletFinancialUser) {
          button = {
            text: 'Add Outlet',
            onClick: () =>
              toggleModal({
                outletModal: true,
              }),
          }
        }
        break
      case 'options':
        button = {
          text: 'Add Option',
          onClick: () =>
            toggleModal({
              optionModal: true,
            }),
        }
        break
      case 'discounts':
        button = canPerformAction('addDiscount')
          ? {
              text: 'Add Discount',
              onClick: () =>
                toggleModal({
                  addDiscountModal: true,
                }),
            }
          : null
        break
      case 'users':
        button = canPerformAction('inviteUser')
          ? {
              text: 'Invite User',
              onClick: () => openInviteUserModal(true),
            }
          : null
        break
      case 'members':
        button = canPerformAction('enrollMemberToDiscount')
          ? {
              text: 'Invite Member',
              onClick: () => setModalInviteMemberToDiscountOpen(true),
            }
          : null
        break
      default:
        button = null
    }

    return button
  }

  const { setModalOpen: openInviteUserModal } = useContext(usersPageContext)
  const {
    setModalInviteMemberToDiscountOpen: setModalInviteMemberToDiscountOpen,
  } = useContext(MembersDiscountContext)
  const { configurePageLayout } = useContext(PageLayoutContext)

  useEffect(() => {
    const tabs = [
      {
        to: `${match.url}/overview`,
        name: 'Overview',
      },
      {
        to: `${match.url}/details`,
        name: 'Details',
        hide: !canPerformAction('editRestaurant') || userIsMenuEditor,
      },
      {
        to: `${match.url}/financial`,
        name: 'Financial',
        hide: userIsMenuEditor,
      },
      {
        to: `${match.url}/menu`,
        name: 'Menu',
      },
      {
        to: `${match.url}/options`,
        name: 'Options',
      },
      {
        to: `${match.url}/outlets`,
        name: 'Outlets',
      },
      {
        to: `${match.url}/discounts`,
        name: 'Discounts',
        hide: userIsMenuEditor,
      },
      {
        to: `${match.url}/users`,
        name: 'Users',
        hide: userIsMenuEditor,
      },
      {
        to: `${match.url}/members`,
        name: 'Members',
        hide: userIsMenuEditor,
      },
    ]

    configurePageLayout({
      product,
      tabs,
      button: tabButton(
        location.pathname,
        openInviteUserModal,
        setModalInviteMemberToDiscountOpen
      ),
    })
  }, [
    configurePageLayout,
    match.url,
    location.pathname,
    product,
    openInviteUserModal,
    setModalInviteMemberToDiscountOpen,
  ])

  if (isOutletFinancialUser() || isOutletUser()) {
    return <NoPermissions />
  }

  return (
    <Fragment>
      {showCacheWarning && (
        <Fragment>
          <Callout intent="warning">
            We are currently in a peak order period, during this time it may
            take up to 60 minutes to reflect updates to your menu or restaurant
            settings.
          </Callout>
          <br />
        </Fragment>
      )}
      <section>
        <Switch>
          <Redirect
            from="/business/:restaurants"
            to="/business/:restaurants/overview"
            exact
          />
          {/*
              These redirects handle legacy menu routing as the view has been
              changed to use a querystring rather then absolute url path.
          */}
          <Redirect
            from={`${match.path}/menu/tree`}
            to={`${match.path}/menu?view=tree`}
            exact
          />
          <Redirect
            from={`${match.path}/menu/list`}
            to={`${match.path}/menu`}
            exact
          />
          <Route
            path={`${match.path}/overview`}
            exact={true}
            component={Dashboard}
          />
          <Route
            path={`${match.path}/details`}
            exact={true}
            component={Details}
          />
          <Route
            path={`${match.path}/financial`}
            exact={true}
            component={Financials}
          />
          <Route path={`${match.path}/menu`} component={Menu} />
          <Route path={`${match.path}/outlets`} component={Outlets} />
          <Route path={`${match.path}/options`} component={Options} />
          <Route path={`${match.path}/discounts`} component={Discounts} />
          <Route
            path={`${match.path}/users`}
            component={() => <Users restaurantId={match.params.restaurants} />}
          />
          <Route
            path={`${match.path}/members`}
            component={() => (
              <MemberDiscounts restaurantId={match.params.restaurants} />
            )}
          />
        </Switch>
      </section>
    </Fragment>
  )
}

Restaurant.propTypes = {
  match: matchType.isRequired,
  classes: object,
  location: object,
}

const RestaurantPageWrapper = props => (
  <UsersPageWrapper entityName="restaurant">
    <MembersDiscountProvider>
      <BusinessAnalyticsContextProvider>
        <Restaurant {...props} />
      </BusinessAnalyticsContextProvider>
    </MembersDiscountProvider>
  </UsersPageWrapper>
)

export default RestaurantPageWrapper

import moment from 'moment'

const FORMAT_TIME = 'HH:mm'

export const FRIDAY_AS_ISO_WEEKDAY = 5
export const SATURDAY_AS_ISO_WEEKDAY = 6

export const isPeakTime = ({ includeDays } = { includeDays: [] }) => {
  if (process.env.DISABLE_PEAK_TIME_RESTRICTION === 'true') {
    return false
  }

  const isPeakTime = moment
    .utc()
    .isBetween(
      moment.utc('16:00', FORMAT_TIME),
      moment.utc('20:00', FORMAT_TIME)
    )

  if (includeDays.length === 0) {
    return isPeakTime
  }

  const isPeakDay = includeDays.includes(moment().isoWeekday())

  return isPeakTime && isPeakDay
}

export const isPeakTimeIncludingFridayAndSaturday = () =>
  isPeakTime({ includeDays: [FRIDAY_AS_ISO_WEEKDAY, SATURDAY_AS_ISO_WEEKDAY] })

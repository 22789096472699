import React, { Fragment } from 'react'
import { array, func, object, string, bool } from 'prop-types'

import OptionRow from './OptionRow'

const EditOptionItems = ({
  items,
  classes,
  errors,
  setFieldValue,
  setFieldTouched,
  handleChange,
  remove,
  optionId,
  isVATregistered,
  values,
  setStatus,
  status,
  restaurantId,
  menuItemId,
}) => (
  <Fragment>
    {items.map((item, index, initialOptionItems) => (
      <OptionRow
        item={item}
        index={index}
        key={item.id}
        setFieldValue={setFieldValue}
        classes={classes}
        handleChange={handleChange}
        setFieldTouched={setFieldTouched}
        errors={errors}
        remove={remove}
        isVATregistered={isVATregistered}
        initialOptionItems={initialOptionItems}
        optionId={optionId}
        status={status}
        setStatus={setStatus}
        values={values}
        restaurantId={restaurantId}
        menuItemId={menuItemId}
      />
    ))}
  </Fragment>
)

EditOptionItems.propTypes = {
  items: array.isRequired,
  setFieldValue: func,
  classes: object,
  handleChange: func,
  setFieldTouched: func,
  errors: object,
  remove: func,
  initialOptionItems: array,
  optionId: string,
  isVATregistered: bool,
  setStatus: func,
  status: string,
}
export default EditOptionItems

import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
  HTMLTable,
  Card,
  Icon,
  Tooltip,
  Popover,
  Classes,
  Position,
  PopoverInteractionKind,
  H5,
  NonIdealState,
  ButtonGroup,
  ControlGroup,
  Button,
  ProgressBar,
  AnchorButton,
  Intent,
  Tag,
  Dialog,
  FormGroup,
  InputGroup,
  HTMLSelect,
  Alert,
  H4,
  Callout,
} from '@blueprintjs/core'
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'
import { Link } from 'react-router-dom'

import TableHeader from './tableHead'
import { MarketplaceFilter } from '@components/Toolbar'
import GET_DPROUTLETS from './queries/getDPROutlets.query'
import GET_DPRMARKETPLACE from './queries/getDPRMarketplace.query'
import FilterRow from '@components/FilterRow/FilterRow'
import DebouncedQuery from '@components/DebouncedQuery/DebouncedQuery'
import Dropdown from '@components/Toolbar/RadioFilter'
import { useRoleAwareBusinessFilterQueryParams } from '../../Toolbar/RoleAwareBusinessFilter/useRoleAwareBusinessFilterQueryParams'
import OutletLink from '@components/OpenStatus/OutletLink'
import { canView, isAtLeastMarketplaceUser } from '@stores/userStore'
import colors from '@styles/colors'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { Col, Row } from 'react-simple-flex-grid'
import { differenceInYears, format, getYear, parseISO } from 'date-fns'
import { Formik } from 'formik'
import { formatAddressObjToString } from '@utils/helpers'
import { client } from '@services/client'
import GET_DPRACTIVITIES from './queries/getDPRActivities.query'
import { errorToast } from '@utils/toast'
import { validation } from './validation'

const DEFAULT_ORDER = 'name_ASC'

const PageLayoutWrapper = ({ product, children }) => {
  const { configurePageLayout } = useContext(PageLayoutContext)

  useEffect(() => {
    const tabs = []

    if (canView('sales')) {
      tabs.push({ to: '/reports/compliance/dac7', name: 'DAC7' })
    }

    configurePageLayout({
      product,
      tabs,
    })
  }, [configurePageLayout, product])

  return children
}

const DAC7 = ({ product }) => {
  const [reportGenerating, setReportGenerating] = useState({
    progress: 0,
    total: 0,
    status: 'Building',
  })

  const [
    {
      activeFilter,
      directPaymentsFilter,
      orderBy = null,
      generateReport = false,
    },
    setQueryParams,
  ] = useQueryParams({
    activeFilter: StringParam,
    directPaymentsFilter: StringParam,
    orderBy: StringParam,
    generateReport: BooleanParam,
  })

  const {
    marketplaceIds: marketplaceFilter,
    onChangeMarketplaceIds: onChangeMarketplaceFilter,
  } = useRoleAwareBusinessFilterQueryParams()

  const handleActiveFilter = ({ id }) => {
    setQueryParams({
      activeFilter: activeFilter === id ? undefined : id,
    })
  }

  const handleDirectPaymentsFilter = ({ id }) => {
    setQueryParams({
      directPaymentsFilter: directPaymentsFilter === id ? undefined : id,
    })
  }

  const setOrderBySelected = (key, order) => {
    setQueryParams({
      orderBy: order === 'NONE' ? undefined : `${key}_${order}`,
    })
  }

  const resetFilters = () => {
    setQueryParams({
      activeFilter: undefined,
      directPaymentsFilter: undefined,
      orderBy: undefined,
    })
    onChangeMarketplaceFilter(undefined)
  }

  const splitName = fullName => {
    return fullName.split(' ').reduce(
      (acc, part, index, array) => {
        if (index === 0) {
          acc[0] = part
        } else if (index === array.length - 1) {
          acc[1] = part
        } else {
          acc[0] += ` ${part}`
        }
        return acc
      },
      ['', '']
    )
  }

  // REPORT GENERATION - Needs refactoring out of here later.

  // Define the relevant namespaces
  const DPI_NS = 'urn:oecd:ties:dpi:v1'
  const STF_NS = 'urn:oecd:ties:dpistf:v1'

  // Function to create <dpi:Identity> element
  const createIdentityElement = (doc, outlet) => {
    // Create <dpi:Identity>
    const identityElement = doc.createElementNS(DPI_NS, 'dpi:Identity')

    // Create <dpi:EntitySeller>
    const entitySellerElement = doc.createElementNS(
      DPI_NS,
      outlet.companyType === 'INDIVIDUAL'
        ? 'dpi:IndividualSeller'
        : 'dpi:EntitySeller'
    )
    identityElement.appendChild(entitySellerElement)

    // Create <dpi:Standard>
    const standardElement = doc.createElementNS(DPI_NS, 'dpi:Standard')
    entitySellerElement.appendChild(standardElement)

    // Create <dpi:EntSellerID>
    const entSellerIDElement = doc.createElementNS(
      DPI_NS,
      outlet.companyType === 'INDIVIDUAL'
        ? 'dpi:IndSellerID'
        : 'dpi:EntSellerID'
    )
    standardElement.appendChild(entSellerIDElement)

    // Create <dpi:ResCountryCode>
    const resCountryCodeElement = doc.createElementNS(
      DPI_NS,
      'dpi:ResCountryCode'
    )
    resCountryCodeElement.textContent =
      outlet.contactAddress.country.code.toUpperCase()
    entSellerIDElement.appendChild(resCountryCodeElement)

    // Create <dpi:TIN>
    const tinElement = doc.createElementNS(DPI_NS, 'dpi:TIN')
    tinElement.setAttribute(
      'issuedBy',
      outlet.contactAddress.country.code.toUpperCase()
    )
    tinElement.textContent =
      outlet.companyType === 'INDIVIDUAL'
        ? outlet.taxReference
        : outlet.companyNumber
    entSellerIDElement.appendChild(tinElement)

    // Create <dpi:VAT>

    if (outlet.vatNumber || outlet.restaurant.vatNumber) {
      const vatElement = doc.createElementNS(DPI_NS, 'dpi:VAT')
      vatElement.textContent = outlet.vatNumber || outlet.restaurant.vatNumber
      entSellerIDElement.appendChild(vatElement)
    }

    // Create <dpi:Name>
    const nameElement = doc.createElementNS(DPI_NS, 'dpi:Name')

    if (outlet.companyType === 'INDIVIDUAL') {
      const [firstName, lastName] = splitName(outlet.contactName)

      const firstNameElement = doc.createElementNS(DPI_NS, 'dpi:FirstName')
      firstNameElement.setAttribute('xnlNameType', 'Forename')
      firstNameElement.textContent = firstName
      nameElement.appendChild(firstNameElement)

      const lastNameElement = doc.createElementNS(DPI_NS, 'dpi:LastName')
      lastNameElement.setAttribute('xnlNameType', 'Surname')
      lastNameElement.textContent = lastName
      nameElement.appendChild(lastNameElement)
    } else {
      nameElement.textContent = outlet.companyLegalName
    }

    entSellerIDElement.appendChild(nameElement)

    // Create <dpi:Address>
    const addressElement = doc.createElementNS(DPI_NS, 'dpi:Address')
    addressElement.setAttribute(
      'legalAddressType',
      outlet.companyType === 'INDIVIDUAL' ? 'OECD301' : 'OECD303'
    )

    // Create <dpi:CountryCode>
    const countryCodeElement = doc.createElementNS(DPI_NS, 'dpi:CountryCode')
    countryCodeElement.textContent =
      outlet.contactAddress.country.code.toUpperCase()
    addressElement.appendChild(countryCodeElement)

    // Create <dpi:AddressFree>
    const addressFreeElement = doc.createElementNS(DPI_NS, 'dpi:AddressFree')
    addressFreeElement.textContent = formatAddressObjToString(
      outlet.contactAddress
    )
    addressElement.appendChild(addressFreeElement)

    entSellerIDElement.appendChild(addressElement)

    // Create <dpi:BirthInfo>
    if (outlet.companyType === 'INDIVIDUAL' && outlet.contactDoB) {
      const birthInfoElement = doc.createElementNS(DPI_NS, 'dpi:BirthInfo')
      const birthDateElement = doc.createElementNS(DPI_NS, 'dpi:BirthDate')
      birthDateElement.textContent = format(
        parseISO(outlet.contactDoB),
        'yyyy-MM-dd'
      )
      birthInfoElement.appendChild(birthDateElement)
      entSellerIDElement.appendChild(birthInfoElement)
    }

    // Create <dpi:FinancialIdentifier>
    const financialIdentifierElement = doc.createElementNS(
      DPI_NS,
      'dpi:FinancialIdentifier'
    )
    standardElement.appendChild(financialIdentifierElement)

    // Create <dpi:Identifier>
    const identifierElement = doc.createElementNS(DPI_NS, 'dpi:Identifier')
    identifierElement.setAttribute('AccountNumberType', 'Bank')
    identifierElement.textContent = `${outlet.bankSort} ${outlet.bankAccount}`

    financialIdentifierElement.appendChild(identifierElement)

    // Create <dpi:AccountHolderName>
    const accountHolderNameElement = doc.createElementNS(
      DPI_NS,
      'dpi:AccountHolderName'
    )
    accountHolderNameElement.textContent =
      outlet.companyType === 'INDIVIDUAL'
        ? outlet.contactName
        : outlet.companyLegalName
    financialIdentifierElement.appendChild(accountHolderNameElement)

    return identityElement
  }

  const createRelevantActivitiesElement = (doc, periods) => {
    // Create <dpi:RelevantActivities>
    const relevantActivities = doc.createElementNS(
      DPI_NS,
      'dpi:RelevantActivities'
    )

    // Create <dpi:SaleOfGoods>
    const saleOfGoods = doc.createElementNS(DPI_NS, 'dpi:SaleOfGoods')
    relevantActivities.appendChild(saleOfGoods)

    // Create <dpi:Consideration>
    const consideration = doc.createElementNS(DPI_NS, 'dpi:Consideration')
    saleOfGoods.appendChild(consideration)

    // Create <dpi:NumberOfActivities>
    const numberOfActivities = doc.createElementNS(
      DPI_NS,
      'dpi:NumberOfActivities'
    )
    saleOfGoods.appendChild(numberOfActivities)

    // Create <dpi:Fees>
    const fees = doc.createElementNS(DPI_NS, 'dpi:Fees')
    saleOfGoods.appendChild(fees)

    // Create <dpi:Taxes>
    const taxes = doc.createElementNS(DPI_NS, 'dpi:Taxes')
    saleOfGoods.appendChild(taxes)

    // Initialize default values for each quarter
    const defaultValues = {
      Q1: {
        totalNetSalesAfterRefunds: 0,
        totalTransactions: 0,
        totalGrossMarketplace: 0,
        totalTaxes: 0,
      },
      Q2: {
        totalNetSalesAfterRefunds: 0,
        totalTransactions: 0,
        totalGrossMarketplace: 0,
        totalTaxes: 0,
      },
      Q3: {
        totalNetSalesAfterRefunds: 0,
        totalTransactions: 0,
        totalGrossMarketplace: 0,
        totalTaxes: 0,
      },
      Q4: {
        totalNetSalesAfterRefunds: 0,
        totalTransactions: 0,
        totalGrossMarketplace: 0,
        totalTaxes: 0,
      },
    }

    // Update default values with actual data
    periods.forEach(period => {
      defaultValues[period.period.split('-')[1]] = period
    })

    // Populate the XML structure with the values
    Object.keys(defaultValues).forEach(quarter => {
      const period = defaultValues[quarter]

      // Populate <dpi:Consideration>
      const consQ = doc.createElementNS(DPI_NS, `dpi:Cons${quarter}`)
      consQ.setAttribute('currCode', 'GBP')
      consQ.textContent = period.totalNetSalesAfterRefunds
      consideration.appendChild(consQ)

      // Populate <dpi:NumberOfActivities>
      const numbQ = doc.createElementNS(DPI_NS, `dpi:Numb${quarter}`)
      numbQ.textContent = period.totalTransactions
      numberOfActivities.appendChild(numbQ)

      // Populate <dpi:Fees>
      const feesQ = doc.createElementNS(DPI_NS, `dpi:Fees${quarter}`)
      feesQ.setAttribute('currCode', 'GBP')
      feesQ.textContent = period.totalGrossMarketplace
      fees.appendChild(feesQ)

      // Populate <dpi:Taxes>
      const taxQ = doc.createElementNS(DPI_NS, `dpi:Tax${quarter}`)
      taxQ.setAttribute('currCode', 'GBP')
      taxQ.textContent = period.totalTaxes
      taxes.appendChild(taxQ)
    })

    return relevantActivities
  }

  async function downloadXmlReport(values, outlets) {
    // 2. Create the XML document with the root element <dpi:DPI_OECD>
    const doc = document.implementation.createDocument(
      DPI_NS,
      'dpi:DPI_OECD',
      null
    )
    const root = doc.documentElement
    const MessageRefId = `${values.countryCode}${values.reportingYear}${
      values.countryCode
    }-DPI${values.id.toUpperCase()}SaleOfGoodsNew`

    // 3. Set attributes on <dpi:DPI_OECD>
    root.setAttribute('version', '1.0')
    root.setAttribute('xmlns:dpi', DPI_NS)

    // 4. Create and append <dpi:MessageSpec>
    const messageSpec = doc.createElementNS(DPI_NS, 'dpi:MessageSpec')
    // The original example redeclares xmlns:dpi on each child; replicate if strictly required:
    messageSpec.setAttribute('xmlns:dpi', DPI_NS)
    root.appendChild(messageSpec)

    // 5. Add child elements to <dpi:MessageSpec>
    const sendingEntityIN = doc.createElementNS(DPI_NS, 'dpi:SendingEntityIN')
    sendingEntityIN.textContent = values.sendingEntityIN
    messageSpec.appendChild(sendingEntityIN)

    const transmittingCountry = doc.createElementNS(
      DPI_NS,
      'dpi:TransmittingCountry'
    )
    transmittingCountry.textContent = values.countryCode
    messageSpec.appendChild(transmittingCountry)

    const receivingCountry = doc.createElementNS(DPI_NS, 'dpi:ReceivingCountry')
    receivingCountry.textContent = values.countryCode
    messageSpec.appendChild(receivingCountry)

    const messageType = doc.createElementNS(DPI_NS, 'dpi:MessageType')
    messageType.textContent = 'DPI'
    messageSpec.appendChild(messageType)

    const messageRefId = doc.createElementNS(DPI_NS, 'dpi:MessageRefId')
    messageRefId.textContent = MessageRefId
    messageSpec.appendChild(messageRefId)

    const messageTypeIndic = doc.createElementNS(DPI_NS, 'dpi:MessageTypeIndic')
    messageTypeIndic.textContent = 'DPI401'
    messageSpec.appendChild(messageTypeIndic)

    const reportingPeriod = doc.createElementNS(DPI_NS, 'dpi:ReportingPeriod')
    reportingPeriod.textContent = `${values.reportingYear}-12-31`
    messageSpec.appendChild(reportingPeriod)

    const timestamp = doc.createElementNS(DPI_NS, 'dpi:Timestamp')
    timestamp.textContent = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
    messageSpec.appendChild(timestamp)

    // 6. Create and append <dpi:DPIBody>
    const dpiBody = doc.createElementNS(DPI_NS, 'dpi:DPIBody')
    dpiBody.setAttribute('xmlns:dpi', DPI_NS)
    root.appendChild(dpiBody)

    // 7. Build <dpi:PlatformOperator> inside <dpi:DPIBody>
    const platformOperator = doc.createElementNS(DPI_NS, 'dpi:PlatformOperator')
    dpiBody.appendChild(platformOperator)

    const resCountryCode = doc.createElementNS(DPI_NS, 'dpi:ResCountryCode')
    resCountryCode.textContent = values.countryCode
    platformOperator.appendChild(resCountryCode)

    const tin = doc.createElementNS(DPI_NS, 'dpi:TIN')
    tin.setAttribute('issuedBy', values.countryCode)
    tin.textContent = values.tin
    platformOperator.appendChild(tin)

    const operatorName = doc.createElementNS(DPI_NS, 'dpi:Name')
    operatorName.textContent = values.tradingName
    platformOperator.appendChild(operatorName)

    const platformBusinessName = doc.createElementNS(
      DPI_NS,
      'dpi:PlatformBusinessName'
    )
    platformBusinessName.textContent = values.name
    platformOperator.appendChild(platformBusinessName)

    const address = doc.createElementNS(DPI_NS, 'dpi:Address')
    address.setAttribute('legalAddressType', 'OECD304')
    platformOperator.appendChild(address)

    const countryCode = doc.createElementNS(DPI_NS, 'dpi:CountryCode')
    countryCode.textContent = values.countryCode
    address.appendChild(countryCode)

    const addressFree = doc.createElementNS(DPI_NS, 'dpi:AddressFree')
    addressFree.textContent = values.contactAddress
    address.appendChild(addressFree)

    const assumedReporting = doc.createElementNS(DPI_NS, 'dpi:AssumedReporting')
    assumedReporting.textContent = 'false'
    platformOperator.appendChild(assumedReporting)

    // 8. Build the nested <dpi:DocSpec> for the PlatformOperator
    const docSpecPlatform = doc.createElementNS(DPI_NS, 'dpi:DocSpec')
    platformOperator.appendChild(docSpecPlatform)

    const docTypeIndicPlatform = doc.createElementNS(STF_NS, 'stf:DocTypeIndic')
    docTypeIndicPlatform.textContent = 'OECD1'
    docSpecPlatform.appendChild(docTypeIndicPlatform)

    const docRefIdPlatform = doc.createElementNS(STF_NS, 'stf:DocRefId')
    docRefIdPlatform.textContent = `${MessageRefId}_${values.marketplaceId.toUpperCase()}_MARKETPLACE`
    docSpecPlatform.appendChild(docRefIdPlatform)

    // 9. Build the <dpi:ReportableSeller> elements
    const promises = outlets.map((outlet, index) => {
      setReportGenerating({
        progress: index + 1,
        total: outlets.length,
        status: `Transactions for '${outlet.name}'`,
      })
      return client
        .query({
          query: GET_DPRACTIVITIES,
          variables: {
            afterDate: new Date(values.reportingYear, 0, 1).toISOString(),
            beforeDate: new Date(
              values.reportingYear,
              11,
              31,
              23,
              59,
              59
            ).toISOString(),
            marketplaceId: values.marketplaceId,
            outletId: outlet.id,
          },
        })
        .then(result => {
          const periods =
            result &&
            result.data &&
            result.data.activities &&
            result.data.activities.periods

          if (!periods || periods.length === 0) return // No need to report outlet if they had no transactions.

          const reportableSeller = doc.createElementNS(
            DPI_NS,
            'dpi:ReportableSeller'
          )
          dpiBody.appendChild(reportableSeller)

          // Add the seller <dpi:Identity>
          reportableSeller.appendChild(createIdentityElement(doc, outlet))

          // Add the seller <dpi:RelevantActivities>
          reportableSeller.appendChild(
            createRelevantActivitiesElement(doc, periods)
          )

          // Add the seller <dpi:DocSpec>
          const docSpecSeller = doc.createElementNS(DPI_NS, 'dpi:DocSpec')
          reportableSeller.appendChild(docSpecSeller)

          const docTypeIndicSeller = doc.createElementNS(
            STF_NS,
            'stf:DocTypeIndic'
          )
          docTypeIndicSeller.textContent = 'OECD1'
          docSpecSeller.appendChild(docTypeIndicSeller)

          const docRefIdSeller = doc.createElementNS(STF_NS, 'stf:DocRefId')
          docRefIdSeller.textContent = `${MessageRefId}_${outlet.id.toUpperCase()}_SELLER`
          docSpecSeller.appendChild(docRefIdSeller)
        })
    })

    try {
      await Promise.all(promises)

      // 10. Serialize to string, including XML declaration
      const serializer = new XMLSerializer()
      const xmlWithoutDeclaration = serializer.serializeToString(doc)
      const xmlString =
        '<?xml version="1.0" encoding="utf-8"?>\n' + xmlWithoutDeclaration

      // 11. Create a Blob and download link
      const blob = new Blob([xmlString], { type: 'application/xml' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${MessageRefId}.xml`
      document.body.appendChild(link)
      link.click()

      // Clean up
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error generating report:', error)
      setReportGenerating({
        progress: 0,
        total: 0,
        status: 'Error',
      })
      setQueryParams({
        generateReport: false,
      })
      errorToast(
        'There was an error generating the report. Please try again or contact support@redbox.systems for assistance.'
      )
    }
  }

  const outletComplianceCheck = outlet => {
    const hasBankAccount = outlet.bankAccount && outlet.bankSort
    const hasCompanyDetails = outlet.companyLegalName && outlet.companyNumber
    const hasContactDetails =
      outlet.contactName && outlet.contactPhone && outlet.contactEmail
    const hasTin = outlet.taxReference && outlet.taxReference.length > 0
    const hasDob =
      outlet.contactDoB &&
      differenceInYears(new Date(), parseISO(outlet.contactDoB)) >= 16
    const hasAddress =
      outlet.contactAddress &&
      outlet.contactAddress.firstLine &&
      outlet.contactAddress.city &&
      outlet.contactAddress.postcode &&
      outlet.contactAddress.country &&
      outlet.contactAddress.country.name

    let isCompliant = false

    if (
      ['COMPANY', 'NON_PROFIT'].includes(outlet.companyType) &&
      hasCompanyDetails &&
      hasContactDetails &&
      hasAddress &&
      hasBankAccount
    ) {
      isCompliant = true
    }

    if (
      outlet.companyType === 'PARTNERSHIP' &&
      hasCompanyDetails &&
      hasContactDetails &&
      hasAddress &&
      hasBankAccount &&
      hasTin
    ) {
      isCompliant = true
    }
    if (
      outlet.companyType === 'INDIVIDUAL' &&
      hasContactDetails &&
      hasAddress &&
      hasBankAccount &&
      hasTin &&
      hasDob
    ) {
      isCompliant = true
    }

    return {
      hasBankAccount,
      hasCompanyDetails,
      hasContactDetails,
      hasAddress,
      isCompliant,
    }
  }

  const renderFilterBar = () => (
    <FilterRow>
      <ButtonGroup>
        <ControlGroup>
          <MarketplaceFilter icon="filter" />
          <Dropdown
            selected={activeFilter}
            setSelected={handleActiveFilter}
            placeholder="Active"
            items={[
              { name: 'All', id: null },
              { name: 'Active', id: 'true' },
              { name: 'Not Active', id: 'false' },
            ]}
          />

          <Dropdown
            selected={directPaymentsFilter}
            setSelected={handleDirectPaymentsFilter}
            placeholder="Payment"
            items={[
              { name: 'All', id: null },
              { name: 'Manual', id: 'false' },
              { name: 'Paybox', id: 'true' },
            ]}
          />
        </ControlGroup>
      </ButtonGroup>
    </FilterRow>
  )

  return (
    <PageLayoutWrapper product={product}>
      <div className="bp3-table-frame">
        {renderFilterBar()}
        {marketplaceFilter.length === 1 ? (
          <DebouncedQuery
            query={GET_DPROUTLETS}
            variables={{
              orderBy: orderBy || DEFAULT_ORDER,
              marketplaceIds: marketplaceFilter,
              isActive: activeFilter ? activeFilter === 'true' : undefined,
              isDirectPayment: directPaymentsFilter
                ? directPaymentsFilter === 'true'
                : undefined,
            }}
            loaderTitle="Loading Outlets"
            ErrorComponent={
              <NonIdealState
                icon="th-list"
                title="Loading Error"
                description="Unable to load outlet data, please retry."
              />
            }
          >
            {data => {
              const outlets = data.getOutlets.outlets
              const complianceReport = {
                title: 'DAC7 Compliance',
                totalOutlets: data.getOutlets.totalCount,
                compliantOutlets: outlets.filter(
                  outlet => outletComplianceCheck(outlet).isCompliant
                ).length,
              }

              return (
                <div className="bp3-table-container bp3-scrollable">
                  {outlets && outlets.length === 0 ? (
                    <div style={{ padding: '100px' }}>
                      <NonIdealState
                        icon="th-list"
                        title="No Outlets Found"
                        description="There are no outlets to display."
                        action={
                          <Button
                            onClick={resetFilters}
                            minimal
                            intent="primary"
                          >
                            Clear Filters
                          </Button>
                        }
                      />
                    </div>
                  ) : (
                    <Fragment>
                      <div
                        style={{
                          maxWidth: '100vw',
                          padding: '24 24 1 24',
                          backgroundColor: '#ffffff',
                        }}
                      >
                        <Row gutter={24}>
                          <Col xs={12} md={6}>
                            <Card
                              style={{
                                minWidth: 'auto',
                                minHeight: 168,
                              }}
                            >
                              {complianceReport.compliantOutlets !==
                              complianceReport.totalOutlets ? (
                                <Fragment>
                                  <H5>
                                    <Icon
                                      icon="warning-sign"
                                      intent="warning"
                                    />{' '}
                                    Information Required
                                  </H5>
                                  <p>
                                    Please gather the necessary information from
                                    your outlets to ensure compliance with DAC7.
                                  </p>
                                  {complianceReport.totalOutlets > 0 && (
                                    <ProgressBar
                                      stripes={false}
                                      value={
                                        complianceReport.compliantOutlets /
                                        complianceReport.totalOutlets
                                      }
                                      animate={false}
                                      intent={'success'}
                                    />
                                  )}
                                  <br />
                                  <p className="bp3-text-small bp3-text-muted">
                                    {complianceReport.compliantOutlets} /{' '}
                                    {complianceReport.totalOutlets} Outlets
                                    Completed
                                  </p>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <H5>
                                    <Icon
                                      icon="endorsed"
                                      color={colors.onlineGreen}
                                    />{' '}
                                    DPR Ready
                                  </H5>
                                  <p>
                                    All your outlets have provided data, you can
                                    now generate reports.
                                  </p>
                                  <Button
                                    text="Generate Report"
                                    // link to this page but with a query string generate=true with the parketplace.id in the filters maye add rwather then all of ur?
                                    onClick={() => {
                                      setQueryParams({
                                        generateReport: true,
                                      })
                                    }}
                                    rightIcon="play"
                                    intent={Intent.SUCCESS}
                                  />
                                </Fragment>
                              )}
                            </Card>
                          </Col>
                          <Col xs={12} md={6}>
                            <Card
                              style={{
                                minWidth: 'auto',
                                minHeight: 168,
                              }}
                            >
                              <H5>Digital Platforms Report (DAC7)</H5>
                              <p>
                                DAC7 is a European Union directive aimed at
                                improving tax transparency by requiring digital
                                platforms to report the income earned by sellers
                                using their services. This ensures compliance
                                with tax obligations across member states and
                                supports fair taxation in the digital economy.
                              </p>
                              <AnchorButton
                                text="HMRC Reporting Rules"
                                href="https://www.gov.uk/guidance/reporting-rules-for-digital-platforms"
                                target="_blank"
                                rel="noopener noreferrer"
                                rightIcon="document-open"
                                intent={Intent.NONE}
                              />
                            </Card>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Card className="bp3-nopad">
                          <HTMLTable bordered={false} interactive={true}>
                            <TableHeader
                              setSelected={setOrderBySelected}
                              selected={orderBy}
                            />
                            <tbody>
                              {outlets.map(outlet => {
                                const outletCompliance =
                                  outletComplianceCheck(outlet)
                                return (
                                  <tr key={outlet.id}>
                                    {/* Outlet */}
                                    <td>
                                      {outletCompliance.isCompliant ? (
                                        <Icon
                                          icon="endorsed"
                                          color={colors.onlineGreen}
                                        />
                                      ) : (
                                        <Icon
                                          icon="warning-sign"
                                          intent="warning"
                                        />
                                      )}{' '}
                                      <OutletLink
                                        tab="details"
                                        outlet={outlet}
                                        showStatus={false}
                                      />
                                    </td>
                                    {/* Marketplace */}
                                    <td>
                                      {outlet.marketplace && (
                                        <Fragment>
                                          {isAtLeastMarketplaceUser() ? (
                                            <Link
                                              to={`/marketplaces/${outlet.marketplace.id}`}
                                            >
                                              {outlet.marketplace.name}
                                            </Link>
                                          ) : (
                                            outlet.marketplace.name
                                          )}
                                        </Fragment>
                                      )}
                                    </td>
                                    <td colSpan={outlet.companyType ? 1 : 3}>
                                      {outlet.companyType ? (
                                        <Tag minimal>{outlet.companyType}</Tag>
                                      ) : (
                                        <Fragment>
                                          <Tooltip
                                            content={
                                              'Legal business entity required.'
                                            }
                                          >
                                            <Icon
                                              icon="issue"
                                              intent="warning"
                                            />
                                          </Tooltip>{' '}
                                          <Link
                                            to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`}
                                          >
                                            Add Entity
                                          </Link>
                                        </Fragment>
                                      )}
                                    </td>
                                    {outlet.companyType && (
                                      <td>
                                        {outlet.companyType === 'INDIVIDUAL' ? (
                                          <span className="bp3-text-disabled">
                                            -
                                          </span>
                                        ) : (
                                          <Fragment>
                                            {outlet.companyLegalName ? (
                                              outlet.companyLegalName
                                            ) : (
                                              <Fragment>
                                                <Tooltip
                                                  content={
                                                    'Company Legal Name is required.'
                                                  }
                                                >
                                                  <Icon
                                                    icon="issue"
                                                    intent="warning"
                                                  />
                                                </Tooltip>{' '}
                                                <Link
                                                  to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`}
                                                >
                                                  Add Company
                                                </Link>
                                              </Fragment>
                                            )}
                                          </Fragment>
                                        )}
                                      </td>
                                    )}
                                    {outlet.companyType && (
                                      <td>
                                        {outlet.companyType === 'INDIVIDUAL' ? (
                                          <span className="bp3-text-disabled">
                                            -
                                          </span>
                                        ) : (
                                          <Fragment>
                                            {outlet.companyNumber ? (
                                              outlet.companyNumber
                                            ) : (
                                              <Fragment>
                                                <Tooltip
                                                  content={
                                                    'Registered Company Number is required, you can find this on companies house.'
                                                  }
                                                >
                                                  <Icon
                                                    icon="issue"
                                                    intent="warning"
                                                  />
                                                </Tooltip>{' '}
                                                <Link
                                                  to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`}
                                                >
                                                  Add Identifier
                                                </Link>
                                              </Fragment>
                                            )}
                                          </Fragment>
                                        )}
                                      </td>
                                    )}

                                    {/* Contact */}
                                    <td>
                                      {outletCompliance.hasContactDetails ? (
                                        <Popover
                                          interactionKind={
                                            PopoverInteractionKind.HOVER
                                          }
                                          position={Position.RIGHT_TOP}
                                          popoverClassName={
                                            Classes.POPOVER_CONTENT_SIZING
                                          }
                                          content={
                                            <Fragment>
                                              <H5>{outlet.contactName}</H5>
                                              <p>
                                                {outlet.contactPhone}
                                                <br />
                                                {outlet.contactEmail && (
                                                  <a
                                                    href={`mailto:${outlet.contactEmail}`}
                                                  >
                                                    {outlet.contactEmail}
                                                  </a>
                                                )}
                                              </p>
                                              <p>
                                                {
                                                  outlet.contactAddress
                                                    .firstLine
                                                }
                                                <br />
                                                {outlet.contactAddress.city}
                                                <br />
                                                {outlet.contactAddress.postcode}
                                                <br />
                                                {
                                                  outlet.contactAddress.country
                                                    .name
                                                }
                                              </p>
                                            </Fragment>
                                          }
                                          className={Classes.TOOLTIP_INDICATOR}
                                        >
                                          {outlet.contactName}
                                        </Popover>
                                      ) : (
                                        <Fragment>
                                          <Tooltip
                                            content={
                                              'Required information must be provided.'
                                            }
                                          >
                                            <Icon
                                              icon="issue"
                                              intent="warning"
                                            />
                                          </Tooltip>{' '}
                                          <Link
                                            to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`}
                                          >
                                            Add Contact
                                          </Link>
                                        </Fragment>
                                      )}{' '}
                                      {!outletCompliance.hasAddress && (
                                        <Fragment>
                                          <Tooltip
                                            content={'Address is required.'}
                                          >
                                            <Icon
                                              icon="issue"
                                              intent="warning"
                                            />
                                          </Tooltip>{' '}
                                          <Link
                                            to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`}
                                          >
                                            Add Address
                                          </Link>
                                        </Fragment>
                                      )}
                                    </td>
                                    {/* Date of Birth */}
                                    <td>
                                      {outlet.companyType === 'INDIVIDUAL' ? (
                                        <Fragment>
                                          {outlet.contactDoB ? (
                                            format(
                                              parseISO(outlet.contactDoB),
                                              'dd-MM-yyyy'
                                            )
                                          ) : (
                                            <Fragment>
                                              <Tooltip
                                                content={
                                                  'Date of Birth must be provided for individuals / sole traders.'
                                                }
                                              >
                                                <Icon
                                                  icon="issue"
                                                  intent="warning"
                                                />
                                              </Tooltip>{' '}
                                              <Link
                                                to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`}
                                              >
                                                Add DoB
                                              </Link>
                                            </Fragment>
                                          )}
                                        </Fragment>
                                      ) : (
                                        <span className="bp3-text-disabled">
                                          -
                                        </span>
                                      )}
                                    </td>
                                    {/* Tax Reference */}
                                    <td>
                                      {outlet.companyType === 'INDIVIDUAL' ? (
                                        <Fragment>
                                          {outlet.taxReference ? (
                                            outlet.taxReference
                                          ) : (
                                            <Fragment>
                                              <Tooltip
                                                content={
                                                  'Tax Identification must be provided for individuals / sole traders.'
                                                }
                                              >
                                                <Icon
                                                  icon="issue"
                                                  intent="warning"
                                                />
                                              </Tooltip>{' '}
                                              <Link
                                                to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`}
                                              >
                                                Add TIN
                                              </Link>
                                            </Fragment>
                                          )}
                                        </Fragment>
                                      ) : (
                                        <span className="bp3-text-disabled">
                                          -
                                        </span>
                                      )}
                                    </td>
                                    {/* VAT Registered */}
                                    <td>
                                      {(outlet.isVATregistered &&
                                        outlet.vatNumber) ||
                                      (outlet.restaurant.isVATregistered &&
                                        outlet.restaurant.vatNumber) ? (
                                        outlet.vatNumber ||
                                        outlet.restaurant.vatNumber
                                      ) : (
                                        <span className="bp3-text-disabled">
                                          -
                                        </span>
                                      )}
                                    </td>
                                    {/* Bank Account */}
                                    <td>
                                      {outletCompliance.hasBankAccount ? (
                                        <span className="bp3-text-monospace bp3-text-muted">
                                          {outlet.bankAccount} {outlet.bankSort}
                                        </span>
                                      ) : (
                                        <Fragment>
                                          <Tooltip
                                            content={
                                              'Date of Birth must be provided for individuals / sole traders.'
                                            }
                                          >
                                            <Icon
                                              icon="issue"
                                              intent="warning"
                                            />
                                          </Tooltip>{' '}
                                          <Link
                                            to={`/business/${outlet.restaurant.id}/outlets/${outlet.id}/details`}
                                          >
                                            Add Bank
                                          </Link>
                                        </Fragment>
                                      )}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </HTMLTable>
                        </Card>
                      </div>
                      <Dialog
                        isOpen={!!generateReport}
                        onClose={() => {
                          setQueryParams({
                            generateReport: false,
                          })
                        }}
                        style={{ top: 100, position: 'absolute' }}
                        title={'DPI Reporting Assistant'}
                        icon={'projects'}
                      >
                        <DebouncedQuery
                          query={GET_DPRMARKETPLACE}
                          variables={{
                            marketplaceId: marketplaceFilter[0],
                          }}
                          loaderTitle="Checking Marketplace"
                          ErrorComponent={
                            <NonIdealState
                              icon="th-list"
                              title="Loading Error"
                              description="Unable to load marketplace data, please retry."
                            />
                          }
                        >
                          {data => {
                            const marketplace = data.getMarketplaces.regions[0]
                            const currentYear = getYear(new Date())

                            return (
                              <Formik
                                initialValues={{
                                  id: marketplace.id,
                                  marketplaceId: marketplace.id,
                                  name: marketplace.name,
                                  companyType: marketplace.companyType,
                                  tin:
                                    marketplace.companyType === 'INDIVIDUAL'
                                      ? marketplace.taxReference
                                      : marketplace.companyNumber,
                                  tradingName:
                                    marketplace.companyType === 'INDIVIDUAL'
                                      ? marketplace.contactName
                                      : marketplace.companyLegalName,
                                  countryCode:
                                    marketplace.contactAddress.country.code.toUpperCase(),
                                  contactAddress: formatAddressObjToString(
                                    marketplace.contactAddress
                                  ),
                                  reportingYear: (currentYear - 1).toString(),
                                }}
                                validationSchema={validation}
                                onSubmit={values => {
                                  downloadXmlReport(values, outlets)
                                }}
                              >
                                {({
                                  handleChange,
                                  handleSubmit,
                                  errors,
                                  initialValues,
                                }) => {
                                  return (
                                    <form onSubmit={handleSubmit}>
                                      <div className={Classes.DIALOG_BODY}>
                                        {Object.keys(errors).length > 0 && (
                                          <Fragment>
                                            <Callout
                                              intent="danger"
                                              title="Incomplete Marketplace Data"
                                            >
                                              <ul>
                                                {Object.keys(errors).map(
                                                  (errorKey, index) => (
                                                    <li key={index}>
                                                      {errors[errorKey]}
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                              <AnchorButton
                                                text="Update Details"
                                                href={`/marketplaces/${initialValues.marketplaceId}/details`}
                                              />
                                            </Callout>
                                            <br />
                                          </Fragment>
                                        )}
                                        <Row>
                                          <Col
                                            span={10}
                                            className="bp3-text-small bp3-text-muted"
                                          >
                                            <p>
                                              Please ensure all details are
                                              correct before submission; this
                                              service is only an aid, not legal
                                              or tax advice, and you remain
                                              responsible for verifying the
                                              completeness and accuracy of the
                                              data submitted.
                                            </p>
                                          </Col>
                                          <Col
                                            span={2}
                                            style={{ textAlign: 'right' }}
                                          >
                                            <Tag intent="success">BETA</Tag>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col
                                            span="12"
                                            className="bp3-running-text"
                                          >
                                            <Card
                                              style={{
                                                marginBottom: 10,
                                                marginTop: 20,
                                              }}
                                            >
                                              <FormGroup
                                                label="Registration IN"
                                                subLabel="Your unique reporting ID assigned by the tax authority."
                                                labelFor="sendingEntityIN"
                                              >
                                                <InputGroup
                                                  name="sendingEntityIN"
                                                  id="sendingEntityIN"
                                                  type="text"
                                                  onChange={handleChange}
                                                  placeholder="XZDPI0000000000"
                                                />
                                              </FormGroup>
                                              <FormGroup
                                                label="Reporting Period"
                                                labelFor="reportingYear"
                                                subLabel="We will only include outlets that have transactions in this year."
                                              >
                                                <HTMLSelect
                                                  name="reportingYear"
                                                  id="reportingYear"
                                                  onChange={handleChange}
                                                >
                                                  {Array.from(
                                                    // Generate a list of years from 2023 to the previous year
                                                    {
                                                      length:
                                                        currentYear - 2023,
                                                    },
                                                    (_, index) =>
                                                      currentYear - 1 - index
                                                  ).map(year => (
                                                    <option
                                                      key={year}
                                                      value={year}
                                                    >
                                                      {year}
                                                    </option>
                                                  ))}
                                                </HTMLSelect>
                                              </FormGroup>
                                            </Card>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className={Classes.DIALOG_FOOTER}>
                                        <Button
                                          onClick={() => {}}
                                          type="submit"
                                          text="Generate Report"
                                          icon="download"
                                          rightIcon={<Tag minimal>XML</Tag>}
                                        />
                                      </div>
                                    </form>
                                  )
                                }}
                              </Formik>
                            )
                          }}
                        </DebouncedQuery>
                      </Dialog>
                    </Fragment>
                  )}
                </div>
              )
            }}
          </DebouncedQuery>
        ) : (
          <div style={{ padding: '100px' }}>
            <NonIdealState
              icon="property"
              title="Select Marketplace"
              description="Please select 1 marketplace to load this report."
            />
          </div>
        )}
      </div>
      <Alert
        isOpen={reportGenerating.progress > 0}
        icon={
          reportGenerating.total === reportGenerating.progress
            ? 'tick-circle'
            : 'projects'
        }
        canEscapeKeyCancel={false}
        canOutsideClickCancel={false}
        confirmButtonText={
          reportGenerating.total === reportGenerating.progress
            ? 'Close'
            : 'Cancel'
        }
        onConfirm={() => {
          if (reportGenerating.total === reportGenerating.progress) {
            setReportGenerating({
              progress: 0,
              total: 0,
              status: '',
            })
            setQueryParams({
              generateReport: false,
            })
          } else {
            location.reload() // cancel and reload page.
          }
        }}
      >
        {reportGenerating.total === reportGenerating.progress ? (
          <Fragment>
            <H4>Report Ready!</H4>
            <p>
              Your report will download shortly. Please make sure you check all
              submissions for accuracy.
            </p>
            <ProgressBar intent={'success'} value={1} animate={false} />
          </Fragment>
        ) : (
          <Fragment>
            <H4>Building Report</H4>
            <p>
              <em>{reportGenerating.status}</em>
            </p>
            <ProgressBar
              intent={'primary'}
              value={reportGenerating.progress / reportGenerating.total}
            />
          </Fragment>
        )}
      </Alert>
    </PageLayoutWrapper>
  )
}

export default DAC7

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Spinner } from '@blueprintjs/core'
import ls from '@utils/localStorage'
import { useOtterModal } from './useOtterQueryParam'
import config from '@config/config'

const API_BASE_URL = config.apiUrl + '/api/otter'

// Hook to handle lazy fetch
/**
 * Custom hook for fetching data using the Otter API.
 *
 * @param {Object} options - The options for the fetch request.
 * @param {string} options.path - The API endpoint path.
 * @param {string} [options.method='GET'] - The HTTP method for the request.
 * @returns {[Function, Object]} - A tuple containing the fetch function and an object with the fetched data and error.
 */
export const useLazyOtterFetch = ({ path, method = 'GET' }) => {
  const token = useMemo(() => ls.get('jwt'), [])
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const { isOpen, close } = useOtterModal()

  const fetchData = useCallback(
    async body => {
      setLoading(true)
      // body &&= JSON.stringify(body)
      body = body && JSON.stringify(body)
      try {
        const response = await fetch(`${API_BASE_URL}${path}`, {
          method,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body,
        })

        setLoading(false)
        const json = await response.json().catch(() => {
          if (response.status === 204) {
            return {}
          }

          throw new Error('Invalid JSON response')
        })

        if (!response.ok) {
          setError({ status: response.status, response: json })
          // on auth error, if the modal is open, close it

          if (response.status === 401 && isOpen) {
            close()
          }
        } else {
          setData(json)
          return json
        }
      } catch (error) {
        setError({ status: 500, error: error })
      }
    },
    [method, path, token]
  )

  return [fetchData, { data, error, loading }]
}

export const OtterFetch = ({
  children,
  path,
  method = 'GET',
  ErrorComponent = null,
}) => {
  const [fetchData, { data, error }] = useLazyOtterFetch({ path, method })

  useEffect(() => {
    fetchData()
  }, [])

  if (error) {
    if (ErrorComponent) {
      return <ErrorComponent error={error} />
    }
    return children(null)
  }

  if (!data) {
    return <Spinner />
  }

  return children(data)
}

OtterFetch.displayName = 'OtterFetch'

import React, { Fragment } from 'react'
import { Formik } from 'formik'
import { object, bool } from 'prop-types'
import { Switch } from '@blueprintjs/core'

const MarketplaceAllowExtendedPreorders = ({
  updateStatus,
  allowExtendedPreorders,
}) => {
  return (
    <Formik initialValues={{ allowExtendedPreorders }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={
              <Fragment>
                Allow Preorders{' '}
                <span className="bp3-text-muted">(up to 31 days)</span>
              </Fragment>
            }
            checked={values.allowExtendedPreorders}
            onChange={e => {
              handleChange(e)
              updateStatus({
                allowExtendedPreorders: e.currentTarget.checked,
              })
            }}
            name="allowExtendedPreorders"
          />
        </section>
      )}
    </Formik>
  )
}

MarketplaceAllowExtendedPreorders.propTypes = {
  allowExtendedPreorders: bool,
  errors: object,
}

export default MarketplaceAllowExtendedPreorders

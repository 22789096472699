import { Icon } from '@blueprintjs/core'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import { GET_ITEM_OPTIONS } from '@components/Restaurant/Menu/queries/getItemDetails.query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { without } from 'lodash'
import React from 'react'
import { Mutation } from 'react-apollo'
import EDIT_MENU_ITEM_OPTIONS from '../mutations/editMenuItemOptions.mutation'

const OptionItemSecondaryLabel = ({
  menuItemId,
  optionIds,
  classes,
  optionId,
  openEditOptionModal,
}) => (
  <Mutation
    mutation={EDIT_MENU_ITEM_OPTIONS}
    variables={{
      id: menuItemId,
      optionIds: without(optionIds, optionId),
    }}
    refetchQueries={[
      {
        query: GET_ITEM_OPTIONS,
        variables: { id: menuItemId },
      },
    ]}
    onError={defaultErrorHandler}
    onCompleted={() => {
      successToast('Successfully removed item option')
    }}
  >
    {editMenuItemOptions => (
      <div className={classes.iconContainer}>
        <Icon
          icon="edit"
          className={classes.icon}
          onClick={() => {
            openEditOptionModal()
          }}
        />

        <ConfirmationPopover
          remove={() => editMenuItemOptions()}
          confirmationText="Are you sure you want to delete this option?"
        >
          <Icon icon="trash" className={classes.icon} />
        </ConfirmationPopover>
      </div>
    )}
  </Mutation>
)

export default OptionItemSecondaryLabel

import gql from 'graphql-tag'

const GET_MARKETPLACE = gql`
  query getMarketplaceDashboard($id: ID!) {
    getMarketplaces(id: $id) {
      totalCount
      count
      regions {
        id
        image
        name
        androidVersion
        iosVersion
        enablePayboxEnterprise
        stripeConnect {
          payoutsEnabled
          accountType
          balance
          balancePending
          balanceCurrency
          dashboardLink
          enterprise
        }
        deliveryZones {
          id
        }
        discounts {
          id
        }
        partner {
          id
        }
        outlets {
          id
          active
          terminals {
            id
            isOnline
          }
        }
      }
    }
  }
`

export default GET_MARKETPLACE

import React, { Fragment, useState } from 'react'
import { object, func } from 'prop-types'
import injectSheet from 'react-jss'
import ReactSVG from 'react-svg'
import { withFormik } from 'formik'
import { Mutation } from 'react-apollo'
import { Card } from '@blueprintjs/core'
import ls from '@utils/localStorage'
import get from 'lodash/get'
import { errorToast } from '@utils/toast'

import LoginForm from '@components/Login/LoginForm'
import styles from '@components/Login/Login.styles'
import LOGIN from '@components/Login/mutations/login.mutation'
import logo from '@assets/logo.svg'
import loginIcon from '@assets/icons/login.svg'
import ForgotPasswordModal from '@components/ForgotPassword/ForgotPassword.modal'
import ResetPassword from '@components/ResetPassword/ResetPassword'
import queryString from 'query-string'

const handleLogin = (data, history) => {
  if (data.bypass) {
    ls.set('jwt', data.bypass.token)
    ls.set(
      'permissionCategories',
      get(data.bypass.role, 'permissionCategories')
    )
    ls.set('role', get(data.bypass.role, 'title'))
    ls.set('ownsPartners', data.bypass.ownsPartners)
    history.push('/')
  } else {
    ls.set('partialJwt', data.partialToken)
    ls.set('canUse2FA', data.canUse2FA)
    ls.set('phoneNumber', data.phoneNumber)
    if (data.canUse2FA) {
      history.push('/2fa')
    } else {
      history.push('/validate-phone')
    }
  }
}

const LoginWrapper = props => {
  const { classes, history } = props
  const { container, loginContainer, logoContainer, loginForm, icon } = classes
  const { email, token } = queryString.parse(location.search)
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  return (
    <div className={container}>
      <div className={loginContainer}>
        <ReactSVG src={logo} alt={'logo'} className={logoContainer} />
        {!token && (
          <Fragment>
            <Card elevation="2" className={loginForm}>
              <ReactSVG src={loginIcon} className={icon} />
              <Mutation
                mutation={LOGIN}
                onError={error => {
                  const serverErrors = get(error, 'graphQLErrors', [])

                  // fallback for errors that weren't returned by the server
                  if (!serverErrors.length) {
                    return errorToast(error.message)
                  }

                  // loop through custom server errors displaying error toast
                  for (const serverError of serverErrors) {
                    errorToast(serverError.message)
                  }

                  props.setSubmitting(false)
                }}
                onCompleted={({ loginUser }) => {
                  handleLogin(loginUser, history)
                }}
              >
                {loginUser => (
                  <LoginForm
                    classes={classes}
                    mutation={loginUser}
                    {...props}
                  />
                )}
              </Mutation>
            </Card>
            <Fragment>
              <a onClick={() => setShowForgotPassword(true)} tabIndex={0}>
                Forgotten Password?
              </a>
              <ForgotPasswordModal
                closeModal={() => setShowForgotPassword(false)}
                modalOpen={showForgotPassword}
              />
            </Fragment>
          </Fragment>
        )}
        {token && (
          <ResetPassword email={email} token={token} history={history} />
        )}
      </div>
    </div>
  )
}

const Login = withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
})(LoginWrapper)

LoginWrapper.propTypes = {
  classes: object,
  history: object,
  setSubmitting: func,
}

export default injectSheet(styles)(Login)
export { Login }

import React from 'react'
import { Mutation } from 'react-apollo'
import { Formik } from 'formik'
import { modalType } from '@utils/types'
import { string } from 'prop-types'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import * as yup from 'yup'
import ADD_POS_DEVICE from './mutations/addPOSDevice'
import GET_POS_DEVICES from './queries/getPOSDevices'
import { newPOSDeviceStructure } from '../../../validation/pos-device'
import OutletTypeahead from '../../OutletTypeahead/OutletTypeahead'

const validationSchema = yup.object().shape(newPOSDeviceStructure)

const AddPOSDeviceModal = ({ modalOpen, modalClose, marketplaceId }) => (
  <Mutation
    mutation={ADD_POS_DEVICE}
    refetchQueries={[
      {
        query: GET_POS_DEVICES,
        variables: {
          marketplaceIds: [marketplaceId],
        },
      },
    ]}
    onCompleted={() => {
      successToast('POS device added successfully')
      modalClose()
    }}
    onError={defaultErrorHandler}
  >
    {addPOSDevice => {
      return (
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            marketplaceId,
            friendlyName: '',
            serialNumber: '',
            pin: '',
            locationId: '',
            outletIds: [],
          }}
          enableReinitialize={true}
          onSubmit={async (
            { marketplaceId, outletIds, ...kioskData },
            { resetForm }
          ) => {
            await addPOSDevice({
              variables: {
                marketplaceId,
                outletIds,
                kioskData,
              },
            })
            resetForm()
          }}
        >
          {({ handleChange, handleSubmit, setFieldValue, errors, values }) => (
            <Dialog
              isOpen={modalOpen}
              onClose={modalClose}
              title="Add POS Device"
            >
              <form onSubmit={handleSubmit} className={Classes.DIALOG_BODY}>
                <FormGroup
                  label="Serial Number"
                  labelFor="serialNumber"
                  helperText={errors.serialNumber ? errors.serialNumber : ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    type="text"
                    name="serialNumber"
                    id="serialNumber"
                    value={values.serialNumber}
                    onChange={handleChange}
                    intent={errors.serialNumber ? Intent.DANGER : Intent.NONE}
                  />
                </FormGroup>
                <FormGroup
                  label="Friendly Name"
                  labelFor="friendlyName"
                  helperText={errors.friendlyName ? errors.friendlyName : ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    type="text"
                    name="friendlyName"
                    id="friendlyName"
                    value={values.friendlyName}
                    onChange={handleChange}
                    intent={errors.friendlyName ? Intent.DANGER : Intent.NONE}
                  />
                </FormGroup>
                <FormGroup
                  label="PIN"
                  labelFor="pin"
                  labelInfo="(4 digits long)"
                  helperText={errors.pin ? errors.pin : ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    type="text"
                    id="pin"
                    name="pin"
                    value={values.pin}
                    intent={errors.pin ? Intent.DANGER : Intent.NONE}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup
                  label="Location ID"
                  labelFor="locationId"
                  helperText={errors.locationId ? errors.locationId : ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    type="text"
                    id="locationId"
                    name="locationId"
                    value={values.locationId}
                    intent={errors.locationId ? Intent.DANGER : Intent.NONE}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup label="Outlets">
                  <OutletTypeahead
                    placeholder="Select outlet(s)"
                    showRestaurantName={true}
                    showMarketplaceName={false}
                    alwaysMultiSelect={true}
                    onChange={outletIds => {
                      setFieldValue('outletIds', outletIds)
                    }}
                    outletIds={values.outletIds}
                    marketplaceId={marketplaceId}
                  />
                </FormGroup>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button type="submit" text={'Create'} />
                </div>
              </form>
            </Dialog>
          )}
        </Formik>
      )
    }}
  </Mutation>
)

AddPOSDeviceModal.propTypes = {
  ...modalType,
  marketplaceId: string,
}

export default AddPOSDeviceModal

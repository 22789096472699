import React from 'react'
import { matchType } from '@utils/types'
import injectSheet from 'react-jss'
import { object, bool } from 'prop-types'

import { styles } from './Menu.styles'
import { Query as CacheQuery } from 'react-apollo'
import MENU_QUERYS from '@components/Restaurant/Menu/queries/menuQueries.clientQuery'

import MenuItems from './MenuItems'
import ParentMenuPane from './ParentMenuPane'
import SubMenuPane from './SubMenuPane'
import { Card } from '@blueprintjs/core'

// This is used for column view
class Menu extends React.Component {
  render() {
    const { classes, outlet, queryId, deepestMenuId, setViewMenuItem } =
      this.props

    return (
      <CacheQuery query={MENU_QUERYS}>
        {({ data }) => {
          return (
            <Card
              className="bp3-nopad"
              style={{ overflowY: 'auto', minWidth: '100%', maxWidth: '100%' }}
            >
              <div className={classes.container}>
                <ParentMenuPane
                  queryId={queryId}
                  menuId={data.menuId}
                  classes={classes}
                  outlet={outlet}
                />

                {data.paneNumber >= 1 && (
                  <SubMenuPane
                    outlet={outlet}
                    queryId={queryId}
                    menuId={data.menuId}
                    classes={classes}
                    subMenuId={data.subMenuId}
                    firstSub
                  />
                )}

                {data.paneNumber === 2 && (
                  <SubMenuPane
                    outlet={outlet}
                    queryId={queryId}
                    menuId={data.subMenuId}
                    classes={classes}
                    subMenuId={data.subSubMenuId}
                  />
                )}

                {data.itemMenuOpen && (
                  <MenuItems
                    outlet={outlet}
                    classes={classes}
                    itemId={data.itemId}
                    menuId={deepestMenuId}
                    setViewMenuItem={setViewMenuItem}
                  />
                )}
              </div>
            </Card>
          )
        }}
      </CacheQuery>
    )
  }
}

Menu.propTypes = {
  match: matchType.isRequired,
  classes: object,
  outlet: bool,
}

export default injectSheet(styles)(Menu)

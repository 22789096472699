import React from 'react'
import { Drawer, DrawerSize } from '@blueprintjs/core'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import { modalType } from '@utils/types'
import { successToast } from '@utils/toast'
import { validation } from '@components/TerminalForm/validation'
import { toggleModal } from '@utils/cacheHelpers'
import TerminalForm from '@components/TerminalForm/TerminalForm'
import ADD_TERMINAL from '@components/TerminalModal/mutations/addTerminal.mutation'
import GET_OUTLET_TERMINALS from '@components/Outlet/Terminals/queries/getTerminals.query'

const TerminalModal = ({ modalOpen, modalClose, outlet: outletId }) => (
  <Drawer
    isOpen={modalOpen}
    onClose={modalClose}
    title="Add Terminal"
    size={DrawerSize.SMALL}
  >
    <Mutation
      mutation={ADD_TERMINAL}
      onError={defaultErrorHandler}
      onCompleted={() => {
        successToast('Terminal successfully added')
        toggleModal({
          terminalModal: false,
        })
      }}
      refetchQueries={[
        {
          query: GET_OUTLET_TERMINALS,
          variables: { outletId },
        },
      ]}
    >
      {addTerminal => (
        <TerminalForm
          onSubmit={values =>
            addTerminal({
              variables: {
                ...values,
              },
            })
          }
          initialValues={{
            friendlyName: '',
            serialNumber: '',
            pin: '',
            softwareVersion: '',
            outletIds: [outletId],
          }}
          validationSchema={validation}
          edit={false}
        />
      )}
    </Mutation>
  </Drawer>
)

TerminalModal.propTypes = {
  ...modalType,
}

export default TerminalModal

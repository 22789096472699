import gql from 'graphql-tag'

const GET_FULFILMENT_TIMES = gql`
  query getFulfilmentTimes($id: String!) {
    getOutlets(id: $id) {
      outlets {
        id
        defaultDeliveryTime
        defaultCollectionTime
        daysOfferedInAdvanceMin
        daysOfferedInAdvanceMax
        autoCompleteAfter
        isOrderToTableEnabled
        noPreordersBeforeOpening
        allowPreorders
        marketplace {
          id
          allowExtendedPreorders
        }
        asapAllowed
      }
    }
  }
`

export default GET_FULFILMENT_TIMES

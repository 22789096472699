import React from 'react'
import { func, bool, array } from 'prop-types'
import Filter from '@components/Toolbar/Filter'
import GET_BUSINESS_OUTLETS from './queries/getBusinessOutlets.query'

export const BusinessOutletFilter = ({
  disabled,
  selectedBusinessOutlets,
  onChange,
  mapItems = outlets =>
    outlets.map(outlet => ({
      id: outlet.id,
      name: `${outlet.restaurant.name} - ${outlet.name}`,
    })),
  marketplaceIds,
}) => (
  <Filter
    name="Outlets"
    disabled={disabled}
    query={GET_BUSINESS_OUTLETS}
    dataPath="getOutlets.outlets"
    mapItems={mapItems}
    filter={selectedBusinessOutlets}
    onChange={onChange}
    variables={{ marketplaceIds }}
  />
)

BusinessOutletFilter.propTypes = {
  onChange: func,
  selectedBusinessOutlets: array,
  disabled: bool,
  divider: bool,
  mapItems: func,
  marketplaceIds: array,
}

import React, { Fragment, useEffect, useState } from 'react'
import Query from '@components/Query/Query'
import {
  Card,
  HTMLTable,
  Button,
  Divider,
  NonIdealState,
  ControlGroup,
  ButtonGroup,
  Icon,
  Classes,
  Tooltip,
  Tag,
  Intent,
} from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { matchType } from '@utils/types'
import GET_RECONCILIATION from './queries/getReconciliation.query'
import Currency from '@components/Currency/Currency'
import { startCase, union, without } from 'lodash'
import FilterRow from '@components/FilterRow/FilterRow'
import Dropdown from '@components/Toolbar/RadioFilter'
import { PartnerFilter } from '@components/Toolbar'
import { DATE_FILTER_TYPES } from '../../../utils/datetime'
import { shiftToRedboxPreMigration } from '../../../utils/dateTimeRangeShifters'
import { useDateRangeQueryParams } from '../../Toolbar/DateRangeFilter/useDateRangeQueryParams'
import DateRangeFilter from '../../Toolbar/DateRangeFilter/DateRangeFilter'
import { ArrayParam, StringParam, useQueryParam } from 'use-query-params'
import OutletLink from '@components/OpenStatus/OutletLink'
import DateTime from '@components/DateTime/DateTime'
import Enum from '@components/Enum/Enum'
import { client } from '@services/client'
import { penceToPounds } from '@utils/helpers'
import { format } from 'date-fns'
import toast from '@utils/toast'

const getPaymentModelText = region =>
  region.stripeId || region.stripeEnterpriseId
    ? region.stripeDirectPayment
      ? `${region.stripeEnterpriseId ? 'PAYBOX-E' : 'PAYBOX-S'}`
      : region.stripeOnboarding
      ? 'ONBOARDING'
      : 'MANUAL'
    : 'OFFLINE'

const PaymentModelTag = ({ region, history }) => (
  <Tag
    onClick={() => {
      history.push(`/marketplaces/${region.id}/payments`)
    }}
    minimal
    interactive
    intent={
      region.stripeId || region.stripeEnterpriseId
        ? region.stripeDirectPayment
          ? Intent.SUCCESS
          : region.stripeOnboarding
          ? Intent.WARNING
          : Intent.NONE
        : Intent.DANGER
    }
  >
    {getPaymentModelText(region)}
  </Tag>
)

const ReconciliationAmounts = ({ expectedAmount, actualAmount }) =>
  expectedAmount === actualAmount ? (
    <Currency amount={actualAmount} />
  ) : (
    <Fragment>
      <Currency amount={expectedAmount} />
      &nbsp;
      <Tooltip
        content={
          <span>
            The expected amount was <Currency amount={expectedAmount} />, but
            the actual amount is <Currency amount={actualAmount} />
          </span>
        }
        hoverOpenDelay={500}
        className={Classes.TOOLTIP_INDICATOR}
      >
        <Currency amount={actualAmount} style={{ color: 'red' }} />
      </Tooltip>
    </Fragment>
  )

const TransactionReconciliation = () => {
  const [partnerFilter = [], onChangePartnerFilter] = useQueryParam(
    'partnerFilter',
    ArrayParam
  )
  const [, setViewOrder] = useQueryParam('viewOrder', StringParam)
  const [paymentMethodFilterParam, setPaymentMethodFilter] = useQueryParam(
    'paymentMethodFilter',
    StringParam
  )

  const paymentMethodFilter = paymentMethodFilterParam || 'NOT_MANUAL_CASH'

  useEffect(() => {
    if (!paymentMethodFilterParam) {
      setPaymentMethodFilter('NOT_MANUAL_CASH')
    }
  }, [paymentMethodFilterParam, setPaymentMethodFilter])

  const dateRangeFilterArgs = {
    defaultFilterTypeKey: DATE_FILTER_TYPES.WEEK.key,
    filterTypes: [
      DATE_FILTER_TYPES.DAY,
      DATE_FILTER_TYPES.WEEK,
      DATE_FILTER_TYPES.MONTH,
      DATE_FILTER_TYPES.CUSTOM,
    ],
    shifter: shiftToRedboxPreMigration,
  }
  const { shiftedStartOfRangeDateTime, shiftedEndOfRangeDateTime } =
    useDateRangeQueryParams(dateRangeFilterArgs)

  const [downloading, setDownloading] = useState(false)

  const handlePartnerFilter = e => {
    const { id, checked } = e.currentTarget
    onChangePartnerFilter(
      checked ? union(partnerFilter, [id]) : without(partnerFilter, id)
    )
  }

  const resetFilters = () => {
    onChangePartnerFilter([])
  }

  const downloadCSV = async e => {
    e.preventDefault()
    setDownloading(true)

    const csvHeader = [
      'orderId',
      'orderNumber',
      'transactionStripeId',
      'outlet',
      'business',
      'marketplace',
      'paymentModel',
      'refundedAt',
      'acceptedAt',
      'type',
      'totalToOutlet',
      'totalPaidToOutlet',
      'totalToMarketplace',
      'totalPaidToMarketplace',
      'totalToPartner',
      'totalPaidToPartner',
      'totalToPaybox',
      'totalPaidToPaybox',
      'totalToRedbox',
      'totalPaidToRedbox',
      'reconciliationResolutionType',
      'reconciliationNotes',
    ]

    let csvData = [
      csvHeader
        .map(column => {
          return startCase(column)
        })
        .join(','),
    ]

    await client
      .query({
        query: GET_RECONCILIATION,
        variables: {
          afterDate: shiftedStartOfRangeDateTime,
          beforeDate: shiftedEndOfRangeDateTime,
          partnerIds: partnerFilter,
          showManualCash: paymentMethodFilter === 'ALL',
        },
      })
      .then(data => {
        data.data.reconcile.map(record => {
          csvData.push(
            csvHeader
              .map(column => {
                return {
                  orderId: record.order
                    ? `"${record.order.id}"`
                    : `"Not Found"`,
                  orderNumber: record.order
                    ? `"${record.order.orderNumber}"`
                    : `"Not Found"`,
                  transactionStripeId: `"${record.transactionStripeId}"`,
                  outlet: `"${(record.outlet && record.outlet.name) || '-'}"`,
                  business: `"${
                    (record.restaurant && record.restaurant.name) || '-'
                  }"`,
                  marketplace: `"${
                    (record.marketplace && record.marketplace.name) || '-'
                  }"`,
                  paymentModel: `"${getPaymentModelText(record.marketplace)}"`,
                  refundedAt: `"${record.refundedAt}"`,
                  acceptedAt: `"${record.acceptedAt}"`,
                  type: `"${record.type}"`,
                  totalToOutlet: penceToPounds(record.totalToOutlet),
                  totalPaidToOutlet: penceToPounds(record.totalPaidToOutlet),
                  totalToMarketplace: penceToPounds(record.totalToMarketplace),
                  totalPaidToMarketplace: penceToPounds(
                    record.totalPaidToMarketplace
                  ),
                  totalToPartner: penceToPounds(record.totalToPartner),
                  totalPaidToPartner: penceToPounds(record.totalPaidToPartner),
                  totalToPaybox: penceToPounds(record.totalToPaybox),
                  totalPaidToPaybox: penceToPounds(record.totalPaidToPaybox),
                  totalToRedbox: penceToPounds(record.totalToRedbox),
                  totalPaidToRedbox: penceToPounds(record.totalPaidToRedbox),
                  reconciliationResolutionType: `"${record.reconciliationResolutionType}"`,
                  reconciliationNotes: `"${record.reconciliationNotes}"`,
                }[column]
              })
              .join(',')
          )
        })
      })

    const blob = new Blob([csvData.join('\n')], { type: 'octet/stream' })
    const url = window.URL.createObjectURL(blob)

    let a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = `reconcile-${format(
      shiftedStartOfRangeDateTime,
      'yyyyMMdd-HHmm'
    )}-to-${format(shiftedEndOfRangeDateTime, 'yyyyMMdd-HHmm')}.csv`
    a.click()

    window.URL.revokeObjectURL(url)

    setDownloading(false)

    toast({
      message: 'Download Complete.',
      intent: 'success',
      icon: 'saved',
    })
  }

  return (
    <div className="bp3-table-frame">
      <FilterRow>
        <div>
          <ButtonGroup>
            <ControlGroup>
              <PartnerFilter
                partnerFilter={partnerFilter}
                onChange={handlePartnerFilter}
              />
              <Dropdown
                selected={paymentMethodFilter}
                setSelected={selectedPaymentMethod => {
                  setPaymentMethodFilter(selectedPaymentMethod.id)
                }}
                placeholder="Payment"
                items={[
                  { name: 'All', id: 'ALL' },
                  { name: 'Exclude Manual Cash Orders', id: 'NOT_MANUAL_CASH' },
                ]}
              />
              <Button
                icon="filter-remove"
                onClick={resetFilters}
                disabled={partnerFilter.length === 0}
              />
            </ControlGroup>
          </ButtonGroup>
          <Divider />
          <DateRangeFilter {...dateRangeFilterArgs} />
        </div>
        <Button
          loading={downloading}
          icon={'cloud-download'}
          style={{
            marginLeft: 'auto',
          }}
          onClick={downloadCSV}
        >
          Download CSV
        </Button>
      </FilterRow>

      <Query
        query={GET_RECONCILIATION}
        variables={{
          afterDate: shiftedStartOfRangeDateTime,
          beforeDate: shiftedEndOfRangeDateTime,
          partnerIds: partnerFilter,
          showManualCash: paymentMethodFilter === 'ALL',
        }}
        loaderTitle="Loading Reconciliation Report"
      >
        {({ reconcile: reconciliationRecords }) => {
          if (reconciliationRecords.length > 0) {
            return (
              <div className="bp3-table-container bp3-scrollable">
                <Card className={'bp3-nopad'}>
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        <th colSpan={8}></th>
                        <th
                          style={{ borderLeft: ' 1px solid #DCDCDD' }}
                          colSpan={3}
                        >
                          Transfers
                        </th>
                        <th
                          colSpan={2}
                          style={{ borderLeft: ' 1px solid #DCDCDD' }}
                        >
                          Fees
                        </th>
                      </tr>
                      <tr>
                        <th>Order</th>
                        <th>Outlet</th>
                        <th>Marketplace</th>
                        <th>Payments</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Resolution</th>
                        <th>Notes</th>
                        <th
                          style={{
                            width: '150px',
                            borderLeft: ' 1px solid #DCDCDD',
                          }}
                        >
                          Outlet
                        </th>
                        <th style={{ width: '150px' }}>Marketplace</th>
                        <th style={{ width: '150px' }}>Partner</th>
                        <th
                          style={{
                            width: '150px',
                            borderLeft: ' 1px solid #DCDCDD',
                          }}
                        >
                          Paybox
                        </th>
                        <th style={{ width: '150px' }}>Redbox</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reconciliationRecords.map((row, index, arr) => {
                        const outlet = {
                          ...row.outlet,
                          restaurant: row.restaurant,
                        }

                        const isSameOrderAsLastRow =
                          index > 0 &&
                          arr[index - 1].order &&
                          row.order &&
                          arr[index - 1].order.id === row.order.id

                        return (
                          <tr key={row.id}>
                            <td>
                              {isSameOrderAsLastRow && (
                                <Icon
                                  icon="inheritance"
                                  style={{ marginRight: '4px' }}
                                />
                              )}
                              {row.order ? (
                                <a
                                  onClick={() => {
                                    setViewOrder(row.order.id)
                                  }}
                                >
                                  #{row.order.orderNumber}
                                </a>
                              ) : (
                                'Not Found'
                              )}
                            </td>
                            <td>
                              <OutletLink outlet={outlet} />
                            </td>
                            <td>
                              <Link to={`/marketplaces/${row.marketplace.id}`}>
                                {row.marketplace.name}
                              </Link>
                            </td>
                            <td>
                              <PaymentModelTag
                                region={row.marketplace}
                                history={history}
                              />
                            </td>
                            <td>
                              {row.refundedAt ? (
                                <DateTime
                                  dateTime={row.refundedAt}
                                  description={'Refund'}
                                />
                              ) : (
                                <DateTime
                                  dateTime={row.acceptedAt}
                                  description={'Accepted'}
                                />
                              )}
                            </td>
                            <td>
                              <Enum
                                tagName={row.type}
                                minimal={true}
                                rightIcon={
                                  row.refundedAt && (
                                    <Icon icon="undo" color="#30404d" />
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Enum
                                tagName={
                                  row.reconciliationResolutionType ||
                                  'UNRESOLVED'
                                }
                                minimal={true}
                              />
                            </td>
                            <td>{row.reconciliationNotes}</td>
                            <td
                              style={{
                                borderLeft: ' 1px solid #DCDCDD',
                              }}
                            >
                              <ReconciliationAmounts
                                expectedAmount={row.totalToOutlet}
                                actualAmount={row.totalPaidToOutlet}
                              />
                            </td>
                            <td>
                              <ReconciliationAmounts
                                expectedAmount={row.totalToMarketplace}
                                actualAmount={row.totalPaidToMarketplace}
                              />
                            </td>
                            <td>
                              <ReconciliationAmounts
                                expectedAmount={row.totalToPartner}
                                actualAmount={row.totalPaidToPartner}
                              />
                            </td>
                            <td
                              style={{
                                borderLeft: '1px solid #DCDCDD',
                              }}
                            >
                              <ReconciliationAmounts
                                expectedAmount={row.totalToPaybox}
                                actualAmount={row.totalPaidToPaybox}
                              />
                            </td>
                            <td>
                              <ReconciliationAmounts
                                expectedAmount={row.totalToRedbox}
                                actualAmount={row.totalPaidToRedbox}
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </HTMLTable>
                </Card>
              </div>
            )
          } else {
            return (
              <NonIdealState
                icon="tick-circle"
                title="Successfully Reconciled"
                description={`All transactions in this period have reconciled successfully.`}
                action={
                  partnerFilter ? (
                    <Button
                      onClick={() => resetFilters()}
                      minimal
                      intent="primary"
                    >
                      Reset Filters
                    </Button>
                  ) : null
                }
              />
            )
          }
        }}
      </Query>
    </div>
  )
}

TransactionReconciliation.propTypes = {
  match: matchType,
}

export default TransactionReconciliation

import React, { Component } from 'react'
import { Tree } from '@blueprintjs/core'
import isEqual from 'lodash/isEqual'
import { object, array } from 'prop-types'

import cx from 'classnames'

class ItemMenus extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nodes: this.props.menus,
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.menus, prevProps.menus)) {
      this.setState({
        nodes: this.props.menus,
      })
    }
  }

  handleNodeClick = node => {
    node.isSelected = true

    if (node.onClick) {
      node.onClick()
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Tree
        className={cx(classes.dropDown, classes.secondaryDropdown)}
        onNodeClick={(node, path) => this.handleNodeClick(node, path)}
        contents={this.state.nodes}
      />
    )
  }
}

ItemMenus.propTypes = {
  classes: object,
  menus: array,
}
export default ItemMenus

import React, { Fragment } from 'react'
import { Mutation } from 'react-apollo'
import Query from '@components/Query/Query'
import { Card, Button, Tag, Icon } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import { string, func, bool } from 'prop-types'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { removeUnderscores } from '@utils/helpers'

import READY_NETWORK_ORDER from './mutations/readyNetworkOrder.mutation'
import GET_ORDER from './Order/queries/getOrder.query'

import { getIntent } from './helpers/helpers'

const ReadyNetworkOrderModal = ({
  modalOpen = false,
  modalClose,
  orderId,
  refetchQueries = [],
}) => (
  <Dialog
    title="Mark Order As Ready"
    isCloseButtonShown={false}
    isOpen={modalOpen}
  >
    <Query query={GET_ORDER} variables={{ id: orderId }}>
      {({ order }) => {
        return (
          <Mutation
            mutation={READY_NETWORK_ORDER}
            refetchQueries={[
              ...refetchQueries,
              {
                query: GET_ORDER,
                variables: {
                  id: orderId,
                },
              },
            ]}
            onError={error => {
              defaultErrorHandler(error)
              modalClose()
            }}
            onCompleted={({ readyNetworkOrder: { message } }) => {
              successToast(message)
              modalClose()
            }}
          >
            {readyNetworkOrder => {
              return (
                <Formik
                  initialValues={{}}
                  onSubmit={() => {
                    readyNetworkOrder({
                      variables: {
                        id: orderId,
                      },
                    })
                  }}
                >
                  {props => {
                    const { handleSubmit, isSubmitting } = props

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="bp3-dialog-body">
                          <Fragment>
                            <p>
                              Are you sure you want to update the order status?
                            </p>
                            <Card>
                              <center>
                                <Tag
                                  large={true}
                                  intent={getIntent(order.orderStatus)}
                                >
                                  {removeUnderscores(order.orderStatus)}
                                </Tag>
                                &nbsp;&nbsp;
                                <Icon icon={'arrow-right'} />
                                &nbsp;&nbsp;
                                <Tag large={true} intent={getIntent('READY')}>
                                  READY
                                </Tag>
                              </center>
                            </Card>
                          </Fragment>
                        </div>
                        <div className="bp3-dialog-footer">
                          <div className="bp3-dialog-footer-actions">
                            <Button
                              minimal
                              onClick={() => modalClose(false)}
                              disabled={isSubmitting}
                            >
                              Cancel
                            </Button>
                            <Button type="submit" loading={isSubmitting}>
                              Confirm
                            </Button>
                          </div>
                        </div>
                      </form>
                    )
                  }}
                </Formik>
              )
            }}
          </Mutation>
        )
      }}
    </Query>
  </Dialog>
)

ReadyNetworkOrderModal.propTypes = {
  modalClose: func,
  modalOpen: bool,
  orderId: string,
}

export default ReadyNetworkOrderModal

import React, { useContext } from 'react'
import { Dialog as BP3Dialog } from '@blueprintjs/core'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'

// wrapper for bp3 dialog to add darkmode

const Dialog = ({ children, ...props }) => {
  const { dark } = useContext(PageLayoutContext)

  return (
    <BP3Dialog {...props} className={dark ? 'bp3-dark' : ''}>
      {children}
    </BP3Dialog>
  )
}

export default Dialog

import React, { Fragment } from 'react'
import first from 'lodash/first'

import Query from '@components/Query/Query'
import { matchType } from '@utils/types'

import GET_ALLOWED_CATEGORIES_FOR_RESTAURANT from './queries/getAllowedCategories.query'
import GET_RESTAURANT from './queries/getRestaurant.query'
import RestaurantDetails from './RestaurantDetails'
import GET_CATEGORIES from './queries/getCategories.query'
import { some } from 'lodash'
import { OtterOnboardingDialog } from '../Otter/OtterOnboardingDialog'

const Details = ({ match }) => {
  const id = match.params.restaurants

  return (
    <Fragment>
      <Query
        query={GET_ALLOWED_CATEGORIES_FOR_RESTAURANT}
        variables={{ restaurantId: id }}
      >
        {({
          categoriesForRestaurant: {
            cuisines,
            optInAllowedCategories,
            categoryEmojiOrImage,
          },
        }) => (
          <Query
            query={GET_RESTAURANT}
            variables={{ id }}
            loaderTitle={'Loading Business'}
          >
            {({ getRestaurants: { restaurants } }) => {
              const restaurant = first(restaurants)
              if (!restaurant) return 'Unable to find business'
              const allowOtterIntegration = restaurant.outlets.some(
                o => o.marketplace.allowOtterIntegration
              )

              // Based on the optInAllowedCategories switch the restaurant will have access
              // to all the cuisines or just a subset of them.
              // If set to true then remove from the restaurant's cuisines those not in the allowed list
              if (optInAllowedCategories) {
                restaurant.cuisines.filter(cuisineAtRestaurantLevel =>
                  some(cuisines, cuisineAtRestaurantLevel)
                )

                return (
                  <RestaurantDetails
                    restaurant={restaurant}
                    cuisines={cuisines}
                    categoryEmojiOrImage={categoryEmojiOrImage}
                    allowOtterIntegration={allowOtterIntegration}
                  />
                )
              } else {
                return (
                  <Query query={GET_CATEGORIES}>
                    {({ getCuisines: { cuisines } }) => (
                      <RestaurantDetails
                        restaurant={restaurant}
                        cuisines={cuisines}
                        categoryEmojiOrImage={categoryEmojiOrImage}
                        allowOtterIntegration={allowOtterIntegration}
                      />
                    )}
                  </Query>
                )
              }
            }}
          </Query>
        )}
      </Query>
      <OtterOnboardingDialog restaurantId={id} />
    </Fragment>
  )
}

Details.propTypes = {
  match: matchType,
}

export default Details

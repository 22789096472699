import {
  AnchorButton,
  Button,
  FormGroup,
  Intent,
  Switch,
} from '@blueprintjs/core'
import { Formik } from 'formik'
import { bool, func, shape, string } from 'prop-types'
import React from 'react'
import AppVersionDropdownContainer from '../Toolbar/AppVersionDropdownContainer'

const MarketplaceForceAppUpdatesForm = ({ initialValues, onSubmit }) => (
  <Formik initialValues={initialValues} onSubmit={onSubmit}>
    {({ values, handleChange, handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Switch
          label={'Display on Launch'}
          checked={values.promptForUpdates}
          name="promptForUpdates"
          onChange={handleChange}
        />

        <FormGroup label="Minimum iOS version">
          <AppVersionDropdownContainer
            name="iosVersion"
            onChange={handleChange}
            value={values.iosVersion}
          />
        </FormGroup>

        <FormGroup label="Minimum Android version">
          <AppVersionDropdownContainer
            name="androidVersion"
            onChange={handleChange}
            value={values.androidVersion}
          />
        </FormGroup>
        <div className="bp-card-footer-actions">
          <Button text="Save" intent={Intent.DEFAULT} type="submit" />
          <AnchorButton
            text="Help"
            href="https://support.redbox.systems/docs/configuring-apps-mobile-v5-0#ConfiguringAppsMobilev5.0+-UpdateAppPrompt"
            target="_blank"
            rel="noopener noreferrer"
            rightIcon="help"
            intent={Intent.PRIMARY}
            minimal
          />
        </div>
      </form>
    )}
  </Formik>
)

MarketplaceForceAppUpdatesForm.propTypes = {
  initialValues: shape({
    id: string,
    promptForUpdates: bool,
    androidVersion: string,
    iosVersion: string,
  }),
  onSubmit: func,
}

export default MarketplaceForceAppUpdatesForm

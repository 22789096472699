import React, { Fragment, useState, useEffect } from 'react'
import { first } from 'lodash'
import {
  Intent,
  Card,
  Icon,
  H1,
  H5,
  Tag,
  NonIdealState,
  Spinner,
  Callout,
  Button,
  AnchorButton,
} from '@blueprintjs/core'
import { history } from '@stores/routerStore'
import { Row, Col } from 'react-simple-flex-grid'
import Query from '@components/Query/Query'
import Currency from '@components/Currency/Currency'
import GET_MARKETPLACE from './queries/getMarketplace.query'
import GET_STATS from './queries/getDashboardStats.query'
import config from '@config/config'
import ls from '@utils/localStorage'
import { gt } from 'semver'

import LineGraph from '@components/Analytics/Graphs/LineGraph'
import moment from 'moment'
import { Query as ApolloQuery } from 'react-apollo'
import { isAtLeastMarketplaceOwner, isMenuEditor } from '@stores/userStore'
import { Link } from 'react-router-dom'

const Dashboard = ({ match }) => {
  const environmentDomain = config.environmentDomain
  const [gitVersion, setGitVersion] = useState(null)
  useEffect(() => {
    fetch(`https://app-build.${environmentDomain}/git/refs`, {
      method: 'GET',
      headers: { Authorization: `Bearer ${ls.get('jwt')}` },
    })
      .then(response => response.json())
      .then(data => data && setGitVersion(data.tags))
  }, [])

  const userIsMenuEditor = isMenuEditor()

  return (
    <Query
      query={GET_MARKETPLACE}
      variables={{ id: match.params.marketplace }}
      loaderTitle={'Loading Marketplace'}
    >
      {({ getMarketplaces: { regions } }) => {
        const marketplace = first(regions)
        if (!marketplace) return 'Unable to find marketplace'

        const stats = {
          terminal: {
            online: 0,
            offline: 0,
            total: 0,
          },
          delivery: {
            total: marketplace.deliveryZones.length,
          },
          discounts: {
            total: marketplace.discounts.length,
          },
          outlets: {
            total: marketplace.outlets.length,
            deactivated: 0,
            active: 0,
          },
        }

        marketplace.outlets.forEach(outlet => {
          stats.outlets.deactivated += outlet.active ? 0 : 1
          stats.outlets.active += outlet.active ? 1 : 0
          outlet.terminals.forEach(terminal => {
            stats.terminal.online += terminal.isOnline ? 1 : 0
            stats.terminal.offline += terminal.isOnline ? 0 : 1
            stats.terminal.total++
          })
        })

        let filterPeriod = 'DAY'
        let tooltipDateFormat = 'ddd Do'

        const endDate = moment()
          .endOf('isoWeek')
          .add(4, 'hours')
          .subtract(1, 'second')

        const startDate = moment()
          .subtract(1, 'weeks')
          .startOf('isoWeek')
          .add(4, 'hours')
          .subtract(1, 'second')

        return (
          <Fragment>
            {isAtLeastMarketplaceOwner() && (
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card
                    style={{
                      height: 308,
                      width: 'max-content',
                      minWidth: '100%',
                      minHeight: 327,
                    }}
                  >
                    <ApolloQuery
                      query={GET_STATS}
                      variables={{
                        startDate: startDate.utc().toISOString(),
                        endDate: endDate.utc().toISOString(),
                        period: filterPeriod,
                        marketplaceId: marketplace.id,
                      }}
                    >
                      {({ data, loading, error = null, networkStatus }) => {
                        if (error) {
                          return (
                            <Callout
                              icon="error"
                              intent="danger"
                              title="Unable to load dashboard, please try again."
                            >
                              <p>
                                Please verify your internet connection and try
                                again.
                              </p>
                              <Button
                                icon="refresh"
                                onClick={() => location.reload()}
                              >
                                Reload Application
                              </Button>
                            </Callout>
                          )
                        }

                        if (loading) {
                          return (
                            <div style={{ padding: 100 }}>
                              <NonIdealState
                                icon={<Spinner size={60} />}
                                title={'Loading Sales'}
                                description={'Please wait...'}
                              />
                            </div>
                          )
                        }

                        if (!data) {
                          return (
                            <div style={{ padding: 100 }}>
                              <NonIdealState
                                icon={'th-disconnect'}
                                title={'No Data'}
                                description={
                                  'No orders have been processed in the last 7 days.'
                                }
                              />
                            </div>
                          )
                        }

                        const { sales } = data.salesBy

                        const salesData = []
                        let totalOrders = 0,
                          totalOrdersValue = 0

                        if (sales && sales.length > 1) {
                          for (let dow = 1; dow <= 7; dow++) {
                            // Runs 5 times, with values of step 0 through 4.
                            const thisWeek = sales[dow + 6]
                            const lastWeek = sales[dow - 1]

                            totalOrders += thisWeek.totalOrders || 0
                            totalOrdersValue += thisWeek.totalOrdersValue || 0

                            salesData.push({
                              x: thisWeek.date,
                              y: thisWeek.totalOrdersValue || 0,
                              y2: lastWeek.totalOrdersValue || 0,
                              y3: thisWeek.totalRefundsValue || 0,
                              y4: lastWeek.totalRefundsValue || 0,
                            })
                          }
                        }

                        const averageOrdersValue =
                          totalOrdersValue / totalOrders

                        return (
                          <Fragment>
                            {loading ||
                            error ||
                            networkStatus === 4 ||
                            !data ? (
                              <NonIdealState
                                icon={
                                  loading || networkStatus === 4 ? (
                                    <div style={{ padding: 100 }}>
                                      <Spinner size="40" />
                                    </div>
                                  ) : (
                                    <Icon
                                      icon="timeline-line-chart"
                                      iconSize="40"
                                    />
                                  )
                                }
                                title={'Weekly Sales'}
                                description={
                                  loading
                                    ? 'Please wait...'
                                    : `No data between ${startDate.format(
                                        'Do MMM'
                                      )} - ${endDate.format('Do MMM')}`
                                }
                              />
                            ) : (
                              <Fragment>
                                <Row>
                                  <Col span={6}>
                                    <h5 className={'bp3-heading'}>
                                      Weekly Sales
                                    </h5>
                                  </Col>
                                  <Col span={6}>
                                    <p
                                      className={'bp3-text-muted'}
                                      style={{ textAlign: 'right' }}
                                    >
                                      {startDate.format('Do MMM')} -{' '}
                                      {endDate.format('Do MMM')}
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={6}>
                                    <h1
                                      style={{ color: '#1968F6' }}
                                      className="bp3-heading"
                                    >
                                      <Currency amount={totalOrdersValue} />
                                    </h1>
                                    <p>Gross Total</p>
                                  </Col>
                                  <Col span={3}>
                                    <h1
                                      style={{ color: '#1968F6' }}
                                      className="bp3-heading"
                                    >
                                      {totalOrders}
                                    </h1>
                                    Total Orders
                                  </Col>
                                  <Col span={3}>
                                    <h1
                                      style={{ color: '#1968F6' }}
                                      className="bp3-heading"
                                    >
                                      <Currency amount={averageOrdersValue} />
                                    </h1>
                                    Average Order
                                  </Col>
                                </Row>

                                {totalOrders > 0 && (
                                  <LineGraph
                                    tooltipDateFormat={tooltipDateFormat}
                                    data={salesData}
                                    period={filterPeriod}
                                    displayBands
                                    currency
                                    responsive={true}
                                  />
                                )}
                              </Fragment>
                            )}
                          </Fragment>
                        )
                      }}
                    </ApolloQuery>
                  </Card>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Row gutter={24}>
                  {(!marketplace.stripeId ||
                    marketplace.enablePayboxEnterprise) && (
                    <Col xs={12} sm={12} md={6} lg={6} xl={3}>
                      {marketplace.stripeConnect ? (
                        <Card style={{ minHeight: 150 }}>
                          <Fragment>
                            <Row>
                              <Col span={6}>
                                <H5>
                                  Balance{' '}
                                  <Tag minimal>
                                    {marketplace.stripeConnect.balanceCurrency.toUpperCase()}
                                  </Tag>
                                </H5>
                              </Col>
                              <Col span={6}>
                                <Link
                                  className={'bp3-text-muted'}
                                  style={{ float: 'right' }}
                                  to={`/marketplaces/${marketplace.id}/payments`}
                                >
                                  Manage Payouts <Icon icon="arrow-right" />
                                </Link>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={6}>
                                <H1 style={{ color: '#1968F6' }}>
                                  <Currency
                                    amount={
                                      marketplace.stripeConnect.balance || 0
                                    }
                                    currency={
                                      marketplace.stripeConnect.balanceCurrency
                                    }
                                  />
                                </H1>
                                {marketplace.stripeConnect.payoutsEnabled ? (
                                  'On The Way'
                                ) : (
                                  <Tag intent="danger">PAYMENTS SUSPENDED</Tag>
                                )}
                              </Col>
                              <Col span={6}>
                                <H1 className="bp3-text-muted">
                                  <Currency
                                    amount={
                                      marketplace.stripeConnect
                                        .balancePending || 0
                                    }
                                    currency={
                                      marketplace.stripeConnect.balanceCurrency
                                    }
                                  />
                                </H1>
                                Pending
                              </Col>
                            </Row>
                          </Fragment>
                        </Card>
                      ) : (
                        <Card style={{ minHeight: 150 }}>
                          <H5>Payments</H5>

                          <p>
                            Set up Paybox to receive sales commission directly
                            to your bank account.
                          </p>
                          <AnchorButton
                            href={`/marketplaces/${marketplace.id}/payments`}
                            intent="success"
                            icon="play"
                          >
                            Start Setup
                          </AnchorButton>
                          {'  '}
                          <AnchorButton
                            href={`https://support.redbox.systems/docs/paybox`}
                            minimal
                            intent="primary"
                            rightIcon="help"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn More
                          </AnchorButton>
                        </Card>
                      )}
                    </Col>
                  )}

                  <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Card
                      interactive
                      style={{ minHeight: 150 }}
                      onClick={() => {
                        history.push(
                          `/outlets?marketplaceIds=${marketplace.id}`
                        )
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={10}>
                            <H5>Outlets</H5>
                          </Col>
                          <Col span={2}>
                            <p
                              className={'bp3-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              <Icon icon="arrow-right" />
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            span={3}
                            md={3}
                            style={{ display: 'inline-flex' }}
                          >
                            <H1>{stats.outlets.total}</H1>
                          </Col>
                        </Row>
                        <Row>
                          {stats.outlets.total > 0 ? (
                            stats.outlets.deactivated > 0 ? (
                              <Tag
                                minimal
                                intent={Intent.WARNING}
                                icon="warning-sign"
                                large
                                round
                              >
                                {stats.outlets.deactivated} Deactivated
                              </Tag>
                            ) : (
                              <Tag
                                intent={Intent.SUCCESS}
                                large
                                round
                                minimal
                                icon={'tick'}
                              >
                                OK
                              </Tag>
                            )
                          ) : (
                            <Tag icon="plus" minimal intent={Intent.NONE} large>
                              Add Outlet
                            </Tag>
                          )}
                        </Row>
                      </Fragment>
                    </Card>
                  </Col>
                  <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Card
                      elevation={stats.terminal.offline > 0 ? 4 : 0}
                      style={
                        stats.terminal.offline > 0
                          ? { minHeight: 150, border: '2px solid red' }
                          : { minHeight: 150 }
                      }
                      onClick={() => {
                        history.push(
                          `/terminals?marketplaceIds=${marketplace.id}`
                        )
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={10}>
                            <H5>Terminals</H5>
                          </Col>
                          <Col span={2}>
                            <p
                              className={'bp3-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              <Icon icon="arrow-right" />
                            </p>
                          </Col>
                        </Row>
                        <Fragment>
                          <Row>
                            <Col span={3} md={3}>
                              <H1>{stats.terminal.total}</H1>
                            </Col>
                          </Row>
                          <Row>
                            {stats.terminal.total > 0 &&
                              stats.terminal.offline > 0 && (
                                <Tag
                                  intent={Intent.DANGER}
                                  large
                                  round
                                  icon="warning-sign"
                                >
                                  {stats.terminal.offline} Offline
                                </Tag>
                              )}
                          </Row>
                        </Fragment>
                      </Fragment>
                    </Card>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card
                      interactive
                      style={{ minHeight: 150 }}
                      onClick={() => {
                        history.push(`/marketplaces/${marketplace.id}/apps`)
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={6}>
                            <H5>Android</H5>
                          </Col>
                          <Col span={4}>
                            <H5>iOS</H5>
                          </Col>
                          <Col span={2}>
                            <p
                              className={'bp3-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              <Icon icon="arrow-right" />
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            span={6}
                            md={6}
                            style={{ display: 'inline-flex' }}
                          >
                            <H1>{marketplace.androidVersion || '-'}</H1>
                            <span className="statInfo">
                              {marketplace.androidVersion && 'v'}
                            </span>
                          </Col>
                          <Col
                            span={6}
                            md={6}
                            style={{ display: 'inline-flex' }}
                          >
                            <H1>{marketplace.iosVersion || '-'}</H1>
                            <span className="statInfo">
                              {marketplace.iosVersion && 'v'}
                            </span>
                          </Col>
                        </Row>
                        {gitVersion && gitVersion.length && (
                          <Row>
                            <Col
                              span={6}
                              md={6}
                              style={{ display: 'inline-flex' }}
                            >
                              {!marketplace.androidVersion ||
                              gt(gitVersion[0], marketplace.androidVersion) ? (
                                <Tag
                                  intent={Intent.PRIMARY}
                                  large
                                  round
                                  minimal
                                  icon="download"
                                >
                                  {gitVersion[0]} Available
                                </Tag>
                              ) : (
                                <Tag
                                  icon="tick"
                                  minimal
                                  intent={Intent.SUCCESS}
                                  large
                                  round
                                >
                                  OK
                                </Tag>
                              )}
                            </Col>
                            <Col
                              span={6}
                              md={6}
                              style={{ display: 'inline-flex' }}
                            >
                              {!marketplace.iosVersion ||
                              gt(gitVersion[0], marketplace.iosVersion) ? (
                                <Tag
                                  intent={Intent.PRIMARY}
                                  large
                                  round
                                  minimal
                                  icon="download"
                                >
                                  {gitVersion[0]} Available
                                </Tag>
                              ) : (
                                <Tag
                                  icon="tick"
                                  minimal
                                  intent={Intent.SUCCESS}
                                  large
                                  round
                                >
                                  OK
                                </Tag>
                              )}
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </Card>
                  </Col>
                  <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Card
                      interactive
                      style={{ minHeight: 150 }}
                      onClick={() => {
                        history.push(
                          `/marketplaces/${marketplace.id}/discount-codes`
                        )
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={10}>
                            <H5>Discount Codes</H5>
                          </Col>
                          <Col span={2}>
                            <p
                              className={'bp3-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              <Icon icon="arrow-right" />
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            span={3}
                            md={3}
                            style={{ display: 'inline-flex' }}
                          >
                            <H1>{stats.discounts.total}</H1>
                          </Col>
                        </Row>
                        <Row>
                          {stats.discounts.total === 0 ? (
                            <Tag icon="plus" minimal intent={Intent.NONE} large>
                              Add Discount Code
                            </Tag>
                          ) : (
                            <Tag
                              icon="tick"
                              minimal
                              intent={Intent.SUCCESS}
                              large
                              round
                            >
                              OK
                            </Tag>
                          )}
                        </Row>
                      </Fragment>
                    </Card>
                  </Col>
                  <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                    <Card
                      interactive={!userIsMenuEditor}
                      style={{ minHeight: 150 }}
                      onClick={() => {
                        !userIsMenuEditor &&
                          history.push(
                            `/marketplaces/${marketplace.id}/delivery-zones`
                          )
                      }}
                    >
                      <Fragment>
                        <Row>
                          <Col span={10}>
                            <H5>Delivery</H5>
                          </Col>
                          <Col span={2}>
                            <p
                              className={'bp3-text-muted'}
                              style={{ textAlign: 'right' }}
                            >
                              {!userIsMenuEditor && <Icon icon="arrow-right" />}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            span={3}
                            md={3}
                            style={{ display: 'inline-flex' }}
                          >
                            <H1>{stats.delivery.total}</H1>
                            <span className="statInfo">zones</span>
                          </Col>
                        </Row>
                        <Row>
                          {stats.delivery.total === 0 && !userIsMenuEditor ? (
                            <Tag icon="plus" minimal intent={Intent.NONE} large>
                              Add Delivery Zone
                            </Tag>
                          ) : (
                            <Tag
                              icon="tick"
                              minimal
                              intent={Intent.SUCCESS}
                              large
                              round
                            >
                              OK
                            </Tag>
                          )}
                        </Row>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Fragment>
        )
      }}
    </Query>
  )
}

export default Dashboard

import { createBrowserHistory } from 'history'
import split from 'lodash/split'
import takeRight from 'lodash/takeRight'
import { without } from 'lodash'

const history = createBrowserHistory()
let currentPath

const storePath = path => {
  // certain paths go too deep and maintaining the breadcrumb is too
  // complicated / not useful at that depth. Adding a path to the
  // bellow array will stop the breadcrumb at that path.
  // eg 'restaurants/xxxxxxIDxxxxx/menu/tree' and
  // 'restaurants/xxxxxxIDxxxxx/menu/list'
  // the extra breadcrumb of list / tree is not useful to the user
  // and it only changes the view visually.
  // made this accept multiple paths as it may happen again
  const limitDepthPaths = ['/menu', '/stock']
  const containsLimit = limitDepthPaths.find(limitDepthpath =>
    path.includes(limitDepthpath)
  )

  if (containsLimit) {
    currentPath = path.split(containsLimit)[0] + containsLimit
  } else {
    currentPath = path
  }
}

const returnCrumbArray = (crumbNo = 3) => {
  // Split on /, remove empty results and return.
  return without(takeRight(split(currentPath, '/'), crumbNo), '')
}

history.listen(location => {
  storePath(location.pathname)
})

storePath(window.location.pathname) // store path on load

export { history, returnCrumbArray }

import React from 'react'
import { Button, HTMLSelect, Intent, Tooltip } from '@blueprintjs/core'
import {
  func,
  number,
  arrayOf,
  shape,
  oneOfType,
  string,
  object,
} from 'prop-types'
import get from 'lodash/get'
import moment from 'moment'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import TimeInput from '@components/TimeInput/TimeInput'

const DAYS = [
  { label: 'Day', value: null },
  { label: 'Mon', value: 1 },
  { label: 'Tue', value: 2 },
  { label: 'Wed', value: 3 },
  { label: 'Thu', value: 4 },
  { label: 'Fri', value: 5 },
  { label: 'Sat', value: 6 },
  { label: 'Sun', value: 7 },
]

const convertHHMMToMinutes = time => {
  if (!time) return false
  const [hours, minutes] = time.split(':')

  return parseInt(hours, 10) * 60 + parseInt(minutes, 10)
}

const AvailablityTimeSelect = ({
  onChange,
  index,
  defaultCollectionTime,
  availabilityTimes,
  errors,
  remove,
  fieldName,
  setFieldValue,
}) => {
  const deliveryWindowRange = time => {
    const prepTime = moment(time, 'HH:mm').add('minute', defaultCollectionTime)

    return !time
      ? ''
      : `${prepTime.format('HH:mm')} - ${prepTime
          .clone()
          .add('minute', availabilityTimes[index].timeSlot)
          .format('HH:mm')}`
  }

  const firstDeliveryTime = deliveryWindowRange(
    availabilityTimes[index].start.time
  )

  return (
    <tr>
      <td>
        <Tooltip
          content={'Length of each delivery window eg: 01:30 is 90 minutes.'}
        >
          <TimeInput
            value={availabilityTimes[index].timeSlotTime}
            handleChange={value => {
              setFieldValue(`${fieldName}[${index}].timeSlotTime`, value)
              // also set the timeSlot value to pass validation
              setFieldValue(
                `${fieldName}[${index}].timeSlot`,
                convertHHMMToMinutes(value)
              )
            }}
            intent={
              get(errors.openingTimes, `[${index}].timeSlot`)
                ? Intent.DANGER
                : Intent.NONE
            }
            minimumMin={5}
          />
        </Tooltip>
        <span className="bp3-text-muted bp3-text-small">
          &nbsp;&nbsp;
          {convertHHMMToMinutes(availabilityTimes[index].timeSlotTime)
            ? convertHHMMToMinutes(availabilityTimes[index].timeSlotTime)
            : null}
          &nbsp;min
        </span>
      </td>
      <td>
        <HTMLSelect
          options={DAYS}
          value={availabilityTimes[index].start.day}
          onChange={onChange}
          name={`${fieldName}[${index}].start.day`}
        />
      </td>
      <td>
        <TimeInput
          value={availabilityTimes[index].start.time}
          handleChange={value => {
            setFieldValue(`${fieldName}[${index}].start.time`, value)
          }}
          intent={
            get(errors.openingTimes, `[${index}].start.time`)
              ? Intent.DANGER
              : Intent.NONE
          }
        />
      </td>
      <td className="bp3-monospace-text">{firstDeliveryTime}</td>
      <td>
        <HTMLSelect
          options={DAYS}
          value={availabilityTimes[index].end.day}
          onChange={onChange}
          name={`${fieldName}[${index}].end.day`}
        />
      </td>
      <td>
        <TimeInput
          value={availabilityTimes[index].end.time}
          handleChange={value => {
            setFieldValue(`${fieldName}[${index}].end.time`, value)
          }}
          intent={
            get(errors.openingTimes, `[${index}].end.time`)
              ? Intent.DANGER
              : Intent.NONE
          }
        />
      </td>
      <td className="bp3-action-cell">
        {availabilityTimes.length > 1 && (
          <ConfirmationPopover
            remove={() => remove(index)}
            confirmationText="Are you sure you want to delete this opening time?"
          >
            <Button icon="cross" minimal />
          </ConfirmationPopover>
        )}
      </td>
    </tr>
  )
}

AvailablityTimeSelect.propTypes = {
  onChange: func,
  index: number,
  availabilityTimes: arrayOf(
    shape({
      start: shape({
        day: oneOfType[(string, number)],
        time: oneOfType[(string, number)],
      }),
      end: shape({
        day: oneOfType[(string, number)],
        time: oneOfType[(string, number)],
      }),
    })
  ),
  errors: object,
  classes: object,
  remove: func,
  fieldName: string,
  setFieldValue: func,
  defaultDeliveryTime: number,
  defaultCollectionTime: number,
}

export default AvailablityTimeSelect

export const styles = theme => ({
  container: {
    padding: '0',
    marginTop: '12px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 10px',
  },
  total: {
    fontWeight: 'bold',
  },
  charge: {
    color: theme.colors.orderHighlight,
  },
})

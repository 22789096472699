import React from 'react'
import cx from 'classnames'

const PaidStatus = ({ classes, order: { paid } }) => (
  <section className={cx(classes.section, classes.center)}>
    <h2 className={classes.title}>{paid ? 'PAID' : 'UNPAID'}</h2>
  </section>
)

export default PaidStatus

import {
  Classes,
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
  H5,
  Tag,
  Spinner,
  Button,
} from '@blueprintjs/core'
import GET_LIVE_ESTIMATE_FOR_CAMPAIGN from '@components/Marketing/queries/getLiveEstimateForCampaign.query'
import React, { Fragment } from 'react'
import { Query } from 'react-apollo'
import DeleteCampaignButton from '../DeleteCampaignButton'

export const InnerFormFooter = ({
  marketplaceId,
  values,
  setModalOpen,
  allowSMSMarketing,
}) => (
  <div className={Classes.DIALOG_FOOTER_ACTIONS}>
    {values.segment && values.type ? (
      <Fragment>
        {values.status === 'SCHEDULED' && (
          <div>
            <DeleteCampaignButton
              campaignId={values.id}
              marketplaceId={marketplaceId}
              title={'Delete campaign'}
              onCompleted={() => setModalOpen(false)}
              intent={Intent.DANGER}
            />
          </div>
        )}
        <br />
        <div style={{ padding: '4px' }}>Reach: </div>
        <Query
          query={GET_LIVE_ESTIMATE_FOR_CAMPAIGN}
          variables={{
            marketplaceId,
            segmentId: values.segment,
            channel: 'EMAIL',
            type: values.type,
          }}
        >
          {({ data, loading }) => (
            <Popover
              interactionKind={PopoverInteractionKind.HOVER}
              position={Position.RIGHT_TOP}
              popoverClassName={Classes.POPOVER_CONTENT_SIZING}
              content={
                <Fragment>
                  <H5>Information</H5>
                  <p>
                    The number reach will change based on customer preferences
                    for receiving communication.
                  </p>
                </Fragment>
              }
            >
              <Tag
                large
                minimal
                icon={loading ? <Spinner size={20} /> : 'follower'}
                intent={
                  !values.emailChannel ||
                  (loading && !data.getLiveEstimateForCampaign) ||
                  data.getLiveEstimateForCampaign.estimate <= 0
                    ? 'warning'
                    : 'success'
                }
              >
                <strong>
                  {!values.emailChannel ||
                  (loading && !data.getLiveEstimateForCampaign)
                    ? 0
                    : data.getLiveEstimateForCampaign.estimate || 0}{' '}
                  Emails
                </strong>
              </Tag>
            </Popover>
          )}
        </Query>
        <Query
          query={GET_LIVE_ESTIMATE_FOR_CAMPAIGN}
          variables={{
            marketplaceId,
            segmentId: values.segment,
            channel: 'PUSH',
            type: values.type,
          }}
        >
          {({ data, loading }) => (
            <Popover
              interactionKind={PopoverInteractionKind.HOVER}
              position={Position.RIGHT_TOP}
              popoverClassName={Classes.POPOVER_CONTENT_SIZING}
              content={
                <Fragment>
                  <H5>Information</H5>
                  <p>
                    The number reach will change based on customer preferences
                    for receiving communication.
                  </p>
                </Fragment>
              }
            >
              <Tag
                large
                minimal
                icon={loading ? <Spinner size={20} /> : 'follower'}
                intent={
                  !values.pushChannel ||
                  (loading && !data.getLiveEstimateForCampaign) ||
                  data.getLiveEstimateForCampaign.estimate <= 0
                    ? 'warning'
                    : 'success'
                }
              >
                <strong>
                  {!values.pushChannel ||
                  (loading && !data.getLiveEstimateForCampaign)
                    ? 0
                    : data.getLiveEstimateForCampaign.estimate || 0}{' '}
                  Mobile Push Notifications
                </strong>
              </Tag>
            </Popover>
          )}
        </Query>
        {allowSMSMarketing ? (
          <Query
            query={GET_LIVE_ESTIMATE_FOR_CAMPAIGN}
            variables={{
              marketplaceId,
              segmentId: values.segment,
              channel: 'SMS',
              type: values.type,
            }}
          >
            {({ data, loading }) => (
              <Popover
                interactionKind={PopoverInteractionKind.HOVER}
                position={Position.RIGHT_TOP}
                popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                content={
                  <Fragment>
                    <H5>Information</H5>
                    <p>
                      The number reach will change based on customer preferences
                      for receiving communication.
                    </p>
                  </Fragment>
                }
              >
                <Tag
                  large
                  minimal
                  icon={loading ? <Spinner size={20} /> : 'follower'}
                  intent={
                    !values.smsChannel ||
                    (loading && !data.getLiveEstimateForCampaign) ||
                    data.getLiveEstimateForCampaign.estimate <= 0
                      ? 'warning'
                      : 'success'
                  }
                >
                  <strong>
                    {!values.smsChannel ||
                    (loading && !data.getLiveEstimateForCampaign)
                      ? 0
                      : data.getLiveEstimateForCampaign.estimate || 0}{' '}
                    SMS
                  </strong>
                </Tag>
              </Popover>
            )}
          </Query>
        ) : (
          ''
        )}
        <Button onClick={() => setModalOpen(true)}>
          {values.timeConfig === 'DATETIME'
            ? 'Schedule Campaign'
            : 'Send Campaign Now'}
        </Button>
      </Fragment>
    ) : (
      <Popover
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.RIGHT_TOP}
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        content={
          <Fragment>
            <H5>Information</H5>
            <p>
              You need to select a target segment and a campaign type, this
              defines who will receive your campaign.
            </p>
            <Button
              onClick={() => {
                window.location.href = `/marketing/${marketplaceId}/segments`
              }}
            >
              Setup Segments
            </Button>
          </Fragment>
        }
      >
        <Button disabled>
          {values.timeConfig === 'DATETIME'
            ? 'Schedule Campaign'
            : 'Send Campaign Now'}
        </Button>
      </Popover>
    )}
  </div>
)

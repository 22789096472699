import gql from 'graphql-tag'

const EDIT_OUTLET_ENABLE_ORDER_TO_TABLE = gql`
  mutation editOutletEnableOrderToTable(
    $outletId: String!
    $isOrderToTableEnabled: Boolean!
    $isPendingTableNumbersEnabled: Boolean!
    $pendingTableNumbersCustomText: String
  ) {
    editOutlet(
      id: $outletId
      isOrderToTableEnabled: $isOrderToTableEnabled
      isPendingTableNumbersEnabled: $isPendingTableNumbersEnabled
      pendingTableNumbersCustomText: $pendingTableNumbersCustomText
    ) {
      message
      outlet {
        id
        isOrderToTableEnabled
        isPendingTableNumbersEnabled
        pendingTableNumbersCustomText
      }
    }
  }
`
export default EDIT_OUTLET_ENABLE_ORDER_TO_TABLE

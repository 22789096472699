import {
  Button,
  Card,
  ControlGroup,
  FormGroup,
  H5,
  HTMLSelect,
  HTMLTable,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
  Slider,
  Switch,
  TextArea,
} from '@blueprintjs/core'
import { DateRangeInput } from '@blueprintjs/datetime'
import { FieldArray } from 'formik'
import moment from 'moment'
import React, { Fragment } from 'react'
import shortid from 'shortid'
import { checkForBlankInterval } from '../../../utils/helpers'
import AvailabilityTimeSelect from '../../Restaurant/Menu/modals/AvailabilityTimeSelect'
import SegmentAttributeFilter from './SegmentAttributeFilter'

const EditSegmentForm = ({
  values,
  errors,
  isValid,
  dirty,
  handleChange,
  handleSubmit,
  setFieldValue,
  setFieldError,
  marketplaceId,
  isSubmitting,
}) => {
  const defaultFilter = {
    attribute: '',
    values: [],
  }

  const defaultGroup = {
    filterGroupType: 'ALL',
    filters: [defaultFilter],
  }

  const BLANK_INTERVAL = {
    start: { day: '1', time: '00:00' },
    end: { day: '7', time: '23:59' },
    key: shortid.generate(),
  }

  const attributes = [
    {
      label: 'Category',
      value: 'category',
      comparators: [
        { label: 'contains', value: 'IS' },
        { label: 'is not', value: 'IS_NOT' },
      ],
    },
    {
      label: 'Outlet',
      value: 'outlet',
      comparators: [
        { label: 'is', value: 'IS' },
        { label: 'is not', value: 'IS_NOT' },
      ],
      values: [],
    },
    {
      label: 'Discounts',
      value: 'discounts',
      comparators: [
        { label: 'available', value: 'IS' },
        { label: 'unavailable', value: 'IS_NOT' },
      ],
    },
    {
      label: 'Featured',
      value: 'featured',
      comparators: [
        { label: 'is featured', value: 'IS' },
        { label: 'is not featured', value: 'IS_NOT' },
      ],
    },
  ]

  const sortByAttributes = [
    { label: 'Random', value: '' },
    { label: 'Distance', value: 'DISTANCE' },
    { label: 'Collection/Delivery time', value: 'TIME' },
    { label: 'Min. Order', value: 'MIN_ORDER' },
  ]

  const segmentOpenStatusOptions = [
    { label: 'All', value: 'ALL' },
    { label: 'Open', value: 'OPEN' },
    { label: 'Closed', value: 'CLOSED' },
  ]

  let segmentCurrentOpenStatusCount = 0
  return (
    <form onSubmit={handleSubmit} className="bp3-drawer-form">
      <div className={'bp3-drawer-content'}>
        <FormGroup
          label="Title"
          labelInfo="(required)"
          helperText={errors && errors.name}
          intent={Intent.DANGER}
        >
          <InputGroup
            name="name"
            type="text"
            value={values && values.name}
            onChange={handleChange}
            large
          />
        </FormGroup>
        <FormGroup
          label="Description"
          helperText={
            (errors && errors.description) ||
            "Description shows underneath your segment's title."
          }
          labelInfo="200 Character Limit (Optional)"
          intent={errors && errors.description ? Intent.DANGER : Intent.NONE}
        >
          <TextArea
            onChange={handleChange}
            name="description"
            style={{ resize: 'none', height: '60px' }}
            fill={true}
            id="description"
            value={values.description || ''}
          />
        </FormGroup>

        <FormGroup
          label="Limit Cards"
          subLabel="Maximum outlets displayed at a time."
        >
          <Card>
            <Slider
              name="outletLimit"
              min={1}
              max={20}
              labelStepSize={1}
              stepSize={1}
              value={values.outletLimit}
              onChange={val => setFieldValue('outletLimit', val)}
            />
          </Card>
        </FormGroup>
        <FormGroup
          label="Sorting"
          helperText="Control display order of the Cards."
        >
          <HTMLSelect
            name={`orderBy`}
            options={[
              ...sortByAttributes.map(attribute => ({
                ...attribute,
                key: attribute.value,
              })),
            ]}
            value={values.orderBy}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup
          label="Display Options"
          helperText="Choose how to display the Card."
        >
          <Switch
            label={'Special Offer Flag'}
            checked={values.showFeaturedFlags}
            onChange={handleChange}
            name="showFeaturedFlags"
          />
          <Switch
            label={'Discount Label'}
            checked={values.showDiscountLabels}
            onChange={handleChange}
            name="showDiscountLabels"
          />
          <Switch
            label={'Show In App'}
            checked={values.showOnApp}
            onChange={handleChange}
            name="showOnApp"
          />
        </FormGroup>
        <br />
        <H5>Filters</H5>
        <FormGroup label="Open / Closed Status">
          <HTMLSelect
            name={`openStatusFilter`}
            options={segmentOpenStatusOptions.map(attribute => ({
              ...attribute,
              key: attribute.value,
            }))}
            value={values.openStatusFilter}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup label="Attributes">
          <FieldArray
            name="groups"
            render={groupsArrayHelper => (
              <Card>
                <div>
                  {values &&
                    values.groups.map((filterGroup, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            borderLeft: 'solid 3px #E6ECEF',
                            padding: 6,
                            borderRadius: 0,
                            margin: 0,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <strong>Group {i + 1}</strong>
                            <ControlGroup>
                              {
                                <Button
                                  icon="trash"
                                  minimal
                                  onClick={() => groupsArrayHelper.remove(i)}
                                />
                              }
                            </ControlGroup>
                          </div>

                          <FormGroup>
                            <FieldArray
                              name={`groups[${i}].filters`}
                              render={arrayHelpers => {
                                return (
                                  <div>
                                    {filterGroup.filters.map(
                                      (filter, filterIndex) => {
                                        const isLastFilter =
                                          filterGroup.filters.length - 1 ===
                                          filterIndex

                                        let thisFilterAttributes = [
                                          ...attributes,
                                        ]
                                        if (!segmentCurrentOpenStatusCount) {
                                          segmentCurrentOpenStatusCount++
                                        } else {
                                          thisFilterAttributes =
                                            thisFilterAttributes.filter(
                                              attribute =>
                                                attribute.value !==
                                                'open_status'
                                            )
                                        }

                                        return (
                                          <div key={filterIndex}>
                                            <SegmentAttributeFilter
                                              attributes={thisFilterAttributes}
                                              key={filterIndex}
                                              filter={filter}
                                              index={filterIndex}
                                              onChange={handleChange}
                                              arrayHelpers={arrayHelpers}
                                              setFieldValue={setFieldValue}
                                              marketplaceId={marketplaceId}
                                              formNamePrefix={`groups[${i}].filters[${filterIndex}]`}
                                              currentValues={{ ...values }}
                                              errors={
                                                errors.groups &&
                                                errors.groups[i] &&
                                                errors.groups[i].filters &&
                                                errors.groups[i].filters[
                                                  filterIndex
                                                ]
                                                  ? errors.groups[i].filters[
                                                      filterIndex
                                                    ]
                                                  : undefined
                                              }
                                            />

                                            {isLastFilter ? null : (
                                              <HTMLSelect
                                                minimal
                                                name={`groups[${i}].filterGroupType`}
                                                options={[
                                                  {
                                                    value: 'ALL',
                                                    label: 'AND',
                                                  },
                                                  {
                                                    value: 'ANY',
                                                    label: 'OR',
                                                  },
                                                ]}
                                                value={
                                                  filterGroup.filterGroupType
                                                }
                                                onChange={handleChange}
                                              />
                                            )}
                                          </div>
                                        )
                                      }
                                    )}
                                    <Button
                                      style={{
                                        borderLeft: 'solid 3px #1968f6',
                                        padding: 6,
                                        borderRadius: 0,
                                      }}
                                      intent="primary"
                                      icon="plus"
                                      type="button"
                                      minimal
                                      onClick={() =>
                                        arrayHelpers.push(defaultFilter)
                                      }
                                    >
                                      Add Filter
                                    </Button>
                                  </div>
                                )
                              }}
                            />
                          </FormGroup>
                        </div>
                      )
                    })}
                </div>
                <Button
                  style={{
                    borderLeft: 'solid 3px #1968f6',
                    padding: 6,
                    borderRadius: 0,
                  }}
                  intent="primary"
                  icon="plus"
                  type="button"
                  minimal
                  onClick={() => groupsArrayHelper.push(defaultGroup)}
                >
                  Add Group
                </Button>
              </Card>
            )}
          ></FieldArray>
        </FormGroup>
        <H5>Schedule</H5>
        <RadioGroup
          name="limitedAvailability"
          label="Availability Date Range"
          inline={false}
          onChange={e => {
            setFieldValue('limitedAvailability', e.target.value)
          }}
          selectedValue={values.limitedAvailability}
        >
          <Radio label="Always Available" value="ALWAYS_AVAILABLE" />
          <Radio label="Scheduled" value="SCHEDULED" />
        </RadioGroup>
        {values.limitedAvailability === 'SCHEDULED' && (
          <FormGroup
            labelFor="availabilityDates"
            helperText={
              errors.availabilityStartDate || errors.availabilityEndDate
                ? errors.availabilityStartDate || errors.availabilityEndDate
                : 'Only show this segment between certain dates.'
            }
            intent={
              errors.availabilityStartDate || errors.availabilityEndDate
                ? Intent.DANGER
                : Intent.NONE
            }
          >
            <DateRangeInput
              formatDate={date => moment(date).format('DD/MM/YYYY HH:mm')}
              allowSingleDayRange={true}
              closeOnSelection={true}
              shortcuts={false}
              onChange={dates => {
                setFieldValue(
                  'availabilityStartDate',
                  moment(dates[0]).startOf('day').toDate()
                )
                setFieldValue(
                  'availabilityEndDate',
                  moment(dates[1]).endOf('day').toDate()
                )
              }}
              value={[values.availabilityStartDate, values.availabilityEndDate]}
              parseDate={str => new Date(str)}
            />
          </FormGroup>
        )}
        <br />
        <FormGroup
          labelFor="availabilityTimes"
          label="Availability Times"
          helperText="Schedule your segment to be shown between certain times."
        />
        <FieldArray
          name="availabilityTimes"
          render={({ push, remove }) => (
            <Fragment>
              <FormGroup>
                <Card className="bp3-noPad">
                  <HTMLTable bordered={false} interactive={true}>
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>From</th>
                        <th>Day</th>
                        <th>To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.availabilityTimes &&
                        values.availabilityTimes.map((time, index) => (
                          <AvailabilityTimeSelect
                            key={time.key}
                            onChange={handleChange}
                            index={index}
                            availabilityTimes={values.availabilityTimes}
                            errors={errors}
                            remove={remove}
                            fieldName="availabilityTimes"
                            setFieldValue={setFieldValue}
                          />
                        ))}
                    </tbody>
                  </HTMLTable>
                </Card>
                <Button
                  text="Add New Time"
                  minimal={true}
                  icon="plus"
                  intent={Intent.SUCCESS}
                  onClick={() =>
                    checkForBlankInterval(
                      values.availabilityTimes,
                      setFieldError,
                      push,
                      BLANK_INTERVAL,
                      'availabilityTimes'
                    )
                  }
                />
              </FormGroup>
            </Fragment>
          )}
        />
      </div>
      <div className="bp3-drawer-footer-actions">
        <Button
          intent={Intent.NONE}
          text="Save"
          loading={isSubmitting}
          disabled={!isValid && dirty}
          type="button"
          onClick={handleSubmit}
        />
      </div>
    </form>
  )
}

export default EditSegmentForm

import React, { Fragment, useContext, useMemo, useState } from 'react'
import config from '@config/config'
import { Mutation } from 'react-apollo'
import { string } from 'prop-types'
import {
  Tag,
  Card,
  AnchorButton,
  Intent,
  FormGroup,
  Icon,
  Callout,
  Button,
  H5,
  NonIdealState,
  Classes,
  Drawer,
} from '@blueprintjs/core'

import Query from '@components/Query/Query'
import GET_STRIPE_CLIENT_ID from './queries/getStripeId.query'
import GET_MARKETPLACE_PAYMENTS from './queries/getMarketplacePayments.query'
import EDIT_STRIPE from './mutations/editStripe.mutation'
import CONNECT_MARKETPLACE_LINK from './mutations/connectMarketplace.mutation'

import MarketplaceStripeForm from './MarketplacePaymentForm/MarketplaceStripeForm'
import CopyText from '@components/CopyText/CopyText'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { isAtLeastPartner } from '@stores/userStore'
import { Row, Col } from 'react-simple-flex-grid'

import {
  stripeAccountSessionAppearanceLight,
  stripeAccountSessionAppearanceDark,
} from '@utils/stripe'
import { loadConnectAndInitialize } from '@stripe/connect-js'
import {
  ConnectAccountManagement,
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
  ConnectNotificationBanner,
  ConnectPayouts,
} from '@stripe/react-connect-js'
import { KycCallout } from '@components/Outlet/shared/KycCallout'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'

const MissingData = (fieldName, url) => (
  <Callout intent={Intent.WARNING} title={`Missing ${fieldName}.`}>
    Please ensure your Business details are correct to proceed with setup.
    <AnchorButton
      text="Update Information"
      outlined
      minimal
      intent={Intent.WARNING}
      href={url}
      style={{ margin: '10px 0px 4px 0px' }}
    />
  </Callout>
)

const Payments = ({ match }) => {
  const { marketplace: marketplaceId } = match.params
  const [isLoading, setLoading] = useState(false)
  const [isBannerVisible, setBannerVisible] = useState(false)
  const [isOnboarding, setOnboarding] = useState(false)
  const [stripeAccountSession, setStripeAccountSession] = useState({
    clientPublicKey: null,
    clientSecret: null,
  })
  const { dark } = useContext(PageLayoutContext)

  const stripeConnectInstance = useMemo(
    () =>
      stripeAccountSession && stripeAccountSession.clientPublicKey
        ? loadConnectAndInitialize({
            publishableKey: stripeAccountSession.clientPublicKey || null,
            appearance: dark
              ? stripeAccountSessionAppearanceDark
              : stripeAccountSessionAppearanceLight,
            fetchClientSecret: async () => {
              return stripeAccountSession.clientSecret || null
            },
          })
        : null,
    [stripeAccountSession, dark]
  )

  const handleBannerStateChange = response => {
    setBannerVisible(response.total > 0)
  }

  return (
    <Query
      query={GET_MARKETPLACE_PAYMENTS}
      variables={{ id: marketplaceId }}
      loaderTitle={'Loading Payments'}
      onCompleted={data => {
        const [marketplace] = data.getMarketplaces.regions
        setStripeAccountSession(marketplace.stripeAccountSession)
      }}
    >
      {({ getMarketplaces: { regions } }) => {
        const [marketplace] = regions

        if (!marketplace) return 'Unable to find marketplace.'

        const stripeExpressConnected =
          (marketplace.stripeId || marketplace.stripeEnterpriseId) &&
          marketplace.stripeConnect &&
          marketplace.stripeConnect.accountType !== 'denied'

        const getMarketplaceValidationErrors = () => {
          if (!marketplace.contactAddress) {
            return {
              message:
                'Unable to find outlet address, please setup your outlet business contact details and location.',
              link: `/marketplaces/${marketplace.id}/details`,
            }
          }

          if (!marketplace.contactName) {
            return {
              message:
                'Unable to find outlet contact name, please setup your outlet business contact details and location.',
              link: `/marketplaces/${marketplace.id}/details`,
            }
          }

          if (!marketplace.contactEmail) {
            return {
              message:
                'Unable to find outlet contact email, please setup your outlet business contact details and location.',
              link: `/marketplaces/${marketplace.id}/details`,
            }
          }

          if (!marketplace.companyType) {
            return {
              message:
                'Business type (Limited / Individual) has not been configured.',
              link: `/marketplaces/${marketplace.id}/details`,
            }
          }

          if (
            marketplace.companyType === 'COMPANY' &&
            (!marketplace.companyLegalName || !marketplace.companyNumber)
          ) {
            return {
              message:
                'Legal Company Name or Registration Number has not been provided.',
              link: `/marketplaces/${marketplace.id}/details`,
            }
          }

          return null // No errors
        }

        const validationError = getMarketplaceValidationErrors()

        const renderStripeAccountHelpers = () => {
          return (
            isAtLeastPartner() &&
            (marketplace.stripeId ||
              marketplace.stripeEnterpriseId ||
              marketplace.stripeCustomerPayboxId) && (
              <Card style={{ marginTop: 24 }}>
                <H5>Connected Accounts</H5>
                {marketplace.stripeId && (
                  <FormGroup label="Standard Account">
                    <CopyText
                      leftIcon={<Icon icon="tick-circle" intent="success" />}
                      text={marketplace.stripeId}
                      mono
                    />
                  </FormGroup>
                )}
                {marketplace.stripeEnterpriseId && (
                  <FormGroup label="Enterprise Account">
                    <CopyText
                      leftIcon={<Icon icon="tick-circle" intent="success" />}
                      text={marketplace.stripeEnterpriseId}
                      mono
                    />
                  </FormGroup>
                )}
                {marketplace.stripeCustomerPayboxId && (
                  <FormGroup
                    label={`${marketplace.partner.name} Customer`}
                    helperText="Customer used for onward billing."
                  >
                    <CopyText
                      leftIcon={<Icon icon={'tick-circle'} intent="success" />}
                      text={marketplace.stripeCustomerPayboxId}
                      mono
                    />
                  </FormGroup>
                )}
              </Card>
            )
          )
        }

        // Onboarding
        if (!marketplace.stripeConnect)
          return (
            <Row gutter={24}>
              <Col lg={9} md={8} sm={12} xs={12}>
                <Drawer
                  canOutsideClickClose={false}
                  isOpen={isOnboarding}
                  title={'Account Verification'}
                  onClose={() => setOnboarding(false)}
                >
                  {stripeConnectInstance && (
                    <ConnectComponentsProvider
                      connectInstance={stripeConnectInstance}
                    >
                      <div className={Classes.DRAWER_BODY}>
                        <div className={Classes.DIALOG_BODY}>
                          <ConnectAccountOnboarding
                            onExit={() => {
                              window.location.reload()
                            }}
                          />
                        </div>
                      </div>
                    </ConnectComponentsProvider>
                  )}
                </Drawer>
                {marketplace.stripeId && !marketplace.stripeDirectPayment && (
                  <Callout intent={Intent.SUCCESS} style={{ marginBottom: 24 }}>
                    Payment processor connected, payout being processed
                    manually.
                  </Callout>
                )}
                {validationError ? (
                  <Callout intent={Intent.WARNING} title="Incomplete Details">
                    <p>{validationError.message}</p>
                    <AnchorButton href={validationError.link} outlined>
                      Update Details
                    </AnchorButton>
                  </Callout>
                ) : (
                  <Fragment>
                    {marketplace.partner.stripeEnterpriseAccessId &&
                      !marketplace.enablePayboxEnterprise && (
                        <Callout
                          intent={Intent.WARNING}
                          style={{ marginBottom: 24 }}
                        >
                          Contact {marketplace.partner.name} to begin
                          verification.
                        </Callout>
                      )}
                    <Card>
                      <H5>
                        Paybox{'  '}
                        {marketplace.partner.stripeEnterpriseAccessId &&
                        marketplace.enablePayboxEnterprise ? (
                          <Tag intent={Intent.SUCCESS} minimal>
                            NEW - READY TO MIGRATE
                          </Tag>
                        ) : (
                          !marketplace.stripeId && (
                            <Tag intent={Intent.SUCCESS} minimal>
                              RECOMMENDED
                            </Tag>
                          )
                        )}
                      </H5>
                      <p className="bp3-running-text">
                        Paybox takes care of automatic fund transfers, ensuring
                        that your payouts are handled effortlessly, without the
                        need for manual intervention. It manages payments
                        efficiently, ensuring quick and reliable transfers with
                        fewer delays. Paybox also automatically verifies
                        businesses, eliminating the need for you to handle
                        verification manually. This ensures compliance with
                        financial regulations and provides additional benefits
                        like automatic KYC checks, dispute management, and
                        consolidated reporting.
                      </p>
                      {marketplace.stripeAccountSession ? (
                        <Button
                          text="Continue Verification"
                          rightIcon="chevron-right"
                          intent="success"
                          onClick={() => {
                            setOnboarding(true)
                          }}
                        />
                      ) : (
                        <Mutation
                          mutation={CONNECT_MARKETPLACE_LINK}
                          onCompleted={() => {
                            setOnboarding(true)
                          }}
                          onError={error => {
                            setLoading(false)
                            defaultErrorHandler(error)
                          }}
                          refetchQueries={[
                            {
                              query: GET_MARKETPLACE_PAYMENTS,
                              variables: { id: marketplaceId },
                            },
                          ]}
                        >
                          {connectMarketplace => {
                            return (
                              <Button
                                text="Begin Verification"
                                rightIcon="chevron-right"
                                disabled={
                                  (marketplace.stripeId &&
                                    !marketplace.enablePayboxEnterprise) ||
                                  (marketplace.partner
                                    .stripeEnterpriseAccessId &&
                                    !marketplace.enablePayboxEnterprise)
                                }
                                intent="success"
                                loading={isLoading}
                                onClick={() => {
                                  setLoading(true)
                                  connectMarketplace({
                                    variables: {
                                      marketplaceId: marketplace.id,
                                    },
                                  })
                                }}
                              />
                            )
                          }}
                        </Mutation>
                      )}{' '}
                      {!marketplace.enablePayboxEnterprise &&
                        !marketplace.partner.stripeEnterpriseAccessId &&
                        !marketplace.stripeId && (
                          <Query
                            query={GET_STRIPE_CLIENT_ID}
                            variables={{ marketplaceId }}
                          >
                            {({ getStripeClientId: { stripeClientId } }) => {
                              if (!stripeClientId)
                                return 'Unable to find platform'

                              return (
                                <Fragment>
                                  <br />
                                  <br />
                                  <br />
                                  <H5>
                                    Connect Payment Processor{' '}
                                    <Tag intent={Intent.NONE} minimal>
                                      MANUAL PAYOUTS
                                    </Tag>
                                  </H5>
                                  <p className="bp3-running-text">
                                    Link your existing Stripe account to start
                                    processing payments directly through your
                                    own account. This option gives you complete
                                    control over your funds and transaction
                                    management. However, it requires manual
                                    payouts, which means you will need to
                                    transfer the funds to your bank manually at
                                    your discretion.
                                  </p>

                                  <p className="bp3-text-small bp3-text-muted">
                                    <Icon icon="warning-sign" /> Businesses must
                                    be invoiced and paid manually.
                                  </p>
                                  <AnchorButton
                                    text="Connect Stripe"
                                    rightIcon="share"
                                    href={`https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write&client_id=${stripeClientId}&redirect_uri=${config.apiUrl}/api/stripe/connect&state=marketplaces_${marketplaceId}`}
                                  />
                                </Fragment>
                              )
                            }}
                          </Query>
                        )}
                    </Card>
                  </Fragment>
                )}
              </Col>
              <Col lg={3} md={4} sm={12} xs={12}>
                <KycCallout />
                {renderStripeAccountHelpers()}
              </Col>
            </Row>
          )

        return (
          stripeConnectInstance && (
            <Fragment>
              {marketplace.stripeConnect &&
                !marketplace.stripeConnect.payoutsEnabled &&
                marketplace.stripeDirectPayment && (
                  <Callout
                    style={{ marginBottom: '24px' }}
                    intent={Intent.WARNING}
                    icon={'error'}
                    title="Earnings Payments Suspended"
                  >
                    Your account may need further verification to enable bank
                    payments.
                  </Callout>
                )}
              {marketplace.stripeConnect &&
                !marketplace.stripeConnect.chargesEnabled &&
                marketplace.stripeDirectPayment && (
                  <Callout
                    style={{ marginBottom: '24px' }}
                    intent={Intent.DANGER}
                    icon={'ban-circle'}
                    title="Ordering Disabled"
                  >
                    Your account may need further verification to process card
                    payments.
                  </Callout>
                )}

              {!marketplace.stripeDirectPayment && !stripeExpressConnected && (
                <Callout
                  style={{ marginBottom: '24px' }}
                  intent={Intent.NONE}
                  icon={'warning-sign'}
                  title="Manual Payouts"
                >
                  You are currently processing payouts manually.
                </Callout>
              )}

              <ConnectComponentsProvider
                connectInstance={stripeConnectInstance}
              >
                <div
                  style={
                    isBannerVisible
                      ? {
                          marginBottom: '24px',
                        }
                      : {}
                  }
                >
                  <ConnectNotificationBanner
                    collectionOptions={{
                      fields: 'eventually_due',
                      futureRequirements: 'include',
                    }}
                    onNotificationsChange={handleBannerStateChange}
                  />
                </div>
                <Row gutter={24}>
                  {() => {
                    if (!marketplace.contactAddress)
                      return MissingData(
                        'company address',
                        `/marketplaces/${marketplace.id}/details`
                      )
                    if (!marketplace.companyLegalName)
                      return MissingData(
                        'company name',
                        `/marketplaces/${marketplace.id}/details`
                      )

                    if (!marketplace.contactEmail)
                      return MissingData(
                        'company email',
                        `/marketplaces/${marketplace.id}/details`
                      )
                  }}

                  <Col lg={9} md={8} sm={12} xs={12}>
                    {!marketplace.stripeDirectPayment &&
                    !stripeExpressConnected ? (
                      <Card>
                        <NonIdealState
                          icon="list"
                          title="Manual Payouts"
                          description={`This Marketplace is paid manually by the Partner.`}
                        />
                      </Card>
                    ) : (
                      <Card>
                        <ConnectPayouts />
                      </Card>
                    )}
                  </Col>

                  <Col lg={3} md={4} sm={12} xs={12}>
                    {marketplace.stripeDirectPayment ? (
                      <Card>
                        <ConnectAccountManagement />
                      </Card>
                    ) : (
                      <Fragment>
                        <Callout
                          icon={
                            marketplace.stripeOnboarding ? 'upload' : 'circle'
                          }
                          intent={
                            marketplace.stripeOnboarding
                              ? Intent.SUCCESS
                              : Intent.WARNING
                          }
                        >
                          <H5>1. Onboard Outlets</H5>
                          <p>
                            Ensure all outlets have onboarded and uploaded KYC
                            documentation. Send your outlets to their onboarding
                            link to set up their account and payment
                            information.
                          </p>
                          {!marketplace.stripeOnboarding && (
                            <p>
                              <strong>
                                Contact "{marketplace.partner.name}" to enable
                                onboarding.
                              </strong>
                            </p>
                          )}
                          <AnchorButton
                            text="View Outlets"
                            href={
                              isAtLeastPartner()
                                ? `/outlets?marketplaceIds=${marketplace.id}`
                                : `/outlets`
                            }
                            target="_blank"
                            alignText="left"
                            rightIcon="arrow-right"
                            icon="list-columns"
                            fill
                            minimal
                            style={{ margin: '4px 0px 4px 0px' }}
                          />
                        </Callout>
                        <br />
                        <Callout icon={'circle'}>
                          <H5>2. Enable Direct Payment Flow</H5>
                          <p>
                            Finally, once all outlets have onboarded you can
                            activate paybox on your account and outlets will be
                            paid directly.
                          </p>
                          <p>
                            It is important to do this at the end of your
                            payment period and before any transactions have
                            taken place for the current payment cycle.
                          </p>

                          <p>
                            <strong>
                              Contact "{marketplace.partner.name}" to agree an
                              activation date.
                            </strong>
                          </p>

                          <AnchorButton
                            text="Paybox Documentation"
                            href={`https://support.redbox.systems/docs/paybox-enterprise`}
                            target="_blank"
                            alignText="left"
                            icon="help"
                            rightIcon="share"
                            fill
                            minimal
                            style={{ margin: '4px 0px 4px 0px' }}
                            intent={Intent.PRIMARY}
                          />
                        </Callout>
                        <br />
                      </Fragment>
                    )}
                    {marketplace.stripeConnect && isAtLeastPartner() && (
                      <Fragment>
                        <Mutation
                          mutation={EDIT_STRIPE}
                          onError={defaultErrorHandler}
                          onCompleted={({ editMarketplace }) =>
                            successToast(editMarketplace.message)
                          }
                        >
                          {editStripe => (
                            <Card>
                              <H5>Paybox Setup</H5>
                              <MarketplaceStripeForm
                                stripeExpressConnected={
                                  stripeExpressConnected &&
                                  marketplace.stripeConnect.chargesEnabled
                                }
                                initialValues={marketplace}
                                onSubmit={values =>
                                  editStripe({
                                    variables: {
                                      ...values,
                                      id: marketplaceId,
                                    },
                                  })
                                }
                              />
                            </Card>
                          )}
                        </Mutation>

                        {renderStripeAccountHelpers()}
                      </Fragment>
                    )}
                  </Col>
                </Row>
              </ConnectComponentsProvider>
            </Fragment>
          )
        )
      }}
    </Query>
  )
}

Payments.propTypes = {
  marketplaceId: string.isRequired,
}

export default Payments

import React from 'react'
import { object, func } from 'prop-types'
import injectSheet from 'react-jss'
import ReactSVG from 'react-svg'
import { withFormik } from 'formik'
import { Mutation } from 'react-apollo'
import { Card, Button } from '@blueprintjs/core'
import ls from '@utils/localStorage'
import get from 'lodash/get'
import { errorToast } from '@utils/toast'

import styles from '@components/Login/Login.styles'
import logo from '@assets/logo.svg'
import VALIDATE_PHONE from './mutations/validatePhone.mutation'

const handleLogin = (validatePhone, history) => history.push('/2fa')

const ValidatePhoneWrapper = props => {
  const { classes, history } = props
  const { container, loginContainer, logoContainer, loginForm, icon } = classes

  return (
    <div className={container}>
      <div className={loginContainer}>
        <ReactSVG src={logo} alt={'logo'} className={logoContainer} />
        <Card elevation="2" className={loginForm}>
          <Mutation
            mutation={VALIDATE_PHONE}
            onError={error => {
              const serverErrors = get(error, 'graphQLErrors', [])

              // fallback for errors that weren't returned by the server
              if (!serverErrors.length) {
                return errorToast(error.message)
              }

              // loop through custom server errors displaying error toast
              for (const serverError of serverErrors) {
                errorToast(serverError.message)
              }

              props.setSubmitting(false)
            }}
            onCompleted={({ validatePhone }) => {
              handleLogin(validatePhone, history)
            }}
          >
            {validatePhone => (
              <div>
                <p>
                  Your phone number needs validating before you can continue
                </p>
                <h3>{ls.get('phoneNumber')}</h3>
                <p>
                  By continuing you confirm this is your phone number and you
                  consent to receive SMS messages for two factor authentication
                </p>
                <Button
                  type="submit"
                  onClick={() => validatePhone()}
                  text="Accept & Send Code"
                />
              </div>
            )}
          </Mutation>
        </Card>
      </div>
    </div>
  )
}

const ValidatePhone = withFormik({
  mapPropsToValues: () => ({
    code: '',
  }),
})(ValidatePhoneWrapper)

ValidatePhone.propTypes = {
  classes: object,

  history: object,
  setSubmitting: func,
}

export default injectSheet(styles)(ValidatePhone)
export { ValidatePhone }

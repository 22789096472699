import { AnchorButton, Tooltip } from '@blueprintjs/core'
import React from 'react'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '../../../utils/defaultErrorHandler'
import { successToast } from '../../../utils/toast'
import QUEUE_INVOICE_EMAIL from './mutations/queueInvoiceEmail.mutation'

const EmailCell = ({ outlet, invoice, refetchQueries }) => {
  const invoiceEmailsDisabledText =
    !outlet.emailInvoices &&
    'Invoice emails are currently disabled on this outlet.'
  return (
    <Mutation
      mutation={QUEUE_INVOICE_EMAIL}
      onCompleted={() => {
        successToast(
          'Email has been queued. It will usually be received within 10 minutes, but may take longer during busy periods.'
        )
      }}
      onError={defaultErrorHandler}
      refetchQueries={refetchQueries}
    >
      {queueInvoiceEmail => {
        if (!invoice) {
          return (
            <td className="bp3-action-cell">
              <Tooltip content="Email will be sent after the pdf is generated.">
                <AnchorButton minimal disabled icon="time" />
              </Tooltip>
            </td>
          )
        }
        if (invoice.emailStatus === 'NEVER_QUEUED') {
          return (
            <td className="bp3-action-cell">
              <Tooltip
                content={`Email not sent. ${
                  invoiceEmailsDisabledText || 'Select to send.'
                }`}
              >
                <AnchorButton
                  minimal
                  disabled={!outlet.emailInvoices}
                  onClick={() => {
                    queueInvoiceEmail({
                      variables: { invoiceKey: invoice.key },
                    })
                  }}
                  icon="send-message"
                />
              </Tooltip>
            </td>
          )
        }
        if (invoice.emailStatus === 'QUEUED') {
          return (
            <td className="bp3-action-cell">
              <Tooltip content="Email queued. It is usually be received by recipient within 10 minutes but may take longer during busy periods.">
                <AnchorButton minimal disabled icon="envelope" />
              </Tooltip>
            </td>
          )
        }
        if (invoice.emailStatus === 'SENT') {
          return (
            <td className="bp3-action-cell">
              <Tooltip
                content={`Email sent. ${
                  invoiceEmailsDisabledText || 'Select to send again.'
                }`}
              >
                <AnchorButton
                  minimal
                  disabled={!outlet.emailInvoices}
                  onClick={() => {
                    queueInvoiceEmail({
                      variables: { invoiceKey: invoice.key },
                    })
                  }}
                  icon="envelope"
                />
              </Tooltip>
            </td>
          )
        }
        if (invoice.emailStatus === 'FAILED') {
          return (
            <td className="bp3-action-cell">
              <Tooltip
                content={`Email failed to send. ${
                  invoiceEmailsDisabledText || 'Select to try again.'
                }`}
              >
                <AnchorButton
                  minimal
                  disabled={!outlet.emailInvoices}
                  onClick={() => {
                    queueInvoiceEmail({
                      variables: { invoiceKey: invoice.key },
                    })
                  }}
                  icon="envelope"
                  intent="danger"
                />
              </Tooltip>
            </td>
          )
        }
        return (
          <td className="bp3-action-cell">
            <Tooltip content="Unexpected email status.">
              <AnchorButton minimal disabled icon="envelope" intent="warning" />
            </Tooltip>
          </td>
        )
      }}
    </Mutation>
  )
}

export default EmailCell

import React from 'react'
import {
  InputGroup,
  ControlGroup,
  Button,
  Classes,
  Tooltip,
} from '@blueprintjs/core'
import { infoToast, errorToast } from '@utils/toast'
import { bool, string } from 'prop-types'

const fallbackCopyTextToClipboard = text => {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
    infoToast(`Copied '${text}' to clipboard.`)
  } catch (err) {
    errorToast('Failed to copy webhook URL to clipboard. Please manually copy')
  }

  document.body.removeChild(textArea)
}

const copyTextToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard
    .writeText(text)
    .then(() => infoToast(`Copied '${text}' to clipboard.`))
    .catch(() =>
      errorToast('Failed to copy to clipboard. Please manually copy.')
    )
}

const CopyText = ({
  text,
  mono,
  minimal = false,
  large = false,
  leftElement,
  leftIcon,
  fill = false,
}) => {
  return (
    <ControlGroup fill={true} vertical={false}>
      <InputGroup
        leftIcon={leftIcon}
        value={text}
        readOnly
        large={large}
        fill={fill}
        className={`${mono && 'bp3-monospace-text'} ${
          minimal && 'bp3-minimal'
        }`}
        leftElement={leftElement}
        rightElement={
          <Tooltip content="Copy">
            <Button
              minimal
              className={Classes.FIXED}
              icon="clipboard"
              onClick={() => copyTextToClipboard(text)}
            />
          </Tooltip>
        }
      />
    </ControlGroup>
  )
}

CopyText.propTypes = {
  text: string,
  mono: bool,
  minimal: bool,
  large: bool,
  fill: bool,
}

export default CopyText

import React from 'react'
import { matchType } from '@utils/types'
import ReviewsTable from '@components/Reviews/ReviewsTable';

const CustomerReviews = ({ match }) => {
  const queryVars = {
    customerId: match.params.customer,
    hideCustomer: true
  }
  return (
    <ReviewsTable queryVars={queryVars} />
  )
}

CustomerReviews.propTypes = {
  match: matchType,
}

export default CustomerReviews

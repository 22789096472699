import * as yup from 'yup'
import { addMenuItemsGroupStructure } from '../../../../validation/menu-item-group'
const {
  name,
  availabilityStartDate,
  availabilityEndDate,
  parentMenuId,
  availabilityTimes,
} = addMenuItemsGroupStructure

export const validation = yup.object().shape({
  name,
  limitedAvailability: yup.boolean(),
  availabilityStartDate: yup.mixed().when('limitedAvailability', {
    is: true,
    then: availabilityStartDate,
  }),
  availabilityEndDate: yup.mixed().when('limitedAvailability', {
    is: true,
    then: availabilityEndDate,
  }),
  parentMenuId,
  availabilityTimes,
})

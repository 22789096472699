import React, { Fragment } from 'react'
import {
  Icon,
  Popover,
  PopoverInteractionKind,
  Position,
  Tag,
} from '@blueprintjs/core'
import { togglePane, setValue } from '@utils/cacheHelpers'
import SubMenu from './SubMenu'
import { get, countBy, sumBy } from 'lodash'
import { string, object, bool } from 'prop-types'
import cx from 'classnames'

import Query from '@components/Query/Query'
import GET_SUB_MENUS from '@components/Restaurant/Menu/queries/getSubMenus.query'
import GET_OUTLET_SUB_MENUS from '@components/Restaurant/Menu/queries/getOutletSubMenus.query'
import MenusList from './MenusList'
import { removeExpiredSoldOut } from '@utils/removeExpiredSoldOut'
import OutletSubMenu from '@components/Restaurant/Menu/MenuTree/OutletSubMenu'

const formatMenus = ({
  outlet,
  menus,
  classes,
  queryId,
  subMenuId,
  firstSub,
  menuId,
  outletSoldOut,
  hiddenMenuItemGroupIds,
  outletId,
}) =>
  menus.map((menu, index, menus) => {
    const outletsSoldOutArray = removeExpiredSoldOut(outletSoldOut)
    const childrenMenus = menu.childrenMenus || []
    const soldCount = sumBy([menu, ...childrenMenus], menu => {
      return (
        countBy(menu.menuItems, item => {
          return outlet
            ? item.outletSoldOut
            : outletsSoldOutArray.includes(item.id)
        }).true || 0
      )
    })

    return {
      id: menu.id,
      key: menu.id,
      icon: 'clipboard',
      label: (
        <Fragment>
          {menu.name}{' '}
          {soldCount > 0 ? <Tag intent={'danger'}>{soldCount}</Tag> : null}
        </Fragment>
      ),
      className: cx({
        [classes.selected]: subMenuId === menu.id,
        [classes.hidden]: hiddenMenuItemGroupIds.includes(menu.id),
      }),
      onClick: () => {
        if (firstSub) {
          setValue({
            paneNumber: 2,
            itemMenuOpen: false, // dont opne menu items possibly more sub menus
            subMenuId: menu.id,
            subSubMenuId: '',
            itemQueryId: menu.id,
            itemDetailsOpen: false,
          })
        } else {
          togglePane({
            itemMenuOpen: true, // open the MenuItems comp because you cant drill deeper
            itemQueryId: menu.id,
            subSubMenuId: menu.id,
            itemDetailsOpen: false,
          })
        }
      },
      secondaryLabel: outlet ? (
        <div
          onClick={e => {
            e.stopPropagation()
            setValue({
              subMenuId: menu.id,
              paneNumber: 2,
            })
          }}
        >
          <Popover
            content={
              <OutletSubMenu
                id={menu.id}
                outletId={outletId}
                hidden={hiddenMenuItemGroupIds.includes(menu.id)}
              ></OutletSubMenu>
            }
            interactionKind={PopoverInteractionKind.CLICK}
            position={Position.LEFT}
          >
            <Icon icon="more" />
          </Popover>
        </div>
      ) : (
        // stops pane from opening if user just clicks on submenu
        <div
          onClick={e => {
            e.stopPropagation()
            if (firstSub) {
              setValue({
                paneNumber: 2,
                subMenuId: menu.id,
                itemQueryId: menu.id,
              })
            } else {
              setValue({
                itemQueryId: menu.id,
              })
            }
          }}
        >
          <Popover
            content={
              <SubMenu
                id={menu.id}
                menuId={menuId}
                queryId={queryId}
                firstSub={firstSub}
                menus={menus}
                index={index}
                outlet={outlet}
              />
            }
            interactionKind={PopoverInteractionKind.CLICK}
          >
            <Icon icon="more" />
          </Popover>
        </div>
      ),
    }
  })

const SubMenuPane = ({
  queryId,
  menuId,
  classes,
  subMenuId,
  firstSub,
  outlet,
}) => (
  <Query
    query={outlet ? GET_OUTLET_SUB_MENUS : GET_SUB_MENUS}
    variables={{ id: queryId, menuId }}
    fetchPolicy="network-only"
  >
    {data => {
      const menus = get(
        data,
        outlet
          ? 'getOutlets.outlets[0].menuItemsGroups[0].childrenMenus'
          : 'getRestaurants.restaurants[0].menuItemsGroups[0].childrenMenus',
        []
      )

      const outletSoldOut = get(
        data,
        outlet ? [] : 'getRestaurants.restaurants[0].outlets',
        []
      )
      const hiddenMenuItemGroupIds = get(
        data,
        outlet ? 'getOutlets.outlets[0].hiddenMenuItemGroupIds' : [],
        []
      )

      const outletId = get(
        data,
        outlet
          ? 'getOutlets.outlets[0].id'
          : 'getRestaurants.restaurants[0].outlets[0].id',
        []
      )

      if (menus.length > 0) {
        return (
          <MenusList
            menus={formatMenus({
              outlet,
              menus,
              classes,
              queryId,
              subMenuId,
              firstSub,
              menuId,
              outletSoldOut,
              hiddenMenuItemGroupIds,
              outletId,
            })}
            classes={classes}
          />
        )
      } else {
        togglePane({
          itemMenuOpen: true,
        })
        return null
      }
    }}
  </Query>
)

SubMenuPane.propTypes = {
  queryId: string.isRequired,
  menuId: string.isRequired,
  classes: object,
  subMenuId: string,
  firstSub: bool,
  outlet: bool,
}
export default SubMenuPane

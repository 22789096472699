import React, { Fragment } from 'react'
import {
  Card,
  H3,
  Icon,
  Classes,
  Intent,
  Dialog,
  Tag,
  AnchorButton,
} from '@blueprintjs/core'
import { Row, Col } from 'react-simple-flex-grid'
import { func } from 'prop-types'
import AddPaymentMethodButton from '@components/Billing/AddBillingPaymentMethodButton'
import GET_MARKETPLACE_BILLING_FEATURES from '../queries/getMarketplaceBillingFeatures.query'
import { first } from 'lodash'
import AddBillingSubscriptionButton from '../AddBillingSubscriptionButton'
import Query from '@components/Query/Query'

const MarketingDialog = ({
  marketplaceId,
  isOpen = false,
  usePortal = true,
  paywall = false,
  onClose = () => {},
}) => (
  <Dialog
    isOpen={isOpen}
    usePortal={usePortal}
    style={{ top: 100, position: 'absolute' }}
    title={paywall ? null : 'Add-On Features'}
    icon={paywall ? null : 'cube'}
    onClose={onClose}
  >
    <div className={Classes.DIALOG_BODY}>
      <Row>
        <Col span={6}>
          <H3>Marketing</H3>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Tag intent="primary">ADD-ON</Tag>
        </Col>
      </Row>

      <Row>
        <Col span="12" className="bp3-running-text">
          {paywall ? (
            <Fragment>
              <p style={{ marginTop: 10 }}>
                <span className="bp3-text-large">
                  Thank you for trying our <strong>Starter Marketing</strong>{' '}
                  plan.
                </span>
                <br />
                <span className="bp3-text-small bp3-text-muted">
                  (Free up to: 1000 Active Channels + 1 'All Customers' Segment)
                </span>
              </p>
              <p>
                It's great to see your marketplace growing, to continue using
                this feature please add billing details to your marketplace.
              </p>
            </Fragment>
          ) : (
            <p>
              Take your marketplace to the next level with multi-channel
              marketing campaigns.
            </p>
          )}
          <Card style={{ marginBottom: 10, marginTop: 20 }}>
            <strong>Premium Marketing Features</strong>
            <p>
              Take your marketplace to the next level with these advanced
              marketing features:
            </p>
            <ul
              className="bp3-list-unstyled bp3-list"
              style={{ marginTop: 20 }}
            >
              <li>
                <Icon icon="tick-circle" intent="success" /> 1000+ Customer
                Channels
              </li>
              <li>
                <Icon icon="tick-circle" intent="success" />
                {'  '}
                Unlimited Segments
              </li>
              <li>
                <Icon icon="tick-circle" intent="success" />
                {'  '}
                Unlimited Campaigns
              </li>
              <li>
                <Icon icon="tick-circle" intent="success" />
                {'  '}
                SMS Marketing{'  '}
                <span className="bp3-text-muted bp3-text-small">
                  (4p per message)
                </span>
              </li>
              <li>
                <Icon icon="tick-circle" intent="success" />
                {'  '}Meta Business Customer Sync
              </li>
            </ul>
          </Card>
          <p className="bp3-text-small">
            A plan will automatically be selected depending on your usage.
            <ul className="bp3-text-muted ">
              <li>
                Active payment method is required to use certain features.
              </li>
              <li>
                Active Marketing Channels, Email, Push, SMS are linked to
                customers with marketing preferences enabled.
              </li>
              <li>
                Plans billed monthly in advance based on the Active Marketing
                Channels on the 1st day of the month.
              </li>
              <li>SMS charges billed in arrears. </li>
              <li>Prices are excluding VAT unless otherwise stated.</li>
            </ul>
          </p>
        </Col>
      </Row>
    </div>
    <div className={Classes.DIALOG_FOOTER}>
      <Query
        query={GET_MARKETPLACE_BILLING_FEATURES}
        variables={{ id: marketplaceId }}
      >
        {({ getMarketplaces: { regions } }) => {
          const marketplace = first(regions)
          if (!marketplace) {
            return 'Unable to find marketplace'
          }
          const paymentMethods = marketplace.billing.paymentMethods
          const isPaymentSetup = paymentMethods.length > 0
          const hasSubscription = marketplace.billing.products.some(product => {
            return product.service === 'MARKETING' && product.subscribed
          })

          return (
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <AnchorButton
                target="_blank"
                href="https://support.redbox.systems/docs/marketing"
                minimal
                intent={Intent.PRIMARY}
                text="Pricing & Documentation"
              />
              {isPaymentSetup ? (
                <AddBillingSubscriptionButton
                  marketplaceId={marketplaceId}
                  icon="cube-add"
                  disabled={hasSubscription}
                  text="Add Marketing"
                  product="MARKETING"
                  intent={Intent.SUCCESS}
                  onCompleted={() => {
                    onClose()
                  }}
                />
              ) : (
                <AddPaymentMethodButton
                  id={marketplaceId}
                  level="MARKETPLACE"
                  product="MARKETING"
                />
              )}
            </div>
          )
        }}
      </Query>
    </div>
  </Dialog>
)

MarketingDialog.propTypes = {
  marketplaceId: String,
  isOpen: Boolean,
  usePortal: Boolean,
  paywall: Boolean,
  onClose: func,
}

export default MarketingDialog

import React, { Fragment } from 'react'
import {
  Popover,
  Tag,
  PopoverInteractionKind,
  Position,
  Classes,
  H5,
} from '@blueprintjs/core'
import { string, object } from 'prop-types'
import GET_LIVE_SEGMENT_ESTIMATE from '../queries/getLiveSegmentEstimate.query'
import Query from '@components/Query/Query'

const SegmentEstimate = ({ marketplaceId, values }) => (
  <Query
    query={GET_LIVE_SEGMENT_ESTIMATE}
    variables={{ marketplaceId, ...values }}
  >
    {({ getLiveSegmentEstimate }) => (
      <Popover
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.RIGHT_TOP}
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        content={
          <Fragment>
            <H5>Information</H5>
            <p>
              This shows how many customers are in this segment once filtering
              has been applied.
            </p>
          </Fragment>
        }
      >
        <Tag minimal large icon="info-sign">
          Contains:{' '}
          <strong>{getLiveSegmentEstimate.estimate || 0} Customers</strong>
        </Tag>
      </Popover>
    )
    // )
    }
  </Query>
)

SegmentEstimate.propTypes = {
  marketplaceId: string.isRequired,
  values: object.isRequired,
}

export default SegmentEstimate

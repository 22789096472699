import React, { Component } from 'react'
import mapboxgl from 'mapbox-gl'
// eslint-disable-next-line no-undef
const MapboxDraw = require('@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw')
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken = process.env.MAPBOX_KEY

export default class MarketplaceGeoFences extends Component {
  constructor(props) {
    super(props)

    this.state = {
      center: {
        lng: -1.146159,
        lat: 52.809403,
      },
      zoom: 5,
      coordinates: null,
      type: null,
      geoFence: null,
      height: '80vh',
      width: '100%',
    }
  }

  componentDidMount() {
    // create the map
    const { zoom, center } = this.state
    const mapConfig = {
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v10',
      zoom,
      maxZoom: 12,
    }

    if (center !== null) {
      mapConfig.center = [center.lng, center.lat]
    }

    this.map = new mapboxgl.Map(mapConfig)

    new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: false,
        save: false,
      },
    })

    this.map.addControl(new mapboxgl.NavigationControl())

    const bounds = new mapboxgl.LngLatBounds()
    const { marketplaces } = this.props

    const features = marketplaces
      .filter(({ geoFence }) => geoFence)
      .map(marketplace => ({
        type: 'Feature',
        geometry: {
          type: marketplace.geoFence.type,
          coordinates: marketplace.geoFence.coordinates,
        },
        properties: marketplace,
      }))

    const source = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    }

    // features can be drawn only after the map loads
    this.map.on('load', () => {
      this.map.addSource(
        'incomingFeatures', // name matters in the following layers
        source
      )

      this.map.addLayer({
        id: 'colorLayer',
        type: 'fill',
        source: 'incomingFeatures',
        paint: {
          'fill-color': ['get', 'brandColor'],
          'fill-opacity': 0.2,
        },
        filter: ['==', '$type', 'Polygon'],
      })

      this.map.on('mousemove', e => {
        const marketplacesInView = this.map.queryRenderedFeatures(e.point, {
          layers: ['colorLayer'],
        })

        if (marketplacesInView.length) {
          document.getElementById('sidebar').innerHTML =
            marketplacesInView.reduce(
              (string, { properties: marketplace }) =>
                (string += `<div style="display: inline-block; height: 1rem; width: 1rem; background: ${marketplace.brandColor};"></div>&nbsp;${marketplace.name} - ${marketplace.customerCount} Customers<br>`),
              ''
            )
        }
      })

      if (bounds.du !== false) {
        this.map.fitBounds(bounds)
      }
    })
  }

  render() {
    return (
      <div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '400px',
            marginRight: '80px',
            marginTop: '34px',
            padding: '5px',
            background: 'rgba(255, 255, 255, 0.8)',
            color: 'black',
            borderRadius: '3px',
            zIndex: 1,
          }}
        >
          <div id="sidebar">
            Hover over a zone to see marketplace name and customer count
          </div>
        </div>
        <div
          style={{
            height: this.state.height,
            width: this.state.width,
          }}
          id="map"
        />
      </div>
    )
  }
}

import React, { Fragment, useState } from 'react'
import {
  Classes,
  Icon,
  Popover,
  Button,
  Position,
  Checkbox,
  Tag,
  Spinner,
} from '@blueprintjs/core'
import { object, func, bool, arrayOf, string } from 'prop-types'
import { indexOf, orderBy } from 'lodash'
import Query from '@components/Query/Query'
import get from 'lodash/get'
import find from 'lodash/find'
import lodashFilter from 'lodash/filter'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'
import injectSheet from 'react-jss'
import EmojiImageLabel from '@components/EmojiImageLabel'

const Items = ({ classes, onChange, filter, items, showEmojis = false }) => {
  const [search, setSearch] = useState('')

  const onSearchChange = e => {
    setSearch(e.target.value)
  }

  return (
    <Fragment>
      <div className={'bp3-input-group ' + classes.search}>
        <Icon icon="search" />
        <input
          autoFocus
          className={Classes.INPUT}
          type="search"
          placeholder="Search"
          value={search}
          onChange={onSearchChange}
        />
      </div>

      <PerfectScrollBar>
        {lodashFilter(items, ({ name }) =>
          search === ''
            ? true
            : name.toLowerCase().includes(search.toLowerCase())
        ).map(({ id, name, emoji, imageSrc }) =>
          showEmojis ? (
            <Checkbox
              id={id}
              labelElement={
                <EmojiImageLabel
                  label={name}
                  emoji={emoji}
                  imageSrc={imageSrc}
                />
              }
              checked={indexOf(filter, id) >= 0}
              alignIndicator="left"
              onChange={onChange}
              className={classes.checkBoxWithEmojis}
            />
          ) : (
            <Checkbox
              key={id}
              id={id}
              label={name}
              checked={indexOf(filter, id) >= 0}
              alignIndicator="left"
              onChange={onChange}
              className={classes.checkBox}
            />
          )
        )}
      </PerfectScrollBar>
    </Fragment>
  )
}

const Filter = ({
  classes,
  name,
  icon,
  disabled,
  filter,
  onChange,
  query,
  variables,
  dataPath,
  itemPath,
  defaultIsOpen,
  showEmojis,
  mapItems = items => items,
}) => {
  return (
    <Popover
      position={Position.BOTTOM_LEFT}
      boundary="window"
      defaultIsOpen={defaultIsOpen}
    >
      <Button
        icon={icon}
        text={name}
        disabled={disabled}
        rightIcon={
          filter.length === 0 ? (
            'double-caret-vertical'
          ) : (
            <Tag intent="primary">{filter.length}</Tag>
          )
        }
      />

      <div className={classes.popup}>
        <Query
          showLoader={true}
          loaderIcon={
            <div style={{ padding: 12 }}>
              <Spinner size={24} value={null} />
            </div>
          }
          query={query}
          variables={variables}
        >
          {data => {
            const dataItems = get(data, dataPath, [])
            if (!data || !dataItems.length) {
              return <p className={classes.error}>None Available</p>
            }

            const items = dataItems.reduce((acc, dataItem) => {
              const item = itemPath ? get(dataItem, itemPath, false) : dataItem
              if (!find(acc, { id: item.id })) {
                acc = [...acc, item]
              }
              return acc
            }, [])

            const mappedItems = mapItems(items)

            const sortedItems = orderBy(
              mappedItems,
              [item => item.name.toLowerCase()],
              ['asc']
            )

            return (
              <Items
                classes={classes}
                items={sortedItems}
                onChange={onChange}
                filter={filter}
                showEmojis={showEmojis}
              />
            )
          }}
        </Query>
      </div>
    </Popover>
  )
}

Filter.propTypes = {
  name: string,
  icon: string,
  disabled: bool,
  filter: arrayOf(string),
  onChange: func,
  query: object,
  variables: object,
  dataPath: string,
  itemPath: string,
  defaultIsOpen: bool,
  showEmojis: bool,
  mapItems: func,
}

const styles = () => ({
  popup: {
    minWidth: 262,
    maxHeight: 500,
    display: 'flex',
    flexDirection: 'column',
  },
  search: {
    margin: 12,
  },
  checkBox: {
    margin: '0 16 10',
  },
  checkBoxWithEmojis: {
    margin: '0px 16px 10px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  error: {
    padding: 12,
    textAlign: 'center',
  },
})

export default injectSheet(styles)(Filter)

import {
  Classes,
  Icon,
  InputGroup,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { get } from 'lodash'
import React from 'react'
import { Fragment } from 'react'
import { Mutation } from 'react-apollo'
import MoveUpDown from './MoveUpDown'
import {
  ARCHIVE_FOOTER_LINK,
  ARCHIVE_HEADER_LINK,
} from './mutations/deleteLinks.mutation'
import EDIT_FOOTER_LINKS_ORDER from './mutations/editFooterLinksOrder.mutation'
import EDIT_HEADER_LINKS_ORDER from './mutations/editHeaderLinksOrder.mutation'
import GET_MARKETPLACE_WEBSITE from './queries/getMarketplaceWebsite.query'

const AddLinks = ({
  links,
  handleChange,
  name,
  errors,
  position,
  marketplaceId,
  remove,
}) => (
  <Fragment>
    {links.map((link, index) => (
      <tr key={index}>
        <td>
          <InputGroup
            type="text"
            placeholder="Title"
            name={`${name}.${index}.title`}
            onChange={handleChange}
            value={link.title}
            intent={
              get(errors, `[${name}][${index}].title`)
                ? Intent.DANGER
                : Intent.NONE
            }
          />
        </td>
        <td>
          <InputGroup
            type="text"
            placeholder="Link (URL)"
            name={`${name}.${index}.link`}
            onChange={handleChange}
            value={link.link}
            leftElement={
              link.link.length > 0 &&
              !link.link.match(
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
              ) ? (
                <Popover
                  content={
                    <div style={{ paddingRight: '5px' }}>
                      <p style={{ marginLeft: '5px' }}>
                        A valid URL must meet the following criteria:
                      </p>
                      <ul>
                        <li>start with 'www.', 'http://' or 'https://'</li>
                        <li>not start or end with '.' '-'</li>
                        <li>
                          not contain more than one '-' next to each other
                        </li>
                        <li>
                          not contain special characters such as '*' '()' '_'
                          '!'"
                        </li>
                      </ul>
                    </div>
                  }
                  interactionKind={PopoverInteractionKind.HOVER}
                  popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                >
                  <Icon
                    icon="warning-sign"
                    intent={Intent.DANGER}
                    style={{ padding: '5px' }}
                  />
                </Popover>
              ) : null
            }
            intent={
              get(errors, `[${name}][${index}].link`)
                ? Intent.DANGER
                : Intent.NONE
            }
          />
        </td>
        <td>
          <Popover
            content={
              <Fragment>
                <Menu>
                  <MoveUpDown
                    mutationToExecute={
                      position === 'header'
                        ? EDIT_HEADER_LINKS_ORDER
                        : EDIT_FOOTER_LINKS_ORDER
                    }
                    index={index}
                    initialList={links}
                    marketplaceId={marketplaceId}
                  />
                </Menu>
                <MenuDivider />
                <Menu>
                  <Mutation
                    mutation={
                      position === 'header'
                        ? ARCHIVE_HEADER_LINK
                        : ARCHIVE_FOOTER_LINK
                    }
                    onError={defaultErrorHandler}
                    onCompleted={() =>
                      successToast(`${link.title} link successfully deleted`)
                    }
                    refetchQueries={[
                      {
                        query: GET_MARKETPLACE_WEBSITE,
                        variables: { id: marketplaceId },
                      },
                    ]}
                  >
                    {archiveLink => (
                      <MenuItem
                        text="Delete"
                        icon="trash"
                        onClick={async () => {
                          await archiveLink({
                            variables: { id: link.id, marketplaceId },
                          })
                          remove(index)
                        }}
                      />
                    )}
                  </Mutation>
                </Menu>
              </Fragment>
            }
            interactionKind={PopoverInteractionKind.CLICK}
            position={Position.RIGHT}
          >
            <Icon icon="more" />
          </Popover>
        </td>
      </tr>
    ))}
  </Fragment>
)

export default AddLinks

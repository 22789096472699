import React from 'react'
import cx from 'classnames'

const OrderNotes = ({ classes, order: { customerOrderNotes } }) =>
  customerOrderNotes && customerOrderNotes.length ? (
    <section className={cx(classes.section, classes.center)}>
      <h4 className={classes.title}>Order Notes</h4>
      <p className={classes.text}>{customerOrderNotes}</p>
    </section>
  ) : null

export default OrderNotes

const modals = {
  defaults: {
    messageDrawer: {
      isOpen: false,
      templateType: null,
      __typename: 'messageDrawer',
    },
    restaurantModal: false,
    marketplaceModal: false,
    partnerModal: false,
    itemModal: false,
    subMenuModal: false,
    optionModal: false,
    outletModal: false,
    addTableModal: false,
    editTableFriendlyNameModal: false,
    terminalModal: false,
    profileModal: false,
    userModal: false,
    outletDeliveryZoneModal: false,
    parentMenuModal: false,
    editMenuModal: false,
    resetTerminalPinModal: false,
    addDeliveryZoneModal: false,
    addDiscountModal: false,
    editDiscountModal: false,
    editReviewModal: false,
    printOrderModal: false,
    __typename: 'Modals',
  },
}

export default modals

import gql from 'graphql-tag'

const GET_MARKETPLACES = gql`
  query getMarketplaces(
    $id: ID
    $marketplaceIds: [ID]
    $name: String
    $partnerIds: [ID]
    $archived: Boolean
    $allowMarketing: Boolean
    $pinpointApplicationId: String
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    $name_contains: String
    $orderBy: RegionalMarketplaceOrderByInput
    $sesMessagingRequired: Boolean
  ) {
    getMarketplaces(
      id: $id
      marketplaceIds: $marketplaceIds
      name: $name
      partnerIds: $partnerIds
      archived: $archived
      allowMarketing: $allowMarketing
      pinpointApplicationId: $pinpointApplicationId
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
      name_contains: $name_contains
      orderBy: $orderBy
      sesMessagingRequired: $sesMessagingRequired
    ) {
      count
      totalCount
      regions {
        id
        androidVersion
        iosVersion
        app {
          id
        }
        name
        companyLegalName
        key
        cname
        contactName
        contactPhone
        contactEmail
        contactAddress {
          id
          firstLine
          secondLine
          city
          postcode
          country
        }
        country {
          id
          name
          ISO3166Alpha2
          currency {
            name
            iso4217
            symbol
          }
        }
        partner {
          id
          name
          partnerCommissionFee
        }
        allowReviews
        outlets {
          id
        }
        deliveryZones {
          id
        }
        partnerFee
        partnerCharge
        partnerCommissionFee
        platformFee
        platformCharge
        faviconImage
        orderMode
        merchantType
        allowOrderToTable
        allowMarketing
        allowSingleItemOrderNotes
        allowExtendedPreorders
        sesMessaging
        allowLocationSearch
        allowSMSMarketing
        allowBilling
        stripeId
        stripeDirectPayment
        stripeOnboarding
        stripeAccessId
        stripeEnterpriseId
        enablePayboxEnterprise
        enableCustomerV2Client
        stripeConnect {
          detailsSubmitted
          chargesEnabled
          payoutsEnabled
          balance
          balanceCurrency
        }
      }
    }
  }
`

export default GET_MARKETPLACES

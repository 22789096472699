import React from 'react'
import { Mutation } from 'react-apollo'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import DELETE_POS_DEVICE from './mutations/deletePOSDevice'
import GET_POS_DEVICES from './queries/getPOSDevices'
import ConfirmationPopover from '../../ConfirmationPopover/ConfirmationPopover'
import { Button, Intent } from '@blueprintjs/core'
import { string, func } from 'prop-types'

const DeletePOSDeviceButton = ({ posDeviceId, marketplaceId, onClose }) => (
  <Mutation
    mutation={DELETE_POS_DEVICE}
    refetchQueries={[
      {
        query: GET_POS_DEVICES,
        variables: {
          marketplaceIds: [marketplaceId],
        },
      },
    ]}
    onCompleted={() => {
      successToast('POS device deleted successfully')
      onClose()
    }}
    onError={defaultErrorHandler}
  >
    {deletePOSDevice => (
      <ConfirmationPopover
        remove={() =>
          deletePOSDevice({
            variables: { deviceId: posDeviceId },
          })
        }
        confirmationText={`Are you sure you want to delete this POS device?
This will remove the device from all outlets and cannot be undone.`}
      >
        <Button
          style={{
            marginRight: 'auto',
            marginLeft: '0px !important',
          }}
          text="Delete"
          type="button"
          intent={Intent.DANGER}
          minimal
        />
      </ConfirmationPopover>
    )}
  </Mutation>
)

DeletePOSDeviceButton.propTypes = {
  posDeviceId: string.isRequired,
  marketplaceId: string.isRequired,
  onClose: func.isRequired,
}

export default DeletePOSDeviceButton

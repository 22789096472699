import { Card, FormGroup, InputGroup, Spinner } from '@blueprintjs/core'
import { Col, Row } from 'react-flexbox-grid'
import React from 'react'
import EmojiPicker from '@components/Marketplace/MessageTemplates/EmojiPicker'
import Favicon from '@components/Favicon/Favicon'
import iphoneBg from '@assets/ios-iphone-background.png'
import moment from 'moment'
import Query from '@components/Query/Query'
import { get } from 'lodash'
import GET_PUSH_SUBSTITUTIONS from './getPushSubstitutions'
import HTMLPushPreview from './HTMLPushPreview'

const PushNotificationPreview = ({
  values,
  setFieldValue,
  handleChange,
  classes,
}) => (
  <Query
    query={GET_PUSH_SUBSTITUTIONS}
    loaderTitle={'Loading Push Notification Template'}
  >
    {data => {
      if (!data) return <Spinner />

      const { firstName, lastName, email } = data.getMyDetails.user

      const customer = {
        firstName,
        lastName,
        email,
        marketplace: { name: values.marketplaceName },
      }

      const outlet = {
        name: 'Outlet Name',
        restaurant: {
          name: 'Business Name',
        },
        marketplace: { name: values.marketplaceName },
      }

      const order = {
        createdAt: moment().format('h:mma [on] MMMM Do'),
        estimatedCompletionTime: moment()
          .add(30, 'minutes')
          .format('h:mma [on] MMMM Do'),
        estimatedDeliveryDate: `${moment()
          .add(1, 'hour')
          .format('MMMM Do h:mma')} - ${moment()
          .add(2, 'hour')
          .format('h:mma')}`,
        orderType: 'delivery',
        dateToShowUser: `${moment()
          .add(1, 'hour')
          .format('MMMM Do h:mma')} - ${moment()
          .add(2, 'hour')
          .format('h:mma')}`,
        orderNumber: '123456-789-987-654',
        grossTotal: '43.21',
        cancellationNotes:
          'We are unfortunately unable to accept your order. If you have paid for your order online we will not be taking any payment.',
        customer,
        outlet,
      }

      const exampleData = { customer, order }

      return (
        <Row style={{ paddingBottom: '20px' }}>
          <Col md={6}>
            <Card>
              <div>
                <FormGroup
                  label="Title"
                  labelFor="pushTitle"
                  labelInfo="(required)"
                >
                  <InputGroup
                    id="pushTitle"
                    name="pushTitle"
                    value={get(values, 'pushTitle', '')}
                    onChange={handleChange}
                    rightElement={
                      <EmojiPicker
                        setFieldValue={setFieldValue}
                        values={values}
                        name={'pushTitle'}
                      />
                    }
                  />
                </FormGroup>
              </div>
              <FormGroup
                label="Body"
                labelFor="pushBody"
                labelInfo="(required)"
              >
                <InputGroup
                  id="pushBody"
                  name="pushBody"
                  value={get(values, 'pushBody', '')}
                  onChange={handleChange}
                  rightElement={
                    <EmojiPicker
                      setFieldValue={setFieldValue}
                      values={values}
                      name={'pushBody'}
                    />
                  }
                />
              </FormGroup>
            </Card>
          </Col>
          <Col md={6}>
            <Card
              style={{
                background: `url(${iphoneBg}) no-repeat`,
                minHeight: 240,
                width: 400,
                margin: '0 auto',
                color: 'black',
              }}
            >
              <ul className={classes.iosNotifications}>
                <li className={classes.iosNotificationItem}>
                  <div className={classes.iosNotificationItemHeader}>
                    <div className={classes.iosNotificationItemTitle}>
                      <Favicon src={values.marketplaceIcon} />{' '}
                      <span>{values.marketplaceName}</span>
                    </div>
                    <div>{moment().format(`h:mma`)}</div>
                  </div>
                  <div>
                    <strong>
                      <HTMLPushPreview
                        html={values.pushTitle || 'Push Title'}
                        exampleData={exampleData}
                      />
                    </strong>
                  </div>
                  <div>
                    <HTMLPushPreview
                      html={values.pushBody || 'Push Body'}
                      exampleData={exampleData}
                    />
                  </div>
                </li>
              </ul>
            </Card>
          </Col>
        </Row>
      )
    }}
  </Query>
)

export default PushNotificationPreview

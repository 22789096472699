import React from 'react'
import { ButtonGroup, Button, Position, MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import {
  getMonth,
  getYear,
  min,
  setMonth,
  setYear,
  startOfMonth,
  startOfQuarter,
} from 'date-fns'
import MonthAndYearSelector from './MonthAndYearSelector'
import WeekSelector from './WeekSelector'
import DaySelector from './DaySelector'
import QuarterSelector from './QuarterSelector'
import CustomRangeSelector from './CustomRangeSelector'
import {
  DATE_FILTER_TYPES,
  startOfMostRecentMonday,
} from '../../../utils/datetime'
import { useDateRangeQueryParams } from './useDateRangeQueryParams'

/**
 * @typedef {import('./useDateRangeQueryParams').DateRangeFilterOptions & {
 *   disabled?: boolean,
 *   minimal?: boolean
 *   filterTypes?: import('../../../utils/datetime').DateFilterType[]
 * }} ExtendedDateRangeFilterOptions
 */

/**
 * @param {ExtendedDateRangeFilterOptions} props
 */
const DateRangeFilter = ({
  filterTypes = Object.values(DATE_FILTER_TYPES),
  disabled = false,
  minimal = false,
  defaultFilterTypeKey = filterTypes[0].key,
  maxCustomRangeInterval = { months: 1 },
  defaultStartOfRangeDate,
  defaultEndOfRangeDate,
  shifter,
}) => {
  const {
    startOfRangeDateTime,
    shiftedStartOfRangeDateTime,
    endOfRangeDateTime,
    shiftedEndOfRangeDateTime,
    dateFilterTypeKey,
    setRange,
  } = useDateRangeQueryParams({
    defaultFilterTypeKey,
    maxCustomRangeInterval,
    defaultStartOfRangeDate,
    defaultEndOfRangeDate,
    shifter,
  })

  return (
    <ButtonGroup>
      <Select
        filterable={false}
        items={filterTypes}
        itemRenderer={(item, { handleClick }) => (
          <MenuItem
            key={item.key}
            onClick={handleClick}
            text={item.name}
            icon={item.key === dateFilterTypeKey ? 'tick' : 'blank'}
            shouldDismissPopover={false}
          />
        )}
        onItemSelect={selectedDateFilterType => {
          switch (selectedDateFilterType.key) {
            case DATE_FILTER_TYPES.DAY.key: {
              setRange({
                dateFilterTypeKey: selectedDateFilterType.key,
                startOfRangeDate: startOfRangeDateTime,
              })
              break
            }
            case DATE_FILTER_TYPES.WEEK.key: {
              setRange({
                dateFilterTypeKey: selectedDateFilterType.key,
                startOfRangeDate: startOfMostRecentMonday(startOfRangeDateTime),
              })
              break
            }
            case DATE_FILTER_TYPES.MONTH.key: {
              setRange({
                dateFilterTypeKey: selectedDateFilterType.key,
                startOfRangeDate: startOfMonth(startOfRangeDateTime),
              })
              break
            }
            case DATE_FILTER_TYPES.QUARTER.key: {
              setRange({
                dateFilterTypeKey: selectedDateFilterType.key,
                startOfRangeDate: startOfQuarter(startOfRangeDateTime),
              })
              break
            }
            case DATE_FILTER_TYPES.CUSTOM.key: {
              setRange({
                dateFilterTypeKey: selectedDateFilterType.key,
                startOfRangeDate: startOfRangeDateTime,
              })
              break
            }
          }
        }}
        popoverProps={{
          minimal: false,
          position: Position.BOTTOM_LEFT,
          boundary: 'window',
        }}
      >
        <Button disabled={disabled} minimal={minimal} icon={'calendar'}>
          {DATE_FILTER_TYPES[dateFilterTypeKey].name}
        </Button>
      </Select>
      {dateFilterTypeKey === DATE_FILTER_TYPES.DAY.key && (
        <DaySelector
          date={startOfRangeDateTime}
          dateToDisplay={shiftedStartOfRangeDateTime}
          onChangeDate={date => {
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: date,
            })
          }}
          disabled={disabled}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.WEEK.key && (
        <WeekSelector
          startOfWeekDate={startOfRangeDateTime}
          startOfWeekDateToDisplay={shiftedStartOfRangeDateTime}
          onChangeStartOfWeekDate={date => {
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: date,
            })
          }}
          endOfWeekDateToDisplay={shiftedEndOfRangeDateTime}
          disabled={disabled}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.MONTH.key && (
        <MonthAndYearSelector
          monthIndex={getMonth(startOfRangeDateTime)}
          onChangeMonth={monthIndex => {
            // start of the selected month, or the start of the current month, whichever is earlier
            const newStartOfRangeDate = min([
              startOfMonth(setMonth(startOfRangeDateTime, monthIndex)),
              startOfMonth(new Date()),
            ])
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: newStartOfRangeDate,
            })
          }}
          year={getYear(startOfRangeDateTime)}
          onChangeYear={year => {
            // start of the selected month, or the start of the current month, whichever is earlier
            const newStartOfRangeDate = min([
              startOfMonth(setYear(startOfRangeDateTime, year)),
              startOfMonth(new Date()),
            ])
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: newStartOfRangeDate,
            })
          }}
          disabled={disabled}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.QUARTER.key && (
        <QuarterSelector
          startOfQuarterDate={startOfRangeDateTime}
          startOfQuarterDateToDisplay={shiftedStartOfRangeDateTime}
          onChangeStartOfQuarterDate={date => {
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: date,
            })
          }}
          endOfQuarterDate={endOfRangeDateTime}
          endOfQuarterDateToDisplay={shiftedEndOfRangeDateTime}
          disabled={disabled}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.CUSTOM.key && (
        <CustomRangeSelector
          startOfRangeDate={startOfRangeDateTime}
          startOfRangeDateToDisplay={shiftedStartOfRangeDateTime}
          onChangeRange={({ start, end }) => {
            setRange({
              dateFilterTypeKey,
              startOfRangeDate: start,
              endOfRangeDate: end,
            })
          }}
          endOfRangeDate={endOfRangeDateTime}
          endOfRangeDateToDisplay={shiftedEndOfRangeDateTime}
          maxCustomRangeInterval={maxCustomRangeInterval}
          disabled={disabled}
        />
      )}
    </ButtonGroup>
  )
}

export default DateRangeFilter

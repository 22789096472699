import {
  Button,
  Card,
  Classes,
  Dialog,
  FormGroup,
  HTMLTable,
  NumericInput,
  Tag,
} from '@blueprintjs/core'
import { successToast } from '@utils/toast'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { useEffect } from 'react'
import { Mutation } from 'react-apollo'
import Selecto from 'react-selecto'
import UPDATE_DELIVERY_WINDOWS_LIMIT from './mutations/updateDeliveryWindowsLimit.mutation'
import GET_OUTLET_DELIVERY_WINDOWS from './queries/deliveryWindows.query'

const DeliveryWindows = ({ deliveryWindows, match }) => {
  const [windowsPerHour, setWindowsPerHour] = useState([])
  const [selectedDeliveryWindows, setSelectedDeliveryWindows] = useState([])
  const [limit, setLimit] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [shiftHeld, setShiftHeld] = useState(false)

  document.addEventListener('keydown', function (e) {
    if (e.key === 'Shift') {
      setShiftHeld(true)
      return
    }
  })

  document.addEventListener('keyup', function (e) {
    if (e.key === 'Shift') {
      setShiftHeld(false)
      setShowDialog(true)
      return
    }
  })

  const weekdays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]

  const hourlySteps = []
  const initialMoment = moment('00:00', 'HH:mm')
  for (let i = 0; i < 24; i++) {
    hourlySteps.push(initialMoment.format('HH:mm'))
    initialMoment.add(1, 'hours')
  }

  useEffect(() => {
    const splitDeliveryWindows = hourlySteps.map(hourlyStep => {
      const splitHour = hourlyStep.split(':')[0]
      const hourlyDeliveryWindows = deliveryWindows.filter(
        window => moment(window.start).hour() === parseInt(splitHour)
      )
      return hourlyDeliveryWindows.length ? hourlyDeliveryWindows : []
    })

    setWindowsPerHour(splitDeliveryWindows)
  }, [deliveryWindows])

  const handleSelect = e => {
    let selectedWindows = selectedDeliveryWindows
    selectedWindows = [
      ...selectedWindows,
      ...e.added.map(el => {
        el.classList.add('selected')
        return deliveryWindows.find(window => window.id === el.id)
      }),
    ]
    e.removed.map(el => {
      el.classList.remove('selected')
      selectedWindows = selectedWindows.filter(window => window.id !== el.id)
    })
    if (selectedWindows.length === 1) {
      setLimit(selectedWindows[0].limit)
    }
    setSelectedDeliveryWindows(selectedWindows)
    if (!shiftHeld) {
      setShowDialog(true)
    }
  }

  return (
    <div>
      {selectedDeliveryWindows.length ? (
        <Dialog
          title="Order Limits"
          isOpen={showDialog}
          onClose={() => setShowDialog(false)}
        >
          <Mutation
            mutation={UPDATE_DELIVERY_WINDOWS_LIMIT}
            variables={{
              ids: selectedDeliveryWindows.map(window => window.id),
              limit,
            }}
            onCompleted={({ updateDeliveryWindowsLimit: { message } }) => {
              successToast(message)
              setShowDialog(false)
            }}
            refetchQueries={[
              {
                query: GET_OUTLET_DELIVERY_WINDOWS,
                variables: { outletId: match.params.outlet },
              },
            ]}
          >
            {updateWindow => (
              <div style={{ marginLeft: 24, marginRight: 24, marginTop: 12 }}>
                <br />
                <FormGroup
                  label="Set limit for delivery window"
                  labelFor="limit"
                  helperText="The number of orders that can be accepted in this delivery window."
                >
                  <NumericInput
                    name="limit"
                    id="limit"
                    onValueChange={(value, valueAsString) => {
                      setLimit(
                        valueAsString === '' || isNaN(value) ? null : value
                      )
                    }}
                    value={limit === null ? '' : limit}
                    large={true}
                    min={0}
                    placeholder={'None'}
                    allowNumericCharactersOnly={true}
                  />
                </FormGroup>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button onClick={() => limit !== '' && updateWindow()}>
                    Save
                  </Button>
                </div>
              </div>
            )}
          </Mutation>
        </Dialog>
      ) : (
        <span style={{ textAlign: 'center' }}></span>
      )}

      <Selecto
        container={'.windows'}
        dragContainer={'.windows'}
        selectableTargets={['.target']}
        selectByClick={true}
        selectFromInside={true}
        continueSelect={false}
        toggleContinueSelect={'shift'}
        keyContainer={window}
        hitRate={100}
        onSelectEnd={e => handleSelect(e)}
      />
      <h5 className="bp3-heading">Order Volume Limits</h5>
      <p>
        You have the option to set a maximum number of pre-orders to receive for
        each delivery window.
      </p>
      <p className="bp3-text-muted bp3-text-small">
        To set a limit select the windows you would like to edit and apply a
        limit, drag to select multiple.
      </p>
      <br />
      <Card
        className="windows bp3-nopad"
        style={{ overflow: 'scroll', cursor: 'crosshair' }}
      >
        <HTMLTable bordered={false} striped={true}>
          <thead>
            <tr>
              {weekdays.map(weekday => (
                <th
                  key={weekday}
                  style={{ textAlign: 'center', fontWeight: 'bold' }}
                >
                  {weekday}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {windowsPerHour.length &&
              windowsPerHour.map((weekdayDeliveryWindows, index) => (
                <Fragment key={`rowFragment${index}`}>
                  {weekdayDeliveryWindows.filter(
                    deliveryWindow =>
                      moment(deliveryWindow.start).hour() ===
                      parseInt(hourlySteps[index].split(':')[0])
                  ).length === 0 ? null : (
                    <tr key={`row${index}`}>
                      {weekdays.map(weekday => (
                        <td
                          key={`weekdayTd${weekday}`}
                          style={{
                            textAlign: 'center',
                            borderRight: '1px solid #d2d5d8',
                            verticalAlign: 'top',
                          }}
                        >
                          {weekdayDeliveryWindows
                            .filter(
                              deliveryWindow =>
                                moment(deliveryWindow.start).format('dddd') ===
                                weekday
                            )
                            .map(deliveryWindow => (
                              <Fragment
                                key={`col${deliveryWindow.start.valueOf()}`}
                              >
                                <span
                                  className="target bp3-button bp3-minimal bp3-monospace-text"
                                  id={deliveryWindow.id}
                                  style={{
                                    margin: '3px 0 0px 0',
                                    width: 140,
                                    height: 30,

                                    color:
                                      deliveryWindow.limit &&
                                      deliveryWindow.limit > 0
                                        ? 'green'
                                        : undefined,
                                  }}
                                >
                                  {moment(deliveryWindow.start).format('HH:mm')}
                                  {deliveryWindow.limit !== null &&
                                  deliveryWindow.limit >= 0 ? (
                                    <span>
                                      &nbsp;
                                      <Tag
                                        intent={`${
                                          deliveryWindow.limit > 0
                                            ? 'success'
                                            : 'danger'
                                        }`}
                                      >
                                        {deliveryWindow.limit}
                                      </Tag>
                                      &nbsp;
                                    </span>
                                  ) : (
                                    <span>
                                      &nbsp;
                                      <Tag minimal>~</Tag>
                                      &nbsp;
                                    </span>
                                  )}
                                  {moment(deliveryWindow.end).format('HH:mm')}
                                </span>

                                <br />
                              </Fragment>
                            ))}
                        </td>
                      ))}
                    </tr>
                  )}
                </Fragment>
              ))}
          </tbody>
        </HTMLTable>
      </Card>
    </div>
  )
}

export default DeliveryWindows

import React, { Fragment } from 'react'
import { Mutation } from 'react-apollo'
import Query from '@components/Query/Query'
import {
  FormGroup,
  TextArea,
  Card,
  Button,
  Tag,
  Icon,
  RadioGroup,
  Callout,
  Intent,
  H4,
  InputGroup,
} from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Formik } from 'formik'
import { shape, string, func, bool } from 'prop-types'
import { successToast } from '@utils/toast'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { get, times } from 'lodash'
import { removeUnderscores } from '@utils/helpers'
import FulfilmentStatus from '@components/Orders/Order/FulfilmentStatus'

import UPDATE_ORDER_STATUS from './mutations/updateOrderStatus.mutation'
import GET_ORDER from './Order/queries/getOrder.query'

import { getIntent } from './helpers/helpers'
import moment from 'moment'

import { toggleModal } from '@utils/cacheHelpers'
import GET_SAME_WINDOW_ORDERS_COUNT from './queries/getSameWindowOrdersCount.query'

const QUARTER_OF_HOUR = 15 // minutes

const UpdateOrderStatusModal = ({
  modalOpen = false,
  modalClose,
  orderId,
  newOrderStatus,
  updateOrderOptions = {
    updateFulfilmentTime: false,
    offerToPrintOnSuccess: false,
  },
  refetchQueries = [],
}) => (
  <Dialog
    title="Update Order Status"
    isCloseButtonShown={false}
    isOpen={modalOpen}
  >
    <Query query={GET_ORDER} variables={{ id: orderId }}>
      {({ order }) => {
        return (
          <Mutation
            mutation={UPDATE_ORDER_STATUS}
            refetchQueries={[
              ...refetchQueries,
              {
                query: GET_ORDER,
                variables: {
                  id: orderId,
                },
              },
            ]}
            onError={error => {
              defaultErrorHandler(error)
              modalClose()
            }}
            onCompleted={({ updateOrderStatus: { message } }) => {
              successToast(message)

              if (updateOrderOptions.offerToPrintOnSuccess) {
                toggleModal({ printOrderModal: orderId })
              }

              modalClose()
            }}
          >
            {updateOrderStatus => {
              let initialValues = {
                id: orderId,
                orderStatus: newOrderStatus,
                tableId: order.tableId,
              }

              switch (newOrderStatus) {
                case 'ORDER_FAILURE_REFUND':
                  initialValues = {
                    ...initialValues,
                    cancellationNotes:
                      order.outlet.marketplace.defaultCancelText,
                  }
                  break
                case 'REJECTED':
                  initialValues = {
                    ...initialValues,
                    cancellationNotes:
                      order.outlet.marketplace.defaultRejectText,
                  }
                  break
              }

              return (
                <Formik
                  initialValues={initialValues}
                  onSubmit={values => {
                    switch (order.fulfillmentMethod) {
                      case 'DELIVERY': {
                        delete values.estimatedCompletionTime
                        break
                      }
                      case 'NETWORK':
                      case 'COLLECTION': {
                        delete values.estimatedDeliveryDate
                        break
                      }
                    }
                    updateOrderStatus({
                      variables: {
                        ...values,
                      },
                    })
                  }}
                >
                  {props => {
                    const { values, handleSubmit, handleChange, isSubmitting } =
                      props

                    const radioTimeSlots = []
                    const orderTimeString =
                      get(order, 'selectedDeliveryWindow.end') ||
                      order.estimatedCompletionTime ||
                      order.estimatedDeliveryDate

                    const orderTime = moment(orderTimeString)

                    radioTimeSlots.push({
                      label: `No Change`,
                      value: orderTimeString,
                    })

                    times(4, index => {
                      const addMinutes = (index + 1) * QUARTER_OF_HOUR
                      const newOrderTime = orderTime
                        .clone()
                        .add(addMinutes, 'minutes')
                      radioTimeSlots.push({
                        label: `+${addMinutes} Minutes (${newOrderTime.format(
                          'HH:mm'
                        )})`,
                        value: newOrderTime.utc().format(),
                      })
                    })

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="bp3-dialog-body">
                          {order.orderStatus !== newOrderStatus && (
                            <Fragment>
                              {!order.asap &&
                                (newOrderStatus === 'PREPARING' ||
                                  newOrderStatus === 'READY') && (
                                  <Fragment>
                                    <Callout
                                      intent="warning"
                                      title={
                                        newOrderStatus === 'PREPARING'
                                          ? 'Are you ready to prepare this preorder?'
                                          : 'Are you sure this order is ready?'
                                      }
                                    >
                                      <FulfilmentStatus
                                        orderStatus={order.orderStatus}
                                        fulfillmentMethod={
                                          order.fulfillmentMethod
                                        }
                                        updatedAt={order.updatedAt}
                                        estimatedDeliveryDate={
                                          order.estimatedDeliveryDate
                                        }
                                        estimatedCompletionTime={
                                          order.estimatedCompletionTime
                                        }
                                        asap={order.asap}
                                      />
                                    </Callout>
                                    <br />
                                  </Fragment>
                                )}
                              <p>
                                Are you sure you want to update the order
                                status?
                              </p>
                              <Card>
                                <center>
                                  <Tag
                                    large={true}
                                    intent={getIntent(order.orderStatus)}
                                  >
                                    {removeUnderscores(order.orderStatus)}
                                  </Tag>
                                  &nbsp;&nbsp;
                                  <Icon icon={'arrow-right'} />
                                  &nbsp;&nbsp;
                                  <Tag
                                    large={true}
                                    intent={getIntent(newOrderStatus)}
                                  >
                                    {removeUnderscores(newOrderStatus)}
                                  </Tag>
                                </center>
                              </Card>
                            </Fragment>
                          )}

                          {order.fulfillmentMethod !== 'TABLE' &&
                            updateOrderOptions.updateFulfilmentTime && (
                              <Fragment>
                                {!order.asap &&
                                  order.fulfillmentMethod !== 'COLLECTION' && (
                                    <Query
                                      query={GET_SAME_WINDOW_ORDERS_COUNT}
                                      variables={{
                                        orderId: order.id,
                                      }}
                                    >
                                      {data => {
                                        if (!data.getSameWindowOrdersCount) {
                                          return null
                                        }

                                        const {
                                          getSameWindowOrdersCount: {
                                            count,
                                            limit,
                                          },
                                        } = data

                                        return (
                                          <div>
                                            {limit > 0 && count >= limit && (
                                              <Callout
                                                icon="warning-sign"
                                                intent={Intent.WARNING}
                                              >
                                                {`This delivery window limit is ${limit} and there are currently ${count} ${
                                                  count > 1 ? 'orders' : 'order'
                                                } with this window.`}
                                              </Callout>
                                            )}
                                          </div>
                                        )
                                      }}
                                    </Query>
                                  )}
                                <H4>Need more time?</H4>
                                {order.estimatedCompletionTime &&
                                !order.estimatedDeliveryDate ? (
                                  <RadioGroup
                                    label="You can move this order forwards, we'll let the customer know."
                                    name="estimatedCompletionTime"
                                    onChange={handleChange}
                                    options={radioTimeSlots}
                                    selectedValue={
                                      values.estimatedCompletionTime
                                        ? values.estimatedCompletionTime
                                        : radioTimeSlots[0].value
                                    }
                                  />
                                ) : (
                                  <RadioGroup
                                    label="You can move this order forwards, we'll let the customer know."
                                    name="estimatedDeliveryDate"
                                    onChange={handleChange}
                                    options={radioTimeSlots}
                                    selectedValue={
                                      values.estimatedDeliveryDate
                                        ? values.estimatedDeliveryDate
                                        : radioTimeSlots[0].value
                                    }
                                  />
                                )}
                              </Fragment>
                            )}
                          {order.fulfillmentMethod === 'TABLE' &&
                            !order.tableId &&
                            newOrderStatus === 'PREPARING' && (
                              <Callout intent={Intent.WARNING}>
                                This order does not have a table assigned, you
                                must assign a table to update the order status.
                                <InputGroup
                                  placeholder="Table ID"
                                  name="tableId"
                                  value={values.tableId}
                                  onChange={handleChange}
                                />
                              </Callout>
                            )}
                          {['ORDER_FAILURE_REFUND', 'REJECTED'].includes(
                            newOrderStatus
                          ) && (
                            <FormGroup
                              label="Customer Message"
                              labelFor="cancellationNotes"
                              labelInfo="(required)"
                            >
                              <TextArea
                                id="cancellationNotes"
                                growVertically={true}
                                fill={true}
                                onChange={handleChange}
                                style={{ height: 100 }}
                                value={get(values, 'cancellationNotes', '')}
                              />
                            </FormGroup>
                          )}
                        </div>
                        <div className="bp3-dialog-footer">
                          <div className="bp3-dialog-footer-actions">
                            <Button
                              minimal
                              onClick={() => modalClose(false)}
                              disabled={isSubmitting}
                              data-test-id="update-order-close-button"
                            >
                              Close
                            </Button>
                            <Button
                              type="submit"
                              loading={isSubmitting}
                              data-test-id="update-order-comfirm-button"
                              disabled={
                                order.fulfillmentMethod === 'TABLE' &&
                                newOrderStatus === 'PREPARING' &&
                                !values.tableId
                              }
                            >
                              Confirm
                            </Button>
                          </div>
                        </div>
                      </form>
                    )
                  }}
                </Formik>
              )
            }}
          </Mutation>
        )
      }}
    </Query>
  </Dialog>
)

UpdateOrderStatusModal.propTypes = {
  modalClose: func,
  modalOpen: bool,
  newOrderStatus: string,
  orderId: string,
  updateOrderOptions: shape({
    updateFulfilmentTime: bool,
    offerToPrintOnSuccess: bool,
  }),
}

export default UpdateOrderStatusModal

import React from 'react'
import { Classes, Button, Intent } from '@blueprintjs/core'
import Dialog from '@components/Dialog/Dialog'
import { Mutation } from 'react-apollo'
import { modalType } from '@utils/types'
import { Formik } from 'formik'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import { toggleModal } from '@utils/cacheHelpers'
import omit from 'lodash/omit'

import { discountValidation } from '../validation/validation'
import CREATE_DISCOUNT from '../mutations/createDiscount.mutation'
import GET_STANDARD_RESTAURANT_DISCOUNTS from '../queries/getStandardRestaurantDiscounts.query'
import { successToast } from '@utils/toast'
import { numberToPence } from '@utils/helpers'
import CreateDiscountInnerFormStruct from './CreateDiscountInnerFormStruct'

const AddDiscountModal = ({
  allowOutletSelection,
  modalOpen,
  modalClose,
  restaurantId,
}) => (
  <Dialog isOpen={modalOpen} onClose={modalClose} title="Create Discount">
    <div className={Classes.DIALOG_BODY}>
      <Mutation
        mutation={CREATE_DISCOUNT}
        onCompleted={({ createDiscount }) => {
          successToast(createDiscount.message)
          toggleModal({
            addDiscountModal: false,
          })
        }}
        refetchQueries={[
          {
            query: GET_STANDARD_RESTAURANT_DISCOUNTS,
            variables: { id: restaurantId },
          },
        ]}
        onError={defaultErrorHandler}
      >
        {createDiscount => (
          <Formik
            validationSchema={discountValidation}
            validateOnChange={true}
            onSubmit={values => {
              createDiscount({
                variables: {
                  ...omit(values, 'discountAmount', 'minimumSubtotalGross'),
                  discountAmount: numberToPence(values.discountAmount),
                  minimumSubtotalGross: numberToPence(
                    values.minimumSubtotalGross
                  ),
                  allowedOutlets: values.allowedOutletIds,
                  restaurantId,
                },
              })
            }}
            initialValues={{
              name: '',
              discountPercentage: 0,
              discountAmount: 0,
              startDate: null,
              endDate: null,
              daysOfWeek: [],
              minimumSubtotalGross: 0,
              allowedOutletIds: [],
            }}
          >
            {({
              errors,
              handleChange,
              values,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <CreateDiscountInnerFormStruct
                  errors={errors}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  allowOutletSelection={allowOutletSelection}
                  restaurantId={restaurantId}
                />
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button text="Save" intent={Intent.PRIMARY} type="submit" />
                </div>
              </form>
            )}
          </Formik>
        )}
      </Mutation>
    </div>
  </Dialog>
)

AddDiscountModal.propTypes = {
  ...modalType,
}

export default AddDiscountModal

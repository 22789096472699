import { shape, node, string, bool, func, number } from 'prop-types'

export const shortRestaurantType = {
  contactEmail: string,
  contactName: string,
  contactPhone: string,
  id: string,
  name: string,
  contactAddress: shape({
    firstLine: string,
    secondLine: string,
    thirdLine: string,
    city: string,
    postcode: string,
    country: shape({
      code: string,
      name: string,
    }),
  }),
}

export const matchType = shape({
  params: shape({
    id: node,
  }),
})

export const productType = shape({
  name: string,
  crumbNo: number,
})

export const locationType = shape({
  pathname: string,
  search: string,
  hash: string,
  key: string,
  product: productType,
})

export const cuisineType = shape({
  name: string,
})

export const modalType = {
  modalOpen: bool,
  modalClose: func,
}

export const drawerType = {
  isOpen: bool.isRequired,
  onClose: func,
}

import React, { useContext, useEffect } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { Switch, Redirect, Route } from 'react-router-dom'
import CustomersList from './CustomersList'
import ReviewsTable from '@components/Reviews/ReviewsTable'

const Customers = ({ match, product }) => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    configurePageLayout({
      product,
      tabs: [
        { to: `${match.url}/list`, name: 'List' },
        { to: `${match.url}/reviews`, name: 'Reviews' },
      ],
    })
  }, [configurePageLayout, product, match.url])

  return (
    <Switch>
      <Redirect from="/customers" to="/customers/list" exact />
      <Route
        exact={true}
        path={`${match.path}/list`}
        component={CustomersList}
      />
      <Route
        exact={true}
        path={`${match.path}/reviews`}
        component={ReviewsTable}
      />
    </Switch>
  )
}

export default Customers

import React, { useState } from 'react'
import {
  Button,
  Card,
  FormGroup,
  H5,
  InputGroup,
  Popover,
  Position,
} from '@blueprintjs/core'
import { Formik } from 'formik'
import { Row, Col } from 'react-simple-flex-grid'
import { canPerformAction } from '../../../stores/userStore'
import { EDIT_PLATFORM_INTEGRATIONS } from './mutations/editPlatformIntegrations.mutation'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { TEST_PLATFORM_INTEGRATIONS_MUTATION } from './mutations/testPlatformIntegrations.mutation'

/**
 * PlatformIntegrations component for managing integrations with external services.
 *
 * @param {object} props
 * @param {import('../../../../generated/graphql-types').Platform} props.platform - Current platform data.
 */
export const PlatformIntegrations = ({ platform }) => {
  const [storeId, setStoreId] = useState('')
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const handleTestClick = testPlatformIntegrations => {
    if (storeId) {
      testPlatformIntegrations({ variables: { storeId } })
      setIsPopoverOpen(false) // Close the popover after testing
    } else {
      setIsPopoverOpen(true) // Open the popover if storeId is not set
    }
  }

  return (
    <Mutation
      mutation={EDIT_PLATFORM_INTEGRATIONS}
      onError={defaultErrorHandler}
      onCompleted={({ editPlatformIntegrations }) =>
        successToast(
          editPlatformIntegrations.message || 'Platform successfully edited'
        )
      }
    >
      {editPlatformIntegrations => (
        <Formik
          initialValues={{
            otterClientId: platform.otterClientId,
            otterClientSecret: platform.otterClientSecret,
            otterWebhookSecret: platform.otterWebhookSecret,
          }}
          onSubmit={({
            otterClientId,
            otterClientSecret,
            otterWebhookSecret,
          }) =>
            editPlatformIntegrations({
              variables: {
                id: platform.id,
                otterClientId,
                otterClientSecret,
                otterWebhookSecret,
              },
            })
          }
        >
          {({ handleSubmit, handleChange, values }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <Row gutter={48}>
                  <Col span={4}>
                    <H5>Otter</H5>
                    <FormGroup
                      label="Client ID"
                      labelFor="otterClientId"
                      labelInfo="(required)"
                    >
                      <InputGroup
                        id="otterClientId"
                        name="otterClientId"
                        value={values.otterClientId}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup
                      label="Client Secret"
                      labelFor="otterClientSecret"
                      labelInfo="(required)"
                    >
                      <InputGroup
                        id="otterClientSecret"
                        name="otterClientSecret"
                        value={values.otterClientSecret}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup
                      label="Webhook Secret"
                      labelFor="otterWebhookSecret"
                      labelInfo="(required)"
                    >
                      <InputGroup
                        id="ottterWebhookSecret"
                        name="otterWebhookSecret"
                        value={values.otterWebhookSecret}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br />

                <div className="bp-card-footer-actions">
                  <Mutation
                    mutation={TEST_PLATFORM_INTEGRATIONS_MUTATION}
                    onError={defaultErrorHandler}
                  >
                    {(testPlatformIntegrations, { data = null }) => (
                      <Popover
                        isOpen={isPopoverOpen}
                        onClose={() => setIsPopoverOpen(false)}
                        position={Position.RIGHT}
                        content={
                          <div style={{ padding: '10px' }}>
                            <InputGroup
                              placeholder="Enter Store ID"
                              value={storeId}
                              onChange={e => setStoreId(e.target.value)}
                              onKeyPress={e => {
                                if (e.key === 'Enter') {
                                  handleTestClick(testPlatformIntegrations)
                                }
                              }}
                            />
                            <Button
                              intent="primary"
                              text="Submit"
                              onClick={() =>
                                handleTestClick(testPlatformIntegrations)
                              }
                              style={{ marginTop: '10px' }}
                            />
                          </div>
                        }
                      >
                        <Button
                          text={
                            data
                              ? data.testPlatformIntegrations
                                ? 'Test Succeeded'
                                : 'Test Failed'
                              : 'Test'
                          }
                          intent={
                            data
                              ? data.testPlatformIntegrations
                                ? 'success'
                                : 'danger'
                              : 'none'
                          }
                          type="button"
                          disabled={!canPerformAction('editPlatform')}
                          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        />
                      </Popover>
                    )}
                  </Mutation>

                  <Button
                    text="Save"
                    type="submit"
                    disabled={!canPerformAction('editPlatform')}
                  />
                </div>
              </Card>
            </form>
          )}
        </Formik>
      )}
    </Mutation>
  )
}

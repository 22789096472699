import { DateSelect } from '@components/Toolbar'
import React, { useContext } from 'react'
import {
  ButtonGroup,
  Popover,
  Position,
  Button,
  Checkbox,
  Divider,
  MenuItem,
} from '@blueprintjs/core'
import { MapDisplayBy } from '@components/Analytics/util/maps'
import PerfectScrollBar from '@components/PerfectScrollBar/PerfectScrollBar'
import { BusinessAnalyticsContext } from './BusinessAnalyticsContext'
import FilterRow from '@components/FilterRow/FilterRow'
import moment from 'moment'
import { Select } from '@blueprintjs/select'
import CustomersFilter from './CustomersFilter'
import OutletSelector from '@components/Analytics/sharedComponents/OutletSelector'
import SignificantDates from './SignificantDates'
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params'

const Filters = ({ businessId }) => {
  const [
    {
      afterDate,
      beforeDate,
      displayBy = 'byValueOfOrders',
      showOutlets = false,
      showCustomers = false,
      showPaymentMethods = false,
      showFulfillmentMethods = false,
      showUserAgents = false,
      showBlankEntries = true,
    },
    setQueryParams,
  ] = useQueryParams({
    afterDate: StringParam,
    beforeDate: StringParam,
    displayBy: StringParam,
    showOutlets: BooleanParam,
    showCustomers: BooleanParam,
    showPaymentMethods: BooleanParam,
    showFulfillmentMethods: BooleanParam,
    showUserAgents: BooleanParam,
    showBlankEntries: BooleanParam,
  })
  const { urlDateFormat, initialAfterDateMoment, initialBeforeDateMoment } =
    useContext(BusinessAnalyticsContext)
  const minimal = true

  const itemRenderer = (option, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null
    }

    return (
      <MenuItem
        key={option}
        onClick={handleClick}
        multiline={true}
        text={MapDisplayBy[option]}
      />
    )
  }

  return (
    <FilterRow>
      <div>
        <Button icon="chart" disabled minimal />
        <Select
          filterable={false}
          items={Object.keys(MapDisplayBy)}
          itemRenderer={itemRenderer}
          onItemSelect={item => setQueryParams({ displayBy: item })}
        >
          <Button
            text={MapDisplayBy[displayBy]}
            icon="small-tick"
            minimal={minimal}
          />
        </Select>
        <Divider />

        <DateSelect
          minimal={minimal}
          afterDate={
            afterDate
              ? moment(afterDate, urlDateFormat)
              : initialAfterDateMoment
          }
          beforeDate={
            beforeDate
              ? moment(beforeDate, urlDateFormat)
              : initialBeforeDateMoment
          }
          onDateChange={({ afterDate, beforeDate }) => {
            setQueryParams({ afterDate: afterDate.format(urlDateFormat) })
            setQueryParams({ beforeDate: beforeDate.format(urlDateFormat) })
          }}
        />
        <Divider />
        <ButtonGroup>
          <Popover
            position={Position.BOTTOM_LEFT}
            boundary="window"
            defaultIsOpen={false}
          >
            <Button
              text="Series"
              rightIcon={'double-caret-vertical'}
              minimal={minimal}
              icon="series-add"
            />
            <PerfectScrollBar>
              <div
                style={{
                  padding: '12px',
                }}
              >
                <Checkbox
                  checked={showOutlets}
                  onChange={() => setQueryParams({ showOutlets: !showOutlets })}
                  label="Outlets Number"
                />
                <Checkbox
                  checked={showCustomers}
                  onChange={() =>
                    setQueryParams({ showCustomers: !showCustomers })
                  }
                  label="Customers Number"
                />
                <Checkbox
                  checked={showPaymentMethods}
                  onChange={() =>
                    setQueryParams({ showPaymentMethods: !showPaymentMethods })
                  }
                  label="Payment Methods"
                />
                <Checkbox
                  checked={showFulfillmentMethods}
                  onChange={() =>
                    setQueryParams({
                      showFulfillmentMethods: !showFulfillmentMethods,
                    })
                  }
                  label="Fulfilment Methods"
                />
                <Checkbox
                  checked={showUserAgents}
                  onChange={() =>
                    setQueryParams({ showUserAgents: !showUserAgents })
                  }
                  label="User Agents"
                />
              </div>
            </PerfectScrollBar>
          </Popover>
        </ButtonGroup>

        <Divider />
        <OutletSelector minimal={minimal} />
        <Divider />
        <CustomersFilter minimal={minimal} />
        <Divider />
        <SignificantDates businessId={businessId} minimal={minimal} />
        <Divider />
        <ButtonGroup>
          <Popover
            position={Position.BOTTOM_LEFT}
            boundary="window"
            defaultIsOpen={false}
          >
            <Button rightIcon="caret-down" minimal icon="more" />
            <PerfectScrollBar>
              <div
                style={{
                  padding: '12px',
                }}
              >
                <Checkbox
                  checked={showBlankEntries}
                  onChange={() =>
                    setQueryParams({ showBlankEntries: !showBlankEntries })
                  }
                  label="Display Intervals with no Orders"
                />
              </div>
            </PerfectScrollBar>
          </Popover>
        </ButtonGroup>
      </div>
    </FilterRow>
  )
}

export default Filters

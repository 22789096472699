import React from 'react'
import injectSheet from 'react-jss'
import { string, bool, object, number, shape } from 'prop-types'
import moment from 'moment'
import styles from './OrderPrintout.styles'
import RestaurantInfo from './sections/RestaurantInfo'
import Fulfilment from './sections/Fulfilment'
import CustomerInfo from './sections/CustomerInfo'
import DeliveryNotes from './sections/DeliveryNotes'
import OrderItems from './sections/OrderItems'
import Totals from './sections/Totals'
import OrderNotes from './sections/OrderNotes'
import PaidStatus from './sections/PaidStatus'
import OrderInfo from './sections/OrderInfo'
import AgeRestriction from './sections/AgeRestriction'

const formatDateString = value => moment(value).format('HH:mm D-MMM')

const OrderPrintout = ({ classes, order }) => (
  <main className={classes.container}>
    <RestaurantInfo classes={classes} order={order} />

    <Fulfilment
      classes={classes}
      order={order}
      formatDateString={formatDateString}
    />

    <CustomerInfo classes={classes} order={order} />

    {order.orderItems.some(item => !!item.menuItem.ageRestricted) && (
      <AgeRestriction classes={classes} order={order} />
    )}

    <DeliveryNotes classes={classes} order={order} />

    <OrderItems classes={classes} order={order} />

    <Totals classes={classes} order={order} />

    <OrderNotes classes={classes} order={order} />

    <PaidStatus classes={classes} order={order} />

    <OrderInfo
      classes={classes}
      order={order}
      formatDateString={formatDateString}
    />
  </main>
)

OrderPrintout.propTypes = {
  classes: object,
  order: shape({
    id: string,
    orderStatus: string,
    orderNumber: string,
    asap: bool,
    paid: bool,
    grossTotal: number,
    estimatedCompletionTime: string,
    estimatedDeliveryDate: string,
    fulfillmentMethod: string,
    paymentMethod: string,
    createdAt: string,
    acceptedAt: string,
    updatedAt: string,
    isAddOnItem: bool,
    outlet: shape({
      name: string,
      restaurant: shape({
        name,
      }),
      outletPhone: string,
    }),
    customer: {
      phoneNumber: string,
      firstName: string,
      lastName: string,
    },
  }).isRequired,
}

export default injectSheet(styles)(OrderPrintout)

export const styles = {
  container: {
    marginBottom: 20,
    padding: '0px 0px',
  },
  restaurantName: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  customerName: {
    fontWeight: 'bold',
  },
}

import defaultErrorHandler from '@utils/defaultErrorHandler'
import { errorToast, successToast } from '@utils/toast'
import { Formik } from 'formik'
import { split } from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { Mutation } from 'react-apollo'
import SEND_MESSAGE_TO_OUTLETS from './mutations/sendMessageToOutlets.mutation'
import SendEmailToOutletsForm from './SendEmailToOutletsForm'
import newMessageValidation from './validation'

const SendNewMessageToOutlets = ({
  messageId = undefined,
  setShowNewMessageDrawer,
}) => {
  const [selectedMarketplace, setSelectedMarketplace] = useState(null)
  const marketplaceId = split(location.pathname, '/')[3]
  useEffect(() => {
    setSelectedMarketplace(marketplaceId)
  }, [marketplaceId])

  return (
    <Mutation
      mutation={SEND_MESSAGE_TO_OUTLETS}
      onError={defaultErrorHandler}
      onCompleted={({ sendMessageToOutlets }) => {
        if (sendMessageToOutlets.message) {
          successToast(sendMessageToOutlets.message)
          setShowNewMessageDrawer(false)
        }
        sendMessageToOutlets.error
          ? errorToast(sendMessageToOutlets.error)
          : null
      }}
    >
      {sendMessageToOutlets => {
        return (
          <Fragment>
            {selectedMarketplace && (
              <Formik
                initialValues={{
                  content: '',
                  subject: '',
                  marketplaceId: selectedMarketplace,
                  restaurantIds: [],
                  addMarketplaceTheme: false,
                }}
                onSubmit={values =>
                  sendMessageToOutlets({
                    variables: {
                      content: values.content,
                      subject: values.subject,
                      marketplaceId: selectedMarketplace,
                      restaurantIds: values.restaurantIds,
                      messageId: messageId,
                      addMarketplaceTheme: values.addMarketplaceTheme,
                    },
                  })
                }
                validationSchema={newMessageValidation}
              >
                {props => <SendEmailToOutletsForm {...props} />}
              </Formik>
            )}
          </Fragment>
        )
      }}
    </Mutation>
  )
}

export default SendNewMessageToOutlets

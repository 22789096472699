import {
  Button,
  Callout,
  Drawer,
  DrawerSize,
  Intent,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import Query from '@components/Query/Query'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import React, { Fragment } from 'react'
import DELETE_DISCOUNT_WITH_VOUCHERS from './mutations/deleteDiscountWithVouchers.mutation'
import DISCOUNT_VOUCHERS_BY_MARKETPLACE from './queries/discountVouchersByMarketplace.query'
import GET_DISCOUNT_WITH_VOUCHERS from './queries/discountWithVouchers.query'
import { Mutation } from 'react-apollo'
import EDIT_DISCOUNT_VOUCHERS from './mutations/editVouchers.mutation'
import { discountValidation } from '@components/Discount/validation/validation'
import { numberToPence, penceToPounds } from '@utils/helpers'
import { Formik } from 'formik'
import CreateDiscountInnerFormStruct from '@components/Discount/modals/CreateDiscountInnerFormStruct'
import { first, flatMap } from 'lodash'
import SingleVoucher from './SingleVoucherDetails'
import MultipleVouchers from './MultipleVouchersDetails'
import ORDERS_BY_MARKETPLACE_AND_DISCOUNT_WITH_VOUCHER from './queries/ordersByMarketplaceAndDiscount.query'
import { transformDiscountsRawData } from '@components/Analytics/util/transformDiscountsRawData'
import DiscountAnalytics from '@components/Analytics/DiscountAnalytics'
import NonIdealStateSmaller from '@components/Analytics/NonIdealStateSmaller'
import { canPerformAction } from '@stores/userStore'

const DiscountCodesDetailsDrawer = ({
  isOpen,
  discountId,
  marketplaceId,
  drawerClose,
  refetchVars,
  enableAnalytics = false,
}) => (
  <Drawer
    isOpen={isOpen}
    onClose={drawerClose}
    title="Discount Code Details"
    size={DrawerSize.LARGE}
  >
    <div className="bp3-drawer-form">
      <Query
        query={GET_DISCOUNT_WITH_VOUCHERS}
        variables={{ discountId }}
        loaderTitle="Loading Discount"
      >
        {({ getDiscount: { discount } }) => {
          const { vouchers, allowedOutlets } = discount
          const discountVoucherIsAlreadyUsed = vouchers.some(
            ({ customerIds }) => customerIds.length
          )

          return (
            <Fragment>
              <Mutation
                mutation={EDIT_DISCOUNT_VOUCHERS}
                onCompleted={() => {
                  successToast('Successfully updated')
                  drawerClose()
                }}
                onError={defaultErrorHandler}
                refetchQueries={[
                  {
                    query: DISCOUNT_VOUCHERS_BY_MARKETPLACE,
                    variables: { ...refetchVars },
                  },
                ]}
              >
                {editDiscount => {
                  const initialValues = {
                    ...discount,
                    allowedOutletIds: allowedOutlets.map(({ id }) => id),
                    outletSelected: allowedOutlets.length > 0,
                    discountAmount: penceToPounds(discount.discountAmount),
                    minimumSubtotalGross: penceToPounds(
                      discount.minimumSubtotalGross
                    ),
                  }

                  return (
                    <Formik
                      validationSchema={discountValidation}
                      validateOnChange={true}
                      onSubmit={values => {
                        editDiscount({
                          variables: {
                            ...values,
                            allowedOutletIds: values.outletSelected
                              ? values.allowedOutletIds
                              : [],
                            discountAmount: numberToPence(
                              values.discountAmount
                            ),
                            minimumSubtotalGross: numberToPence(
                              values.minimumSubtotalGross
                            ),
                          },
                        })
                      }}
                      initialValues={initialValues}
                    >
                      {({
                        errors,
                        handleChange,
                        values,
                        handleSubmit,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className={'bp3-drawer-content'}>
                            {enableAnalytics && (
                              <Query
                                query={
                                  ORDERS_BY_MARKETPLACE_AND_DISCOUNT_WITH_VOUCHER
                                }
                                variables={{
                                  marketplaceId,
                                  discountId,
                                }}
                                loaderTitle="Loading Analytics"
                              >
                                {({
                                  marketplaceOrdersWithVouchers: data,
                                  loading,
                                  error,
                                }) => {
                                  if (loading && !data) {
                                    return <Spinner />
                                  }

                                  if (error) {
                                    return (
                                      <NonIdealState
                                        icon="error"
                                        title="Unable to load analytics data"
                                      />
                                    )
                                  }

                                  if (!data.length)
                                    return <NonIdealStateSmaller />

                                  const transformedData =
                                    transformDiscountsRawData(data)

                                  return (
                                    <DiscountAnalytics
                                      {...transformedData}
                                      showFilterRow={false} // disable temporary, might get outlets from RED-4495
                                    />
                                  )
                                }}
                              </Query>
                            )}

                            {discountVoucherIsAlreadyUsed ? (
                              <Callout
                                intent={Intent.WARNING}
                                style={{ margin: '0 0 20px' }}
                              >
                                {
                                  'This discount has been used at least once and, as a result, cannot be modified. You can still activate or deactivate it.'
                                }
                              </Callout>
                            ) : null}

                            <VouchersDetails
                              vouchers={vouchers}
                              discountName={discount.name}
                            />

                            <CreateDiscountInnerFormStruct
                              errors={errors}
                              values={values}
                              isDisabled={discountVoucherIsAlreadyUsed}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              marketplaceId={marketplaceId}
                            />
                          </div>
                          <div className="bp3-drawer-footer-actions">
                            <Mutation
                              mutation={DELETE_DISCOUNT_WITH_VOUCHERS}
                              onCompleted={() => {
                                successToast(
                                  'Successfully removed the discount'
                                )
                                drawerClose(null)
                              }}
                              onError={err => {
                                defaultErrorHandler(err)
                                drawerClose(null)
                              }}
                              refetchQueries={[
                                {
                                  query: DISCOUNT_VOUCHERS_BY_MARKETPLACE,
                                  variables: { ...refetchVars },
                                },
                              ]}
                            >
                              {deleteDiscount =>
                                !discountVoucherIsAlreadyUsed &&
                                canPerformAction('deleteDiscount') && (
                                  <ConfirmationPopover
                                    remove={() => {
                                      deleteDiscount({
                                        variables: {
                                          discountId,
                                        },
                                      })
                                    }}
                                  >
                                    <Button
                                      text="Delete"
                                      type="button"
                                      intent={Intent.DANGER}
                                      minimal
                                    />
                                  </ConfirmationPopover>
                                )
                              }
                            </Mutation>
                            {!discountVoucherIsAlreadyUsed && (
                              <Button
                                text="Update"
                                intent={Intent.NONE}
                                type="submit"
                              />
                            )}
                          </div>
                        </form>
                      )}
                    </Formik>
                  )
                }}
              </Mutation>
            </Fragment>
          )
        }}
      </Query>
    </div>
  </Drawer>
)

const VouchersDetails = ({ vouchers, discountName }) => {
  const orderIds = flatMap(vouchers, ({ orderIds }) => orderIds)

  return vouchers.length > 1 ? (
    <MultipleVouchers
      vouchers={vouchers}
      discountName={discountName}
      orderIds={orderIds}
    />
  ) : (
    <SingleVoucher
      orderIds={orderIds}
      voucher={first(vouchers)}
      discountName={discountName}
    />
  )
}

export default DiscountCodesDetailsDrawer

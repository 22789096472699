import colors from './colors'
import darkColors from './darkColors'

const styles = {
  '@global': {
    '.pageContainer': {
      width: '100%',
      height: '100%',
      position: 'fixed',
      background: colors.mainBg,
    },
    '.mainBodyContainer': {
      position: 'relative',
      marginLeft: '48px',
      padding: '24px',
      top: 96,
      '@media (max-width: 720px)': {
        padding: 12,
      },
    },
    body: {
      backgroundColor: '#FCFCFC',
    },
    table: {
      width: '100%',
    },
    'tfoot td': {
      boxShadow: 'inset 0 1px 0 0 rgba(17, 20, 24, 0.15)',
    },
    form: {
      marginBottom: 0,
    },
    main: {
      '& .bp3-tab-list': {
        '@media (max-width: 720px)': {
          width: 'calc(100vw - 100px)',
          overflowX: 'scroll',
        },
      },
    },
    hr: {
      borderBottom: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      marginTop: '1em',
      marginBottom: '0.5em',
      marginLeft: 'auto',
      marginRight: 'auto',
      borderTop: '1px solid #d8d9da',
    },
    'p.dashboard-data-value-medium': {
      fontSize: 24,
      fontWeight: 600,
      color: '#1968f6',
    },
    'p.dashboard-data-value-large': {
      fontSize: 32,
      fontWeight: 600,
      color: '#1968f6',
    },
    'p.dashboard-data-value': {
      fontSize: 26,
      fontWeight: 600,
      color: '#1968f6',
    },
    'p.dashboard-data-value-small': {
      fontSize: 20,
      fontWeight: 600,
      marginBottom: 4,
      color: '#1968f6',
    },
    'p.dashboard-data-label': {
      fontSize: 14,
      color: '#385770',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    'h1.bp3-heading': {
      '@media (max-width: 720px)': {
        fontSize: 24,
      },
    },
    'h6.bp3-heading': {
      color: '#2f3f4c',
    },
    '.bp3-non-ideal-state': {
      marginTop: '20%',
      marginBottom: '20%',
      paddingLeft: 24,
      paddingRight: 24,
      height: '20%',
    },
    '.bp3-dialog': {
      backgroundColor: '#FCFCFC',
    },
    '.bp3-control-group': {
      '& .bp3-form-group': {
        margin: 0,
      },
    },
    '.bp3-help': {
      color: '#147dbd',
      cursor: 'help',
    },
    '.bp3-no-break': {
      whiteSpace: 'nowrap',
    },
    '.bp3-nopad': {
      padding: '0 !important',
    },
    '.bp3-card': {
      padding: '20px',
      marginBottom: '24px',
      '@media (max-width: 720px)': {
        padding: '12px',
        marginBottom: '12px',
      },
    },
    '.statInfo': {
      fontSize: 15,
      fontWeight: 400,
      opacity: 0.3,
      padding: '16 0 0 5',
    },
    '.toast-progress': {
      marginTop: '5px',
      marginBottom: 0,
    },
    '.bp3-space-between': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.bp-card-footer-actions': {
      borderTop: '1px solid #d9dada',
      padding: '16 20 16 20',
      margin: '0 -20 -20 -20',
      backgroundColor: '#fbfbfb',
      borderRadius: '0 0 4px 4px',
      justifyContent: 'space-between',
      display: 'flex',
      '@media (max-width: 720px)': {
        margin: '0 -12 -12 -12',
        padding: '10 10 10 10',
      },
    },
    '.bp3-nopad .bp-card-footer-actions': {
      margin: 0 /* This will apply only when .bp-card-footer-actions is a child of .bp3-nopad */,
    },
    '.bp3-drawer-footer-actions': {
      borderTop: '1px solid #d9dada',
      padding: '20',
      display: 'flex',
      flexDirection: 'row',
      flexFlow: 'row wrap',
      justifyContent: 'space-between',
      bottom: 0,
      position: 'absolute',
      width: '100%',
      backgroundColor: '#fbfbfb',
      zIndex: 100,
      '@media (max-width: 720px)': {
        padding: '10 10 10 10',
      },
    },
    '.bp3-drawer-form': {
      overflowY: 'scroll',
      padding: 4,
      '& .bp3-drawer-content': {
        margin: 0,
        overflowY: 'scroll',
        padding: '16 16 80 16',
      },
    },
    '.bp3-drawer-table': {
      padding: 0,
      overflow: 'scroll',

      '& .bp3-drawer-content': {
        margin: 0,
        padding: '0 0 80 0',
        overflow: 'scroll',
        '& .bp3-html-table': {
          display: 'block',
          whiteSpace: 'nowrap',
        },
      },
    },
    '.bp-popover-footer-actions': {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '12px',
      '& button': {
        marginLeft: '12px',
      },
    },
    '.bp3-dialog-header': {
      background: '#26333c',
      '& .bp3-heading': {
        color: 'white',
      },
    },
    '.bp3-layout-vertical': {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      '& > div': {
        flex: '1 0 21%',
        marginBottom: '24px',
        '&:last-child': {
          marginBottom: '0px',
        },
      },
    },
    '.bp3-layout-row': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
    },
    '.bp3-layout-col': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
    },
    '.bp3-layout-col-side': {
      flex: '0 0 324px',
      marginLeft: '24px',
    },
    '.bp3-tree-node-content': {
      cursor: 'pointer',
    },
    '.bp3-dashboard': {
      display: 'flex',
      flexWrap: 'wrap',
      '& .bp3-card': {
        flex: '1 0 21%',
        marginRight: '24px',
        '&:last-child': {
          marginRight: '0px',
        },
      },
    },
    '.bp3-limit-form-width': {
      '& .bp3-form-group': {
        maxWidth: '500px',
      },
    },
    '.bp3-form-content .bp3-popover-target': {
      width: '100%',
    },
    'input[type=text].bp3-time': {
      width: '60px !important',
    },
    'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
      {
        ['-webkit-appearance']: 'none',
        margin: 0,
      },
    '.bp3-tab-indicator': {
      backgroundColor: `${colors.highlight} !important`,
    },
    '.bp3-html-table': {
      '& .bp3-switch': {
        marginBottom: 0,
        marginTop: '4px',
      },
      '& .bp3-control': {
        marginBottom: 0,
      },
    },
    '.bp3-scrollable': {
      overflow: 'scroll !important',
      paddingBottom: 24,
      '& table': {
        minWidth: 700,
      },
    },
    '.bp3-html-table > .bp3-control': {
      marginBottom: 0,
    },
    '.bp3-html-table td': {
      verticalAlign: 'middle !important',
      height: '38px',
      padding: '0 !important',
      paddingLeft: '11px !important',
      paddingRight: '11px !important',
    },
    '.bp3-text-small td': {
      height: '28px',
    },
    '.bp3-cell-nopad td': {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
    },
    '.bp3-dialog-table': {
      margin: '10px 0 15px',
    },
    '.bp3-table-frame': {
      background: '#F4F8FA',
      minWidth: 0,
      maxWidth: '100%',
      top: 96,
      bottom: 0,
      left: 46,
      right: 0,
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',

      '& .bp3-card': {
        minWidth: 'max-content',
      },

      '& .bp3-table-frame-nav': {
        padding: '8px 16px',
        background: '#eef4f7',
        borderBottom: '1px solid #CDD3D6',
        '& > *': {
          marginRight: 12,
          display: 'inline-flex',
        },
        '& .bp3-icon': {
          '& svg': {
            fill: '#6a7c88',
          },
        },
      },
    },
    '.bp3-minimal': {
      '& .bp3-input': {
        boxShadow: 'none',
        background: 'white',
        padding: '0 8 0 8',
        '&:focus': {
          boxShadow:
            'rgba(19, 124, 189, 0) 0px 0px 0px 0px, rgba(19, 124, 189, 0) 0px 0px 0px 0px, rgba(16, 22, 26, 0.15) 0px 0px 0px 1px inset, rgba(16, 22, 26, 0.2) 0px 1px 1px 0px inset',
        },
      },
    },

    '.bp3-link-text': {
      color: `${colors.globalLinkColor} !important`,

      '&:hover': {
        textDecoration: 'underline !important',
      },
    },
    '.bp3-popover-height': {
      maxHeight: '500px',
      overflowY: 'auto',
      padding: '16px 16px 6px 16px',
    },
    '.bp3-add-button': {
      padding: '30px 0px',
      color: `${colors.highlight} !important`,
      '& .bp3-icon': {
        color: colors.highlight,
      },
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    '.bp3-save-button': {
      background: `${colors.primaryButtonColor} !important`,
      color: `${colors.primaryButtonText} !important`,
      padding: '0px 10px',
      marginRight: 5,
    },
    'td.bp3-action-cell': {
      verticalAlign: 'middle !important',
      paddingRight: '0 !important',
    },
    'td > ul': {
      listStyleType: 'none',
      padding: '0 !important',
      margin: '0 !important',
    },
    '.bp3-button-link': {
      color: `${colors.globalLinkColor} !important`,

      '&:hover': {
        textDecoration: 'underline !important',
      },
    },
    '.bp3-card-non-ideal-state': {
      height: '50vh',
      margin: 10,
    },
    '.bp3-card-panel': {
      background: '#f6f6f7',
    },
    '.bp3-popover-select': {
      '& .bp3-menu': {
        maxHeight: 400,
        maxWidth: 500,
        overflow: 'auto',
      },
    },
    '.bp3-multi-select-popover': {
      '& .bp3-menu': {
        maxHeight: 400,
        maxWidth: 500,
        overflow: 'auto',
      },
    },
    header: {
      '& .bp3-navbar': {
        backgroundColor: `${colors.headerBgColor}`,
        '@media (max-width: 700px)': {
          '& .bp3-navbar-group': {
            overflow: 'scroll',
            padding: '0 1px',
          },
          '& .bp3-breadcrumbs': {
            flex: 'none',
          },
          '& .bp3-button': {
            '& .bp3-icon': { marginRight: 0 },
            '& .bp3-button-text': { display: 'none' },
          },
        },
      },
      '& .devBanner': {
        backgroundRepeat: 'repeat-x',
        backgroundSize: '100% 3px ',
        backgroundImage: `repeating-linear-gradient(315deg, #2f3f4c, #2f3f4c 8px, #f7a212 8px, #f7a212 16px )`,
      },
    },
    '.bp3-mute-toggle': {
      opacity: '0.6',
      padding: '0 10px 0 0',

      '&:hover': {
        opacity: '1',
      },
    },
    '.bp3-toast-container.bp3-toast-container-inline': {
      position: 'fixed',
    },
    '.bp3-col-divide': {
      '@media (min-width: 1200px)': {
        borderLeft: '1px solid #d9dada',
      },
    },

    // DARK MODE
    '.bp3-dark': {
      background: '#293742',
      '& .bp-card-footer-actions': {
        borderTop: '1px solid #222F38',
        background: '#394b59 !important',
      },
      '& .bp3-drawer-footer-actions': {
        borderTop: '1px solid #222F38',
      },
      '& .bp3-col-divide': {
        borderLeft: '1px solid #1f2a32',
      },
    },

    '.bp3-dark .bp3-minimal': {
      '& .bp3-input': {
        boxShadow: 'none',
        background: 'transparent !important',
        '&:focus': {
          boxShadow: 'none',
        },
      },
    },

    '.bp3-dark .bp3-drawer-footer-actions': {
      borderTop: '1px solid #222F38',
      backgroundColor: '#394b59 !important',
    },

    '.bp3-dark header': {
      '& .bp3-navbar': {
        backgroundColor: `${darkColors.headerBgColor}`,
      },
      '& .devBanner': {
        backgroundRepeat: 'repeat-x',
        backgroundSize: '100% 3px ',
        backgroundImage: `repeating-linear-gradient(315deg, #2f3f4c, #2f3f4c 8px, #f7a212 8px, #f7a212 16px )`,
      },
    },
    '.bp3-dark header .bp3-tabs': {
      background: '#27333E !important',
      borderBottom: '1px solid #222F38',
    },
    '.bp3-dark .bp3-table-frame': {
      background: '#2f3f4c',
    },
    '.bp3-dark-link-text': {
      color: `#48aff0 !important`,

      '&:hover': {
        textDecoration: 'underline !important',
      },
    },
  },
}
export default styles

import React from 'react'
import { Mutation } from 'react-apollo'
import { Formik, FieldArray } from 'formik'
import { object } from 'prop-types'
import { HTMLTable, Button, Intent, Classes, Card } from '@blueprintjs/core'
import get from 'lodash/get'
import size from 'lodash/size'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import { validation } from '@components/Restaurant/Menu/validation/optionFormValidation'
import { successToast } from '@utils/toast'
import { numberToPence } from '@utils/helpers'
import ADD_OPTION from '@components/Restaurant/Menu/mutations/addOption.mutation'
import ADD_OPTION_ITEM from '@components/Restaurant/Menu/mutations/addOptionItem.mutation'
import GET_OPTIONS from '@components/Restaurant/Menu/queries/getOptions.query'
import OptionForm from './OptionForm'
import AddOptionItems from './AddOptionItems'
import GET_RESTAURANT_VAT_STATUS from '@components/Restaurant/Menu/queries/getRestaurantVatStatus.query'
import Query from '@components/Query/Query'

const BLANK_OPTION = {
  name: '',
  minOptions: 0,
  maxOptions: 1,
  optionItems: [
    {
      name: '',
      price: '',
      vatRate: 0,
      position: 1,
    },
  ],
  required: 'optional',
  newOptionItems: [],
}

const submit = async (values, addOption, addOptionItem, restaurantId) => {
  // creates new option first
  const newOption = await addOption({
    variables: {
      ...values,
      restaurantId,
      optionItems: [],
    },
  })

  const parentId = get(newOption, 'data.addOption.option.id')
  if (parentId) {
    // once id is returned creates option items with that id as parent id
    values.optionItems.map(item =>
      addOptionItem({
        variables: {
          ...item,
          price: numberToPence(item.price),
          vatRate: parseFloat(item.vatRate),
          parentId,
          restaurantId,
        },
      })
    )
  }
}

const AddOptionForm = ({ classes, restaurantId, onClose }) => (
  <Query query={GET_RESTAURANT_VAT_STATUS} variables={{ id: restaurantId }}>
    {({ getRestaurants: { restaurants } }) => {
      const isVATregistered = get(restaurants, '[0].isVATregistered', false)
      return (
        <Mutation
          mutation={ADD_OPTION}
          onError={defaultErrorHandler}
          onCompleted={({ addOption }) => {
            successToast(addOption.message)
            onClose()
          }}
        >
          {addOption => (
            <Mutation
              mutation={ADD_OPTION_ITEM}
              onError={defaultErrorHandler}
              refetchQueries={[
                { query: GET_OPTIONS, variables: { restaurantId } },
              ]}
            >
              {addOptionItem => (
                <Formik
                  initialValues={BLANK_OPTION}
                  validationSchema={validation}
                >
                  {props => {
                    const {
                      values,
                      validateForm,
                      status,
                      setStatus,
                      setFieldValue,
                    } = props
                    return (
                      <form
                        onSubmit={event => {
                          event.preventDefault()
                          validateForm().then(errors => {
                            if (!size(errors)) {
                              submit(
                                values,
                                addOption,
                                addOptionItem,
                                restaurantId
                              )
                            }
                          })
                        }}
                      >
                        <OptionForm classes={classes} {...props} />
                        <Card className="bp3-nopad">
                          <HTMLTable
                            className="bp3-dialog-table"
                            interactive={true}
                          >
                            <thead>
                              <tr>
                                <th>Option Item</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              <FieldArray
                                name="optionItems"
                                render={({ remove }) => (
                                  <AddOptionItems
                                    items={props.values.optionItems}
                                    id={props.values.id}
                                    classes={classes}
                                    name={'optionItems'}
                                    remove={remove}
                                    {...props}
                                    isVATregistered={isVATregistered}
                                    status={status}
                                    setStatus={setStatus}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                  />
                                )}
                              />
                            </tbody>
                            <tfoot>
                              <FieldArray
                                name="optionItems"
                                render={({ push }) => (
                                  <Button
                                    text="Add Option Item"
                                    minimal={true}
                                    icon="plus"
                                    type="button"
                                    intent={'primary'}
                                    onClick={() =>
                                      push({
                                        name: '',
                                        price: '',
                                        vatRate: '',
                                        position: values.optionItems.length + 1,
                                      })
                                    }
                                    style={{ margin: '6px 0 10px 10px' }}
                                  />
                                )}
                              />
                            </tfoot>
                          </HTMLTable>
                        </Card>

                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                          <Button
                            text="Create"
                            intent={Intent.DEFAULT}
                            type="submit"
                          />
                        </div>
                      </form>
                    )
                  }}
                </Formik>
              )}
            </Mutation>
          )}
        </Mutation>
      )
    }}
  </Query>
)

AddOptionForm.propTypes = {
  classes: object,
}

export default AddOptionForm

import React from 'react'
import cx from 'classnames'
import get from 'lodash/get'
import moment from 'moment-timezone'

const Fulfilment = ({ classes, order, formatDateString }) => {
  let fulfilmentMethodString = order.fulfillmentMethod
  if (order.fulfillmentMethod === 'NETWORK') {
    fulfilmentMethodString = 'DELIVERY'
  } else if (order.fulfillmentMethod === 'TABLE') {
    fulfilmentMethodString = `TABLE ${get(
      order,
      'tableSnapshot.friendlyName',
      'unknown'
    ).replace(/^table\s*/i, '')}`
  }
  return (
    <section className={cx(classes.section, classes.center)}>
      <h2 className={classes.title}>{fulfilmentMethodString}</h2>

      <div>
        {order.fulfillmentMethod === 'NETWORK' && (
          <p className={classes.text}>
            Pickup: {formatDateString(order.estimatedCompletionTime)}
          </p>
        )}

        {order.fulfillmentMethod === 'TABLE' ? (
          <p className={classes.text}>
            Ordered: {formatDateString(order.createdAt)}
          </p>
        ) : (
          <p className={classes.text}>
            Due:{' '}
            {order.fulfillmentMethod === 'COLLECTION'
              ? `${moment(order.estimatedCompletionTime).format('HH:mm D-MMM')}`
              : `${moment(order.selectedDeliveryWindow.start).format(
                  `HH:mm ${
                    moment(order.selectedDeliveryWindow.start).day() !==
                    moment(order.selectedDeliveryWindow.end).day()
                      ? 'D-MMM'
                      : ''
                  }`
                )} - ${moment(order.selectedDeliveryWindow.end).format(
                  'HH:mm D-MMM'
                )}`}
            {order.asap ? ' (ASAP)' : ' (REQUESTED)'}
          </p>
        )}
      </div>
    </section>
  )
}

export default Fulfilment

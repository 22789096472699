import gql from 'graphql-tag'

const GET_CUSTOMERS = gql`
  query customers(
    $skip: Int
    $first: Int
    $last: Int
    $email: String
    $phoneNumber: String
    $postcodePartial: String
    $stripeCustomerId: String
    $marketplaceIds: [ID!]
    $orderBy: CustomerOrderByInput
  ) {
    customers(
      skip: $skip
      first: $first
      last: $last
      orderBy: $orderBy
      marketplaceIds: $marketplaceIds
      postcodePartial: $postcodePartial
      email: $email
      phoneNumber: $phoneNumber
      stripeCustomerId: $stripeCustomerId
    ) {
      count
      totalCount
      customers {
        id
        firstName
        lastName
        failedAttempts
        email
        createdAt
        updatedAt
        marketplace {
          id
          name
        }
        deliveryAddress {
          id
          city
          postcode
        }
        stripeCustomerId
        totalValue
        lastOrderAt
        totalCompletedOrders
        reviews {
          id
        }
      }
    }
  }
`

export default GET_CUSTOMERS

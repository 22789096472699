import { Callout } from '@blueprintjs/core'
import React, { Fragment } from 'react'
import injectStyle from 'react-jss'

const style = () => ({
  smaller: {
    height: 'auto !important',
    padding: '48px 24px',
  },
})

const NonIdealStateSmaller = () => (
  <Fragment>
    <Callout icon="outdated" title="Not Claimed">
      Because the discount has not yet been used, there is no data available for
      analysis.
    </Callout>
    <br />
  </Fragment>
)

export default injectStyle(style)(NonIdealStateSmaller)

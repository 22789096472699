import pick from 'lodash/pick'

export const orderList = (arr, indexA, direction) => {
  const arrLength = arr.length

  // reorder the items - faster to swap than splice - O(1) vs O(n)
  const _arr = [...arr]
  let indexB
  if (direction === 'up') {
    indexB =
      indexA === 0
        ? 0 // safety net
        : indexA - 1
  } else {
    indexB =
      indexA === arrLength - 1
        ? arrLength - 1 // safety net
        : indexA + 1
  }
  let temp = _arr[indexA]
  _arr[indexA] = _arr[indexB]
  _arr[indexB] = temp

  // keep only the required props
  return _arr.map((arrItem, index) => {
    arrItem['position'] = index
    return pick(arrItem, ['id', 'position'])
  })
}

export const styles = theme => ({
  container: {
    padding: '0',
    marginTop: '20px',
  },
  orderNumber: {
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemNameContainer: {
    display: 'flex',
  },
  quantityLabel: {
    marginRight: 16,
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.fontSize.small,
    color: theme.colors.orderHighlight,
  },
})

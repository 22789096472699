import React from 'react'
import { FormGroup, Intent } from '@blueprintjs/core'
import CurrencyInput from '../../CurrencyInput/CurrencyInput'
import { func, number, shape, string } from 'prop-types'
import { DeliveryZoneType } from '../../../../generated/graphql-types'

const CostInput = ({ values, setFieldValue, errors }) => (
  <FormGroup
    label={
      values.deliveryZone &&
      [
        DeliveryZoneType.Stuart,
        DeliveryZoneType.Waybox,
        DeliveryZoneType.UberDirect,
      ].includes(values.deliveryZone.deliveryZoneType)
        ? 'Fallback Delivery Cost'
        : 'Delivery Cost'
    }
    subLabel={
      values.deliveryZone &&
      [
        DeliveryZoneType.Stuart,
        DeliveryZoneType.Waybox,
        DeliveryZoneType.UberDirect,
      ].includes(values.deliveryZone.deliveryZoneType)
        ? 'Displayed to customer as an example.'
        : null
    }
    labelFor="cost"
    helperText={errors.cost}
    intent={errors.cost ? Intent.DANGER : Intent.NONE}
  >
    <CurrencyInput
      name="cost"
      type="number"
      defaultValue={values.cost}
      onUpdate={value => setFieldValue('cost', value)}
      full={true}
    />
  </FormGroup>
)
CostInput.propTypes = {
  values: shape({
    deliveryZone: shape({
      deliveryZoneType: string.isRequired,
    }),
    cost: number,
  }).isRequired,
  setFieldValue: func.isRequired,
}

export default CostInput

export const styles = {
  segmentConditionSelect: {
    borderRadius: ' 0 4px 4px 0',
    color: ({ dark }) => (dark ? '#6ec1ec' : '#106ba3'),
    fontWeight: 'bold',
    fontSize: 10,
    backgroundColor: ({ dark }) => (dark ? '#3f505e' : '#dbebf5'),
    margin: '0',
    display: 'inline-block',
    borderLeft: 'solid 3px #1968f6',
  },
  segmentConditionIndicator: {
    borderRadius: ' 0 4px 4px 0',
    color: ({ dark }) => (dark ? '#6ec1ec' : '#106ba3'),
    fontWeight: 'bold',
    fontSize: 14,
    backgroundColor: ({ dark }) => (dark ? '#3f505e' : '#dbebf5'),
    margin: '0',
    display: 'inline-block',
    padding: '6px 10px 6px 10px',
    borderLeft: 'solid 3px #1968f6',
  },
  segmentGroup: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 14,
    backgroundColor: '#1968f6',
    margin: '0',
    display: 'inline-block',
    padding: '6px 10px 6px 10px',
  },
}

import {
  Checkbox,
  Classes,
  Popover,
  PopoverInteractionKind,
  Position,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import React, { Fragment, useState } from 'react'
import { Mutation } from 'react-apollo'
import UPDATE_VOUCHERS_ACTIVE_STATE from './mutations/updateVouchersActiveState'

const OnePerCustomerAndActive = ({ active, onePerCustomer, voucherIds }) => {
  const [vouchersActive, setVouchersActive] = useState(active)

  return (
    <Fragment>
      <Mutation
        mutation={UPDATE_VOUCHERS_ACTIVE_STATE}
        onError={defaultErrorHandler}
        onCompleted={() => {
          successToast('Successfully updated the vouchers active state.')
          setVouchersActive(!vouchersActive)
        }}
      >
        {(updateVouchersActiveState, { loading }) => (
          <Checkbox
            name="active"
            label="Activate Discount"
            id="active"
            checked={vouchersActive}
            disabled={loading}
            onChange={() => {
              updateVouchersActiveState({
                variables: {
                  voucherIds,
                  active: !vouchersActive,
                },
              })
            }}
          />
        )}
      </Mutation>
      <Popover
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.TOP_LEFT}
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        content={
          <p>
            This setting cannot be changed. If you require vouchers with
            multiple usages per customer please create new ones.
          </p>
        }
      >
        <Checkbox
          name="onePerCustomer"
          label="Single Use Per Customer"
          id="onePerCustomer"
          checked={onePerCustomer}
          disabled
        />
      </Popover>
    </Fragment>
  )
}

export default OnePerCustomerAndActive

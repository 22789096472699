import React from 'react'
import { string, func, bool } from 'prop-types'

import { Query } from 'react-apollo'
import GET_MARKETPLACE_IDS from './queries/getMarketplaceIds.query.js'
import TypeaheadSingle from '../Typeahead/TypeaheadSingle'
import { Intent, Spinner } from '@blueprintjs/core'

const MarketplaceSelect = ({ marketplaceId, disabled, onChange }) => (
  <Query query={GET_MARKETPLACE_IDS}>
    {({ data, error, loading }) => (
      <TypeaheadSingle
        items={
          !loading &&
          !error &&
          data.getMarketplaces.regions.map(({ id, name, partner }) => ({
            id,
            name,
            label: partner.name,
          }))
        }
        intent={error ? Intent.DANGER : Intent.NONE}
        disabled={disabled || loading || error}
        placeholder={
          loading ? 'Loading Marketplaces...' : 'Select Marketplace...'
        }
        selected={!loading && marketplaceId}
        setSelected={value => onChange(value)}
        rightElement={loading ? <Spinner size={Spinner.SIZE_SMALL} /> : null}
      />
    )}
  </Query>
)

MarketplaceSelect.propTypes = {
  marketplaceId: string,
  disabled: bool,
  onChange: func,
}

export default MarketplaceSelect

import { Intent, Position, Toaster } from '@blueprintjs/core'
import config from '@config/config'
import ls from '@utils/localStorage'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import { withClientState } from 'apollo-link-state'
import gql from 'graphql-tag'
import merge from 'lodash/merge'
import fetch from 'unfetch'
import { logout } from '../stores/userStore'
import {
  discount,
  login,
  marketplace,
  menu,
  modals,
  orders,
  transactions,
  users,
} from './state'

const cache = new InMemoryCache()

const unsubscribe = async () => {
  await client.clearStore()
  clearState()
}

const clearState = () => stateLink.writeDefaults()

const httpLink = createHttpLink({
  uri: ({ operationName }) => {
    const url = `${config.apiUrl}/gql`
    if (process.env.NODE_ENV === 'development') {
      return `${url}?op=${operationName}`
    }
    return url
  },
  fetch,
})

const maxOneTopPositionToaster = Toaster.create({
  position: Position.TOP,
  maxToasts: 1,
})
const toastMaxOneToTopPosition = bread =>
  maxOneTopPositionToaster.show({
    icon: 'warning-sign',
    intent: Intent.DANGER,
    timeout: 5000,
    ...bread,
  })
const unlimitedTopPositionToaster = Toaster.create({
  position: Position.TOP,
})
const toastUnlimitedToTopPosition = bread =>
  unlimitedTopPositionToaster.show({
    icon: 'warning-sign',
    intent: Intent.DANGER,
    timeout: 5000,
    ...bread,
  })
const errorLink = onError(({ networkError, graphQLErrors = [] }) => {
  console.log({ networkError, graphQLErrors }, 'errors')
  if (networkError) {
    if (!networkError.statusCode) {
      toastMaxOneToTopPosition({
        message: 'Please verify your internet connection and try again.',
        action: {
          text: 'Reload',
          onClick: () => location.reload(),
        },
      })
    } else if (networkError.statusCode === 401) {
      logout()
      toastMaxOneToTopPosition({
        message: 'An authentication error occurred. Please log in again.',
      })
    } else if (
      networkError.statusCode >= 500 &&
      networkError.statusCode < 600
    ) {
      toastMaxOneToTopPosition({
        message: 'Service temporarily unavailable, please try again shortly.',
        action: {
          text: 'Reload',
          onClick: () => location.reload(),
        },
      })
    } else {
      toastMaxOneToTopPosition({
        message: 'An unexpected error occurred.',
        action: {
          text: 'Reload',
          onClick: () => location.reload(),
        },
      })
    }
  }
  if (graphQLErrors.length) {
    for (const graphQLError of graphQLErrors) {
      const message = graphQLError.message || 'An unexpected error occurred.'
      console.error(new Error(message), { graphQLError })
      if (graphQLError.code === 'SERVER_ERROR') {
        toastMaxOneToTopPosition({
          message,
        })
      } else {
        toastUnlimitedToTopPosition({
          message,
        })
      }
    }
  }
})

const stateLink = withClientState({
  cache,
  ...merge(
    modals,
    menu,
    login,
    users,
    marketplace,
    transactions,
    discount,
    orders
  ),
})

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    )
  }
  return forward(operation)
})

function omitTypename(key, value) {
  return key === '__typename' ? undefined : value
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = ls.get('jwt')
  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } else {
    // fall back to partial token for 2FA
    const partialToken = ls.get('partialJwt')
    if (partialToken) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${partialToken}`,
        },
      })
    }
  }

  return forward(operation)
})

const link = ApolloLink.from([
  omitTypenameLink,
  authMiddleware,
  stateLink,
  errorLink,
  httpLink,
])

const client = new ApolloClient({
  link,
  cache,
  name: 'management-web',
})
client.disableNetworkFetches = false
// the above enables fetchPolicy="network-only"
// this is undocumented in apollo and took some finding linking to the issue for future reference
// https://github.com/apollographql/react-apollo/issues/3084#issuecomment-501856583

export { client, gql, unsubscribe, clearState }

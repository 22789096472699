import React, { useRef } from 'react'
import PhoneInputLib from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import injectSheet from 'react-jss'
import { Icon } from '@blueprintjs/core'
import get from 'lodash/get'

const styles = theme => ({
  container: {
    position: 'relative',
  },
  inputClass: {
    '.bp3-dark &': {
      background: theme.darkColors.inputBg,
      color: '#FFF',
      border: 'none',
    },
    width: '100% !important',
    fontSize: '14px !important',
  },
  searchClass: {
    width: '100% !important',
    display: 'flex',
    alignItems: 'center',
    padding: '0px !important',
    fontSize: '14px !important',

    '.bp3-dark &': {
      background: theme.darkColors.headerBgColor,
      border: 'none',
    },

    '.bp3-dark & .search-box': {
      background: theme.darkColors.inputBg,
      border: 'none',
      color: '#FFF',
    },

    '& input': {
      flexGrow: 1,
      borderTopWidth: '0 !important',
      borderRightWidth: '0 !important',
      borderLeftWidth: '0 !important',
      borderBottomWidth: '1px !important',
      padding: '7px 8px 6px !important',
      lineHeight: '18px !important',
      borderRadius: '0 !important',
      margin: '0 !important',
    },
  },
  dropdownClass: {
    '.bp3-dark &': {
      background: theme.darkColors.headerBgColor,
      border: 'none',
    },
    '.bp3-dark & .country:hover,\
     .bp3-dark & .country.highlight': {
      background: theme.darkColors.menuItemColor,
      border: 'none',
    },
  },
  buttonClass: {
    '.bp3-dark &,\
     .bp3-dark & .selected-flag:hover,\
     .bp3-dark & .open,\
     .bp3-dark & .selected-flag:focus':
      {
        background: theme.darkColors.inputBg,
        border: 'none',
      },
    border: 'none',
    fontSize: '14px !important',
  },
  clearButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
})

const PhoneInputInner = ({ classes, onChange, ...rest }) => {
  const ref = useRef(null)

  const getCountryCode = () =>
    get(ref, 'current.state.selectedCountry.countryCode', '')
  const clear = () => {
    const countryCode = getCountryCode()
    onChange(countryCode)
  }

  return (
    <div className={classes.container}>
      <PhoneInputLib
        disableSearchIcon={true}
        country={'gb'}
        countryCodeEditable={false}
        enableSearch
        copyNumbersOnly={false}
        inputClass={classes.inputClass}
        searchClass={classes.searchClass}
        buttonClass={classes.buttonClass}
        dropdownClass={classes.dropdownClass}
        onChange={onChange}
        onKeyDown={e => {
          const countryCode = getCountryCode()
          const isAltBackspace = e.altKey === true && e.charCode === 0
          if (
            isAltBackspace &&
            countryCode &&
            `+${countryCode}` === e.target.value
          ) {
            e.preventDefault()
          }
        }}
        ref={ref}
        {...rest}
      />

      <button
        onClick={clear}
        className={'bp3-button ' + classes.clearButton}
        type="button"
      >
        <Icon icon="cross" />
      </button>
    </div>
  )
}

const PhoneInput = injectSheet(styles)(PhoneInputInner)

export default PhoneInput

import gql from 'graphql-tag'

const GET_VISITORS = gql`
  query getVisitors($outletId: ID!, $date: String!) {
    getVisitors(outletId: $outletId, date: $date) {
      visitors {
        id
        name
        phoneNumber
        createdAt
      }
      count
    }
  }
`

export default GET_VISITORS

import { Drawer, NonIdealState, Spinner } from '@blueprintjs/core'
import React, { Fragment } from 'react'
import { Query } from 'react-apollo'
import GET_MESSAGE_HISTORY from './queries/getMessageHistory.query'
import { SendMessageReply } from './SendMessageReply'

const EmailDialog = ({ selectedMessage, modalOpen, setModalOpen, isInbox }) => {
  return (
    <Query
      query={GET_MESSAGE_HISTORY}
      variables={{
        marketplaceId: selectedMessage.marketplace.id,
        messageSubject: selectedMessage.subject,
        emailsFrom:
          typeof selectedMessage.from === 'string'
            ? [selectedMessage.from]
            : selectedMessage.from,
        emailsTo:
          typeof selectedMessage.to === 'string'
            ? [selectedMessage.to]
            : selectedMessage.to,
      }}
    >
      {({ loading, data, error = null }) => {
        if (loading) {
          return <Spinner />
        }
        if (error) {
          return (
            <NonIdealState
              icon="list"
              title="MessageHistory"
              description="There was an error loading message history"
            />
          )
        }
        const {
          getMessageHistory: { messages },
        } = data

        return (
          <Fragment>
            {messages.length && (
              <Drawer
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                title="Reply to a message"
                className="replyMessageDrawer"
              >
                <SendMessageReply
                  messages={messages}
                  customersList={data.customers.customers}
                  isInbox={isInbox}
                />
              </Drawer>
            )}
          </Fragment>
        )
      }}
    </Query>
  )
}

export default EmailDialog

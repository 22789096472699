import React from 'react'
import { bool, object, string } from 'prop-types'
import cx from 'classnames'
import { toggleModal } from '@utils/cacheHelpers'
import get from 'lodash/get'
import { Icon, Popover, PopoverInteractionKind, Menu } from '@blueprintjs/core'
import Query from '@components/Query/Query'
import ItemsList from './ItemsList'
import GET_MENU_ITEMS from '@components/Restaurant/Menu/queries/getMenuItems.query'
import GET_OUTLET_MENU_ITEMS from '@components/Restaurant/Menu/queries/getOutletMenuItems.query'
import EDIT_MENU_ITEMS_ORDER from '@components/Restaurant/Menu/mutations/editMenuItemsOrder.mutation'
import MoveUpDown from '@components/MoveUpDown/MoveUpDown'
import { withRouter } from 'react-router'
import { removeExpiredSoldOut } from '@utils/removeExpiredSoldOut'

const formatMenus = ({
  menuItems,
  classes,
  itemId,
  menuId,
  outlet,
  outletId,
  restaurantId,
  setViewMenuItem,
  outletsSoldOut,
}) => {
  const menuNodes = menuItems.map((item, index, initialItems) => {
    const soldOutArray = removeExpiredSoldOut(outletsSoldOut)
    const soldOut = outlet ? item.outletSoldOut : soldOutArray.includes(item.id)
    const hidden = outlet ? item.outletHidden : false

    return {
      id: item.id,
      key: item.id,
      icon: <Icon icon={'dot'} color={soldOut ? 'red' : null} />,
      label: item.name,
      position: item.position,
      className: cx(classes.item, hidden ? classes.hidden : '', {
        [classes.selected]: item.id === itemId,
      }),
      onClick: () => {
        setViewMenuItem({ menuId: menuId, menuItemId: item.id })
      },
      secondaryLabel: outlet ? null : (
        <div
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <Popover
            content={
              <Menu>
                <MoveUpDown
                  mutationToExecute={EDIT_MENU_ITEMS_ORDER}
                  refetchQueries={[
                    {
                      query: outlet ? GET_OUTLET_MENU_ITEMS : GET_MENU_ITEMS,
                      variables: outlet
                        ? {
                            outletId,
                            menuItemGroupId: menuId,
                          }
                        : {
                            id: menuId,
                            restaurantId,
                          },
                    },
                  ]}
                  index={index}
                  initialList={initialItems}
                />
              </Menu>
            }
            interactionKind={PopoverInteractionKind.CLICK}
          >
            <Icon icon="more" />
          </Popover>
        </div>
      ),
    }
  })

  if (!outlet) {
    menuNodes.push({
      id: 'add',
      key: 'add',
      label: 'Add Item',
      icon: 'plus',
      className: classes.add,
      onClick: () => toggleModal({ itemModal: true }),
    })
  }
  return menuNodes
}

const MenuItems = ({
  outlet,
  match: {
    params: { restaurants: restaurantId, outlet: outletId },
  },
  itemId,
  classes,
  menuId,
  setViewMenuItem,
}) => {
  return (
    <Query
      query={outlet ? GET_OUTLET_MENU_ITEMS : GET_MENU_ITEMS}
      variables={
        outlet
          ? {
              outletId,
              menuItemGroupId: menuId,
            }
          : {
              id: menuId,
              restaurantId,
            }
      }
      fetchPolicy="network-only"
    >
      {data => {
        const menuItems = get(
          data,
          outlet
            ? 'getOutlets.outlets[0].menuItemsGroups[0].menuItems'
            : 'getMenuItemsGroups.menuItemsGroups[0].menuItems',
          []
        )
        const outletsSoldOut = get(
          data,
          outlet ? [] : 'getRestaurants.restaurants[0].outlets',
          []
        )

        const formattedMenuItems = formatMenus({
          menuItems,
          classes,
          itemId,
          menuId,
          outlet,
          outletId,
          restaurantId,
          setViewMenuItem,
          outletsSoldOut,
        })

        return <ItemsList menus={formattedMenuItems} classes={classes} />
      }}
    </Query>
  )
}

MenuItems.propTypes = {
  classes: object,
  itemId: string,
  menuId: string,
  outlet: bool,
}

export default withRouter(MenuItems)

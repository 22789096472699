import React from 'react'
import { array } from 'prop-types'
import { Classes, NavbarGroup } from '@blueprintjs/core'
import { returnCrumbArray } from '@stores/routerStore'
import RestaurantCrumbs from '@components/Header/Breadcrumbs/RestaurantCrumbs'
import PartnerCrumbs from '@components/Header/Breadcrumbs/PartnerCrumbs'
import MarketplaceCrumbs from '@components/Header/Breadcrumbs/MarketplaceCrumbs'
import OutletCrumbs from '@components/Header/Breadcrumbs/OutletCrumbs'
import TerminalCrumbs from '@components/Header/Breadcrumbs/TerminalCrumbs'
import OrderCrumbs from '@components/Header/Breadcrumbs/OrderCrumbs'
import CustomerCrumbs from '@components/Header/Breadcrumbs/CustomerCrumbs'
import MarketingCrumbs from '@components/Header/Breadcrumbs/MarketingCrumbs'
import Crumb from '@components/Header/Breadcrumbs/Crumb'
import DeliveryZonesCrumbs from '@components/Header/Breadcrumbs/DeliveryZonesCrumbs'
import { productType } from '@utils/types'
import { replace } from 'lodash'

const DEFAULT_BREADCRUMB = 'Redbox'

const shouldTruncate = () => {
  return returnCrumbArray().length >= 3
}

const isSecondPart = i => i === 1
const isThirdPart = i => i === 2
const excludeThirdPartIfSecond = ['inbox', 'sent']

const DefaultCrumbs = ({ crumbs }) =>
  crumbs.map((crumb, i) => {
    if (isSecondPart(i)) {
      return <Crumb key={crumb} text={crumb} link={`/${crumbs[0]}/${crumb}`} />
    }
    if (isThirdPart(i) && excludeThirdPartIfSecond.includes(crumbs[1])) {
      return
    } else if (isThirdPart(i)) {
      return (
        <Crumb
          key={crumb}
          text={replace(crumb, '-', ' ')}
          link={`/${crumbs[0]}/${crumbs[1]}/${crumb}`}
        />
      )
    }
    return <Crumb key={crumb} text={crumb} />
  })

const DynamicCrumbs = ({ crumbs, product = {} }) => {
  let Crumbs
  switch (product.name) {
    case 'restaurant':
      Crumbs = RestaurantCrumbs
      break
    case 'partner':
      Crumbs = PartnerCrumbs
      break
    case 'marketplace':
      Crumbs = MarketplaceCrumbs
      break
    case 'delivery-zones':
      Crumbs = DeliveryZonesCrumbs
      break
    case 'outlet':
      Crumbs = OutletCrumbs
      break
    case 'terminal':
      Crumbs = TerminalCrumbs
      break
    case 'order':
      Crumbs = OrderCrumbs
      break
    case 'customer':
      Crumbs = CustomerCrumbs
      break
    case 'marketing':
      Crumbs = MarketingCrumbs
      break
    default:
      Crumbs = DefaultCrumbs
  }

  return <Crumbs crumbs={crumbs} />
}

DynamicCrumbs.propTypes = {
  crumbs: array,
  product: productType,
}

const Breadcrumbs = ({ product }) => (
  <NavbarGroup>
    <ul className={Classes.BREADCRUMBS}>
      {/* Default Breadcrumb is always present */}
      <Crumb collapsed={shouldTruncate()} text={DEFAULT_BREADCRUMB} link="/" />
      <DynamicCrumbs
        product={product}
        crumbs={returnCrumbArray(product.crumbNo)}
      />
    </ul>
  </NavbarGroup>
)

Breadcrumbs.propTypes = {
  product: productType,
}

Breadcrumbs.defaultProps = {
  product: {},
}

export default Breadcrumbs

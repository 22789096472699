import { MenuDivider, MenuItem } from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import React, { Fragment } from 'react'
import { Mutation } from 'react-apollo'
import ADD_OPTION from '../Menu/mutations/addOption.mutation'
import GET_OPTIONS from '../Menu/queries/getOptions.query'

const DuplicateMenuOption = ({ restaurantId, option }) => {
  return (
    <Fragment>
      <Mutation
        mutation={ADD_OPTION}
        onError={defaultErrorHandler}
        onCompleted={({ addOption }) => successToast(addOption.message)}
        refetchQueries={[
          {
            query: GET_OPTIONS,
            variables: {
              restaurantId,
            },
          },
        ]}
      >
        {addOption => (
          <Fragment>
            <MenuDivider />
            <MenuItem
              text="Duplicate Option"
              icon="duplicate"
              onClick={() => {
                const { name, minOptions, maxOptions, optionItems } = option
                const newOptionItems = optionItems.map(optionItem => {
                  return {
                    name: optionItem.name,
                    price: optionItem.price,
                    soldOut: optionItem.soldOut || false,
                  }
                })
                const newOption = {
                  name,
                  minOptions,
                  maxOptions,
                  restaurantId,
                  optionItems: newOptionItems,
                }
                addOption({ variables: newOption })
              }}
            />
          </Fragment>
        )}
      </Mutation>
    </Fragment>
  )
}

export default DuplicateMenuOption

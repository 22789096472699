import SortBy from '@components/Toolbar/SortBy'
import React from 'react'

const cells = [
  {
    key: 'name',
    content: 'Outlet',
    sortable: true,
  },
  {
    key: 'marketplace',
    content: 'Marketplace',
  },
  {
    key: 'companyType',
    content: 'Entity',
  },
  {
    key: 'companyLegalName',
    content: 'Legal Company Name',
  },
  {
    key: 'companyNumber',
    content: 'Number',
  },

  {
    key: 'contactName',
    content: 'Contact',
  },
  { key: 'contactDoB', content: 'DOB' },
  {
    key: 'taxReference',
    content: 'TIN',
  },
  {
    key: 'vatRegistered',
    content: 'VAT',
  },
  {
    key: 'bankAccount',
    content: 'Bank Account',
  },
]

const TableHeader = ({ setSelected, selected }) => (
  <thead>
    <tr>
      {cells.map(({ key, width, sortable, content }) => (
        <th key={key} width={width}>
          {sortable ? (
            <SortBy
              title={content}
              orderBy={key}
              setSelected={setSelected}
              selected={selected}
            />
          ) : (
            content
          )}
        </th>
      ))}
    </tr>
  </thead>
)

export default TableHeader

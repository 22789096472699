import React, { Fragment } from 'react'
import { Button } from '@blueprintjs/core'
import { Formik } from 'formik'
import { Mutation } from 'react-apollo'
import { object, shape, string } from 'prop-types'
import { successToast } from '@utils/toast'
import { penceToPounds, numberToPence } from '@utils/helpers'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { toggleModal } from '@utils/cacheHelpers'
import { deliveryZoneType } from '@utils/constants'
import EDIT_OUTLET_DELIVERY_ZONE from './mutations/editOutletDeliveryZone.mutation'
import ARCHIVE_OUTLET_DELIVERY_ZONE from './mutations/archiveDeliveryZone.mutation'
import GET_DELIVERY_ZONES from '@components/Outlet/Fulfilment/queries/getDeliveryZones.query'
import ConfirmationPopover from '@components/ConfirmationPopover/ConfirmationPopover'
import { get } from 'lodash'
import CostInput from './Shared/CostInput'
import RadiusInput from './Shared/RadiusInput'
import SubsidyInput from './Shared/SubsidyInput'
import PickupNotesInput from './Shared/PickupNotesInput'
import { UndeliverableAction } from './Shared/UndeliverableAction'
import { outletDeliveryValidation } from './validation/outletDeliveryValidation'

const EditOutletDeliveryZoneForm = ({ activeOutletDeliveryZone, outletId }) => (
  <Mutation
    mutation={EDIT_OUTLET_DELIVERY_ZONE}
    onCompleted={() => {
      successToast('Successfully edited delivery zone')
      toggleModal({
        outletDeliveryZoneModal: false,
      })
    }}
    onError={error => {
      defaultErrorHandler(error)
    }}
    refetchQueries={[
      {
        query: GET_DELIVERY_ZONES,
        variables: { outletId },
      },
    ]}
  >
    {editOutletDeliveryZone => {
      const initialValues = {
        ...activeOutletDeliveryZone,
        fixedSubsidy: penceToPounds(
          get(activeOutletDeliveryZone, 'deliveryNetwork.fixedSubsidy') || 0
        ),
        radiusMiles: activeOutletDeliveryZone.radiusMiles || null,
        cost: penceToPounds(activeOutletDeliveryZone.deliveryCost),
        subsidiseDelivery:
          activeOutletDeliveryZone.deliveryZone.deliveryZoneType === 'STUART' &&
          activeOutletDeliveryZone.deliveryNetwork &&
          activeOutletDeliveryZone.deliveryNetwork.fixedSubsidy !== null,
      }
      return (
        <Formik
          initialValues={initialValues}
          validationSchema={outletDeliveryValidation}
          onSubmit={async values => {
            const deliveryCostNum = numberToPence(values.cost)
            const radiusMilesNum =
              values.radiusMiles === null ? null : Number(values.radiusMiles)

            // calculate fixed subsidy to either:
            // * undefined = no change
            // * null = remove subsidy
            // * number = set new subsidy value
            let fixedSubsidy
            if (!values.subsidiseDelivery && initialValues.subsidiseDelivery) {
              fixedSubsidy = null
            } else if (
              values.subsidiseDelivery &&
              values.fixedSubsidy !== initialValues.fixedSubsidy
            ) {
              fixedSubsidy = numberToPence(values.fixedSubsidy)
            }

            const variables = {
              id: values.id,
              ...(radiusMilesNum !== null &&
                radiusMilesNum !== initialValues.radiusMiles && {
                  radiusMiles: radiusMilesNum,
                }),
              ...(deliveryCostNum !== initialValues.cost && {
                cost: deliveryCostNum,
              }),
              ...(fixedSubsidy !== undefined && { fixedSubsidy }),
              ...(values.uberDirectUndeliverableAction !== undefined && {
                uberDirectUndeliverableAction:
                  values.uberDirectUndeliverableAction,
              }),
              ...(values.pickupNotes &&
                values.pickupNotes.length && {
                  pickupNotes: values.pickupNotes,
                }),
            }

            const result = await editOutletDeliveryZone({ variables })

            return result
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => {
            return (
              <form onSubmit={handleSubmit} className="bp3-drawer-form">
                <div className="bp3-drawer-content">
                  <CostInput
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                  />

                  {[
                    deliveryZoneType.RADIUS_AROUND_OUTLET,
                    deliveryZoneType.STUART,
                    deliveryZoneType.UBER_DIRECT,
                  ].includes(
                    activeOutletDeliveryZone.deliveryZone.deliveryZoneType
                  ) && (
                    <RadiusInput
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />
                  )}
                  {deliveryZoneType.STUART ===
                    activeOutletDeliveryZone.deliveryZone.deliveryZoneType && (
                    <Fragment>
                      <SubsidyInput
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                      <PickupNotesInput
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                      />
                    </Fragment>
                  )}
                  {deliveryZoneType.UBER_DIRECT ===
                    activeOutletDeliveryZone.deliveryZone.deliveryZoneType && (
                    <Fragment>
                      <PickupNotesInput
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                      />
                      <UndeliverableAction
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                    </Fragment>
                  )}
                </div>

                <div className="bp3-drawer-footer-actions">
                  <Mutation
                    mutation={ARCHIVE_OUTLET_DELIVERY_ZONE}
                    onCompleted={() => {
                      successToast('Delivery Zone removed')
                      toggleModal({
                        outletDeliveryZoneModal: false,
                      })
                    }}
                    onError={defaultErrorHandler}
                    refetchQueries={[
                      { query: GET_DELIVERY_ZONES, variables: { outletId } },
                    ]}
                  >
                    {archiveDeliveryZone => (
                      <ConfirmationPopover
                        remove={() =>
                          archiveDeliveryZone({
                            variables: { id: activeOutletDeliveryZone.id },
                          })
                        }
                        confirmationText="Are you sure you want to delete this delivery zone?"
                      >
                        <Button
                          text="Delete"
                          type="button"
                          intent="danger"
                          minimal={true}
                        />
                      </ConfirmationPopover>
                    )}
                  </Mutation>
                  <Button
                    text="Save"
                    type="submit"
                    onClick={e => {
                      e.preventDefault()
                      handleSubmit(e)
                    }}
                  />
                </div>
              </form>
            )
          }}
        </Formik>
      )
    }}
  </Mutation>
)

EditOutletDeliveryZoneForm.propTypes = {
  activeOutletDeliveryZone: shape({
    id: string.isRequired,
    radiusMiles: object,
    deliveryCost: object,
    deliveryZone: shape({
      id: string.isRequired,
      deliveryZoneType: string.isRequired,
    }).isRequired,
    deliveryNetwork: object,
  }).isRequired,
  outletId: string.isRequired,
}

export default EditOutletDeliveryZoneForm

import React from 'react'
import { MultiSelect } from '@blueprintjs/select'
import { find, indexOf } from 'lodash'
import { Intent, MenuItem } from '@blueprintjs/core'

const allWeekDays = [
  { position: 1, name: 'Mon' },
  { position: 2, name: 'Tues' },
  { position: 3, name: 'Wed' },
  { position: 4, name: 'Thu' },
  { position: 5, name: 'Fri' },
  { position: 6, name: 'Sat' },
  { position: 7, name: 'Sun' },
]

const renderItem = ({
  day,
  modifiers,
  handleClick,
  selectedDays,
  disabled,
}) => {
  const localIndex = indexOf(selectedDays, day.position)
  if (localIndex >= 0) {
    modifiers.disabled = true
  }

  return (
    <MenuItem
      active={modifiers.active}
      key={day.position}
      onClick={handleClick}
      text={day.name}
      icon={localIndex >= 0 ? 'tick' : 'blank'}
      disabled={disabled}
    />
  )
}

const WeekDaysSelect = ({ selectedDays, remove, push, disabled, intent }) => (
  <MultiSelect
    id="daysOfWeek"
    items={allWeekDays}
    className={
      intent && intent === Intent.DANGER && 'bp3-input-group bp3-intent-danger'
    }
    placeholder="Week days"
    selectedItems={selectedDays}
    itemRenderer={(day, { modifiers, handleClick }) => {
      return renderItem({ day, modifiers, handleClick, selectedDays, disabled })
    }}
    tagRenderer={day => {
      return find(allWeekDays, ['position', day]).name
    }}
    tagInputProps={{
      tagProps: { minimal: true, style: { opacity: disabled && 0.4 } },
      onRemove: (item, index) => !disabled && remove(index),
    }}
    onItemSelect={day => {
      const isSelectedAlready = selectedDays.includes(day.position)
      if (!disabled && isSelectedAlready) {
        remove(
          selectedDays.findIndex(selectedDay => selectedDay === day.position)
        )
      } else {
        !disabled && push(day.position)
      }
    }}
  />
)

export default WeekDaysSelect

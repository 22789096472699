import React, { useEffect, useContext } from 'react'
import { PageLayoutContext } from '@components/PageLayout/PageLayout'
import { Switch, Redirect, Route } from 'react-router-dom'

import Details from './Details'
import { matchType, locationType } from '@utils/types'

const Terminal = ({ match }) => {
  const { configurePageLayout } = useContext(PageLayoutContext)
  useEffect(() => {
    configurePageLayout({})
  }, [configurePageLayout])

  return (
    <section>
      <Switch>
        <Redirect
          from="/business/:restaurants/outlets/:outlet/terminals/:terminal"
          to="/business/:restaurants/outlets/:outlet/terminals/:terminal/details"
          exact
        />
        <Route
          path={`${match.path}/details`}
          exact={true}
          component={Details}
        />
      </Switch>
    </section>
  )
}

Terminal.propTypes = {
  match: matchType,
  location: locationType,
}

export default Terminal

import React from 'react'

import { matchType } from '@utils/types'
import ListVisitors from './ListVisitors'
import { isAtLeastOutletUser } from '@stores/userStore'
import { NoPermissions } from '@components/UI/Permission/Permission'

const Trace = ({ match }) =>
  isAtLeastOutletUser() ? (
    <ListVisitors outletId={match.params.outlet} />
  ) : (
    <NoPermissions />
  )

Trace.propTypes = {
  match: matchType,
}
export default Trace

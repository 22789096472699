import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import React from 'react'
import { useContext } from 'react'
import { Mutation } from 'react-apollo'
import { MembersDiscountContext } from './MembersDiscountProvider'
import ENROLL_MEMBER_TO_DISCOUNT from './mutations/enrollMemberToDiscount'
import PercentageInput from '@components/PercentageInput/PercentageInput'
import { memberDiscountValidation } from './validation/memberDiscountValidation'
import GET_MEMBER_DISCOUNTS from './queries/getMemberDiscounts.query'

const EnrollToDiscountForm = ({ restaurantId, refetchVars }) => {
  const {
    setModalInviteMemberToDiscountOpen: setModalInviteCustomerToDiscountOpen,
  } = useContext(MembersDiscountContext)
  return (
    <div className={Classes.DIALOG_BODY}>
      <Mutation
        mutation={ENROLL_MEMBER_TO_DISCOUNT}
        onCompleted={({ enrollMemberToDiscount }) => {
          successToast(enrollMemberToDiscount.message)
          setModalInviteCustomerToDiscountOpen(false)
        }}
        onError={error => {
          defaultErrorHandler(error)
          setModalInviteCustomerToDiscountOpen(false)
        }}
        refetchQueries={[
          {
            query: GET_MEMBER_DISCOUNTS,
            variables: { ...refetchVars },
          },
        ]}
      >
        {enrollMemberToDiscount => (
          <Formik
            validateOnChange={true}
            validationSchema={memberDiscountValidation}
            initialValues={{
              customerEmail: '',
              discountPercentage: 0,
              discountName: '',
              customerFallbackName: '',
            }}
            onSubmit={({
              customerEmail,
              discountPercentage,
              discountName,
              customerFallbackName,
            }) => {
              enrollMemberToDiscount({
                variables: {
                  restaurantId,
                  customerEmail,
                  discountPercentage,
                  discountName,
                  customerFallbackName,
                },
              })
            }}
          >
            {({
              errors,
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormGroup
                  label="Discount Name"
                  labelFor="discountName"
                  helperText={errors.discountName || ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    name="discountName"
                    id="discountName"
                    type="text"
                    placeholder="As it will appear on the receipt"
                    onChange={handleChange}
                    intent={errors.discountName ? Intent.DANGER : Intent.NONE}
                    value={values.discountName}
                  />
                </FormGroup>

                <FormGroup
                  label="Customer Email"
                  labelFor="customerEmail"
                  helperText={errors.customerEmail || ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    name="customerEmail"
                    id="customerEmail"
                    type="email"
                    onChange={handleChange}
                    intent={errors.customerEmail ? Intent.DANGER : Intent.NONE}
                    value={values.customerEmail}
                  />
                </FormGroup>

                <FormGroup
                  label="Customer Name"
                  labelFor="customerFallbackName"
                  helperText={errors.customerFallbackName || ''}
                  intent={Intent.DANGER}
                >
                  <InputGroup
                    name="customerFallbackName"
                    id="customerFallbackName"
                    type="text"
                    placeholder="As it will appear on the invite email if the customer is not registered"
                    onChange={handleChange}
                    intent={
                      errors.customerFallbackName ? Intent.DANGER : Intent.NONE
                    }
                    value={values.customerFallbackName}
                  />
                </FormGroup>

                <FormGroup
                  label="Discount Percentage"
                  labelFor="discountPercentage"
                  helperText={errors.discountPercentage || ''}
                  intent={Intent.DANGER}
                >
                  <PercentageInput
                    value={values.discountPercentage}
                    handleChange={values =>
                      setFieldValue('discountPercentage', values.floatValue)
                    }
                    fill
                    intent={
                      errors.discountPercentage ? Intent.DANGER : Intent.NONE
                    }
                  />
                </FormGroup>

                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button
                    text="Send Invite"
                    intent={Intent.PRIMARY}
                    type="submit"
                  />
                </div>
              </form>
            )}
          </Formik>
        )}
      </Mutation>
    </div>
  )
}

export default EnrollToDiscountForm

import React, { Fragment } from 'react'
import Query from '@components/Query/Query'
import { Query as CacheQuery } from 'react-apollo'
import { toggleModal } from '@utils/cacheHelpers'
import EnableOrderToTableForm from './EnableOrderToTableForm'
import TableList from './TableList'
import OPEN_ADD_TABLE_MODAL from '@components/Outlet/Tables/queries/openAddTableModal.clientQuery'
import GET_OUTLET_TABLES from '@components/Outlet/Tables/queries/getOutletTables.query'
import AddTableModal from '@components/TableModal/AddTableModal'
import { isAtLeastOutletUser } from '@stores/userStore'
import { NoPermissions } from '@components/UI/Permission/Permission'

const Tables = ({ match }) =>
  isAtLeastOutletUser() ? (
    <Query
      query={GET_OUTLET_TABLES}
      variables={{
        outletId: match.params.outlet,
      }}
      loaderTitle={'Loading Tables'}
    >
      {({ getOutlets: { outlets } }) => {
        const outlet = outlets[0]
        return (
          <CacheQuery query={OPEN_ADD_TABLE_MODAL}>
            {({ data: { addTableModal } }) => (
              <Fragment>
                <div className="bp3-layout-row">
                  <div className="bp3-layout-col">
                    <TableList
                      tables={outlet.tables}
                      outletId={outlet.id}
                    ></TableList>
                  </div>
                  <div className="bp3-layout-col-side">
                    <EnableOrderToTableForm outlet={outlet} />
                  </div>
                </div>

                <AddTableModal
                  modalOpen={addTableModal}
                  modalClose={() =>
                    toggleModal({
                      addTableModal: false,
                    })
                  }
                  outletId={outlet.id}
                />
              </Fragment>
            )}
          </CacheQuery>
        )
      }}
    </Query>
  ) : (
    <NoPermissions />
  )

export default Tables

import React, { Fragment } from 'react'
import injectSheet from 'react-jss'
import { string, object } from 'prop-types'

import { styles } from './Notes.styles'
import { Divider } from '@blueprintjs/core'
import { lowerCase, startCase } from 'lodash'

const Notes = ({
  classes,
  deliveryNotes,
  customerOrderNotes,
  customerDeliveryNotes,
  cancellationNotes,
  fulfillmentMethod,
}) => (
  <Fragment>
    <div className={classes.container}>
      <div className={classes.orderNotesContainer}>
        <p className={classes.noteTitle}>Order Notes</p>
        <div className={classes.note}>
          {customerOrderNotes || 'No Order Notes'}
        </div>
      </div>

      <div className={classes.deliveryNotesContainer}>
        <p className={classes.noteTitle}>
          {startCase(lowerCase(fulfillmentMethod))} Notes
        </p>
        <div className={classes.note}>
          {customerDeliveryNotes ||
            deliveryNotes ||
            `No ${startCase(lowerCase(fulfillmentMethod))} Notes`}
        </div>
      </div>
    </div>
    {cancellationNotes && (
      <Fragment>
        <Divider />
        <div className={classes.container}>
          <div className={classes.cancellationNotes}>
            <p className={classes.noteTitle}>Cancellation Notes</p>
            <div className={classes.note}>
              {cancellationNotes || 'No Cancellation Notes'}
            </div>
          </div>
        </div>
      </Fragment>
    )}
  </Fragment>
)

Notes.propTypes = {
  classes: object,
  customerOrderNotes: string,
  customerDeliveryNotes: string,
  cancellationNotes: string,
}

export default injectSheet(styles)(Notes)

import React, { Fragment } from 'react'
import { bool, func, string } from 'prop-types'
import {
  Classes,
  H5,
  Popover,
  PopoverInteractionKind,
  Position,
  Tag,
  Text,
} from '@blueprintjs/core'
import { Business } from './business.definitions'
import { Disputes } from './dispute.definitions'
import { OrderStatus } from './orderStatus.definitions'
import { PaymentStatus } from './paymentStatus.definitions'
import { PaymentRisk } from './paymentRisk.definitions'
import { PaymentNetwork } from './paymentNetwork.definitions'
import {
  ReconciliationResolutionType,
  ReconciliationType,
} from './reconciliation.definitions'

const Definitions = {
  ...Business,
  ...Disputes,
  ...OrderStatus,
  ...PaymentStatus,
  ...PaymentRisk,
  ...PaymentNetwork,
  ...ReconciliationType,
  ...ReconciliationResolutionType,
}

const Enum = ({
  tagName,
  content = undefined,
  icon = null,
  rightIcon = null,
  minimal = undefined,
  intent = null,
  position = Position.LEFT,
  title = null,
  details = null,
  actions = null,
  shortcut = null,
  large = false,
  onClick = undefined,
}) => {
  if (!tagName) return null
  tagName = tagName.toLowerCase()
  const definition = Definitions[tagName]

  if (!definition) return <Tag icon="error">{tagName}</Tag>

  // If minimal is not defined, default to false or the definition value
  if (minimal === undefined) {
    minimal = definition.minimal !== undefined ? definition.minimal : false
  }

  const tagElement = (
    <Tag
      onClick={onClick}
      interactive
      minimal={minimal} // Default to false
      intent={intent || definition.intent || 'none'}
      icon={icon || definition.icon || null}
      rightIcon={rightIcon || definition.rightIcon || null}
      large={large}
    >
      {definition.tag || tagName.toUpperCase().replace(/_/g, ' ')}
    </Tag>
  )

  // Conditionally wrap the Tag in a Popover if content is not null
  return content || definition ? (
    <Popover
      interactionKind={PopoverInteractionKind.HOVER}
      position={position}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      captureDismiss={true}
      content={
        <Fragment>
          <H5
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>{title || definition.title}</span>
            {shortcut}
          </H5>

          <Text>{details || definition.details}</Text>
          {actions && <br />}
          {actions}
        </Fragment>
      }
    >
      {tagElement}
    </Popover>
  ) : (
    tagElement
  )
}

Enum.propTypes = {
  tagName: string,
  content: string,
  icon: string,
  large: bool,
  rightIcon: Element || string,
  minimal: bool,
  intent: string,
  position: string,
  title: string,
  details: string,
  actions: Element,
  shortcut: Element,
  onClick: func,
}

export default Enum

import {
  add,
  endOfDay,
  format,
  min,
  startOfDay,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns'

/**
 * @typedef {keyof typeof DATE_FILTER_TYPES} DateFilterTypeKey
 * @typedef {typeof DATE_FILTER_TYPES[DateFilterTypeKey]} DateFilterType
 */
export const DATE_FILTER_TYPES = {
  DAY: { key: 'DAY', name: 'Day' },
  WEEK: { key: 'WEEK', name: 'Week' },
  MONTH: { key: 'MONTH', name: 'Month' },
  QUARTER: { key: 'QUARTER', name: 'Quarter' },
  CUSTOM: { key: 'CUSTOM', name: 'Custom' },
}
export const URL_DATE_FORMAT = 'yyyy-MM-dd'
export const YEARLESS_TIMELESS_FORMAT = 'EEE dd MMM'
export const YEARLESS_FORMAT = 'EEE dd MMM, HH:mm'
export const YEAR_FORMAT = 'EEE dd MMM yyyy, HH:mm'
export const ISO_FORMAT = `yyyy-MM-dd'T'HH:mm:ss'Z'`
export const ISO_WEEK_DAY = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

export const formatToDateOnly = date => format(date, URL_DATE_FORMAT)

export const startOfMostRecentMonday = (date = new Date()) =>
  startOfWeek(date, { weekStartsOn: ISO_WEEK_DAY.MONDAY })

export const getDefaultStartOfRangeDate = dateFilterTypeKey => {
  if (dateFilterTypeKey === DATE_FILTER_TYPES.WEEK.key) {
    return startOfMostRecentMonday()
  }
  if (dateFilterTypeKey === DATE_FILTER_TYPES.MONTH.key) {
    return startOfMonth(new Date())
  }
  return startOfDay(new Date())
}

export const calculateEndFromStartOfRangeDate = ({
  startOfRangeDate,
  dateFilterTypeKey,
  maxCustomRangeInterval = { months: 1 },
}) => {
  const end = endOfDay(
    min([
      // add the appropriate interval to the start date
      add(startOfRangeDate, {
        ...(dateFilterTypeKey === DATE_FILTER_TYPES.WEEK.key && {
          weeks: 1,
          days: -1,
        }),
        ...(dateFilterTypeKey === DATE_FILTER_TYPES.MONTH.key && {
          months: 1,
          days: -1,
        }),
        ...(dateFilterTypeKey === DATE_FILTER_TYPES.QUARTER.key && {
          months: 3,
          days: -1,
        }),
      }),
      // limit to today
      new Date(),
      // if custom range, limit to max custom range interval too
      ...(dateFilterTypeKey === DATE_FILTER_TYPES.CUSTOM.key
        ? [sub(add(startOfRangeDate, maxCustomRangeInterval), { days: 1 })]
        : []),
    ])
  )
  return end
}

import React, { Fragment } from 'react'
import first from 'lodash/first'
import { string } from 'prop-types'

import Query from '@components/Query/Query'
import { matchType } from '@utils/types'

import GET_PARTNER_PAYMENTS from './queries/getPartner.query'
import EDIT_PARTNER_PAYMENTS from './mutations/editPartner.mutation'

import {
  Button,
  Card,
  Checkbox,
  FormGroup,
  H5,
  H6,
  HTMLSelect,
  HTMLTable,
  Icon,
  InputGroup,
  NonIdealState,
  Tag,
} from '@blueprintjs/core'

import { Row, Col } from 'react-simple-flex-grid'
import { Mutation } from 'react-apollo'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { successToast } from '@utils/toast'
import { Formik } from 'formik'
import { validation } from './validation'
import CopyText from '@components/CopyText/CopyText'
import ConfirmationPopover from '../../ConfirmationPopover/ConfirmationPopover'
import RESET_PARTNER_WEBHOOKS from './mutations/resetWebhooks.mutation'
import DELETE_PARTNER_WEBHOOKS from './mutations/deleteWebhooks.mutation'
import StripeConnectForm from '../Financials/PartnerForm/StripeConnectForm'
import { NoPermissions } from '@components/UI/Permission/Permission'
import { isSuperUser } from '@stores/userStore'
import { WEBHOOK_STATUS_TO_INTENT } from '../../../utils/stripe'

const Payments = ({ match }) => {
  const partnerId = match.params.partner

  return (
    <Query
      query={GET_PARTNER_PAYMENTS}
      variables={{ id: partnerId }}
      loaderTitle={'Loading Payment Providers'}
    >
      {({ getPartners: { partners } }) => {
        let partner = first(partners)
        if (!partner) {
          return 'Unable to find partner.'
        }

        if (!(isSuperUser() || partner.stripeEnterpriseAllowed)) {
          return <NoPermissions />
        }

        return (
          <Fragment>
            <StripeConnectForm partner={partner} />
            <Card>
              <H5>Advanced</H5>

              <Mutation
                mutation={EDIT_PARTNER_PAYMENTS}
                onError={defaultErrorHandler}
                onCompleted={({ editPartnerPayments }) => {
                  successToast(editPartnerPayments.message)
                }}
                refetchQueries={[
                  {
                    query: GET_PARTNER_PAYMENTS,
                    variables: { id: partner.id },
                  },
                ]}
              >
                {editPartnerPayments => {
                  return (
                    <Formik
                      validationSchema={validation}
                      enableReinitialize={true}
                      initialValues={{
                        ...partner,
                      }}
                      onSubmit={values =>
                        editPartnerPayments({
                          variables: {
                            ...values,
                          },
                        })
                      }
                    >
                      {props => {
                        const {
                          values,
                          handleSubmit,
                          handleChange,
                          isSubmitting,
                        } = props
                        return (
                          <form onSubmit={handleSubmit}>
                            <Row gutter={24}>
                              <Col xs={12} md={6}>
                                <H6>Stripe API</H6>
                                {partner.stripeEnterpriseId && (
                                  <FormGroup
                                    label="Account ID"
                                    labelFor="stripeEnterpriseId"
                                  >
                                    <CopyText
                                      text={partner.stripeEnterpriseId}
                                      mono
                                      leftIcon={
                                        <Icon
                                          icon={
                                            partner.stripeEnterpriseId &&
                                            partner.stripeEnterpriseAccessId
                                              ? 'tick-circle'
                                              : 'warning-sign'
                                          }
                                          intent={
                                            partner.stripeEnterpriseId &&
                                            partner.stripeEnterpriseAccessId
                                              ? 'success'
                                              : 'warning'
                                          }
                                        />
                                      }
                                    />
                                  </FormGroup>
                                )}
                                <FormGroup
                                  label="Public Key"
                                  labelFor="stripeEnterprisePublicId"
                                >
                                  <InputGroup
                                    name="stripeEnterprisePublicId"
                                    id="stripeEnterprisePublicId"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.stripeEnterprisePublicId}
                                    disabled={!partner.stripeEnterpriseAllowed}
                                  />
                                </FormGroup>
                                <FormGroup
                                  label="Stripe Private Key"
                                  labelFor="stripeEnterpriseAccessId"
                                >
                                  <InputGroup
                                    name="stripeEnterpriseAccessId"
                                    id="stripeEnterpriseAccessId"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.stripeEnterpriseAccessId}
                                    disabled={!partner.stripeEnterpriseAllowed}
                                  />
                                </FormGroup>
                                <br />
                              </Col>
                              <Col xs={12} md={6}>
                                <H6>Settings</H6>
                                <p></p>
                                <FormGroup
                                  label="Payout Day"
                                  helperText="Select day to initiate weekly funds payouts."
                                  labelFor="stripeEnterprisePayoutDay"
                                >
                                  <HTMLSelect
                                    name="stripeEnterprisePayoutDay"
                                    options={[
                                      { label: 'Monday', value: 'MONDAY' },
                                      { label: 'Tuesday', value: 'TUESDAY' },
                                      {
                                        label: 'Wednesday',
                                        value: 'WEDNESDAY',
                                      },
                                      { label: 'Thursday', value: 'THURSDAY' },
                                      { label: 'Friday', value: 'FRIDAY' },
                                    ]}
                                    value={values.stripeEnterprisePayoutDay}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                                <FormGroup
                                  label="Show Stripe Dashboard Link"
                                  helperText="Show a link to the Stripe Dashboard to help users manage their accounts."
                                >
                                  <Checkbox
                                    name="stripeShowDashboardMarketplace"
                                    label="Marketplace"
                                    checked={
                                      values.stripeShowDashboardMarketplace
                                    }
                                    onChange={handleChange}
                                  />
                                  <Checkbox
                                    name="stripeShowDashboardOutlet"
                                    label="Outlet"
                                    checked={values.stripeShowDashboardOutlet}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <div className="bp-card-footer-actions">
                              <Button type="submit" loading={isSubmitting}>
                                Save
                              </Button>
                            </div>
                          </form>
                        )
                      }}
                    </Formik>
                  )
                }}
              </Mutation>
            </Card>
            {partner.stripeEnterpriseId && (
              <Card>
                {!partner.stripeEnterpriseWebhooks ||
                (partner.stripeEnterpriseWebhooks &&
                  partner.stripeEnterpriseWebhooks.length === 0) ? (
                  <Mutation
                    mutation={RESET_PARTNER_WEBHOOKS}
                    onCompleted={({ setPartnerPaymentWebhooks }) => {
                      successToast(setPartnerPaymentWebhooks.message)
                    }}
                    onError={defaultErrorHandler}
                    refetchQueries={[
                      {
                        query: GET_PARTNER_PAYMENTS,
                        variables: { id: partner.id },
                      },
                    ]}
                  >
                    {setPartnerPaymentWebhooks => (
                      <NonIdealState
                        title="Webhook Setup Required"
                        icon="offline"
                        description="You must setup webhooks to process payments."
                        action={
                          <Button
                            type="button"
                            onClick={() =>
                              setPartnerPaymentWebhooks({
                                variables: { id: partner.id },
                              })
                            }
                          >
                            Setup Webhooks
                          </Button>
                        }
                      />
                    )}
                  </Mutation>
                ) : (
                  <Fragment>
                    <H5>Incoming Webhooks</H5>
                    <Card className="bp3-nopad">
                      <HTMLTable>
                        <thead>
                          <tr>
                            <th style={{ width: 200 }}>Event</th>
                            <th>URL</th>
                            <th style={{ width: 10 }}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {partner.stripeEnterpriseWebhooks.map(
                            ({ id, description, url, status }) => (
                              <tr key={id}>
                                <td className="bp3-no-break">{description}</td>
                                <td>
                                  <CopyText minimal mono text={url} fill />
                                </td>
                                <td>
                                  <Tag
                                    minimal
                                    intent={WEBHOOK_STATUS_TO_INTENT[status]}
                                  >
                                    {status}
                                  </Tag>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </HTMLTable>
                    </Card>
                  </Fragment>
                )}
                {partner.stripeEnterpriseWebhooks &&
                  partner.stripeEnterpriseWebhooks.length > 0 && (
                    <div className="bp-card-footer-actions">
                      <Mutation
                        mutation={RESET_PARTNER_WEBHOOKS}
                        onCompleted={({ setPartnerPaymentWebhooks }) => {
                          successToast(setPartnerPaymentWebhooks.message)
                        }}
                        onError={defaultErrorHandler}
                        refetchQueries={[
                          {
                            query: GET_PARTNER_PAYMENTS,
                            variables: { id: partner.id },
                          },
                        ]}
                      >
                        {setPartnerPaymentWebhooks => (
                          <ConfirmationPopover
                            remove={() =>
                              setPartnerPaymentWebhooks({
                                variables: { id: partner.id },
                              })
                            }
                            intent="warning"
                            buttonTitle="Reset Webhooks"
                            confirmationText="Are you sure you want to reset webhooks, this may cause temporary issues processing payments until they are recreated?"
                          >
                            <Button type="button">Reset Webhooks</Button>
                          </ConfirmationPopover>
                        )}
                      </Mutation>

                      <Mutation
                        mutation={DELETE_PARTNER_WEBHOOKS}
                        onCompleted={({ deletePartnerPaymentWebhooks }) => {
                          successToast(deletePartnerPaymentWebhooks.message)
                        }}
                        onError={defaultErrorHandler}
                        refetchQueries={[
                          {
                            query: GET_PARTNER_PAYMENTS,
                            variables: { id: partner.id },
                          },
                        ]}
                      >
                        {deletePartnerPaymentWebhooks => (
                          <ConfirmationPopover
                            remove={() =>
                              deletePartnerPaymentWebhooks({
                                variables: { id: partner.id },
                              })
                            }
                            buttonTitle="Delete All"
                            confirmationText="We will only delete Redbox webhooks, this will stop payments processing normally. Are you sure?"
                          >
                            <Button type="button" intent="danger" minimal>
                              Delete
                            </Button>
                          </ConfirmationPopover>
                        )}
                      </Mutation>
                    </div>
                  )}
              </Card>
            )}
          </Fragment>
        )
      }}
    </Query>
  )
}

Payments.propTypes = {
  partnerId: string,
  match: matchType,
}

export default Payments

import React from 'react'
import { Formik } from 'formik'
import { Switch } from '@blueprintjs/core'
import { isAtLeastMarketplaceAdmin } from '@stores/userStore'

const MarketplaceAllowOtterIntegrationSwtich = ({
  updateField,
  allowOtterIntegration,
}) => {
  return (
    <Formik initialValues={{ allowOtterIntegration }}>
      {({ values, handleChange }) => (
        <section>
          <Switch
            label={'Allow Otter Integration'}
            checked={values.allowOtterIntegration}
            onChange={e => {
              handleChange(e)
              updateField({
                allowOtterIntegration: e.currentTarget.checked,
              })
            }}
            name="allowOtterIntegration"
            disabled={!isAtLeastMarketplaceAdmin()}
          />
        </section>
      )}
    </Formik>
  )
}

export default MarketplaceAllowOtterIntegrationSwtich

import { gql } from '@services/client'

const GET_USER_INFO = gql`
  query getUserInfoManagement {
    getMyDetails {
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        supportEmail
        supportUrl
        supportTitle
        role {
          id
          title
        }
      }
    }
  }
`

export default GET_USER_INFO

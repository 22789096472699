import React from 'react'
import App from '@components/App'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
// init history early so we can pass through to sentry
import { history } from '@stores/routerStore'

// sentry cant track parametrised routes in react router v4 so we need to give it a list of those routes
// this will need updating if we add any new parametrised routes
// note: order is important and longer routes must be before their shorter parents
// can be removed when sentry supports react router v6
/// see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
const parametrisedRoutes = [
  '/partners/:partner/apps/:app',
  '/partners/:partner/details',
  '/partners/:partner',
  '/marketing/:id/:id/campaigns',
  '/marketing/:id/:id/dashboard',
  '/marketing/:id/:id/facebook',
  '/marketing/:id/:id/segments',
  '/marketing/:id/dashboard',
  '/marketing/:id',
  '/business/:restaurants/outlets/:outletId/terminals/:terminal/details',
  '/business/:restaurants/outlets/:outletId/terminals/:terminal',
  '/business/:restaurantId/outlets/:outletId/overview',
  '/business/:restaurantId/outlets/:outletId',
  '/business/:restaurants/overview',
  '/business/:restaurants',
  '/customer/:customer/details',
  '/customer/:customer',
  '/dashboard/:period',
  '/marketplaces/:marketplace/delivery-zones/:deliveryZoneId',
  '/marketplaces/:marketplace/overview',
  '/marketplaces/:marketplace',
  '/messaging/inbox/:marketplaceId',
  '/messaging/sent/:marketplaceId',
  '/orders/:statusFilter',
  '/virtual-terminal/:statusFilter',
].map(path => ({ path }))

Sentry.init({
  dsn: 'https://653b97793bd64e8dad05d9c2f68cc24f@o10154.ingest.sentry.io/4504203318657024',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV4Instrumentation(
        history,
        parametrisedRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  ignoreErrors: [
    'ResizeObserver loop',
    // This error is thrown by the browser when the user denies permission to the camera/audio
    'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
    'AbortError: The play() request was interrupted by a call to pause()',
  ],
  environment: process.env.ENVIRONMENT_NAME,
  // sample replays only for 10% of errors
  replaysOnErrorSampleRate: 0.1,
  release: process.env.SENTRY_RELEASE,
  beforeSend(event) {
    // only send events when SEND_SENTRY_EVENTS is true
    if (process.env.SEND_SENTRY_EVENTS !== 'true') {
      return null
    }
    return event
  },
})

const Root = Sentry.withProfiler(App)

ReactDOM.render(<Root />, document.getElementById('root'))

// Hot Module Replacement
if (module.hot) {
  module.hot.accept()
}

import gql from 'graphql-tag'

const GET_OUTLET = gql`
  query getOutletAndRestaurantNames($outletId: String!) {
    getOutlets(id: $outletId, first: 1) {
      outlets {
        id
        name
        restaurant {
          id
          name
        }
      }
    }
  }
`

export default GET_OUTLET

export const styles = () => ({
  maxMinContainer: {
    display: 'flex',
    alignItems: 'baseline',
    padding: '10px 0px',
    marginRight: 30,
    justifyContent: 'space-between',
  },
  priceRow: { width: 300 },
})

import React from 'react'
import { Mutation } from 'react-apollo'
import { successToast } from '@utils/toast'
import {
  Card,
  Button,
  Switch,
  HTMLSelect,
  FormGroup,
  Intent,
} from '@blueprintjs/core'
import { Row } from 'react-simple-flex-grid'
import defaultErrorHandler from '@utils/defaultErrorHandler'
import { Formik } from 'formik'
// Mutations & Queries
import EDIT_MARKETPLACE_KIOSK_FEATURES from './mutations/editMarketplaceKioskFeatures.mutation'

const DisplayKioskConfig = ({ marketplace }) => {
  return (
    <Card>
      <h5 className="bp3-heading">Kiosk</h5>
      <Mutation
        mutation={EDIT_MARKETPLACE_KIOSK_FEATURES}
        onError={defaultErrorHandler}
        onCompleted={({ editMarketplaceKioskFeatures }) =>
          successToast(editMarketplaceKioskFeatures.message)
        }
      >
        {editMarketplaceAppFeatures => (
          <Formik
            initialValues={marketplace}
            onSubmit={values =>
              editMarketplaceAppFeatures({
                variables: values,
              })
            }
          >
            {({ values, handleChange, handleSubmit }) => (
              <section>
                <Row>
                  <Switch
                    label={'Enable Search'}
                    checked={values.kioskAllowSearch}
                    disabled={true}
                    onChange={handleChange}
                    style={{ width: '90%' }}
                    name="kioskAllowSearch"
                  />
                </Row>
                <br />
                <FormGroup
                  label="Menu Item Options Display"
                  labelFor="kioskOptionsListStyle"
                  intent={Intent.DANGER}
                >
                  <HTMLSelect
                    style={{ width: '300px' }}
                    name="kioskOptionsListStyle"
                    options={[
                      { label: 'List', value: 'LIST' },
                      { label: 'Paginated', value: 'WIZARD' },
                      { label: 'Image Paginated', value: 'WIZARD_IMAGE' },
                    ]}
                    value={values.kioskOptionsListStyle}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup
                  label="Menu Item Display"
                  labelFor="kioskMenuListItemStyle"
                  intent={Intent.DANGER}
                >
                  <HTMLSelect
                    style={{ width: '300px' }}
                    name="kioskMenuListItemStyle"
                    options={[
                      { label: 'List', value: 'LIST' },
                      { label: 'Image List', value: 'IMAGE_LIST' },
                      {
                        label: 'Image Grid',
                        value: 'IMAGE_GRID',
                      },
                    ]}
                    value={values.kioskMenuListItemStyle}
                    onChange={handleChange}
                  />
                </FormGroup>
                <div className="bp-card-footer-actions">
                  <Button text="Save" onClick={handleSubmit} />
                </div>
              </section>
            )}
          </Formik>
        )}
      </Mutation>
    </Card>
  )
}

export default DisplayKioskConfig

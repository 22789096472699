import React from 'react'
import first from 'lodash/first'
import { Mutation } from 'react-apollo'
import { string } from 'prop-types'
import defaultErrorHandler from '@utils/defaultErrorHandler'

import Query from '@components/Query/Query'
import { matchType } from '@utils/types'
import { penceToPounds, numberToPence } from '@utils/helpers'

import { successToast } from '@utils/toast'
import GET_PARTNER from './queries/getPartner.query'
import EDIT_PARTNER from './mutations/editPartner.mutation'
import PartnerForm from './PartnerForm/PartnerForm'

import { validation } from './validation'
import { omit } from 'lodash'
import { isPlatformLevelUser } from '@stores/userStore'

const Details = ({ match }) => {
  const partnerId = match.params.partner

  return (
    <Query
      query={GET_PARTNER}
      variables={{ id: partnerId }}
      loaderTitle={'Loading Partner'}
    >
      {({ getPartners: { partners } }) => {
        const partner = first(partners)
        if (!partner) {
          return 'Unable to find partner.'
        }

        return (
          <Mutation
            mutation={EDIT_PARTNER}
            onError={defaultErrorHandler}
            onCompleted={() => successToast('Partner successfully updated.')}
          >
            {editPartner => {
              const initialValues = {
                ...omit(partner, ['contactAddress.id']),
                platformCharge: penceToPounds(partner.platformCharge),
                partnerCommissionCharge: numberToPence(
                  partner.partnerCommissionCharge || 0
                ),
                partnerVat: partner.partnerVat || 0,
                partnerCommissionFee: partner.partnerCommissionFee || 0,
              }
              return (
                <PartnerForm
                  partner={partner}
                  editable={isPlatformLevelUser()}
                  onSubmit={values =>
                    editPartner({
                      variables: {
                        ...values,
                        platformCharge: numberToPence(values.platformCharge),
                        partnerCommissionCharge: numberToPence(
                          values.partnerCommissionCharge
                        ),
                      },
                    })
                  }
                  initialValues={initialValues}
                  validationSchema={validation}
                />
              )
            }}
          </Mutation>
        )
      }}
    </Query>
  )
}

Details.propTypes = {
  partnerId: string,
  match: matchType,
}

export default Details
